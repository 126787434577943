import React from 'react';
import WhiteBox from '../../../../../sharedComponents/whiteBox/WhiteBox';
import TripaTable from '../../../../../sharedComponents/table/Table';
import { columns } from './reusableComponent';

function Accepted({ candidates }) {
  return (
    <WhiteBox>
      <TripaTable columns={columns} rows={candidates} />
    </WhiteBox>
  );
}

export default Accepted;
