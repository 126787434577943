import React, { useEffect, useState } from "react";
import InfoBoxes from "../../../../sharedComponents/profile/infoBox";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CreateTeam from "./modals/createTeam";
import { getTeamList } from "../../../../../../api/hr";
import { CircularProgress } from "@mui/material";
import DeleteTeam from "./modals/deleteTeam";
import SelectedTeam from "./SelectedTeam";

export default function TeamListing({
  selectedTeam,
  setSelectedTeam,
  selectedCandidates,
  setSelectedCandidates,
  setSelectedCandidateDetail,
}) {
  const [addTeamModalOpen, setAddTeamModelOpen] = useState(false);
  const [deleteTeamModalOpen, setDeleteTeamModalOpen] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const getTeams = async () => {
    setLoading(true);
    const result = await getTeamList();
    if (result?.status === 200 && Array.isArray(result.data)) {
      setTeamList(result.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    getTeams();
  }, []);
  useEffect(() => {
    if (addTeamModalOpen === false && deleteTeamModalOpen === false) {
      setActiveId(null);
      getTeams();
    }
  }, [addTeamModalOpen, deleteTeamModalOpen]);
  return (
    <>
      <InfoBoxes
        hasIcon={true}
        title="Teams"
        containerStyle="xl:max-h-[80vh] max-h-[60vh] overflow-y-auto overflow-hidden"
        icon={<AddCircleOutlineTwoToneIcon />}
        handleClickAdd={() => setAddTeamModelOpen(true)}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          teamList.map((team, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                <div>{team.team_name}</div>
                <div>
                  <EditOutlinedIcon
                    onClick={() => {
                      setActiveId(team._id);
                      setAddTeamModelOpen(true);
                    }}
                    className="text-secondaryBalck cursor-pointer"
                  />
                  <DeleteOutlineOutlinedIcon
                    onClick={() => {
                      setActiveId(team._id);
                      setDeleteTeamModalOpen(true);
                    }}
                    className="text-secondaryBalck cursor-pointer"
                  />
                </div>
              </div>
            );
          })
        )}
        <CreateTeam
          open={addTeamModalOpen}
          id={activeId}
          setOpen={setAddTeamModelOpen}
        />
        <DeleteTeam
          open={deleteTeamModalOpen}
          id={activeId}
          setOpen={setDeleteTeamModalOpen}
        />
      </InfoBoxes>
      <SelectedTeam
        teams={teamList}
        teamLoading={loading}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        selectedCandidates={selectedCandidates}
        setSelectedCandidates={setSelectedCandidates}
        setSelectedCandidateDetail={setSelectedCandidateDetail}
      />
    </>
  );
}
