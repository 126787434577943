import React from "react";
import Cookies from "universal-cookie";
import { useNavigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchBehaviour } from "../../../../redux/actions/behaviourData";
import { googlelogin } from "../../../../api/jc/";
import Header from "../header/header";
import Footer from "../footer/footer";
import CryptoJS from "crypto-js";
import config from "../../../../config/config";

function GoogleLoginPage({
  isSignup = false,
  responseGoogle,
  responseGoogleFail,
}) {
  const cookies = new Cookies();
  const history = useNavigate();
  const dispatch = useDispatch();

  const getUser = async () => {
    googlelogin()
      .then((res) => {
        var ciphertext
        if (res.data.Token) {
          if (res.data.role === "job_candidate") {
            localStorage.setItem("access", res.data.Token);
            cookies.set("token-session", res.data.Token);
            ciphertext = CryptoJS.AES.encrypt(
              JSON.stringify("job_candidate"),
              config.cookieSecret
            ).toString();
            localStorage.setItem("role", ciphertext);
            dispatch(fetchBehaviour());
            const { isFirstNameAndLastNameCompleted } = res.data;
            if (res.data.is_first) {
              localStorage.setItem("accessData", JSON.stringify(res.data));
              const {
                isAssessmentTypeCompleted,
                isBehaviorAssessmentCompleted = true,
                isDriverOfEngagementDiagnosticCompleted,
                isRoleBasedAssessmentCompleted,
                isRoleBasedCompetanceAssessmentCompleted,
              } = res.data;
              if (!isAssessmentTypeCompleted) {
                history("/assessment/start", {
                  state: {
                    behaviorAssessmentCode: isBehaviorAssessmentCompleted,
                  },
                });
              } else if (isBehaviorAssessmentCompleted !== true) {
                history("/assessment/behavior/start", {
                  state: {
                    behaviorAssessmentCode: isBehaviorAssessmentCompleted,
                  },
                });
              } else if (isDriverOfEngagementDiagnosticCompleted !== true) {
                history("/assessment/values/start");
              } else if (
                isRoleBasedAssessmentCompleted !== true ||
                isRoleBasedCompetanceAssessmentCompleted !== true
              ) {
                history("/assessment/rolebased/start");
              } else if (!isFirstNameAndLastNameCompleted) {
                history("/resumeform/upload");
              }
            } else if (!isFirstNameAndLastNameCompleted) {
              history("/resumeform/upload");
            } else {
              history("/coaching/profile");
            }
          } else {
            history("/coaching/login");
          }
        }
        else if (res.data.role === "company") {
          localStorage.setItem("access", res.data.Token);
          cookies.set("token-session", res.data.Token);
          ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify("company"),
            config.cookieSecret
          ).toString();
          localStorage.setItem("role", ciphertext);
          history("/business/dashboard");
        }
      })
      .catch((err) => {
        console.log("error: " + err);
      });
  };
  useEffect(() => {
    getUser();
  });
  return (
    <div className="flex flex-col min-h-[100vh]">
      <Header />
      <div className="lg:m-[100px] md:m-[50px] m-[20px]"></div>
      <Outlet />
      <Footer />
    </div>
  );
}

export default GoogleLoginPage;
