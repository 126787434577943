import React, { useEffect, useState } from "react";
import avatar from "../../../assets/Male.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ScrollBoxWrapper } from "../../HRComponents/hrPanel/pages/candidatePool/reuseableComponent";
import { makeStyles } from "@mui/styles";
import BoxDescriptionInfo from "../../JCComponents/behavioralTraits/sharedcomponents/boxDescriptionInfo";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import CompetencyChart from "./CompetencyChart";
import { mapDriverOfEngagmentColors, mapBehaviourColor } from "../../../../utils/getColors";
import ListCompetencies from "../../HRComponents/hrPanel/pages/condidateList/modals/ListCompetencies";
import { CustomTooltip } from "../../HRComponents/hrPanel/pages/dashboard/reusableComponent";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
      },
    },
    low: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#828282 !important",
      },
    },
    mid: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#8B5086 !important",
      },
    },
    high: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#7AB794 !important",
      },
    },
  };
});

function CondidateProfile({ selectedCandidate:candidate }) {
  const progressstyles = useStyles();
  candidate = candidate?.candidate_profile_id

  // const [progress, setProgress] = useState(70);
  const [competencies, setCompetencies] = useState([]);
  const [motivators, setMotivators] = useState([]);
  const [externalMotivators, setExternalMotivators] = useState([]);
  const [internalMaximizers, setInternalMaximizers] = useState([]);
  const [externalMaximizers, setExternalMaximizers] = useState([]);
  const [internalNeedGrowth, setInternalNeedGrowth] = useState([]);
  const [externalNeedGrowth, setExternalNeedGrowth] = useState([]);
  const [internalMinimizer, setInternalMinimizer] = useState([]);
  const [externalMinimizer, setExternalMinimizer] = useState([]);
  const [internalReinforcementData, setInternalReinforcement] = useState([]);
  const [externalReinforcementData, setExternalReinforcement] = useState([]);
  const [externalPreferred, setExternalPreferred] = useState();
  
  
  const [roleBaseData, setRoleBaseData] = useState([])

  useEffect(() => {
    if (candidate && candidate?.assessments) {
      let roleBasedCompetencies = candidate.assessments.find(
        ({ assessment }) =>
          assessment.assessment_type === 'RoleBasedCompetanceAssessment'
      );
      if (roleBasedCompetencies?.assessment?.result)
        setCompetencies(roleBasedCompetencies.assessment.result);
      else setCompetencies([])

      const roleBasedCompetenciesResultData = candidate?.assessments?.find(
        ({ assessment }) => assessment?.assessment_type === 'RoleBasedCompetanceAssessment'
      )?.assessment?.assessment_content || []
    
      setRoleBaseData(roleBasedCompetenciesResultData?.aiRoleCompetencies || [])

      const externalData = roleBasedCompetenciesResultData?.aiDescriptor?.find(({ heading }) => heading === 'ExternalPatternsPage') || []
      const internalData = roleBasedCompetenciesResultData?.aiDescriptor?.find(({ heading }) => heading === 'InternalPatternsPage') || []
      const internalMotivatorsData = internalData?.sections?.find(({ title }) => title === 'Motivators') || []
      if(internalMotivatorsData?.statements){
        setMotivators(internalMotivatorsData?.statements.split('\n'))
      }

      const externalMotivatorsData = externalData?.sections?.find(({ title }) => title === 'Motivators') || []
      if(externalMotivatorsData?.statements){
        setExternalMotivators(externalMotivatorsData?.statements.split('\n'))
      }

      const internalMaximizersData = internalData?.sections?.find(({ title }) => title === 'Maximizers') || []
      if(internalMaximizersData?.statements){
        setInternalMaximizers(internalMaximizersData?.statements.split('\n'))
      }

      const externalMaximizersData = externalData?.sections?.find(({ title }) => title === 'Maximizers') || []
      if(externalMaximizersData?.statements){
        setExternalMaximizers(externalMaximizersData?.statements.split('\n'))
      }

      const internalReinforcementData = internalData?.sections?.find(({ title }) => title === 'Targets for Reinforcement (R) and Development (D)') || []
      if(internalReinforcementData?.statements){
        setInternalReinforcement(internalReinforcementData?.statements.split('\n'))
      }

      const externalReinforcementData = externalData?.sections?.find(({ title }) => title === 'Targets for Reinforcement (R) and Development (D)') || []
      if(externalReinforcementData?.statements){
        setExternalReinforcement(externalReinforcementData?.statements.split('\n'))
      }

      const internalNeedGrowthData = internalData?.sections?.find(({ title }) => title === 'Needs for Growth') || []
      if(internalNeedGrowthData?.statements){
        setInternalNeedGrowth(internalNeedGrowthData?.statements.split('\n'))
      }

      const externalNeedGrowthData = externalData?.sections?.find(({ title }) => title === 'Needs for Growth') || []
      if(externalNeedGrowthData?.statements){
        setExternalNeedGrowth(externalNeedGrowthData?.statements.split('\n'))
      }

      const internalMinimizerData = internalData?.sections?.find(({ title }) => title === 'Minimizers') || []
      if(internalMinimizerData?.statements){
        setInternalMinimizer(internalMinimizerData?.statements.split('\n'))
      }

      const externalMinimizerData = externalData?.sections?.find(({ title }) => title === 'Minimizers') || []
      if(externalMinimizerData?.statements){
        setExternalMinimizer(externalMinimizerData?.statements.split('\n'))
      }

      const externalPreferredData = externalData?.sections?.find(({ title }) => title === 'Preferred Environment') || []
      if(externalPreferredData?.statements){
        setExternalPreferred(externalPreferredData?.statements)
      }
    }
  }, [candidate]);
  const titleStyle = "text-pirmaryBlue font-bold text-[24px]";
  const subTitleStyle = "text-pirmaryBlue font-bold text-[16px]";
  const textStyle = "text-[#828282] font-normal text-[16px]";

  let valueData = candidate?.assessments?.find(
    ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
  )?.assessment?.assessment_content || []
  let valueTopValuesData = valueData?.score || []

  const behaviourData = candidate?.assessments?.find(
    ({ assessment }) => assessment?.assessment_type === 'BehaviorAssessment'
  )?.assessment?.assessment_content || []
  const preferredBehaviour = behaviourData?.score || []

  const behaviourDiscDescriptor = behaviourData?.discDescriptor || []
  let behaviourStrength = behaviourDiscDescriptor?.find(({ section }) => section === 'sbi')
  let behaviourJobClimate = behaviourDiscDescriptor?.find(({ section }) => section === 'ijc')
  let behaviourTrnLrn = behaviourDiscDescriptor?.find(({ section }) => section === 'trn-lrn')

  let behaviorResultData = candidate?.assessments.filter(
      (i) => i.assessment.assessment_type === "BehaviorAssessment"
  )?.[0]?.assessment?.result || []

  const max = Math.max(...behaviorResultData?.map((x) => Number(x?.value || 0)));
  let maxdefaultBehavior = behaviorResultData?.filter((x) => Number(x.value) === max);

  
  return (
    <div className="max-h-[75vh] overflow-y-auto md:max-h-full pr-5 md:pr-0 ">
      <div className="flex flex-wrap justify-start mb-3">
        <img
          alt={"profile"}
          src={candidate?.candidate_picture_upload_path || avatar}
          className="rounded-full h-28 w-28"
          // sx={{ width: "96px", height: "96px" }}
        />
        <div className="ml-4 flex flex-col items-start justify-center">
          <h3 className="text-pirmaryBlue font-bold text-[24px]">
            {candidate?.first_name || 'Jane Cooperrrr'} {candidate?.last_name || ''}
          </h3>
          <p className="text-secondaryBalck text-[24px] font-normal">
            {candidate?.designation || ''}
          </p>
        </div>
      </div>

      {candidate?.about_me && (
        <div className='mb-6'>
          <h2 className={titleStyle}>About me 
            <CustomTooltip
              TooltipTitle={"About me"}
              TooltipText={
                "A summary of the essential information the candidate wants a manager or human resource professional to know from their résumé."
              }
            />
          </h2>
          <p className='text-pirmaryBlue font-normal text-[16px]'>
            {candidate.about_me}
          </p>
        </div>
      )}
      
      <div className="grid sm:grid-cols-12 grid-cols-1 gap-x-1 gap-y-3">
          <div className='col-span-2'>
            <h2 className="text-[#384259] font-bold text-[24px] text-center">Location
              <CustomTooltip
                TooltipTitle={"Location"}
                TooltipText={
                  "Provides the current place of residence (City, Country) "
                }
              />
            </h2>
            <p className="text-[#828282] font-normal text-[16px] text-center">
              {candidate?.City || ''} {candidate?.country && `, ${candidate.country}`}
            </p>
          </div>
          <div className='col-span-3'>
            <h2 className="text-[#384259] font-bold text-[24px] text-center">
              Salary expectation
              <CustomTooltip
                TooltipTitle={"Salary expectation"}
                TooltipText={
                  "Indicates the salary range the candidate expects to have."
                }
              />
            </h2>
            <p className="text-[#828282] font-normal text-[16px] text-center">
              {candidate?.salary_expectation_min + ' -' || ''}{' '}
              {candidate?.salary_expectation_max || ''}{' '}
              {candidate?.salary_currency || ''}
            </p>
          </div>
          <div className='col-span-1'>
            <h2 className="text-[#384259] font-bold text-[24px] text-center">Skills</h2>
            <p className="text-[#828282] font-normal text-[16px] text-center">{candidate?.matchSkill || candidate?.skills?.length || 0}</p>
          </div>
          <div className='col-span-3'>
            <h2 className="text-[#384259] font-bold text-[24px] text-center">Top 3 values
              <CustomTooltip
                TooltipTitle={"Top 3 values"}
                TooltipText={
                  "Display the top 3 guiding principles and beliefs, attitudes, and behavior that reflect what is essential for the candidate."
                }
              />
            </h2>
            <p className="font-bold text-[16px]">
                { candidate.driver_of_engagement?.length > 0 &&
                  candidate.driver_of_engagement?.split(',').map((data, i) => {
                    let valueTooltip = valueTopValuesData?.find(({ section_fullname }) => data === section_fullname);
                    return (
                      <>
                      <Tooltip
                          key={i}
                          placement='top'
                          title={
                            <div className='rounded-md'>
                              <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
                                {`${data} : ${valueTooltip?.value || 0}%`}
                              </div>
                              <div className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'>
                                {valueTooltip?.statement[0] || ''}
                              </div>
                            </div>
                          }
                          arrow
                        >
                          <span className='text-base cursor-pointer' style={{ color: mapDriverOfEngagmentColors?.[data] }} id={i}>
                            {data}
                            {i !== candidate.driver_of_engagement.split(',').length - 1 && ', '}
                          </span>
                      </Tooltip>
                      </>
                    )
                  })
                }
            </p>
          </div>
          <div className='col-span-3'>
            <h2 className='text-[#384259] font-bold text-[24px] text-center'>
              Preferred behavior
              <CustomTooltip
                TooltipTitle={"Preferred behavior"}
                TooltipText={
                  "This is the natural behavior style of the candidate they are being most natural. It is their primary behavior style and the one they adopt when they are authentic and genuine to themselves."
                }
              />
              <p className='font-bold text-[16px]'>
              { maxdefaultBehavior?.map((preBehavior, index) => {
                let behaviorTooltip = preferredBehaviour?.find(({ type }) => type === preBehavior?.type)
                  return (
                    <>
                      <Tooltip
                        placement='bottom'
                        title={
                          <div className='rounded-md'>
                            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
                              {`${mapBehaviourColor?.[preBehavior?.type]?.name} : ${
                                behaviorTooltip?.value[0]?.value || 0
                              }%`}
                            </div>
                            <div
                              className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'
                              dangerouslySetInnerHTML={{
                                __html:
                                  behaviorTooltip?.statements.join('<br /><br />- ') ||
                                  '',
                              }}
                            />
                          </div>
                        }
                        arrow
                      >
                        <span className='text-base cursor-pointer' style={{ color: mapBehaviourColor?.[preBehavior?.type]?.color }} id={`behavior${index}`}>
                          {mapBehaviourColor?.[preBehavior?.type]?.name}
                          {index !== maxdefaultBehavior.length - 1 && ', '}
                        </span>
                      </Tooltip>
                    </>
                  );
                })
              }
              </p>
            </h2>
          </div>
      </div>
      
      <div className="grid 2xl:grid-cols-10 grid-cols-1 gap-4 my-6">
        {/* chart start */}
        
        <div className='flex flex-col justify-start col-span-6 '>
          <h2 className='text-pirmaryBlue font-bold text-[24px] mb-4'>
            Role-based competencies
            <CustomTooltip
              TooltipTitle={"Role-based competencies (Graph)"}
              TooltipText={
                "Display the list of capacities resulting from the candidate's decision-making (personal, practical, and analytical) style's unique combination. It helps to understand the candidate's strengths and weaknesses that enable them to make the necessary changes to achieve their desired level of success."
              }
            />
          </h2>
          <div className="grid 2xl:grid-cols-10 grid-cols-1 gap-4">
          <p className='col-span-4 text-base text-[16px] text-center font-bold text-pirmaryBlue'>
            {candidate?.assessment_type}
          </p>
          </div>
          {competencies?.length > 0 && (
            <CompetencyChart competencies={competencies} roleBaseData={roleBaseData} />
          )}
        </div>

        {/* chart end */}
        <div className="2xl:flex 2xl:justify-center col-span-4 ">
          <div className="flex flex-col  ">
            <h2 className="text-pirmaryBlue font-bold text-[24px] mb-3">
              Motivators
              <CustomTooltip
                TooltipTitle={"Motivators"}
                TooltipText={
                  "Display statements or adjectives that make the candidate enthusiastic and engaged about doing something based on the unique combination of their decision-making styles."
                }
              />
            </h2>
            { motivators?.map((motivators_sta, index) => {
                return(
                  <>
                    <span className="text-[#828282] font-normal text-[16px]" key={index}>
                      {motivators_sta}
                    </span>
                  </>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="my-6">
        <h2 className="text-pirmaryBlue font-bold text-[24px] mb-4">
          Behavior strength-based insights
          <CustomTooltip
            TooltipTitle={"Behavior strength-based insights"}
            TooltipText={
              "Display a list of statements highlighting the candidate-specific strength of their identified behavior style."
            }
          />
        </h2>
        <h5 className="text-pirmaryBlue font-bold text-[16px] mb-2">
          The following statements highlight specific strengths of {candidate?.first_name} behavioral style: 
        </h5>
        <ul className="list-disc pl-5">
        {behaviourStrength?.subsection?.length &&
          behaviourStrength.subsection[0]?.statements?.length &&
            behaviourStrength?.subsection[0]?.statements?.map((item, index) => (
              <li className="text-[#828282] font-normal text-[16px] decoration-clone" key={index + 1}>
                {item}
              </li>
        ))}
        </ul>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-6">
        <ScrollBoxWrapper title="Role-relevant experience" TooltipTitle={"Role-relevant experience"} TooltipText={"Displayed the achievement/accomplishments of the candidate through their work experience."}>
          {candidate?.work_experience?.length > 0 && (
            <div className=''>
              {candidate.work_experience.map(({role, company_name, from_date, to_date, achievements }, i) => (
                <div id={i}>
                  <div className='mb-[24px]'>
                    <p className='text-[16px] text[#384259] font-normal'>
                      {role}
                      <span className='font-bold'> {company_name || ''}</span>,
                    </p>
                      {from_date && (
                        <p className='text-[16px] text[#384259] font-normal'>
                          {moment(from_date).format('DD-yyyy')} to{' '}
                          {to_date
                            ? moment(to_date).format('DD-yyyy')
                            : moment().format('DD-yyyy')}
                        </p>
                      )}
                  </div>
                  <div className='my-4'>
                    <h2 className='text-[16px] text-pirmaryBlue font-bold'>
                      Achievements:
                    </h2>
                    {achievements && achievements?.split(/\n/).map((data) => (
                      <p className='flex mt-2'>
                        <span className=''>
                          <CheckCircleIcon
                            sx={{
                              '&.MuiSvgIcon-root': { fill: '#7AB794 !important' },
                            }}
                          />
                        </span>
                        <span className='text-[16px] text-pirmaryBlue font-normal ml-3'>
                          {data}{' '}
                        </span>
                      </p>
                    ))}
                  </div>
                  <div className="mb-4"><hr/></div>
                </div>
                )
              )}
            </div>
          )}
        </ScrollBoxWrapper>
        <ScrollBoxWrapper title="Education" TooltipTitle={"Education"} TooltipText={"List the academic achievements of the candidate."}>
          {candidate?.education?.length > 0 && (
            <div className=''>
              {candidate.education.map(
                (
                  { majors_obtained, institution_name, from_date, to_date },
                  i
                ) => (
                  <div id={i}>
                    <div className='mb-4'>
                      <h2 className={subTitleStyle}>{majors_obtained}</h2>
                      {from_date && (
                        <p className={textStyle}>
                          {moment(from_date).format('DD-yyyy')}-
                          {moment(to_date).format('DD-yyyy')}
                        </p>
                      )}
                    </div>
                    {institution_name && (
                      <div className='mb-4'>
                        <h2 className={subTitleStyle}>Institute:</h2>
                        <p className={textStyle}>{institution_name}</p>
                      </div>
                    )}
                    <div className="mb-4"><hr/></div>
                  </div>
                )
              )}
            </div>
          )}
        </ScrollBoxWrapper>
        <ScrollBoxWrapper title="Skills" TooltipTitle={"Skills"} TooltipText={"List the matching skills of the job requisition against the skills documented by the candidate in their résumé and their self-evaluated proficiency on the identified skills."}>
          {candidate?.skills?.length > 0 &&
            candidate.skills.map(({ name, level }, i) => (
              <div id={i} className='flex flex-col'>
                <div
                  className={`flex justify-between ${
                    i === 0 ? 'pb-3' : 'py-3'
                  }`}
                >
                  <span className='text-base text-pirmaryBlue'>{name}</span>
                  <span className='text-base font-bold text-pirmaryBlue'>
                    {level * 20}%
                  </span>
                </div>
                <div className='-mt-2'>
                  <LinearProgress
                    className={
                      level <= 2 ? progressstyles.low
                        : level <= 3 ? progressstyles.mid
                          : level <= 5 ? progressstyles.high : ""
                    }
                    variant='determinate'
                    value={level * 20}
                  />
                </div>
              </div>
            ))}
        </ScrollBoxWrapper>
        <ScrollBoxWrapper title="Skills gap" TooltipTitle={"Skills gap"} TooltipText={"Lists the candidate's skills gaps against the skills of the job requisition."}>
          {candidate?.skillGap?.length > 0 &&
            candidate.skillGap.map((skill, i) => (
              <div className='flex items-center my-3' id={i}>
                <CheckCircleIcon
                  sx={{
                    '&.MuiSvgIcon-root': { fill: '#7AB794 !important' },
                  }}
                />
                <span className={textStyle}>{skill || ''}</span>
              </div>
            ))}
        </ScrollBoxWrapper>
        <ScrollBoxWrapper title="Professional certificate" TooltipTitle={"Professional Certificate"} TooltipText={"List the candidate's focus training for a specific career or task(s)."}>
          <div className="divide-y-2">
            {candidate?.certifications?.length > 0 &&
              candidate.certifications.map(
                ({ name, issuing_by_organization, issue_date }, i) => (
                  <div className='mb-6' id={i}>
                    <h5 className={subTitleStyle}>{name}</h5>
                    <p className={textStyle}>
                      {moment(issue_date).format('DD-yyyy')}
                    </p>
                    <p className={textStyle}>{issuing_by_organization}</p>
                  </div>
                )
              )}
          </div>
        </ScrollBoxWrapper>
        <ScrollBoxWrapper title="Functional area of preference" TooltipTitle={"Functional area of preference"} TooltipText={"The candidates preferred functional areas (management, finance, information technology) to work. Role-based competencies (7 categories): List the role type competencies and their respective sub-competencies that ensure the candidate can do the job and have growth potential."}>
          {candidate?.function_area_preferences?.length > 0 &&
            candidate.function_area_preferences.map((data, i) => (
              <div className={`first-of-type:mt-0 mt-2 ${textStyle}`} id={i}>
                {data}
              </div>
            ))}
        </ScrollBoxWrapper>
      </div>
      <div className="grid lg:grid-cols-1 grid-cols-1 gap-x-6">
        <ScrollBoxWrapper title={`Role-based competencies: ${candidate?.assessment_type || ''}`} TooltipTitle={"Role-based competencies (and sub-competencies description)"} TooltipText={"List the role type competencies and their respective sub-competencies that ensure the candidate has the ability to do the job and growth potential."}>
          <ListCompetencies
            roleBaseData={roleBaseData}
            competencies={competencies}
            assessment={candidate?.assessment_type || ''}
          />
        </ScrollBoxWrapper>
      </div>

      <h2 className={`${titleStyle} mt-6 mb-2`}>Competencies-based strengths
        <CustomTooltip
          TooltipTitle={"Competencies-based strengths"}
          TooltipText={
            "List the candidate's strengths based on their decision-making styles (personal, practical, and analytical) that will enable them to affect changes to achieve personal and professional success. "
          }
        />
      </h2>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-6">
        <BoxDescriptionInfo
          title="Internal"
          list={internalMaximizers}
          style={{
            maxHeight: "300px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
          }}
        />
        <BoxDescriptionInfo
          title="External"
          list={externalMaximizers}
          style={{
            maxHeight: "300px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
          }}
        />
      </div>

      <h2 className={`${titleStyle} mt-6 mb-2`}>Motivation
        <CustomTooltip
          TooltipTitle={"Motivation"}
          TooltipText={
            "List the candidate's internal (how candidates perceive themselves focusing on self-esteem, roles awareness, and self-direction) and external (how the candidate perceives what they do in the workplace, focusing on empathy, practical thinking, and system of judgment) strengths based on their decision-making styles (personal, practical, and analytical) that will enable them to affect changes to achieve personal and professional success."
          }
        />
      </h2>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-6">
        <BoxDescriptionInfo
          title="Internal"
          list={motivators}
          style={{
            maxHeight: "300px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
          }}
        />
        <BoxDescriptionInfo
          title="External"
          list={externalMotivators}
          style={{
            maxHeight: "300px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
          }}
        />
      </div>

      <h2 className={`${titleStyle} mt-6 mb-2`}>
        Target of reinforcement (R) and development (D)
        <CustomTooltip
          TooltipTitle={"The target of Reinforcement (R) and Development (D)"}
          TooltipText={
            "Display actions that target specific behaviors the candidates need positive reinforcement and develop based on their decision-making style."
          }
        />
      </h2>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-6">
        <BoxDescriptionInfo
          title="Internal"
          list={internalReinforcementData}
          style={{
            maxHeight: "300px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
          }}
        />
        <BoxDescriptionInfo
          title="External"
          list={externalReinforcementData}
          style={{
            maxHeight: "300px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
          }}
        />
      </div>

      <div className="mt-8 mb-6 rounded-2xl bg-white max-h-[500px] overflow-hidden border border-grayLight">
        <div className="m-6">
          <h2 className={`${titleStyle} mb-2`}>Preferred environment
            <CustomTooltip
              TooltipTitle={"Preferred environment"}
              TooltipText={
                "Describe the ideal work setting, social features, and physical conditions of the candidate based on their unique combination of decision-making style. The preferred work setting and the perfect work environment lead the candidate to perform tasks and activities to the maximum of their ability while entirely focused and immersed in an incredible feeling of confidence, enjoyment, and engagement: relationship, collaboration, efficiency, and mental health."
              }
            />
          </h2>
          <p className="text-[#384259] font-normal text-[16px] ">
            {externalPreferred}
          </p>
          <h5 className="text-pirmaryBlue font-bold text-[16px] py-[10px]">
            Based on {candidate.first_name} {candidate.last_name} preference to behave, the ideal work environment is as follow:
          </h5>
          <ul className="list-disc pl-5">
          {behaviourJobClimate?.subsection?.length &&
          behaviourJobClimate.subsection[0]?.statements?.length &&
            behaviourJobClimate?.subsection[0]?.statements?.map((item, index) => (
              <li className="text-[#384259] font-normal text-[16px] decoration-clone" key={index + 1}>
                {item}
              </li>
          ))}
          </ul>
        </div>
      </div>

      <div className="mb-6 rounded-2xl bg-white max-h-[500px] overflow-hidden border border-grayLight">
        <div className="m-6">
          <h2 className={`${titleStyle} mb-2`}>Competencies-based strengths
            <CustomTooltip
              TooltipTitle={"Competencies-based strengths"}
              TooltipText={
                "List the candidate's strengths based on their decision-making styles (personal, practical, and analytical) that will enable them to affect changes to achieve personal and professional success. "
              }
            />
          </h2>
          <ul className="list-disc pl-5">
            {internalMaximizers?.map((int_max, index) => {
                return(
                  <>
                    <li className="text-[#384259] font-normal text-[16px] decoration-clone" key={index + 1}>
                      {int_max}
                    </li>
                  </>
                )
              })
            }
            {externalMaximizers?.map((ext_max, index) => {
                return(
                  <>
                    <li className="text-[#384259] font-normal text-[16px] decoration-clone" key={index + 1}>
                      {ext_max}
                    </li>
                  </>
                )
              })
            }
          </ul>
        </div>
      </div>

      <div className="mb-6 rounded-2xl bg-white max-h-[500px] overflow-hidden border border-grayLight">
        <div className="m-6">
          <h2 className={`${titleStyle} mb-2`}>Needs for growth
            <CustomTooltip
              TooltipTitle={"Needs for growth"}
              TooltipText={
                "Provides information the candidate needs to fulfill before moving on to growth. This information could include physiological, safety, social, and esteem."
              }
            />
          </h2>
          <ul className="list-disc pl-5">
            {internalNeedGrowth?.map((int_growth, index) => {
                return(
                  <>
                    <li className="text-[#384259] font-normal text-[16px] decoration-clone" key={index + 1}>
                      {int_growth}
                    </li>
                  </>
                )
              })
            }
            {externalNeedGrowth?.map((ext_growth, index) => {
                return(
                  <>
                    <li className="text-[#384259] font-normal text-[16px] decoration-clone" key={index + 1}>
                      {ext_growth}
                    </li>
                  </>
                )
              })
            }
          </ul>
        </div>
      </div>

      <div className="mb-6 rounded-2xl bg-white max-h-[500px] overflow-hidden border border-grayLight">
        <div className="m-6">
          <h2 className={`${titleStyle} mb-2`}>Kryptonite
            <CustomTooltip
              TooltipTitle={"Kryptonite"}
              TooltipText={
                "Kryptonite is a term derived from the Superman comics and refers to a fictional mineral that weakens and drains Superman's powers. In a metaphorical sense, it represents your vulnerabilities, weaknesses, or blind spots. Just as Superman is susceptible to the negative effects of kryptonite, individuals may have aspects or traits that undermine their performance or hinder their success."
              }
            />
          </h2>

          <ul className="list-disc pl-5">
            {internalMinimizer?.map((int_minimizer, index) => {
                return(
                  <>
                    <li className="text-[#384259] font-normal text-[16px] decoration-clone" key={index + 1}>
                      {int_minimizer}
                    </li>
                  </>
                )
              })
            }
            {externalMinimizer?.map((ext_minimizer, index) => {
                return(
                  <>
                    <li className="text-[#384259] font-normal text-[16px] decoration-clone" key={index + 1}>
                      {ext_minimizer}
                    </li>
                  </>
                )
              })
            }
          </ul>
        </div>
      </div>

      <div className="mb-6 rounded-2xl bg-white max-h-[500px] overflow-hidden border border-grayLight">
        <div className="m-6">
          <h2 className={`${titleStyle} mb-2`}>
            Preferred learning and training styles
            <CustomTooltip
              TooltipTitle={"Preferred learning and training styles"}
              TooltipText={
                "List of candidates' preferences for receiving and conveying knowledge with others based on their tendencies to behave."
              }
            />
          </h2>
          <ul className="list-disc pl-5">
          {behaviourTrnLrn?.subsection?.length &&
          behaviourTrnLrn.subsection[0]?.statements?.length &&
          behaviourTrnLrn?.subsection[0]?.statements?.map((item, index) => (
              <li className="text-[#384259] font-normal text-[16px] decoration-clone" key={index + 1}>
                {item}
              </li>
          ))}
          </ul>
        </div>
      </div>

      {/* <div className="mb-6 rounded-2xl bg-white max-h-[500px] overflow-hidden border border-grayLight">
        <div className="m-6">
          <h2 className={`${titleStyle} mb-2`}>Ideal work environment
            <CustomTooltip
              TooltipTitle={"Ideal work environment"}
              TooltipText={
                "This section provides information about the elements that conform to the perfect work settings for the candidate based on their preferred behavior style. The ideal work environment and the preferred environment are part of the settings that lead the candidate to effortlessly perform tasks and activities to the maximum of their ability while entirely focused and immersed in an incredible feeling of confidence, enjoyment, and engagement: relationship, collaboration, efficiency, and mental health."
              }
            />
          </h2>
          <p className="text-[#384259] font-normal text-[16px]">
            An atmosphere where there is an open exchange of ideas readily
            available feedback; the sharing of responsibilities and decisions. A
            role which allow the most complete usage of all three dimensions of
            thought.
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default CondidateProfile;
