import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, useNavigate } from "react-router-dom";
import { getPremiumStatus } from "../api/jc";
import { openPremiumModal } from "../redux/actions/premium";

export default function ProtectedRoute(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isPremium, setIsPremium] = useState(false);
    const getData = async () => {
        getPremiumStatus().then((res) => {
            const isPremium = res?.data?.user_pricing_type === "premium"
            setIsPremium(isPremium)
            if(!isPremium){
                dispatch(openPremiumModal());
                return navigate('/coaching/profile')
            }
        })
    }

    useEffect(() => {
        getData()
    }, [isPremium]);
    
    return (
        <React.Fragment>
            {
                isPremium ? props.children : null
            }
        </React.Fragment>
    );
}