import React from "react";

function DarkBtn({ children, onClick, customStyle,type }) {
  return (
    <button
      onClick={onClick}
      type={type}
      className="bg-[#828282] hover:bg-[#686666] transition-all py-[9px] px-[27px] d-flex flex-row 
      items-center rounded text-white text-base cursor-pointer "
      style={customStyle}
    >
      {children}
    </button>
  );
}

export default DarkBtn;