import React, { useState, useEffect } from 'react';
import InfoBoxes from '../../sharedComponents/profile/infoBox';
import ReactApexChart from 'react-apexcharts';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { getByUserId, getPremiumStatus } from '../../../../api/jc';
import { Skeleton } from '@mui/material';
import DimensionOfThoughts from '../../sharedComponents/DimensionOfThoughts';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../../redux/actions/user';
import PremiumButton from '../premiumButton';
import behaviorBlured from '../../../assets/Behavioral-traits-blured.png';
import valueImgBlured from '../../../assets/Dashboad-Values-blured.png';
import dimensionsBlured from '../../../assets/Dimension-blured.png';
import profileBlured from '../../../assets/Profile-Role-based-blured.png';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '5px!important',
      borderRadius: '5px',
      backgroundColor: '#F5F5F5 !important',
      '& .MuiLinearProgress-bar': {
        borderRadius: '5px',
      },
    },
    low: {
      height: '5px!important',
      borderRadius: '5px',
      backgroundColor: '#F5F5F5 !important',
      '& .MuiLinearProgress-bar': {
        borderRadius: '5px',
        backgroundColor: '#7AB794 !important',
      },
    },
    mid: {
      height: '5px!important',
      borderRadius: '5px',
      backgroundColor: '#F5F5F5 !important',
      '& .MuiLinearProgress-bar': {
        borderRadius: '5px',
        backgroundColor: '#8B5086 !important',
      },
    },
  };
});
export default function Dashboard() {
  const dispatch = useDispatch();
  const [values, setValues] = useState({ internal: 0, external: 0 });
  const [dimensions, setDimensions] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [behaviourChart, setbehaviourChart] = useState([]);
  const [isPremium, setIsPremium] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [roleBasedCompetanceAssessment, setRoleBasedCompetanceAssessment] =
    useState([]);
  const [percent, setpercent] = useState('');
  const [assessmentType, setassessmentType] = useState('');
  const behaviorChartTooltip = useSelector(
    (state) =>
      state.user?.assessments?.find(
        ({ assessment }) => assessment?.assessment_type === 'BehaviorAssessment'
      )?.assessment?.assessment_content?.score
  );
  const roleBaseTooltip = useSelector(
    (state) =>
      state.user?.assessments
        ?.find(
          ({ assessment }) =>
            assessment?.assessment_type === 'RoleBasedCompetanceAssessment'
        )
        ?.assessment?.assessment_content?.aiRoleCompetencies?.map(
          ({ headingTitle }) => headingTitle.replace('?', '<br /><br />')
        ) || []
  );
  const driverTooltip = useSelector(
    (state) =>
      state.user?.assessments?.find(
        ({ assessment }) =>
          assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
      )?.assessment?.assessment_content?.score || []
  );
  const driverOfEngagementData = [
    'Aesthetic',
    'Economic',
    'Individualistic',
    'Political',
    'Altruistic',
    'Regulatory',
    'Theoretical',
  ];
  const tooltipData = [
    {
      tooltip: [
        `The comment below highlights some of the traits specific to just your unique score. <br />
  
      - You prefer a minimum of direct supervision or control over your actions. <br />
      - You are pretty competitive in the things you do on both, the personal and professional side. <br />
      - You like challenging assignments that require you to work out of your comfort zone. <br />
      - You can be sufficiently assertive without being pushy or nervy. <br />
      - You are always interested in new approaches and new ways of doing things.
      - You like to have variety and a faster pace.`,

        `The comment below highlights some of the traits specific to just your unique score. <br />
  
      - You can be an effective coach or counselor for others. <br />
      - Your present yourself in a poised manner to both small or large groups of people. <br />
      - Your tend to be generous with your time in helping others. <br />
      - Your tend to meet new people in a confident and appropriate manner. <br />
      - You like democratic not dictatorial relationships on the job. <br />
      - You like a flexible environment that allows for creativity.`,

        `The comment below highlights some of the traits specific to just your unique score.<br />
  
      - You appreciate the need of others to have more freedom and less structure.<br />
      - You are flexible enough to deal with change openly and without fear.<br />
      - Change is fine with you as long as it is needed.<br />
      - Your respect the established ways, but are open to change when it is deemed necessary.<br />
      "- You are comfortable acting alone to determine the best course of action.<br />
      - You bring a metered sense of urgency to get things done now, but not without some planning and thought.<br />`,

        `The comment below highlights some of the traits specific to just your unique score. <br />
  
      - You think it is important to adhere to specific and detailed instructions or procedures. <br />
      - You are very conscientious in delivering high level of detail. <br />
      - You desire a great deal of explanation before beginning new tasks. <br />
      - You believe in maintaining high standards of quality control. <br />
      - You are excellent at gathering detailed information and examples. <br />
      - You possess excellent critical thinking and problem solving ability. <br />`,
      ],
    },
    {
      tooltip: ['Adaptive', 'Adaptive', 'Adaptive', 'Adaptive'],
    },
  ];
  const chartBarOption = {
    // dataLabels: {
    //   enabled: false, },
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
      labels: ['Decisive', 'Interactive', 'Steady', 'Cautious'],
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#FF0000', '#E5C311', '#008000', '#0000FF'],
    stroke: {
      show: true,
      width: 6,
      colors: ['transparent'],
    },
    fill: {
      colors: ['#f00', '#D3D3D3'],
    },
    plotOptions: {
      bar: {
        columnWidth: '80%',
        // distributed: true,
      },
    },
    xaxis: {
      categories: ['Decisive', 'Interactive', 'Steady', 'Cautious'],
    },
    yaxis: [
      {
        seriesName: 'Column A',
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
        title: {
          // text: "Columns",
          show: false,
        },
      },
      {
        seriesName: 'Column B',
        show: false,
      },
    ],
    noData: {
      text: 'Loading...',
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      shared: false,
      fixed: {
        enabled: false,
        position: 'topRight',
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        if (seriesIndex === 0) {
          return `
          <div class=''>
            <div class="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t"> 
              ${w.config.chart.labels[dataPointIndex]} : ${
            series[seriesIndex][dataPointIndex]
          }%
            </div>
            <div className='rounded-md tooltip-font-desc' style='padding:0.75rem;background-color:#4D5871;'>
              <div className='text-xs text-white p-3 rounded-b'>
                ${
                  behaviorChartTooltip?.length &&
                  behaviorChartTooltip[dataPointIndex]?.statements.join(
                    '<br /><br />- '
                  )
                }
              </div>
            </div>
          </div>
        `;
        }
        if (seriesIndex === 1) {
          return `
            <div class=''>
              <div class="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                ${w.config.chart.labels[dataPointIndex]} 
                </div>
                <div className='rounded-md tooltip-font-desc' style='padding:0.75rem;background-color:#4D5871;'>
                <div className='text-xs text-white p-3 rounded-b'>
                  ${tooltipData[seriesIndex].tooltip[dataPointIndex]} : ${series[seriesIndex][dataPointIndex]}
                </div>
              </div>
            </div>
          `;
        }
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
      show: false,
    },
  };

  const [chartSeries, setChartSeries] = useState([]);
  const [chartData, setChartData] = useState([
    {
      data: [],
    },
  ]);
  const optionschart = {
    series: { data: [6, 8, 9, 10, 12, 14] },
    options: {
      noData: {
        text: 'Loading...',
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, w }) {
          return `
            <div class="min-w-[300px] w-[300px]">
              <div class="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                ${w.config.labels[seriesIndex]} : ${Number(
            series[seriesIndex]
          ).toFixed(1)}%
              </div>
              <div className='rounded-md tooltip-font-desc' style='padding:0.75rem;background-color:#4D5871;'>
                <div>${w.config.chart.tooltip[seriesIndex]}</div>
              </div>
            </div
          `;
        },
      },
      colors: [
        '#9B7210',
        '#CA902C',
        '#AA554C',
        '#4FD1C5',
        '#7ABEEF',
        '#F6AD55',
        '#8B5086',
        '#7AB794',
      ],
      chart: {
        type: 'pie',
        tooltip: roleBaseTooltip,
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: [
            '#9B7210',
            '#CA902C',
            '#AA554C',
            '#4FD1C5',
            '#7ABEEF',
            '#F6AD55',
            '#8B5086',
            '#7AB794',
          ],
        },
      },

      legend: {
        show: false,
      },
      labels: roleBasedCompetanceAssessment?.length
        ? roleBasedCompetanceAssessment.map(({ type }) => type)
        : [],
    },
  };
  const barOptions = {
    series: [
      {
        data: [40, 50, 60, 55, 45, 70, 60],
      },
    ],
    options: {
      tooltip: {
        enabled: true,
        theme: 'dark',
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `<div class="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t"> 
          ${w.config.chart.labels[dataPointIndex]} : ${series[seriesIndex][dataPointIndex]}% 
          </div>
          <div className='rounded-md tooltip-font-desc' style='padding:0.75rem;background-color:#4D5871'>
              <div className='text-xs text-white p-3 rounded-b'>
                ${w.config.chart.tooltip[dataPointIndex]}
              </div>
            </div>`;
        },
        style: {
          fontSize: '50px',
        },
      },
      noData: {
        text: 'Loading...',
      },
      chart: {
        height: 'auto',
        toolbar: {
          show: false,
        },
        type: 'bar',
        events: {
          click: function (chart, w, e) {},
        },
        tooltip: [
          driverTooltip?.find(({ section }) => section === 'aes')
            ?.statement[0] || '',
          driverTooltip?.find(({ section }) => section === 'eco')
            ?.statement[0] || '',
          driverTooltip?.find(({ section }) => section === 'ind')
            ?.statement[0] || '',
          driverTooltip?.find(({ section }) => section === 'pol')
            ?.statement[0] || '',
          driverTooltip?.find(({ section }) => section === 'alt')
            ?.statement[0] || '',
          driverTooltip?.find(({ section }) => section === 'reg')
            ?.statement[0] || '',
          driverTooltip?.find(({ section }) => section === 'the')
            ?.statement[0] || '',
        ],
        labels: driverOfEngagementData,
      },
      colors: [
        '#A9D18E',
        '#4472C4',
        '#ED7D33',
        '#C2342C',
        '#E5C311',
        '#7F7F7F',
        '#7F6000',
      ],
      plotOptions: {
        bar: {
          columnWidth: '80%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: driverOfEngagementData,
        labels: {
          style: {
            colors: ['#2C2C2C'],
            fontSize: '12px',
          },
        },
      },
    },
  };
  const getData = () => {
    getByUserId().then(async (res) => {
      dispatch(updateUser(res.data));
      let temp = [];
      let temp2 = [];
      let temp3 = [];
      let temp4 = 0;
      res?.data?.assessments
        .filter((i) => i.assessment.assessment_type === 'BehaviorAssessment')[0]
        ?.assessment?.result?.map((item, index) => {
          temp.push(Number(item.value));
        });
      res?.data?.assessments
        .filter((i) => i.assessment.assessment_type === 'BehaviorAssessment')[0]
        ?.assessment?.result?.map((item, index) => {
          temp2.push(Number(item.adaptiveValue));
        });
      setRoleBasedCompetanceAssessment(
        res?.data?.assessments.filter(
          (i) => i.assessment.assessment_type === 'RoleBasedCompetanceAssessment'
        )[0]?.assessment?.result
      );
      res?.data?.assessments
        .filter(
          (i) => i.assessment.assessment_type === 'RoleBasedCompetanceAssessment'
        )[0]
        ?.assessment?.result?.map((item, index) => {
          temp3.push(Number(item?.value));
        });
      res?.data?.assessments
        .filter(
          (i) => i.assessment.assessment_type === 'RoleBasedCompetanceAssessment'
        )[0]
        ?.assessment?.result?.map((item, index) => {
          temp4 += Number(item?.value);
        });

      setbehaviourChart(
        res.data.assessments.filter(
          (i) => i.assessment.assessment_type === 'BehaviorAssessment'
        )[0]?.assessment?.result
      );

      setassessmentType(res.data.assessment_type);
      setpercent(temp4);
      setGraphData(temp3);
      setChartSeries([
        {
          name: 'Column A',
          type: 'column',
          data: temp,
          tooltip: [
            `The comment below highlights some of the traits specific to just your unique score. <br />

              - You prefer a minimum of direct supervision or control over your actions. <br />
              - You are pretty competitive in the things you do on both, the personal and professional side. <br />
              - You like challenging assignments that require you to work out of your comfort zone. <br />
              - You can be sufficiently assertive without being pushy or nervy. <br />
              - You are always interested in new approaches and new ways of doing things.
              - You like to have variety and a faster pace.`,

            `The comment below highlights some of the traits specific to just your unique score. <br />

              - You can be an effective coach or counselor for others. <br />
              - Your present yourself in a poised manner to both small or large groups of people. <br />
              - Your tend to be generous with your time in helping others. <br />
              - Your tend to meet new people in a confident and appropriate manner. <br />
              - You like democratic not dictatorial relationships on the job. <br />
              - You like a flexible environment that allows for creativity.`,

            `The comment below highlights some of the traits specific to just your unique score.<br />

              - You appreciate the need of others to have more freedom and less structure.<br />
              - You are flexible enough to deal with change openly and without fear.<br />
              - Change is fine with you as long as it is needed.<br />
              - Your respect the established ways, but are open to change when it is deemed necessary.<br />
              "- You are comfortable acting alone to determine the best course of action.<br />
              - You bring a metered sense of urgency to get things done now, but not without some planning and thought.<br />`,

            `The comment below highlights some of the traits specific to just your unique score. <br />

              - You think it is important to adhere to specific and detailed instructions or procedures. <br />
              - You are very conscientious in delivering high level of detail. <br />
              - You desire a great deal of explanation before beginning new tasks. <br />
              - You believe in maintaining high standards of quality control. <br />
              - You are excellent at gathering detailed information and examples. <br />
              - You possess excellent critical thinking and problem solving ability. <br />`,
          ],
        },
        {
          name: 'Column B',
          type: 'column',
          data: temp2,
          tooltip: ['Adaptive', 'Adaptive', 'Adaptive', 'Adaptive'],
        },
      ]);
      let values1 = 0;
      let values2 = 0;
      let tempvalues = [];
      let driverData = res?.data?.assessments.find(
        (i) => i.assessment.assessment_type === 'DriverOfEngagementDiagnostic'
      );
      tempvalues = driverOfEngagementData.map((data) => {
        let { value = 0 } =
          driverData?.assessment?.result?.length &&
          driverData.assessment.result.find(({ type }) => data === type);
        return Number(value);
      });
      setChartData([
        {
          data: tempvalues,
        },
      ]);
      setDimensions(
        res.data.assessments.filter(
          (i) => i.assessment.assessment_type === 'RoleBasedAssessment'
        )[0]?.assessment?.result
      );
      let values =
        res?.data?.assessments?.length &&
        res.data.assessments.find(
          (i) => i.assessment.assessment_type === 'RoleBasedAssessment'
        )?.assessment?.api_result?.reliability;
      res?.data?.assessments
        .filter((i) => i.assessment.assessment_type === 'RoleBasedAssessment')[0]
        ?.assessment?.api_result?.reliability?.filter(
          (i) => i.level === 'Internal'
        )
        .map((item) => {
          values1 += Number(item.value);
        });
      res?.data?.assessments
        .filter((i) => i.assessment.assessment_type === 'RoleBasedAssessment')[0]
        ?.assessment?.result?.filter((i) => i.level === 'External')
        .map((item) => {
          values2 += Number(item.value);
        });
      values?.length &&
        setValues({
          internal: values[0] || 0,
          external: values[1] || 0,
        });
      const premeumRes = await getPremiumStatus();
      const isPremium = premeumRes?.data?.user_pricing_type === 'premium';
      setIsPremium(isPremium ? 'yes' : 'no');
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const progressstyles = useStyles();

  const max = Math.max(...behaviourChart?.map((x) => Number(x?.value || 0)));
  const maxValue = behaviourChart?.find((x) => Number(x.value) === max);
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 pb-24 gap-6'>
      <InfoBoxes
        title='Behavioral traits - executive summary'
        // icon={<EditOutlinedIcon />}
        TooltipTitle='Behavioral traits - Executive summary'
        displayWidthStyle='w-[500px]'
        TooltipText={
          <>
            <div>
            This executive summary aims to explain your behavior by breaking it down into individual traits and examining their chemistry combinations. Our analysis is based on the original DISC self-assessment tool created by psychologist William Moulton Marston in 1928, which uses emotional and behavioral theory to identify specific traits. <br/><br/>

            Our analysis identified your unique combination of <span className='text-[#FF0000] font-bold'>Dominance</span>, <span className='text-[#E5C311] font-bold'>Influence</span>, <span className='text-[#008000] font-bold'>Steadiness</span>, and <span className='text-[#0000FF] font-bold'>Conscientiousness</span> traits. Dominance measures your assertiveness and willingness to take charge, while Influence measures your social skills and ability to connect with others. Steadiness measures your patience and tendency to maintain stability, while Conscientiousness measures your attention to detail and willingness to follow the rules.<br/><br/>

            By understanding your individual traits and how they interact with each other, you can gain valuable insights into your own behavior and how it may be perceived by others. This can be useful in both personal and professional contexts, helping you to communicate more effectively, work more collaboratively, and achieve your goals more efficiently.
            </div>
          </>
        }
      >
        {isLoading ? (
          <div className='flex gap-5 justify-between items-end'>
            <Skeleton
              variant='rectangular'
              width={100}
              height={500}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={400}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={500}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={200}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={450}
              className='h-full'
            />
          </div>
        ) : (
          <>
            {isPremium === 'no' && (
              <PremiumButton>
                <img src={behaviorBlured} className='h-[400px] sm:h-[600px]' />
              </PremiumButton>
            )}
            {isPremium === 'yes' && (
              <>
                <div className='chart-container'>
                  <ReactApexChart
                    height={450}
                    width={'100%'}
                    options={chartBarOption}
                    series={chartSeries}
                    type='bar'
                  />
                </div>
                <div
                  className={`flex flex-wrap md:justify-around justify-start items-center text-justify md:w-[80%] mx-auto text-pirmaryBlue italic`}
                >
                  **** Please note that a high score doesn't necessarily correlate with 'good' behavioral traits, just as a low score doesn't imply 'bad' traits. This is because our scoring system is based on a spectrum or continuum of behaviors, thereby reflecting the diversity and complexity of human traits.

                  <span className='text-center pt-5'>**** For a more comprehensive understanding of these traits, delve deeper by exploring your personalized Behavioral Traits Report.</span>
                </div>
              </>
            )}
          </>
        )}
      </InfoBoxes>
      <InfoBoxes
        title='Values - executive summary'
        // icon={<EditOutlinedIcon />}
        displayWidthStyle='w-[500px]'
        TooltipText={
          <>
            <div>
            This executive summary overviews the values that motivate and influence your behavior, performance, and a sense of purpose. By understanding your unique values, you can gain insight into what drives you and how you can align your actions and decisions with your personal and professional goals. <br/><br/>

            Our analysis of your values is based on a comprehensive assessment that evaluates your beliefs, attitudes, and priorities. We have identified the core values that are most important to you and how they shape your behavior and decision-making.<br/><br/>

            Understanding your values and how they relate to your goals and aspirations allows you to make more informed choices and act aligned with your personal and professional vision. This can help you build meaningful relationships, succeed tremendously, and find purpose and fulfillment.<br/><br/>

            This executive summary provides a valuable tool for self-reflection and personal growth, helping you better understand yourself and your motivations.
            </div>
          </>
        }
        TooltipTitle='Values - Executive summary'
      >
        {isLoading ? (
          <div className='flex gap-5 justify-between items-end'>
            <Skeleton
              variant='rectangular'
              width={100}
              height={500}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={400}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={500}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={200}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={450}
              className='h-full'
            />
          </div>
        ) : (
          <>
            {isPremium === 'no' && (
              <PremiumButton>
                <img src={valueImgBlured} className='h-[400px] md:h-[600px]' />
              </PremiumButton>
            )}
            {isPremium === 'yes' && (
              <>
                <ReactApexChart
                  height={450}
                  width={'100%'}
                  options={barOptions.options}
                  series={chartData}
                  type='bar'
                />
                <div
                  className={`flex flex-wrap md:justify-around justify-start items-center text-justify md:w-[80%] mx-auto text-pirmaryBlue italic`}
                >
                  **** It's important to note that a high score on the 'Values report' doesn't necessarily indicate 'good' or 'bad' values. Rather, it reflects the hierarchy of your values according to the current phase of your life. In other words, it's about what matters most to you right now.

                  <span className='text-center pt-5'>**** For an even deeper understanding of your values, delve into your personalized  value report.</span>
                </div>
              </>
            )}
          </>
        )}
      </InfoBoxes>
      <InfoBoxes
        title='Critical Thinking'
        // icon={<EditOutlinedIcon />}
        displayWidthStyle='w-[500px]'
        TooltipText={
          <>
            <div>
              Are you curious about your decision-making style and how it affects your performance in daily life? <br/><br/> 

              In this summary, we'll explore the significant components of your critical thinking, which are the foundation of your overall decision-making pattern.<br/><br/>

              Dr. Robert S. Hartman states that the human mind evaluates and organizes everything into six distinct but fully integrated areas or perspectives. Your critical thinking assessment measures these six independent dimensions of thought, illustrated in the graph below. These dimensions include the External dimension, which is focused on workplace perception and encompasses empathy, practical thinking, and system judgment. The Internal dimension, conversely, is centered on self-perception and includes self-esteem, role awareness, and self-direction.<br/><br/>

              Your critical thinking pattern results from the interplay between these External and Internal dimensions. Since decisions always precede actions, understanding your fundamental ways of thinking is essential.<br/><br/>

              It's worth noting that some people may exhibit unconventional critical thinking, indicating creativity and innovative thinking from a specific perspective. This can be an advantage or a disadvantage, depending on the situation.
            </div>
          </>
        }
        TooltipTitle='Critical thinking'
      >
        {isLoading ? (
          <div className='flex gap-5 justify-between items-end'>
            <Skeleton
              variant='rectangular'
              width={100}
              height={500}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={400}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={500}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={200}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={450}
              className='h-full'
            />
          </div>
        ) : (
          <>
            {isPremium === 'no' && (
              <PremiumButton>
                <img src={dimensionsBlured} />
              </PremiumButton>
            )}
            {isPremium === 'yes' && (
              <div className='grid grid-cols-1 md:grid-cols-2 relative'>
                <div className='absolute -top-14 h-8 rounded-full px-3 flex justify-center items-center bg-grayLight text-pirmaryBlue font-bold text-base right-0'>
                  {(values?.internal).toFixed(2)} /{' '}
                  {(values?.external).toFixed(2)}
                </div>
                <div className='col-span-2'>
                  <DimensionOfThoughts className='relative w-[90%] mx-auto' />
                </div>
                {/* <div className=" mt-16 mb-32 ml-5 flex items-center justify-center md:justify-every"></div> */}
                <div className='pr-6 md:border-r border-dashed pt-6'>
                  <p className='font-bold text-lg text-pirmaryBlue'>External</p>
                  <div className=''>
                    {dimensions
                      ?.filter((i) => i.level === 'External')
                      .map((item, index) => (
                        <div className='flex flex-col mt-2'>
                          <div className='flex justify-between py-3'>
                            <span className='text-base text-pirmaryBlue'>
                              {item.type}
                            </span>
                            <span className='text-base font-bold text-pirmaryBlue'>
                              {Number(item.value).toFixed(1)}
                            </span>
                          </div>
                          <div className='-mt-2'>
                            <LinearProgress
                              className={progressstyles.mid}
                              variant='determinate'
                              value={Number(item.value * 10).toFixed(1)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className='pl-6 pt-6'>
                  <p className='font-bold text-lg text-pirmaryBlue'>Internal</p>
                  <div className=''>
                    {dimensions
                      ?.filter((i) => i.level === 'Internal')
                      .map((item, index) => (
                        <div className='flex flex-col mt-2'>
                          <div className='flex justify-between py-3'>
                            <span className='text-base text-pirmaryBlue'>
                              {item.type}
                            </span>
                            <span className='text-base font-bold text-pirmaryBlue'>
                              {Number(item.value).toFixed(1)}
                            </span>
                          </div>
                          <div className='-mt-2'>
                            <LinearProgress
                              className={progressstyles.low}
                              variant='determinate'
                              value={Number(item.value * 10).toFixed(1)}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div
                  className={`col-span-2 md:justify-around justify-start items-center md:w-[80%] mx-auto text-pirmaryBlue italic text-center pt-[60px]`}
                >
                  *** For deeper insights into Critical thinking traits, explore your Role-based competencies report.
                </div>

              </div>
            )}
          </>
        )}
      </InfoBoxes>
      <InfoBoxes
        title='Role-based competencies'
        // icon={<EditOutlinedIcon />}
        displayWidthStyle='w-[500px]'
        TooltipText={
          <>
            <div>
              This summary provides a list of capacities that result from your unique combination of decision-making styles (personal, practical, and analytical). By understanding your strengths and weaknesses in these areas, you can make necessary changes to achieve your desired level of performance.<br/><br/>

              It's worth noting that these capacities also serve as byproducts of your critical thinking, which forms the foundation of your decision-making style. So as you develop your critical thinking skills, you'll see improvements in your role-based competencies.
            </div>
          </>
        }
        TooltipTitle='Role-based competencies'
      >
        {isLoading ? (
          <div className='flex gap-5 justify-between items-end'>
            <Skeleton
              variant='rectangular'
              width={100}
              height={500}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={400}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={500}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={200}
              className='h-full'
            />
            <Skeleton
              variant='rectangular'
              width={100}
              height={450}
              className='h-full'
            />
          </div>
        ) : (
          <>
            {isPremium === 'no' && (
              <PremiumButton>
                <img src={profileBlured} className='mx-auto' />
              </PremiumButton>
            )}
            {isPremium === 'yes' && (
              <>
                <p className='text-base text-[18px] text-center font-bold text-pirmaryBlue mb-1'>
                  {assessmentType}
                </p>
                <div id='chart' className='relative flex justify-center'>
                  <span className='absolute self-center font-bold text-lg'>
                    {percent &&
                      (percent / graphData?.length)?.toFixed(1) + ' %'}
                    {console.log(graphData)}
                  </span>
                  <ReactApexChart
                    height={340}
                    options={optionschart.options}
                    series={graphData || [20, 30, 40, 50, 50, 60]}
                    type='donut'
                  />
                </div>
                <div className='min-h-44 h-[350px] overflow-y-auto'>
                  {roleBasedCompetanceAssessment?.map((item, index) => (
                    <div
                      className={`flex flex-wrap  text-pirmaryBlue justify-between items-center py-2 mr-3 border-b`}
                    >
                      <span className='flex-grow flex justify-start items-center'>
                        <div className=''>
                          <span
                            style={{
                              backgroundColor: `${optionschart?.options?.dataLabels?.style?.colors?.[index]}`,
                            }}
                            className={`h-3 mr-2 inline-block w-3 rounded-full`}
                          ></span>
                        </div>
                        <span className='text-base leading-5 max-w-fit inline-block'>
                          {item?.type}
                        </span>
                      </span>
                      <span className=' font-bold text-pirmaryBlue'>
                        {Number(item.value).toFixed(1) + ' %'}
                      </span>
                    </div>
                  ))}

                  <div className={`md:justify-around justify-start items-center md:w-[80%] mx-auto text-pirmaryBlue italic text-center pt-[60px]`}>
                    *** For deeper insights into Role-based competencies, explore your personalized report.
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </InfoBoxes>
    </div>
  );
}
