import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/arrowRight.svg";
import {
  awaitingColumns as columns,
  useStyle,
} from "../reusableComponent";

function Recommendation({ allData }) {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage] = React.useState(4);
  const [recommendations, setRecommendations] = React.useState([]);
  const handleChange = (event, value) => {
    setPage(value);
  };

  React.useEffect(() => {
    if(allData?.ask_for_recommendetion?.length) {
      setRecommendations(allData.ask_for_recommendetion.filter(({ response = false }) => !response).map((data) => data));
    }
  }, [allData]);

  const classes = useStyle();
  return (
    <>
      <h2 className="text-[24px] text-[#384259] font-bold mb-6 mt-8">
        Awaiting recommendation
      </h2>
      <div className="">
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 750 }} className={classes.table}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {recommendations
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          let value = row[column.id];

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="flex items-center justify-end">
            <div className="relative mr-5">
              <Pagination
                count={
                  recommendations.length < 10 ? 1 : Math.ceil(recommendations.length / rowsPerPage)
                }
                page={page}
                onChange={handleChange}
                rowsPerPage={rowsPerPage}
                className={classes.pagination}
              />
              <ArrowLeft
                style={{
                  position: "absolute",
                  left: 0,
                  top: "48%",
                  transform: "translateY(-50%)",
                  zIndex: 100,
                  opacity: page - 1 === 0 ? 0.5 : 1,
                }}
              />
              <ArrowRight
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 100,
                  opacity:
                    page === Math.ceil(recommendations.length / rowsPerPage) ? 0.5 : 1,
                }}
              />
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
}

export default Recommendation;
