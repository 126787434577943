import React from "react";
import FollowingQuestion from "../../../../../assets/slideBoxFollowingQuestion.svg";

function Slide2() {
  return (
    <div className="flex items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <img src={FollowingQuestion} alt="" className="w-full h-full" />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          Your business model should answer the following questions:
        </h5>
        <p className="text-base text-pirmaryBlue  mb-6">
          Who are your customers? <br/>
          These could be potential employers, clients, or collaborators.
        </p>
        <p className="text-base text-pirmaryBlue  mb-6">
          What do your customers value? <br/>
          Understanding your customers' needs and preferences is essential for creating value and meeting their expectations.
        </p>
        <p className="text-base text-pirmaryBlue  mb-6">
          How do you make money in this business? <br/>
          This question is particularly relevant if you work as a freelancer or entrepreneur.
        </p>
        <p className="text-base text-pirmaryBlue  mb-6">
          First, you need to identify your revenue streams and pricing strategies to ensure you earn a sustainable income.
        </p>
        <p className="text-base text-pirmaryBlue  mb-6">
          How can you deliver value to customers (employers) at a reasonable cost?<br/>
          This question relates to the concept of cost-effectiveness.
        </p>
        <p className="text-base text-pirmaryBlue  mb-6">
          You need to identify ways to provide value to your customers without incurring high prices, as this will help you remain competitive and profitable.
        </p>
        <p className="text-base text-pirmaryBlue">
          Answering these questions will help you develop a personal business model that aligns with your goals, values, and strengths and enables you to succeed in your career.
        </p>
      </div>
    </div>
  );
}

export default Slide2;
