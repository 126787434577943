import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import TeslaLogo from "../../../../../../assets/teslaLogo.svg";

function createData(
  sr,
  name,
  gender,
  city,
  country,
  jobReqId,
  jobReq,
  assesementType,
  defaultBehavior,
  competenciesScore,
  status
) {
  return {
    sr,
    name,
    gender,
    city,
    country,
    jobReqId,
    jobReq,
    assesementType,
    defaultBehavior,
    competenciesScore,
    status,
  };
}

const CreateBrand = ({ name, src, alt }) => {
  return (
    <span className="flex items-center text-base text-[#384259]">
      <img src={src} alt={alt} style={{ marginRight: "10px" }} /> {name}
    </span>
  );
};

const CreateStatusBtn = ({ editOnClick, deleteOnClick }) => {
  return (
    <span className="flex items-center">
      <CheckIcon
        sx={{
          marginRight: "10px",
          background: "#F4F6F8",
          padding: "4px",
          width: "34px",
          height: "34px",
          borderRadius: "4px",
          color: "#7AB794",
          cursor: "pointer",
        }}
      />
      <ClearIcon
        sx={{
          background: "rgba(220, 38, 38, 0.06);",
          padding: "4px",
          width: "34px",
          height: "34px",
          borderRadius: "4px",
          color: "#DC2626",
          cursor: "pointer",
        }}
      />
    </span>
  );
};

export const columns = [
  { id: "sr", label: "Sr. ", minWidth: 30 },
  { id: "name", label: "Name ", minWidth: 170 },
  { id: "gender", label: "Gender", minWidth: 60 },
  { id: "city", label: "City", minWidth: 60 },
  { id: "country", label: "Country", minWidth: 90 },
  { id: "jobReqId", label: "Requisition ID.", minWidth: 130 },
  { id: "jobReq", label: "Job req.", minWidth: 210 },
  { id: "assesementType", label: "Assesement type", minWidth: 170 },
  { id: "defaultBehavior", label: "Default behavior", minWidth: 170 },
  { id: "competenciesScore", label: "Competence score", minWidth: 180 },
  { id: "status", label: "Status", minWidth: 60 },
];

export const rows = [
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
  createData(
    1,
    <CreateBrand src={TeslaLogo} name="Robert Binny" alt="Robert Binny" />,
    "Male",
    "London",
    "UK ",
    "40",
    "Management Consulting",
    "Management",
    "Cautious - Medium",
    "61.6%",
    <CreateStatusBtn />
  ),
];
