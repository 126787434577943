import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import InfoBoxes from "../../sharedComponents/profile/infoBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ReactComponent as Aesthetic } from "../../../assets/aesthetic.svg";
import { ReactComponent as Economic } from "../../../assets/Economic.svg";
import { ReactComponent as Individualistic } from "../../../assets/Individualistic.svg";
import { ReactComponent as Political } from "../../../assets/Political.svg";
import { ReactComponent as Altruistic } from "../../../assets/Altruistic.svg";
import { ReactComponent as Regulatory } from "../../../assets/Regulatory.svg";
import { ReactComponent as Theoritical } from "../../../assets/Theoritical.svg";
import { ReactComponent as Tick } from "../../../assets/tickicon.svg";
import Asthatic from "./tabs/Asthatic";
import EconomicTab from "./tabs/EconomicTab";
import IndividualisticTab from "./tabs/IndividualisticTab";
import PoliticalTab from "./tabs/PoliticalTab";
import AltruisticTab from "./tabs/AltruisticTab";
import RegulatoryTab from "./tabs/RegulatoryTab";
import TheoriticalTab from "./tabs/TheoriticalTab";
import ReactApexChart from "react-apexcharts";
import {
  a11yProps,
  TabPanel,
  tabstyleValue,
} from "../login/reusableComponents";
import SlideBox from "../../sharedComponents/slideBox/SlideBox";
import Slide1 from "./slides/Slide1";
import Slide2 from "./slides/Slide2";
import Slide3 from "./slides/Slide3";
import Slide4 from "./slides/Slide4";
import Slide5 from "./slides/Slide5";
import { getByUserId } from "../../../../api/jc";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../redux/actions/user";

function ValueProfile() {
  const dispatch = useDispatch()
  const driverOfEngagementData = [
    'Aesthetic',
    'Economic',
    'Individualistic',
    'Political',
    'Altruistic',
    'Regulatory',
    'Theoretical',
  ];
  const tooltipData = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.score || []
  );
  const [openSlideBox, setOpenSlideBox] = React.useState(false);
  const [chartData,setChartData] = useState([
    {
      data: [],
    },
  ])
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = tabstyleValue();
  const SlideBoxList = [
    <Slide1 />,
    <Slide2 />,
    // <Slide3 />,
    //<Slide4 />,
    <Slide5 />,
  ];
  const barOptions = {
    series: [
      {
        data: [40, 50, 60, 55, 45, 70, 60],
      },
    ],
    options: {
      noData: {
        text: 'Loading...'
      },
      tooltip: {
        enabled: true,
        theme: 'dark',
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
          <div class="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
          ${w.config.chart.labels[dataPointIndex]} : ${series[seriesIndex][dataPointIndex]}%
          </div>
          <div className='rounded-md tooltip-font-desc' style='padding:0.75rem;background-color:#4D5871'>
              <div className='text-xs text-white p-3 rounded-b'>
                ${w.config.chart.tooltip[dataPointIndex]}
              </div>
            </div>`;
        },
        style: {
          fontSize: '50px'
        }
      },
      chart: {
        height: "auto",
        toolbar: {
          show: false,
        },
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
        tooltip: [
          tooltipData?.find(({ section }) => section === 'aes')?.statement[0] || '',
          tooltipData?.find(({ section }) => section === 'eco')?.statement[0] || '',
          tooltipData?.find(({ section }) => section === 'ind')?.statement[0] || '',
          tooltipData?.find(({ section }) => section === 'pol')?.statement[0] || '',
          tooltipData?.find(({ section }) => section === 'alt')?.statement[0] || '',
          tooltipData?.find(({ section }) => section === 'reg')?.statement[0] || '',
          tooltipData?.find(({ section }) => section === 'the')?.statement[0] || '',
        ],
        labels: driverOfEngagementData
      },
      colors: [
        "#A9D18E",
        "#4472C4",
        "#ED7D33",
        "#C2342C",
        "#E5C311",
        "#7F7F7F",
        "#7F6000",
      ],
      plotOptions: {
        bar: {
          columnWidth: "80%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: driverOfEngagementData,
        labels: {
          style: {
            colors: ["#2C2C2C"],
            fontSize: "12px",
          },
        },
      },
    },
  };

  let tabColor;
  if (value === 0) {
    tabColor = classes.green;
  } else if (value === 1) {
    tabColor = classes.blue;
  } else if (value === 2) {
    tabColor = classes.orange;
  } else if (value === 3) {
    tabColor = classes.red;
  } else if (value === 4) {
    tabColor = classes.yellow;
  } else if (value === 5) {
    tabColor = classes.gray;
  } else {
    tabColor = classes.brown;
  }
  const getData = () => {
    getByUserId().then((res) => {
      dispatch(updateUser(res.data));
      let temp = []
      
      let driverData = res?.data?.assessments.find(
        (i) => i.assessment.assessment_type === 'DriverOfEngagementDiagnostic'
      );
      temp = driverOfEngagementData.map((data) => {
        let { value = 0 } =
          driverData?.assessment?.result?.length &&
          driverData.assessment.result.find(({ type }) => data === type);
        return Number(value);
      });

      setChartData([{
        data: temp
      }])
      // console.log(res.data.assessments.filter(
      //   i => i.assessment.assessment_type == "DriverOfEngagementDiagnostic"
      // )[0]?.assessment?.result)
      // console.log(temp)
    })
  }
  // console.log(chartSeries)
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <SlideBox
        title="About this report"
        componentList={SlideBoxList}
        open={openSlideBox}
        setOpen={setOpenSlideBox}
      />
      <div className="grid grid-cols-1 lg:grid-cols-3 md:gap-6">
        <div className="col-span-2">
          <InfoBoxes
            hasIcon={true}
            title="Values - executive summary"
            containerStyle="h-[96%]"
            displayStyle="inline-block"
          >
            <span>
              <InfoIcon
                className="cursor-pointer ml-1 text-secondaryBalck"
                onClick={() => setOpenSlideBox(true)}
              />
            </span>
            <ReactApexChart
              height={450}
              width={"100%"}
              options={barOptions.options}
              series={chartData}
              type="bar"
            />
            <div
              className={`flex flex-wrap md:justify-around justify-start items-center md:w-[80%] mx-auto text-pirmaryBlue italic`}
            >
              **** It's important to note that a high score on the 'Values Report' doesn't necessarily indicate 'good' or 'bad' values. Rather, it reflects the hierarchy of your values according to the current phase of your life. In other words, it's about what matters most to you right now.
            </div>
          </InfoBoxes>
        </div>
        <div className="col-span-1">
          <InfoBoxes
            hasIcon={true}
            title="The seven dimensions of values"
            containerStyle="h-[96%]"
          >
            <div className="text-base text-pirmaryBlue w-full">
              Understanding your values is crucial as it provides insight into your preferences and helps you develop strategies to overcome obstacles that hinder your progress in achieving your goals and purpose. These values represent your deepest needs and can significantly impact your behavior and actions, fueling your passion, reducing fatigue, inspiring you, and increasing your level of commitment. Values drive engagement and motivation and help you identify your behavioral preferences, enabling you to pursue the right opportunities uniquely and effectively at the right time, leading to the desired outcome.
            </div>
          </InfoBoxes>
        </div>
      </div>

      <InfoBoxes hasIcon={true} title="My values">
        <h2 className="text-[20px] text-[#384259] font-bold mb-4">
          The Seven Dimensions of Value and Motivation
        </h2>
        <p className="text-pirmaryBlue text-base mb-6 ">
          Research on human performance and management shows that successful individuals often possess high self-awareness. Understanding what motivates you and influences your sense of purpose allows you to make informed decisions about your professional and personal life. Additionally, self-awareness helps you identify a role that aligns with your values, increasing job satisfaction and reducing the risk of burnout.
        </p>
        <p className="text-pirmaryBlue text-base mb-6 ">
          The seven dimensions of value and motivation provide a framework for understanding your values and drivers. By recognizing how these dimensions relate to your values, you can gain insight into your motivations and make decisions that align with your goals and aspirations. This knowledge helps you achieve greater personal and professional fulfillment, allowing you to realize your full potential.
        </p>
        <div className=" overflow-x-auto ">
          <table className="w-full  min-w-[650px]">
            <thead className="bg-[#F4F6F8] h-16 w-full">
              <tr className="bg-[#F4F6F8] ">
                <th className="font-bold px-3 text-left text-2xl text-pirmaryBlue">
                  Value
                </th>
                <th className="font-bold px-3 text-left text-2xl text-pirmaryBlue">
                  Preference
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-16 border-b border-[#E5E5E5]">
                <td className="h-[56px] w-[56px]">
                  <div className="flex items-center">
                    <span>
                      <Aesthetic />
                    </span>
                    <span className="ml-3 text-[#A9D18E] font-bold">
                      Aesthetic
                    </span>
                  </div>
                </td>
                <td className="text-base text-pirmaryBlue px-3 py-[14px] w-[500px]">
                  The primary motivation of this value is to achieve balance and harmony and appreciate the beauty in various forms. Individuals who score high in this dimension typically place high importance on environmental concerns and sustainable practices.
                </td>
              </tr>
              <tr className="h-16 border-b border-[#E5E5E5]">
                <td className="h-[56px] w-[56px]">
                  <div className="flex items-center">
                    <span>
                      <Economic />
                    </span>
                    <span className="ml-3 text-[#4472C4] font-bold">
                      Economic
                    </span>
                  </div>
                </td>
                <td className="text-base text-pirmaryBlue px-3 py-[14px] w-[500px]">
                  This dimension focuses on the motivation for financial security and stability. Individuals scoring high in this dimension typically adopt a professional approach emphasizing bottom-line results and reasonable returns.
                </td>
              </tr>
              <tr className="h-16 border-b border-[#E5E5E5]">
                <td className="h-[56px] w-[56px]">
                  <div className="flex items-center">
                    <span>
                      <Individualistic />
                    </span>
                    <span className="ml-3 text-[#ED7D33] font-bold">
                      Individualistic
                    </span>
                  </div>
                </td>
                <td className="text-base text-pirmaryBlue px-3 py-[14px] w-[500px]">
                  This dimension reflects the need to be seen as unique and independent and to stand out from the crowd. The individualistic drive seeks social independence and the freedom of personal expression.
                </td>
              </tr>
              <tr className="h-16 border-b border-[#E5E5E5]">
                <td className="h-[56px] w-[56px]">
                  <div className="flex items-center">
                    <span>
                      <Political />
                    </span>
                    <span className="ml-3 text-[#C2342C] font-bold">
                      Political
                    </span>
                  </div>
                </td>
                <td className="text-base text-pirmaryBlue px-3 py-[14px] w-[500px]">
                  The political dimension is associated with a drive for leadership, control, and influence over one's environment or success. Competitive tendencies are common among individuals who score high in this dimension.
                </td>
              </tr>
              <tr className="h-16 border-b border-[#E5E5E5]">
                <td className="h-[56px] w-[56px]">
                  <div className="flex items-center">
                    <span>
                      <Altruistic />
                    </span>
                    <span className="ml-3 text-[#E5C311] font-bold">
                      Altruistic
                    </span>
                  </div>
                </td>
                <td className="text-base text-pirmaryBlue px-3 py-[14px] w-[500px]">
                  The altruistic dimension reflects a genuine desire to help others in a humanitarian sense. Individuals who score high in this dimension are driven to give their time, resources, and energy to make a positive impact on the lives of others.
                </td>
              </tr>
              <tr className="h-16 border-b border-[#E5E5E5]">
                <td className="h-[56px] w-[56px]">
                  <div className="flex items-center">
                    <span>
                      <Regulatory />
                    </span>
                    <span className="ml-3 text-[#7F7F7F] font-bold">
                      Regulatory
                    </span>
                  </div>
                </td>
                <td className="text-base text-pirmaryBlue px-3 py-[14px] w-[500px]">
                  The regulatory dimension reflects the motivation to establish order, routine, and structure. Individuals who score high in this dimension emphasize rules, policies, traditional approaches, and security through standards and protocols.
                </td>
              </tr>
              <tr className="h-16 border-b border-[#E5E5E5]">
                <td className="h-[56px] w-[56px]">
                  <div className="flex items-center">
                    <span>
                      <Theoritical />
                    </span>
                    <span className="ml-3 text-[#7F6000] font-bold">
                      Theoretical
                    </span>
                  </div>
                </td>
                <td className="text-base text-pirmaryBlue px-3 py-[14px] w-[500px]">
                  The theoretical dimension reflects the motivation to gain knowledge, understand, or discover the truth. Individuals who score high in this dimension often desire knowledge's sake and emphasize rational thinking, reasoning, and problem-solving.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="text-[20px] font-bold mb-6 mt-10 ">
          Understanding your values for a more fulfilling life
        </div>

        <p className="text-base text-pirmaryBlue mb-6">
          Recognizing your values is essential for creating a blueprint for success and a more fulfilling life. It helps you identify the environment that aligns with your passions and values. The seven dimensions of value - Aesthetic, Economic, Political, Altruistic, Individualistic, Theoretical, and Regulatory - are independent of your behavioral style but can significantly influence your decisions and actions.
        </p>

        <p className="text-base text-pirmaryBlue mb-6">
          By using your values report, you can:
        </p>
        <div className="text-base flex justify-start items-center my-1">
          <Tick />
          <span className="text-pirmaryBlue ml-2 text-base">
            Make informed career decisions that increase job satisfaction.
          </span>
        </div>

        <div className="text-base flex justify-start items-center my-1">
          <Tick />
          <span className="text-pirmaryBlue ml-2 text-base">
            Gain insight into the root causes of conflicts and address them effectively.
          </span>
        </div>

        <div className="text-base flex justify-start items-center my-1">
          <Tick />
          <span className="text-pirmaryBlue ml-2 text-base">
            Experience greater joy and a sense of accomplishment in life.
          </span>
        </div>

        <div className="text-base flex justify-start items-center my-1">
          <Tick />
          <span className="text-pirmaryBlue ml-2 text-base">
            Appreciate and understand viewpoints that differ from your own.
          </span>
        </div>
        <div className="text-base flex justify-start items-center my-1">
          <Tick />
          <span className="text-pirmaryBlue ml-2 text-base">
            Cultivate a deeper appreciation for life.
          </span>
        </div>

        <p className="text-base text-pirmaryBlue mt-5 mb-6 italic">
          Discover your values and live a more fulfilling life that aligns with your passions and aspirations. Our values report offers insights to help you create a purposeful and fulfilling life.
        </p>
      </InfoBoxes>

      <InfoBoxes hasIcon={true} title="A closer look at your seven values">
        <Tabs
          className={`${classes.root} ${tabColor}`}
          value={value}
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Aesthetic"
            {...a11yProps(0)}
            className={`${classes.tab} ${classes.greenTab}`}
          />
          <Tab
            label="Economic"
            {...a11yProps(1)}
            className={`${classes.tab} ${classes.blueTab}`}
          />
          <Tab
            label="Individualistic"
            {...a11yProps(2)}
            className={`${classes.tab} ${classes.orangeTab}`}
          />
          <Tab
            label="Political"
            {...a11yProps(3)}
            className={`${classes.tab} ${classes.redTab}`}
          />
          <Tab
            label="Altruistic"
            {...a11yProps(4)}
            className={`${classes.tab} ${classes.yellowTab}`}
          />
          <Tab
            label="Regulatory"
            {...a11yProps(5)}
            className={`${classes.tab} ${classes.grayTab}`}
          />
          <Tab
            label="Theoretical"
            {...a11yProps(6)}
            className={`${classes.tab} ${classes.brownTab}`}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Asthatic />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EconomicTab />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <IndividualisticTab />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <PoliticalTab />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <AltruisticTab />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <RegulatoryTab />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <TheoriticalTab />
        </TabPanel>
      </InfoBoxes>
    </>
  );
}

export default ValueProfile;
