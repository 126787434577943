import React from "react";
import { BehaviorStepper } from "../reusableComponents";
import WhiteBox from "../shared/WhiteBox";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../../../../config/config";
function BehaviorTraits({ setActiveStep }) {
  const { state } = useLocation();
  const [assessmentCode, setAssessmentCode] = React.useState('');
 const navigate = useNavigate();

 React.useEffect(() => {
   if(state?.behaviorAssessmentCode) {
     setAssessmentCode(state.behaviorAssessmentCode)
   }
 }, [state]);
  return (
    <>
      <BehaviorStepper step={0} />
      <WhiteBox title="Behavior traits assessment">

      <p className="text-base mb-5">
        Unlock your full potential
      </p>

      <p className="text-base mb-5">
        Did you know your behaviors and talents can significantly impact your personal and professional success?
      </p>

      <p className="text-base mb-5">
        Understanding where you stand regarding your behavior traits is the first step to becoming more influential and impactful in your life.  
      </p>

      <p className="text-base mb-5">
        This comprehensive behavior traits assessment will help you uncover your unique strengths and areas for improvement. With this insight, you can make informed decisions about your career path, develop better relationships with colleagues and loved ones, and achieve greater fulfillment in all areas of your life.  
      </p>

      </WhiteBox>
      <div className="max-w-[950px] w-full mx-auto my-5 flex sm:flex-row flex-col sm:h-auto h-[100px] items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate('/assessment/start')}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#E3E6E9",
              fontSize: "16px",
              color: "#828282",
            },
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate('/assessment/behavior/finish', { state: { iFrameLink: `${config.assessmentUrlApi}/remote/DISC/${assessmentCode}/instrument/?fname=Niral&lname=Bhalodia&email=niral@mozayc.net&completeURL=${window.location.origin}/assessment/behavior/complete/?code=` } })}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#7AB794",
              fontSize: "16px",
            },
          }}
          endIcon={
            <ArrowRightAltIcon
              sx={{
                "&.MuiSvgIcon-root": {
                  fontSize: "30px",
                  color: "white",
                },
              }}
            />
          }
        >
          Start the Assesment
        </Button>
      </div>
    </>
  );
}

export default BehaviorTraits;
