import React from "react";
import Modal from "../../../../../sharedComponents/modal/Modal";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import SuccessBtn from "../../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import { saveScoreCard } from "../../../../../../../api/hr";

const tableStyle = makeStyles({
  table: {
    "& .MuiTableRow-head": {
      "& .MuiTableCell-head": {
        background: "#F4F6F8",
        fontWeight: "700",
        color: "#384259",
        fontSize: "16px",
      },
    },
  },
  pagination: {
    margin: "24px 0px 30px 0px",

    "& .MuiSvgIcon-fontSizeMedium": {
      zIndex: 200,
      background: "red",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      opacity: 0,
    },
    "& .MuiPaginationItem-page": {
      border: " 1px solid #E5E5E5",
      borderRadius: "0px",
    },
    "& .Mui-selected": {
      background: "#00000000 !important",
      borderRadius: "0px",
      border: "1px solid #7AB794",
      ":hover": {
        // backgroundColor: "transparent",
      },
    },
  },
});

export const MakeBold = ({ str }) => {
  return <span className="text-[14px] text-pirmaryBlue font-bold">{str}</span>;
};
const NumberInput = ({ value, defaultValue, onchangeScoreData }) => {
  return (
    <input
      type="number"
      className="border-2 border-solid border-[#E3E6E9] rounded w-12 h-12 p-2 focus:outline-none"
      defaultValue={defaultValue}
      value={value}
      onChange={onchangeScoreData}
      min="1"
      max="5"
    />
  );
};
const TextInput = ({ value, defaultValue, onchangeScoreData }) => {
  return (
    <textarea
      name=""
      id=""
      cols="25"
      rows="1"
      defaultValue={defaultValue}
      value={value}
      onChange={onchangeScoreData}
      maxLength={100}
      className="border-2 border-solid border-[#E3E6E9] rounded  p-2 focus:outline-none resize-none"
    >
      {/* {value ? value : ""} */}
    </textarea>
  );
};

const columns = [
  {
    id: "criterion",
    label: (
      <span className="text-base font-bold text-pirmaryBlue">
        Java Developer <br /> Criterion
      </span>
    ),
    minWidth: 50,
  },
  {
    id: "interviewRating",
    label: (
      <span className="text-base font-bold text-pirmaryBlue">
        Interview <br /> Rating (1-5)
        <Tooltip
          placement="top"
          title={
            <div className="rounded-md">
              <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                Interview Rating (1-5)
              </div>
              <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                Please enter the rating for the interviewee to help minimize
                your bias and score the candidate objectively.
              </div>
            </div>
          }
          arrow
        >
          <InfoIcon
            className="text-secondaryBalck cursor-pointer ml-1"
            fontSize="small"
          />
        </Tooltip>
      </span>
    ),
    minWidth: 50,
    align: "center",
  },
  {
    id: "performanceRatingAfterHire",
    label: (
      <span className="text-base font-bold text-pirmaryBlue">
        Performance Rating <br /> after Hire (1-5)
        <Tooltip
          placement="top"
          title={
            <div className="rounded-md">
              <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                Performance Rating after Hire (1-5)
              </div>
              <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                Your new hire will most likely have 60 - 90 days probation, and
                you will need to provide a performance review. You can use this
                section to rate your new hire's value and contribution to the
                company.
              </div>
            </div>
          }
          arrow
        >
          <InfoIcon
            className="text-secondaryBalck cursor-pointer ml-1"
            fontSize="small"
          />
        </Tooltip>
      </span>
    ),
    minWidth: 50,
    align: "center",
  },
  {
    id: "gap",
    label: (
      <span className="text-base font-bold text-pirmaryBlue">
        GAP
        <Tooltip
          placement="top"
          title={
            <div className="rounded-md">
              <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                GAP
              </div>
              <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                While TRIPA provides you with skill gaps, you may want to list
                the gaps you identify during the interview process, compare them
                with the candidate insights report, and decide if the skills
                gaps are something you can work with and develop a training plan
                for the new hire.
              </div>
            </div>
          }
          arrow
        >
          <InfoIcon
            className="text-secondaryBalck cursor-pointer ml-1"
            fontSize="small"
          />
        </Tooltip>
      </span>
    ),
    minWidth: 50,
    align: "center",
  },
  {
    id: "comments",
    label: (
      <span className="text-base font-bold text-pirmaryBlue">
        Comments/
        <br /> Lessons Learned
        <Tooltip
          placement="top"
          title={
            <div className="rounded-md">
              <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                Comments/Lessons Learned
              </div>
              <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                What experiences you distilled from the candidate or your new
                hire that you should consider for future actions and behaviors.
                use this section to document this information. <br /> Reply
              </div>
            </div>
          }
          arrow
        >
          <InfoIcon
            className="text-secondaryBalck cursor-pointer ml-1"
            fontSize="small"
          />
        </Tooltip>
      </span>
    ),
    minWidth: 50,
    align: "center",
  },
];
const rows = [
  {
    criterion: (
      <span className="text-base text-pirmaryBlue font-bold">
        Technical <br />
        Ability
      </span>
    ),
    interviewRating: 1,
    performanceRatingAfterHire: 1,
    gap: "Lorem Ipsum Dummy Content",
    comments: "Lorem Ipsum Dummy Content",
  },
  {
    criterion: (
      <span className="text-base text-pirmaryBlue font-bold">
        Leadership <br /> Skills
      </span>
    ),
    interviewRating: 1,
    performanceRatingAfterHire: 1,
    gap: "Lorem Ipsum Dummy Content",
    comments: "Lorem Ipsum Dummy Content",
  },
  {
    criterion: (
      <span className="text-base text-pirmaryBlue font-bold">
        Internpersonal/
        <br />
        Team Skills
      </span>
    ),
    interviewRating: 1,
    performanceRatingAfterHire: 1,
    gap: "Lorem Ipsum Dummy Content",
    comments: "Lorem Ipsum Dummy Content",
  },
  {
    criterion: (
      <span className="text-base text-pirmaryBlue font-bold">
        Presentation
        <br />
        Skills
      </span>
    ),
    interviewRating: 1,
    performanceRatingAfterHire: 1,
    gap: "Lorem Ipsum Dummy Content",
    comments: "Lorem Ipsum Dummy Content",
  },
  {
    criterion: (
      <span className="text-base text-pirmaryBlue font-bold">
        Organizational
        <br />
        Citizenship
      </span>
    ),
    interviewRating: 1,
    performanceRatingAfterHire: 1,
    gap: "Lorem Ipsum Dummy Content",
    comments: "Lorem Ipsum Dummy Content",
  },
];

let initScoreData = {
  id: '',
  candidate_profile_id: '',
  technical_ability: {
    interviewRating: '',
    performanceRating: '',
    gap: '',
    comment: '',
  },
  leadership_skills: {
    interviewRating: '',
    performanceRating: '',
    gap: '',
    comment: '',
  },
  interpersonal_skills: {
    interviewRating: '',
    performanceRating: '',
    gap: '',
    comment: '',
  },
  presentation_skills: {
    interviewRating: '',
    performanceRating: '',
    gap: '',
    comment: '',
  },
  organizational_citizenship: {
    interviewRating: '',
    performanceRating: '',
    gap: '',
    comment: '',
  },
  overallStrength: '',
  overallConcerns: '',
  suggestion: '',
  date: moment().format('yyyy-MM-DD'),
}

function InterviewScoreModal({ open, setOpen, data, setData, getCandidates }) {
  data = { ...initScoreData, ...data, id: data._id };
  const classes = tableStyle();
  // const [page, setPage] = React.useState(1);
  const page = 1;
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rowsPerPage = 10;
  // const [data, setData] = React.useState(initScoreData);


  const changeTableData = (rowId, col, value) => {
    let userData = { ...data };
    switch (rowId) {
      case 0:
        userData.technical_ability[col] = value;
        break;

      case 1:
        userData.leadership_skills[col] = value;
        break;
      case 2:
        userData.interpersonal_skills[col] = value;
        break;

      case 3:
        userData.presentation_skills[col] = value;
        break;

      case 4:
        userData.organizational_citizenship[col] = value;
        break;
      default:
        break;
    }
    setData(userData);
  };
  const getTableData = (key, col) => {
    switch (key) {
      case 0:
        return data?.technical_ability[col] || '';
      case 1:
        return data?.leadership_skills[col] || '';
      case 2:
        return data?.interpersonal_skills[col] || '';
      case 3:
        return data?.presentation_skills[col] || '';
      case 4:
        return data?.organizational_citizenship[col] || '';
      default:
        return '';
    }
  };


  const onDateChange = ({ target }) => {
    setData({
      ...data,
      date: moment(target.value).format('yyyy-MM-DD'),
    });
  };

  const changeCommonData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      const res = await saveScoreCard(data);
      setOpen(false)
      getCandidates()
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      title="Interview scorecard"
      open={open}
      setOpen={setOpen}
      mdUpWidth={950}
      lgUpWidth={1050}
      xlUpWidth={1150}
    >
      <div className="flex flex-wrap gap-4">
        <div className="w-96">
          <p className="text-base text-pirmaryBlue">Candidate Name</p>
          <p className="text-base text-pirmaryBlue font-bold">Jane Cooper</p>
        </div>
        <div className="flex flex-col">
          <label htmlFor="interviewDate">Date</label>
          <input
            id="interviewDate"
            type="date"
            defaultValue={data.date || ''}
            className="p-3 border-[1px] border-solid border-red rounded min-w-[250px]"
            onChange={onDateChange}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-4 my-6">
        <div className="w-96">
          <p className="text-base text-pirmaryBlue">Position Interviewed for</p>
        </div>
        <div className="flex flex-col">
          <p className="text-base text-pirmaryBlue">Hiring Manager</p>
          <p className="text-base text-pirmaryBlue font-bold">Manager Name</p>
        </div>
      </div>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer
          sx={{ maxHeight: 800, width: "100% " }}
          className={classes.table}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        let value;

                        if (column.id === "interviewRating") {
                          value = (
                            <NumberInput
                              value={getTableData(index, 'interviewRating')}
                              onchangeScoreData={(e) => {
                                changeTableData(
                                  index,
                                  'interviewRating',
                                  e.target.value
                                );
                              }}
                            />
                          );
                        } else if (column.id === "performanceRatingAfterHire") {
                          value = (
                            <NumberInput
                              value={getTableData(index, 'performanceRating')}
                              onchangeScoreData={(e) => {
                                changeTableData(
                                  index,
                                  'performanceRating',
                                  e.target.value
                                );
                              }}
                            />
                          );
                        } else if (column.id === "gap") {
                          value = (
                            <TextInput
                              value={getTableData(index, 'gap')}
                              onchangeScoreData={(e) => {
                                changeTableData(
                                  index,
                                  'gap',
                                  e.target.value
                                );
                              }}
                            />
                          );
                        } else if (column.id === "comments") {
                          value = (
                            <TextInput
                              value={getTableData(index, 'comment')}
                              onchangeScoreData={(e) => {
                                  changeTableData(
                                    index,
                                    'comment',
                                    e.target.value
                                  );
                              }}
                            />
                          );
                        } else {
                          value = row[column.id];
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <div className="grid lg:grid-cols-auto-fit-450 grid-cols-auto-fit-200 gap-4 mt-6">
        <div className="flex flex-col">
          <label htmlFor="" className="text-pirmaryBlue text-base font-normal">
            Overall Strength
          </label>
          <input
            type="text"
            name="overallStrength"
            defaultValue={data.overallStrength}
            onChange={changeCommonData}
            id="overallStrength"
            placeholder="Overall Strength"
            className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="" className="text-pirmaryBlue text-base font-normal">
            Overall Concerns
          </label>
          <input
            type="text"
            name="overallConcerns"
            defaultValue={data.overallConcerns}
            onChange={changeCommonData}
            id="overallConcerns"
            placeholder="Overall Concerns"
            className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="" className="text-pirmaryBlue text-base font-normal">
            What would help this candidate be most successful in the role?
          </label>
          <input
            type="text"
            name="suggestion"
            defaultValue={data.suggestion}
            onChange={changeCommonData}
            id="suggestion"
            placeholder="type any suggestions..."
            className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
          />
        </div>
      </div>
      <div className='mt-3 float-right'>
        <SuccessBtn onClick={handleSubmit}>Submit</SuccessBtn>
      </div>
    </Modal>
  );
}

export default InterviewScoreModal;
