import React from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import { ReactComponent as ArrowLeft } from '../../../../../../../assets/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../../../../assets/arrowRight.svg';
import { columns, useStyle, CreateAvatar } from '../reusableComponent';

import JobPreviewModal from '../modals/JobPreviewModal';
import DeleteModal from '../modals/DeleteModal';
import EditModal from '../modals/EditModal';
import { deleteHRJobPost } from '../../../../../../../../api/hr';

function AllJobs({ getAllJobs, jobPosts }) {
  const [allJobs, setAllJobs] = React.useState([]);
  const [selectedJob, setSelectedJob] = React.useState();

  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const previweHandler = (rowData) => {
    setSelectedJob(rowData);
    setOpen(true);
  };
  const editHandler = (rowData) => {
    setSelectedJob(rowData);
    setOpenEditModal(true);
  };
  const deleteHandler = (rowData) => {
    setOpenDeleteModal(rowData._id);
  };

  const handleDeleteModal = async () => {
    try {
      await deleteHRJobPost({ id: openDeleteModal });
      await getAllJobs();
      setOpenDeleteModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const classes = useStyle();

  React.useEffect(() => {
    if (jobPosts?.length > 0) {
      let newFormat = jobPosts.map((job, i) => {
        return {
          ...job,
          id: i + 1,
          logo: <CreateAvatar src={job.logo_file} />,
        };
      });
      setAllJobs(newFormat);
    }
  }, [jobPosts]);

  return (
    <div className='mt-10'>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 750 }} className={classes.table}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {allJobs
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        let value;

                        if (column.id === 'action') {
                          value = (
                            <div className=''>
                              <VisibilityOutlinedIcon
                                className={classes.perview}
                                onClick={() => previweHandler(row)}
                              />
                              <ModeEditOutlineOutlinedIcon
                                className={classes.edit}
                                onClick={() => editHandler(row)}
                              />
                              <DeleteOutlineOutlinedIcon
                                className={classes.delete}
                                onClick={() => deleteHandler(row)}
                              />
                            </div>
                          );
                        } else {
                          value = row[column.id];
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex items-center justify-end'>
          <div className='relative mr-5'>
            <Pagination
              count={
                allJobs.length < 10
                  ? 1
                  : Math.ceil(allJobs.length / rowsPerPage)
              }
              page={page}
              onChange={handleChange}
              rowsPerPage={rowsPerPage}
              className={classes.pagination}
            />
            <ArrowLeft
              style={{
                position: 'absolute',
                left: 0,
                top: '48%',
                transform: 'translateY(-50%)',
                zIndex: 100,
                opacity: page - 1 === 0 ? 0.5 : 1,
              }}
            />
            <ArrowRight
              style={{
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 100,
                opacity:
                  page === Math.ceil(allJobs.length / rowsPerPage) ? 0.5 : 1,
              }}
            />
          </div>
        </div>
      </Paper>

      {selectedJob && (
        <>
          <JobPreviewModal
            selectedJob={selectedJob}
            open={open}
            setOpen={setOpen}
          />
          <EditModal
            selectedJob={selectedJob}
            open={openEditModal}
            setOpen={setOpenEditModal}
            getAllJobs={getAllJobs}
          />
        </>
      )}
      {DeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          handleDelete={handleDeleteModal}
        />
      )}
    </div>
  );
}

export default AllJobs;
