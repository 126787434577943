import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { a11yProps, TabPanel, tabsStyle } from './reusableComponent';
import Received from './received';
import Accepted from './accepted';
import Rejected from './rejected';
import {
  getRecievedApplications,
  postHRJobPostApplication,
} from '../../../../../../api/hr';
import { Avatar } from '@mui/material';
import { getCompetencyTotal } from '../../../../../../utils/getCompetencyTotal';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CondidateProfileModal from '../condidateList/modals/CondidateProfileModal';

function ApplicationsReceived() {
  const [pendingCandidates, setPendingCandidates] = React.useState([]);
  const [acceptedCandidates, setAcceptedCandidates] = React.useState([]);
  const [rejectedCandidates, setRejectedCandidates] = React.useState([]);
  const [candidProModal, setCandidProModal] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CreateBrand = ({ candidate, name, lastName, src, alt }) => {
    return (
      <span
        className='flex items-center text-base text-[#384259] cursor-pointer'
        onClick={() => {
          setCandidProModal(candidate);
        }}
      >
        <Avatar src={src} alt={alt} style={{ marginRight: '10px' }} /> {name}{' '}
        {lastName}
      </span>
    );
  };

  const CreateStatusBtn = ({ application_id }) => {
    const handleApplication = async (status = 'accepted') => {
      try {
        await postHRJobPostApplication({
          application_id,
          status,
        });
        getAllApplications();
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <span className='flex items-center'>
        <CheckIcon
          onClick={() => handleApplication('accepted')}
          sx={{
            marginRight: '10px',
            background: '#F4F6F8',
            padding: '4px',
            width: '34px',
            height: '34px',
            borderRadius: '4px',
            color: '#7AB794',
            cursor: 'pointer',
          }}
        />
        <ClearIcon
          onClick={() => handleApplication('rejected')}
          sx={{
            background: 'rgba(220, 38, 38, 0.06);',
            padding: '4px',
            width: '34px',
            height: '34px',
            borderRadius: '4px',
            color: '#DC2626',
            cursor: 'pointer',
          }}
        />
      </span>
    );
  };
  const getAllApplications = async () => {
    try {
      const res = await getRecievedApplications();
      if (res?.data?.length > 0) {
        const filterArr = (arr, status) => {
          let filteredArr = arr.filter(
            (candidate) => candidate.status === status
          );
          return filteredArr.map((candidate, index) => {
            let {
              city = '',
              country = '',
              job_id: jobReqId = '',
              job_title: jobReq = '',
            } = candidate?.job_post_id;
            let {
              first_name = '',
              last_name = '',
              // matchSkill = '',
              assessment_type: assesementType = '',
              // driver_of_engagement = '',
              candidate_picture_upload_path = '',
              preferred_behavior: defaultBehavior = '',
              assessments = [],
              gender = '',
            } = candidate?.candidate_profile_id;
            return {
              ...candidate,
              sr: index + 1,
              name: (
                <CreateBrand
                  candidate={candidate}
                  name={first_name}
                  lastName={last_name}
                  src={candidate_picture_upload_path}
                  alt={'alt'}
                />
              ),
              gender,
              city,
              country,
              jobReqId,
              jobReq,
              assesementType,
              defaultBehavior,
              competenciesScore: getCompetencyTotal(assessments) + '%',
              statusBtn: (
                <CreateStatusBtn application_id={candidate?._id || ''} />
              ),
            };
          });
        };

        setPendingCandidates(filterArr(res.data, 'pending'));
        setAcceptedCandidates(filterArr(res.data, 'accepted'));
        setRejectedCandidates(filterArr(res.data, 'rejected'));
      }
    } catch (error) {
      console.error(error);
    }
  };
  React.useEffect(() => {
    getAllApplications();
  }, []);

  const classes = tabsStyle();
  return (
    <div className='mt-10'>
      <Tabs
        className={classes.root}
        value={value}
        onChange={handleChange}
        variant='scrollable'
        allowScrollButtonsMobile
        aria-label='basic tabs example'
        sx={{
          '& .MuiTabs-scroller': {
            borderRadius: '4px',
            color: 'white',
          },
          '&.MuiTabs-root': {
            '&.MuiTabs-scroller': {
              '&.MuiTabs-indicator': {
                backgroundColor: 'red !important',
              },
            },
          },
        }}
      >
        <Tab
          label='Application Received'
          {...a11yProps(0)}
          className={classes.tab}
        />
        <Tab
          label='Application Accepted'
          {...a11yProps(1)}
          className={classes.tab}
        />
        <Tab
          label='Application Rejected'
          {...a11yProps(2)}
          className={classes.tab}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Received candidates={pendingCandidates} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Accepted candidates={acceptedCandidates} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Rejected candidates={rejectedCandidates} />
      </TabPanel>
      <CondidateProfileModal
        open={candidProModal}
        setOpen={setCandidProModal}
      />
    </div>
  );
}

export default ApplicationsReceived;
