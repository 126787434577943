import React, { useState } from "react";
import InfoBoxes from "../../sharedComponents/profile/infoBox";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import { Avatar } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Modal from "../../sharedComponents/modal/Modal";
import CertificateModal from "./modalCompnents/certificateModal";
import moment from "moment";

export default function Certificates({ handleReload, data }) {
  const [addInfo, setAddInfo] = useState(false);
  const [selected, setSelected] = useState({})
  const handleSelectItem = (item) => {
    setAddInfo(true)
    setSelected(item)
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontWeight: "700",
        // width: "56px",
        // height: "56px",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (fullName) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }
  return (
    <>
      <InfoBoxes
        title="Professional certificates"
        handleClickAdd={() => {
          setSelected({})
          setAddInfo(true)
        }}
        icon={<AddCircleOutlineTwoToneIcon />}
        TooltipText="A professional certificate verifies your knowledge, experience, and skills in a specific job or task for which you have received training. It is an essential credential that demonstrates your competency and enhances your professional credibility."
        TooltipTitle="Professional certificates"
      >
        <div className="flex flex-col divide-y">
          {
            data && data?.certifications?.map((item, index) => (
              <div className="grid grid-cols-12 py-4 " key={index}>
                <div className="col-span-1">
                   <Avatar {...stringAvatar(`${item?.name}`)} />
                </div>
                <div className="col-span-11 pl-2">
                  <div className="flex justify-between">
                    <span className="text-lg font-bold text-pirmaryBlue">
                      {item?.name}
                    </span>
                    <EditOutlinedIcon className="text-secondaryBalck cursor-pointer" onClick={() => handleSelectItem(item)} />
                  </div>
                  <div className="text-base leading-5 text-pirmaryBlue">
                    {item?.issuing_by_organization}
                  </div>
                  <div className="text-base leading-5 text-pirmaryBlue">
                    Issued {moment(item?.issue_date).format('DD/MM/YYYY')} 
                    {/* - No Expiration Date */}
                  </div>
                  <div className="text-secondaryBalck">Certificate Number {item?.credential_id}</div>
                </div>
              </div>
            ))
          }
        </div>
      </InfoBoxes>
      <Modal
        title={`${Object.keys(selected).length > 0 ? 'Edit' : 'Add'} professional certificate`}
        open={addInfo}
        setOpen={setAddInfo}
        lgUpWidth={600}
      >
        <CertificateModal selected={selected} handleReload={() => {
          handleReload()
          setAddInfo(false)
          setSelected({})
        }} />
      </Modal>
    </>
  );
}
