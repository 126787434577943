const getTitle = (section) => {
  switch (section) {
    case 'gc':
      return 'General Traits';
    case 'ps':
      return 'Motivational Insights';
    case 'kmm':
      return 'Key Strength';
    case 'tpd':
      return 'Training/Learning Insights';
    case 'cqi':
      return 'Continual Improvement Insights';
    default:
      return section || '';
  }
};

export default getTitle;
