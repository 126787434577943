import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function Item(props) {
  const { id } = props;

  const style = {
    display: "block",
  border: "1px solid #7AB794",
  background: "#fff",
  "border-radius": "8px",
  "font-weight": "bold",
  color: "#7ab794",
  padding: "10px",
  height: "auto",
  "margin-bottom": "4px",
  "text-align": "center",
  };

  return <div style={style}>{id}</div>;
}

export default function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item id={props.id} />
    </div>
  );
}
