import InfoIcon from "@mui/icons-material/Info";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
  },
});

function CustomTooltip({ TooltipTitle, TooltipText, placement }) {
  return (
    <CustomWidthTooltip
      placement={placement}
      title={
        <div className={`rounded-md`}>
          <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
            {TooltipTitle}
          </div>
          <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
            {TooltipText}
          </div>
        </div>
      }
      arrow
    >
      <InfoIcon
        className="text-white cursor-pointer"
        fontSize="small"
        sx={{ marginLeft: "5px" }}
      />
    </CustomWidthTooltip>
  );
}

export const Card = ({
  children,
  value= '',
  hasTitleBar,
  isIconBottom,
  disable,
  setCardAnswerModal,
  title,
  mainQuestoin,
  subQuestionList,
  TooltipTextList=[],
  TooltipTitle,
  text,
  placement
}) => {
  return (
    <div className="rounded-[10px] ">
      {hasTitleBar ? (
        <div className="h-[85px] bg-[#7AB794] rounded-t-[10px] pt-[14px] pb-[10px] px-5">
          <div className="flex">
            <h3 className="text-base text-[16px] uppercase font-bold text-white">
              {hasTitleBar && title && title}
            </h3>
            <CustomTooltip
              TooltipText={
                TooltipTextList &&
                TooltipTextList.map((txt, index) => (
                  <p key={index} className="mb-6">
                    {txt}
                  </p>
                ))
              }
              TooltipTitle={TooltipTitle}
              placement={placement?placement:'top'}
            />
          </div>
          <h5 className="text-[14px] leading-[18px] font-bold text-white">
            {hasTitleBar && mainQuestoin && mainQuestoin}
          </h5>
        </div>
      ) : (
        <div className="h-[80px] 2xl:bg-white bg-[#7AB794] rounded-t-[10px] py-4 px-5"></div>
      )}

      <div
        className="bg-white py-5 px-4 rounded-b-[10px] h-[400px]
       text-xs text-[#828282] font-bold flex flex-col"
      >
        <div className='overflow-y-auto max-h-[360px] mb-[20px]'>
          {value.length ? value.split('\n').map((val) => <p className="mb-5">{val}</p>) :
            (subQuestionList.length &&
              subQuestionList.map((qust, index) => {
                return (
                  <p className='mb-5' key={index}>
                    {qust}
                  </p>
                );
              }))}
          {text && text}
        </div>
        <div
          className={`flex justify-center ${
            isIconBottom ? "mt-auto" : "2xl:mt-0 mt-auto"
          }`}
        >
          {hasTitleBar && (
            <div
              className={`w-[40px] h-[40px] rounded flex justify-center 
              items-center text-white cursor-pointer ${
                disable ? 'bg-[#C4C4C4]' : value ? 'bg-[#7AB794]' : 'bg-[#954A89]'
              }`}
              onClick={
                !disable &&
                (() =>
                  setCardAnswerModal({
                    title,
                    mainQuestoin,
                    value,
                    questions: subQuestionList,
                  }))
              }
            >
              <ModeEditOutlineOutlinedIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
