import React from 'react';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as DimensionofThoughts } from '../../../assets/DimensionofThoughts.svg';
import 'react-tooltip/dist/react-tooltip.css';

const DimensionOfThoughts = ({ className = '' }) => {
  return (
    <>
      <div className={className}>
        <DimensionofThoughts className='my-50' />

        <Tooltip className='react-custom-tooltip' anchorSelect="#empathy-tooltip">
          <div className='rounded-md w-[600px]'>
            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
              Empathy
            </div>
            <div className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'>
              Empathy is a crucial skill that allows you to perceive and understand how your decisions can impact others. It involves placing yourself in someone else's shoes and seeing their circumstances from their perspective. For example, empathy can help you understand your team's needs, motivations, and challenges if you're a manager.<br /><br />

              It is essential to recognize the importance of empathy in personal and professional relationships. Without it, misunderstandings, conflicts, and communication breakdowns will likely occur. EmpIn addition, empathy is a crucial driver of emotional intelligence, a highly valued trait in the workplace.<br /><br />

              Listening actively, asking open-ended questions, and seeking feedback are essential to developing empathy. These actions can help you better understand other people's thoughts, feelings, and perspectives. Moreover, practice putting yourself in other people's shoes and imagining their experiences. Over time, you can develop your empathy skills and become more effective in personal and professional interactions.
            </div>
          </div>
        </Tooltip>
        <Tooltip className='react-custom-tooltip' anchorSelect="#system-judgment-tooltip">
          <div className='rounded-md w-[600px]'>
            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
              System of judgment
            </div>
            <div className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'>
              System judgment is an essential competency that reflects an individual's ability to operate within established external systems, such as policies, rules, and structures. This competency is critical, as it speaks to the clarity of understanding and the importance of how one's choices and actions will work with the established systems, rules, and standards.<br /><br />

              Strong system judgment skills are essential in various settings, such as in a workplace where employees must follow established policies and procedures. An individual with excellent system judgment skills can work effectively within those guidelines while recognizing when a change or deviation may be necessary.<br /><br />

              Additionally, system judgment is essential in a broader societal context. Individuals who can operate within established systems and follow established rules and regulations are typically more likely to be considered responsible and trustworthy members of society. Conversely, those who consistently flout rules and regulations are more likely to be viewed as reckless or unreliable.<br /><br />

              System judgment is a critical competency that can significantly impact an individual's success in various contexts. By operating within established systems and structures, individuals can build trust with colleagues, employers, and society, ultimately leading to tremendous success and opportunities.
            </div>
          </div>
        </Tooltip>
        <Tooltip className='react-custom-tooltip' anchorSelect="#practical-thinking-tooltip">
          <div className='rounded-md w-[600px]'>
            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
              Practical thinking
            </div>
            <div className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'>
              Practical thinking is making pragmatic, helpful, and everyday sense decisions by identifying and understanding problems realistically and evaluating the options available to achieve the expected results practically.<br /><br />

              For example, a manager with a tight budget may use practical thinking to evaluate cost-saving measures such as negotiating better deals with suppliers, reducing staff hours, or cutting back on non-essential expenses. In this case, practical thinking involves weighing the costs and benefits of each option, considering the impact on employees and stakeholders, and choosing the most effective and efficient solution.<br /><br />

              Another example could be a homeowner faced with a leaky roof. Again, practical thinking would involve evaluating the various repair options available, considering factors such as cost, quality of materials, and the reputation of the contractor, and making a decision that balances these factors to achieve the desired outcome within their budget.<br /><br />

              Practical thinking requires a focus on real-world solutions and outcomes, a willingness to consider a range of options, and the ability to make informed decisions based on the available information.
            </div>
          </div>
        </Tooltip>
        <Tooltip className='react-custom-tooltip' anchorSelect="#self-direction-tooltip">
          <div className='rounded-md w-[600px]'>
            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
              Self-direction
            </div>
            <div className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'>
              Self-direction is a vital skill that allows individuals to generate the motivation to improve themselves, regardless of their current level of proficiency. It also involves clearly understanding the path that will lead to this improvement. For example, you who are self-directed might take the initiative to seek new learning opportunities, such as online courses or workshops, to develop your skills in a particular area. You might also challenge yourself by taking on new projects or responsibilities in your current role to stretch your abilities and gain new experiences.<br /><br />

              A low score in this area may indicate that you are in transition or questioning their methods to achieve your self-betterment goals. For instance, if someone is trying to decide which skills to develop or how to do it, they may need help progressing toward their goals. In such cases, seeking guidance from a mentor or coach who can give you insight and direction may be helpful for you.<br /><br />

              Self-direction means taking charge of one's development and identifying the actions and steps necessary to achieve one's goals. This can include setting clear objectives, breaking them down into smaller tasks, and regularly assessing progress. For example, someone who is self-directed might create a personal development plan that outlines specific learning skills and milestones to achieve over a certain period. They might also track their progress and adjust their approach to stay on track.<br /><br />

              Overall, cultivating self-direction is essential for personal growth and professional success. By taking ownership of our development and having a clear vision of our desired outcomes, we can achieve our full potential and lead fulfilling lives.
            </div>
          </div>
        </Tooltip>
        <Tooltip className='react-custom-tooltip' anchorSelect="#self-esteem-tooltip">
          <div className='rounded-md w-[600px]'>
            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
              Self-esteem
            </div>
            <div className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'>
              Self-esteem, or self-acceptance, is the subjective understanding, realization, evaluation, and appreciation of your self-worth. It is based on internal values that allow you to appreciate yourself not for the success you have attained, what you do, or the role you occupy but rather for how you judge yourself to be on the inside. High self-esteem means feeling confident in yourself and your abilities and valuing yourself as a worthwhile and deserving individual.<br /><br />

              Self-esteem speaks to the clarity of understanding and the importance of recognizing how your choices impact your sense of self-worth. For example, someone with high self-esteem might make choices that prioritize their well-being and happiness rather than trying to please others or conform to societal norms. They might also set boundaries in their relationships and assert their needs and values rather than sacrificing their interests to please others.<br /><br />

              On the other hand, someone with low self-esteem may struggle to recognize their worth and feel unworthy of love, respect, and success. As a result, they may engage in self-criticism and negative self-talk, eroding their confidence and undermining their ability to achieve their goals.<br /><br />

              Cultivating healthy self-esteem is essential for personal growth, happiness, and well-being. By valuing ourselves for who we are rather than what we do or achieve, we can develop a strong sense of self-worth and lead fulfilling lives.
            </div>
          </div>
        </Tooltip>
        <Tooltip className='react-custom-tooltip' anchorSelect="#role-awareness-tooltip">
          <div className='rounded-md w-[600px]'>
            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
              Role awareness
            </div>
            <div className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'>
              Role awareness refers to the ability of a person to be aware of their role in the world or within a given environment. It involves understanding the expectations of a particular position and how they can be met. For example, a teacher with high role awareness would clearly understand their responsibilities, such as preparing lesson plans, grading assignments, and communicating with students and parents. They would also understand how to meet the expectations of their position, such as maintaining a positive classroom environment and ensuring that students are learning.<br /><br />

              Role awareness is essential for success in any position, as it allows individuals to align their actions with the expectations of their role. It is imperative when starting a new job or career, as it enables individuals to quickly adapt to their new environment and understand the expectations of their position. However, it is normal for individuals to have lower scores in role awareness and self-direction when seeking new opportunities, as they are still learning and adapting to the new environment. For example, new employees may need higher role awareness as they are still learning their responsibilities and expectations.<br /><br />

              Role awareness emphasizes understanding how your choices impact your perception of your current role. By being aware of your role and the expectations placed on it, you can make informed decisions that align with your responsibilities and contribute to the success of your team or organization. For example, a salesperson with high role awareness would understand the importance of meeting their sales targets and prioritizing their actions accordingly.
            </div>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default DimensionOfThoughts;
