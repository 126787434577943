import React from 'react';
import { ReactComponent as PaypalLogo } from '../../../../../assets/paypalLogo.svg';
import DeleteBtn from '../../../../sharedComponents/buttons/dnagerBtn/DnagerBtn';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import { ReactComponent as ArrowLeft } from '../../../../../assets/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../../assets/arrowRight.svg';
import {
  billingColumns as columns,
  useStyle,
} from '../../reusableComponent';
import { getSubscriptionData, getBillingHistory, cancelSubscription } from '../../../../../../api/jc';
import moment from 'moment';
import Modal from '../../../../sharedComponents/modal/Modal';
import ChangePlan from './ChangePlan';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { toast } from 'react-toastify';
import SuccessBtn from '../../../../sharedComponents/buttons/successBtn/SuccessBtn';

function PlanDetails({ setIsPlanPage }) {
  const [open, setOpen] = React.useState(false);
  const [cancelSubscriptionOpen, setCancelSubscriptionOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  // const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const rowsPerPage =  4;
  const [plan, setPlan] = React.useState({
    name: '',
    planEnd: '',
    amount: '',
    monthAmount: '',
  });
  const [isSubscribed, setIsSubscribed] = React.useState(false);
  const [billingData, setBillingData] = React.useState([]);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const classes = useStyle();

  const getBillingData = async () => {
    try {
      const res = await getBillingHistory()
      if(res?.data?.data?.length > 0) {
        setBillingData(
          res.data?.data?.length > 0 &&
            res.data.data.map(
              (
                { created = '', amount_paid = 0, lines, hosted_invoice_url: link },
                index
              ) => {
                return {
                  id: index + 1,
                  date: moment(created, 'X').format('MMMM D YYYY'),
                  amount: `$${Number(amount_paid) / 100}`,
                  description: lines?.data[0]?.description || '',
                  link,
                };
              }
            )
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getData = async () => {
    try {
      const res = await getSubscriptionData();
      if (res?.data) {
        res.data?.subscription_status === 1 && setIsSubscribed(true);
        if (res.data?.plan) {
          let planEnd = '';
          let plan = res.data?.plan?.plan;
          let name = '';
          let amount = Number(plan?.amount || 0) / 100;
          let monthAmount = '';
          planEnd = moment(res.data.plan?.current_period_end || '', 'X').format('MMMM D YYYY');
          if (plan?.interval === 'year') name = 'Premium';
          else if (plan?.interval === 'month' && plan?.interval_count === 3)
            name = 'Quarterly';
          else name = 'Monthly';
          if (name === 'Premium') monthAmount = Number(amount / 12);
          else if (name === 'Quarterly') monthAmount = Number(amount) / 3;
          else monthAmount = amount;
          monthAmount = String(monthAmount)
          monthAmount = Number(monthAmount.slice(0, monthAmount.indexOf('.') + 3))
          setPlan({
            name,
            planEnd,
            amount,
            monthAmount,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFeemimuPlan = async () =>{
    try{
      const res = await cancelSubscription()
      if (res?.data) {
        if(res.data?.subscription_cancel){
          setCancelSubscriptionOpen(false)
          setIsSubscribed(false)
          toast.success('Subscription canceled successfully!')
        }
      }else{
        toast.error("Something is wrong! Please try again!");
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getData();
    getBillingData()
  }, []);

  return (
    <>
      <h2 className={`text-[24px] text-[#384259] font-bold mt-8`}>
        Plan details
      </h2>
      <p className='mt-6 mb-2 text-base text-[#828282]'>Your current plan</p>
      <div className='flex flex-wrap'>
        <p className='text-base font-bold text-dark mr-[80px]'>
          {isSubscribed ? plan?.name : '-'}
        </p>
        <p
          className='text-base font-bold text-success cursor-pointer transition-all hover:text-[#84cea4]'
          onClick={() => setOpen(true)}
        >
          Change Plan
        </p>
      </div>

      {isSubscribed && (
        <div className='text-[14px] text-dark rounded bg-[#F4F6F8] p-[14px] max-w-[600px] mt-4 mb-12'>
          Your plan will automatically renewed on <span className='font-bold'>{plan.planEnd || ''}</span>. It will
          be charged as one payment of ${plan.amount || ''} ($
          {plan.monthAmount || ''}/month)
        </div>
      )}

      <h2 className='text-[24px] text-[#384259] font-bold'>Payment method</h2>

      <div className='flex flex-wrap mt-6 mb-12'>
        <p className=' mr-[80px]'>
          <PaypalLogo />
        </p>
      </div>

      <h2 className='text-[24px] text-[#384259] font-bold'>Billing history</h2>

      <div className='mt-6'>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 750 }} className={classes.table}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {billingData
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          let value = ''
                          value = row[column.id];
                          if (column.id === 'link') {
                            value = (
                              <a href={row[column.id]} target='_blank' rel="noreferrer">
                                <ReceiptLongIcon
                                  className='text-secondaryBalck cursor-pointer ml-1'
                                  fontSize='small'
                                />
                              </a>
                            );
                          }

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className='flex items-center justify-end'>
            <div className='relative mr-5'>
              <Pagination
                count={
                  billingData.length < 10 ? 1 : Math.ceil(billingData.length / rowsPerPage)
                }
                page={page}
                onChange={handleChange}
                rowsPerPage={rowsPerPage}
                className={classes.pagination}
              />
              <ArrowLeft
                style={{
                  position: 'absolute',
                  left: 0,
                  top: '48%',
                  transform: 'translateY(-50%)',
                  zIndex: 100,
                  opacity: page - 1 === 0 ? 0.5 : 1,
                }}
              />
              <ArrowRight
                style={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  zIndex: 100,
                  opacity:
                    page === Math.ceil(billingData.length / rowsPerPage) ? 0.5 : 1,
                }}
              />
            </div>
          </div>
        </Paper>
      </div>

      <div className='flex items-center mt-8'>
        <div className='flex flex-col'>
          <h5 className='text-[24px] text-[#384259] font-bold'>
            Cancel subscription
          </h5>
          <p className='text-sm text-[#828282] max-w-[600px]'>
            When you cancel your subscription, all future payments will be canceled, and your plan will be downgraded to the free version of TRIPA once the current subscription expires. Please note that this downgrade may result in losing access to some premium features. Thank you for being so supportive, and we hope to see you again in the future.
          </p>
        </div>
        <DeleteBtn disabled={!(isSubscribed)} onClick={() => setCancelSubscriptionOpen(true)}>Cancel subscription</DeleteBtn>
      </div>


      <Modal
        title={"Change plan"}
        open={open}
        setOpen={setOpen}
        mdUpWidth={650}
        style={{ maxWidth: "550px", width: "100%" }}
      >
        <ChangePlan setIsPlanPage={setOpen} setIsSubscribed={setIsSubscribed} isSubscribed={isSubscribed} />
      </Modal>

      <Modal title='Cancel subscription' mdUpWidth={650} style={{ maxWidth: "550px", width: "100%" }} open={cancelSubscriptionOpen}>
        
        <div className="my-6 text-secondaryBalck text-[16px]">
          <p>Canceling your Premium subscription will stop all future payments, and your plan will downgrade to the Freemium version of TRIPA Coaching at the end of your current subscription period.</p>
          <p className="pt-4">Are you sure you want to continue?</p>             
        </div>

        <div className="mt-2 col-span-2 flex justify-between">
          <div>
            <DeleteBtn className='w-full py-4 text=[16px] font-bold' disabled={!(isSubscribed)} onClick={handleFeemimuPlan}>Cancel subscription</DeleteBtn>
          </div>
          <SuccessBtn>
            <span className="font-bold" onClick={() => setCancelSubscriptionOpen(false)}>Never mind</span>
          </SuccessBtn>
        </div>
      </Modal>
    </>
  );
}

export default PlanDetails;
