export const jobFunctins = [
'Administration / Office',
'Arts and Culture',
'Board Member',
'Business / Corporate Services',
'Client / Customer Services',
'Communications',
'Construction, Works, Engineering',
'Education, Curriculum and Teaching',
'Environment, Conservation and NRM',
'Facility / Grounds Management and Maintenance',
'Finance Management',
'Health - Medical and Nursing Management',
'HR, Training and Organisational Development',
'Information and Communications Technology',
'Information Services, Statistics, Records, Archives',
'Infrastructure Management - Transport, Utilities',
'Legal Officers and Practitioners',
'Librarians and Library Management',
'Management',
'Marketing',
'OH&S, Risk Management',
'Operations Management',
'Planning, Policy, Strategy',
'Printing, Design, Publishing, Web',
'Projects, Programs and Advisors',
'Property, Assets and Fleet Management',
'Public Relations and Media',
'Purchasing and Procurement',
'Quality Management',
'Science and Technical Research and Development',
'Security and Law Enforcement',
'Service Delivery',
'Sport and Recreation',
'Travel, Accommodation, Tourism',
'Wellbeing, Community / Social',
]