import React from "react";
import Bussiness from "../../../../../assets/bussinessModel.svg";

function Slide1() {
  return (
    <div className="flex items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <img src={Bussiness} alt="" className="w-full h-full" />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          What is a personal business model?
        </h5>
        <p className="text-base text-pirmaryBlue  mb-6">
          A personal business model is an individual's system logic to identify their unique selling proposition (USP) and create and capture economic and social value. In other words, it's how you describe your story of working to produce value and contribute to society.
        </p>
        <p className="text-base text-pirmaryBlue  mb-6">
          To create your business model, starting with your personal purpose statement (PPS) is essential. This statement defines your goal and helps you identify your unique selling proposition.
        </p>
      </div>
    </div>
  );
}

export default Slide1;
