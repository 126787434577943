import React from "react";
import { ReactComponent as ImgSlide3 } from "../../../../../assets/PurposeStatement3.svg";
import { makeStyles } from "@mui/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const useStyle = makeStyles((theme) => ({
  checkbox: {
    "& .Mui-checked": {
      color: "#7AB693 !important",
    },
    "& .MuiFormControlLabel-label": {
      color: "#828282",
    },
  },
}));

function Slide3({ setAgreement, agreement }) {
  const handleReadInstruction = (e) => {
    setAgreement(e.target.checked);
  };
  const classes = useStyle();
  return (
    <div className="flex items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <ImgSlide3 />
        </div>
      </div>

      <div className="">
        <p className="text-base text-pirmaryBlue  mb-6">
          Remember that your purpose statement is a chemistry and living document; therefore, it will be subject to updates.
        </p>
        <p className="text-base text-pirmaryBlue  mb-6">
          Once you can confidently share your purpose statement with others, it is time for you to start working on your business model to re-invent yourself and align your career with your purpose.
        </p>

        <FormGroup>
          <FormControlLabel
            checked={agreement}
            onChange={handleReadInstruction}
            control={<Checkbox />}
            name=""
            label="I acknowledge that I have read all the instructions properly."
            inputProps={{ "aria-label": "controlled" }}
            className={classes.checkbox}
          />
        </FormGroup>
      </div>
    </div>
  );
}

export default Slide3;
