import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL, headerJson } from "../../api/jc";

export const updateBehaviour = createAction("UPDATE_BEHAVIOUR");

export const fetchBehaviour = createAsyncThunk("users/behaviour", async () => {
  const roleRes = await axios.post(
    `${baseURL}assessments/questions/getQuestionByAssessmentType`,
    {assessment_type: "RoleBasedAssessment"},
    {headers: headerJson()}
  );
  const behaviourRes = await axios.post(
    `${baseURL}assessments/questions/getQuestionByAssessmentType`,
    {assessment_type: "BehaviorAssessment"},
    {headers: headerJson()}
  );
  const driverRes = await axios.post(
    `${baseURL}assessments/questions/getQuestionByAssessmentType`,
    {assessment_type: "DriverOfEngagementDiagnostic"},
    {headers: headerJson()}
  );
  return {
    behaviourAssessment: behaviourRes.data,
    roleBasedAssessment: roleRes.data,
    driverOfEngagement: driverRes.data,
  };
});
