import React from "react";
import Modal from "../../../../../sharedComponents/modal/Modal";
import CompareCandidateProfile from "../../../../../sharedComponents/candidateProfile/CompareCandidateProfile";
import BoxInfo from "../../../../../sharedComponents/profile/infoBox";
function FullReport({ open, setOpen, colors, compatibility }) {
  return (
    <Modal
      mdUpWidth={1350}
      lgUpWidth={1450}
      xlUpWidth={1550}
      title={compatibility.preferred_behavior_compatibility}
      open={open}
      setOpen={setOpen}
      bgColorTitle={colors.bg}
      textColorTitle={"#384259"}
      bgColorContent={"#F8F9FA"}
    >
      <div className="grid xl:grid-cols-2 grid-cols-1 gap-6">
        <BoxInfo hasIcon={true} containerStyle="px-6">
          <CompareCandidateProfile
            selectedCandidate={{ candidate_profile_id: compatibility.a }}
          />
        </BoxInfo>
        <BoxInfo hasIcon={true} containerStyle="px-6">
          <CompareCandidateProfile
            selectedCandidate={{ candidate_profile_id: compatibility.b }}
          />
        </BoxInfo>
      </div>
    </Modal>
  );
}

export default FullReport;
