import React from "react";

function OutlineSuccessBtn({ children, onClick, customStyle, className }) {
  return (
    <button
      onClick={onClick}
      className={[
        "outline-1 outline transition-all hover:bg-[#ececec] outline-success py-[9px] px-[32px] d-flex flex-row items-center rounded text-success text-base m-2",
        className ?? "",
      ].join(" ")}
      style={customStyle}
    >
      {children}
    </button>
  );
}

export default OutlineSuccessBtn;
