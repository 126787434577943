import { createReducer } from "@reduxjs/toolkit"
import { handleBudgetInstructions } from "../actions/personalBudget"

const initPersonalBudget = {
  showInstructions: false
}

const budgetReducer = createReducer(initPersonalBudget, (builder) => {
  builder
    .addCase(handleBudgetInstructions, (state, action) => {
      return ({showInstructions: Boolean(action.payload)})
    })
})

export default budgetReducer;