import React, { useState } from 'react';
import CircleProgressBar from '../../../sharedComponents/circleProgressBar/CircleProgressBar';
import './style.css';
import { ReactComponent as SuccessTraits } from '../../../../assets/success-traits.svg';
import Avatartest2 from '../../../../assets/avatartest.svg';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import PremiumButton from '../../premiumButton';
import successBlured from '../../../../assets/Success-traits-blured.png'

export const charMapping = {
  I: {
    name: 'Interactive',
    color: '#e5c311',
  },
  D: {
    name: 'Decisive',
    color: '#ff0000',
  },
  S: {
    name: 'Steady',
    color: '#008000',
  },
  C: {
    name: 'Cautious',
    color: '#0000ff',
  },
};

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: "#C6E7D4",
      color: "#166534",
      fontSize: '35px',
      fontWeight: "700",
      margin: '0 auto',
      width: "96px",
      height: "96px",
    },
    children: getLetterFromTheName(name),
  };
}

const getLetterFromTheName = (fullName) => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if(index === 0 || index === allNames.length - 1){
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}

const SemiCircleChart = ({ chartData, allData, image, setAdaptiveModal, setAuthenticModal, isPremium, isLoading }) => {
  const [isChartLoading, setIsChartLoading] = useState(true);
  const autheticBehaviorContent = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'BehaviorAssessment'
    )?.assessment?.assessment_content?.discDescriptor?.find(({ section }) => section === 'as')?.title[0] || ''
  );
  const adaptiveBehaviorContent = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'BehaviorAssessment'
    )?.assessment?.assessment_content?.discDescriptor?.find(({ section }) => section === 'ms')?.title[0] || ''
  );
  const max = Math.max(...chartData?.map((x) => Number(x?.value || 0)));
  const maxValue = chartData?.find((x) => Number(x.value) === max);
  const multipleBehavior = chartData?.filter((x) => Number(x.value) === max);

  React.useEffect(() => {
    if(isPremium){
      // setting data into svg image by id
      let img = document.getElementById('profile-logo-behavior'); 
      // img.setAttributeNS(null, 'href', image || <Avatar {...stringAvatar(`${allData?.first_name || ''} ${allData?.last_name || ''}`)} />);
      img?.setAttributeNS(null, 'href', image || Avatartest2);
      if (chartData?.length) {
        chartData.forEach(({ type, value }) => {
          switch (type) {
            case 'D':
              document.querySelector('#behavior-decisive-chart-label').innerHTML =
                'Decisive';
              document.querySelector(
                '#behavior-decisive-chart-value'
              ).innerHTML = `${value}%`;
              break;
            case 'I':
              document.querySelector(
                '#behavior-interactive-chart-label'
              ).innerHTML = 'Interactive';
              document.querySelector(
                '#behavior-interactive-chart-value'
              ).innerHTML = `${value}%`;
              break;
            case 'S':
              document.querySelector('#behavior-steady-chart-label').innerHTML =
                'Steady';
              document.querySelector(
                '#behavior-steady-chart-value'
              ).innerHTML = `${value}%`;
              break;
            case 'C':
              document.querySelector('#behavior-cautious-chart-label').innerHTML =
                'Cautious';
              document.querySelector(
                '#behavior-cautious-chart-value'
              ).innerHTML = `${value}%`;
              break;

            default:
              break;
          }
        });
        setIsChartLoading(false)
      }
    }
  }, [chartData]);

  return (
    <div className='grid grid-cols-1 md:grid-cols-10'>
      <div className='col-span-4'>
        {isLoading ? (
          <div className='flex gap-5 justify-center w-full'>
            <Skeleton
              variant='circular'
              height={300}
              width={300}
            />
          </div>
        ) : !isPremium ? (
          <PremiumButton>
            <img src={successBlured} className='mx-auto' />
          </PremiumButton>
        ) : (
          <>
          <div className={isChartLoading? 'hidden' : undefined}>
            <div>
              <SuccessTraits className='mx-auto w-[100%]' />
            </div>
            <div>
              <div className='flex text-center items-center justify-center md:justify-every'>
                <div>
                  <div className='bottom'>
                    {/* <div className='image'>
                      {image && <img src={image} className='' />}
                    </div> */}
                    <div className='text'>
                      <h4 className='font-bold'>{`${allData.first_name}  ${allData.last_name}`}</h4>
                      <div className='text-gray-500 flex items-center justify-center'>
                        <span>Natural behavior:&nbsp;</span>
                        {multipleBehavior?.map((preBehavior, index) => {
                        return (
                          <>
                            <span style={{ color: charMapping?.[preBehavior?.type]?.color }} className='font-bold'>
                              {charMapping?.[preBehavior?.type]?.name}
                            </span>
                            {index < multipleBehavior.length - 1 && <>, &nbsp;</>}
                          </>
                        )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        )}
      </div>

      <div className='col-span-6 md:pl-10'>
        <p className=' text-pirmaryBlue text-lg mb-3'>
          <span className=' font-bold'>How </span>
          do you prefer to use your talents based on natural behavior style.
        </p>
        <div className='grid grid-cols-1 md:grid-cols-8'>
          <div className='flex flex-col my-2 w-full col-span-1 md:col-span-7 pr-10'>
            <div className='text-pirmaryBlue font-bold text-base'>Authentic behavior</div>
            <div>
              <p className='text-pirmaryBlue text-base'>Authentic behavior or natural style pattern refers to your behavior when you're not consciously thinking about it. This is your most comfortable and authentic self, and it's the style that you tend to revert to under stress...</p>
              <button
                type="button"
                className="text-success underline text-base font-bold leading-5 mt-3"
                onClick={() => setAuthenticModal(true)}
              >
                View more
              </button>
            </div>
          </div>
          <div className='flex items-center justify-center my-3 md:my-0'>
            <CircleProgressBar
              percentage={maxValue?.value}
              pathColor={charMapping?.[maxValue?.type]?.color}
            />
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-8'>
          <div className='flex flex-col my-2 w-full col-span-1 md:col-span-7 pr-10'>
            <div className='text-pirmaryBlue font-bold text-base'>Adaptive behavior</div>
            <div>
              <p className='text-pirmaryBlue text-base'>Adaptive behavior or style pattern refers to how you consciously modify your behavior to adapt to a particular situation or environment. Unlike your natural behavior pattern, this is not your default behavior...</p>
              <button
                type="button"
                className="text-success underline text-base font-bold leading-5 mt-3"
                onClick={() => setAdaptiveModal(true)}
              >
                View more
              </button>
            </div>
          </div>
          <div className='flex items-center justify-center my-3 md:my-0'>
            <CircleProgressBar
              percentage={maxValue?.adaptiveValue}
              pathColor='grey'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SemiCircleChart;
