import React, { useEffect, useState } from "react";
import InfoBoxes from "../../../../sharedComponents/profile/infoBox";
import SuccessBtn from "../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import CreateAvatar from "./sharedComponent/CreateAvatar";
import HorizontalProfileInfo from "../../../../sharedComponents/horizontalProfileInfo/HorizontalProfileInfo";
import { makeStyles } from "@mui/styles";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import {
  getTeamById,
  addCandidateToCompatibility,
} from "../../../../../../api/hr";

import { CircularProgress } from "@mui/material";
import { ProfileCheckbox } from "../candidatePool/reuseableComponent";

import condidate1 from "../../../../../assets/condidate1.svg";
import condidate2 from "../../../../../assets/condidate2.svg";

const useStyle = makeStyles((theme) => ({
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E3E6E9 !important",
      backgroundColor: "transparent !important",
      top: 0,

      "& .css-1in441m": {
        display: "none",
      },
      "& .css-1ftyaf0": {
        display: "none",
      },
    },
    "& .MuiSelect-nativeInput": {
      bottom: "50%",
      transform: "translateY(50%)",
      left: "20px !important",
      opacity: 0,
    },
    "& .MuiSelect-icon": {
      right: "20px !important",
      fill: "#7E8B96 !important",
      display: "none",
    },
    "& .MuiInputBase-input": {
      padding: "0px",
    },
  },
  inputLabel: {
    "&.Mui-focused ": {
      color: "#384259 !important",
    },
    "&.MuiInputLabel-root": {
      color: "#384259 !important",
      fontSize: "16px",
      transform: "none !important",
      position: "static !important",
    },
  },
  menuItem: {
    "&.Mui-selected ": {
      backgroundColor: "#7AB69344 !important",
    },
    "& .MuiButtonBase-root-MuiMenuItem-root .Mui-selected": {
      color: "red",
    },
    "& .Mui-checked ": {
      color: "#7AB693 !important",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      height: "50vh",
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function SelectedTeam({
  teams,
  teamLoading,
  selectedCandidates,
  setSelectedCandidates,
  setSelectedCandidateDetail,
  selectedTeam,
  setSelectedTeam,
}) {
  const [teamDetails, setTeamDetails] = useState({
    candidates: [],
  });
  const [loading, setLoading] = useState(false);
  const classes = useStyle();
  const getTeamInfo = async (id) => {
    setLoading(true);
    const res = await getTeamById({ id: id });
    if (res?.status === 200 && res?.data) {
      setTeamDetails(res?.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    setSelectedCandidates([])
    getTeamInfo(selectedTeam);
  }, [selectedTeam]);

  useEffect(() => {
    let selectedWithDetail = teamDetails.candidates.filter((candidate) => {
      return selectedCandidates.includes(candidate?.candidate_profile_id?._id);
    });
    setSelectedCandidateDetail(selectedWithDetail);
  }, [selectedCandidates]);
  return (
    <InfoBoxes
      hasIcon={true}
      title="Selected team"
      containerStyle="xl:max-h-[80vh] max-h-[60vh] overflow-y-auto overflow-hidden"
    >
      {/*  max-h-[40vh] scroll-y-auto overflow-hidden */}

      <div>
        <FormControl
          sx={{ margin: "0px", minwidth: 200, width: "100%" }}
          className={classes.select}
        >
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            value={selectedTeam}
            displayEmpty
            onChange={(e) => setSelectedTeam(e.target.value)}
            input={<OutlinedInput label="Select a team" />}
            MenuProps={MenuProps}
            className={classes.select}
            disabled={teamLoading}
            sx={{
              padding: "20px 15px",
              borderRadius: "10px",
              zIndex: "200",
            }}
            renderValue={(selected) => {
              if (![null, undefined, ""].includes(selected)) {
                return teams.filter((team) => team._id === selected)[0]
                  .team_name;
              }
              return <span style={{ color: "#999" }}>Select a team</span>;
            }}
          >
            {teams.map((team, index) => (
              <MenuItem
                key={index}
                value={team._id}
                className={classes.menuItem}
              >
                {team.team_name}
              </MenuItem>
            ))}
          </Select>
          {/* <span
            className={`absolute top-[52%] left-[20px] text-[14px] text-[#828282] 
              ${personName.length !== 0 ? "hidden" : "block"} `}
          >
            Select Team Members
          </span>
          <span
            className={`absolute top-[52%] right-[20px] text-[14px] text-[#828282] cursor-pointer`}
          >
            <SearchIcon sx={{ color: "#7AB794", zIndex: "100" }} />
          </span> */}
        </FormControl>
      </div>
      {/* <SuccessBtn
        onClick={() => {
          setSelectedCandidates(personName);
        }}
      >
        Add
      </SuccessBtn>

      <div className="mt-[50px]">
        {candidateList
          .filter((candidate) => selectedCandidates.includes(candidate._id))
          .map((candidate) => (
            <HorizontalProfileInfo
              src={
                candidate?.candidate_profile_id?.candidate_picture_upload_path
              }
              alt="candidate"
              name={[
                candidate?.candidate_profile_id.first_name,
                candidate?.candidate_profile_id?.last_name,
              ].join(" ")}
              subName={candidate?.candidate_profile_id?.designation}
            />
          ))}
      </div> */}
      <div className="mt-[50px]">
        {loading ? (
          <CircularProgress />
        ) : (
          teamDetails.candidates.map((candidate, id) => {
            return (
              <ProfileCheckbox
                key={id}
                id={id}
                candidate={candidate}
                setSelectedCandidates={setSelectedCandidates}
                controlled={true}
                selectedCandidates={selectedCandidates}
              />
            );
          })
        )}
      </div>
    </InfoBoxes>
  );
}

export default SelectedTeam;
