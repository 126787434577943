import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ChangePassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate('/coaching/login', {
      state: { isResetPassword: true, token: params.token },
    });
  }, []);

  return null;
};

export default ChangePassword;
