import React, { useState } from "react";
import WideModal from "../sharedComponent/WideModal";
import TeamDynamicsRefChart from "../.../../../../../../../assets/team_dynamics_ref.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import SuccessBtn from "../../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import SecondaryBtn from "../../../../../sharedComponents/buttons/secondaryBtn/SecondaryBtn";

function TeamDynamicsChart({ open, setOpen }) {
  const [swiperObj, setSwiperObj] = useState(null);
  return (
    <WideModal title="Team Chemistry Introduction" open={open} setOpen={setOpen}>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        loop={false}
        pagination={{
          clickable: true,
        }}
        onSwiper={setSwiperObj}
        modules={[Pagination, Navigation]}
        className="team-dynamics-modal-swiper"
      >
        <SwiperSlide>
          <div>
            <p>
              TRIPA's <strong>Team Chemistry</strong> tool uses the DISC human
              assessment framework that will help you identify the unconscious
              psychological forces that influence your team's behaviour and
              performance.
            </p>
            <p>
              Team chemistry tools can help you build the ultimate team and avoid
              and frequently address team misalignment, conflicts, and
              communications issues common in the workplace. TRIPA's team
              chemistry tool is your ally that will help you lead every team
              member to efficiently work together by identifying, understanding,
              and leveraging your team's strengths.
            </p>
            <p>
              The DISC, four behaviour styles framework, are defined as follows:
            </p>
            <p>
              "<span style={{ color: "#C43E2A" }}>D</span>" Decisive: How people
              approach <strong>problems, challenges,</strong> and 
              <strong> make decisions.</strong>
            </p>
            <p>
              "<span style={{ color: "#E4C111" }}>I</span>" Interactive: How
              people tend to interact and handle situations with other{" "}
              <strong>people, share opinions,</strong> and
              <strong> contacts.</strong>
            </p>
            <p>
              "<span style={{ color: "#AAD18D" }}>S</span>" Stabilising: How
              people tend to
              <strong> pace</strong> things and
              <strong> consistency</strong> in their environment.
            </p>
            <p>
              "<span style={{ color: "#4775C2" }}>C</span>" Cautious: How people
              react to established
              <strong>protocol, procedures, standards,</strong> and
              <strong> constraints.</strong>
            </p>
            <p>
              To take maximum advantage of TRIPA's Team Chemistry report, you may
              need to get familiar with the DISC four behaviour styles and
              discuss it with one of our coaches or consultant for debriefing or
              guidance.
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span></span>
              <SuccessBtn onClick={() => swiperObj.slideNext()}>
                Next
              </SuccessBtn>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <p>
              This radar plotting will help you identify members of your team's
              similarities and differences in behavior and communication styles,
              their strengths, and areas of opportunity.
            </p>
            <img
              src={TeamDynamicsRefChart}
              alt="Team Chemistry Ref Chart"
              style={{ maxWidth: "100%" }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SecondaryBtn onClick={() => swiperObj.slidePrev()}>
                Back
              </SecondaryBtn>
              <SuccessBtn onClick={() => setOpen(false)}>Done</SuccessBtn>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </WideModal>
  );
}

export default TeamDynamicsChart;
