import { createReducer } from "@reduxjs/toolkit"
import { fetchBehaviour, updateBehaviour } from "../actions/behaviourData"

const initBehaviour = {}

const userReducer = createReducer(initBehaviour, (builder) => {
  builder
    .addCase(updateBehaviour, (state, action) => {
      state = {...state, ...action.payload}
    })
    .addCase(fetchBehaviour.fulfilled, (state, action) => {
      return action.payload
    })
})

export default userReducer;