import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircleProgressBar from "../../../sharedComponents/circleProgressBar/CircleProgressBar";
import IconGreen from "../../../../assets/characteristicIconGreen.svg";
import TickText from "../../../sharedComponents/tickText/TickText";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Steady({ setOpenModal, data }) {
  const content = useSelector(
    (state) =>
      state.user?.assessments
        ?.find(
          ({ assessment }) =>
            assessment?.assessment_type === 'BehaviorAssessment'
        )
        ?.assessment?.assessment_content?.score?.find(({ type }) => type == 'S')
        ?.statements
  );
  const [allData, setAllData] = useState({})
  useEffect(() => {
    setAllData(data.filter(i => i.type == "S")[0])
  }, [data])
  return (
    <div className="pt-4">
      <p className="text-green-700 font-bold text-2xl mb-2">Steady</p>
      <div className="text-base font-bold text-pirmaryBlue">
        Approach to the pace of the work environment.
      </div>
      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="text-base lg:col-span-8 col-span-1 text-pirmaryBlue mt-4 mb-10 pr-4">
          <span className="font-bold text-green-700">HIGHER S </span>Individuals with higher S scores prefer a controlled, deliberate, and predictable work environment. They place a premium on the security of their work situation and, as a result, may have longer tenure in a position compared to individuals with other behavioral styles. They are great listeners and exhibit patience when coaching and teaching others on their team.
        </div>
        <div className=" lg:col-span-2 col-span-1 px-4 py-4 justify-self-center">
          <CircleProgressBar percentage={allData && Number(allData?.value)} pathColor="#008000" />
        </div>
      </div>
      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="text-base lg:col-span-8 col-span-1 text-pirmaryBlue mt-4 mb-10">
          <span className="font-bold text-green-700">LOWER S </span> individuals with lower S scores prefer a flexible, chemistry, and unstructured work environment. They value the freedom to express themselves and switch quickly between different activities. As a result, they may become bored with the routine that provides security to individuals with higher S scores. They seek opportunities that offer high urgency and activity and prefer spontaneity.
        </div>
        <div className=" lg:col-span-2 col-span-1 px-4 py-4 justify-self-center">
          <CircleProgressBar percentage={allData && Number(allData?.adaptiveValue)} pathColor="grey" />
        </div>
      </div>

      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="lg:col-span-8 col-span-1 mt-4 mb-10 lg:w-[90%]">
          {content?.length && (
            <>
              <div className="text-base font-bold mb-4">{content[0]}</div>
              {content.slice(1, content.length - 1).map((data, i) => <TickText key={i} text={data} />)}
            </>
          )}

          <div className=" text-secondaryBalck text-sm mt-4">
            * High score doesn’t mean good, and a low score doesn’t mean bad, as
            this is a spectrum or continuum of BEHAVIORAL TRAITS.
          </div>
        </div>
        <div
          className="lg:col-span-2 col-span-1 mt-4 mb-10 flex flex-col
          items-center text-center cursor-pointer"
          onClick={() => setOpenModal(true)}
        >
          <div className="w-[100px] h-[100px]">
            <img src={IconGreen} alt="" className="w-full" />
          </div>
          <p className="text-sm font-bold text-pirmaryBlue mt-4">
            Observable characteristic of High <br />{" "}
            <span className="text-[#008000]">Steady</span> behavior <Tooltip placement="bottom"
              title={
                <div className="rounded-md">
                  <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                    Stabilizer Behavior
                  </div>
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Stabilizers are characterized by their calmness, consistency, patience, and resilience. They're adept at problem-solving, supportive, diplomatic, and have strong organizational skills. Their long-term focus, sense of responsibility, and high emotional intelligence help maintain a balanced, harmonious environment, making them reliable team members.
                  </div>
                </div>
              }
              arrow
            >
              <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
            </Tooltip>
          </p>
        </div>
      </div>
    </div>
  );
}
