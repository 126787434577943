import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getPersonalBudget } from "../../../../api/jc";

export const useStyle = makeStyles((theme) => ({
  root: {},

  stepper: {
    "&.MuiStepper-root": {
      justifyContent: "center",
      [theme.breakpoints.down("lg")]: {
        alignItems: "start",
        flexDirection: "column !important",
        paddingLeft: "20px",
      },
    },
  },
  step: {
    position: "relative",
    "&.MuiStep-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .MuiStepIcon-root": {
      color: "#f8f9fa",
      border: "1px solid #B9C0C4",
      borderRadius: "50%",
      width: "28px",
      height: "28px",
    },
    "& .MuiStepIcon-text": {
      fill: "#828282",
      size: "16px",
    },
    "& .Mui-active .MuiStepIcon-text": { fill: "#7AB794 !important" },
    "& .Mui-active": {
      color: "#ffffff !important",
    },
    "& .MuiStepIcon-root.Mui-active ": {
      color: "#ffffff !important",
      border: "1px solid #7AB794",
    },
    "& .Mui-completed": { color: "#7AB794 !important" },
    "& .MuiStepIcon-root.Mui-completed ": {
      border: "1px solid #7AB794",
    },
  },
  stepLabel: {
    "& .MuiStepLabel-label": {
      color: "#828282 !important",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      width: "90%",
      alignItems: "start !important",
    },
    "&.MuiStepLabel-root": {
      alignItems: "start !important",
    },
  },
  leftArrow: {
    "&.MuiSvgIcon-fontSizeMedium": {
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
  },

  linestepper: {
    "&.MuiStepper-root": {
      justifyContent: "center",
      [theme.breakpoints.down("lg")]: {
        alignItems: "start",
        flexDirection: "column !important",
        paddingLeft: "20px",
      },
    },
  },
  linestep: {
    position: "relative",
    "&.MuiStep-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiStepIcon-root": {
      color: "#f8f9fa",
      border: "1px solid #B9C0C4",
      borderRadius: "50%",
      width: "28px",
      height: "28px",
    },
    "& .MuiStepIcon-text": {
      fill: "#828282",
      size: "16px",
    },

    "& .Mui-active .MuiStepIcon-text": { fill: "#7AB794 !important" },
    "& .Mui-active": {
      color: "#ffffff !important",
    },
    "& .MuiStepIcon-root.Mui-active ": {
      color: "#ffffff !important",
      border: "1px solid #7AB794",
    },
    "& .Mui-completed": { color: "#7AB794 !important" },
    "& .MuiStepIcon-root.Mui-completed ": {
      border: "1px solid #7AB794",
    },
    "& .MuiStepConnector-root": {
      position: "absolute",
      top: "12px",
      left: "calc(-75% + 40px)",
      right: "calc(85% + 100px)",
      [theme.breakpoints.down("lg")]: {
        position: "static",
      },
    },
  },
  linestepLabel: {
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      textAlign: "left",
      paddingLeft: 40,
    },
    "& .MuiStepLabel-label": {
      color: "#828282 !important",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      width: "95%",
      alignItems: "start !important",
    },
    "&.MuiStepLabel-root": {
      alignItems: "start !important",
    },
    "& .MuiStepLabel-iconContainer": {},
  },
}));

const steps = [
  "Enter your income information in the two income tables",
  "Enter your expenses. Use the Monthly expenses table for recurring espenses.",
  "Enter the starting balance in the January column on the Annual Budget table.",
];

export const BudgetStepperArrow = ({ step }) => {
  const stepperStyle = useStyle();
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "33px",
        marginBottom: "33px",
      }}
    >
      <Stepper activeStep={step} className={stepperStyle.stepper}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps} className={stepperStyle.step}>
              <StepLabel {...labelProps} className={stepperStyle.stepLabel}>
                <h5 className="text-base text-success font-bold mb-2">
                  Step {index + 1}
                </h5>
                {label}
              </StepLabel>
              {index !== steps.length - 1 && (
                <span
                  className={`${
                    index < step ? "text-success" : "text-[#B9C0C480]"
                  }`}
                >
                  <ArrowForwardIosIcon
                    className={stepperStyle.leftArrow}
                    sx={{ fontSize: "38px" }}
                  />
                </span>
              )}
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
export const BudgetStepperLine = ({ step }) => {
  const stepperStyle = useStyle();
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "33px",
        marginBottom: "33px",
      }}
    >
      <Stepper
        activeStep={step}
        alternativeLabel
        className={stepperStyle.linestepper}
      >
        {steps.map((label, index) => (
          <Step key={label} className={stepperStyle.linestep}>
            <h5 className="text-base text-success font-bold mb-2 absolute top-0 left-[45px]">
              Step {index + 1}
            </h5>
            <StepLabel className={stepperStyle.linestepLabel}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const tableStyle = makeStyles({
  table: {
    "& .MuiTableRow-head": {
      "& .MuiTableCell-head": {
        background: "#F4F6F8",
        fontWeight: "700",
        color: "#384259",
        fontSize: "16px",
      },
    },
  },
  pagination: {
    margin: "24px 0px 30px 0px",

    "& .MuiSvgIcon-fontSizeMedium": {
      zIndex: 200,
      background: "red",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      opacity: 0,
    },
    "& .MuiPaginationItem-page": {
      border: " 1px solid #E5E5E5",
      borderRadius: "0px",
    },
    "& .Mui-selected": {
      background: "#00000000 !important",
      borderRadius: "0px",
      border: "1px solid #7AB794",
      ":hover": {
        // backgroundColor: "transparent",
      },
    },
  },
});

export const MakeBold = ({ str }) => {
  return <span className="text-[14px] text-pirmaryBlue font-bold">{str}</span>;
};

export const BudgetTable = ({ columns, rows }) => {
  // const [page, setPage] = React.useState(1);
  const page = 1;
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rowsPerPage = 10;
  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const classes = tableStyle();
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{ maxHeight: 800, width: "100% important" }}
        className={classes.table}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      let value;

                      value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export const handleGetMonthlyNetIncome = (data, setMonthlyIncomeData) => {
  if(data?.monthly_net_income?.length > 0) {
    let monthlyData = data.monthly_net_income.map(
      ({ income_type: incomeType = '', amount = '' }) => {
        return {
          incomeType,
          amount: `US $${amount}`,
        };
      }
    );
    let totalAmount = data.monthly_net_income
    .map((data) => Number(data.amount))
    .reduce((prev, next) => prev + next, 0)
    .toFixed(2)
    monthlyData.push({
      incomeType: <MakeBold str='Available cash' />,
      amount: <MakeBold str={`US $${totalAmount}`} />,
    });
    setMonthlyIncomeData(monthlyData);
  } else setMonthlyIncomeData([])
}

export const handleGetAdditionalIncome = (data, setAdditionalIncomeData) => {
  if(data?.additional_income?.length > 0) {
    let additionalData = data.additional_income.map(
      ({ details = '', amount = '' }) => {
        return {
          details,
          month: data?.month,
          amount: `US $${amount}`,
        };
      }
    );
    let totalAmount = data.additional_income
    .map((data) => Number(data.amount))
    .reduce((prev, next) => prev + next, 0)
    .toFixed(2)
    additionalData.push({
      details: <MakeBold str='Total Additional income' />,
      month: '',
      amount: <MakeBold str={`US $${totalAmount}`} />
    });
    setAdditionalIncomeData(additionalData);
  } else setAdditionalIncomeData([])
}

export const handleGetMonthlyExpenses = (data, setMonthlyExpenseData) => {
  if(data?.monthly_expenses?.length > 0) {
    let monthlyData = data.monthly_expenses.map(
      ({ expensess_name: espenses = '', cost = '' }) => {
        return {
          espenses,
          cost: `US $${cost}`,
        };
      }
    );
    let totalAmount = data.monthly_expenses
    .map((data) => Number(data.cost))
    .reduce((prev, next) => prev + next, 0)
    .toFixed(2)
    monthlyData.push({
      espenses: <MakeBold str='Total expenses' />,
      cost: <MakeBold str={`US $${totalAmount}`} />,
    });
    setMonthlyExpenseData(monthlyData);
  } else setMonthlyExpenseData([])
}

export const handleGetPlannedExpenses = (data, setPlannedExpensesData) => {
  if(data?.planned_expenses?.length > 0) {
    let additionalData = data.planned_expenses.map(
      ({ expenditure = '', amount = '' }) => {
        return {
          expenditure,
          month: data?.month,
          amount: `US $${amount}`,
        };
      }
    );
    let totalAmount = data.planned_expenses
    .map((data) => Number(data.amount))
    .reduce((prev, next) => prev + next, 0)
    .toFixed(2)
    additionalData.push({
      expenditure: <MakeBold str='Total Planned expenses' />,
      month: '',
      amount: <MakeBold str={`US $${totalAmount}`} />
    });
    setPlannedExpensesData(additionalData);
  } else setPlannedExpensesData([])
}

export const getBudgetData = async ( year, setAllData ) => {
  try {
    const res = await getPersonalBudget({ year });
    if (res?.data?.length) {
      let data = [
        {
          incomeExpenses: <MakeBold str='Available cash' />,
        },
        {
          incomeExpenses: <MakeBold str='Additional income' />,
        },
        {
          incomeExpenses: <MakeBold str='Monthly Expenses' />,
        },
        {
          incomeExpenses: <MakeBold str='Planned Expenses' />,
        },
        {
          incomeExpenses: <MakeBold str='Savings' />,
        },
      ];
      res.data.forEach(
        ({
          month = '',
          monthly_net_income = [],
          additional_income = [],
          monthly_expenses = [],
          planned_expenses = [],
        }) => {
          let totalMonthlyIncome = monthly_net_income?.length
            ? monthly_net_income
                .map(({ amount = 0 }) => Number(amount))
                .reduce((prev, next) => prev + next, 0)
            : 0;
          let totalAdditionalIncome = additional_income?.length
            ? additional_income
                .map(({ amount = 0 }) => Number(amount))
                .reduce((prev, next) => prev + next, 0)
            : 0;
          let totalMonthlyExpenses = monthly_expenses?.length
            ? monthly_expenses
                .map(({ cost = 0 }) => Number(cost))
                .reduce((prev, next) => prev + next, 0)
            : 0;
          let totalPlannedExpenses = planned_expenses?.length
            ? planned_expenses
                .map(({ amount = 0 }) => Number(amount))
                .reduce((prev, next) => prev + next, 0)
            : 0;
          let totalSavings =
            totalMonthlyIncome +
            totalAdditionalIncome -
            totalMonthlyExpenses -
            totalPlannedExpenses;
          data[0] = {
            ...data[0],
            [month]: `US $${totalMonthlyIncome}`,
          };
          data[1] = {
            ...data[1],
            [month]: `US $${totalAdditionalIncome}`,
          };
          data[2] = {
            ...data[2],
            [month]: `US $${totalMonthlyExpenses}`,
          };
          data[3] = {
            ...data[3],
            [month]: `US $${totalPlannedExpenses}`,
          };
          data[4] = {
            ...data[4],
            [month]: <MakeBold str={`US $${totalSavings.toFixed(2)}`} />,
          };
        }
      );
      setAllData(data || []);
    }
  } catch (error) {
    console.error(error);
  }
};
