import React, { useState } from "react";
import { useEffect } from "react";
import { BehaviorStepper } from "../reusableComponents";

import WhiteBox from "../shared/WhiteBox";
import Button from "@mui/material/Button";
// import {useEffect, useState} from "@types/react";
import { saveAssessment1 } from "../../../../../api/jc";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DnD from "../basicComponents/dropArea";
import config from "../../../../../config/config";

function RoleBasedCompetenciesStepSix() {
  const [enable, setEnable] = useState(false);
  const [answerList, setAnswerList] = useState([]);
  const [mappedQuestions, setMappedQuestions] = useState([]);
  const [iFrameLink, setIFrameLink] = useState("");
  const questions = useSelector((state) => state.behaviour.roleBasedAssessment);
  const choice = useSelector((state) => state.steps.roleBasedChoiceStep6);
  const navigate = useNavigate();

  useEffect(() => {
    if (questions?.length) {
      setMappedQuestions(questions.map((data) => data?.question));
    }
  }, [questions]);

  const handleSubmit = async () => {
    try {
      let data = localStorage.getItem("accessData");
      data = data && JSON.parse(data);
      let code = data?.isRoleBasedCompetanceAssessmentCompleted || "";
      let fName = data?.firstNameAndLastName?.first_name || "Abc";
      let lName = data?.firstNameAndLastName?.last_name || "Abc";
      let gender = data?.firstNameAndLastName?.gender === "male" ? "m" : "f";
      let email = data?.email_address || "";
      let aiReportId = data?.assessmentType?.ai_report_id || 208;

      const bodyFormData = new FormData();
      await answerList?.forEach((data) => {
        bodyFormData.append("answer[]", getAnswerValue(data));
      });
      await bodyFormData.append("code", code);
      // bodyFormData.append('page', 1)
      // bodyFormData.append('lang', 'en')
      // bodyFormData.append('override_bad_rho', false)
      await bodyFormData.append("fname", fName);
      await bodyFormData.append("lname", lName);
      await bodyFormData.append("email", email);
      await bodyFormData.append("gender", gender);

      await bodyFormData.append("assessmentCodeType", aiReportId);
      const res = await saveAssessment1(bodyFormData);
      res?.status === 200 && navigate("/assessment/rolebased/part2");
    } catch (error) {
      console.error(error);
    }
  };

  const getAnswerValue = (data) => {
    switch (data) {
      case "A new car":
        return 0;
      case "A technical improvement":
        return 1;
      case "A foolish thought":
        return 2;
      case "A blunder":
        return 3;
      case "A wreck":
        return 4;
      case "An award for a good deed":
        return 5;
      case "Poisoning the city water":
        return 6;
      case "Imprisoning an innocent person":
        return 7;
      case "A short circuit":
        return 8;
      case "A token of love":
        return 9;
      case "A lover's embrace":
        return 10;
      case "Torturing a person":
        return 11;
      case "A life of adventure":
        return 12;
      case "A madman":
        return 13;
      case "A telephone":
        return 14;
      case "Prostitution":
        return 15;
      case "Justice":
        return 16;
      case "A decoration for bravery":
        return 17;
      default:
        return 0;
    }
  };

  React.useEffect(() => {
    let data = localStorage.getItem("accessData");
    data = data && JSON.parse(data);
    let code = data?.isRoleBasedAssessmentCompleted || "";
    let fName = data?.firstNameAndLastName?.first_name || "Abc";
    let lName = data?.firstNameAndLastName?.last_name || "Abc";
    let gender = data?.firstNameAndLastName?.gender === "male" ? "m" : "f";
    let email = data?.email_address || "";
    let aiReportId = data?.assessmentType?.ai_report_id || 208;
    let iFrameSrc = `${config.assessmentUrlApi}/remote/instrument/AI/${code}?fname=${fName}&lname=${lName}&gender=${gender}&email=${email}&ai_report=${aiReportId}&completeURL=${window.location.origin}/assessment/rolebased/complete/?code=`;
    setIFrameLink(iFrameSrc);
  }, []);

  return (
    <>
      <BehaviorStepper step={2} />
      <WhiteBox title="Role-based competencies: Part 1">
        <p className="text-[#384259] text-base mb-5">
          Please evaluate the statements based on their importance rather than goodness or badness.
        </p>

        <p className="text-[#384259] text-base mb-5">
          To begin, click and drag the statement that represents the highest value to the right-hand column.
        </p>

        <p className="text-[#384259] text-base mb-5">
          Next, you can move the statement representing the next best value directly below the first one.
        </p>

        <p className="text-[#384259] text-base mb-5">
          Continue moving all the items from left to right  to reflect your opinion on how good or bad they are (top <span className="font-bold">- Positive</span> to bottom <span className="font-bold">- Negative</span>).
        </p>

        <p className="text-[#384259] text-base mb-5">
          When satisfied with your ranking, click "<span className="font-bold">View Part 2</span>" to proceed.
        </p>
      
        <DnD setEnable={setEnable} setAnswerList={setAnswerList} />
      </WhiteBox>
      <div className="max-w-[950px] w-full mx-auto my-5 flex items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate("/assessment/rolebased/start")}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#E3E6E9",
              fontSize: "16px",
              color: "#828282",
            },
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => {
            if (!enable) {
              toast.warning("Please Move All Items To Hear!!");
            } else {
              handleSubmit();
            }
          }}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#7AB794",
              fontSize: "16px",
            },
          }}
        >
          View Part 2
        </Button>
      </div>
    </>
  );
}

export default RoleBasedCompetenciesStepSix;
