import React from "react";
import Modal from "../../../../../../sharedComponents/modal/Modal";
import SlideBox from "../../../../../../sharedComponents/slideBox/SlideBox";

import Stpe1 from "./stpe1";
import Step2 from "./step2";

const list = [Stpe1, Step2];

function Introductions({ open, setOpen }) {
  return (
    <SlideBox
      title="Team chemistry introduction"
      open={open}
      setOpen={setOpen}
      componentList={list}
    />
  );
}

export default Introductions;
