import React from "react";
import SlideBox from "../../../../sharedComponents/slideBox/SlideBox";
import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
import { toast } from "react-toastify";

function PurposeStatement({
  open,
  setOpen,
  doneMethod,
  setPurposeStatementModal,
}) {
  const [agreement, setAgreement] = React.useState(false);

  const handleDoneMethod = () => {
    if (agreement) {
      setOpen(false);
      setPurposeStatementModal(true);
    } else {
      toast.error("Please checked the agreement (Checkbox)");
    }
  };
  const list = [
    <Slide1 />,
    <Slide2 />,
    <Slide3 agreement={agreement} setAgreement={setAgreement} />,
  ];
  return (
    <SlideBox
      title="Personal purpose statement (PPS)"
      componentList={list}
      open={open}
      setOpen={setOpen}
      doneMethod={handleDoneMethod}
    />
  );
}

export default PurposeStatement;
