import React from "react";

function PurpleBtn({children, onClick, customStyle}) {
  return (
    <button onClick={onClick}
      className="bg-[#B07FAA] hover:bg-[#da63cc] py-[9px] px-[32px] d-flex flex-row 
      items-center rounded text-white text-base transition-all"
      style={customStyle}
    >
      {children}
    </button>
  );
}

export default PurpleBtn;
