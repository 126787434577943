export const getCandidateBehaviorForRadarPlotting = (cell) => {
    let candidateBehaviorDetails = ''
    let color = ''
    if(cell){
      if(cell>=11 && cell<=17){
        candidateBehaviorDetails = 'Convincing, straightforward, goal/result oriented, assertive, embrace and pursue challenges. But on the other hand, decisive people can come across as aggressive, impatient, and intense desire to lead.'
        color = '#ff0000'
      }else if(cell>=21 && cell<=27){
        candidateBehaviorDetails = 'Persuasive, assertive, and bold. Enjoy embracing new concepts, often a mover-and-shaker with high energy, engagement, and encouragement. Can be highly outgoing.'
        color = '#d37c1e'
      }else if(cell>=31 && cell<=37){
        candidateBehaviorDetails = 'Exceedingly outgoing, talkative, persuasive, influential people-oriented, and entertainer. Strong communication skills, enjoy variety and have a high level of optimism.'
        color = '#e5c311'
      }else if(cell>=41 && cell<=47){
        candidateBehaviorDetails = 'Laid-back, easy-going, creates a strong will, strong customer service skills, and reassuring—pleasant, supportive, persuasive, reliable team player. '
        color = '#c7c94e'
      }else if(cell>=51 && cell<=57){
        candidateBehaviorDetails = 'Balance, team-oriented, very patient, and favors stability and structure. Not a risk-taker and likes to operate at an even steady pace.'
        color = '#008000'
      }else if(cell>=61 && cell<=67){
        candidateBehaviorDetails = 'Very patient, balanced, value facts/data, humble, self-effacing, reserved, composed, and favor stability, structure, and rules. Dislike ambiguity and confusion.'
        color = '#75a0a8'
      }else if(cell>=71 && cell<=77){
        candidateBehaviorDetails = 'Precise, accurate, and trust in the values of structures, standards, and rules. See the value and follow the rules. Data, fact, and analysis based.'
        color = '#0000ff'
      }else if(cell>=81 && cell<=87){
        candidateBehaviorDetails = 'Efficient, determined, purposeful, analytical, organized, factual, practical, innovative, and aware of the consequences of their actions.'
        color = '#845175'
      }else if(cell==91){
        candidateBehaviorDetails = 'Exceedingly outgoing, talkative, persuasive, influential people-oriented, and entertainer. Strong communication skills, enjoy variety and have a high level of optimism.'
        color = '#e5c311'
      }else if(cell==93){
        candidateBehaviorDetails = 'Balance, team-oriented, very patient, and favors stability and structure. Not a risk-taker and likes to operate at an even steady pace.'
        color = '#008000'
      }else if(cell==95){
        candidateBehaviorDetails = 'Precise, accurate, and trust in the values of structures, standards, and rules. See the value and follow the rules. Data, fact, and analysis based.'
        color = '#0000ff'
      }else if(cell==97){
        candidateBehaviorDetails = 'Convincing, straightforward, goal/result oriented, assertive, embrace and pursue challenges. But on the other hand, decisive people can come across as aggressive, impatient, and intense desire to lead.'
        color = '#ff0000'
      }
    }
    return {
      'details':candidateBehaviorDetails,
      'color':color
    }
  }

  export const getCandidateBehaviorDetailsForRadarPlotting = (score) => {
    let candidateBehaviorDetails = ''
    let color = ''
    if(score){
      if(score>=0 && score<=90){
        candidateBehaviorDetails = 'Convincing, straightforward, goal/result oriented, assertive, embrace and pursue challenges. But on the other hand, decisive people can come across as aggressive, impatient, and intense desire to lead.'
        color = '#C7C94E'
      } else if(score>=91 && score<=180){
        candidateBehaviorDetails = 'Laid-back, easy-going, creates a strong will, strong customer service skills, and reassuring—pleasant, supportive, persuasive, reliable team player. '
        color = '#75A0A8'
      }else if(score>=181 && score<=270){
        candidateBehaviorDetails = 'Precise, accurate, and trust in the values of structures, standards, and rules. See the value and follow the rules. Data, fact, and analysis based.'
        color = '#0000FF'
      }else if(score>=271 && score<=360){
        candidateBehaviorDetails = 'Balance, team-oriented, very patient, and favors stability and structure. Not a risk-taker and likes to operate at an even steady pace.'
        color = '#FF0000'
      } else {
        candidateBehaviorDetails = 'Convincing, straightforward, goal/result oriented, assertive, embrace and pursue challenges. But on the other hand, decisive people can come across as aggressive, impatient, and intense desire to lead.'
        color = '#C7C94E'
      }
    }
    return {
      'details':candidateBehaviorDetails,
      'color':color
    }
  }

  export const getCandidateTeamCompatibility = (behaviorType) => {
    let candidateBehaviorDetails = ''
    let color = ''
    if(behaviorType){
      if(behaviorType === "Interactive"){
        candidateBehaviorDetails = 'Individuals with this default behavior trait tend to be exceedingly outgoing and persuasive, thriving on interactions with people, with a knack for communication and an optimistic outlook. Yet, their talkative nature can sometimes overshadow their focus on details.';
        color ='#C7C94E';
      } else if(behaviorType === "Steady"){
        candidateBehaviorDetails = 'Individuals with this default behavior trait tend to be balanced and team-oriented, valuing patience and, stability and structure. They operate at an even pace, often avoiding risks.';
        color ='#75A0A8';
      } else if(behaviorType === "Cautious"){
        candidateBehaviorDetails = 'Individuals with this default behavior trait tend to be precise and value-driven individuals who trust in structures, standards, and rules. They rely on data, facts, and analysis, showing discomfort with ambiguity.';
        color ='#0000FF';
      } else if(behaviorType === "Decisive"){
        candidateBehaviorDetails = 'Individuals with this default behavior trait tend to be very convincing, straightforward, goal-oriented, and assertive individuals embracing challenges head-on. However, they can come across as aggressive or impatient due to their intense desire to lead.';
        color ='#FF0000';
      }
    }
    return {
      'details':candidateBehaviorDetails,
      'color':color
    }
  }