import React, { useState } from "react";
import { useEffect } from "react";
import { BehaviorStepper } from "../reusableComponents";

import WhiteBox from "../shared/WhiteBox";
import Button from "@mui/material/Button";
import TransferList from "../shared/TransferList";
// import {useEffect, useState} from "@types/react";
import { saveAssessment2, aiSubmit } from "../../../../../api/jc";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DnD from "../basicComponents/dropArea";

function RoleBasedCompetenciesStepSixB() {
  const [enable, setEnable] = useState(false);
  const [answerList, setAnswerList] = useState([]);
  const [mappedQuestions, setMappedQuestions] = useState([]);
  const questions = useSelector((state) => state.behaviour.roleBasedAssessment);
  const choice = useSelector((state) => state.steps.roleBasedChoice);
  const navigate = useNavigate();

  useEffect(() => {
    if (questions?.length) {
      setMappedQuestions(questions.map((data) => data?.question));
    }
  }, [questions]);

  const handleSubmit = async () => {
    try {
      let data = localStorage.getItem("accessData");
      data = data && JSON.parse(data);
      let code = data?.isRoleBasedAssessmentCompleted || "";

      let bodyFormData = new FormData();
      answerList?.forEach((data) => {
        bodyFormData.append("answer[]", getAnswerValue(data));
      });
      bodyFormData.append("code", code);
      await saveAssessment2(bodyFormData);

      let bodySubmitFormData = new FormData();
      bodySubmitFormData.append("code", code);
      bodySubmitFormData.append("lang", "en");
      bodySubmitFormData.append("json", true);
      bodySubmitFormData.append("override_bad_rho", true);
      const res = await aiSubmit(bodySubmitFormData);
      res?.status === 200 &&
        navigate("/assessment/rolebased/complete/?code=" + code);
    } catch (error) {
      console.error(error);
    }
  };

  const getAnswerValue = (data) => {
    switch (data) {
      case "I like my work, it does me good.":
        return 0;
      case "My mind is clear and makes me understand things.":
        return 1;
      case "My mind is not very clear and I don't understand things very well.":
        return 2;
      case "No matter how hard I work, I shall always feel frustrated.":
        return 3;
      case "My working conditions are poor and ruin my life.":
        return 4;
      case "I feel at home in the world.":
        return 5;
      case "I'm so unhappy I'm actually sick.":
        return 6;
      case "I'm so unhappy I can't think straight.":
        return 7;
      case "My work contributes nothing to the world.":
        return 8;
      case "My work brings out the best in me.":
        return 9;
      case "I love to be myself.":
        return 10;
      case "I hate to be myself.":
        return 11;
      case "My good spirits keep me in good health.":
        return 12;
      case "I don't understand things very well and that makes me unhappy.":
        return 13;
      case "The more clearly I think, the better I feel.":
        return 14;
      case "My work makes me unhappy.":
        return 15;
      case "My good spirits keep my mind clear.":
        return 16;
      case "My work adds to the beauty and harmony of the world.":
        return 17;
      default:
        return 0;
    }
  };

  return (
    <>
      <BehaviorStepper step={2} />
      <WhiteBox title="Role-based competencies: Part 2">

        <p className="text-[#384259] text-base mb-5">
          Please evaluate the statements based solely on which one you most agree or disagree with.
        </p>

        <p className="text-[#384259] text-base mb-5">
          To begin, click and drag the statement that you agree with the most to the right-hand column.
        </p>

        <p className="text-[#384259] text-base mb-5">
          Next, move the statement representing the most vigorous agreement below the first one.
        </p>

        <p className="text-[#384259] text-base mb-5">
          Continue moving all the items from left to right to reflect your level of agreement or disagreement with them (top - Strong agreement (<span className="font-bold">Positive</span>) to the bottom - Strong disagreement (<span className="font-bold">Negative</span>)).
        </p>
        
        <p className="text-[#384259] text-base mb-5">
          When satisfied with your ranking, click "<span className="font-bold">DONE</span>" to complete the assessment.
        </p>

        <p className="text-[#384259] text-base mb-5">
          Congratulations!
        </p>

        <p className="text-[#384259] text-base mb-5">
          You're only a few clicks away from accessing your comprehensive reports. Click "DONE" to discover your unique combination of  personal, practical, and analytical skills. We'll support you every step of your journey to growth and success.
        </p>
        
        <DnD
          setEnable={setEnable}
          setAnswerList={setAnswerList}
          isFirstPage={false}
        />
      </WhiteBox>
      <div className="max-w-[950px] w-full mx-auto my-5 flex items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate("/assessment/rolebased/step1")}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#E3E6E9",
              fontSize: "16px",
              color: "#828282",
            },
          }}
        >
          View Part 1
        </Button>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => {
            if (!enable) {
              toast.warning("Please Move All Items To Hear!!");
            } else {
              handleSubmit();
            }
          }}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#7AB794",
              fontSize: "16px",
            },
          }}
        >
          Done
        </Button>
      </div>
    </>
  );
}

export default RoleBasedCompetenciesStepSixB;
