import React from "react";
import WhiteBox from "../../../../../sharedComponents/whiteBox/WhiteBox";
import DarkBtn from "../../../../../sharedComponents/buttons/darkBtn/DarkBtn";
import PurpleBtn from "../../../../../sharedComponents/buttons/purpleBtn/PurpleBtn";
import SelectField from "../../../../../sharedComponents/selectField/SelectField";
import PasswordField from "../../../../../sharedComponents/PasswordField/PasswordField";

function AccountDetails({ data, handleChangeData, handleNext }) {
  return (
    <>
      <WhiteBox title="Account details">
        <form className="grid md:grid-cols-auto-fit-450 grid-cols-auto-fit-250 gap-4 mt-[24px]">
          <div className="flex flex-col">
            <label
              htmlFor="accountName"
              className="text-pirmaryBlue text-base font-normal"
            >
              Account name
            </label>
            <input
              type="text"
              name="accountName"
              value={data.accountName || ""}
              onChange={handleChangeData}
              id="accountName"
              placeholder="Account name"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="accountEmail"
              className="text-pirmaryBlue text-base font-normal"
            >
              Account email
            </label>
            <input
              type="text"
              name="accountEmail"
              value={data.accountEmail || ""}
              onChange={handleChangeData}
              id="accountEmail"
              placeholder="Account email"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="nameOfTheAccountAdmin"
              className="text-pirmaryBlue text-base font-normal"
            >
              Name of the account admin
            </label>
            <input
              type="text"
              name="nameOfTheAccountAdmin"
              value={data.nameOfTheAccountAdmin || ""}
              onChange={handleChangeData}
              id="nameOfTheAccountAdmin"
              placeholder="Name of the account admin"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="nameOfTheCompany"
              className="text-pirmaryBlue text-base font-normal"
            >
              Name of the company
            </label>
            <input
              type="text"
              name="nameOfTheCompany"
              value={data.nameOfTheCompany || ""}
              onChange={handleChangeData}
              id="nameOfTheCompany"
              placeholder="Name of the company"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="companyAddress"
              className="text-pirmaryBlue text-base font-normal"
            >
              Company address
            </label>
            <input
              type="text"
              name="companyAddress"
              value={data.companyAddress || ""}
              onChange={handleChangeData}
              id="companyAddress"
              placeholder="Company address"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="companyPhone"
              className="text-pirmaryBlue text-base font-normal"
            >
              Company phone
            </label>
            <input
              type="text"
              name="companyPhone"
              value={data.companyPhone || ""}
              onChange={handleChangeData}
              id="companyPhone"
              placeholder="Company phone"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
          <div className="">
            <PasswordField
              name={"password"}
              value={data.password || ""}
              label={"Password"}
              labelFor={"password"}
              id={"password"}
              onChange={handleChangeData}
              placeholder={"Password"}
              className={"focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"}
            />
          </div>
          <div className="">
            <PasswordField
              name={"confirmPassword"}
              value={data.confirmPassword || ""}
              label={"Confirm password"}
              labelFor={"confirmPassword"}
              id={"confirmPassword"}
              onChange={handleChangeData}
              placeholder={"Confirm password"}
              className={"focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"}
            />
          </div>

          <div className="">
            <SelectField
              selectItems={["Option 1", "Option 2", "Option 3"]}
              label="Company size"
              labelId="companySize"
              id="companySize"
              value={data.companySize}
              onChange={handleChangeData}
              name="companySize"
              placeholder="Company size"
              inputStyle={{ padding: "14px" }}
            />
          </div>
        </form>
      </WhiteBox>
      <div className="flex items-center justify-end">
        <DarkBtn>Cancel</DarkBtn>
        <PurpleBtn customStyle={{ marginLeft: "24px" }} onClick={handleNext}>
          Next
        </PurpleBtn>
      </div>
    </>
  );
}

export default AccountDetails;
