import React, {useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyEmailAddress } from "../../../../api/jc";

export default function VerifyEmail() {
    const params = useParams()
    const navigate = useNavigate();
    const handleVerify = () => {
        verifyEmailAddress(params.token).then((res) => {
            if (res) {
                toast.success("Mail verified successfully");
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            navigate('/coaching/login')
        })
    }
    useEffect(() => {
        handleVerify(params.token);
    }, []);

    return (
        <></>
    );
}

