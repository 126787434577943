import React from "react";
import BehavioralTraitsSlide1 from "../../../../assets/BehavioralTraitsSlide1.svg";

function Slide1() {
  return (
    <div className="flex md:flex-row flex-col items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <img src={BehavioralTraitsSlide1} alt="" className="w-full h-full" />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          Understanding your behavioral traits report
        </h5>
        <p className="text-base text-pirmaryBlue  mb-6">
          Introduction: This report is designed to provide valuable insights into your behavior style using the DISC self-assessment tool by psychologist William Moulton Marston. By understanding your natural and adaptive behavioral styles, success traits, areas for improvement, preferred learning and communication styles, and tips for staying motivated, you can improve your performance and achieve success.
        </p>
      </div>
    </div>
  );
}

export default Slide1;
