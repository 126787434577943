import React, { useEffect } from "react";

// layout
import TeamListing from "./TeamListing";
import SelectedTeam from "./SelectedTeam";
import PlottingLocPres from "./PlottingLocPres";
import TeamCompatibility from "./TeamCompatibility";

// modal
import IntroductionsModal from "./modals/introductions/Introductions";
import ChartModal from "./modals/TeamDynamicsChart";
import BehaviorChart from "./modals/behaviorChart/BehaviorChart";
import { getCandidatesByPool } from "../../../../../../api/hr";

function TeamDynamics() {
  const [introductionModal, setIntroductionModal] = React.useState(false);
  const [chartModal, setChartModal] = React.useState(false);
  const [behaviorChart, setBehaviorChart] = React.useState(false);

  const [selectedCandidates, setSelectedCandidates] = React.useState([]);
  const [selectedCandidatesDetail, setSelectedCandidateDetail] = React.useState(
    []
  );

  const [selectedTeam, setSelectedTeam] = React.useState(undefined);
  return (
    <>
      <div className="grid xl:grid-cols-3 2xl:grid-cols-4 grid-cols-1 gap-4">
        <div className="col-span-1 ">
          <TeamListing
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            selectedCandidates={selectedCandidates}
            setSelectedCandidates={setSelectedCandidates}
            setSelectedCandidateDetail={setSelectedCandidateDetail}
          />
        </div>

        <div className="col-span-2">
          <PlottingLocPres
            selectedCandidatesDetail={selectedCandidatesDetail}
            setBehaviorChart={setBehaviorChart}
            chartModal={chartModal}
            behaviorChart={behaviorChart}
            setChartModal={setChartModal}
          />
        </div>

        <div className="col-span-1 ">
          <TeamCompatibility
            selectedTeam={selectedTeam}
            selectedCandidatesDetail={selectedCandidatesDetail}
          />
        </div>
      </div>

      {/* modals */}
      <IntroductionsModal
        open={introductionModal}
        setOpen={setIntroductionModal}
      />
      <ChartModal open={chartModal} setOpen={setChartModal} />
      <BehaviorChart selectedCandidatesDetail={selectedCandidatesDetail} open={behaviorChart} setOpen={setBehaviorChart} />
    </>
  );
}

export default TeamDynamics;
