import React from "react";

function DnagerBtn({children, onClick, disabled = false}) {
  return (
    <button onClick={onClick}
      className={`bg-danger bg-[#ef5757] py-[9px] px-[32px] d-flex flex-row 
      items-center rounded text-white text-base font-bold ${
        disabled && "cursor-not-allowed"
      }`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default DnagerBtn;
