import React from "react";
import BoxDescriptionInfo from "../../behavioralTraits/sharedcomponents/boxDescriptionInfo";
import { ReactComponent as Economic } from "../../../../assets/Economic.svg";
import { useSelector } from "react-redux";
import getTitle from "../reusableComponent";

// const list1 = [
//   "You have the ability to identify with and understand other individuals who have both a lower or higher Economic drive than your own.",
//   "Regarding the Economic score, you would be considered rather practical and realistic about money.",
//   "The drive and motivational factors which tend to lead people who score like you should be measured against other peaks on the Values graph.",
//   `The pattern of responses when compared to others in the business community indicates that there would be no visible "greed factor" in the interaction you have with others.`,
//   "This score should not be confused with average economic achievement. Many executives and others who score in this area may have already achieved substantial economic goals of their own. As a result, money itself may no longer motivate like it used to.",
// ];
// const list2 = [
//   "Remember that your score range is near the national mean for Economic drive and that you don't score as an extremist on this scale.",
// ];
// const list3 = [
//   "What motivates people who score like you? It's more than money alone, it's some of the other peaks that occur on the Values graph.",
//   "You are a good team player in helping others with projects and initiatives without requiring an economic return of your own.",
//   "You are not an extremist and therefore a stabilizing force when economic issues emerge.",
//   "You are able to balance both needs and perspectives of those with substantially different economic drives.",
//   "You tend to be a good team player especially because you do not try to compete to the extent of creating dissension within the group, team or office.",
// ];
// const list4 = [
//   "You typically don't come to the training session asking. 'How much more am I going to earn as result of this course?'",
//   "You may be somewhat flexible in preferring both cooperative and competitive learning activities.",
//   "Because your score is near the national mean, please check other Values graph peaks and troughs to obtain additional professional development insights.",
//   "Your score is like those who engage in training and development activities in a supportive manner.",
// ];
// const list5 = [
//   "You should assist in those areas or projects where there may be greater financial reward.",
//   "You should allow space for those with higher economic drive factors to demonstrate their strengths or voice their ideas.",
//   "If there is already a level of economic comfort, you may need to allow greater voice to those who haven't yet achieved their own economic comfort zone.",
//   "There may be times when you may need to take a stronger stand on some issues related to economic drives or incentives.",
// ];

function EconomicTab() {
  const data = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.valueDescriptor?.find(({ section }) => section === 'eco')?.subsection || []
  );

  const userLevel = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.score
  );

  return (
    <>
      <div className="flex md:flex-row flex-col my-6">
        <div className="flex flex-col items-center justify-center mr-6 mb-3 md:mb-0">
          <Economic className="scale-150 mb-2" />
          <p className="text-2xl text-[#4472C4] font-bold">Economic</p>
        </div>
        {/* <p className="text-base text-pirmaryBlue">
          This dimension examines the motivation for security from economic
          gain, and to achieve practical returns. The preferred approach of this
          dimension is a professional one with a focus on bottom-line results.
          Your score shows an Average score on Economic value. The following
          comments and information highlight the specifics to you:
          <div className="font-bold mt-5">
            You can perceive and create a balance between the need for economic
            return and other requirements.
          </div>
        </p> */}
        <p className="text-base text-pirmaryBlue">
          The Economic value dimension assesses the motivation for financial security and the desire to achieve practical returns. The preferred approach of this dimension is professional, emphasizing bottom-line results.
          <div className="text-base text-pirmaryBlue mt-3">
            Your assessment score for this value is in the {userLevel[1]?.level} range.
          </div>
          <div className="text-base text-pirmaryBlue mt-3">
            This suggests that {userLevel[1]?.statement} However, remember that this is just one aspect of your overall value system, and other values may influence your choices differently.
          </div>
          <div className="font-bold mt-5">
            You can perceive and create a balance between the need for economic
            return and other requirements.
          </div>
        </p>
      </div>

      <div className="grid mt-6 grid-cols-1 gap-6 md:grid-cols-2">
        {data?.map(({ title = '', statements = [] }, index) => (
          <BoxDescriptionInfo
            key={index}
            title={getTitle(title)}
            description=""
            caption=""
            list={statements}
          />
        ))}
      </div>
    </>
  );
}

export default EconomicTab;
