import Avatar from "@mui/material/Avatar";
import condidate1 from "../../../../../assets/condidate1.svg";
import condidate2 from "../../../../../assets/condidate2.svg";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

export function CustomTooltip({ TooltipTitle, TooltipText }) {
  return (
    <Tooltip
      placement="top"
      title={
        <div className="rounded-md">
          <div className="bg-pirmaryBlue text-white text-sm font-bold px-3 py-2 items-center flex rounded-t">
            {TooltipTitle}
          </div>
          <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
            {TooltipText}
          </div>
        </div>
      }
      arrow
    >
      <InfoIcon
        className="text-secondaryBalck cursor-pointer"
        fontSize="small"
        sx={{ marginLeft: "5px" }}
      />
    </Tooltip>
  );
}

export const useStyle = makeStyles({
  table: {
    "& .MuiTableRow-head": {
      "& .MuiTableCell-head": {
        background: "#F4F6F8",
        fontWeight: "700",
        color: "#384259",
        fontSize: "16px",
      },
    },
  },
  pagination: {
    margin: "24px 0px 30px 0px",

    "& .MuiSvgIcon-fontSizeMedium": {
      zIndex: 200,
      background: "red",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      opacity: 0,
    },
    "& .MuiPaginationItem-page": {
      border: " 1px solid #E5E5E5",
      borderRadius: "0px",
    },
    "& .Mui-selected": {
      background: "#00000000 !important",
      borderRadius: "0px",
      border: "1px solid #7AB794",
      ":hover": {
        // backgroundColor: "transparent",
      },
    },
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E3E6E9 !important",
      backgroundColor: "transparent !important",
      top: 0,

      "& .css-1in441m": {
        display: "none",
      },
      "& .css-1ftyaf0": {
        display: "none",
      },
    },
    "& .MuiSelect-nativeInput": {
      bottom: "50%",
      transform: "translateY(50%)",
      left: "20px !important",
      opacity: 0,
    },
    "& .MuiSelect-icon": {
      right: "20px !important",
      fill: "#7E8B96 !important",
      display: "none",
    },
    "& .MuiInputBase-input": {
      padding: "0px",
    },
  },
  inputLabel: {
    "&.Mui-focused ": {
      color: "#384259 !important",
    },
    "&.MuiInputLabel-root": {
      color: "#384259 !important",
      fontSize: "16px",
      transform: "none !important",
      position: "static !important",
    },
  },
  menuItem: {
    "&.Mui-selected ": {
      backgroundColor: "#7AB69344 !important",
    },
    "& .MuiButtonBase-root-MuiMenuItem-root .Mui-selected": {
      color: "red",
    },
    "& .Mui-checked ": {
      color: "#7AB693 !important",
    },
  },
});

const CreateAvatar = ({ name, lastName, src, alt }) => {
  return (
    <span className="flex items-center text-base text-[#384259]">
      <Avatar src={src} alt={alt} sx={{ marginRight: "10px" }} /> {name}{" "}
      {lastName}
    </span>
  );
};

export const columns = [
  { id: "sr", label: "Sr. ", minWidth: 50, align: "center" },
  { id: "name", label: "Name", minWidth: 200 },
  { id: "gender", label: "Gender", minWidth: 50, align: "center" },
  { id: "city", label: "City", minWidth: 50, align: "left" },
  { id: "country", label: "Country", minWidth: 70, align: "left" },
  { id: "jobReqId", label: "Requisition ID.", minWidth: 120, align: "left" },
  { id: "skillsMatch", label: "Skills match", minWidth: 120, align: "left" },
  { id: "jobReq", label: "Job req.", minWidth: 190, align: "left" },
  {
    id: "assesementType",
    label: "Assesement Type",
    minWidth: 150,
    align: "left",
  },
  {
    id: "defaultBehavior",
    label: "Default Behavior",
    minWidth: 160,
    align: "left",
  },
  {
    id: "competenciesScore",
    label: "Competence Score",
    minWidth: 140,
    align: "left",
  },
];

export const rows = [
  {
    id: 1,
    sr: 1,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 2,
    sr: 2,
    name: <CreateAvatar src={condidate2} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 3,
    sr: 3,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 4,
    sr: 4,
    name: <CreateAvatar src={condidate2} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 5,
    sr: 5,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 6,
    sr: 6,
    name: <CreateAvatar src={condidate2} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 7,
    sr: 7,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 8,
    sr: 8,
    name: <CreateAvatar src={condidate2} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 9,
    sr: 9,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 10,
    sr: 10,
    name: <CreateAvatar src={condidate2} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 11,
    sr: 11,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 12,
    sr: 12,
    name: <CreateAvatar src={condidate2} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 13,
    sr: 13,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
  {
    id: 14,
    sr: 14,
    name: <CreateAvatar src={condidate2} name="Robert " lastName="Binny" />,
    gender: "Male",
    city: "London",
    country: "UK",
    jobReqId: 40,
    skillsMatch: "9/10",
    jobReq: "Management Consulting",
    assesementType: "Management",
    defaultBehavior: "Cautious - Medium",
    competenciesScore: "61.6%",
  },
];
