import React from "react";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import Modal from "../../../sharedComponents/modal/Modal";

function CardAnswerModal({ open, setOpen, modalData, saveStatement }) {
  const [answer, setAnswer] = React.useState("");

  const handleSubmit = () => {
    try {
      saveStatement({
        id: modalData.id,
        [modalData.name]: answer
      })
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    setAnswer(modalData?.value || '')
  }, [modalData]);
  
  return (
    <Modal title={modalData?.title || ''} open={open} setOpen={setOpen} mdUpWidth={550}>
      <h2 className="text-base text-pirmaryBlue mb-2">
        {modalData?.mainQuestoin || ''}
      </h2>
      <h6 className="text-xs text-pirmaryBlue font-bold mb-1">
        Below are the questions to help you answer the questions:
      </h6>
      {modalData?.questions?.length && modalData.questions.map((question, index) => (        
        <p key={index} className="text-xs text-secondaryBalck font-bold mb-[3px]">
          {question}
        </p>
      ))}

      <div className="">
        <textarea
          name=""
          id=""
          value={answer}
          cols=""
          rows="10"
          className="resize-none p-3 rounded border-2 border-solid border-[#E3E6E9] min-h-[270px] outline-none 
          text-base text-secondaryBalck w-full"
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="Enter your answer..."
        ></textarea>
      </div>

      <div className="flex justify-end mt-7">
        <SuccessBtn onClick={handleSubmit}>Save</SuccessBtn>
      </div>
    </Modal>
  );
}

export default CardAnswerModal;
