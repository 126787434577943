import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const CompetencyChart = ({ competencies, layout = "horizontal", roleBaseData }) => {
  const [total, setTotal] = useState(0);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    let data = competencies.map(({ value }) => Number(value));
    setGraphData(data)
    setTotal((data.reduce((a, b) => a + b, 0) / data.length).toFixed(1));
  }, [competencies]);

  const roleBaseTooltip = roleBaseData?.map(({ headingTitle }) => headingTitle.replace('?', '<br /><br />'))
  const optionschart = {
    series: { data: [6, 8, 9, 10, 12, 14] },
    options: {
      noData: {
        text: 'Loading...'
      },
      tooltip: {
        fixed: {
          enabled: true, // Enable fixed tooltip position
          position: 'right', // Position the tooltip on the right
        },
        custom: function ({ series, seriesIndex, w }) {
          return `
          <div class="min-w-[300px]">
            <div class="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
              ${w.config.labels[seriesIndex]} : ${Number(series[seriesIndex]).toFixed(1)}%
            </div>
            <div className='rounded-md tooltip-font-desc' style='padding:0.75rem;background-color:#4D5871;'>
              <div>${w.config.chart.tooltip[seriesIndex]}</div>
            </div>
          </div>`;
        },
      },
      colors: [
        "#9B7210",
        "#CA902C",
        "#AA554C",
        "#4FD1C5",
        "#7ABEEF",
        "#F6AD55",
        "#8B5086",
        "#7AB794",
      ],
      chart: {
        type: "pie",
        tooltip: roleBaseTooltip,
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: [
            "#9B7210",
            "#CA902C",
            "#AA554C",
            "#4FD1C5",
            "#7ABEEF",
            "#F6AD55",
            "#8B5086",
            "#7AB794",
          ],
        },
      },

      legend: {
        show: false,
      },
      labels: competencies?.length
        ? competencies.map(({ type }) => type)
        : [],
    },
  };

  // const optionschart = {
  //   series:
  //     competencies.length > 0
  //       ? competencies.map(({ value }) => Number(value))
  //       : [],
  //   // series: [10, 20, 40, 30, 5, 15],
  //   options: {
  //     colors: [
  //       "#4472c4",
  //       "#ed7d31",
  //       "#a5a5a5",
  //       "#ffc000",
  //       "#5b9bd5",
  //       "#70ad47",
  //     ],
  //     chart: {
  //       width: 380,
  //       type: "pie",
  //     },
  //     dataLabels: {
  //       enabled: false,
  //       style: {
  //         colors: [
  //           "#4472c4",
  //           "#ed7d31",
  //           "#a5a5a5",
  //           "#ffc000",
  //           "#5b9bd5",
  //           "#70ad47",
  //         ],
  //       },
  //     },

  //     legend: {
  //       show: false,
  //     },
  //     labels:
  //       competencies.length > 0 ? competencies.map(({ type }) => type) : [],
  //     responsive: [
  //       {
  //         breakpoint: 480,
  //         options: {
  //           chart: {
  //             width: 200,
  //           },
  //           legend: {
  //             position: "bottom",
  //             display: "none",
  //           },
  //         },
  //       },
  //     ],
  //   },
  // };
  return (
    <>
      <div
        className={[
          "grid grid-cols-1",
          layout === "horizontal" ? "lg:grid-cols-7" : "",
        ].join(" ")}
      >
        <div id="chart" className="relative flex justify-center items-center col-span-3 dashboard-candidate-detail-chart">
          <span className="absolute self-center font-bold text-lg">
            {total}%
          </span>
          <ReactApexChart
            height={"auto"}
            width={"100%"}
            options={optionschart.options}
            series={graphData || [20, 30, 40, 50, 50, 60]}
            type="donut"
          />
        </div>

        <div className="col-span-4">
          <div className="h-[260px] max-h-[260px] overflow-auto">
            <div className="mr-[24px] min-h-full">
            {competencies?.length > 0 &&
              competencies?.map((item, index) => (
              <div
                key={index}
                className={`flex py-2 flex-wrap  text-pirmaryBlue justify-between items-center ${
                  index !== 4 && " border-b lg:border-b-0"
                }`}
              >
                <span className="flex-grow flex justify-start items-center">
                  <div className="">
                    <span
                      style={{
                        backgroundColor: `${optionschart.options.dataLabels.style.colors[index]}`,
                      }}
                      className={`h-3 mr-2 inline-block w-3 rounded-full`}
                    ></span>
                  </div>
                  <span className="text-base leading-5 max-w-fit inline-block">
                    {item?.type}
                  </span>
                </span>
                <span className=" font-bold text-pirmaryBlue">
                  {Number(item?.value).toFixed(1) + "%"}
                </span>
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompetencyChart;
