import { combineReducers } from "redux";
import user from "./user";
import behaviour from './behaviourData';
import steps from './steps'
import budgetReducer from './personalBudget'
import premiumModalReducer from './PremiumReducer'

export default combineReducers({
  user,
  behaviour,
  steps,
  budgetReducer,
  premiumModalReducer
});
