import React, { useState } from "react";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import Container from "./container";
import { Item } from "./sortableItem";

const wrapperStyle = {
  display: "flex",
  flexDirection: "row",
};


const defaultAnnouncements = {
  onDragStart(id) {
    console.log(`Picked up draggable item ${id}.`);
  },
  onDragOver(id, overId) {
    if (overId) {
      console.log(
        `Draggable item ${id} was moved over droppable area ${overId}.`
      );
      return;
    }

    console.log(`Draggable item ${id} is no longer over a droppable area.`);
  },
  onDragEnd(id, overId) {
    if (overId) {
      console.log(
        `Draggable item ${id} was dropped over droppable area ${overId}`
      );
      return;
    }

    console.log(`Draggable item ${id} was dropped.`);
  },
  onDragCancel(id) {
    console.log(`Dragging was cancelled. Draggable item ${id} was dropped.`);
  },
};

export default function DnD({ setEnable, setAnswerList, isFirstPage = true }) {

  const [items, setItems] = useState({
    container1: [],
    container2: []
  });

  React.useEffect(() => {
    if(isFirstPage) {
      setItems({
        container1: [
          "A new car",
          "A technical improvement",
          "A foolish thought",
          "A blunder",
          "A wreck",
          "An award for a good deed",
          "Poisoning the city water",
          "Imprisoning an innocent person",
          "A short circuit",
          "A token of love",
          "A lover's embrace",
          "Torturing a person",
          "A life of adventure",
          "A madman",
          "A telephone",
          "Prostitution",
          "Justice",
          "A decoration for bravery"
        ],
        container2: []
      })
    }else{
      setItems({
        container1: [
          "I like my work, it does me good.",
          "My mind is clear and makes me understand things.",
          "My mind is not very clear and I don't understand things very well.",
          "No matter how hard I work, I shall always feel frustrated.",
          "My working conditions are poor and ruin my life.",
          "I feel at home in the world.",
          "I'm so unhappy I'm actually sick.",
          "I'm so unhappy I can't think straight.",
          "My work contributes nothing to the world.",
          "My work brings out the best in me.",
          "I love to be myself.",
          "I hate to be myself.",
          "My good spirits keep me in good health.",
          "I don't understand things very well and that makes me unhappy.",
          "The more clearly I think, the better I feel.",
          "My work makes me unhappy.",
          "My good spirits keep my mind clear.",
          "My work adds to the beauty and harmony of the world."
        ],
        container2: []
      })
    }
  }, []);

  const [activeId, setActiveId] = useState();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <div style={wrapperStyle}>
      <DndContext
        announcements={defaultAnnouncements}
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <Container id="container1" items={items.container1}/>
        <div className="flex flex-col items-center justify-center">
            <p className="text-[10px] text-[#000000] mb-[10px]">Positive</p>
            <div className="w-[3px] h-[100%] min-h-[470px] bg-gradient-to-b from-[#7AB794] to-[#704615] rounded-[6px]"></div>
            <p className="text-[10px] text-[#000000] mt-[10px]">Negative</p>
          </div>
        <Container id="container2" items={items.container2} />
        <DragOverlay>{activeId ? <Item id={activeId} /> : null}</DragOverlay>
      </DndContext>
    </div>
  );

  function findContainer(id) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  }

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  function handleDragOver(event) {
    // console.log(event);
    const { active, over, activatorEvent } = event;
    const { id } = active;
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          activatorEvent.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item !== active.id),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(
          items[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }
    // to get the list of items
    console.log(items);
    if(items?.container1?.length === 0) {
      setEnable(true);
      setAnswerList(items?.container2)
    }
    setActiveId(null);
  }
}
