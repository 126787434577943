import React from "react";
import BehavioralTraitsSlide3 from "../../../../assets/BehavioralTraitsSlide3.svg";

function Slide2() {
  return (
    <div className="flex md:flex-row flex-col items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <img src={BehavioralTraitsSlide3} alt="" className="w-full h-full" />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          How to get the most out of this report
        </h5>
        <p className="text-base text-pirmaryBlue  mb-6">
          As you review your report, keep the following points in mind:
        </p>

        <ul className="list-disc pl-5 text-base text-pirmaryBlue">
          <li>First, identify your top three values which strongly influence your decision-making process.</li>
          <li>Your values may change over time, so knowing and understanding all the motivators influencing your sense of purpose is essential.</li>
          <li>Pay close attention to all the details of your values, as the report will highlight your natural strengths and areas for improvement.</li>
          <li>Focus on your strengths and recognize your blind spots to improve your performance.</li>
        </ul>

      </div>
    </div>
  );
}

export default Slide2;
