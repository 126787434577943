import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import WhiteBox from "../shared/WhiteBox";
import { useLocation, useNavigate } from "react-router-dom";
import minutes30 from "../../../../assets/minutes30.png";
import desktopNomobile from "../../../../assets/desktop-nomobile.png";
import Modal from "../../../sharedComponents/modal/Modal";

function CandidateAssessmentTime({ valueOrg }) {
  const { state } = useLocation();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <WhiteBox title="30 minutes from success.">
        <div className="flex flex-col justify-center items-center">
          <p className="text-[#384259] text-base font-[400]">
              The assessment consists of three sections: Behavior traits, Values, and Role-based competencies and it generally takes approximately 30 minutes to complete.
          </p>

          <img src={minutes30} alt="" width={'550px'} />

          <p className="text-[#384259] mb-6 text-base font-[400]">
              We recommend completing the assessment in one sitting for the most accurate and reliable results. However, if you can't do that, please make sure that you complete one section per sitting.
          </p>

          <div className="max-w-[950px] w-full mx-auto my-5 flex sm:flex-row flex-col sm:h-auto h-[100px] items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
              <Button
              type="button"
              variant="contained"
              color="success"
              onClick={() => navigate('/assessment/start')}
              sx={{
                  "&.MuiButton-containedSuccess": {
                  backgroundColor: "#E3E6E9",
                  fontSize: "16px",
                  color: "#828282",
                  },
              }}
              >
              Back
              </Button>
              <Button
              type="button"
              variant="contained"
              color="success"
              onClick={() => navigate('/assessment/behavior/start', { state })}
              sx={{
                  "&.MuiButton-containedSuccess": {
                  backgroundColor: "#7AB794",
                  fontSize: "16px",
                  },
              }}
              endIcon={
                  <ArrowRightAltIcon
                  sx={{
                      "&.MuiSvgIcon-root": {
                      fontSize: "30px",
                      color: "white",
                      },
                  }}
                  />
              }
              >
              Start the Assesment
              </Button>
          </div>
        </div>
      </WhiteBox>

      <Modal
        title='TRIPA'
        open={open}
        setOpen={setOpen}
        mdUpWidth={650}
        style={{ maxWidth: '550px', width: '100%' }}
        >
        <div className="flex flex-col justify-center items-center">
          <p className="text-[#384259] text-base text-[20px] font-[400] pb-10">
            For optimal formatting and navigation, please use a desktop or laptop computer to complete your assessment. Please note that the assessment has not been formatted to support mobile devices. Thank you for your understanding.
          </p>

          <img src={desktopNomobile} alt="" width={'550px'} />

          <div className="max-w-[950px] w-full mx-auto mt-10 flex flex-col sm:h-auto h-[100px] items-end justify-between lg:px-0 md:px-10 sm:px-8 px-4">
              <Button
              type="button"
              variant="contained"
              color="success"
              onClick={() => setOpen(false)}
              sx={{
                  "&.MuiButton-containedSuccess": {
                  backgroundColor: "#7AB794",
                  fontSize: "16px",
                  },
              }}
              >
              Ok
              </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CandidateAssessmentTime;
