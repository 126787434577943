import React from 'react';
import ReactApexChart from 'react-apexcharts';
import WhiteBox from '../../../../../sharedComponents/whiteBox/WhiteBox';

const DashboardPieChart = ({ data = [] }) => {
  const optionschart = {
    series: data.length > 0 ? data.map(({ count }) => count) : [],
    options: {
      colors: ['#8EC2A4', '#9C6A98', '#F7B96E', '#8EC7F1', '#69D8CD'],
      chart: {
        width: 380,
        type: 'pie',
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#8EC2A4', '#9C6A98', '#F7B96E', '#8EC7F1', '#69D8CD'],
        },
      },

      legend: {
        show: false,
      },
      labels: data.length > 0 ? data.map(({ department }) => department) : [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
              display: 'none',
            },
          },
        },
      ],
    },
  };
  return (
    <WhiteBox
      title='Open positions by department'
      titleStyle={{
        fontSize: '24px',
        color: '#384259',
        fontWeight: '400',
      }}
      style={{ margin: '0px', height: '100%' }}
    >
      <div id='chart' className='relative flex justify-center'>
        <ReactApexChart
          height={'auto'}
          width={'100%'}
          options={optionschart.options}
          series={optionschart.series}
          type='donut'
        />
      </div>
      {optionschart.options.labels.map((item, index) => (
        <div
          className={`flex flex-wrap text-pirmaryBlue justify-between items-center py-2 ${
            index !== 4 && ' border-b'
          }`}
        >
          <span className='flex justify-center items-center'>
            <span
              style={{
                backgroundColor: `${optionschart.options.dataLabels.style.colors[index]}`,
              }}
              className={`h-3 mr-2 inline-block w-3 rounded-full`}
            ></span>
            <span className='text-base leading-5 text-ellipsis overflow-hidden whitespace-nowrap max-w-fit inline-block'>
              {item}
            </span>
          </span>
          <span className='font-bold text-pirmaryBlue'>
            {optionschart.series[index]}
          </span>
        </div>
      ))}
    </WhiteBox>
  );
};

export default DashboardPieChart;
