import React from "react";

function Slide2() {
  return (
    <>
      <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
        Dimensions
      </h5>
      <p className="text-base text-pirmaryBlue  mb-4">
        The external dimension (how people perceive what you do) focuses on the workplace and addresses empathy, practical thinking, and system judgment.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        The internal dimension (how you perceive yourself) focuses on self-esteem, role awareness, and self-direction.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        The external and internal dimensions create your overall decision-making or critical thinking pattern. As the decision always precedes action, understanding your fundamental ways of thinking is crucial.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        Some people have the unconventional critical thinking, which can indicate creativity and innovative thinking from a specific perspective. This can be an advantage, but it can also be problematic or a drawback for others.
      </p>
    </>
  );
}

export default Slide2;
