import React from "react";
import BoxDescriptionInfo from "../../behavioralTraits/sharedcomponents/boxDescriptionInfo";
import { ReactComponent as Political } from "../../../../assets/Political.svg";
import { useSelector } from "react-redux";
import getTitle from "../reusableComponent";

// const list1 = [
//   "Your score in this range is near the typical business professional's score.",
//   "A score near the mean indicates that the Political (power seeking) drive is not your primary motivational factor.",
//   "You are able to understand the needs of those on the team who are highly competitive, as well as those who tend to be more cooperative.",
//   "You show an appropriate balance between seeking leadership roles and supporting roles without being an extremist in either direction.",
//   "You demonstrate flexibility in being able to lead a team when necessary, and to support the team when necessary.",
// ];
// const list2 = [
//   "Don't forget that you have the ability to be a stabilizing agent between highcontrol and high-support on special team functions and initiatives.",
//   "You bring a power seeking drive typical of many business professionals, since your score is very near the national mean on this scale.",
//   "Give your input to the team in order to gain a middle-of-the-road insight and understanding of work related issues.",
//   "Review other Values drives that might be higher or lower than the Political score in this report in order to gain a greater understanding of specific keys to managing and motivating.",
// ];
// const list3 = [
//   "You bring flexibility to the team. Able to lead when asked, but able to support when asked as well.",
//   "You are a stabilizing force on the team.",
//   "You are able to appreciate the needs of both the higher and lower Political individuals on the team.",
//   "You are perceived by others on the team as neither dictatorial nor dependent with regard to team projects and goals.",
//   "You show appropriate respect to leaders of a project, as well as ability to offer suggestions for change.",
// ];
// const list4 = [
//   "You score like those who are supportive in a variety of work activities and development.",
//   "You will respond with flexibility to either cooperative or competitive team activities.",
//   "You score like those who participate openly in training activities without trying to dominate the event.",
//   "You show ability to lead a training event as well as support and participate.",
// ];
// const list5 = [
//   "You may need to shift gears into either a more supportive role or a greater leadership role at times.",
//   "When issues of team leadership emerge, you may need to take a more visible stand on some problem-solving situations.",
//   "Examine other Values drives in this report in order to gain increased understanding of areas for continuous improvement.",
//   "Remember that it is OK that some don't appreciate artistry, balance or harmony and you can serve as a bridge between those who do and don't see value in such things.",
// ];

function PoliticalTab() {
  const data = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.valueDescriptor?.find(({ section }) => section === 'pol')?.subsection || []
  );

  const userLevel = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.score
  );

  return (
    <>
      <div className="flex md:flex-row flex-col my-6">
        <div className="flex flex-col items-center justify-center mr-6 mb-3 md:mb-0">
          <Political className="scale-150 mb-2" />
          <p className="text-[24px] text-[#C2342C] font-bold">Political</p>
        </div>
        {/* <p className="text-base text-pirmaryBlue">
          This drive is the driver of a leader, influence, and control over
          one's environment or success. Competitiveness is often associated with
          those scoring high in this motivation. Your score shows a Very High
          score on Political value. The following comments and information
          highlight the specifics to you:
          <div className="font-bold mt-5">
            You are an influential leader and can take control of a variety of
            initiatives and maintain control.
          </div>
        </p> */}
        <p className="text-base text-pirmaryBlue">
          This dimension is focused on the drive for leadership, influence, and control over one's environment or success. Those who score high in this motivation are often associated with competitiveness.
          <div className="text-base text-pirmaryBlue mt-3">
            Your assessment score for this value is in the {userLevel[3]?.level} range.
          </div>
          <div className="text-base text-pirmaryBlue mt-3">
            This suggests that {userLevel[3]?.statement} However, remember that this is just one aspect of your overall value system, and other values may influence your choices differently.
          </div>
          <div className="font-bold mt-5">
            You are an influential leader and can take control of a variety of
            initiatives and maintain control.
          </div>
        </p>
      </div>

      <div className="grid mt-6 grid-cols-1 gap-6 md:grid-cols-2">
        {data?.map(({ title = '', statements = [] }, index) => (
          <BoxDescriptionInfo
            key={index}
            title={getTitle(title)}
            description=""
            caption=""
            list={statements}
          />
        ))}
      </div>
    </>
  );
}

export default PoliticalTab;
