import React from 'react';
import Modal from '../../../../../../sharedComponents/modal/Modal';
import SuccessBtn from '../../../../../../sharedComponents/buttons/successBtn/SuccessBtn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useStyle } from '../reusableComponent';
import { Avatar } from '@mui/material';

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: "#C6E7D4",
      color: "#166534",
      fontSize: '35px',
      fontWeight: "700",
      width: "6rem",
      height: "6rem",
    },
    children: getLetterFromTheName(name),
  };
}

const getLetterFromTheName = (name) => {
  const allNames = name.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if(index === 0 || index === allNames.length - 1){
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}

function JobPreviewModal({ selectedJob, open, setOpen }) {
  const classes = useStyle();
  return (
    <Modal
      title='Job preview'
      open={open}
      setOpen={setOpen}
      mdUpWidth={950}
      lgUpWidth={1050}
      xlUpWidth={1150}
    >
      <div className='flex items-center'>
        <div className='mr-6'>
          {/* <TeslaLarge /> */}
          {/* {selectedJob.logo} */}
          <span className="w-[105px] h-[105px] bg-white border-2 border-solid border-[#E5E5E5] flex justify-center items-center md:mr-[12px] mb:my-0">
            {selectedJob.logo_file ? (
              <img src={selectedJob.logo_file} alt="" className="h-full" />
            ) : (
              <Avatar {...stringAvatar(`${selectedJob?.company_name || 'T'}`)} />
            )}
          </span>
        </div>
        <div className='flex flex-col'>
          <h4 className={classes.title}>{selectedJob.job_title}</h4>
          <p className='text-[18px] text-[#384259]'>
            <span>{selectedJob.company_name}</span>-
            <span>
              {selectedJob.city}
              {`, ${selectedJob.country}`}
            </span>
          </p>
        </div>
      </div>

      <div className='border-[1px] border-dashed border-[#C4C4C4] my-6 '></div>

      <div className='grid grid-cols-auto-fit-200 gap-4 rounded border-[1px] border-solid border-[#E5E5E5] p-[30px] mb-6'>
        <div className='flex flex-col'>
          <h4 className={classes.subTitle}>Seniority level</h4>
          <p className={classes.text}>{selectedJob.seniority_level}</p>
        </div>
        <div className='flex flex-col'>
          <h4 className={classes.subTitle}>Industry</h4>
          <p className={classes.text}>{selectedJob.company_industry}</p>
        </div>
        <div className='flex flex-col'>
          <h4 className={classes.subTitle}>Human resources</h4>
          <p className={classes.text}>{selectedJob.employment_type}</p>
        </div>
        <div className='flex flex-col'>
          <h4 className={classes.subTitle}>Job function</h4>
          <p className={classes.text}>{selectedJob.job_function}</p>
        </div>
        <div className='flex flex-col'>
          <h4 className={classes.subTitle}>Salary range</h4>
          <p className={classes.text}>
            {selectedJob.salary_range_from} - {selectedJob.salary_range_to}
          </p>
        </div>
        <div className='flex flex-col'>
          <h4 className={classes.subTitle}>Experience</h4>
          <p className={classes.text}>{selectedJob.experience}</p>
        </div>
        {/* <div className='flex flex-col'>
          <h4 className={classes.subTitle}>URL</h4>
          {selectedJob.web_url && (
            <Link to={selectedJob.web_url} className='text-[#4472C4] text-sm'>
              {selectedJob.web_url}
            </Link>
          )}
        </div> */}
        {/* <div className='flex flex-col'>
          <h4 className={classes.subTitle}>Job Funnel</h4>
          <p className={classes.text}>{selectedJob.job_funnel}</p>
        </div> */}
        <div className='flex flex-col'>
          <h4 className={classes.subTitle}>Job posting open until</h4>
          <p className={classes.text}>{selectedJob.open_jobposting}</p>
        </div>
        <div className='flex flex-col'>
          <h4 className={classes.subTitle}>Application through Tripa</h4>
          <p className={classes.text}>
            {selectedJob.application_through_tripa}
          </p>
        </div>
      </div>

      <div className='mb-4'>
        <h2 className={`font-bold text-[22px]`}>Job details</h2>
        <div className='mt-4'>
          <h5 className='text-[18px] font-bold  text-[#384259]'>
            Your mission
          </h5>
          <p
            className={`pt-[5px] ${classes.text}`}
            dangerouslySetInnerHTML={{ __html: selectedJob.your_mission }}
          />
        </div>
        <div className='mt-4'>
          <h5 className='text-[18px] font-bold  text-[#384259]'>Your focus</h5>
          <p
            className={`pt-[5px] ${classes.text}`}
            dangerouslySetInnerHTML={{ __html: selectedJob.your_focus }}
          />
        </div>
        <div className='mt-4'>
          <h5 className='text-[18px] font-bold  text-[#384259]'>Why us</h5>
          <p
            className={`pt-[5px] ${classes.text}`}
            dangerouslySetInnerHTML={{ __html: selectedJob.why_us }}
          />
        </div>
        <div className='mt-4'>
          <h5 className='text-[18px] font-bold  text-[#384259]'>Why you</h5>
          <p
            className={`pt-[5px] ${classes.text}`}
            dangerouslySetInnerHTML={{ __html: selectedJob.why_you }}
          />
        </div>
      </div>

      <div className='flex flex-wrap'>
        <div className='lg:min-w-[430px] min-w-full'>
          <h2 className={`${classes.title} mb-5`}>
            Knowledge, Abilities & Skills
          </h2>
          {selectedJob.skills &&
            selectedJob.skills.length > 0 &&
            selectedJob.skills.map((skill) => (
              <p className='flex mb-3'>
                <CheckCircleIcon className={classes.checkCircle} />
                <span className={`text-[#384259] text-base ml-3`}>{skill}</span>
              </p>
            ))}
        </div>
        <div className='lg:min-w-[430px] min-w-full'>
          <h2 className={`${classes.title} mb-5`}>Competencies</h2>
          {selectedJob?.sub_competencies &&
            selectedJob?.sub_competencies.length > 0 &&
            selectedJob.sub_competencies.map((competency) => (
              <p className='flex mb-3'>
                <CheckCircleIcon className={classes.checkCircle} />
                <span className={`text-[#384259] text-base ml-3`}>
                  {competency}
                </span>
              </p>
          ))}
          {selectedJob?.additional_competencies &&
            selectedJob?.additional_competencies.length > 0 &&
            selectedJob.additional_competencies.map((competency) => (
              <p className='flex mb-3'>
                <CheckCircleIcon className={classes.checkCircle} />
                <span className={`text-[#384259] text-base ml-3`}>
                  {competency}
                </span>
              </p>
          ))}
        </div>
      </div>
      <div className='flex flex-wrap mt-6'>
        <div className='lg:min-w-[430px] min-w-full'>
          <h2 className={`${classes.title} mb-5`}>Language skills</h2>
          {selectedJob.languages &&
            selectedJob.languages.length > 0 &&
            selectedJob.languages.map((language) => (
              <p className='flex mb-3'>
                <CheckCircleIcon className={classes.checkCircle} />
                <span className={`text-[#384259] text-base ml-3`}>
                  {language}
                </span>
              </p>
            ))}
        </div>
        <div className='lg:min-w-[430px] min-w-full'>
          <h2 className={`${classes.title} mb-5`}>Education & Qualification</h2>
          {selectedJob.education &&
            selectedJob.education.length > 0 &&
            selectedJob.education.map((edu) => (
              <p className='flex mb-3'>
                <CheckCircleIcon className={classes.checkCircle} />
                <span className={`text-[#384259] text-base ml-3`}>{edu}</span>
              </p>
            ))}
        </div>
      </div>

      <div className='flex items-center justify-end mt-8'>
        <div className='flex'>
          <SuccessBtn
            type='button'
            onClick={() => setOpen(false)}
            customStyle={{ marginRight: '24px' }}
          >
            OKAY
          </SuccessBtn>
        </div>
      </div>
    </Modal>
  );
}

export default JobPreviewModal;
