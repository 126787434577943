import React from "react";
import { BehaviorStepper } from "../reusableComponents";

import WhiteBox from "../shared/WhiteBox";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import config from "../../../../../config/config";

function RoleBasedCompetenciesStepFive() {
  const navigate = useNavigate();

  const [link, setLink] = React.useState("");

  React.useEffect(() => {
    let data = localStorage.getItem("accessData");
    data = data && JSON.parse(data);
    let code = data?.isRoleBasedAssessmentCompleted || "";
    let fName = data?.firstNameAndLastName?.first_name || "Abc";
    let lName = data?.firstNameAndLastName?.last_name || "Abc";
    let email = data?.email_address || "";
    let aiReportId = data?.assessmentType?.ai_report_id || 208;
    let iFrameSrc = `${config.assessmentUrlApi}/remote/instrument/AI/${code}?fname=${fName}&lname=${lName}&email=${email}&ai_report=${aiReportId}&completeURL=${window.location.origin}/assessment/rolebased/complete/?code=`;
    setLink(iFrameSrc);
  }, []);
  return (
    <>
      <BehaviorStepper step={2} />
      <WhiteBox title="Welcome to the role-based competencies assessment!">

        <p className="text-[#384259] text-base mb-5">
          Role-based competencies report provides a list of capacities from your decision-making styles' unique combination (personal, practical, and analytical). It helps you understand your strengths and weaknesses that enable you to make the necessary changes to achieve your desired level of performance.
        </p>

        <p className="text-base mb-5 text-[#828282]">
          <span className="text-[#E82127] font-bold">VERY IMPORTANT:</span>{" "}
          PLEASE READ THESE INSTRUCTIONS THOROUGHLY
        </p>

        <p className="text-[#384259] text-base mb-5">
          This assessment is split into two parts, and you will need to read the instructions carefully since the directions for each part are different. Click the "Continue" button to begin. You'll see 18 statements. Read each statement carefully and decide whether you value it positively or negatively.
        </p>

        <p className="text-[#384259] text-base mb-5">
          For <span className="text-[#000] font-bold">Part 1</span>, drag the statement you feel represents the highest value to the top of the right-hand column. Then, move the following best statement directly below it. Finally, continue moving all the items from left to right to reflect your opinion on their goodness or badness (top to bottom).
        </p>
        
        <p className="text-[#384259] text-base mb-5">
          There's no time limit, but it's best to trust your initial thoughts when deciding on the ranking. Once you're satisfied with your orders for Part 1, click on "View Part 2" to proceed.
        </p>

        <p className="text-[#384259] text-base mb-5">
          In <span className="text-[#000] font-bold">Part 2</span>, you'll see a new set of statements. Repeat the same process as in Part 1, ranking the statements from top to bottom based on which one you most agree or disagree with.
        </p>

        <p className="text-[#384259] text-base mb-5">
          You can move between Part 1 and Part 2 and adjust your rankings until you're comfortable with your final order. However, it's best to trust your initial thoughts, which are usually the most accurate.
        </p>

        <p className="text-[#384259] text-base mb-5">
          <span className="text-[#000] font-bold">IMPORTANT:</span> In Part 1, do not judge the expressions by their importance but rather only by the goodness or badness of their content. In Part 2, evaluate the terms based solely on which statement you most agree or disagree with.
        </p>

        <p className="text-[#384259] text-base mb-5">
          Thank you for participating in this role-based competencies assessment. We hope this experience will give you some insight into your skills and areas for development, leading to personal and professional growth.
        </p>
      </WhiteBox>
      <div className="max-w-[950px] w-full mx-auto my-5 flex items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate("/assessment/values/start")}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#E3E6E9",
              fontSize: "16px",
              color: "#828282",
            },
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate("/assessment/rolebased/step1")}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#7AB794",
              fontSize: "16px",
            },
          }}
          endIcon={
            <ArrowRightAltIcon
              sx={{
                "&.MuiSvgIcon-root": {
                  fontSize: "30px",
                  color: "white",
                },
              }}
            />
          }
        >
          Continue
        </Button>
      </div>
    </>
  );
}

export default RoleBasedCompetenciesStepFive;
