import React from 'react';
import Modal from '../../../../../../sharedComponents/modal/Modal';
import SecondaryBtn from '../../../../../../sharedComponents/buttons/secondaryBtn/SecondaryBtn';
import DangerLightBtn from '../../../../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn';

function DeleteModal({ setOpen, open, handleDelete }) {
  return (
    <Modal mdUpWidth={340} title='Delete job' open={open} setOpen={setOpen}>
      <p className='text-base text-pirmaryBlue'>
        Are you sure you want to delete this Job?
      </p>

      <div className='flex flex-wrap justify-between items-center mt-6'>
        <SecondaryBtn onClick={() => setOpen(false)}>No</SecondaryBtn>
        <DangerLightBtn onClick={handleDelete}>Yes</DangerLightBtn>
      </div>
    </Modal>
  );
}

export default DeleteModal;
