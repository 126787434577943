import React from "react";

function WhiteBox({ title, children, ...rest }) {
  return (
    <div
      className={`p-8 rounded-2xl bg-white w-full max-w-[950px] lg:mx-auto md:m-10 mx-3 my-6`}
      {...rest}
    >
      {title && (
        <h2 className="text-[28px] leading-8 font-bold mb-7"> {title}</h2>
      )}
      {children}
    </div>
  );
}

export default WhiteBox;
