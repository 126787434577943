import React from "react";
import Leftbar from "./Leftbar";
import BottomCards from "./BottomCards";

function CandidatePoolWrapper({ children, setSelectedCandidate }) {
  const [counts, setCounts] = React.useState();
  return (
    <>
      <div className='grid grid-cols-12 gap-6'>
        <div className='lg:col-start-1 lg:col-end-4 col-start-1 col-end-13 order-2 lg:order-1'>
          <Leftbar
            setSelectedCandidate={setSelectedCandidate}
            setCounts={setCounts}
          />
        </div>
        <div className="lg:col-start-4 lg:col-end-13 col-start-1 col-end-13 order-1 lg:order-2">
          {children}
        </div>
      </div>
      {counts && <BottomCards counts={counts} />}
    </>
  );
}

export default CandidatePoolWrapper;
