import React from "react";
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText } from "@mui/material";

const useStyle = makeStyles((theme) => ({
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E3E6E9 !important",
      backgroundColor: "transparent !important",
      
      "& .css-1in441m": {
        display: "none",
      },
      "& .css-1ftyaf0": {
        display: "none",
      },
    },
    "& .MuiSelect-nativeInput": {
      bottom: "50%",
      transform: "translateY(50%)",
      left: "20px !important",
      opacity: 0,
    },
    "& .MuiSelect-icon": {
      right: "20px !important",
      fill: "#7E8B96 !important",
    },
    "& .MuiOutlinedInput-root": {
      height: "49px",
    },
  },
  inputLabel: {
    "&.Mui-focused ": {
      color: "#384259 !important",
    },
    "&.MuiInputLabel-root": {
      color: "#384259 !important",
      fontSize: "16px",
      transform: "none !important",
      position: "static !important",
    },
  },
  placeholder: {
    color: "#aaa"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Placeholder = ({ children }) => {
  const classes = useStyle();
  return <div className={classes.placeholder}>{children}</div>;
};

function MultipleSelectCheckmarks({
  onChange,
  selectItems = [],
  label,
  labelId,
  id,
  value,
  name,
  placeholder,
  inputStyle,
  isMultiple = false,
  disabled = false,
  required = false,
}) {
  const classes = useStyle();
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth className={classes.select} disabled={disabled}>
        {label && <InputLabel id={labelId} className={classes.inputLabel}>
          {label && label}
          {required ? " *" : null}
        </InputLabel>}
        <Select
          displayEmpty
          labelId={labelId}
          id={id}
          onChange={onChange}
          name={name}
          value={value}
          className={`${disabled && "bg-gray-400 bg-opacity-5"} ${
            classes.select
          }`}
          sx={inputStyle}
          multiple={isMultiple}
          disabled={disabled}
          renderValue={
            typeof value === "string" ? value !== "" ? (selected) => selected.join(', ') : () => <Placeholder>{placeholder}</Placeholder> : value.length>0 ? (selected) => selected.join(', ') : () => <Placeholder>{placeholder}</Placeholder>
          }
          MenuProps={MenuProps}
        >
            {selectItems.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={value.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default MultipleSelectCheckmarks;
