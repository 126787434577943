import React from "react";

import PlanDetails from "./PlanDetails";
import ChangePlan from "./ChangePlan";

function Subscription() {
  const [isPlanPage, setIsPlanPage] = React.useState(false);
  // const [plan, setPlan] = React.useState("Annual");

  return isPlanPage ? <ChangePlan setIsPlanPage={setIsPlanPage} /> : <PlanDetails setIsPlanPage={setIsPlanPage} />;
}

export default Subscription;
