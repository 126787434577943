import React from "react";
import Modal from "../../../sharedComponents/modal/Modal";
import { Formik, Field, Form } from "formik";
import Button from "@mui/material/Button";

export default function AboutMeModalBody({
  values,
  isOpen,
  onSubmit,
  onClose,
}) {
  const { workAt, wentTo, email, phone, summary } = values || {};

  return (
    <div>
      <Modal title="About me" open={isOpen} setOpen={onClose} lgUpWidth={600} >
        <Formik
          initialValues={{ workAt, wentTo, email, phone, summary }}
          onSubmit={async (values) => {
            onSubmit(values);
          }}
        >
          <Form>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8">
              <div className="flex flex-col my-4">
                <label htmlFor="firstName" className="text-pirmaryBlue">
                  Work At
                </label>
                <Field
                  name="workAt"
                  type="text"
                  className="focus:outline-none border-2 p-[12px] border-solid rounded border-grayLight"
                />
              </div>
              <div className="flex flex-col my-4">
                <label htmlFor="firstName" className="text-pirmaryBlue">
                  Went To
                </label>
                <Field
                  name="wentTo"
                  type="text"
                  className="focus:outline-none border-2 p-[12px] border-solid rounded border-grayLight"
                />
              </div>
              <div className="flex flex-col my-4">
                <label htmlFor="firstName" className="text-pirmaryBlue">
                  email
                </label>
                <Field
                  name="email"
                  type="text"
                  className="focus:outline-none border-2 p-[12px] border-solid rounded border-grayLight"
                  disabled
                />
              </div>
              <div className="flex flex-col my-4">
                <label htmlFor="firstName" className="text-pirmaryBlue">
                  Phone
                </label>
                <Field
                  name="phone"
                  type="text"
                  className="focus:outline-none border-2 p-[12px] border-solid rounded border-grayLight"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="firstName" className="text-pirmaryBlue">
                Discription
              </label>
              <Field name="summary">
                {({ field, props }) => (
                  <textarea
                    className="focus:outline-none border-2 p-[12px] border-solid rounded border-grayLight"
                    {...props}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="flex justify-end mt-3">
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{
                  "&.MuiButton-containedSuccess": {
                    backgroundColor: "#7AB794",
                    fontSize: "16px",
                    width: "160px",
                  },
                }}
              >
                Update
              </Button>
            </div>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
}
