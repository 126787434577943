import { MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { DatePickerStyles } from "../../../sharedComponents/materialStyles";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import DangerLightBtn from "../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import { useFormik } from "formik";
import { addEducationJC, deleteEducationJC } from "../../../../../api/jc";
import { toast } from "react-toastify";
import moment from "moment";
import cx from "classnames";

export default function EducationEditModal({
  handleReload,
  setSelected,
  selected,
  isLocal = false
}) {
  const handleSubmit = () => {
    if (selected) {
      let data = {
        id: selected._id,
        field_of_study: formik.values.field_of_study,
        from_date: formik.values.from_date,
        to_date: formik.values.to_date,
        institution_name: formik.values.institution_name,
        majors_obtained: formik.values.majors_obtained,
      };

      if (isLocal) {
        delete data.id;
        handleReload(data);
        return
      }
      addEducationJC(data)
        .then((res) => {
          handleReload();
          toast.success("Updated Education..!");
          setSelected();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error while updating Education!");
        });
    } else {
      if (isLocal) {
        handleReload(formik.values);
        return
      }
      addEducationJC(formik.values)
        .then((res) => {
          handleReload();
          toast.success("Added Education..!");
          setSelected();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error while creating Education!");
        });
    }
  };
  const handleDelete = () => {
    if (isLocal) {
      handleReload();
      return;
    }
    deleteEducationJC({ id: selected._id }).then((res) => {
      toast.success("successfully removed.");
      handleReload();
      setSelected();
    });
  };
  const validate = (values) => {
    const errors = {};

    if (!values.from_date) errors.from_date = true;
    if (!values.to_date) errors.to_date = true;
    if (!values.institution_name) errors.institution_name = true;
    if (!values.field_of_study) errors.field_of_study = true;
    if (!values.majors_obtained) errors.majors_obtained = true;
    if (values.from_date && values.to_date) {
      if (
        moment(values.from_date).isAfter(values.to_date) ||
        moment(values.from_date).isSame(values.to_date)
      )
        errors.date = true;
    }
  
    return errors;
  };

  const formik = useFormik({
    handleSubmit,
    enableReinitialize: true,
    validate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const themeDataPicker = DatePickerStyles();

  useEffect(() => {
    if (selected) {
      const {
        from_date,
        to_date,
        field_of_study,
        institution_name,
        majors_obtained,
      } = selected || {};
      formik.setValues({
        field_of_study,
        from_date: from_date ? moment(from_date).format("YYYY-MM-DD") : "",
        to_date: to_date ? moment(to_date).format("YYYY-MM-DD") : "",
        institution_name,
        majors_obtained,
      });
    } else {
      formik.setValues({
        field_of_study: "",
        from_date:"",
        to_date: "",
        institution_name: "",
        majors_obtained : "",
      });
    }
  }, []);

  const { errors } = formik || {};

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
          <div className="flex flex-col">
            <label className="text-base text-pirmaryBlue">Start date *</label>
            <TextField
              type="date"
              value={formik?.values?.from_date}
              onChange={(e) => {
                formik.setFieldValue("from_date", e.target.value);
              }}
              error={errors.from_date || errors.date}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              className={themeDataPicker.root}
            />
            {errors.from_date && (
              <span className="text-red-500">This is required field</span>
            )}
            {errors.date && (
              <span className="text-red-500">start date should be less than end date</span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-base text-pirmaryBlue">End date *</label>
            <TextField
              id="date"
              type="date"
              onChange={(e) => {
                formik.setFieldValue("to_date", e.target.value);
              }}
              error={errors.to_date}
              value={formik?.values?.to_date}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              className={themeDataPicker.root}
            />
            {errors.to_date && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">
              Institution name *
            </label>
            <input
              type="text"
              value={formik?.values?.institution_name || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="institution_name"
              placeHolder="Enter institution name"
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.institution_name },
                "border-grayLight"
              )}
            />
            {errors.institution_name && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">Field of study *</label>
            <input
              type="text"
              value={formik?.values?.field_of_study || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="field_of_study"
              placeHolder="Enter field of study"
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.field_of_study },
                "border-grayLight"
              )}
            />
            {errors.field_of_study && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">
              Major obtained *
            </label>
            <Select
              type="text"
              displayEmpty
              onChange={(e) => {
                formik.setFieldValue("majors_obtained", e.target.value);
              }}
              value={formik?.values?.majors_obtained || ""}
              placeHolder="Enter majors obtained"
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.majors_obtained },
                "bg-gray-400 bg-opacity-5"
              )}
            >
              <MenuItem value=''>
                Select majors obtained
              </MenuItem>
              <MenuItem value="High School">High School</MenuItem>
              <MenuItem value="Technical Degree">Technical Degree</MenuItem>
              <MenuItem value="Bachelor">Bachelor</MenuItem>
              <MenuItem value="Master">Master</MenuItem>
              <MenuItem value="PhD">PhD</MenuItem>
              <MenuItem value="Diploma">Diploma</MenuItem>
              <MenuItem value="Postgraduate">Postgraduate</MenuItem>
            </Select>
            {errors.majors_obtained && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="mt-2 col-span-2 flex justify-between items-center">
            <div>
              {selected && (
                <DangerLightBtn onClick={handleDelete} type="button">
                  <span className="font-bold">Delete education</span>
                </DangerLightBtn>
              )}
            </div>
            <SuccessBtn type="submit">
              <span className="font-bold">
                {selected ? "Update" : "Add"} education
              </span>
            </SuccessBtn>
          </div>
        </div>
      </form>
    </>
  );
}
