import { createReducer } from "@reduxjs/toolkit"
import { updateUser } from "../actions/user"

const initUser = {}

const userReducer = createReducer(initUser, (builder) => {
  builder
    .addCase(updateUser, (state, action) => {
      return action.payload;
    })
})

export default userReducer;