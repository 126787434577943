import React from "react";
import WhiteBox from "../../../../../sharedComponents/whiteBox/WhiteBox";
import SelectField from "../../../../../sharedComponents/selectField/SelectField";
import SuccessBtn from "../../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import { allCountry } from "../../../../../../contants/countries";
import { jobFunctins } from "../../../../../../contants/functions";
import { industries } from "../../../../../../contants/industries";
import datas from "../../../../../../contants/cities.json"

function BasicDetails2({ data, setData, handleSubmit }) {
  const handleChangeData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  // const formValidate = (values) => {
  //   const myError = 0;

  //   if (!values.country) {
  //     toast.error("Country is required");
  //     return 1;
  //   }
  //   if (!values.city) {
  //     toast.error("City is required");
  //     return 1;
  //   }
  //   if (!values.employees) {
  //     toast.error("Employees is required");
  //     return 1;
  //   }

  //   return myError;
  // };
  const countries = Object.keys(datas);
  const cities = data.country ? datas[data.country] : [];

  return (
    <WhiteBox
      title="Basic details"
      titleStyle={{ fontSize: "24px", margin: "20px auto !important" }}
    >
      <form action="#" onSubmit={handleSubmit}>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
          <div className="sm:col-start-1 sm:col-end-2">
            <SelectField
              selectItems={countries}
              label="Country"
              labelId="country"
              id="country"
              value={data.country}
              onChange={handleChangeData}
              name="country"
              placeholder="Select"
              inputStyle={{ padding: "14px" }}
              required
            />
          </div>
          <div className="sm:col-start-2 sm:col-end-3">
            <SelectField
              selectItems={cities}
              label="City"
              labelId="city"
              id="city"
              value={data.city}
              onChange={handleChangeData}
              name="city"
              placeholder="Select"
              inputStyle={{ padding: "14px" }}
              required
              disabled={cities?.length > 0 ? false : true}
            />
          </div>
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 my-6">
          <div className="sm:col-start-1 sm:col-end-2">
            <SelectField
              selectItems={industries}
              label="Company industry"
              labelId="companyIndustry"
              id="companyIndustry"
              value={data.companyIndustry}
              onChange={handleChangeData}
              name="companyIndustry"
              placeholder="Select"
              inputStyle={{ padding: "14px" }}
              required
            />
          </div>
          <div className="sm:col-start-2 sm:col-end-3">
            {/* <SelectField
              selectItems={jobFunctins}
              label="Company function"
              labelId="companyFunction"
              id="companyFunction"
              value={data.companyFunction}
              onChange={handleChangeData}
              name="companyFunction"
              placeholder="Select"
              inputStyle={{ padding: "14px" }}
              required
            />
          </div>
          <div className="sm:col-start-3 sm:col-end-4"> */}
            <SelectField
              selectItems={["1 - 10","11 - 49", "50 - 199", "200 - 500", "501 - 1000", "1000+"]}
              label="Employees"
              labelId="employees"
              id="employees"
              value={data.employees}
              onChange={handleChangeData}
              name="employees"
              placeholder="Select"
              inputStyle={{ padding: "14px" }}
              required
            />
          </div>
        </div>
        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 my-6">
          <div className="sm:col-start-1 sm:col-end-2  flex flex-col">
            <label htmlFor="companyTaxID" className="text-pirmaryBlue">
              Company tax id
            </label>
            <input
              type="text"
              name="companyTaxID"
              value={data.companyTaxID || ""}
              onChange={handleChangeData}
              id="companyTaxID"
              placeholder="Company tax id"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
          <div className="sm:col-start-2 sm:col-end-3 flex flex-col">
            <label htmlFor="companyURL" className="text-pirmaryBlue">
              Company URL
            </label>
            <input
              type="text"
              name="companyURL"
              value={data.companyURL || ""}
              onChange={handleChangeData}
              id="companyURL"
              placeholder="Company URL"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
          <div className="sm:col-start-3 sm:col-end-4 flex flex-col">
            <label htmlFor="companyJobURL" className="text-pirmaryBlue">
              Company jobs URL
            </label>
            <input
              type="text"
              name="companyJobURL"
              value={data.companyJobURL || ""}
              onChange={handleChangeData}
              id="companyJobURL"
              placeholder="Company jobs URL"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
        </div>
        <div className="flex justify-end items-center">
          <SuccessBtn type="submit">Update</SuccessBtn>
        </div>
      </form>
    </WhiteBox>
  );
}

export default BasicDetails2;
