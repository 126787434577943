import React from "react";
import { CustomTooltip } from "../../HRComponents/hrPanel/pages/dashboard/reusableComponent";

function WhiteBox({ title, children, style, titleStyle, TooltipTitle, TooltipText }) {
  return (
    <div
      style={style}
      className={`p-8 rounded-2xl bg-white lg:mx-auto md:m-10 mx-1 my-3 w-full`}
    >
      {title && (
        <h2 className="text-[28px] leading-8 font-bold mb-7" style={titleStyle}>
          {title}
          {TooltipTitle && (
          <CustomTooltip
            TooltipTitle={TooltipTitle}
            TooltipText={TooltipText}
          />
          )}
        </h2>
      )}
      {children}
    </div>
  );
}

export default WhiteBox;
