import React from "react";

function Card({ title, number, image }) {
  return (
    <div
      className="px-[32px] py-[16px] h-[155px] md:w-[270px] w-full bg-white rounded-[16px]
     flex flex-col justify-between "
    >
      <h5 className="text-[20px] text-[#353535] font-normal">{title}</h5>
      <div className="flex justify-between items-center">
        <p className="text-[#6DA183] text-[36px] font-bold">{number}</p>
        <div className="w-[45px] h-[45px] object-cover">{image}</div>
      </div>
    </div>
  );
}

export default Card;
