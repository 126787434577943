import React, { useEffect } from 'react';
import WhiteBox from '../../../../sharedComponents/whiteBox/WhiteBox';
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SuccessBtn from '../../../../sharedComponents/buttons/successBtn/SuccessBtn';
import {
  ProfileCheckbox,
  CandidatePoolWrapper,
  SelectPoolField,
} from './reuseableComponent';
import Condidate1 from '../../../../../assets/condidate1.svg';
import AddCandidate from './modal/AddCandidate';
import UpdateCandidate from './modal/UpdateCandidate';
import DeleteCandidate from './modal/DeleteCandidate';
import {
  getCandidatesByPool,
  getHRProfile,
  getPoolsByTitle,
  addToPool,
} from '../../../../../../api/hr';

function Leftbar({ setSelectedCandidate: setCandidate, setCounts }) {
  const [pools, setPools] = React.useState([]);
  const [jobReq, setJobReq] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [openAddCandidate, setOpenAddCandidate] = React.useState(false);
  const [openUpdateCandidate, setOpenUpdateCandidate] = React.useState(false);
  const [openDeleteCandidate, setOpenDeleteCandidate] = React.useState(false);
  const [selectedCandidates, setSelectedCandidates] = React.useState([]);
  const [selectedPool, setSelectedPool] = React.useState(false);

  const getAllPools = async () => {
    try {
      const res = await getHRProfile();
      res?.data?.pool?.length > 0 && setPools(res.data.pool);
      if (res?.data?.job_post?.length > 0) {
        const mappedJob = res.data.job_post.map((data) => data.job_title);
        const arrayUniqueByKey = [
          ...new Map(mappedJob.map((item) => [item, item])).values(),
        ];
        setJobReq(arrayUniqueByKey);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCandidates = async (pool_id) => {
    try {
      const res = await getCandidatesByPool({ pool_id });
      if (res?.data?.candidates) {
        setCandidates(res.data.candidates);
        setCandidate(res.data.candidates[0]);
        setCounts(res.data.counts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPoolIdByTitle = async (job_title) => {
    try {
      const res = await getPoolsByTitle({
        job_title,
      });
      console.log(res);
      let jobIds = [];
      if (res?.data?.length > 0) {
        jobIds = res.data
          .filter(({ is_draft }) => is_draft === 'false')
          .map(({ job_id }) => job_id);
      }
      return jobIds;
    } catch (error) {
      console.error(error);
    }
  };

  const addCandidatesToPool = async () => {
    setSelectedPool(true);
    try {
      let body = {
        id: selectedPool,
        candidates:
          selectedCandidates?.length > 0 &&
          selectedCandidates?.map((_id) => ({
            canidate_profile_id: _id,
          })),
      };
      const res = await addToPool(body);
      setSelectedPool(false);
    } catch (error) {
      setSelectedPool(false);
      console.error(error);
    }
    setSelectedPool(false);
  };

  const handleChangeData = (e) => {
   // setSelectedPool(e.target.value);
  };

  useEffect(() => {
    getAllPools();
  }, []);

  return (
    <>
      <CandidatePoolWrapper
        title='Candidate pool'
        icon={<AddCircleOutlineTwoToneIcon />}
        handleClickAdd={() => {
          setOpenAddCandidate(true);
        }}
      >
        <div className='flex flex-col'>
          <div className=''>
            <SuccessBtn
              customStyle={{ marginBottom: '14px' }}
              onClick={() => getCandidates()}
            >
              All candidates
            </SuccessBtn>
          </div>
          {pools?.length > 0 &&
            pools.map((pool) => (
              <div className='flex justify-between py-3'>
                <span
                  className='text-base text-pirmaryBlue cursor-pointer'
                  onClick={() => getCandidates(pool._id)}
                >
                  {pool.candidate_pool_name}
                </span>
                <span className=''>
                  <EditOutlinedIcon
                    onClick={() => setOpenUpdateCandidate(pool)}
                    className='text-secondaryBalck cursor-pointer'
                  />
                  <DeleteOutlineOutlinedIcon
                    onClick={() => setOpenDeleteCandidate(pool._id)}
                    className='text-secondaryBalck cursor-pointer ml-1'
                  />
                </span>
              </div>
            ))}
          {/* <div className='flex justify-between py-3'>
            <span className='text-base text-pirmaryBlue'>Java Developers</span>
            <span className=''>
              <EditOutlinedIcon
                onClick={() => setOpenUpdateCandidate(true)}
                className='text-secondaryBalck cursor-pointer'
              />
              <DeleteOutlineOutlinedIcon
                onClick={() => setOpenDeleteCandidate(true)}
                className='text-secondaryBalck cursor-pointer ml-1'
              />
            </span>
          </div>
          <div className='flex justify-between py-3'>
            <span className='text-base text-pirmaryBlue'>Test</span>
            <span className=''>
              <EditOutlinedIcon
                onClick={() => setOpenUpdateCandidate(true)}
                className='text-secondaryBalck cursor-pointer'
              />
              <DeleteOutlineOutlinedIcon
                onClick={() => setOpenDeleteCandidate(true)}
                className='text-secondaryBalck cursor-pointer ml-1'
              />
            </span>
          </div> */}
        </div>
      </CandidatePoolWrapper>

      <WhiteBox
        title='Select users and add them into pool'
        titleStyle={{ fontSize: '24px', color: '#384259' }}
      >
        <SelectPoolField
          selectItems={pools}
          label=''
          labelId='jobFunction'
          id='jobFunction'
          // value={data.jobFunction}
          onChange={handleChangeData}
          name='jobFunction'
          placeholder='Select'
          inputStyle={{ padding: '14px' }}
          isCustom={true}
        />
        <SuccessBtn
          customStyle={{ marginTop: '15px', marginBottom: '15px' }}
          onClick={addCandidatesToPool}
          disabled={selectedPool}
        >
          Add
        </SuccessBtn>

        <form>
          {candidates.length > 0 &&
            candidates.map((candidate, id) => (
              <ProfileCheckbox
                key={id}
                id={id}
                candidate={candidate}
                src={Condidate1}
                setCandidate={setCandidate}
                setSelectedCandidates={setSelectedCandidates}
              />
            ))}
        </form>
      </WhiteBox>

      <div className='flex justify-center lg:justify-start'>
        <SuccessBtn className='text-[#7AB794] text-base font-bold'>
          Check team chemistry from the pool
        </SuccessBtn>
      </div>

      <AddCandidate
        open={openAddCandidate}
        setOpen={setOpenAddCandidate}
        getAllPools={getAllPools}
        jobReq={jobReq}
        getJobIds={getPoolIdByTitle}
      />
      <UpdateCandidate
        open={openUpdateCandidate}
        setOpen={setOpenUpdateCandidate}
        getAllPools={getAllPools}
        jobReq={jobReq}
        getJobIds={getPoolIdByTitle}
      />
      <DeleteCandidate
        open={openDeleteCandidate}
        setOpen={setOpenDeleteCandidate}
        getAllPools={getAllPools}
      />
    </>
  );
}

export default Leftbar;
