import React from "react";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import Modal from "../../../sharedComponents/modal/Modal";

function PurposeStatementModal({ open, setOpen, setDisable, saveStatement, data }) {
  const [statement, setStatement] = React.useState('');
  const handelOnClick = async () => {
    if (statement) {
      await saveStatement({
        id: data?.id || '',
        personal_statement: statement,
      });
      setDisable(false);
      setOpen(false);
    }
  };

  React.useEffect(() => {
    setStatement(data?.personal_statement || '')
  }, [data]);

  return (
    <Modal
      title="Personal purpose statement (PPS)"
      open={open}
      setOpen={setOpen}
      xlUpWidth={1150}
      lgUpWidth={1050}
      mdUpWidth={850}
    >
      <h2 className="text-lg text-pirmaryBlue font-bold mb-4">
        Write down your personal purpose statement below.
      </h2>

      <textarea
        value={statement}
        name=""
        id=""
        cols="10"
        rows="25"
        className="text-base text-[#828282] outline-none resize-none
       p-[18px] border-2 border-solid border-[#E3E6E9]  rounded w-full "
        placeholder="Explain your problem..."
        onChange={(e) => setStatement(e.target.value)}
      ></textarea>

      <div className="flex justify-end items-center">
        <div>
          {/* <SuccessBtn customStyle={{ marginTop: "20px" }}>Edit</SuccessBtn> */}
          <SuccessBtn
            customStyle={{ marginLeft: "32px", marginTop: "20px" }}
            onClick={handelOnClick}
          >
            Save
          </SuccessBtn>
        </div>
      </div>
    </Modal>
  );
}

export default PurposeStatementModal;
