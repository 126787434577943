
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const tabsStyle = makeStyles({
  root: {
    flexGrow: 1,
    color: "#273164",
    textAlign: "right",
    borderBottom: "1px solid #E3E6E9  !important",
    "& .MuiBox-root": {
      padding: "0 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#7AB794 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },
  tab: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    // borderBottom: "1px solid #E3E6E9 !important",
    "&.Mui-selected": {
      color: "#6DA183 !important",
      fontSize: "16px",
      fontWeight: "700",
    },
  },
});

export const checkboxStyle = makeStyles({
  root: {
    "& .Mui-checked": {
      color: "#7AB693 !important",
    },
    "& .MuiFormControlLabel-label": {
      color: "#828282",
    },
  },
});

export default function InfoBoxes({
  title,
  icon,
  children,
  TooltipTitle,
  TooltipText,
  handleClickAdd,
}) {
  return (
    <>
      <div className="">
        <div className="flex items-center">
          {title && <p className="font-bold text-2xl ">{title}</p>}
          <Tooltip
            placement="top"
            title={
              <div className="rounded-md">
                {TooltipTitle && 
                <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                  {TooltipTitle}
                </div>}
                <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                  {TooltipText}
                </div>
              </div>
            }
            arrow
          >
            <InfoIcon
              className="text-secondaryBalck cursor-pointer"
              fontSize="small"
              sx={{ marginLeft: "5px" }}
            />
          </Tooltip>

          <span
            className="text-secondaryBalck cursor-pointer relative top-1"
            onClick={handleClickAdd}
          >
            {/* <EditOutlinedIcon className='text-secondaryBalck cursor-pointer' /> */}
            {icon}
          </span>
        </div>
        {children}
      </div>
    </>
  );
}

export const formValidate = (values) => {
  const myError = 0;
  if (!values.city) {
    toast.error("City Name is required!");
    return 1;
  }
  if (!values.country) {
    toast.error("Country is required!");
    return 1;
  }
  return myError;
};
