export const getCompetencyTotal = (assessments = []) => {
  let total = 0;

  if (assessments.length > 0) {
    let roleBasedCompetencies = assessments.find(
      ({ assessment }) =>
        assessment.assessment_type === 'RoleBasedCompetanceAssessment'
    );
    if (roleBasedCompetencies?.assessment?.result) {
      let data = roleBasedCompetencies.assessment.result.map(
        ({ value }) => value
      );
      total = data.reduce((a, b) => Number(a) + Number(b), 0) / data.length;
    }
  }

  return total.toFixed(0);
};
