import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import DangerLightBtn from "../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import { DatePickerStyles } from "../../../sharedComponents/materialStyles";
import { useFormik } from "formik";
import { addTrainingJC, deleteTrainingJC} from "../../../../../api/jc";
import { toast } from "react-toastify";
import moment from "moment";
import cx from "classnames";

export default function TrainingsModal({ handleReload, selected, setSelected }) {
  const themeDataPicker = DatePickerStyles();
  const [initialValues, setInitialValues] = useState({
    institution_ame: "",
    name_of_certificate: "",
    field_of_study: "",
    start_date: "",
    end_date: "",
    description: "",
  });
  const handleSubmit = (values) => {

      if (Object.keys(selected).lenght !== 0) {

        let data ={
          id: formik.values._id,
          description: formik.values.description,
          end_date: formik.values.end_date,
          field_of_study: formik.values.field_of_study,
          institution_ame: formik.values.institution_ame,
          name_of_certificate: formik.values.name_of_certificate,
          start_date: formik.values.start_date,
          __v: formik.values.__v
        }


        // let times = new Date(...data.start_date.split("-"));
        let temp = {
          ...data,
          start_date: new Date(...data.start_date.split("-")),
          end_date: new Date(...data.end_date.split("-")),
        };

        addTrainingJC(temp).then((res) => {
          console.log(res.data);
          toast.success("Successfully done.");
          handleReload();
        });
        console.log(formik.values);

      } else {

          // let times = new Date(...formik.values.start_date.split("-"));
          let temp = {
            ...formik.values,
            start_date: new Date(...formik.values.start_date.split("-")),
            end_date: new Date(...formik.values.end_date.split("-")),
          };
          console.log(formik.values);
          addTrainingJC(temp).then((res) => {
            console.log(res.data);
            toast.success("Successfully done.");
            handleReload();
          });

      }

  };
  const handleDelete = () => {
    console.log(selected._id);
    deleteTrainingJC({ id: selected._id }).then((res) => {
      toast.success("successfully removed.");
      handleReload();
      setSelected({});
    });
  };

  const validate = (values) => {
    const errors = {};
    
    if (!values.institution_ame) errors.institution_ame = true;
    if (!values.field_of_study) errors.field_of_study = true;
    if (!values.start_date) errors.start_date = true;
    if (values.start_date && values.end_date) {
      if (
        moment(values.start_date).isAfter(values.end_date) ||
        moment(values.start_date).isSame(values.end_date)
      )
        errors.date = true;
    }
  
    return errors;
  };

  const formik = useFormik({
    initialValues,
    handleSubmit,
    validate,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  useEffect(() => {
    if (Object.keys(selected).lenght !== 0)
      setInitialValues({
        ...selected,
        end_date: selected?.end_date ? moment(selected.end_date).format('yyyy-MM-DD') : '',
        start_date: selected?.start_date ? moment(selected.start_date).format('yyyy-MM-DD') : '',
      });
  }, [selected]);

  const { errors } = formik || {};

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
          <div className="flex flex-col">
            <label className="text-base text-pirmaryBlue">Start date *</label>
            <TextField
              id="date"
              // label="Birthday"
              type="date"
              error={errors.start_date || errors.date}
              defaultValue=""
              name="start_date"
              value={moment(formik.values.start_date).format('yyyy-MM-DD')}
              onChange={(e) => {
                formik.setFieldValue("start_date", e.target.value);
              }}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              className={themeDataPicker.root}
            />
            {errors.start_date && (
              <span className="text-red-500">This is required field</span>
            )}
            {errors.date && (
              <span className="text-red-500">start date should be less than end date</span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-base text-pirmaryBlue">End date</label>
            <TextField
              id="date"
              // label="Birthday"
              type="date"
              name="end_date"
              value={moment(formik.values.end_date).format('yyyy-MM-DD')}
              defaultValue=""
              onChange={(e) => {
                formik.setFieldValue("end_date", e.target.value);
              }}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              className={themeDataPicker.root}
            />
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">
              Name of certificate
            </label>
            <input
              value={formik.values.name_of_certificate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="name_of_certificate"
              type="text"
              placeHolder="Enter name of certificate"
              className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
            />
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">
              Institution name *
            </label>
            <input
              value={formik.values.institution_ame}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="institution_ame"
              type="text"
              placeHolder="Enter institution name"
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.institution_ame },
                "border-grayLight"
              )}
            />
            {errors.institution_ame && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">Field of study *</label>
            <input
              value={formik.values.field_of_study}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="field_of_study"
              type="text"
              placeHolder="Enter field of study"
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.field_of_study },
                "border-grayLight"
              )}
            />
            {errors.field_of_study && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">Description</label>
            <input
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="description"
              type="text"
              placeHolder="Enter description"
              className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
            />
          </div>
          <div className="mt-2 col-span-2 flex justify-between">
            <div>
              {Object.keys(selected).length > 0 && (
                <DangerLightBtn onClick={handleDelete} type="button">
                  <span className="font-bold">Delete training</span>
                </DangerLightBtn>
              )}
            </div>
            <SuccessBtn type="submit">
              <span className="font-bold">
                {Object.keys(selected).length > 0 ? 'Update training' : 'Add training'}
              </span>
            </SuccessBtn>
          </div>
        </div>
      </form>
    </>
  );
}
