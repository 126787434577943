import { makeStyles } from "@mui/styles";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import condidate1 from "../../../../../../assets/condidate1.svg";
import condidate2 from "../../../../../../assets/condidate2.svg";

export function CustomTooltip({ TooltipTitle, TooltipText }) {
  return (
    <Tooltip
      placement="top"
      title={
        <div className="rounded-md">
          <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
            {TooltipTitle}
          </div>
          <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
            {TooltipText}
          </div>
        </div>
      }
      arrow
    >
      <InfoIcon
        className="text-secondaryBalck cursor-pointer"
        fontSize="small"
        sx={{ marginLeft: "5px" }}
      />
    </Tooltip>
  );
}

export const useStyle = makeStyles({
  table: {
    "& .MuiTableRow-head": {
      "& .MuiTableCell-head": {
        background: "#F4F6F8",
        fontWeight: "700",
        color: "#384259",
        fontSize: "16px",
      },
    },
  },
  pagination: {
    margin: "24px 0px 30px 0px",

    "& .MuiSvgIcon-fontSizeMedium": {
      zIndex: 200,
      background: "red",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      opacity: 0,
    },
    "& .MuiPaginationItem-page": {
      border: " 1px solid #E5E5E5",
      borderRadius: "0px",
    },
    "& .Mui-selected": {
      background: "#00000000 !important",
      borderRadius: "0px",
      border: "1px solid #7AB794",
      ":hover": {
        // backgroundColor: "transparent",
      },
    },
  },
});

export const CreateActionBtn = ({ editOnClick, deleteOnClick }) => {
  return (
    <span className="flex items-center">
      <ModeEditOutlineOutlinedIcon
        sx={{
          marginRight: "10px",
          background: "rgba(244, 246, 248, 0.61);",
          padding: "4px",
          width: "34px",
          height: "34px",
          borderRadius: "4px",
          color: "#828282",
          cursor: "pointer",
        }}
        onClick={editOnClick}
      />
      <DeleteOutlineOutlinedIcon
        sx={{
          background: "rgba(244, 246, 248, 0.61);",
          padding: "4px",
          width: "34px",
          height: "34px",
          borderRadius: "4px",
          color: "#828282",
          cursor: "pointer",
        }}
        onClick={deleteOnClick}
      />
    </span>
  );
};


const CreateAvatar = ({ name, lastName, src, alt }) => {
  return (
    <span className="flex items-center text-base text-[#384259]">
      <Avatar src={src} alt={alt} sx={{ marginRight: "10px" }} /> {name}{" "}
      {lastName}
    </span>
  );
};

export const columns = [
  { id: "name", label: "Name", minWidth: 180 },
  { id: "email_id", label: "Email", minWidth: 150 },
  {
    id: "phone_number",
    label: "Mobile",
    minWidth: 100,
    align: "left",
  },
  {
    id: "address",
    label: "Address",
    minWidth: 250,
    align: "left",
  },
  // {
  //   id: "jobApplied",
  //   label: "Job Applied",
  //   minWidth: 180,
  //   align: "left",
  // },
  {
    id: "action",
    label: "Action",
    minWidth: 60,
    align: "left",
  },
];

export const rows = [
  {
    id: 1,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    email: "robert@tesla.com",
    mobile: 9876543210,
    address: "221 B, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 2,
    name: <CreateAvatar src={condidate2} name="Alexis " lastName="Binny" />,
    email: "test@test.com",
    mobile: 1234567890,
    address: "233 Ba, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 3,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    email: "robert@tesla.com",
    mobile: 9876543210,
    address: "221 B, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 4,
    name: <CreateAvatar src={condidate2} name="Alexis " lastName="Binny" />,
    email: "test@test.com",
    mobile: 1234567890,
    address: "233 Ba, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 5,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    email: "robert@tesla.com",
    mobile: 9876543210,
    address: "221 B, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 6,
    name: <CreateAvatar src={condidate2} name="Alexis " lastName="Binny" />,
    email: "test@test.com",
    mobile: 1234567890,
    address: "233 Ba, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 7,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    email: "robert@tesla.com",
    mobile: 9876543210,
    address: "221 B, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 8,
    name: <CreateAvatar src={condidate2} name="Alexis " lastName="Binny" />,
    email: "test@test.com",
    mobile: 1234567890,
    address: "233 Ba, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 9,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    email: "robert@tesla.com",
    mobile: 9876543210,
    address: "221 B, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 10,
    name: <CreateAvatar src={condidate2} name="Alexis " lastName="Binny" />,
    email: "test@test.com",
    mobile: 1234567890,
    address: "233 Ba, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 11,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    email: "robert@tesla.com",
    mobile: 9876543210,
    address: "221 B, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 12,
    name: <CreateAvatar src={condidate2} name="Alexis " lastName="Binny" />,
    email: "test@test.com",
    mobile: 1234567890,
    address: "233 Ba, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 13,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    email: "robert@tesla.com",
    mobile: 9876543210,
    address: "221 B, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 14,
    name: <CreateAvatar src={condidate2} name="Alexis " lastName="Binny" />,
    email: "test@test.com",
    mobile: 1234567890,
    address: "233 Ba, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 15,
    name: <CreateAvatar src={condidate1} name="Robert " lastName="Binny" />,
    email: "robert@tesla.com",
    mobile: 9876543210,
    address: "221 B, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
  {
    id: 16,
    name: <CreateAvatar src={condidate2} name="Alexis " lastName="Binny" />,
    email: "test@test.com",
    mobile: 1234567890,
    address: "233 Ba, Baker  Street, London, UK ",
    jobApplied: "Management Consulting",
  },
];
