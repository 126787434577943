import React from "react";

function Slide1() {
  return (
    <>
      <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
        Critical thinking
      </h5>
      <p className="text-base text-pirmaryBlue  mb-4">
        This report and its corresponding graph help you understand the importance and role of your critical decision-making style in your daily life, as it forms the foundation of your overall performance.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        As <span className="text-[#7AB794]">Peter Drucker</span> said, "You cannot manage what you cannot measure." Therefore, this report aims to measure your critical thinking assessment in six distinct but integrated areas or perspectives Dr. Robert S. Hartman discovered.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        The graph in this section illustrates the measurement of these six independent dimensions of thought: External and Internal dimensions, each with two significant components that combine your critical thinking.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        The graph portrays the six unique dimensions of your critical thinking: <span className="text-[#8B5086] font-bold">External</span> (Empathy, System Judgment, and Practical Thinking) and <span className="text-[#7AB794] font-bold">Internal</span> (Role Awareness, Self-Esteem, and Self-Direction).
      </p>
    </>
  );
}

export default Slide1;
