import React from "react";
import { toast } from "react-toastify";
import { sendEmailToVerify } from "../../../../api/hr";
import SuccessBtn from "../../sharedComponents/buttons/successBtn/SuccessBtn";
import Modal from "../../sharedComponents/modal/Modal";

const ForgotPassword = ({ forgotPassword, setForgotPassword, email = "" }) => {
  const [value, setValue] = React.useState("");
  const getResetLink = async () => {
    try {
      await sendEmailToVerify(value);
      toast.success("Reset link sent, please check your inbox!");
      setForgotPassword(false);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    email && setValue(email);
  }, [email]);

  return (
    <>
      <Modal
        title="Password reset"
        open={forgotPassword}
        setOpen={setForgotPassword}
        mdUpWidth={550}
      >
        <div className="font-bold text-[14px] mt-[10px] mb-7">
          Enter your email, and we will send you the link to reset your
          password.
        </div>
        <div className="flex flex-col">
          <label className="text-base text-pirmaryBlue">Email *</label>
          <input
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            type="text"
            placeHolder="Enter your email"
            className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
          />
        </div>
        <SuccessBtn onClick={getResetLink} className="mt-5" type="submit">
          <span className="font-bold">Send me the reset link</span>
        </SuccessBtn>
      </Modal>
    </>
  );
};

export default ForgotPassword;
