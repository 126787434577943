import "react-circular-progressbar/dist/styles.css";
import CircleProgressBar from "../../../sharedComponents/circleProgressBar/CircleProgressBar";
import IconYellow from "../../../../assets/characteristicIconYellow.svg";
import TickText from "../../../sharedComponents/tickText/TickText";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export default function Interactive({ setOpenModal, data }) {
  const content = useSelector(
    (state) =>
      state.user?.assessments
        ?.find(
          ({ assessment }) =>
            assessment?.assessment_type === 'BehaviorAssessment'
        )
        ?.assessment?.assessment_content?.score?.find(({ type }) => type === 'I')
        ?.statements
  );
  const [allData, setAllData] = useState({})
  useEffect(() => {
    setAllData(data.filter(i => i.type === "I")[0])
  }, [data])
  return (
    <div className="pt-4">
      <p className="text-[#e5c311] font-bold text-2xl mb-2">Interactive</p>
      <div className="text-base font-bold text-pirmaryBlue">
        Approach to interacting with people and displaying emotions.
      </div>
      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="text-base lg:col-span-8 col-span-1 text-pirmaryBlue mt-4 mb-10 pr-4">
          <span className="font-bold text-[#e5c311]">HIGHER I </span> individuals with a higher I score tend to approach meeting new people in an outgoing, socially assertive, and gregarious manner, particularly when encountering individuals they haven't met before. They are talkative, interactive, and open, even with people they've just met. However, individuals with higher I scores may also exhibit impulsive behavior.
        </div>
        <div className=" lg:col-span-2 col-span-1 px-4 py-4 justify-self-center">
          <CircleProgressBar percentage={allData && Number(allData?.value)} pathColor="#e5c311" />
        </div>
      </div>
      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="text-base lg:col-span-8 col-span-1 text-pirmaryBlue mt-4 mb-10">
          <span className="font-bold text-[#e5c311]">LOWER I </span> individuals with a lower I score tend to approach meeting new people more controlled, quiet, and reserved, particularly when encountering individuals they haven't met before. They tend to be talkative with their friends and close associates but may appear more reserved when interacting with people they have recently met. In addition, they often prioritize controlling their emotions and tend to approach new relationships in a more reflective manner rather than an emotional one.
        </div>
        <div className=" lg:col-span-2 col-span-1 px-4 py-4 justify-self-center">
          <CircleProgressBar percentage={allData && Number(allData?.adaptiveValue)} pathColor="grey" />
        </div>
      </div>

      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="lg:col-span-8 col-span-1 mt-4 mb-10 lg:w-[90%]">
          {content?.length && (
            <>
              <div className="text-base font-bold mb-4">{content[0]}</div>
              {content.slice(1, content.length - 1).map((data, i) => <TickText key={i} text={data} />)}
            </>
          )}


          <div className=" text-secondaryBalck text-sm mt-4">
            * High score doesn’t mean good, and a low score doesn’t mean bad, as
            this is a spectrum or continuum of BEHAVIORAL TRAITS.
          </div>
        </div>
        <div
          className="lg:col-span-2 col-span-1 mt-4 mb-10 flex flex-col
          items-center text-center cursor-pointer"
          onClick={() => setOpenModal(true)}
        >
          <div className="w-[100px] h-[100px]">
            <img src={IconYellow} alt="" className="w-full" />
          </div>
          <p className="text-sm font-bold text-pirmaryBlue mt-4">
            Observable characteristic of High <br />{" "}
            <span className="text-[#e5c311]">Interactive</span> behavior <Tooltip placement="bottom"
              title={
                <div className="rounded-md">
                  <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                    Interactive Behavior
                  </div>
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Interactive individuals excel in communication, empathy, active participation, and collaboration. They adapt their interaction styles to diverse people and situations and are open-minded, respectful, and socially aware. Their positive attitude encourages others to interact freely, fostering a supportive and engaging environment.
                  </div>
                </div>
              }
              arrow
            >
              <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
            </Tooltip>
          </p>
        </div>
      </div>
    </div>
  );
}
