import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const useStyle = makeStyles((theme) => ({
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E3E6E9 !important",
      backgroundColor: "transparent !important",
      top: 0,

      "& .css-1in441m": {
        display: "none",
      },
      "& .css-1ftyaf0": {
        display: "none",
      },
    },
    "& .MuiSelect-nativeInput": {
      bottom: "50%",
      transform: "translateY(50%)",
      left: "20px !important",
      opacity: 0,
    },
    "& .MuiSelect-icon": {
      right: "20px !important",
      fill: "#7E8B96 !important",
    },
    "& .MuiInputBase-input": {
      padding: "0px",
    },
  },
  inputLabel: {
    "&.Mui-focused ": {
      color: "#384259 !important",
    },
    "&.MuiInputLabel-root": {
      color: "#384259 !important",
      fontSize: "16px",
      transform: "none !important",
      position: "static !important",
    },
  },
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const tabsStyle = makeStyles({
  root: {
    flexGrow: 1,
    color: "#273164",
    textAlign: "right",
    borderBottom: "1px solid #E3E6E9  !important",
    "& .MuiBox-root": {
      padding: "0 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#7AB794 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },
  tab: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    // borderBottom: "1px solid #E3E6E9 !important",
    "&.Mui-selected": {
      color: "#6DA183 !important",
      fontSize: "16px",
      fontWeight: "700",
    },
  },
});

export function SelectField({
  onChange,
  selectItems = [],
  label,
  labelId,
  id,
  value,
  name,
  placeholder,
  inputStyle,
  isCustom,
  isMultiple = false,
  required = false,
}) {
  const classes = useStyle();
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth className={classes.select}>
        <InputLabel id={labelId} className={classes.inputLabel}>
          {label && label}
          {required ? " *" : null}
        </InputLabel>
        <Select
          labelId={labelId}
          id={id}
          value={value}
          label="test"
          onChange={onChange}
          name={name}
          className={classes.select}
          sx={inputStyle}
          multiple={isMultiple}
        >
          {selectItems.map(({ id, name }, i) => (
            <MenuItem key={i} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
        <span
          className={`absolute top-[50%] left-[20px] text-[14px] text-[#828282] ${
            label ? "" : "translate-y-[-50%]"
          }  ${value ? "hidden" : "block"} `}
        >
          {placeholder}
        </span>
      </FormControl>
    </Box>
  );
}
