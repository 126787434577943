import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const useStyle = makeStyles((theme) => ({
  root: {},
  checkbox: {
    "& .Mui-checked": {
      color: "#7AB693 !important",
    },
    "& .MuiFormControlLabel-label": {
      color: "#828282",
    },
  },
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const tabsStyle = makeStyles({
  root: {
    flexGrow: 1,
    color: "#273164",
    textAlign: "right",
    borderBottom: "1px solid #E3E6E9  !important",
    "& .MuiBox-root": {
      padding: "0 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#7AB794 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },
  tab: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    // borderBottom: "1px solid #E3E6E9 !important",
    "&.Mui-selected": {
      color: "#6DA183 !important",
      fontSize: "16px",
      fontWeight: "700",
    },
  },
});
