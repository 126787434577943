import React from "react";
import BoxDescriptionInfo from "../../behavioralTraits/sharedcomponents/boxDescriptionInfo";
import { ReactComponent as Individualistic } from "../../../../assets/Individualistic.svg";
import { useSelector } from "react-redux";
import getTitle from "../reusableComponent";

// const list1 = [
//   "You like freedom in your own work area.",
//   "You sometimes surprise others with spontaneous ideas or responses.",
//   "You experience a feeling of accomplishment in being recognized for completing a tough assignment in a creative way.",
//   "Your pattern of responses indicates that you have a strong desire to be your own person. You enjoy working in your own way and own methods.",
// ];
// const list2 = [
//   "You prefer an environment where you have space to demonstrate your unique contributions to the team.",
//   "You may like to work apart from the team and independently at times.You should work with an idea, develop it, and run with it for a while before making a judgment.",
//   "You should work with an idea, develop it, and run with it for a while before making a judgment.",
//   "You should be allowed freedom to make your own decisions about how an assignment should be completed.",
//   "You should remember to find continuous opportunities to learn and progress.",
// ];
// const list3 = [
//   "You desire to be an individual and to celebrate differences.",
//   "You have the ability to take a stand and not be afraid to be different in either ideas or approaches to problem solving.",
//   "You are not afraid to take calculated risks.",
//   "You bring creative ideas.",
//   "You bring a variety of different and energetic ideas to the workplace.",
// ];
// const list4 = [
//   "You are able to be a flexible participant in training and development programs.",
//   "You tend to enjoy both team-oriented and individual or independent learning activities.",
//   "You will be a supportive member of the training experience from the viewpoint of this Values dimension.",
//   "Because this score is near the national mean, please check other higher and lower Values areas to obtain additional insight into learning preferences.",
// ];
// const list5 = [
//   "Without necessarily picking sides, you may need to take a stand on some issues related to individual agendas.",
//   "To gain additional insight, you should examine other values drives to determine the importance of this Individualistic drive factor.",
//   "You should allow space for those with higher Individualistic drives to express themselves in appropriate ways.",
//   "You should avoid criticizing those with higher or lower Individualistic drives, since all Values positions are positions deserving respect.",
// ];

function IndividualisticTab() {
  const data = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.valueDescriptor?.find(({ section }) => section === 'ind')?.subsection || []
  );

  const userLevel = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.score
  );

  return (
    <>
      <div className="flex md:flex-row flex-col my-6">
        <div className="flex flex-col items-center justify-center mr-6 mb-3 md:mb-0">
          <Individualistic className="scale-150 mb-2" />
          <p className="text-2xl text-[#ED7D33] font-bold">Individualistic</p>
        </div>
        {/* <p className="text-base text-pirmaryBlue">
          This dimension deals with one's need to be seen as unique,
          independent, and stand apart from the crowd. The individualistic is
          the drive that seeks social independence and has the opportunity for
          freedom of personal expression. Your score shows an Average score on
          Individualistic value. The following comments and information
          highlight the specifics to you:
          <div className="font-bold mt-5">
            You are not an extremist and can balance the needs of both others
            and yourself.
          </div>
        </p> */}
        <p className="text-base text-pirmaryBlue">
          The Individualistic dimension concerns the desire to be recognized as distinct, self-sufficient, and different from others. It reflects the drive for social independence and the freedom to express oneself.
          <div className="text-base text-pirmaryBlue mt-3">
            Your assessment score for this value is in the {userLevel[2]?.level} range.
          </div>
          <div className="text-base text-pirmaryBlue mt-3">
            This suggests that {userLevel[2]?.statement} However, remember that this is just one aspect of your overall value system, and other values may influence your choices differently.
          </div>
          <div className="font-bold mt-5">
            You are not an extremist and can balance the needs of both others
            and yourself.
          </div>
        </p>
      </div>

      <div className="grid mt-6 grid-cols-1 gap-6 md:grid-cols-2">
        {data?.map(({ title = '', statements = [] }, index) => (
          <BoxDescriptionInfo
            key={index}
            title={getTitle(title)}
            description=""
            caption=""
            list={statements}
          />
        ))}
      </div>
    </>
  );
}

export default IndividualisticTab;
