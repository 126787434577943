import React from "react";
import WrappercomponentProfile from "../../sharedComponents/wrappercomponentProfile";
import { Outlet, useLocation , useNavigate} from "react-router-dom";
import CryptoJS from 'crypto-js';
import config from "../../../../config/config";


function JcPanel() {
  const { pathname } = useLocation();
  const page = pathname.split("/coaching/")[1];
  const [pageTitle, setPageTitle] = React.useState(page);
  const history = useNavigate();
  
  const getData = async () => {
    try {
    console.log("getData Role");
      const ciphertext = localStorage.getItem("role");
      var bytes = CryptoJS.AES.decrypt(ciphertext, config.cookieSecret);
      var role = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (!(role == 'job_candidate')){
        history("/unauthorize");
      }
  } catch (e) {
    console.error(e);
  }
    
  }

  React.useEffect(() => {
    const pageUpperCase = page?.toUpperCase().split("-").join(" ");

    setPageTitle(pageUpperCase);
  }, [page]);

  React.useEffect(() => {
    getData();
  },[]);

  return (
    <WrappercomponentProfile routeName={pageTitle}>
      <Outlet />
    </WrappercomponentProfile>
  );
}

export default JcPanel;
