import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

function IconTooltip({ tooltipText, tooltipTitle }) {
  return (
    <Tooltip
      title={
        <div className="rounded-md">
          <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
            {tooltipTitle}
          </div>
          <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
            {tooltipText}
          </div>
        </div>
      }
      arrow
    >
      <InfoIcon
        className="text-secondaryBalck cursor-pointer"
        fontSize="small"
        sx={{ marginLeft: "5px" }}
      />
    </Tooltip>
  );
}

export default IconTooltip;
