import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./sortableItem";

const containerStyle = {
  "background-color": "#ffffff",
  "box-shadow": "0px 0px 4px rgb(0 0 0 / 10%)",
  "padding": "16px",
  "border-radius": "16px",
  "box-sizing": "border-box",
  "overflow-y": "auto",
  margin: 10,
  flex: 1,
};

export default function Container(props) {
  const { id, items } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} style={containerStyle}>
        {items?.map((id) => (
          <SortableItem key={id} id={id} />
        ))}
      </div>
    </SortableContext>
  );
}
