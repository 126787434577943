import React from 'react';
import SelectField from '../../../../../../sharedComponents/selectField/SelectField';
import moment from 'moment';

function PostingFunnels({ data, handleChangeData, formErrors }) {
  return (
    <form className="grid md:grid-cols-auto-fit-450 grid-cols-auto-fit-250 gap-4 mt-[24px]">
      <div className="flex flex-col">
        <SelectField
          selectItems={['Yes (Recommended)', 'No']}
          label='Applicants can apply through TRIPA'
          labelId='application_through_tripa'
          id='application_through_tripa'
          value={data.application_through_tripa}
          onChange={handleChangeData}
          name='application_through_tripa'
          placeholder='Select'
          inputStyle={{ padding: '14px' }}
          required
        />
        {formErrors.application_through_tripa && (
          <span className="text-red-500">{formErrors.application_through_tripa}</span>
        )}
      </div>
      <div className="flex flex-col">
        <SelectField
          selectItems={['Posted', 'Option 2', 'Option 3']}
          label='Add your job candidata funnel'
          labelId='job_funnel'
          id='job_funnel'
          value={data?.job_funnel || ''}
          onChange={handleChangeData}
          name='job_funnel'
          placeholder='Select'
          inputStyle={{ padding: '14px' }}
          disabled={true}
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="web_url" className="text-pirmaryBlue text-base font-normal">
          Direct your job applicants to your external web site (s) to apply.
        </label>
        <input
          type='text'
          name='web_url'
          value={data.web_url}
          onChange={handleChangeData}
          id='web_url'
          placeholder='www.yourdomain.com'
          className='focus:outline-none border-2 border-solid border-grayLight p-3 rounded w-full'
          disabled={true}
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="open_jobposting" className="text-pirmaryBlue text-base font-normal">
          Keep Job posting open until
        </label>
        <input
          id="open_jobposting"
          type="date"
          name="open_jobposting"
          value={data.open_jobposting}
          onChange={handleChangeData}
          min={moment().format("YYYY-MM-DD")}
          className="focus:outline-none border-2 border-solid border-grayLight p-3 rounded"
        />
      </div>
    </form>
  );
}

export default PostingFunnels;
