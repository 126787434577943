import React, { useEffect, useState } from "react";
import Modal from "../../../../../sharedComponents/modal/Modal";
import {
  getCandidateListForTeam,
  teamSaveUpdate,
  addCandidateToTeam,
  getTeamById,
} from "../../../../../../../api/hr";
import SuccessBtn from "../../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import { Autocomplete, Chip, CircularProgress, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";

import {
  ProfileCheckbox,
  CandidatePoolWrapper,
  SelectPoolField,
} from "../../candidatePool/reuseableComponent";

import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E3E6E9 !important",
      backgroundColor: "transparent !important",
      
      "& .css-1in441m": {
        display: "none",
      },
      "& .css-1ftyaf0": {
        display: "none",
      },
    },
    "& .MuiSelect-nativeInput": {
      bottom: "50%",
      transform: "translateY(50%)",
      left: "20px !important",
      opacity: 0,
    },
    "& .MuiSelect-icon": {
      right: "20px !important",
      fill: "#7E8B96 !important",
    },
    "& .MuiInputBase-input": {
      padding: "0px",
    },
  },
  inputLabel: {
    "&.Mui-focused ": {
      color: "#384259 !important",
    },
    "&.MuiInputLabel-root": {
      color: "#384259 !important",
      fontSize: "16px",
      transform: "none !important",
      position: "static !important",
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px",
      color: "#323131"
    }
  }
}));

export default function CreateTeam({ open, setOpen, id }) {
  const [teamName, setTeamName] = useState("");
  const [candidateList, setCandidateList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formErrors, setFormErrors] = React.useState({});

  const getCandidatesList = async () => {
    setLoading(true);
    const res = await getCandidateListForTeam();
    if (res?.status === 200 && (res?.data?.candidates || res?.data?.employees)) 
    {
      setCandidateList(res.data.candidates);
      setEmployeeList(res.data.employees)
    }
    setLoading(false);
  };

  useEffect(() => {
    getCandidatesList();
  }, []);

  const getTeamInfo = async (id) => {
    setLoading(true);
    const res = await getTeamById({ id: id });
    if (res?.status === 200) {
      setTeamName(res?.data?.team_name);
      console.log(candidateList)
      console.log(res?.data?.candidates)
      let selCan = []
      let selEmp = []
      res?.data?.candidates.map((candidate) => {
        let selectedCan = candidateList.find((cc) => cc?._id === candidate?.candidate_profile_id?._id)
        if(selectedCan){
          selCan.push(selectedCan)
        } 
        let selectedEmp = employeeList.find((cc) => cc?._id === candidate?.candidate_profile_id?._id)
        if(selectedEmp){
          selEmp.push(selectedEmp)
        }
      })
      setSelectedCandidates(selCan)
      setSelectedEmployees(selEmp)
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id !== null) {
      getTeamInfo(id);
    } else {
      setTeamName("");
      setSelectedCandidates([]);
      setSelectedEmployees([]);
    }
    setFormErrors({})
  }, [id]);

  const handleAutoCompeteData = (type,value) => {
    if(type=='candidates'){
      setSelectedCandidates(value);
    }else{
      setSelectedEmployees(value);
    }
  };

  // const handleChangeData = async(e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   getCandidatesList(value);
  // };

  const validate = (teamName,selectedTeamMember) => {
    let errors = {};
    
    if (!teamName) {
      errors.candidate_team_name = "This is required field";
    }
    
    if (selectedTeamMember && selectedTeamMember.length<=0) {
      errors.employees = "You need to select external candidates or employees";
    }else if (selectedTeamMember && selectedTeamMember.length<=1) {
      errors.employees = "You need to select at least 2 members for the team";
    }
    return errors;
  };
  
  const createTeamSubmit = async () => {
    let formErrors = {}
    formErrors = validate(teamName,[...selectedCandidates, ...selectedEmployees])
    setFormErrors(formErrors)
    if (Object.keys(formErrors).length === 0) {
      setSubmitLoading(true);
      let dataToSend = {
        team_name: teamName,
      };
      if (id !== null) {
        dataToSend = {
          ...dataToSend,
          id: id,
        };
      }
      const res = await teamSaveUpdate(dataToSend);
      if (res?.status === 200 && (res?.data?._id || id !== null)) {
        let selectedcandidateMember = [...selectedCandidates, ...selectedEmployees]
        const res2 = await addCandidateToTeam({
          id: id !== null ? id : res.data._id,
          candidates: selectedcandidateMember.map((candidate) => {
            return {
              candidate_profile_id: candidate?._id,
            };
          }),
        });
        if (res2?.status === 200) {
          setTeamName("")
          setSelectedCandidates([]);
          setSelectedEmployees([])
          toast.success(
            ["Team", id === null ? "created" : "updated", "successfully"].join(
              " "
            )
          );
          setOpen(false);
        } else {
          toast.error("Unable to add members to the team");
        }
      } else {
        toast.error(
          ["Unable to", id === null ? "create" : "update", "team"].join(" ")
        );
      }
      setSubmitLoading(false);
    }
  };

  const classes = useStyle();
  return (
    <Modal
      title={[id === null ? "Create" : "Update", "a team"].join(" ")}
      open={open}
      setOpen={setOpen}
    >
      <div className="flex flex-col my-4">
        <label htmlFor="candidate_team_name" className="text-pirmaryBlue">
          Team Name
        </label>
        <input
          type="text"
          name="candidate_team_name"
          value={teamName}
          onChange={(e) => {
            setTeamName(e.target.value);
          }}
          id="candidate_team_name"
          placeholder="Team Name"
          className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
          disabled={loading}
        />
        {formErrors.candidate_team_name && (
          <span className="text-red-500">{formErrors.candidate_team_name}</span>
        )}
      </div>
      <div className="flex flex-col">
        <label className="text-[17px] text-pirmaryBlue">
          Select team members
        </label>
        {/* <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue="candidates"
          onChange={handleChangeData}
        >
          <FormControlLabel value="candidates" control={<Radio />} label="External candidates" />
          <FormControlLabel value="employees" control={<Radio />} label="Employees" />
        </RadioGroup> */}
        <div className="pt-1">
        <label htmlFor="candidates" className="text-pirmaryBlue">
          External candidates
        </label>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
          <Autocomplete
            multiple={true}
            id='candidates'
            name='candidates'
            options={candidateList}
            getOptionLabel={(option) => option?.first_name+' '+option?.last_name}
            onChange={(event, val) => {
              handleAutoCompeteData('candidates',val)
            }}
            value={selectedCandidates}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option?.first_name+' '+option?.last_name}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderInput={(params) => (
              <TextField
                classes={{ root: classes.customTextField }}
                {...params}
                placeholder={'Select team members'}
              />
            )}
            className={`${
              classes.select
            }`}
          />
          {formErrors.candidates && (
            <span className="text-red-500">{formErrors.candidates}</span>
          )}
          </>
        )}
        </div>

        <div className="pt-3">
        <label htmlFor="employees" className="text-pirmaryBlue">
          Employees
        </label>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
          <Autocomplete
            multiple={true}
            id='employees'
            name='employees'
            options={employeeList}
            getOptionLabel={(option) => option?.first_name+' '+option?.last_name}
            onChange={(event, val) => {
              handleAutoCompeteData('employees',val)
            }}
            value={selectedEmployees}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option?.first_name+' '+option?.last_name}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderInput={(params) => (
              <TextField
                classes={{ root: classes.customTextField }}
                {...params}
                placeholder={'Select team members'}
              />
            )}
            className={`${
              classes.select
            }`}
          />
          {formErrors.employees && (
            <span className="text-red-500">{formErrors.employees}</span>
          )}
          </>
        )}
        </div>
        <SuccessBtn
          customStyle={{ marginTop: "15px" }}
          onClick={createTeamSubmit}
          disabled={submitLoading}
        >
          {id === null ? "Add" : "Update"}
        </SuccessBtn>
      </div>
    </Modal>
  );
}
