import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Footer from '../../sharedComponents/footer/footer';
import Header from '../../sharedComponents/header/header';
import WhiteBox from '../../sharedComponents/whiteBox/WhiteBox';

import { useStyle } from './reuseableComponent';

import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { addAskRecommendationJC } from '../../../../api/jc';

function Recommendation() {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [comment, setComment] = React.useState('');

  const handleSubmit = async (e) => {
    try {
      if (comment) {
        await addAskRecommendationJC({
          id,
          response: comment,
        });
        navigate('/');
      } else toast.error('Please enter comment!');
    } catch (error) {
      console.error(error);
    }
  };

  const classes = useStyle();

  return (
    <>
      <Header />
      <WhiteBox
        title='Give Recommendation'
        style={{ width: '100%', maxWidth: '700px', margin: '70px auto' }}
      >
        <div className='flex md:flex-row flex-col items-center w-[100%]'>
          <div className='flex flex-col my-3 w-[100%] md:pr-5'>
            <label
              htmlFor='firstName'
              className='text-pirmaryBlue text-base mb-2'
            >
              Message
            </label>
            <textarea
              name='comment'
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              id='comment'
              rows={6}
              placeholder='Enter message here...'
              className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
            ></textarea>
          </div>
        </div>

        <div className='w-full flex items-center justify-end'>
          <Button
            type='submit'
            variant='contained'
            color='success'
            className={classes.completeSignupBtn}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Link to='/business/dashboard'></Link>
        </div>
      </WhiteBox>
      <Footer />
    </>
  );
}

export default Recommendation;
