import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  subCompetenciesData
} from '../../../../../../contants/jobReqData';

import {
  a11yProps,
  TabPanel,
  tabsStyle,
  formValidate,
} from "./reuseableComponent";
import InfoBoxes from "./reuseableComponent";
import WhiteBox from "../../../../../sharedComponents/whiteBox/WhiteBox";
import SuccessBtn from "../../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import BackBtn from "../../../../../sharedComponents/buttons/secondaryBtn/SecondaryBtn";
import PurpleBtn from "../../../../../sharedComponents/buttons/purpleBtn/PurpleBtn";

// Tab components
import CompanyDetails from "./tabs/CompanyDetails";
import Skill from "./tabs/Skill";
import PostingFunnels from "./tabs/PostingFunnels";
import { postHRJobPost } from "../../../../../../../api/hr";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";

function JobRequisition({ changeTab, getAllJobs }) {
  const [image, setImage] = React.useState();
  const [formErrors, setFormErrors] = React.useState({});

  const [data, setData] = React.useState({
    companyName: "",
    jobTitle: "",
    jobFunction: "",
    companyIndustry: "",
    city: "",
    country: "",
    employmentType: "",
    salaryRangeFrom: 0,
    salaryRangeTo: 0,
    currency: "",
    seniorityLevel: "",
    yourMissionState: "",
    yourFocusState: "",
    whyYouState: "",
    whyUsState: "",
    your_mission: "",
    your_focus: "",
    why_you: "",
    why_us: "",

    skills: [],
    education: [],
    languages: [],
    experience: "",
    roleBasedCometencies: [],
    competencies: "",
    additionalRoleCompetencies: [],

    application_through_tripa: "",
    open_jobposting: "",
    job_funnel: "",
    web_url: "",
  });
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleImgFile = (e) => {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImage(e.target.result);
      setData((prev) => ({ ...prev, image: files[0] }));
    };
  };

  const deleteImage = () => {
    setImage();
    setData((prev) => ({ ...prev, image: "" }));
  };

  const handleChangeData = async(e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(name==='competencies'){
      let newVal = [];
      const subRoleCompData = subCompetenciesData?.find((x) => x.value === value);
      subRoleCompData.children.forEach(data => {
          newVal.push(data.value);
      })
      await setData((prev) => ({ ...prev, roleBasedCometencies: newVal }));
    }
    await setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAutoCompeteData = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoleBasedCometencies = (removedRole) => {
    const competenciesData = data.roleBasedCometencies?.filter((role) => role !== removedRole);
    setData((prev) => ({ ...prev, roleBasedCometencies: competenciesData }));
  };

  const handleAdditionalRoleBasedCometencies = (removedRole) => {
    const competenciesData = data.additionalRoleCompetencies?.filter((role) => role !== removedRole);
    setData((prev) => ({ ...prev, additionalRoleCompetencies: competenciesData }));
  };

  const handleNext = (event, val) => {
    if (val < 2) {
      setValue((prev) => prev + 1);
    } else {
      // submit
      const error = formValidate(data);
      if (!error) {
        event.target.nextElementSibling.click();
      }
    }
  };

  const validate = (values) => {
    let errors = {};
    
    if (!values.companyName) {
      errors.companyName = "This is required field";
    }
    if (!values.jobTitle) {
      errors.jobTitle = "This is required field";
    }
    if (!values.jobFunction) {
      errors.jobFunction = "This is required field";
    }
    if (!values.companyIndustry) {
      errors.companyIndustry = "This is required field";
    }
    if (!values.country) {
      errors.country = "This is required field";
    }
    if (!values.city) {
      errors.city = "This is required field";
    }
    if (!values.employmentType) {
      errors.employmentType = "This is required field";
    }
    if (!values.currency) {
      errors.currency = "This is required field";
    }
    if (!values.salaryRangeFrom) {
      errors.salaryRangeFrom = "This is required field";
    }
    if (!values.salaryRangeTo) {
      errors.salaryRangeTo = "This is required field";
    }
    if (!values.seniorityLevel) {
      errors.seniorityLevel = "This is required field";
    }
    
    if (values.skills && values.skills.length<=0) {
      errors.skills = "This is required field";
    }
    if (values.education && values.education.length<=0) {
      errors.education = "This is required field";
    }
    if (values.languages && values.languages.length<=0) {
      errors.languages = "This is required field";
    }
    if (!values.experience) {
      errors.experience = "This is required field";
    }
    if (!values.competencies) {
      errors.competencies = "This is required field";
    }    
    if (values.additionalRoleCompetencies && values.additionalRoleCompetencies.length>10) {
      errors.additionalRoleCompetencies = "You are only allowed to add a maximum of 10 additional competencies";
    }

    if (!values.application_through_tripa) {
      errors.application_through_tripa = "This is required field";
    }
        
    return errors;
  };

  const saveDraft = () => handleSubmit(true);

  const handleSubmit = async (saveDraft = false) => {
    try {
      let formErrors = {}
      if (!saveDraft) {
        formErrors = validate(data)
        setFormErrors(formErrors)
        
        if(formErrors.companyName || formErrors.jobTitle || formErrors.jobFunction || formErrors.companyIndustry || formErrors.country || formErrors.city || formErrors.employmentType || formErrors.currency || formErrors.salaryRangeFrom || formErrors.salaryRangeTo || formErrors.seniorityLevel){
          setValue(() => 0)
        }else if(formErrors.skills || formErrors.education || formErrors.languages || formErrors.experience || formErrors.competencies || formErrors.additionalRoleCompetencies){
          setValue(() => 1)
        }
      }

      if (Object.keys(formErrors).length === 0) {
        let body = {
          image: data.image,
          company_name: data.companyName,
          job_title: data.jobTitle,
          job_function: data.jobFunction,
          company_industry: data.companyIndustry,
          city: data.city,
          country: data.country,
          employment_type: data.employmentType,
          salary_currency: data.currency,
          salary_range_from: data?.salaryRangeFrom ? data?.salaryRangeFrom.replace(/\s/g, '') : '',
          salary_range_to: data?.salaryRangeTo ? data?.salaryRangeTo.replace(/\s/g, '') : '',
          seniority_level: data.seniorityLevel,
          your_mission: data.your_mission,
          your_focus: data.your_focus,
          why_you: data.why_you,
          why_us: data.why_us,
  
          skills: data.skills,
          education: data.education,
          languages: data.languages,
          experience: data.experience,
          additional_competencies: data.additionalRoleCompetencies,
          competencies: data.competencies,
          sub_competencies: data.roleBasedCometencies,
  
          application_through_tripa: data.application_through_tripa,
          web_url: data.web_url,
          job_funnel: data.job_funnel,
          open_jobposting: data.open_jobposting,
  
          is_draft: saveDraft,
        }

        await postHRJobPost(body)
        getAllJobs()
        changeTab(!saveDraft ? 1 : 2)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleBack = (val) => {
    if (val > 0) {
      setValue((prev) => prev - 1);
    }
  };

  const classes = tabsStyle();
  return (
    <>
      <WhiteBox
        title="What type of job do you want to post?"
        titleStyle={{ fontSize: "24px", fontWeight: "700" }}
      >
        <Tabs
          className={classes.root}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-scroller": {
              borderRadius: "4px",
              color: "white",
            },
            "&.MuiTabs-root": {
              "&.MuiTabs-scroller": {
                "&.MuiTabs-indicator": {
                  backgroundColor: "red !important",
                },
              },
            },
          }}
        >
          <Tab
            label="Company Details"
            {...a11yProps(0)}
            className={classes.tab}
          />
          <Tab
            label="Skill & Competencies Required"
            {...a11yProps(1)}
            className={classes.tab}
          />
          <Tab
            label="Posting Funnels"
            {...a11yProps(2)}
            className={classes.tab}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <CompanyDetails
            data={data}
            handleChangeData={handleChangeData}
            image={image}
            handleImgFile={handleImgFile}
            deleteImage={deleteImage}
            formErrors={formErrors}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Skill formErrors={formErrors} data={data} handleAutoCompeteData={handleAutoCompeteData} handleChangeData={handleChangeData} handleRoleBasedCometencies={handleRoleBasedCometencies} handleAdditionalRoleBasedCometencies={handleAdditionalRoleBasedCometencies} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PostingFunnels formErrors={formErrors} data={data} handleChangeData={handleChangeData} />
        </TabPanel>
      </WhiteBox>

      {value === 0 && (
        <>
          <WhiteBox>
            <InfoBoxes
              title="Your mission"
              TooltipTitle="Your mission"
              TooltipText="Provide a summary of the role and what the organization expects the job candidate to accomplish"
            >
              <Editor
                editorState={data.yourMissionState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(event) => {
                  let editorSourceHTML = draftToHtml(
                    convertToRaw(event.getCurrentContent())
                  );
                  setData((prev) => ({
                    ...prev,
                    your_mission: editorSourceHTML,
                    yourMissionState: event,
                  }));
                }}
              />
            </InfoBoxes>
          </WhiteBox>
          <WhiteBox>
            <InfoBoxes
              title="Your focus"
              TooltipTitle="Your focus"
              TooltipText="List the activities and tasks necessary to achieve the role's mission."
            >
              <Editor
                editorState={data.yourFocusState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(event) => {
                  let editorSourceHTML = draftToHtml(
                    convertToRaw(event.getCurrentContent())
                  );
                  setData((prev) => ({
                    ...prev,
                    your_focus: editorSourceHTML,
                    yourFocusState: event,
                  }));
                }}
              />
            </InfoBoxes>
          </WhiteBox>
          <WhiteBox>
            <InfoBoxes
              title="Why you"
              TooltipTitle="Why you"
              TooltipText="List education, skills, and abilities expect the job candidate has to achieve the mission."
            >
              <Editor
                editorState={data.whyYouState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(event) => {
                  let editorSourceHTML = draftToHtml(
                    convertToRaw(event.getCurrentContent())
                  );
                  setData((prev) => ({
                    ...prev,
                    why_you: editorSourceHTML,
                    whyYouState: event,
                  }));
                }}
              />
            </InfoBoxes>
          </WhiteBox>
          <WhiteBox>
            <InfoBoxes
              title="Why us"
              TooltipTitle="Why Us"
              TooltipText="Provide a succinct summary of the reasons why the job candidate should work for you."
            >
              <Editor
                editorState={data.whyUsState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(event) => {
                  let editorSourceHTML = draftToHtml(
                    convertToRaw(event.getCurrentContent())
                  );
                  setData((prev) => ({
                    ...prev,
                    why_us: editorSourceHTML,
                    whyUsState: event,
                  }));
                }}
              />
            </InfoBoxes>
          </WhiteBox>
        </>
      )}

      <div
        className={`flex md:flex-row flex-col my-3 md:my-0 items-center ${
          value !== 0 ? "justify-between " : "justify-end"
        }`}
      >
        {value !== 0 && (
          <BackBtn onClick={() => handleBack(value)}>Back</BackBtn>
        )}

        <div className="flex">
          <SuccessBtn onClick={saveDraft}>Save Draft</SuccessBtn>
          <PurpleBtn
            onClick={(e) => {
              if (value === 2) handleSubmit();
              else handleNext(e, value);
            }}
            customStyle={{ marginLeft: "10px" }}
          >
            {value === 2 ? "Submit" : "Next"}
          </PurpleBtn>
          <Link to="/business/create-candidate-test"></Link>
        </div>
      </div>
    </>
  );
}

export default JobRequisition;
