import {
  Avatar,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import WhiteBox from '../../../../../sharedComponents/whiteBox/WhiteBox';
import { columns, rows, useStyle } from '../reusableComponent';
import { ReactComponent as ArrowLeft } from '../../../../../../assets/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../../../assets/arrowRight.svg';
import { candidateListApi } from '../../../../../../../api/hr';
import { getCompetencyTotal } from '../../../../../../../utils/getCompetencyTotal';
import { getDriverColors } from '../../../../../../../utils/getColors';
import TextTooltip from '../../../../../sharedComponents/textTooltip/TextTooltip';
import CondidateProfileModal from '../../condidateList/modals/CondidateProfileModal';

const CandidateList = () => {
  const [page, setPage] = React.useState(1);
  const [candidates, setCandidates] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [candidProModal, setCandidProModal] = React.useState(false);
  const classes = useStyle();
  const handleChange = (event, value) => {
    setPage(value);
  };

  const CreateColorfulText = ({ text, color }) => {
    return (
      <span style={{ color: color, fontSize: '16px', cursor: 'pointer' }}>
        <TextTooltip
          tooltipTitle={text}
          tooltipText='The role-based competencies display the list of capacities resulting from your decision-making style’s unique combination (personal, practical, and analytical). It helps you understand your strengths and weaknesses that enable you to make the necessary changes to achieve your desired level of success.  '
        >
          {text}
        </TextTooltip>
      </span>
    );
  };

  const CreateAvatar = ({ name, lastName, src, alt, onClick }) => {
    return (
      <span
        className='flex items-center text-base text-[#384259] cursor-pointer'
        onClick={onClick}
      >
        <Avatar src={src} alt={alt} sx={{ marginRight: '10px' }} /> {name}{' '}
        {lastName}
      </span>
    );
  };

  const getCandidates = async (pool_id) => {
    try {
      const res = await candidateListApi({ pool_id });
      if (res?.data?.candidates?.length > 0) {
        let newObj = res.data.candidates.map((candidate, i) => {
          let {
            candidate: {
              canidate_profile_id = '',
              candidate_intreview_score_card = null,
            },
            job_requisition = '',
            job_id = '',
          } = candidate;
          let {
            first_name = '',
            last_name = '',
            matchSkill = '',
            assessment_type = '',
            driver_of_engagement = '',
            candidate_picture_upload_path = '',
            preferred_behavior = '',
            assessments = [],
          } = canidate_profile_id;
          let name = {
            name: first_name,
            lastName: last_name,
            src: candidate_picture_upload_path,
          };

          return {
            ...candidate,
            id: i + 1,
            sr: i + 1,
            name,
            candidate_profile_id: canidate_profile_id,
            candidate_intreview_score_card,
            jobReqId: job_id,
            jobReq: job_requisition,
            assesementType: assessment_type,
            competenciesScore: getCompetencyTotal(assessments) + '%',
            defaultBehavior: (
              <CreateColorfulText text={preferred_behavior} color={'#6DA183'} />
            ),
            topValue:
              driver_of_engagement?.length > 0 &&
              driver_of_engagement?.split(',').map((data, i) => (
                <span className={`text-[${getDriverColors(data)}]`} id={i}>
                  <CreateColorfulText
                    text={data}
                    color={getDriverColors(data)}
                  />
                  {i !== driver_of_engagement.split(',').length - 1 && ', '}
                </span>
              )),
            skillsMatch: matchSkill,
            interviewScoreCard: 5,
          };
        });
        setCandidates(newObj);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getCandidates();
  }, []);

  return (
    <WhiteBox
      title='Candidate details'
      titleStyle={{
        fontSize: '24px',
        color: '#384259',
        fontWeight: '400',
      }}
      style={{ margin: '0px', height: '100%' }}
    >
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 750 }} className={classes.table}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {candidates
                .slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        let value = row[column.id];

                        if (column.id === 'name') {
                          const nameData = row['name'];
                          value = (
                            <CreateAvatar
                              src={nameData.src}
                              name={nameData.name + ' ' + nameData.lastName}
                              onClick={() => {
                                console.log(row);
                                setCandidProModal(row);
                              }}
                            />
                          );
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex items-center justify-end'>
          <div className='relative mr-5'>
            <Pagination
              count={
                candidates.length < 10
                  ? 1
                  : Math.ceil(candidates.length / rowsPerPage)
              }
              page={page}
              onChange={handleChange}
              rowsPerPage={rowsPerPage}
              className={classes.pagination}
            />
            <ArrowLeft
              style={{
                position: 'absolute',
                left: 0,
                top: '48%',
                transform: 'translateY(-50%)',
                zIndex: 100,
                opacity: page - 1 === 0 ? 0.5 : 1,
              }}
            />
            <ArrowRight
              style={{
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 100,
                opacity:
                  page === Math.ceil(candidates.length / rowsPerPage) ? 0.5 : 1,
              }}
            />
          </div>
        </div>

        <CondidateProfileModal
          open={candidProModal}
          setOpen={setCandidProModal}
        />
      </Paper>
    </WhiteBox>
  );
};

export default CandidateList;
