import React from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Avatar } from "@mui/material";

export default function WorkExperience({handleSelectItem}) {
  const work_experience = useSelector(state => state.user.work_experience);

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontWeight: "700",
        // width: "56px",
        // height: "56px",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (fullName) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  return (
    <div className='flex flex-col divide-y'>
      {
        work_experience?.map((item) => (
          <div className='grid grid-cols-12 py-4 ' key={item._id}>
            <div className='col-span-1'>
            <Avatar {...stringAvatar(`${item?.company_name}`)} />
            </div>
            <div className='col-span-11 pl-2'>
              <div className='flex justify-between'>
                <span className='text-lg font-bold text-pirmaryBlue'> {item?.role} </span>
                <EditOutlinedIcon className='text-secondaryBalck cursor-pointer' onClick={() => handleSelectItem(item)} />
              </div>
              <div className='text-base leading-5 text-pirmaryBlue'>{item?.company_name}</div>
              <div className='text-secondaryBalck'>
                <span> {moment(item?.from_date).format('DD/MM/YYYY')} </span> to <span>{item?.isPresent ? 'Present' : moment(item?.to_date).format('DD/MM/YYYY')}</span>
              </div>
              <div className='text-base leading-5 text-pirmaryBlue font-bold mt-5 mb-2'>Experience summary</div>
              <div className='text-secondaryBalck text-sm leading-[18px]'>
                {item?.experience_summary}
              </div>
              <div className='text-base leading-5 text-pirmaryBlue font-bold mt-5 mb-2'>Achievements</div>
              <div className='text-secondaryBalck text-sm leading-[18px]'>
                {item?.achievements}
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}
