import React, { useState } from "react";
import Footer from "../../JCComponents/behaviorTraitsAssessment/shared/Footer";
import SidebarProfile from "../sidebarProfile/sidebarProfile";
import SignOutModal from "../signOutModal";

export default function WrapperComponentProfile({ children, routeName }) {
  const [openSide, setopenSide] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  
  return (
    <>
      <SidebarProfile
        routeName={routeName}
        openSide={openSide}
        setopenSide={setopenSide}
        openSignOut={openModal}
        setOpenSignOut={setOpenModal}
      />
      <div className="flex flex-col min-h-[100vh]">
        <div
          className={`${
            !openSide ? "lg:ml-[280px] " : ""
          }top-20 transition-all mt-20 mb-10 px-5 lg:px-16`}
        >
          <>{children}</>
        </div>
        <Footer openSide={openSide} />
      </div>

    <SignOutModal open={openModal}  setOpen={setOpenModal}/>
    </>
  );
}
