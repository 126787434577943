import React from 'react';
import {
  Checkbox,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import WhiteBox from '../../../../../sharedComponents/whiteBox/WhiteBox';
import { useStyle } from '../reusableComponent';
import { tabsStyle } from '../../condidateList/reusableComponent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { barChartApi } from '../../../../../../../api/hr';

const BarCharts = ({ pools = [] }) => {
  const [data, setData] = React.useState({ barChart: '', stackedChart: '' });
  const [selectedPools, setSelectedPools] = React.useState([]);
  let { barChart, stackedChart } = data;
  const classes = useStyle();
  // const tabClasses = tabsStyle();
  // const [value, setValue] = React.useState(0);
  const [dynamicBarData, setDynamicBarData] = React.useState();
  // const handleChangeValue = (event, newValue) => {
  //   setValue(newValue);
  //   switch (newValue) {
  //     case 0:
  //       getDynamicChartData();
  //       break;
  //     case 1:
  //       getDynamicChartData('quarterly');
  //       break;
  //     case 2:
  //       getDynamicChartData('yearly');
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const getChartsData = async (pool_ids = []) => {
    try {
      const res = await barChartApi({
        pool_ids: pool_ids?.length > 0 && pool_ids,
      });
      res?.data && setData(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDynamicChartData = (timePeriod = 'monthly') => {
    if (barChart && barChart[timePeriod]) {
      setDynamicBarData(barChart[timePeriod]);
    }
  };

  const handleChangePositoin = (event) => {
    console.log(event.target.value);
    const {
      target: { value },
    } = event;
    getChartsData(value);
    setSelectedPools(value);
  };
  const barOptions = {
    series: [
      {
        data:
          dynamicBarData?.job_candidate_count?.length > 0
            ? dynamicBarData.job_candidate_count.map((data) => data)
            : [],
      },
    ],
    options: {
      chart: {
        height: 'auto',
        type: 'bar',
        toolbar: {
          show: false,
        },
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors: ['#954A89', '#7AB794', '#CEDCD3'],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories:
          dynamicBarData?.job_requisition?.length > 0
            ? dynamicBarData.job_requisition.map((data) => data)
            : [],
        labels: {
          style: {
            colors: ['#2C2C2C'],
            fontSize: '12px',
          },
        },
      },
    },
  };

  const stackedOptions = {
    series:
      stackedChart?.stackData?.length > 0
        ? stackedChart.stackData.map(
            ({ job_requisition = '', candidatesMonthlyCount = [] }) => ({
              name: job_requisition,
              data: candidatesMonthlyCount,
            })
          )
        : [],
    options: {
      colors: [
        '#954A89',
        '#7AB794',
        '#CEDCD3',
        '#89C19E',
        '#C173B5',
        '#C173B5',
        '#6DA183',
        '#B07FAA',
      ],
      chart: {
        type: 'bar',
        height: 400,

        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      xaxis: {
        categories:
          stackedChart?.months?.length > 0
            ? stackedChart.months.map((month = '') => month)
            : [],
        // labels: {
        //   formatter: function (val) {
        //     return val + "K"
        //   }
        // }
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 10,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  React.useEffect(() => {
    if (data?.barChart) {
      getDynamicChartData();
    }
  }, [data]);

  React.useEffect(() => {
    getChartsData();
  }, []);
  return (
    <div className='grid xl:grid-cols-10 grid-cols-1 gap-4 my-5'>
      <div className='xl:col-span-6 col-span-1'>
        <WhiteBox
          title=''
          titleStyle={{
            fontSize: '24px',
            color: '#384259',
            fontWeight: '400',
          }}
          style={{ margin: '0px', height: '100%' }}
        >
          <div className='flex flex-wrap justify-between items-center'>
            <h2 className='text-2xl text-pirmaryBlue '>Candidate bar chart</h2>
            <div className='flex flex-wrap sm:flex-nowrap items-center'>
              <label
                htmlFor='selectLabel'
                className='mr-2 text-xl text-pirmaryBlue'
              >
                Positions:
              </label>{' '}
              <div className='sm:w-64 w-48'>
                <FormControl
                  sx={{ margin: '15px 0px', width: '100%' }}
                  className={classes.select}
                >
                  <Select
                    labelId='demo-multiple-checkbox-label'
                    id='demo-multiple-checkbox'
                    multiple
                    value={selectedPools}
                    onChange={handleChangePositoin}
                    // placeholder='Select position'
                    // input={<OutlinedInput label='Tag' />}
                    renderValue={(selected) =>
                      selected
                        .map((id) => {
                          let pool = pools.find(({ _id }) => _id == id);
                          return pool?.job_requisition || '';
                        })
                        .filter((data) => data !== '')
                        .join(', ')
                    }
                    sx={{
                      padding: '14px 40px 14px 14px',
                      borderRadius: '10px',
                      zIndex: '200',
                    }}
                  >
                    {pools?.map(({ _id, job_requisition = '' }, index) => (
                      <MenuItem
                        key={index}
                        value={_id}
                        className={classes.menuItem}
                      >
                        <Checkbox checked={selectedPools.indexOf(_id) > -1} />
                        {job_requisition}
                      </MenuItem>
                    ))}
                  </Select>
                  <span
                    className={`absolute top-[50%] translate-y-[-50%] left-[20px] text-[14px] text-[#828282] 
                     ${selectedPools.length === 0 ? 'block' : 'hidden'} `}
                  >
                    Select position
                  </span>
                  <span
                    className={`absolute top-[50%] translate-y-[-50%] right-[20px] text-[14px] text-[#828282] cursor-pointer`}
                  >
                    <KeyboardArrowDownIcon
                      sx={{
                        color: '#7AB794',
                        zIndex: '100',
                        fontSize: '30px',
                      }}
                    />
                  </span>
                </FormControl>
              </div>
            </div>
          </div>

          <div className='mt-5'>
            <ReactApexChart
              options={stackedOptions.options}
              series={stackedOptions.series}
              type='bar'
              height={350}
            />
            {/* <div
              className={`flex flex-wrap md:justify-around justify-start items-center md:w-[80%] mx-auto text-pirmaryBlue`}
            >
              {stackedOptions.options.xaxis.categories.map((item, index) => (
                <span
                  className='flex justify-center items-center mx-2 my-1'
                  key={index}
                >
                  <span
                    style={{
                      backgroundColor: `${stackedOptions.options.colors[index]}`,
                    }}
                    className={`h-3 mr-2 inline-block w-3 rounded-full`}
                  ></span>
                  <span className='text-base leading-5 text-ellipsis overflow-hidden whitespace-nowrap max-w-fit inline-block'>
                    {item}
                  </span>
                </span>
              ))}
            </div> */}
          </div>
        </WhiteBox>
      </div>
      <div className='xl:col-span-4 col-span-1'>
        <WhiteBox
          title='Candidate Bar Chart'
          titleStyle={{
            fontSize: '24px',
            color: '#384259',
            fontWeight: '400',
          }}
          style={{ margin: '0px', height: '100%' }}
        >
          <ReactApexChart
            height={450}
            width={'100%'}
            options={barOptions.options}
            series={barOptions.series}
            type='bar'
          />
        </WhiteBox>
      </div>
    </div>
  );
};

export default BarCharts;
