export const getDriverColors = (driverContent) => {
  driverContent = driverContent.toLowerCase();
  let color;
  switch (driverContent) {
    case 'aesthetic':
      color = '#A9D18E';
      break;
    case 'economic':
      color = '#4472C4';
      break;
    case 'individualistic':
      color = '#ED7D33';
      break;
    case 'political':
      color = '#C2342C';
      break;
    case 'altruistic':
      color = '#E5C311';
      break;
    case 'regulatory':
      color = '#7F7F7F';
      break;
    case 'theoretical':
      color = '#7F6000';
      break;
    default:
      break;
  }
  return color;
};


export const roleBaseColors = [
  '#737210',
  '#CA902C',
  '#AA554C',
  '#4FD1C5',
  '#7ABEEF',
  '#CA722C'
]

export const mapDriverOfEngagmentColors = {
  Aesthetic :"#A9D18E",
  Economic :"#4472C4",
  Individualistic :"#ED7D33",
  Political :"#C2342C",
  Altruistic :"#E5C311",
  Regulatory :"#7F7F7F",
  Theoretical :"#7F6000",
}

export const mapBehaviourColor = {
  I: {
    name: 'Interactive',
    color: '#e5c311',
  },
  D: {
    name: 'Decisive',
    color: '#ff0000',
  },
  S: {
    name: 'Steady',
    color: '#008000',
  },
  C: {
    name: 'Cautious',
    color: '#0000ff',
  },
};

export const mapBehaviourNameColor = {
  Interactive: {
    name: 'Interactive',
    color: '#e5c311',
  },
  Decisive: {
    name: 'Decisive',
    color: '#ff0000',
  },
  Steady: {
    name: 'Steady',
    color: '#008000',
  },
  Cautious: {
    name: 'Cautious',
    color: '#0000ff',
  },
};