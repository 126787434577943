import axios from "axios";
import { toast } from "react-toastify";
import config from "../../config/config";

export const baseURL = `${config.backEndUrl}/auth/api/tripa/`;

export const headerJson = () => {
  const token = localStorage.getItem("access");

  return {
    "Content-Type": "application/json",
    Authorization: `bearer ${token}`,
    "x-access-token": token,
  };
};

function headerFormData() {
  const token = localStorage.getItem("access");

  return {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `bearer ${token}`,
    "x-access-token": token,
  };
}

axios.defaults.withCredentials = true;
const Api = axios.create({
  baseURL: `${baseURL}`,
  timeout: 30000,
  withCredentials: true,
  credentials: "include",
});
Api.interceptors.response.use(
  function (response) {
    if (response?.data?.error) {
      toast.warning(response.data.error);
      return response;
    }
    return response;
    // should be edit ....
  },
  async function (error) {
    let errorMessage = "";
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        errorMessage = error.response.data.message;
        toast.warning(errorMessage);
        return Promise.reject(error);
      } 
      if (status === 403) {
        return false;
      } 
      if (status === 400) {
        errorMessage = error.response.data.message;
        toast.warning(errorMessage);
        return Promise.reject(error);
      }
      if (status === 404) {
        errorMessage = error.response.data.message;
        toast.warning(errorMessage);
        return Promise.reject(error);
      } 
      if (error.response.data.message === 'token is not valid!') {
        return false;
        // do nothing
      } else {
        errorMessage = error.response.data.message;
        toast.warning(errorMessage);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

// common apis

// changePassword
export const changePassword = (body) => {
  return Api.post("auth/changePassword", body, { headers: headerJson() });
};

// deactivate account
export const deactivateAccount = (body) => {
  return Api.post("auth/deactiveUser", body, { headers: headerJson() });
};

// delete account
export const deleteAccount = (body) => {
  return Api.post("auth/deleteAccount", body, { headers: headerJson() });
};

// auth-stripe
export const authStripeAPI = (body) => {
  return Api.post("auth/stripe", body, { headers: headerJson() });
};

export const updateSubscriptionStatus = (body) => {
  return Api.post("auth/stripe/updateSubscriptionStatus", body, { headers: headerJson() });
}

export const stripeCheckoutSession = (body) => {
  return Api.post("auth/stripe/createCheckoutSession", body, {
    headers: headerJson(),
  });
};

export const getSubscriptionData = (body) => {
  return Api.post("auth/stripe/getSubscription", body, {
    headers: headerJson(),
  });
};

export const cancelSubscription = (body) => {
  return Api.post("auth/stripe/cancelSubscription", body, {
    headers: headerJson(),
  });
};

export const getBillingHistory = (body) => {
  return Api.post("auth/stripe/invoices", body, { headers: headerJson() });
};

//

export const signInApiJC = (body) => {
  return Api.post("signin", body);
};

export const verifyEmailAddress = (token) => {
  return Api.post(`auth/confirm/${token}`);
};

export const reactiveAccount = (token) => {
  return Api.post(`auth/reactiveUser/${token}`);
};

export const getByUserId = () => {
  // console.log("getByUserId",headerJson());
  return Api.get("candidate/profile/findByUserId", { headers: headerJson() });
}

export const getPremiumStatus = (body) => {
  return Api.post("auth/stripe/getSubscriptionStatus", body, { headers: headerJson() });
}

export const getQuestions = (body) => {
  // console.log("getByUserId",headerJson());
  return Api.post("assessments/questions/getQuestionByAssessmentType", body, {
    headers: headerJson(),
  });
};

export const signUpApiJC = (body, referral) => {
  return Api.post(`signup/${referral}`, body);
};

// send email to verify
export const sendEmailToVerifyJC = (email) => {
  return Api.post("auth/sendresetPassword", {
    email_address: email,
    user_type: 'jc'
  });
};

export const sendVerifyMail = (email) => {
  return Api.post("sendVerifyMail", {
    roles: 'job_candidate',
    email_address: email,
  });
};

export const sendEmailToReactiveJC = (email) => {
  return Api.post("auth/sendReactive", {
    email_address: email,
    roles: 'job_candidate',
  });
};
// Choose a new password
export const resetPassword = ({ password, token }) => {
  return Api.post(`/auth/resetPassword/${token}`, {
    password,
  });
};

// verify email ==> ?

// **************** //
//      JC APIS     //
// **************** //

// profile
export const postJCProfile = (body) => {
  return Api.post("candidate/profile/saveAndUpdate", body, {
    headers: headerJson(),
  });
};

// remove profile photo
export const deleteProfilePhoto = () => {
  return Api.delete("candidate/profile/deleteLogoByUserId", {
    headers: headerJson(),
  });
};
// experience
export const postExperience = (body) => {
  return Api.post("candidate/workExperience/saveAndUpdate", body, {
    headers: headerJson(),
  });
};

// ask Recommendation JC save & update
export const addAskRecommendationJC = (body) => {
  return Api.post("candidate/askForRecommection/saveAndUpdate", body, {
    headers: headerJson(),
  });
};
// remove
export const deleteAskRecommendationJC = (body) => {
  return Api.delete("candidate/askForRecommection/deleteById", {
    data: body,
    headers: headerJson(),
  });
};

// certification
export const addCertificationJC = (body) => {
  return Api.post("candidate/certification/saveAndUpdate", body, {
    headers: headerJson(),
  });
};

// uploadQuestions
export const uploadQuestions = (body) => {
  return Api.post("candidate/assessment/questionsSave", body, {
    headers: headerJson(),
  });
};

// save assessment data
export const saveAssessment = (body) => {
  return Api.post("candidate/assessment/assessmentSave", body, {
    headers: headerJson(),
  });
};

export const saveAssessment1 = async (body) => {
const results = await Api.post("candidate/assessment/saveanswers1", body, {
  headers: headerFormData(),
});
console.log('assessment' , results.headers );
  return results;
}

export const saveAssessment2 = (body) => {
  return Api.post("candidate/assessment/saveanswers2", body, {
    headers: headerFormData(),
  });
};

export const aiSubmit = (body) => {
  return Api.post("candidate/assessment/aisubmit", body , {
    headers: headerFormData(),
  });
};

// remove
export const deleteCertificationJC = (body) => {
  return Api.delete("candidate/certification/deleteById", {
    data: body,
    headers: headerJson(),
  });
};

// education
export const addEducationJC = (body) => {
  return Api.post("candidate/education/saveAndUpdate", body, {
    headers: headerJson(),
  });
};
// remove
export const deleteEducationJC = (body) => {
  return Api.delete("candidate/education/deleteById", {
    data: body,
    headers: headerJson(),
  });
};

// language Skill
export const addLanguageSkillJC = (body) => {
  return Api.post("candidate/languageSkill/saveAndUpdate", body, {
    headers: headerJson(),
  });
};
// remove
export const deleteLanguageSkillJC = (body) => {
  return Api.delete("candidate/languageSkill/deleteById", {
    data: body,
    headers: headerJson(),
  });
};

// referral
export const addReferralJC = (body) => {
  return Api.post("candidate/referral/saveAndUpdate", body, {
    headers: headerJson(),
  });
};
// remove
export const deleteReferralJC = (body) => {
  return Api.delete("candidate/referral/deleteById", body, {
    headers: headerJson(),
  });
};

// skill
export const addSkillJC = (body) => {
  return Api.post("candidate/skill/saveAndUpdate", body, {
    headers: headerJson(),
  });
};
// remove
export const deleteSkillJC = (body) => {
  return Api.delete("candidate/skill/deleteById", {
    data: body,
    headers: headerJson(),
  });
};

// toJobPostApplication
export const addToJobPostApplicationJC = (body) => {
  return Api.post("candidate/toJobPostApplication/saveAndUpdate", body, {
    headers: headerJson(),
  });
};

// get all jobs
export const getAllJobs = () => {
  return Api.get("candidate/profile/jobsList", { headers: headerJson() });
};

// remove
export const deleteToJobPostApplicationJC = (body) => {
  return Api.delete("candidate/toJobPostApplication/deleteById", body, {
    headers: headerJson(),
  });
};

// training
export const addTrainingJC = (body) => {
  return Api.post("candidate/training/saveAndUpdate", body, {
    headers: headerJson(),
  });
};
// remove
export const deleteTrainingJC = (body) => {
  return Api.delete("candidate/training/deleteById", {
    data: body,
    headers: headerJson(),
  });
};

// work Experience
export const addWorkExperienceJC = (body) => {
  return Api.post("candidate/workExperience/saveAndUpdate", body, {
    headers: headerJson(),
  });
};
// remove

export const deleteWorkExperienceJC = (body) => {
  return Api.delete("candidate/workExperience/deleteById", {
    data: body,
    headers: headerJson(),
  });
};

// upload resume
export const postUploadResume = (body) => {
  return Api.post("candidate/resumes/parsing", body, { headers: headerJson() });
};

// Personal Statement

export const savePersonalStatement = (body) => {
  return Api.post("candidate/personalStatement/saveAndUpdate", body, {
    headers: headerJson(),
  });
};

// Personal Budget apis

export const savePersonalBudget = (body) => {
  return Api.post("candidate/personalBuget/saveAndUpdate", body, {
    headers: headerJson(),
  });
};

export const getPersonalBudget = (body) => {
  return Api.post("candidate/personalBuget/getPersonalBuget", body, {
    headers: headerJson(),
  });
};

export const getAnnualBudget = (body) => {
  return Api.post("candidate/personalBuget/getAnnualPersonalBuget", body, { headers: headerJson() });
}

export const isJobCandidateReturnBoolean = () => {
  return Api.get("candidate/checkRole", {
    headers: headerJson(),
  });
};

export const isCheckRole = () => {
  return Api.get("checkRole", {
    headers: headerJson(),
  });
};

export const googlelogin = () => {
  return Api.get("google/login/successfully");
};

export const githublogin = () => {
  return Api.get("github/login/successfully");
};

// *********************************************** //
// -------------- JC apis completed -------------- //
// *********************************************** //
