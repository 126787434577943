import TeslaLogo from "../../../assets/teslaLogo.svg";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

export const useStyle = makeStyles({
  table: {
    "& .MuiTableRow-head": {
      "& .MuiTableCell-head": {
        background: "#F4F6F8",
        fontWeight: "700",
        color: "#384259",
        fontSize: "16px",
      },
    },
  },
  pagination: {
    margin: "24px 0px 30px 0px",

    "& .MuiSvgIcon-fontSizeMedium": {
      zIndex: 200,
      background: "red",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      opacity: 0,
    },
    "& .MuiPaginationItem-page": {
      border: " 1px solid #E5E5E5",
      borderRadius: "0px",
    },
    "& .Mui-selected": {
      background: "#00000000 !important",
      borderRadius: "0px",
      border: "1px solid #7AB794",
      ":hover": {
        // backgroundColor: "transparent",
      },
    },
  },
  perview: {
    color: "#C4C4C4",
    cursor: "pointer",
  },
  title: {
    fontSize: "18px",
    color: "#384259",
    fontWeight: "700",
  },
  subTitle: {
    fontSize: "16px",
    color: "#384259",
    fontWeight: "700",
  },
  text: {
    fontSize: "14px",
    color: "#828282",
    fontWeight: "400",
  },
  checkCircle: {
    "&.MuiSvgIcon-root": { fill: "#7AB794 !important" },
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "15px!important",
  },
  [theme.breakpoints.up("md")]: {
    "& .MuiPaper-root": {
      minWidth: "800px",
    },
  },
  [theme.breakpoints.up("lg")]: {
    "& .MuiPaper-root": {
      minWidth: "950px",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& .MuiPaper-root": {
      margin: "20px",
      minWidth: "100%",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "#7AB794",
        color: "#ffffff",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function Modal({ title, children, open, setOpen, style }) {
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={style}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span className="font-bold text-lg">{title}</span>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ padding: "20px 30px 40px 30px !important" }}
        >
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
const CreateAvatar = ({ src, alt }) => {
  return <img src={src} alt={alt} className="mx-auto" />;
};


export const columns = [
  { id: "logo", label: "Logo", minWidth: 130, align: "center" },
  { id: "companyName", label: "Company name", minWidth: 170 },
  {
    id: "jobTitle",
    label: "Job title",
    minWidth: 230,
    align: "left",
  },
  {
    id: "employmentType",
    label: "Employment type",
    minWidth: 170,
    align: "left",
  },
  {
    id: "location",
    label: "Location",
    minWidth: 150,
    align: "left",
  },
  {
    id: "preview",
    label: "Preview",
    minWidth: 100,
    align: "center",
  },
  {
    id: "applicationStatus",
    label: "Application status",
    minWidth: 170,
    align: "left",
  },
];

export const rows = [
  {
    id: 1,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 2,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 3,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 4,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 5,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 6,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 7,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 8,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 9,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 10,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 1,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 12,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 13,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
  {
    id: 14,
    logo: <CreateAvatar src={TeslaLogo} />,
    companyName: "Tesla",
    jobTitle: "Management Consulting",
    location: "Veinna, Austria",
    employmentType: "Full time",
    applicationStatus: "In-progress",
  },
];
