import React from "react";
import BehavioralTraitsSlide5 from "../../../../assets/BehavioralTraitsSlide5.svg";
import InfoIcon from '@mui/icons-material/Info';

function Slide7() {
  return (
    <div className="flex md:flex-row flex-col items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <img src={BehavioralTraitsSlide5} alt="" className="w-full h-full" />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          In addition to the report introduction
        </h5>
        <p className="text-base text-pirmaryBlue  mb-6">
          Throughout the report, you'll find information icons <InfoIcon sx={{color: "#828282"}}/> providing details that help you understand your results. If you need more help, you can schedule a coaching session by emailing {" "}
          <a
            href="mailto:info@tripa.ai"
            className="text-success hover:underline"
          >
            info@tripa.ai
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default Slide7;