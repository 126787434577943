import { Tab, Tabs } from '@mui/material';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import WhiteBox from '../../../../sharedComponents/whiteBox/WhiteBox';
import { a11yProps, TabPanel, tabsStyle, useStyle } from './reusableComponent';

const BarCharts = ({ data = { barChart: '', stackedChart: '' } }) => {
  let { barChart, stackedChart } = data;
  // const classes = useStyle();
  const tabClasses = tabsStyle();
  const [value, setValue] = React.useState(0);
  const [dynamicBarData, setDynamicBarData] = React.useState();
  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        getDynamicChartData();
        break;
      case 1:
        getDynamicChartData('quarterly');
        break;
      case 2:
        getDynamicChartData('yearly');
        break;

      default:
        break;
    }
  };
  const getDynamicChartData = (timePeriod = 'monthly') => {
    if (barChart && barChart[timePeriod]) {
      setDynamicBarData(barChart[timePeriod]);
    }
  };
  const barOptions = {
    series: [
      {
        data:
          dynamicBarData?.job_candidate_count?.length > 0
            ? dynamicBarData.job_candidate_count.map((data) => data)
            : [],
      },
    ],
    options: {
      chart: {
        height: 'auto',
        type: 'bar',
        toolbar: {
          show: false,
        },
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors: ['#954A89', '#7AB794', '#CEDCD3'],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories:
          dynamicBarData?.job_requisition?.length > 0
            ? dynamicBarData.job_requisition.map((data) => data)
            : [],
        labels: {
          style: {
            colors: ['#2C2C2C'],
            fontSize: '12px',
          },
        },
      },
    },
  };

  const stackedOptions = {
    series:
      stackedChart?.stackData?.length > 0
        ? stackedChart.stackData.map(
            ({ job_requisition = '', candidatesMonthlyCount = [] }) => ({
              name: job_requisition,
              data: candidatesMonthlyCount,
            })
          )
        : [],
    options: {
      colors: [
        '#954A89',
        '#7AB794',
        '#CEDCD3',
        '#89C19E',
        '#C173B5',
        '#C173B5',
        '#6DA183',
        '#B07FAA',
      ],
      chart: {
        type: 'bar',
        height: 400,

        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      xaxis: {
        categories:
          stackedChart?.months?.length > 0
            ? stackedChart.months.map((month = '') => month)
            : [],
        // labels: {
        //   formatter: function (val) {
        //     return val + "K"
        //   }
        // }
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 10,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  React.useEffect(() => {
    getDynamicChartData();
  }, []);

  return (
    <div className='grid 2xl:grid-cols-5 grid-cols-1  gap-4'>
      <div className='col-span-3 '>
        <WhiteBox
          title='Candidate bar chart'
          style={{ width: '100%', height: '100%' }}
        >
          <div className='mt-5'>
            <ReactApexChart
              options={stackedOptions.options}
              series={stackedOptions.series}
              type='bar'
              height={400}
            />
            <div
              className={`flex flex-wrap md:justify-around justify-start items-center md:w-[80%] mx-auto text-pirmaryBlue`}
            >
              {/* {stackedOptions.options.xaxis.categories.map((item, index) => (
                <span className='flex justify-center items-center mx-2 my-1'>
                  <span
                    style={{
                      backgroundColor: `${stackedOptions.options.colors[index]}`,
                    }}
                    className={`h-3 mr-2 inline-block w-3 rounded-full`}
                  ></span>
                  <span className='text-base leading-5 text-ellipsis overflow-hidden whitespace-nowrap max-w-fit inline-block'>
                    {item}
                  </span>
                </span>
              ))} */}
            </div>
          </div>
        </WhiteBox>
      </div>
      <div className='col-span-2'>
        <WhiteBox title='' style={{ width: '100%', height: '100%' }}>
          <div className='flex flex-wrap justify-between items-center'>
            <h2 className='text-xl text-pirmaryBlue font-bold mb-4'>
              Candidate bar chart
            </h2>

            <Tabs
              className={tabClasses.root}
              value={value}
              onChange={handleChangeValue}
              variant='scrollable'
              allowScrollButtonsMobile
              aria-label='basic tabs example'
              sx={{
                '& .MuiTabs-scroller': {
                  borderRadius: '4px',
                  color: 'white',
                },
                '&.MuiTabs-root': {
                  '&.MuiTabs-scroller': {
                    '&.MuiTabs-indicator': {
                      backgroundColor: 'red !important',
                    },
                  },
                },
              }}
            >
              <Tab
                label='Monthly'
                {...a11yProps(0)}
                className={tabClasses.tab}
              />
              <Tab
                label='Quarterly'
                {...a11yProps(1)}
                className={tabClasses.tab}
              />
              <Tab
                label='Yearly'
                {...a11yProps(2)}
                className={tabClasses.tab}
              />
            </Tabs>
          </div>

          <TabPanel value={value} index={0}>
            <ReactApexChart
              height={400}
              width={'100%'}
              options={barOptions.options}
              series={barOptions.series}
              type='bar'
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ReactApexChart
              height={400}
              width={'100%'}
              options={barOptions.options}
              series={barOptions.series}
              type='bar'
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ReactApexChart
              height={400}
              width={'100%'}
              options={barOptions.options}
              series={barOptions.series}
              type='bar'
            />
          </TabPanel>
        </WhiteBox>
      </div>
    </div>
  );
};

export default BarCharts;
