import React from 'react';
import Card from './Card';
import { CustomTooltip } from '../reusableComponent';
import { ReactComponent as TotalAssessment } from '../../../../../../assets/TotalAssessment.svg';
import TotalCondidate from '../../../../../../assets/TotalCondidate.svg';
import { ReactComponent as ShortlistedCandidates } from '../../../../../../assets/ShortlistedCandidates.svg';
import HiredCandidates from '../../../../../../assets/HiredCandidates.svg';
import { ReactComponent as RejectedApplicants } from '../../../../../../assets/RejectedApplicants.svg';
import { ReactComponent as OpenRequisitions } from '../../../../../../assets/OpenRequisitions.svg';
import { ReactComponent as PendingRequisitions } from '../../../../../../assets/PendingRequisitions.svg';
import { ReactComponent as CompletedAssessment } from '../../../../../../assets/CompletedAssessment.svg';
import { ReactComponent as CandidateMatchingCompetencies } from '../../../../../../assets/CandidateMatchingCompetencies.svg';
import { ReactComponent as CandidatesMatchingSkillsCompetencies } from '../../../../../../assets/CandidatesMatchingSkillsCompetencies.svg';
import { ReactComponent as CandidatesMatchingSkills } from '../../../../../../assets/CandidatesMatchingSkills.svg';

const DashboardCounts = ({
  counts = { candidatesBasedonBehaviourStyle: '' },
}) => {
  let { candidatesBasedonBehaviourStyle = '' } = counts;
  return (
    <div className='flex flex-wrap gap-4 my-10 mx-auto sm:mx-0 place-content-center'>
      <Card
        title='Total condidate'
        number={counts.totalCondidate || 0}
        image={<img src={TotalCondidate}></img>}
      />
      <Card
        title='Job listings'
        number={counts.jobListings || 0}
        image={<OpenRequisitions />}
      />
      <Card
        title='Shortlisted candidates'
        number={counts.shortlistedCandidates || 0}
        image={<ShortlistedCandidates />}
      />
      <Card
        title='Hired candidates'
        number={counts.hiredCandidates || 0}
        image={<img src={HiredCandidates}></img>}
      />
      <Card
        title='Rejected applicants'
        number={counts.rejectedApplicants || 0}
        image={<RejectedApplicants />}
      />
      <div
        className='px-[32px] py-[16px] md:h-[155px] h-auto 
        min-w-[270px] bg-white rounded-[16px] flex flex-col'
      >
        <h2 className='text-[20px] text-[#353535] mb-2'>
          Candidates based on behavior style:
        </h2>
        <div className='flex flex-wrap md:w-[495px] w-full'>
          <div className='w-full flex flex-wrap justify-between mb-1'>
            <div className='flex justify-between items-center min-w-[180px]'>
              <p className='flex items-center text-[20px] text-[#353535]'>
                <span className='w-[17px] h-[17px] mr-2 bg-[#C43E2A] rounded-full'></span>
                Decisive
              </p>
              <p className='flex items-center'>
                <span className='text-[24px] text-[#6DA183] font-bold mr-1'>
                  {candidatesBasedonBehaviourStyle?.decisive || 0}
                </span>
                <CustomTooltip TooltipTitle='Decisive' TooltipText='People with this behavior style have a preference for problem-solving, getting results, and making quick decisions.' />
              </p>
            </div>
            <div className='flex justify-between items-center min-w-[180px]'>
              <p className='flex items-center text-[20px] text-[#353535]'>
                <span className='w-[17px] h-[17px] mr-2 bg-[#E4C111] rounded-full'></span>
                Interactive
              </p>
              <p className='flex items-center'>
                <span className='text-[24px] text-[#6DA183] font-bold mr-1'>
                  {candidatesBasedonBehaviourStyle?.interactive || 0}
                </span>
                <CustomTooltip TooltipTitle='Interactive' TooltipText='People with this behavior style prefer to engage actively and interact with others, be direct, open, and show emotion.' />
              </p>
            </div>
          </div>

          <div className='w-full flex flex-wrap justify-between'>
            <div className='flex justify-between items-center min-w-[180px]'>
              <p className='flex items-center text-[20px] text-[#353535]'>
                <span className='w-[17px] h-[17px] mr-2 bg-[#AAD18D] rounded-full'></span>
                Stability
              </p>
              <p className='flex items-center'>
                <span className='text-[24px] text-[#6DA183] font-bold mr-1'>
                  {candidatesBasedonBehaviourStyle?.stabilizing || 0}
                </span>
                <CustomTooltip TooltipTitle='Stability' TooltipText='People with this behavior style have a preference for a systematic approach, pacing, persistence, and being indirect, open, and steady.' />
              </p>
            </div>
            <div className='flex justify-between items-center min-w-[180px]'>
              <p className='flex items-center text-[20px] text-[#353535]'>
                <span className='w-[17px] h-[17px] mr-2 bg-[#4775C2] rounded-full'></span>
                Cautious
              </p>
              <p className='flex items-center'>
                <span className='text-[24px] text-[#6DA183] font-bold mr-1'>
                  {candidatesBasedonBehaviourStyle?.catious || 0}
                </span>
                <CustomTooltip TooltipTitle='Cautious' TooltipText='People with this behavior style have a preference for procedures, standards, and protocols to be indirect and guarded.' />
              </p>
            </div>
          </div>
        </div>
      </div>

      <Card
        title='Offers sent'
        number={counts.offersSent || 0}
        image={<TotalAssessment />}
      />
      <Card
        title='Offers accepted'
        number={counts.offersAccepted || 0}
        image={<TotalAssessment />}
      />
      <Card
        title='Offers rejected'
        number={counts.offersRejected || 0}
        image={<TotalAssessment />}
      />
      <Card
        title='Total assessment'
        number={counts.totalAssessment || 0}
        image={<PendingRequisitions />}
      />
      <Card
        title='Completed assessment'
        number={counts.completedAssessment || 0}
        image={<CompletedAssessment />}
      />
      <Card
        title='Candidates Matching Competencies'
        number={counts.candidatesMatchingCompetencies || 0}
        image={<CandidateMatchingCompetencies />}
      />
      <Card
        title='Candidates matching skills & competencies'
        number={counts.candidatesMatchingSkillsCompetencies || 0}
        image={<CandidatesMatchingSkillsCompetencies />}
      />
      <Card
        title='Candidates matching skills'
        number={counts.candidatesMatchingSkills || 0}
        image={<CandidatesMatchingSkills />}
      />
    </div>
  );
};

export default DashboardCounts;
