import React, { useState } from "react";
import Modal from "../../../../../sharedComponents/modal/Modal";
import DangerLightBtn from "../../../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import SuccessBtn from "../../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import { deleteTeam } from "../../../../../../../api/hr";
import { toast } from "react-toastify";

export default function DeleteTeam({ open, setOpen, id }) {
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    const res = await deleteTeam({ id: id });
    if (res?.status === 200) {
      toast.success("Team Deleted Successfully");
      setOpen(false);
    } else {
      toast.error(res?.data?.message);
    }
  };
  return (
    <Modal mdUpWidth={340} title="Delete team" open={open} setOpen={setOpen}>
      <p className="text-base text-pirmaryBlue">
        Are you sure to delete this team?
      </p>
      <div className="flex flex-wrap justify-between items-center mt-6">
        <DangerLightBtn onClick={() => setOpen(false)}>No</DangerLightBtn>
        <SuccessBtn onClick={handleDelete}>Yes</SuccessBtn>
      </div>
    </Modal>
  );
}
