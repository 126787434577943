import React, { useState } from 'react'
import SuccessBtn from '../../../sharedComponents/buttons/successBtn/SuccessBtn'
import { useFormik } from "formik";
import { currency } from '../../../../contants/currency'
import { postJCProfile } from '../../../../../api/jc';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import cx from "classnames";
import { MenuItem, Select } from '@mui/material';
export default function UpdateSalary({ data, handleReload }) {
  const [initialValues, setInitialValues] = useState({
    salary_expectation_min: "",
    salary_expectation_max: "",
    salary_currency: "",
  });
  const formData = new FormData();

  const handleSubmit = (values) => {
    // let times = new Date(...formik.values.issue_date.split("-"))
    // console.log(times.getTime())
    // console.log(formik.values)
    let data = {
      salary_expectation_min: formik.values.salary_expectation_min.replace(/\s/g, ''),
      salary_expectation_max: formik.values.salary_expectation_max.replace(/\s/g, ''),
      salary_currency: formik.values.salary_currency,
    }
    if(data.salary_currency){
    if (!isNaN(data.salary_expectation_max)) {
      if (!isNaN(data.salary_expectation_min)) {
        if (Math.floor(data.salary_expectation_min) < Math.floor(data.salary_expectation_max)) {
          console.log('data', data)
          formData.append("body", JSON.stringify(data));
          postJCProfile(formData).then((res) => {
            console.log('response', res.data)
            toast.success("Updated successfully.")
            handleReload()
          })
        }
        else {
          toast.error("Minimum salary can not be higher than maximum salary")
        }
      } else {
        toast.error("Minimum salary should be a number")
      }
    }
    else {
      toast.error("Maximum salary should be a number")
    }
  }else {
    toast.error("Please choose a currency")
  }
  }

  const validate = (values) => {
    const errors = {};
    
    if (!values.salary_currency) errors.salary_currency = true;
    if (!values.salary_expectation_max) errors.salary_expectation_max = true;
    if (!values.salary_expectation_min) errors.salary_expectation_min = true;
  
    return errors;
  };
  const formik = useFormik({
    initialValues,
    handleSubmit,
    validate,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: values => {
      handleSubmit(values)
    }
  })
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  React.useEffect(() => {
    formik.setFieldValue('salary_currency', data?.salary_currency || '')
    formik.setFieldValue('salary_expectation_min', data?.salary_expectation_min || '')
    formik.setFieldValue('salary_expectation_max', data?.salary_expectation_max || '')
  }, [data]);

  const { errors } = formik || {};

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='grid grid-cols-2 gap-6'>
          <div className='col-span-2'>
            <label className='text-base text-pirmaryBlue'>Select currency</label>
            <Select
              displayEmpty
              value={formik.values?.salary_currency || ''}
              onChange={(e) => {
                formik.setFieldValue('salary_currency', e.target.value);
              }}
              type='text'
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.salary_currency },
                "border-grayLight"
              )}
              MenuProps={MenuProps}
            >
              <MenuItem value=''>
                Please select
              </MenuItem>
              {currency?.map((item, index) => (
                <MenuItem value={item} key={item + index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            {errors.salary_currency && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          {/* <div> */}
          <div className='flex flex-col'>
            <label className='text-base text-pirmaryBlue'>Minimum salary</label>
            <NumericFormat
              onChange={(e) => {
                formik.setFieldValue(
                  'salary_expectation_min',
                  e.target.value
                );
              }}
              value={formik.values.salary_expectation_min}
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.salary_expectation_min },
                "border-grayLight"
              )}
              placeholder='Enter minimum salary'
              thousandSeparator={' '}
              thousandsGroupStyle='thousand'
            />
            {errors.salary_expectation_min && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className='flex flex-col'>
            <label className='text-base text-pirmaryBlue'>Maximum salary</label>
            <NumericFormat
              onChange={(e) => {
                formik.setFieldValue(
                  'salary_expectation_max',
                  e.target.value
                );
              }}
              value={formik.values.salary_expectation_max}
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.salary_expectation_max },
                "border-grayLight"
              )}
              placeholder='Enter maximum salary'
              thousandSeparator={' '}
              thousandsGroupStyle='thousand'
            />
            {errors.salary_expectation_max && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          {/* </div> */}
          <div className='mt-2 col-span-2 flex justify-end'>
            <SuccessBtn
              type="submit"
            >
              <span className="font-bold">
                Update salary
              </span>
            </SuccessBtn>
          </div>
        </div>
      </form>
    </>
  )
}
