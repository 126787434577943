import React from "react";
import { Card } from "./reuseableComponent";
import StartHere from "../../../assets/startHereNew.png";
import BusinessModelSlidBox from "./slideBoxes/businessModel";
import PurposeStatementSlideBox from "./slideBoxes/purposeStatement";
import PurposeStatementModal from "./modals/PurposeStatementModal";
import CardAnswerModal from "./modals/CardAnswerModal";
import { getByUserId, savePersonalStatement } from "../../../../api/jc";
import { useDispatch, useSelector } from "react-redux";
import { handleBudgetInstructions } from "../../../../redux/actions/personalBudget";

function PersonalStatment() {
  const dispatch = useDispatch();
  const [disable, setDisable] = React.useState(true);
  const [purposeStatement, setPurposeStatement] = React.useState(false);
  const [purposeStatementModal, setPurposeStatementModal] = React.useState(false);
  const [modalData, setModalData] = React.useState(false);
  const [cardAnswerModal, setCardAnswerModal] = React.useState(false);
  const [data, setData] = React.useState();

  const businessModel = useSelector(state => state.budgetReducer.showInstructions)
  const setBusinessModel = (data) => {
    dispatch(handleBudgetInstructions(data))
  }
  const getData = async () => {
    try {
      const res = await getByUserId()
      if(res?.data) {
        if (!res.data?.personal_statement) setBusinessModel(true)
        else setDisable(false)
        res?.data?.personal_statement && setData(res.data.personal_statement)
      }
    } catch (error) {
      console.error(error);
    }
  }

  const saveData = async (body) => {
    try {
      await savePersonalStatement(body)
      getData()
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getData()
  }, []);

  return (
    <>
      <div className="relative">
        <div
          className="2xl:absolute 2xl:top-[50%] 2xl:left-[50%] 2xl:translate-x-[-50%] 2xl:translate-y-[-50%]
          flex justify-center items-center mb-6"
        >
          <div
            className="w-[175px] h-[175px] flex justify-center items-center rounded-full
            cursor-pointer p-[20px] pt-[27px] bg-contain" style={{ backgroundImage: `url(${StartHere})`}}
            onClick={() => setPurposeStatement(true)}
          >
            {disable ? (
              <h3 className="text-pirmaryBlue text-[20px] font-bold text-center">
                START <br />
                HERE
              </h3>
            ) : (
              <h3 className="text-pirmaryBlue text-[16px] font-bold text-center">
                VIEW <br />
                PERSONAL PURPOSE STATEMENT
              </h3>
            )}
          </div>
        </div>
        <div className="grid grid-cols-auto-fit-250 gap-8 place-content-center ">
          <Card
            hasTitleBar={true}
            isIconBottom={true}
            disable={disable}
            value={data?.key_partners}
            setCardAnswerModal={(defaultContent) => {
              setModalData({
                ...defaultContent,
                id: data?._id,
                name: 'key_partners',
              });
              setCardAnswerModal(true);
            }}
            title="KEY PARTNERS"
            mainQuestoin="Who are the people that will help you?"
            subQuestionList={[
              "Who are the individuals or organizations that support your business goals and help you deliver value to your customers?",
              "Who are your most important partners and suppliers, and how do they contribute to your success?",
              "What are the primary motivations for your key partnerships, and how do they align with your business objectives?",
              "Which essential resources do you acquire from your partners, and how do these resources enable you to deliver your value proposition?",
              "How do you maintain and enhance your relationships with your key partners, and what strategies do you use to manage any challenges that may arise?",
              "By identifying and leveraging key partnerships, you can access critical resources and expertise to help you achieve your goals and create long-term success.",
            ]}
            TooltipTextList={[
              "Key Partners are the individuals, organizations, or companies with whom your business or services collaborate to achieve success. These partnerships give your business the necessary resources, expertise, or support to deliver your value proposition effectively.",
              "Key partners can be mentors, coaches, advisors, suppliers, vendors, distributors, or any other entity that contributes to the success of your business. Additionally, include colleagues, professional network members, family members, or friends who provide resources, support, or motivation to complete specific tasks.",
              "It is essential to identify and maintain strong relationships with your key partners to ensure the continued success of your business or services. By leveraging the expertise and resources of your key partners, you can enhance the value you provide to your customers and improve your overall business operations.",
            ]}
            TooltipTitle="KEY PARTNERS"
          />
          <Card
            hasTitleBar={true}
            isIconBottom={true}
            disable={disable}
            value={data?.key_activities}
            setCardAnswerModal={(defaultContent) => {
              setModalData({
                ...defaultContent,
                id: data?._id,
                name: 'key_activities',
              });
              setCardAnswerModal(true);
            }}
            title="KEY ACTIVITIES"
            mainQuestoin="The path you should take How do you do it?"
            subQuestionList={[
              "What physical or intellectual activities would you need to deliver your unique value proposition?",
              "How do your inherent talents and abilities support these critical activities, and how can you leverage them to enhance your effectiveness?",
              "Which key activities are critical for your distribution channels, customer relationships, and revenue streams, and how can you optimize them to maximize customer value?",
              "How can you streamline and automate critical activities to improve efficiency and productivity and save time and resources for other important tasks?",
              "By identifying and prioritizing your key activities, you can ensure that your business model is aligned with your unique value proposition and that you deliver maximum value to your customers.",
            ]}
            TooltipTextList={[
              "Key Activities are the essential physical and intellectual tasks you must undertake to fulfill your promises to your customers, family, or employer based on your Unique Selling Proposition (USP)/value. These activities span different areas, such as operations, marketing, production, problem-solving, and administration.",
              "The specific activities you must focus on depend on your USP, which is closely linked to your inborn talents and abilities. Therefore, identifying and prioritizing the key activities that align with your USP can help you streamline your work and increase your overall productivity and effectiveness in delivering value to your customers.",
            ]}
            TooltipTitle="KEY ACTIVITIES"
          />
          <Card
            hasTitleBar={true}
            isIconBottom={false}
            disable={disable}
            value={data?.value_provided}
            setCardAnswerModal={(defaultContent) => {
              setModalData({
                ...defaultContent,
                id: data?._id,
                name: 'value_provided',
              });
              setCardAnswerModal(true);
            }}
            title="VALUE PROVIDED"
            mainQuestoin="Your Unique Selling Proposition (USP) How do you help?"
            subQuestionList={[
              "What specific value do you offer the customers that distinguish you from competitors?",
              "How do your inherent talents and skills enable you to provide unique value to your customers?",
              "What core benefits do your customers receive from your services, and how do these benefits align with their needs and desires?",
              "How do you tailor your services to meet customers' needs and provide personalized value?",
              "How do you measure the effectiveness of your value to your customers, and how do you continuously improve and refine your approach?",
              "Answering these questions will help you identify the unique value you provide and how you can improve your approach to meet your customers' needs better. Remember to stay customer-focused and use their needs and desires to guide your business model.",
            ]}
            TooltipTextList={[
              "The Value Proposition or Value Provided in your business model is the assurance you give your target audience that your services or products will meet their needs and provide benefits. It's an easy-to-understand reason why someone should choose to buy from you or hire you, and it can help you stand out in a competitive marketplace.",
              "To create a robust Value Proposition, you must identify your unique selling points (USPs): skills, experience, knowledge, or personal traits that make you stand out. You should also highlight your transferable skills, such as excellent communication or problem-solving abilities, that could be applied to different industries or roles.",
              "Another way to demonstrate your value is to emphasize your achievements, such as increasing revenue for a company or implementing a new system that improves efficiency. You can also position yourself as an expert in a specific field, which can be a strong selling point.",
              "Finally, it's essential to be able to personalize your approach and tailor your Value Proposition to the specific needs of your target audience. For example, if you're applying for a job in marketing, emphasize your skills in branding, social media, or market research.",
              "By listing your strengths and skills clearly and concisely, you can create a compelling Value Proposition that will help you attract customers or employers and succeed in your business model.",
            ]}
            TooltipTitle="VALUE PROVIDED"
          />
          <Card
            hasTitleBar={true}
            isIconBottom={true}
            disable={disable}
            value={data?.customer_relationship}
            setCardAnswerModal={(defaultContent) => {
              setModalData({
                ...defaultContent,
                id: data?._id,
                name: 'customer_relationship',
              });
              setCardAnswerModal(true);
            }}
            title="CUSTOMER RELATIONSHIP"
            mainQuestoin="How do you interact with customers?"
            subQuestionList={[
              "What problem or need does your product or service address?",
              "Who is most likely to experience this problem or need it?",
              "What demographics (age, gender, income, education, etc.) are the most likely to be interested in your product or service?",
              "Are there any geographic or cultural factors that might influence your customer segments?",
              "What benefits or outcomes are your customers looking for?",
              "Are there any common interests or behaviors among your potential customers?",
              "What potential barriers or objections might your customers have to your product or service?",
              "How can you tailor your messaging and marketing strategies to address the needs and concerns of each customer segment?",
              "How do your customers currently solve the problem or address the need that your product or service provides a solution for?",
              "What are the pain points or challenges might your potential customers face in adopting your product or service?",
              "What motivates potential customers to purchase or try a new product or service?",
              "Are there any trends or shifts in the market that impact your customer segments or the demand for your product or service?",
              "How can you differentiate your product or service from competitors in the market?",
              "What are the most effective channels for reaching and engaging with your target customer segments (e.g., social media, email, events, etc.)?",
              "How can you measure the success of your customer segment strategy, and what metrics should you track?",
            ]}
            TooltipTextList={[
              "Customer Relationship refers to how you interact with the people or organizations you serve, which is crucial to your business's success. It involves understanding the needs and expectations of your customers and building a relationship with them that fosters loyalty and trust.",
              "To develop strong customer relationships, you need to consider the different types of customers you have and their unique needs. For example, you may have different types of customers, such as first-time buyers, repeat customers, or long-term clients, each with varying levels of engagement and loyalty.",
              "It is essential to identify the most effective communication channels and touchpoints to engage with your customers and provide the support they need. For example, some customers prefer email communication, while others prefer phone calls or social media messaging.",
              "You also need to consider the level of support your customers receive, such as after-sales support, technical assistance, or customer service. Providing excellent customer service and support can help build trust and loyalty and lead to positive reviews and referrals.",
              "In summary, developing strong customer relationships involves understanding the different types of customers you have, their unique needs, and the most effective ways to engage and support them. Building solid relationships with your customers can increase customer satisfaction, loyalty, and retention, ultimately driving business growth.",
            ]}
            TooltipTitle="CUSTOMER RELATIONSHIP"
          />

          <Card
            hasTitleBar={true}
            isIconBottom={true}
            disable={disable}
            value={data?.customer_segments}
            setCardAnswerModal={(defaultContent) => {
              setModalData({
                ...defaultContent,
                id: data?._id,
                name: 'customer_segments',
              });
              setCardAnswerModal(true);
            }}
            title="CUSTOMER SEGMENTS"
            mainQuestoin="What do you help and serve?"
            subQuestionList={[
              "What problem or need does your product or service address?",
              "Who is most likely to experience this problem or need it?",
              "What demographics (age, gender, income, education, etc.) are most likely to be interested in your product or service?",
              "Are there any geographic or cultural factors that might influence your customer segments?",
              "What benefits or outcomes are your customers looking for?",
              "Are there any common interests or behaviors among your potential customers?",
              "What potential barriers or objections might your customers have to your product or service?",
              "How can you tailor your messaging and marketing strategies to address the needs and concerns of each customer segment?",
              "When developing customer segments, it's essential to keep in mind the value proposition of your product or service and the needs and desires of your potential customers. By understanding and targeting specific customer segments, you can improve the effectiveness of your marketing and sales efforts and the overall customer experience."
            ]}
            TooltipTextList={[
              "To build and deliver your products or services, you must identify the essential resources required to provide your unique selling proposition (USP) or service.",
              "What do you need to build and deliver the service to your customers?",
              "What is the infrastructure to build the best service possible?",
              "Customer Segments are essential to defining your target customers with your product or service. Therefore, your Unique Selling Proposition (USP) should aim to benefit specific customer segments, and you need to understand their needs, preferences, and behaviors.",
              "Segmenting your customers based on similarities such as age, gender, behavior, interest, education, and geographic location helps you create personalized and targeted marketing strategies. Understanding the differences between customer segments allows you to tailor your messaging and outreach to specific groups and improve the customer experience.",
              "It's also essential to consider the customer journey for each segment, from the moment they first become aware of your product or service to their eventual purchase and beyond. This will help you identify potential pain points and opportunities for improvement, allowing you to deliver a seamless and satisfying experience for each segment.",
              "Customer segmentation allows you to create a more focused and effective marketing strategy, improve customer satisfaction, and drive business growth.",
            ]}
            TooltipTitle="CUSTOMER SEGMENTS"
          />

          <Card
            hasTitleBar={true}
            isIconBottom={true}
            disable={disable}
            value={data?.key_reseources}
            setCardAnswerModal={(defaultContent) => {
              setModalData({
                ...defaultContent,
                id: data?._id,
                name: 'key_reseources',
              });
              setCardAnswerModal(true);
            }}
            title="KEY RESOURCES"
            mainQuestoin="What do you need?"
            subQuestionList={[
              "What essential resources does your Unique Selling Proposition (USP) or service require?",
              "Consider the following questions to help you identify the necessary resources:",
              "Identify the physical assets needed to produce and deliver your products or services, such as equipment, facilities, and inventory.",
              "Intellectual Property: Determine what intellectual property protection you need for your business ideas, designs, and products, such as patents, trademarks, and copyrights.",
              "Skills and Abilities: Identify the technical skills, communication skills, and leadership abilities needed to operate your business and deliver exceptional products or services.",
              "Support Networks: Consider the personal and professional support networks you need to access advice, resources, and guidance to help you grow your business.",
              "Infrastructure: To provide the best possible service, ensure you have the infrastructure to support your business operations and deliver your products or services.",
              "Communication Channels: Determine the most effective channels to reach and engage your customers, including social media, email, and phone calls.",
              "Payment and Delivery Methods: To increase satisfaction, provide your customers convenient and secure payment and delivery options.",
              "IT Systems and Software: Use IT systems to manage your business operations, including inventory management, accounting, and customer relationship management (CRM)."
            ]}
            TooltipTextList={[
              "Key resources refer to the essential elements to operate and provide your products or services.",
              "To build and deliver your products or services, you must identify the essential resources required to provide your unique selling proposition (USP) or service.",
              "These may include:",
              "Values: Your personal and professional values guide how you operate your business and interact with your customers.",
              "Abilities and skills: Your unique abilities and skills are critical to providing high-quality products or services and differentiating your business from competitors.",
              "Support network: Your personal and professional support networks can provide valuable advice, resources, and connections to help you grow your business.",
              "Physical assets: Physical assets, such as equipment, facilities, and inventory, are necessary to produce and deliver your products or services.",
              "Intellectual property: Intellectual property, such as patents, trademarks, and copyrights, may protect your unique business ideas, designs, and products.",
              "Identifying and leveraging these essential resources can help you operate your business more effectively and efficiently, improve customer satisfaction, and drive revenue growth."
            ]}
            TooltipTitle="KEY RESOURCES"
          />
          <Card
            hasTitleBar={true}
            isIconBottom={true}
            disable={disable}
            value={data?.distributing_channels}
            setCardAnswerModal={(defaultContent) => {
              setModalData({
                ...defaultContent,
                id: data?._id,
                name: 'distributing_channels',
              });
              setCardAnswerModal(true);
            }}
            title="DISTRIBUTING CHANNELS"
            mainQuestoin="How do you react to customers?"
            subQuestionList={[
              "How do you approach customer interactions and reach out to potential customers?",
              "Do you use social media, email, phone calls, or in-person meetings?",
              "What communication channels do your customers prefer?",
              "Do they prefer to be contacted via email, phone, social media, or in-person meetings?",
              "It's essential to identify your customers' preferred communication channels and prioritize them to make sure they communicate effectively.",
              "Which distribution channels have been the most effective for your business, and at what cost?",
              "It's essential to evaluate the ROI of your distribution channels to optimize your marketing budget.",
              "How do customers purchase and pay for your products or services?",
              "Do you use e-commerce platforms, in-store sales, or both?",
              "Make sure to offer convenient and secure payment methods to increase customer satisfaction.",
              "What is your approach to following up on customers' purchases?",
              "Do you send follow-up emails or make phone calls to check in on customer satisfaction? ",
              "Following up on customers' purchases can help build a loyal customer base.",
              "How do you deliver your products or services? ",
              "Do you use a delivery service or provide in-store pick-up? ",
              "Ensure that your delivery process is reliable and convenient for customers.",
              "Finally, how do you integrate your customers into your audience routine to build long-term relationships and increase loyalty? Consider offering loyalty programs, personalized discounts, or exclusive promotions to keep your customers engaged and loyal to your brand."
            ]}
            TooltipTextList={[
              "Distribution channels refer to reaching your target customers and delivering your unique selling proposition (USP) or products and services. Your distribution channels are critical to communicating how you can help your customers, how you sell, and how your customers will pay for your products or services.",
            ]}
            TooltipTitle="DISTRIBUTING CHANNELS"
          />

          <Card
            hasTitleBar={false}
            isIconBottom={true}
            disable={disable}
            setCardAnswerModal={setCardAnswerModal}
            title=""
            mainQuestoin=""
            subQuestionList={["", "", "", ""]}
            text={
              <>
                <h2 className="text-sm font-bold text-secondaryBalck mb-4">
                  Steps to use this Personal Business Model Canvas:
                </h2>
                <ol className="list-decimal pl-5 ">
                  <li className="leading-5">
                    Click on the turn-on button to start with a Personal Purpose
                    Statement (PPS).
                  </li>
                  <li className="leading-5">
                    Read the instructions carefully, as they will have
                    implications for the quality of your PPS.
                  </li>
                  <li className="leading-5">
                    Write your first PPS draft. You can update your PPS any
                    time, but first, you must write it down.
                  </li>
                  <li className="leading-5">
                    After creating your first PPS draft, you can return to your
                    business model to identify your Unique Selling Proposition
                    (USP).
                  </li>
                  <li className="leading-5">
                    The questions in each business model building block lead you
                    to identify internal and external opportunities.
                  </li>
                  <li className="leading-5">
                    You can always edit your answer by clicking the View button.
                  </li>
                </ol>
              </>
            }
          />
          <Card
            hasTitleBar={true}
            isIconBottom={true}
            disable={disable}
            value={data?.cost_structure}
            setCardAnswerModal={(defaultContent) => {
              setModalData({
                ...defaultContent,
                id: data?._id,
                name: 'cost_structure',
              });
              setCardAnswerModal(true);
            }}
            title="COST STRUCTURE"
            mainQuestoin="What will it cost?"
            subQuestionList={[
              "What will it cost?",
              "This broad question can be challenging to answer without more information. The cost of something will depend on what you are trying to accomplish, what resources are required, and what your goals are. Therefore, it's essential to carefully consider all the factors involved when estimating the cost of a project or business.",
              "What are the most important costs in your work?",
              "The answer to this question will depend on your specific work. However, some expected costs many businesses face include employee salaries and benefits, office space rental or mortgage, equipment and technology expenses, marketing and advertising, and product or inventory expenses.",
              "Which key resources activities are most expensive?",
              "This will depend on the specific business or project. However, some everyday resource-intensive activities include product development, research and development, marketing and advertising, and employee training and development.",
              "What are the most critical cost in delivering your Unique Selling Proposition, product, or services?",
              "This will depend on the unique value proposition of your product or service. For example, if your product is known for its high quality, the cost of high-quality materials or manufacturing processes may be critical. If your service is known for its exceptional customer service, then the cost of hiring and training skilled customer service representatives may be vital.",
              "What are your third-party services costs that You are required to deliver value (training service, Transportation, software tools, and other Hardware, internet, phone, and so on)?",
              "Some standard third-party services many businesses require include software licenses, web hosting, domain registration, shipping and transportation services, and professional development and training services. The cost of these services can vary depending on the specific vendor and the required service level.",
            ]}
            TooltipTextList={[
              "Cost Structure is a vital component of your business model that helps you identify and understand the direct costs associated with operating your business or providing your services. By analyzing these costs, you can determine the most effective way to allocate resources and manage expenses to ensure profitability and sustainability.",
              "Typical costs in the cost structure may include raw materials, labor, marketing, rent, and utilities. Therefore, understanding how these costs relate to your overall business strategy impacts your revenue streams and value proposition.",
              "While it's true that personal and emotional costs may also impact your business, it's essential to consider them in a separate category from the cost structure. For example, these costs may include stress, anxiety, or other emotional impacts affecting your ability to operate your business effectively. Therefore, it's essential to address these costs separately and develop strategies to manage them effectively to ensure the long-term success of your business is going well.",
            ]}
            TooltipTitle="COST STRUCTURE"
          />

          <Card
            hasTitleBar={true}
            isIconBottom={true}
            disable={disable}
            value={data?.revenue_stream}
            setCardAnswerModal={(defaultContent) => {
              setModalData({
                ...defaultContent,
                id: data?._id,
                name: 'revenue_stream',
              });
              setCardAnswerModal(true);
            }}
            title="REVENUE STREAM"
            mainQuestoin="How much will you make it"
            subQuestionList={[
              "How does your service generate or earn money?",
              "It includes identifying your pricing strategy, payment methods, and other revenue sources.",

              "Is your revenue stream sustainable?",
              "Consider factors such as market demand, competition, and changing industry trends.",

              "Could your revenue increase in the future?",
              "Explore opportunities for growth, such as expanding your customer base or offering new products or services.",

              "What is your salary or professional fees as a contractor?",
              "It's important to factor in all expenses and costs when determining your pricing strategy.",

              "Do you have additional sources of income, such as stock options, royalties, or investments?",
              "These can supplement your primary revenue stream and contribute to overall financial stability.",

              "What value do your customers or potential clients place on your service?",
              "Understanding your customers' needs and preferences can help optimize your revenue stream and improve customer retention.",
            ]}
            TooltipTextList={[
              "The revenue stream is the amount of money your service generates or earns and is a crucial component of your business model. Therefore, it's essential to determine if your revenue stream is sustainable and if there's potential to increase it in the future.",
              "When identifying your revenue stream, consider your salary as a contractor or professional fees, as well as any additional sources of income such as stock options, royalties, or investments. In addition, it would help if you understood the value your customers or potential clients place on your services to ensure you're pricing your offerings appropriately.",
              "By analyzing and optimizing your revenue streams, you can ensure that your business is sustainable and profitable in the long term. Therefore, it's essential to regularly review and adjust your revenue streams to stay competitive and meet the changing needs of your customers.",
            ]}
            TooltipTitle="REVENUE STREAM"
          />
        </div>
      </div>


      <BusinessModelSlidBox
        businessModel={businessModel}
        setBusinessModel={setBusinessModel}
      />
      <PurposeStatementSlideBox
        open={purposeStatement}
        setOpen={setPurposeStatement}
        setPurposeStatementModal = {setPurposeStatementModal}
      />
      <PurposeStatementModal
        open={purposeStatementModal}
        setOpen={setPurposeStatementModal}
        setDisable={setDisable}
        saveStatement={saveData}
        data={{
          id: data?._id,
          personal_statement: data?.personal_statement
        }}
      />
      <CardAnswerModal
        open={cardAnswerModal}
        setOpen={setCardAnswerModal}
        modalData={modalData}
        saveStatement={async (data) => {
          await saveData(data);
          setModalData(false);
          setCardAnswerModal(false)
        }}
      />
    </>
  );
}

export default PersonalStatment;
