import React from "react";
import SuccessBtn from "../../../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import DangerLightBtn from "../../../../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import SelectField from "../../../../../../sharedComponents/selectField/SelectField";
import InfoBoxes from "../reuseableComponent";
import { currency } from "../../../../../../../contants/currency";
import { industries } from "../../../../../../../contants/industries";
import { jobFunctins } from "../../../../../../../contants/functions";
import { Avatar } from "@mui/material";
import { CustomTooltip } from "../../../dashboard/reusableComponent";
import { NumericFormat } from "react-number-format";
import datas from "../../../../../../../contants/cities.json"

function CompanyDetails({
  data,
  handleChangeData,
  image,
  handleImgFile,
  deleteImage,
  formErrors
}) {

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontSize: '35px',
        fontWeight: "700",
        width: "6rem",
        height: "6rem",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (name) => {
    const allNames = name.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  const countries = Object.keys(datas);
  const cities = data.country ? datas[data.country] : [];

  return (
    <>
      <form className="grid md:grid-cols-auto-fit-450 grid-cols-auto-fit-250 gap-4 mt-[24px]">
        <div className="flex flex-col">
          <label
            htmlFor="companyName"
            className="text-pirmaryBlue text-base font-normal"
          >
            Company name *
          </label>
          <input
            type="text"
            name="companyName"
            value={data.companyName || ""}
            onChange={handleChangeData}
            id="companyName"
            placeholder="Northwest Trading"
            className="focus:outline-none border-2 border-solid border-grayLight p-3 rounded"
          />
          {formErrors.companyName && (
            <span className="text-red-500">{formErrors.companyName}</span>
          )}
        </div>
        <div className="flex flex-col relative">
          <label
            htmlFor="jobTitle"
            className="text-pirmaryBlue text-base font-normal flex items-center"
          >
            Job title *
            <InfoBoxes TooltipTitle='Job title' TooltipText='Job title is the official name that describes the job or positions your employee holds'></InfoBoxes>
          </label>
          <input
            type="text"
            name="jobTitle"
            value={data.jobTitle || ""}
            onChange={handleChangeData}
            id="jobTitle"
            placeholder="Northwest Trading"
            className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded pr-10"
          />
          {formErrors.jobTitle && (
            <span className="text-red-500">{formErrors.jobTitle}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={jobFunctins}
            label="Job function"
            labelId="jobFunction"
            id="jobFunction"
            value={data.jobFunction}
            onChange={handleChangeData}
            name="jobFunction"
            placeholder="Select"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.jobFunction && (
            <span className="text-red-500">{formErrors.jobFunction}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={industries}
            label="Company industry"
            labelId="companyIndustry"
            id="companyIndustry"
            value={data.companyIndustry}
            onChange={handleChangeData}
            name="companyIndustry"
            placeholder="Select"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.companyIndustry && (
            <span className="text-red-500">{formErrors.companyIndustry}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={countries}
            label="Country"
            labelId="country"
            id="country"
            value={data.country}
            onChange={handleChangeData}
            name="country"
            placeholder="Select"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.country && (
            <span className="text-red-500">{formErrors.country}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={cities}
            label="City"
            labelId="companyIndustry"
            id="city"
            value={data.city}
            onChange={handleChangeData}
            name="city"
            placeholder="Select"
            inputStyle={{ padding: "14px" }}
            required
            disabled={cities?.length > 0 ? false : true}
          />
          {formErrors.city && (
            <span className="text-red-500">{formErrors.city}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={[
              "Full time",
              "Part time",
              "Contract",
              "Temporary",
              "Volunteer",
              "Internship",
            ]}
            label="Employment type"
            labelId="employmentType"
            id="employmentType"
            value={data.employmentType}
            onChange={handleChangeData}
            name="employmentType"
            placeholder="Select"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.employmentType && (
            <span className="text-red-500">{formErrors.employmentType}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={currency}
            label="Currency"
            labelId="currency"
            id="currency"
            value={data.currency}
            onChange={handleChangeData}
            name="currency"
            placeholder="Select"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.currency && (
            <span className="text-red-500">{formErrors.currency}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="companyName"
            className="text-pirmaryBlue text-base font-normal flex items-center"
          >
            Salary range *
            <span className="ml-1">
              <CustomTooltip
                TooltipTitle={"Salary range"}
                TooltipText={
                  "When entering your salary expectation, ensure you separate the three spaces that indicate thousands. For instance, 1000 should be 1 000; 20000 should be 20 000, and 150000 should be 150 000"
                }
              />
            </span>
          </label>
          <div className="grid grid-cols-auto-fit-200 gap-4">
            <div className="flex flex-col">
            <NumericFormat
              name="salaryRangeFrom"
              value={data.salaryRangeFrom || ""}
              onChange={handleChangeData}
              id="salaryRangeFrom"
              placeholder="From"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded "
              thousandSeparator={' '}
              thousandsGroupStyle='thousand'
            />
            {formErrors.salaryRangeFrom && (
              <span className="text-red-500">{formErrors.salaryRangeFrom}</span>
            )}
            </div>
            <div className="flex flex-col">
            <NumericFormat
              name="salaryRangeTo"
              value={data.salaryRangeTo || ""}
              onChange={handleChangeData}
              id="salaryRangeTo"
              placeholder="To"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded  "
              thousandSeparator={' '}
              thousandsGroupStyle='thousand'
            />
            {formErrors.salaryRangeTo && (
              <span className="text-red-500">{formErrors.salaryRangeTo}</span>
            )}
            </div>
          </div>
        </div>
        <div className="">
          <SelectField
            selectItems={[
              "Intership",
              "Entry level",
              "Mid-Senior level",
              "Associate",
              "Director",
              "Executive",
            ]}
            label="Seniority level"
            labelId="seniorityLevel"
            id="seniorityLevel"
            value={data.seniorityLevel}
            onChange={handleChangeData}
            name="seniorityLevel"
            placeholder="Select"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.seniorityLevel && (
            <span className="text-red-500">{formErrors.seniorityLevel}</span>
          )}
        </div>
      </form>

      <div className="mt-[24px] mb-[30px]">
        <InfoBoxes
          title="Upload your logo file"
          TooltipTitle="Upload your logo file"
          TooltipText="Uploading a sound quality logo file will help us to support your corporate branding."
        ></InfoBoxes>
      </div>
      <div className=" flex md:flex-row flex-col items-center w-[100%] mb-[36px] ">
        <div className="w-[105px] h-[105px] bg-white border-2 border-solid border-[#E5E5E5] flex justify-center items-center md:mr-[24px] mb-6 mb:my-0">
          <span className="min-w-[95px] min-h-[95px] flex justify-center items-center p-3">
            {image ? (
              <img src={image} alt="" className="h-full" />
            ) : (
              <Avatar {...stringAvatar(`${data?.companyName || 'T'}`)} />
            )}
          </span>
        </div>
        <div className="">
          <div className="flex justify-center flex-wrap md:justify-start items-center my-2 md:my-0">
            <div className="relative">
              <SuccessBtn customStyle={{ marginRight: "16px" }}>
                Upload a logo
              </SuccessBtn>
              <input
                className="absolute left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                type="file"
                name="image"
                onChange={(e) => handleImgFile(e)}
              />
            </div>
            <DangerLightBtn onClick={deleteImage}>Delete </DangerLightBtn>
          </div>
          <p className="text-[#828282] text-[14px] font-normal mt-[16px]">
            * Image size should be at least 512px X 512px and max size of 500kb,
            allowed files .png and .jpg{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;
