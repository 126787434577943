import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import { toast } from "react-toastify";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

// style for tab panel
export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const tabsStyle = makeStyles({
  root: {
    flexGrow: 1,
    color: "#273164",
    textAlign: "right",
    "& .MuiBox-root": {
      padding: "0 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#7AB794 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },
  tab: {
    width: "50%",
    border: "1px solid #E3E6E9 !important",
    "&.Mui-selected": {
      border: "1px solid #7AB794 !important",
      background: "#7AB794",
      color: "white !important",
      outline: "none",
      boxShadow: "none",
    },
  },
  checkbox: {
    "&.Mui-checked": {
      color: "#7AB693 !important",
    },
  },
});

export const PasswordField = ({
  isPassword,
  setIsPassword,
  name,
  label,
  labelFor,
  id,
  value,
  onChange,
  placeholder,
  style,
  inputStyle,
  className,
}) => {
  const togglePassword = () => {
    setIsPassword(!isPassword);
  };

  return (
    <div className="flex flex-col my-3 relative" style={style}>
      <label htmlFor={labelFor} className="text-pirmaryBlue">
        {label ? label : "Password"}
      </label>
      <input
        type={isPassword ? "text" : "password"}
        className={`focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded ${className}`}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        inputStyle={inputStyle}
      />
      <button onClick={togglePassword} className="absolute right-5 top-[50%]">
        {isPassword ? (
          <VisibilityOutlinedIcon sx={{ color: "#828282" }} />
        ) : (
          <VisibilityOffOutlinedIcon sx={{ color: "#828282" }} />
        )}
      </button>
    </div>
  );
};

export const formValidate = (values) => {
  const myError = 0;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const regularExpressionPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  if (!values.email) {
    toast.error("Email is required.");
    return 1;
  } else if (!regex.test(values.email)) {
    toast.error("This is not a valid email format.");
    return 1;
  }
  if (!values.password) {
    toast.error("Password is required.");
    return 1;
  } else if (values.password.length < 8) {
    toast.error("Password must be more than 8 characters.");
    return 1;
  } else if (!regularExpressionPassword.test(values.password)) {
    toast.error("The password you create must be a combination of alphanumeric characters, which includes both letters (A-Z, a-z) and numbers (0-9). In addition, at least one special character (such as !, @, #, $, %, &, *, etc.) should be included. Please ensure you adhere to these guidelines when setting your password.");
    return 1;
  }
  if (!values.confirmPassword) {
    toast.error("Confirm password is required.");
    return 1;
  } else if (values.confirmPassword !== values.password) {
    toast.error("Confirm password is not correct.");
    return 1;
  }
  if (!values.terms) {
    toast.error("You should to check terms of service and privacy policy.");
    return 1;
  }
  if (!values.agreeToReceiveEmail) {
    toast.error(
      "You should agree to receive subsequent emails and third-party communication."
    );
    return 1;
  }
  return myError;
};
