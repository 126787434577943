import React, { useState } from "react";
import InfoBoxes from "../../sharedComponents/profile/infoBox";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import RefferalModal from "./modalCompnents/refferalModal";
import Modal from "../../sharedComponents/modal/Modal";
import { Avatar } from "@mui/material";

export default function Refferals({ handleReload, data }) {
  const [editInfo, setEditInfo] = useState(false);

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontWeight: "700",
        // width: "56px",
        // height: "56px",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (fullName) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }
  return (
    <>
      <InfoBoxes
        title="Referrals"
        handleClickAdd={() => setEditInfo(true)}
        icon={<AddCircleOutlineTwoToneIcon />}
        TooltipText="Please share your knowledge, wisdom, and experience about TRIPA, our premium subscription service, with your network and earn a 30% discount on your renewal when five people you refer to sign up. TRIPA provides access to exclusive content and features that help you improve your skills and advance your career. Take advantage of this opportunity to enhance your professional development and help your friends and colleagues do the same. Conditions apply."
        TooltipTitle="Referrals"
      >
        <div className="flex flex-col divide-y">
          {
            data && data?.referrals?.map((item, index) => (

              <div className="grid grid-cols-12 pt-4 pb-7 " key={index}>
                <div className="col-span-1">
                  <Avatar {...stringAvatar(`${item?.name}`)} />
                </div>
                <div className="col-span-11 pl-2">
                  <div className="text-lg font-bold text-pirmaryBlue">
                    {item?.name}
                  </div>
                  <div className="text-base leading-5 text-pirmaryBlue">
                    {item?.email}
                  </div>
                  <div className="text-secondaryBalck">sent on 26 Jun 2020</div>
                  {
                    item?.is_referral_login ?
                      <span
                        className={`rounded-full py-1 relative top-2 px-4 font-bold text-xs bg-[#BBF7D0] text-[#166534]`}
                      >
                        Accepted
                      </span>
                      :
                      <span
                        className={`rounded-full py-1 relative top-2 px-4 font-bold text-xs bg-[#FECACA] text-[#991B1B]`}
                      >
                        Waiting
                      </span>
                  }
                </div>
              </div>
            ))
          }
        </div>
      </InfoBoxes>
      <Modal title="Referral program" open={editInfo} setOpen={setEditInfo}>
        <RefferalModal handleReload={() => {
          setEditInfo(false);
          handleReload()
        }} />
      </Modal>
    </>
  );
}
