import React from "react";
import RebrandYourself from "../../../../../assets/slidBoxRebrandYourself.svg";

function Slide1() {
  return (
    <div className="flex items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <img src={RebrandYourself} alt="" className="w-full h-full" />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          How can you use this tool to rebrand yourself?
        </h5>
        <p className="text-base text-pirmaryBlue mb-6">
          Using this tool, you can rebrand yourself by identifying and showcasing your unique selling proposition (USP) and creating a business model that highlights your strengths and sets you apart from your competitors. In addition, you can use the tool to define your personal purpose statement (PPS) and answer critical questions about your business model, such as who your customers are, what they value, and how you can deliver value to them at a reasonable cost.
        </p>
        <p className="text-base text-pirmaryBlue mb-6">
          Through this process, you can develop a clear and compelling narrative demonstrating your value proposition to potential employers or clients. You can also use the tool to identify areas where you may need to improve your skills or gain new experience to stay competitive.
        </p>
        <p className="text-base text-pirmaryBlue">
          Like a business, your business model is subject to testing and refinement in the marketplace. Therefore, you should be open to feedback and willing to adjust your approach to achieve your goals and stay ahead of the competition.
        </p>
      </div>
    </div>
  );
}

export default Slide1;
