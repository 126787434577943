import React from "react";
import Avatar from "@mui/material/Avatar";

function CreateAvatar({ name, lastName, src, alt }) {
  return (
    <span className="flex items-center text-base text-[#384259]">
      <Avatar src={src} alt={alt} sx={{ marginRight: "10px" }} /> {name}{" "}
      {lastName}
    </span>
  );
}

export default CreateAvatar;
