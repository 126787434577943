import React from "react";
import CandidatePoolWrapper from "./CandidatePoolWrapper";
import CondidateProfile from "../../../../sharedComponents/candidateProfile/CondidateProfile";
import WhiteBox from "../../../../sharedComponents/whiteBox/WhiteBox";

function CandidatePool() {
  const [selectedCandidate, setSelectedCandidate] = React.useState();
  return (
    <CandidatePoolWrapper setSelectedCandidate={setSelectedCandidate}>
      <WhiteBox>
        {selectedCandidate ? (
          <CondidateProfile selectedCandidate={selectedCandidate} />
        ) : (
          <div className="min-h-[100vh]">
            <p className="text-center text-3xl mt-10">Please select Pool</p>
          </div>
        )}
      </WhiteBox>
    </CandidatePoolWrapper>
  );
}

export default CandidatePool;
