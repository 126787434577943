import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import WorkForce from "../../../assets/workforce.svg";
import Dashboard from "../../../assets/dashboard.svg";
import PowerTeam from "../../../assets/powerteam.svg";
import IdentifyYourValue from "../../../assets/identifyyourvalue.svg";
import CareerPathInsights from "../../../assets/careerpathinsights.svg";
import DashboardCoaching from "../../../assets/dashboard_coaching.svg";
import RegistrationUpdate from "../../../assets/registration_update.svg";
import cx from "classnames";
import {
  TabPanel,
  tabsStyle,
  PasswordField,
  formValidate,
} from "./reusableComponents";
import LoginWithGoogle from "../../sharedComponents/loginWithGoogle/LoginWithGoogle";
import LoginWithGithub from "../../sharedComponents/loginWithGithub/loginWithGithub";
import SuccessBtn from "../../sharedComponents/buttons/successBtn/SuccessBtn";
import Header from "../../sharedComponents/header/header";
import Footer from "../../sharedComponents/footer/footer";
import Cookies from "universal-cookie";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../styles/swiper.css";
import { Pagination, Navigation } from "swiper";
import { signInApiJC } from "../../../../api/jc";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchBehaviour } from "../../../../redux/actions/behaviourData";
import ForgotPassword from "./ForgotPassword";
import ResendMailVerify from "./ResendMailVerify";
import ReActiveAccount from "./ReActiveAccount";
import ResetPassword from "../ChangePassword/ResetPasswordModal";
import CryptoJS from "crypto-js";
import config from "../../../../config/config";
import Modal from "../../sharedComponents/modal/Modal";

export default function LoginCandidate() {
  const { state } = useLocation();
  const history = useNavigate();
  // const [value, setValue] = useState(0);
  const value = 0;
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [resendVerifyPopUp, setResendVerifyPopUp] = useState(false);
  const [resendVerify, setResendVerify] = useState(false);
  const [reActivePopUp, setReActivePopUp] = useState(false);
  const [reActive, setReActive] = useState(false);
  const [openRegistration, setOpenRegistration] = useState(false);
  const cookies = new Cookies();
  const [coachingData, setCoachingData] = useState({
    email: "",
    password: "",
    keepMeLogin: false,
    emailIdError: null,
    passwordError: null,
    emailIdErrorMessage: null,
    passwordErrorMessage: null,
    isLoading: null,
  });

  const [coachPassShown, setCoachPassShown] = useState(false);

  const dispatch = useDispatch();
  const handleCoachingData = (event) => {
    // validateEmailCoaching(coachingData);
    // validatePasswordCoaching(coachingData);
    const name = event.target.name;
    if (event.target.type === "checkbox") {
      setCoachingData((prevData) => ({
        ...prevData,
        [name]: event.target.checked,
      }));
    } else {
      const value = event.target.value;
      setCoachingData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const validateEmailCoaching = (values) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      setCoachingData((prevData) => ({
        ...prevData,
        emailIdErrorMessage: "Your Email address is not correct",
        emailIdError: true,
      }));
      return;
    }
    if (!regex.test(values.email)) {
      setCoachingData((prevData) => ({
        ...prevData,
        emailIdErrorMessage: "Your Email address is not correct",
        emailIdError: true,
      }));
      return;
    }
    setCoachingData((prevData) => ({
      ...prevData,
      emailIdError: false,
    }));
    return;
  };

  const validatePasswordCoaching = (values) => {
    if (!values.password) {
      setCoachingData((prevData) => ({
        ...prevData,
        passwordErrorM: true,
        passwordErrorMessage: "Your passwrod is wrong",
      }));
      return;
    }
    // if (values.password.length < 8) {
    //   setCoachingData((prevData) => ({
    //     ...prevData,
    //     passwordError: true,
    //     passwordErrorMessage: "Password should be at least 8 charcter",
    //   }));
    //   return;
    // }
    // if (values.password.length > 20) {
    //   setCoachingData((prevData) => ({
    //     ...prevData,
    //     passwordError: true,
    //     passwordErrorMessage: "Password must be maximum 20 charcter",
    //   }));
    //   return;
    // }
    setCoachingData((prevData) => ({
      ...prevData,
      passwordError: false,
    }));
    return;
  };

  const coachingformHandler = async (e) => {
    setCoachingData((prevData) => ({
      ...prevData,
      isLoading: true,
    }));
    e.preventDefault();
    validateEmailCoaching(coachingData);
    validatePasswordCoaching(coachingData);
    const error = formValidate(coachingData);

    let temp = {
      email_address: coachingData.email,
      password: coachingData.password,
      roles: ["job_candidate"],
    };
    if (error) {
      setCoachingData((prevData) => ({
        ...prevData,
        isLoading: false,
      }));
    }
    if (!error) {
      signInApiJC(temp)
        .then((res) => {
          localStorage.setItem("access", res.data.Token);
          cookies.set("token-session", res.data.Token);
          var ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify("job_candidate"),
            config.cookieSecret
          ).toString();
          localStorage.setItem("role", ciphertext);
          if (coachingData.keepMeLogin) {
            var keepMeLogin = CryptoJS.AES.encrypt(
              JSON.stringify("true"),
              config.cookieSecret
            ).toString();
            localStorage.setItem('keepMe', keepMeLogin);
          }
          localStorage.setItem("accessData", JSON.stringify(res.data));
          dispatch(fetchBehaviour());
          const { isFirstNameAndLastNameCompleted } = res.data;
          if (res.data.is_first) {
            const {
              isAssessmentTypeCompleted,
              isBehaviorAssessmentCompleted = true,
              isDriverOfEngagementDiagnosticCompleted,
              isRoleBasedAssessmentCompleted,
              isRoleBasedCompetanceAssessmentCompleted,
            } = res.data;
            if (!isAssessmentTypeCompleted) {
              history("/assessment/start", {
                state: {
                  behaviorAssessmentCode: isBehaviorAssessmentCompleted,
                },
              });
            } else if (isBehaviorAssessmentCompleted !== true) {
              history("/assessment/behavior/start", {
                state: {
                  behaviorAssessmentCode: isBehaviorAssessmentCompleted,
                },
              });
            } else if (isDriverOfEngagementDiagnosticCompleted !== true) {
              history("/assessment/values/start");
            } else if (
              isRoleBasedAssessmentCompleted !== true ||
              isRoleBasedCompetanceAssessmentCompleted !== true
            ) {
              history("/assessment/rolebased/start");
            } else if (!isFirstNameAndLastNameCompleted) {
              history("/resumeform/upload");
            }
          } else if (!isFirstNameAndLastNameCompleted) {
            history("/resumeform/upload");
          } else {
            history("/coaching/profile");
          }
          // history.push("jc/profile")
          // e.target.nextElementSibling.click();
        })
        .catch((e) => {
          console.log(e?.response?.data?.message)
          if (e?.response?.data?.message === 'Please check your mail to verify your email address')
            setResendVerify(true)
          if (e?.response?.data?.message === 'User has been deactive')
            setReActive(true)
          setCoachingData((prevData) => ({
            ...prevData,
            isLoading: false,
          }));
        });
    }
  };

  const classes = tabsStyle();

  React.useEffect(() => {
    if (state?.isResetPassword) {
      setResetPassword(state.token);
    }

    if (state?.isFromLandingPage) {
      setOpenRegistration(true);
    }
  }, [state]);

  const openPopup = () => {
    setOpenRegistration(true);
  }

  return (
    <div className="flex flex-col min-h-[100vh]">
      <Header />
      <div>
        <div className="login-reg-container">
          <div className="h-full col-span-1 md:w-full bg-white login-reg-wrapper">
            <div className="">
              <div className="bg-[#f8f9fa] h-full swiper-parent">
                <div className="flex flex-col justify-center items-center">
                  <h2 className="font-bold text-[#000000] text-center text-[23px] w-full max-w-[550px] leading-[31px] mb-[30px]">
                    Maximize your potential with invaluable insights that empower you to uncover the perfect job opportunity. Don't settle for professional paths that fail to fully harness your unique strengths and exceptional abilities.
                  </h2>
                  <img src={DashboardCoaching} alt="" width={'550px'} />
                  {/* {value === 0 && (
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={0}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      // navigation={true}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center ">
                          <h2 className="font-bold text-[#000000] text-[24px] px-4 w-full max-w-[550px] leading-[31px] mb-[30px]">
                         Unlock your peak performance with crucial insights that empower you to identify the perfect job opportunity. Don't settle for professional roles that fail to leverage your superpowers and unique abilities. Maximize your potential and pursue a career that aligns perfectly with your extraordinary talents and capabilities.
                          </h2>

                          <img src={DashboardCoaching} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center overflow-hidden">
                          <div className="mb-[30px] w-full max-w-[500px] px-4">
                            <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                              Career path insights
                            </h2>
                            <p className="text-[#828282] text-[14px] text-center mt-2">
                              Take the manual and guesswork from your candidate
                              screening process to identify the success
                              predictors of those who will help you create
                              greater value for your business.
                            </p>
                          </div>
                          <img src={CareerPathInsights} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center overflow-hidden">
                          <div className="mb-[30px] w-full max-w-[500px] px-4">
                            <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                              Identify your value
                            </h2>
                            <p className="text-[#828282] text-[14px] text-center mt-2">
                              Take the manual and guesswork from your candidate
                              screening process to identify the success
                              predictors of those who will help you create
                              greater value for your business.
                            </p>
                          </div>
                          <img src={IdentifyYourValue} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  )}
                  {value === 1 && (
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={0}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      // navigation={true}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center ">
                          <h2 className="font-bold text-[#000000] text-[24px] px-4 w-full max-w-[550px] leading-[31px] mb-[30px]">
                            Unlock your peak performance with crucial insights that empower you to identify the perfect job opportunity. Don't settle for professional roles that fail to leverage your superpowers and unique abilities. Maximize your potential and pursue a career that aligns perfectly with your extraordinary talents and capabilities.
                          </h2>

                          <img src={DashboardCoaching} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center overflow-hidden">
                          <div className="mb-[30px] w-full max-w-[500px] px-4">
                            <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                              Dashboard
                            </h2>
                            <p className="text-[#828282] text-[14px] text-center mt-2">
                              Take the manual and guesswork from your candidate
                              screening process to identify the success
                              predictors of those who will help you create
                              greater value for your business.
                            </p>
                          </div>
                          <img src={Dashboard} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center overflow-hidden">
                          <div className="mb-[30px] w-full max-w-[500px] px-4">
                            <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                              Power Team
                            </h2>
                            <p className="text-[#828282] text-[14px] text-center mt-2">
                              Take the manual and guesswork from your candidate
                              screening process to identify the success
                              predictors of those who will help you create
                              greater value for your business.
                            </p>
                          </div>
                          <img src={PowerTeam} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  )} */}
                </div>
              </div>
            </div>
            {/* start tab Coaching */}
            <div className="form-section-container">
              <p className="text-[24px] font-bold">Log In 👍</p>
              <div className="text-secondaryBalck text-[14px] mt-[10px] mb-7">
                Don't have an account?<span className="text-success cursor-pointer" onClick={openPopup}><span className="ml-2">Sign Up</span></span>
                {/* Comment for add Popup  */}
                {/* <Link to="/coaching/register" className="text-success ml-1">
                  <span className="ml-2">Sign Up</span>
                </Link> */}
              </div>
              <TabPanel>
                <div>
                  {/* <div className="w-[100%] flex flex-col lg:flex-row">
                    <div className="lg:w-[50%] w-[100%] lg:my-8">
                      <LoginWithGoogle
                        responseGoogle={"job_candidate"}
                        responseGoogleFail={() => {}}
                      />
                    </div>
                    <div className="lg:w-[50%] w-[100%] lg:pl-4 lg:my-8 mb-8">
                      <Link
                        to="#"
                        className="border-solid border-2 border-grayLight h-auto w-100 rounded
                          flex flex-row justify-center items-center p-3 cursor-pointer hover:bg-slate-100 transition-all"
                      >
                        <span className="text-secondaryBalck flex items-center">
                          Log in with{" "}
                          <span className="h-[32px] w-[80px] ml-1">
                            <Bindid />
                          </span>
                        </span>

                        <div className="mx-3"></div>
                      </Link>
                    </div>
                    <div className="lg:w-[50%] w-[100%] lg:pl-4 lg:my-8 mb-8">
                      <div className="lg:w-[100%] w-[100%] ml-1">
                        {" "}
                        <LoginWithGithub
                          responseGithub={"job_candidate"}
                          responseGithubFail={() => {}}
                        />{" "}
                      </div>
                      <div className="mx-3"></div>
                    </div>
                  </div> 

                  <div className="border-t-2 relative">
                    <span className="bg-white w-[50px] h-[50px] text-center text-[#768798] absolute top-[-15px] left-[50%] translate-x-[-50%]">
                      OR
                    </span>
                  </div> */}
                  <div className="flex flex-col my-4">
                    <label
                      htmlFor="Coaching-emailInput"
                      className="text-pirmaryBlue"
                    >
                      Email address *
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={coachingData.email || ""}
                      onChange={handleCoachingData}
                      id="Coaching-emailInput"
                      placeholder="Enter your email"
                      className={cx(
                        "focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded",
                        { "border-red-500": coachingData.emailIdError },
                        "border-grayLight"
                      )}
                    />
                    {coachingData.emailIdError && (
                      <span className="text-red-500">
                        {coachingData.emailIdErrorMessage}
                      </span>
                    )}
                  </div>
                  <PasswordField
                    isPassword={coachPassShown}
                    setIsPassword={setCoachPassShown}
                    name={"password"}
                    value={coachingData.password || ""}
                    label={"Password"}
                    labelFor={"Coaching-password"}
                    id={"Coaching-password"}
                    onChange={handleCoachingData}
                    placeholder={"Enter your password"}
                    className={cx(
                      "focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded",
                      { "border-red-500": coachingData.passwordError },
                      "border-grayLight"
                    )}
                  />
                  {coachingData.passwordError && (
                    <span className="text-red-500">
                      {coachingData.passwordErrorMessage}
                    </span>
                  )}
                  <div className="flex items-center justify-between">
                    <div className="flex flex-row items-center my-4">
                      <FormGroup>
                        <FormControlLabel
                          checked={coachingData.keepMeLogin}
                          onChange={handleCoachingData}
                          control={<Checkbox />}
                          name="keepMeLogin"
                          label="Keep me logged in"
                          inputProps={{ "aria-label": "controlled" }}
                          className={classes.checkbox}
                        />
                      </FormGroup>
                    </div>
                    {resendVerify ? <p
                      onClick={() => setResendVerifyPopUp(true)}
                      className="text-success cursor-pointer hover:underline hover:decoration-1 hover:decoration-success"
                    >
                      Resend verify mail
                    </p> : <p />}
                    {reActive ? <p
                      onClick={() => setReActivePopUp(true)}
                      className="text-success cursor-pointer hover:underline hover:decoration-1 hover:decoration-success"
                    >
                      Reactive you account
                    </p> : <p />}
                    <p
                      onClick={() => setForgotPassword(true)}
                      className="text-success cursor-pointer hover:underline hover:decoration-1 hover:decoration-success"
                    >
                      Forgot your password?
                    </p>
                  </div>

                  <SuccessBtn
                    type="submit"
                    onClick={coachingformHandler}
                    customStyle={{ width: "100%", height: "55px" }}
                    disabled={coachingData.isLoading}
                  >
                    LOGIN
                  </SuccessBtn>
                </div>
              </TabPanel>
            </div>
            {/* end tab Coaching */}
          </div>
        </div>
      </div>
      <ForgotPassword
        forgotPassword={forgotPassword}
        setForgotPassword={setForgotPassword}
        email={coachingData?.email}
      />
      <ResendMailVerify
        resendVerifyPopUp={resendVerifyPopUp}
        setResendVerifyPopUp={setResendVerifyPopUp}
        email={coachingData?.email}
        setResendVerify={setResendVerify}
      />
      <ReActiveAccount
        reActivePopUp={reActivePopUp}
        setReActivePopUp={setReActivePopUp}
        email={coachingData?.email}
        setReActive={setReActive}
      />
      <ResetPassword
        resetPassword={resetPassword}
        setResetPassword={setResetPassword}
      />
      <Footer />
      <Modal
        title="Registration Update 📣"
        open={openRegistration} setOpen={setOpenRegistration}
      >
        <div>
          <div className="mt-[60px] mb-[30px]">
          <img src={RegistrationUpdate} alt="RegistrationUpdate" />
          </div>
          <div className="text-center"><b className="text-black font-bold md:text-[28px] md:leading-9">We've reached our capacity for registrations.</b></div>
          <div className="text-center"><b className="text-black font-bold md:text-[28px] md:leading-9">Thank you for your overwhelming interest!</b></div>
          <div className="text-center mt-2 text-[#455A64] font-bold md:text-[16px]">“Please check back later for availability”</div>
          <div className="text-center mt-6 text-[#5A9174] font-bold md:text-[30px]">Thank you for understanding</div>
        </div>
      </Modal>
    </div>
  );
}
