import React from "react";

function ViewMoreViewLess({ children }) {
  const [isViewMoreShown, setIsViewMoreShown] = React.useState(false);

  const handleSwitch = () => {
    setIsViewMoreShown((prev) => !prev);
  };

  return (
    <div>
      <p className="text-base text-pirmaryBlue">{isViewMoreShown ? children : children.substr(0, 200) + "..."}</p>
      <button type="button" className="text-success underline text-base font-bold leading-5 mt-3" onClick={handleSwitch}>
        {isViewMoreShown ? "View less" : "View more"}
      </button>
    </div>
  );
}

export default ViewMoreViewLess;
