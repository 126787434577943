import React from "react";
import cx from "classnames";
import Modal from "../../../sharedComponents/modal/Modal";
import { Formik, Field, Form } from "formik";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import DangerLightBtn from "../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";

export default function CertificationsModalBody({
  values,
  isOpen,
  onSubmit,
  onClose,
  isNew
}) {
  const {
    name,
    issuing_by_organization,
    issue_date,
    credential_id
  } = isNew ? {} : values || {};

  const validate = (values) => {
    const errors = {};
    
    if (!values.name) errors.name = true;
    if (!values.issuing_by_organization) errors.issuing_by_organization = true;
    if (!values.issue_date) errors.issue_date = true;
  
    return errors;
  };

  return (
    <div>
      <Modal title={`${isNew ? 'Add' : 'Edit'} Professional Certificate`} open={isOpen} setOpen={onClose} lgUpWidth={600}>
        <Formik
          initialValues={{
            name,
            issuing_by_organization,
            issue_date,
            credential_id
          }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={async (values) => {
            onSubmit(values);
          }}
        >
          {({ errors }) => (
            <Form>
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
                <div className="flex flex-col col-span-2">
                  <label htmlFor="name" className="text-base text-pirmaryBlue">
                    Name *
                  </label>
                  <Field
                    name="name"
                    type="text"
                    placeholder='Enter name'
                    className={cx(
                      "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                      { "border-red-500": errors.name },
                      "border-grayLight"
                    )}
                  />
                  {errors.name && (
                    <span className="text-red-500">This is required field</span>
                  )}
                </div>
                <div className="flex flex-col col-span-2">
                  <label htmlFor="issuing_by_organization" className="text-base text-pirmaryBlue">
                    Issuing Organization *
                  </label>
                  <Field
                    name="issuing_by_organization"
                    type="text"
                    placeHolder="Enter Issuing Organiztion"
                    className={cx(
                      "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                      { "border-red-500": errors.issuing_by_organization },
                      "border-grayLight"
                    )}
                  />
                  {errors.issuing_by_organization && (
                    <span className="text-red-500">This is required field</span>
                  )}
                </div>
                <div className="flex flex-col mb-3">
                  <label htmlFor="issue_date" className="text-base text-pirmaryBlue">
                    Issue Date *
                  </label>
                  <Field
                    name="issue_date"
                    type="date"
                    className={cx(
                      "focus:outline-none border-2 p-[12px] border-solid rounded",
                      { "border-red-500": errors.issue_date },
                      "border-grayLight"
                    )}
                  />
                  {errors.issue_date && (
                    <span className="text-red-500">This is required field</span>
                  )}
                </div>
              </div>
              <div className="flex flex-col col-span-2">
                <label className="text-base text-pirmaryBlue" htmlFor="credential_id">Certificate Number</label>
                <Field
                  name="credential_id"
                  type="text"
                  placeHolder="Enter Certificate Number"
                  className={cx(
                    "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                    { "border-red-500": errors.credential_id },
                    "border-grayLight"
                  )}
                />
                {errors.credential_id && (
                  <span className="text-red-500">This is required field</span>
                )}
              </div>
              <div className="flex justify-between mt-3">
                <div>
                  {!isNew && (
                    <DangerLightBtn onClick={() => onSubmit()} type='button'>
                      <span className='font-bold'>Delete Certificate</span>
                    </DangerLightBtn>
                  )}
                </div>
                <SuccessBtn
                  type="submit"
                >
                  { isNew ? "Add Certificate" : "Update Certificate"}
                </SuccessBtn>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
