import React from 'react';
import WhiteBox from '../../../../../sharedComponents/whiteBox/WhiteBox';
import Modal from '../../../../../sharedComponents/modal/Modal';
import SuccessBtn from '../../../../../sharedComponents/buttons/successBtn/SuccessBtn';
import DarkBtn from '../../../../../sharedComponents/buttons/darkBtn/DarkBtn';
import DangerLightBtn from '../../../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TripaTable from '../../../../../sharedComponents/table/Table';
import {
  CreateActionBtn,
  columns,
} from './reusableComponent';
import {
  deleteContact,
  getAllContacts,
  postContact,
} from '../../../../../../../api/hr';
function ContactList() {
  const initData = {
    first_name: '',
    last_name: '',
    email_id: '',
    phone_number: '',
    address: '',
  };
  const [contacts, setContacts] = React.useState([]);
  const [addModal, setAddModal] = React.useState(false);
  const [addData, setAddData] = React.useState(initData);
  const [editModal, setEditModal] = React.useState(false);
  const [editData, setEditData] = React.useState(initData);

  const [deleteModal, setDeleteModal] = React.useState(false);

  const handleChangeData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setAddData((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeEditData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setEditData((prev) => ({ ...prev, [name]: value }));
  };

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setEditModal(true);
  };
  const handleDelete = (row) => {
    setDeleteModal(row._id);
  };
  const getContacts = async () => {
    try {
      const res = await getAllContacts();
      if (res?.data && res.data.length > 0) {
        let newFormat = res.data.map((contact) => {
          return {
            ...contact,
            name: `${contact.first_name}  ${contact.last_name}`,
          };
        });
        setContacts(newFormat);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddContact = async () => {
    try {
      await postContact(addData);
      getContacts();
      setAddData(initData);
      setAddModal(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleEditContact = async () => {
    try {
      await postContact({ ...editData, id: editData._id });
      getContacts();
      setEditData(initData);
      setEditModal(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteContact = async () => {
    try {
      await deleteContact({ id: deleteModal });
      getContacts();
      setDeleteModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getContacts();
  }, []);
  return (
    <>
      <WhiteBox>
        <div className='flex flex-col md:flex-row items-center justify-between mb-6'>
          <div className='relative'>
            <input
              className='mb-3 md:mb-0 md:min-w-[270px] min-w-full '
              type='text'
              placeholder='Search Contact'
              style={{
                border: '1px solid #E3E6E9',
                borderRadius: '4px',
                padding: '7px 15px',
                outline: 'none',
                fontSize: '16px',
                color: '#384259',
              }}
            />
            <SearchOutlinedIcon
              sx={{
                position: 'absolute',
                color: '#7AB794',
                right: 15,
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
              }}
            />
          </div>
          <SuccessBtn
            onClick={() => {
              setAddData({
                first_name: '',
                last_name: '',
                email_id: '',
                phone_number: '',
                address: '',
              });
              setAddModal(true);
            }}
          >
            Add contact
          </SuccessBtn>
        </div>

        <TripaTable
          columns={columns}
          rows={contacts}
          CreateActionBtn={CreateActionBtn}
          colActionName='action'
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      </WhiteBox>
      <Modal title='Add contact' open={addModal} setOpen={setAddModal}>
        <form action=''>
          <div className='flex flex-col mb-4'>
            <label
              htmlFor='first_name'
              className='text-pirmaryBlue text-base font-normal'
            >
              First name
            </label>
            <input
              type='text'
              name='first_name'
              value={addData.first_name || ''}
              onChange={handleChangeData}
              id='first_name'
              placeholder='First Name'
              className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
            />
          </div>
          <div className='flex flex-col mb-4'>
            <label
              htmlFor='last_name'
              className='text-pirmaryBlue text-base font-normal'
            >
              Last name
            </label>
            <input
              type='text'
              name='last_name'
              value={addData.last_name || ''}
              onChange={handleChangeData}
              id='last_name'
              placeholder='Last Name'
              className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
            />
          </div>
          <div className='flex flex-col mb-4'>
            <label
              htmlFor='email_id'
              className='text-pirmaryBlue text-base font-normal'
            >
              Email
            </label>
            <input
              type='email'
              name='email_id'
              value={addData.email_id || ''}
              onChange={handleChangeData}
              id='email_id'
              placeholder='Enter email address'
              className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
            />
          </div>
          <div className='flex flex-col mb-4'>
            <label
              htmlFor='phone_number'
              className='text-pirmaryBlue text-base font-normal'
            >
              Mobile number
            </label>
            <input
              type='text'
              name='phone_number'
              value={addData.phone_number || ''}
              onChange={handleChangeData}
              id='phone_number'
              placeholder='Enter mobile number'
              className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
            />
          </div>
          <div className='flex flex-col mb-4'>
            <label
              htmlFor='address'
              className='text-pirmaryBlue text-base font-normal'
            >
              Address
            </label>
            <input
              type='text'
              name='address'
              value={addData.address || ''}
              onChange={handleChangeData}
              id='address'
              placeholder='Enter Address'
              className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
            />
          </div>

          <div className='flex items-center md:flex-row flex-col justify-between'>
            <DarkBtn
              type='button'
              customStyle={{ margin: '5px 0px' }}
              onClick={() => setAddModal(false)}
            >
              Cancel
            </DarkBtn>
            <SuccessBtn
              onClick={handleAddContact}
              type='button'
              customStyle={{ margin: '5px 0px' }}
            >
              Add contact
            </SuccessBtn>
          </div>
        </form>
      </Modal>
      {editModal && (
        <Modal title='Update contact' open={editModal} setOpen={setEditModal}>
          <form action=''>
            <div className='flex flex-col mb-4'>
              <label
                htmlFor='first_name'
                className='text-pirmaryBlue text-base font-normal'
              >
                First name
              </label>
              <input
                type='text'
                name='first_name'
                value={editData.first_name || ''}
                onChange={handleChangeEditData}
                id='first_name'
                placeholder='First Name'
                className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
              />
            </div>
            <div className='flex flex-col mb-4'>
              <label
                htmlFor='last_name'
                className='text-pirmaryBlue text-base font-normal'
              >
                Last name
              </label>
              <input
                type='text'
                name='last_name'
                value={editData.last_name || ''}
                onChange={handleChangeEditData}
                id='last_name'
                placeholder='Last Name'
                className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
              />
            </div>
            <div className='flex flex-col mb-4'>
              <label
                htmlFor='email_id'
                className='text-pirmaryBlue text-base font-normal'
              >
                Email
              </label>
              <input
                type='email'
                name='email_id'
                value={editData.email_id || ''}
                onChange={handleChangeEditData}
                id='email_id'
                placeholder='Enter email address'
                className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
              />
            </div>
            <div className='flex flex-col mb-4'>
              <label
                htmlFor='phone_number'
                className='text-pirmaryBlue text-base font-normal'
              >
                Mobile number
              </label>
              <input
                type='text'
                name='phone_number'
                value={editData.phone_number || ''}
                onChange={handleChangeEditData}
                id='phone_number'
                placeholder='Enter mobile number'
                className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
              />
            </div>
            <div className='flex flex-col mb-4'>
              <label
                htmlFor='address'
                className='text-pirmaryBlue text-base font-normal'
              >
                Address
              </label>
              <input
                type='text'
                name='address'
                value={editData.address || ''}
                onChange={handleChangeEditData}
                id='address'
                placeholder='Enter Address'
                className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
              />
            </div>

            <div className='flex items-center md:flex-row flex-col justify-between'>
              <DarkBtn
                type='button'
                customStyle={{ margin: '5px 0px' }}
                onClick={() => setEditModal(false)}
              >
                Cancel
              </DarkBtn>
              <SuccessBtn
                type='button'
                onClick={handleEditContact}
                customStyle={{ margin: '5px 0px' }}
              >
                Update contact
              </SuccessBtn>
            </div>
          </form>
        </Modal>
      )}
      <Modal title='Update contact' open={deleteModal} setOpen={setDeleteModal}>
        <p className='text-base text-[#384259] mt-8'>
          Are you sure you want to delete this contact?
        </p>
        <div className='flex items-center md:flex-row flex-col justify-between my-7'>
          <DarkBtn
            type='button'
            customStyle={{ margin: '5px 0px' }}
            onClick={() => setDeleteModal(false)}
          >
            No
          </DarkBtn>
          <DangerLightBtn
            onClick={handleDeleteContact}
            type='button'
            customStyle={{ margin: '5px 0px' }}
          >
            Yes
          </DangerLightBtn>
        </div>
      </Modal>
    </>
  );
}

export default ContactList;
