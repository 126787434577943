import React, { useState, useEffect } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import { ReactComponent as ArrowLeft } from "../../../../../assets/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../../../../assets/arrowRight.svg";
import SuccessBtn from "../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import Tooltip from "@mui/material/Tooltip";

import {
  useStyle,
  rows,
  columns,
  CreateAvatar,
} from "./reusableComponent";

import CondidateListCard from "./CondidateListCard";
import WhiteBox from "../../../../sharedComponents/whiteBox/WhiteBox";

import { ReactComponent as Female } from "../../../../../assets/Female.svg";
import { ReactComponent as Male } from "../../../../../assets/Male.svg";
import { ReactComponent as CandidateMatchingCompetencies } from "../../../../../assets/CandidateMatchingCompetencies.svg";
import { ReactComponent as CandidatesMatchingSkillsCompetencies } from "../../../../../assets/CandidatesMatchingSkillsCompetencies.svg";
import { ReactComponent as CandidatesMatchingSkills } from "../../../../../assets/CandidatesMatchingSkills.svg";
import { ReactComponent as CompletedAssessment } from "../../../../../assets/CompletedAssessment.svg";
import { ReactComponent as TotalAssessment } from "../../../../../assets/TotalAssessment.svg";
import { ReactComponent as PendingRequisitions } from "../../../../../assets/PendingRequisitions.svg";
import { ReactComponent as OpenRequisitions } from "../../../../../assets/OpenRequisitions.svg";
import { ReactComponent as OffersSent } from "../../../../../assets/OffersSent.svg";

import CondidateProfileModal from "./modals/CondidateProfileModal";

import {
  getHRProfile,
  getWorkforceList,
} from "../../../../../../api/hr";
import { getCompetencyTotal } from "../../../../../../utils/getCompetencyTotal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TableSortLabel from '@mui/material/TableSortLabel';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

function WorkforceList() {
  const navigate = useNavigate();
  const [candidProModal, setCandidProModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [rowsperpage, setRowsperpage] = React.useState(10);
  const [counts, setCounts] = React.useState();
  const [candidates, setCandidates] = React.useState([]);

  const classes = useStyle();

  // For Search Start
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCandidates, setFilteredCandidates] = useState([]);

  const filterCandidates = () => {
    const query = searchQuery.toLowerCase();
    const filtered = candidates.filter(candidate => {
      const fullName = `${candidate.name.name} ${candidate.name.lastName}`.toLowerCase();
      const gender = (candidate.gender || "").toLowerCase();
      const tester = (candidate.tester.props.children || "").toString().toLowerCase();
      const roleBasedCompetence = (candidate.roleBasedCompetence || "").toLowerCase();
      const competenceScore = (candidate.competenceScore || "").toLowerCase();
      const defaultBehavior = JSON.stringify(candidate.defaultBehaviour).toLowerCase();
      const country = (candidate.country || "").toLowerCase();

      return (
        fullName.includes(query) ||
        gender.includes(query) ||
        tester.includes(query) ||
        roleBasedCompetence.includes(query) ||
        competenceScore.includes(query) ||
        defaultBehavior.includes(query) ||
        country.includes(query)
      );
    });
    setFilteredCandidates(filtered);
  };

  useEffect(() => {
    filterCandidates();
  }, [searchQuery, candidates]);

  const displayedCandidates = searchQuery ? filteredCandidates : candidates;
  // For Search End

  // For Sorting Start
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('default');

  const handleSort = (columnId) => {
    if (orderBy === columnId) {
      setOrder((prevOrder) => {
        if (prevOrder === 'default') return 'asc';
        if (prevOrder === 'asc') return 'desc';
        return 'default'; // Toggle to default if currently descending
      });
    } else {
      setOrderBy(columnId);
      setOrder('asc');
    }
  };
  // For Sorting End

  const handleChange = (event, value) => {
    setPage(value);
  };

  const mapDriverOfEngagmentColors = {
    Aesthetic: "#A9D18E",
    Economic: "#4472C4",
    Individualistic: "#ED7D33",
    Political: "#C2342C",
    Altruistic: "#E5C311",
    Regulatory: "#7F7F7F",
    Theoretical: "#7F6000",
  }

  const charMapping = {
    I: {
      name: 'Interactive',
      color: '#e5c311',
    },
    D: {
      name: 'Decisive',
      color: '#ff0000',
    },
    S: {
      name: 'Steady',
      color: '#008000',
    },
    C: {
      name: 'Cautious',
      color: '#0000ff',
    },
  };

  const getCandidates = async () => {
    try {
      const companyProfileData = await getHRProfile();
      const res = await getWorkforceList();
      if (res?.data?.candidates?.length > 0) {
        let newObj = res.data.candidates.map((candidate, i) => {
          if (
            ![null, undefined, ""].includes(candidate.candidate_profile_id) &&
            Object.keys(candidate.candidate_profile_id).length > 0 && candidate?.candidate_profile_id?.first_name && candidate?.candidate_profile_id?.first_name != '' && candidate?.candidate_profile_id?.assessment_type && candidate?.candidate_profile_id?.assessment_type != ''
          ) {
            let profile = candidate.candidate_profile_id;
            let name = {
              name: profile?.first_name || candidate.first_name,
              lastName: profile?.last_name || candidate.last_name,
              src: profile?.candidate_picture_upload_path,
            };

            let gender = profile.gender ? profile.gender.charAt(0).toUpperCase() + profile.gender.slice(1).toLowerCase() : "";

            let valueData = candidate?.candidate_profile_id?.assessments?.find(
              ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
            )?.assessment?.assessment_content?.score || []

            const behaviorData = candidate?.candidate_profile_id?.assessments?.find(
              ({ assessment }) => assessment?.assessment_type === 'BehaviorAssessment'
            )?.assessment?.assessment_content?.score || []

            let behaviorResultData = candidate?.candidate_profile_id?.assessments.filter(
              (i) => i.assessment.assessment_type === "BehaviorAssessment"
            )?.[0]?.assessment?.result || []

            const max = Math.max(...behaviorResultData?.map((x) => Number(x?.value || 0)));
            let maxdefaultBehavior = behaviorResultData?.filter((x) => Number(x.value) === max);

            return {
              ...candidate,
              name: name,
              // gender: (
              //   <>
              //     <span className="capitalize">{profile.gender ?? ""}</span>
              //   </>
              // ),
              gender: gender,
              company: candidate.company_name ?? "",
              tester: (
                <>
                  <span>{companyProfileData?.data?.full_name}</span>
                </>
              ),
              assessmentStatus: "Completed",
              roleBasedCompetence: profile.assessment_type ?? "",
              competenceScore: getCompetencyTotal(profile.assessments) + "%",
              defaultBehaviour:
                maxdefaultBehavior?.map((preBehavior, index) => {
                  let behaviorTooltip = behaviorData?.find(({ type }) => type === preBehavior?.type)
                  return (
                    <>
                      <Tooltip
                        placement='top'
                        title={
                          <div className='rounded-md w-[550px]'>
                            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
                              {`${charMapping?.[preBehavior?.type]?.name} : ${behaviorTooltip?.value[0]?.value || 0
                                }%`}
                            </div>
                            <div
                              className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'
                              dangerouslySetInnerHTML={{
                                __html:
                                  behaviorTooltip?.statements.join('<br /><br />- ') ||
                                  '',
                              }}
                            />
                          </div>
                        }
                        arrow
                      >
                        <span className='text-base cursor-pointer' style={{ color: charMapping?.[preBehavior?.type]?.color }} id={`behavior${index}`}>
                          {charMapping?.[preBehavior?.type]?.name}
                          {index !== maxdefaultBehavior.length - 1 && ', '}
                        </span>
                      </Tooltip>
                    </>
                  );
                }),
              values:
                profile.driver_of_engagement?.length > 0 &&
                profile.driver_of_engagement?.split(',').map((data, i) => {

                  let valueTooltip = valueData?.find(({ section_fullname }) => data === section_fullname);
                  return (
                    <>
                      <Tooltip
                        key={i}
                        placement='top'
                        title={
                          <div className='rounded-md'>
                            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
                              {`${data} : ${valueTooltip?.value || 0}%`}
                            </div>
                            <div className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'>
                              {valueTooltip?.statement[0] || ''}
                            </div>
                          </div>
                        }
                        arrow
                      >
                        <span className='text-base cursor-pointer' style={{ color: mapDriverOfEngagmentColors?.[data] }} id={i}>
                          {data}
                          {i !== profile.driver_of_engagement.split(',').length - 1 && ', '}
                        </span>
                      </Tooltip>
                    </>
                  )
                }),
              country: profile.country ?? "",
              city: profile.city ?? "",
            };
          } else {
            let name = {
              name: candidate.first_name,
              lastName: candidate.last_name,
              src: '',
            };
            return {
              ...candidate,
              name: name,
              gender: "",
              company: candidate.company_name ?? "",
              tester: (
                <>
                  <span>{companyProfileData?.data?.full_name}</span>
                </>
              ),
              assessmentStatus: "No completed",
              roleBasedCompetence: candidate.assessment_type ?? "",
              competenceScore: "",
              defaultBehaviour: "",
              values: "",
              country: "",
              city: ""
            };
          }
        });
        setCandidates(newObj);
        setCounts(res.data.counts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getCandidates();
  }, []);

  return (
    <>
      <div className="mt-[40px] mb-[24px]">
        {counts && (
          <div className="grid 2xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
            <CondidateListCard
              title="Female"
              number={counts.female}
              image={<Female />}
            />
            <CondidateListCard
              title="Male"
              number={counts.male}
              image={<Male />}
            />
            <CondidateListCard
              title="Candidate matching competencies"
              number={counts.candidatesMatchingCompetences}
              image={<CandidateMatchingCompetencies />}
            />
            <CondidateListCard
              title="Candidates matching skills & competencies"
              number={counts.candidatesMatchingSkillsAndCompetences}
              image={<CandidatesMatchingSkillsCompetencies />}
            />
            <CondidateListCard
              title="Candidates matching skills"
              number={counts.candidatesMatchingSkills}
              image={<CandidatesMatchingSkills />}
            />
          </div>
        )}

        {/* Candidate Pool Tabel start */}
        <WhiteBox
          style={{ width: "100%", minHeight: "600px", margin: "30px auto" }}
        >
          <div className="">
            <div className="col-span-4">
              <div className='mb-4 flex justify-end candidate-list-and-workforce-search'>
                <Paper
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                  onSubmit={(e) => {
                    e.preventDefault();
                    filterCandidates();
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search candidate"
                    inputProps={{ 'aria-label': 'search candidate' }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                    style={{ color: '#7AB794' }}
                    onClick={filterCandidates} // Optionally, trigger the filter on button click
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer className={classes.table}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={
                              column.id === "name"
                                ? {
                                  minWidth: column.minWidth,
                                  position: "sticky",
                                  left: 0,
                                  background: "#f4f6f8 !important",
                                  boxShadow:
                                    "-3px -3px 8px rgba(0, 0, 0, 0.1)",
                                  textAlign: "center"
                                }
                                : { minWidth: column.minWidth, textAlign: "center" }
                            }
                          >
                            {column.id === 'name' ||
                              column.id === 'roleBasedCompetence' || // Updated column ID
                              column.id === 'defaultBehaviour' || column.id === 'gender' || column.id === 'tester' || column.id === 'country'
                              ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={
                                    orderBy === column.id
                                      ? order === 'default'
                                        ? 'asc'
                                        : order
                                      : 'asc'
                                  }
                                  onClick={() => handleSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedCandidates
                        .slice(
                          (page - 1) * rowsperpage,
                          (page - 1) * rowsperpage + rowsperpage
                        )
                        .sort((a, b) => {
                          console.log('a.gender:', a.gender);
                          console.log('b.gender:', b.gender);

                          if (order === 'default') return 0; // No sorting, return the original order
                          if (orderBy === 'name') {
                            // Sort by name
                            if (order === 'asc') {
                              return a.name.name.localeCompare(b.name.name);
                            } else {
                              return b.name.name.localeCompare(a.name.name);
                            }
                          } else if (orderBy === 'roleBasedCompetence') {
                            // Sort by Role-based competencies
                            if (order === 'asc') {
                              return a.roleBasedCompetence.localeCompare(b.roleBasedCompetence);
                            } else {
                              return b.roleBasedCompetence.localeCompare(a.roleBasedCompetence);
                            }
                          }
                          else if (orderBy === 'defaultBehaviour') {
                            const defaultBehaviourA = JSON.stringify(a.defaultBehaviour);
                            const defaultBehaviourB = JSON.stringify(b.defaultBehaviour);
                            //  Sort by defaultBehaviour competencies
                            if (order === 'asc') {
                              return defaultBehaviourA.localeCompare(b.defaultBehaviourB);
                            } else {
                              return defaultBehaviourB.localeCompare(defaultBehaviourA);
                            }
                          }
                          else if (orderBy === 'gender') {
                            const genderA = a.gender || ""; // Ensure it's a string and handle null/undefined
                            const genderB = b.gender || "";
                            // Sort by gender competencies
                            if (order === 'asc') {
                              return genderA.localeCompare(genderB);
                            } else {
                              return genderB.localeCompare(genderA);
                            }
                          }
                          else if (orderBy === 'tester') {
                            const testerA = (a.tester.props.children || '').toString();
                            const testerB = (b.tester.props.children || '').toString();
                            // Sort by tester competencies
                            if (order === 'asc') {
                              return testerA.localeCompare(testerB);
                            } else {
                              return testerB.localeCompare(testerA);
                            }
                          }
                          else if (orderBy === 'country') {
                            // Sort by tester competencies
                            if (order === 'asc') {
                              return a.country.localeCompare(b.country);
                            } else {
                              return b.country.localeCompare(a.country);
                            }
                          }
                          else {
                            return 0; // No sorting for other columns
                          }
                        })
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              {columns.map((column) => {
                                let value;

                                if (column.id === "name") {
                                  const nameData = row["name"];
                                  value = (
                                    <CreateAvatar
                                      src={nameData.src}
                                      name={
                                        nameData.name + ' ' + nameData.lastName
                                      }
                                      onClick={() => {
                                        if (row["assessmentStatus"] == 'Completed') {
                                          setCandidProModal(row)
                                        } else {
                                          toast.warning("Employee has not finished its assessment.")
                                        }
                                      }}
                                    />
                                  );
                                } else if (column.id === "actions") {
                                  value = (
                                    <IconButton
                                      // color="error"
                                      aria-label="delete"
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  );
                                } else {
                                  value = row[column.id];
                                }

                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={
                                      column.id === "name"
                                        ? {
                                          position: "sticky",
                                          left: 0,
                                          background: "#ffffff !important",
                                          boxShadow:
                                            "-3px 3px 8px rgba(0, 0, 0, 0.1)",
                                        }
                                        : {}
                                    }
                                  >
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="flex flex-wrap items-center justify-between">
                  <SuccessBtn onClick={() => navigate('/business/team-chemistry')} customStyle={{ marginLeft: "20px" }}>
                    Check Team Chemistry
                  </SuccessBtn>
                  <div className="relative mr-5">
                    <Pagination
                      count={
                        candidates.length < 10
                          ? 1
                          : Math.ceil(candidates.length / rowsperpage)
                      }
                      page={page}
                      onChange={handleChange}
                      rowsperpage={rowsperpage}
                      className={classes.pagination}
                    />
                    <ArrowLeft
                      style={{
                        position: "absolute",
                        left: 0,
                        top: "48%",
                        transform: "translateY(-50%)",
                        zIndex: 100,
                        opacity: page - 1 === 0 ? 0.5 : 1,
                      }}
                    />
                    <ArrowRight
                      style={{
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 100,
                        opacity:
                          page === Math.ceil(rows.length / rowsperpage)
                            ? 0.5
                            : 1,
                      }}
                    />
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </WhiteBox>
        {/* Candidate Pool Tabel end */}

        {/* {chartData && <BarCharts data={chartData} />} */}

        {counts && (
          <div className="grid 2xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-24">
            <CondidateListCard
              title="Completed assessment"
              number={counts.completedAssessment}
              image={<CompletedAssessment />}
            />
            <CondidateListCard
              title="Total assessment"
              number={counts.totalAssessment}
              image={<TotalAssessment />}
            />
            <CondidateListCard
              title="Pending job requisitions"
              number={counts.pendingJobRequisitions}
              image={<PendingRequisitions />}
            />
            <CondidateListCard
              title="Open requisitions"
              number={counts.openRequisitions}
              image={<OpenRequisitions />}
            />
            <CondidateListCard
              title="Offers sent"
              number={counts.offersSent}
              image={<OffersSent />}
            />
          </div>
        )}
      </div>

      {/* modals start */}
      <CondidateProfileModal
        open={candidProModal}
        setOpen={setCandidProModal}
      />
      {/* modals end */}
    </>
  );
}

export default WorkforceList;
