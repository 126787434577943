import React from "react";
import BoxInfo from "../../../../../sharedComponents/profile/infoBox";
import HorizontalProfileInfo from "../../../../../sharedComponents/horizontalProfileInfo/HorizontalProfileInfo";
import CompetencyChart from "../../../../../sharedComponents/candidateProfile/CompetencyChart";
import avatar from "../../../../../../assets/Male.svg";
import { mapBehaviourNameColor } from "../../../../../../../utils/getColors";
import { getCandidateBehaviorForRadarPlotting, getCandidateTeamCompatibility } from "../../../../../../../utils/getTeamPlotting";

function LowComfortProfile({ profile }) {
  let competencies = profile?.assessments.find(
    ({ assessment }) =>
      assessment.assessment_type === "RoleBasedCompetanceAssessment"
  )?.assessment?.result;
  
  let roleBasedCompetenciesResultData = profile?.assessments?.find(
    ({ assessment }) => assessment?.assessment_type === 'RoleBasedCompetanceAssessment'
  )?.assessment?.assessment_content || []
  
  let roleBaseData = roleBasedCompetenciesResultData?.aiRoleCompetencies || []

  const internalData = roleBasedCompetenciesResultData?.aiDescriptor?.find(({ heading }) => heading === 'InternalPatternsPage') || []
  const internalMotivatorsData = internalData?.sections?.find(({ title }) => title === 'Motivators') || []
  let motivators = []
  if(internalMotivatorsData?.statements){
    motivators = internalMotivatorsData?.statements.split('\n')
  }

  const behaviourData = profile?.assessments?.find(
    ({ assessment }) => assessment?.assessment_type === 'BehaviorAssessment'
  )?.assessment?.assessment_content || []

  const teamPlottingData = profile?.assessments?.find(
    ({ assessment }) => assessment?.assessment_type === 'BehaviorAssessment'
  )?.assessment?.team_plotting || []
  
  const behaviourDiscDescriptor = behaviourData?.discDescriptor || []
  let behaviourStrength = behaviourDiscDescriptor?.find(({ section }) => section === 'sbi')
  
  let cell = teamPlottingData?.cell;
  let candidateInfo = getCandidateBehaviorForRadarPlotting(cell)

  let newCandidateInfo = getCandidateTeamCompatibility(profile?.preferredBehavior);

  return (
    <BoxInfo hasIcon={true}>
      <HorizontalProfileInfo
        src={profile.candidate_picture_upload_path || avatar}
        alt="condidate"
        name={[profile.first_name, profile.last_name].join(" ")}
        subName={profile?.preferredBehavior||''}
      />

      <div className="flex items-center">
        <div className="">
          <span className="block h-[60px] w-[5px]" style={{ 'background-color': newCandidateInfo?.color }}></span>
        </div>
        <div className="h-[60px] max-h-[60px] overflow-auto">
          <div className="mr-[24px] min-h-full">
            <p className="text-xs text-pirmaryBlue ml-4">{newCandidateInfo?.details}</p>
          </div>
        </div>
      </div>

      <div className="my-6">
        <h2 className="text-[16px] font-bold text-pirmaryBlue mt-[14px] mb-4">
          Motivators
        </h2>
        <div className="h-[100px] max-h-[100px] overflow-auto">
          <div className="mr-[24px] min-h-full">
          {motivators?.map((motivators_sta, index) => {
            return(
              <>
                <div className="text-[#828282] font-normal text-[14px]" key={index}>
                  {motivators_sta}
                </div>
              </>
            )
          })}
          </div>
        </div>
      </div>

      <div className="my-6">
        <h2 className="text-[16px] font-bold text-pirmaryBlue mt-[14px] mb-4">
          Behavior strengths
        </h2>
        <div className="h-[150px] max-h-[150px] overflow-auto">
          <div className="mr-[24px] min-h-full">
            <ul className="list-disc pl-[20px] mb-[14px] text-base">
              {behaviourStrength?.subsection?.length &&
                behaviourStrength.subsection[0]?.statements?.length &&
                  behaviourStrength?.subsection[0]?.statements?.map((item, index) => (
                    <li className="text-[#828282] font-normal text-[14px] decoration-clone" key={index + 1}>
                      {item}
                    </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="my-6">
        <h2 className="text-[16px] font-bold text-pirmaryBlue mt-[14px] mb-4">
          Role-based competencies
        </h2>
        <p className='text-base text-[14px] text-center font-bold text-pirmaryBlue mb-1'>
          {profile?.assessment_type}
        </p>
        <CompetencyChart layout="vertical" competencies={competencies} roleBaseData={roleBaseData} />
      </div>
    </BoxInfo>
  );
}

export default LowComfortProfile;
