import React from "react";
import CondidateListCard from "../condidateList/CondidateListCard";
import { ReactComponent as CompletedAssessment } from "../../../../../assets/CompletedAssessment.svg";
import { ReactComponent as TotalAssessment } from "../../../../../assets/TotalAssessment.svg";
import { ReactComponent as PendingRequisitions } from "../../../../../assets/PendingRequisitions.svg";
import { ReactComponent as OpenRequisitions } from "../../../../../assets/OpenRequisitions.svg";
import { ReactComponent as OffersSent } from "../../../../../assets/OffersSent.svg";

function BottomCards({ counts }) {
  return (
    <div className="grid 2xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-[24px]">
      <CondidateListCard
        title="Completed assessment"
        number={counts.completedAssessment}
        image={<CompletedAssessment />}
      />
      <CondidateListCard
        title="Total assessment"
        number={counts.totalAssessment}
        image={<TotalAssessment />}
      />
      <CondidateListCard
        title="Pending Job Requisitions"
        number={counts.pendingJobRequisitions}
        image={<PendingRequisitions />}
      />
      <CondidateListCard
        title="Open requisitions"
        number={counts.openRequisitions}
        image={<OpenRequisitions />}
      />
      <CondidateListCard
        title="Offers sent"
        number={counts.offersSent}
        image={<OffersSent />}
      />
    </div>
  );
}

export default BottomCards;
