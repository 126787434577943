import React from "react";

import WrapperComponentHr from "./wrapperComponentHr/WrapperComponentHr";

import { useLocation, Outlet , useNavigate} from "react-router-dom";
import CryptoJS from 'crypto-js';
import config from "../../../../config/config";

function HrPanel() {
  const [pageTitle, setPageTitle] = React.useState();
  const { pathname } = useLocation();
  const page = pathname.split("/business/")[1];
  const history = useNavigate();

  const getData = async () => {
    try {
      const ciphertext = localStorage.getItem("role");
      var bytes = CryptoJS.AES.decrypt(ciphertext, config.cookieSecret);
      var role = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (!(role === 'company')){
        history("/unauthorize");
      }
  } catch (e) {
    console.error(e);
  }
    
  }
  React.useEffect(() => {
    getData();
  },[]);
  
  React.useEffect(() => {
    const pageUpperCase = page?.toUpperCase().split("-").join(" ");
    setPageTitle(pageUpperCase);
  }, [page]);
  
  return (
    <WrapperComponentHr title={pageTitle}>
      <Outlet />
    </WrapperComponentHr>
  );
}

export default HrPanel;
