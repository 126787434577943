import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

export const useStyle = makeStyles((theme) => ({
  root: {},
  completeSignupBtn: {
    "&.MuiButton-containedSuccess": {
      backgroundColor: "#7AB794 !important",
      fontSize: "16px",
      marginTop: "25px",
    },
  },
  checkbox: {
    "& .Mui-checked": {
      color: "#7AB693 !important",
    },
    "& .MuiFormControlLabel-label": {
      color: "#828282",
    },
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E3E6E9 !important",
      backgroundColor: "transparent !important",
      top: 0 ,

      "& .css-1in441m": {
        display: "none",
      },
      "& .css-1ftyaf0": {
        display: "none",
      },
    },
    "& .MuiSelect-nativeInput": {
      bottom: "50%",
      transform: "translateY(50%)",
      left: "20px !important",
      opacity: 0,
    },
    "& .MuiSelect-icon": {
      right: "20px !important",
      fill: "#7E8B96 !important",
    },
  },
  inputLabel: {
    "&.Mui-focused ": {
      color: "#384259 !important",
    },
    "&.MuiInputLabel-root": {
      color: "#384259 !important",
      top: "-45px",
      transform: "none !important",
      position: "static !important",
    },
  },
}));

export const formValidate = (values) => {
  const myError = false;
  if (!values.firstName) {
    toast.error("First Name is required!");
    return true;
  }
  if (!values.lastName) {
    toast.error("Last Name is required");
    return true;
  }
  if (!values.company_name) {
    toast.error("Company Name is required");
    return true;
  }
  if (!values.industry) {
    toast.error("Industry is required");
    return true;
  }
  if (!values.job_title) {
    toast.error("Job Title is required");
    return true;
  }
  if (!values.number_of_employee) {
    toast.error("Number of employee is required");
    return true;
  }
  if (!values.country) {
    toast.error("country is required");
    return true;
  }
  if (!values.city) {
    toast.error("city is required");
    return true;
  }
  return myError;
};
