import React, { useState } from 'react';
import WhiteBox from '../../../../sharedComponents/whiteBox/WhiteBox';
import SelectField from '../../../../sharedComponents/selectField/SelectField';
import InfoBoxes from './createJob/reuseableComponent';
import { checkboxStyle } from './createJob/reuseableComponent';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SuccessBtn from '../../../../sharedComponents/buttons/successBtn/SuccessBtn';
import DarkBtn from '../../../../sharedComponents/buttons/darkBtn/DarkBtn';
import { getHRProfile, postHRSendAssessment } from '../../../../../../api/hr';
import { toast } from 'react-toastify';
import { competenciesData } from '../../../../../contants/jobReqData';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Field, FieldArray, FormikProvider, getIn, useFormik } from "formik";
import { FocusError } from 'focus-formik-error';
import cx from "classnames";
import * as Yup from 'yup';

function CreateCandidateTest() {
  const navigate = useNavigate();
  let initData = {
    job_title: '',
    assessment_type: '',
    company_name: '',
    testee_details : [ {first_name: '', last_name: '', email: ''}],
    notify_to : [ {first_name: '', last_name: '', email: ''}],
    introduction_message: '',
    notify: false
  };
  const [initialValues, setInitialValues] = useState(initData);

  const getProfileData = async () => {
    try {
      const res = await getHRProfile();
      if (res?.data) {
        let data = res.data;
        if (data.company_name)
        setInitialValues((prev) => ({
          ...prev,
          company_name: data.company_name
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getProfileData();
  }, []);

  const hadleCreateTest = async () => {
    try {
      if(!formik.values.notify){
        formik.values.notify = false
      }
      let bodyFormData = formik.values;
      const res = await postHRSendAssessment(bodyFormData);
      if (res?.status === 200) {
        toast.success('Test created successfully!');
        setInitialValues(initData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validationSchema = Yup.object({
    job_title: Yup.string().required("This is required field"),
    assessment_type: Yup.string().required("This is required field"),
    company_name: Yup.string().required("This is required field"),
    
    testee_details: Yup.array(Yup.object({
      first_name: Yup.string().required("This is required field"),
      last_name: Yup.string().required("This is required field"),
      email: Yup.string().email('Invalid email').required("This is required field")
    })).required("This is required field"),

    notify_to: Yup.array(Yup.object({
        first_name: Yup.string().required("This is required field"),
        last_name: Yup.string().required("This is required field"),
        email: Yup.string().email('Invalid email').required("This is required field")
    })).required("This is required field"),

    //introduction_message: Yup.string().required("Required"),
  })

  const formik = useFormik({
    initialValues,
    hadleCreateTest,
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      hadleCreateTest(values);
      formik.resetForm()
    },
  });
  const { errors } = formik || {};
  const classes = checkboxStyle();

  const ErrorMessage = ({ name }) => (
    <Field name={name}>
      {props => {
        const error = getIn(props.form.errors, name);
        const touch = getIn(props.form.touched, name);
        return touch && error ? (<span className="text-red-500">{error}</span>) : null;
      }}
    </Field>
  );

  return (
    <WhiteBox title='Create a test'>
      <form className='mt-[24px]' onSubmit={formik.handleSubmit}>
        <FocusError formik={formik} />
        <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-7 gap-y-3">
          <div className='flex flex-col'>
            <label
              htmlFor='job_title'
              className='text-pirmaryBlue text-base font-normal flex items-center'
            >
              Job title
              <InfoBoxes TooltipTitle='Job title' TooltipText='Type the job title you will be testing the candidate'></InfoBoxes>
            </label>
            <input
              type='text'
              name='job_title'
              value={formik.values.job_title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='job_title'
              placeholder='Job Title'
              //className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
              className={cx(
                "w-full rounded border-2 border-solid text-sm text-pirmaryBlue p-[12px] focus:outline-none",
                //{ "border-red-500": errors.job_title },
                "border-grayLight"
              )}
            />
            {errors.job_title && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>

          <div className='flex flex-col'>
            <SelectField
              selectItems={competenciesData}
              label='Role-based competencies'
              labelId='assessment_type'
              id='assessment_type'
              value={formik.values.assessment_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='assessment_type'
              placeholder='Select'
              inputStyle={{ padding: '14px' }}
              className={cx(
                "w-full rounded border-2 border-solid text-sm text-pirmaryBlue p-[12px] focus:outline-none",
                //{ "border-red-500": errors.assessment_type },
                "border-grayLight"
              )}
            />
            {errors.assessment_type && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>

          <div className='flex flex-col'>
            <label
              htmlFor='company_name'
              className='text-pirmaryBlue text-base font-normal flex items-center'
            >
              Company name
              <InfoBoxes TooltipTitle='Company name' TooltipText='Enter your company name'></InfoBoxes>
            </label>
            <input
              type='text'
              name='company_name'
              value={formik.values.company_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='company_name'
              placeholder='Company Name'
              className={cx(
                "w-full rounded border-2 border-solid text-sm text-pirmaryBlue p-[12px] focus:outline-none",
                //{ "border-red-500": errors.company_name },
                "border-grayLight"
              )}
            />
            {errors.company_name && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
        </div>

        <FormikProvider value={formik}>
          <FieldArray
            name="testee_details"
            render={arrayHelpers => (
              <div>
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 mt-4">
                  <div className='flex flex-col'>
                    <label
                      className='text-pirmaryBlue text-base font-normal flex items-center'
                    >
                      Testee details
                      <InfoBoxes TooltipTitle='Number Of Testee' TooltipText='Please enter the first, last name and email address of the testee(s).'></InfoBoxes>
                    </label>
                  </div>
                  {/* <div className='text-right'>
                  <SuccessBtn className="w-[130px]" type="button" onClick={() => arrayHelpers.push({first_name: '', last_name: '', email: ''})}>Add Testee</SuccessBtn>
                  </div> */}
                </div>
                {formik.values.testee_details && formik.values.testee_details.length > 0 ? (
                  formik.values.testee_details.map((input, index) => (
                  <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-7 gap-y-3 mt-3" key={index}>
                    <div className='flex flex-col'>
                      <Field
                        name={`testee_details[${index}].first_name`}
                        id={`testee_details[${index}].first_name`}
                        placeholder='First Name'
                        type="text"
                        className={cx(
                          "w-full rounded border-2 border-solid text-sm text-pirmaryBlue p-[12px] focus:outline-none",
                          //{ "border-red-500": getIn(errors, `testee_details[${index}].first_name`) },
                          "border-grayLight"
                        )}
                      />
                      <ErrorMessage name={`testee_details[${index}].first_name`} />
                    </div>
                    <div className='flex flex-col'>
                      <Field
                        name={`testee_details[${index}].last_name`}
                        placeholder='Last Name'
                        type="text"
                        className={cx(
                          "w-full rounded border-2 border-solid text-sm text-pirmaryBlue p-[12px] focus:outline-none",
                          //{ "border-red-500": getIn(errors, `testee_details[${index}].last_name`) },
                          "border-grayLight"
                        )}
                      />
                      <ErrorMessage name={`testee_details[${index}].last_name`} />
                    </div>
                    <div className='flex-col'>
                      <div className={`inline`}>
                        <div className={`inline-block w-[75%]`}>
                          <Field
                            name={`testee_details[${index}].email`}
                            placeholder='Email'
                            type="text"
                            className={cx(
                              "w-full rounded border-2 border-solid text-sm text-pirmaryBlue p-[12px] focus:outline-none",
                              //{ "border-red-500": getIn(errors, `testee_details[${index}].email`) },
                              "border-grayLight"
                            )}
                          />
                          <ErrorMessage name={`testee_details[${index}].email`} />
                        </div>
                        <div className={`inline-block w-[25%] align-top ${index > 0 ? "text-center" : "text-right"}`}>
                          {index > 0 ? (
                            <DeleteOutlineOutlinedIcon sx={{color: "#828282", cursor: "pointer"}} onClick={() => arrayHelpers.remove(index)} />
                          ) : (
                            <SuccessBtn type="button" className="px-[15px]" onClick={() => arrayHelpers.push({first_name: '', last_name: '', email: ''})}>Add</SuccessBtn>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  ))
                ) : (<></>
                )}
              </div>
            )}
          />
        </FormikProvider>
        
        <FormikProvider value={formik}>
          <FieldArray
            name="notify_to"
            render={arrayHelpers => (
              <div>
                <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 mt-4">
                  <div className='flex flex-col'>
                    <label
                      className='text-pirmaryBlue text-base font-normal flex items-center'
                    >
                      Notify to
                      <InfoBoxes TooltipTitle='Notify to' TooltipText='Please enter the first, last name and email address of the people that needs to be notified about the assessment request was sent to the testee(s).'></InfoBoxes>
                    </label>
                  </div>
                  {/* <div className='text-right'>
                  <SuccessBtn className="w-[130px]" type="button" onClick={() => arrayHelpers.push({first_name: '', last_name: '', email: ''})}>Add</SuccessBtn>
                  </div> */}
                </div>
                {formik.values.notify_to && formik.values.notify_to.length > 0 ? (
                  formik.values.notify_to.map((input, index) => (
                  <div className="grid sm:grid-cols-3 grid-cols-1 gap-x-7 gap-y-3 mt-3" key={index}>
                    <div className='flex flex-col'>
                      <Field
                        name={`notify_to[${index}].first_name`}
                        placeholder='First Name'
                        type="text"
                        className={cx(
                          "w-full rounded border-2 border-solid text-sm text-pirmaryBlue p-[12px] focus:outline-none",
                          //{ "border-red-500": getIn(errors, `notify_to[${index}].first_name`) },
                          "border-grayLight"
                        )}
                      />
                      <ErrorMessage name={`notify_to[${index}].first_name`} />
                    </div>
                    <div className='flex flex-col'>
                      <Field
                        name={`notify_to[${index}].last_name`}
                        placeholder='Last Name'
                        type="text"
                        className={cx(
                          "w-full rounded border-2 border-solid text-sm text-pirmaryBlue p-[12px] focus:outline-none",
                          //{ "border-red-500": getIn(errors, `notify_to[${index}].last_name`) },
                          "border-grayLight"
                        )}
                      />
                      <ErrorMessage name={`notify_to[${index}].last_name`} />
                    </div>
                    <div className='flex-col'>
                      <div className={`inline`}>
                        <div className={`inline-block w-[75%]`}>
                          <Field
                            name={`notify_to[${index}].email`}
                            placeholder='official email johndoe@abcde.com'
                            type="text"
                            className={cx(
                              "w-full rounded border-2 border-solid text-sm text-pirmaryBlue p-[12px] focus:outline-none",
                              //{ "border-red-500": getIn(errors, `notify_to[${index}].email`) },
                              "border-grayLight"
                            )}
                          />
                          <ErrorMessage name={`notify_to[${index}].email`} />
                        </div>
                        <div className={`inline-block w-[25%] align-top ${index > 0 ? "text-center" : "text-right"}`}>
                          {index > 0 ? (
                            <DeleteOutlineOutlinedIcon sx={{color: "#828282", cursor: "pointer"}} onClick={() => arrayHelpers.remove(index)} />
                          ) : (
                            <SuccessBtn type="button" className="px-[15px]" onClick={() => arrayHelpers.push({first_name: '', last_name: '', email: ''})}>Add</SuccessBtn>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  ))
                ) : (<></>
                )}
              </div>
            )}
          />
        </FormikProvider>
        
        <div className="grid sm:grid-cols-1 grid-cols-1 gap-x-7 gap-y-3 mt-4">
          {/* <div className='flex flex-col'>
            <label
              htmlFor='introduction_message'
              className='text-pirmaryBlue text-base font-normal flex items-center'
            >
              Introduction message
              <InfoBoxes TooltipTitle='Introduction message' TooltipText='Write a greeting, include a sentence on why you are sending the role-based competencies test'></InfoBoxes>
            </label>
            <textarea
              value={formik.values.introduction_message}
              name='introduction_message'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur} 
              placeholder="Introduction Message"
              className={cx(
                "w-full rounded border-2 border-solid text-sm text-pirmaryBlue p-[12px] focus:outline-none resize-y min-h-[100px]",
                //{ "border-red-500": errors.introduction_message },
                "border-grayLight"
              )}
            />
            {errors.introduction_message && (
              <span className="text-red-500">This is required field</span>
            )}
          </div> */}
          <div className='flex flex-col'>
            <label
              htmlFor='notify'
              className='text-pirmaryBlue text-base font-normal flex items-center'
            >
              Notify when report is completed
              <InfoBoxes TooltipTitle='Notify' TooltipText='Select "Yes" to be notified when the testee(s) complete the assessment.'></InfoBoxes>
            </label>
            <FormGroup>
              <FormControlLabel
                checked={formik.values.notify}
                onChange={() => formik.setFieldValue('notify', !formik.values.notify)}
                control={<Checkbox />}
                name='notify'
                label='Yes'
                inputProps={{ 'aria-label': 'controlled' }}
                className={classes.root}
              />
            </FormGroup>
          </div>
        </div>

        <div className='flex flex-wrap items-center justify-between my-[33px]'>
          <DarkBtn onClick={() => navigate('/business/manager-admin')}>Cancel</DarkBtn>
          <SuccessBtn type="submit">Create Test</SuccessBtn>
        </div>
      </form>
    </WhiteBox>
  );
}

export default CreateCandidateTest;
