import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";
import WhiteBox from "../../../../sharedComponents/whiteBox/WhiteBox";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const useStyle = makeStyles((theme) => ({
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E3E6E9 !important",
      backgroundColor: "transparent !important",
      top: 0,

      "& .css-1in441m": {
        display: "none",
      },
      "& .css-1ftyaf0": {
        display: "none",
      },
    },
    "& .MuiSelect-nativeInput": {
      bottom: "50%",
      transform: "translateY(50%)",
      left: "20px !important",
      opacity: 0,
    },
    "& .MuiSelect-icon": {
      right: "20px !important",
      fill: "#7E8B96 !important",
    },
    "& .MuiInputBase-input": {
      padding: "0px",
    },
  },
  inputLabel: {
    "&.Mui-focused ": {
      color: "#384259 !important",
    },
    "&.MuiInputLabel-root": {
      color: "#384259 !important",
      fontSize: "16px",
      transform: "none !important",
      position: "static !important",
    },
  },
}));
export const checkboxStyle = makeStyles({
  root: {
    "&.Mui-checked": {
      color: "#7AB693 !important",
    },
  },
});
export function ProfileCheckbox({
  id,
  src,
  alt = "",
  candidate,
  setCandidate,
  setSelectedCandidates,
  controlled,
  selectedCandidates,
}) {
  let {
    candidate_profile_id: {
      first_name,
      last_name,
      _id,
      candidate_picture_upload_path,
    },
  } = candidate;
  const classes = checkboxStyle();
  let additionalProps = {};
  if (controlled) {
    additionalProps = {
      ...additionalProps,
      checked: selectedCandidates.includes(_id),
    };
  }
  return (
    <div className="flex items-center my-4">
      <Checkbox
        {...label}
        id={id}
        className={classes.root}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedCandidates((prevCandidates) => [...prevCandidates, _id]);
          } else {
            setSelectedCandidates((prevCandidates) => {
              let newCandidateList = prevCandidates.filter(
                (candidateId) => candidateId !== _id
              );
              return newCandidateList;
            });
          }
        }}
        {...additionalProps}
      />
      <label
        // htmlFor={id}
        onClick={() => setCandidate(candidate)}
        className="ml-6 flex items-center text-base font-normal text-[#2C2C2C] cursor-pointer"
      >
        <Avatar
          src={candidate_picture_upload_path}
          alt={alt}
          sx={{ marginRight: "6px" }}
        />{" "}
        {`${first_name} ${last_name}`}
      </label>
    </div>
  );
}

export function CandidatePoolWrapper({
  title,
  icon,
  children,
  handleClickAdd,
}) {
  return (
    <>
      <div className=" shadow-md px-6 mt-[46px] p-8 rounded-2xl bg-white lg:mx-auto md:m-10 mx-1 my-3">
        <div className="flex justify-between mb-6">
          <p className="font-bold text-2xl text-[#384259] ">{title}</p>
          <span
            className="text-secondaryBalck cursor-pointer relative top-1"
            onClick={handleClickAdd}
          >
            {icon}
          </span>
        </div>
        {children}
      </div>
    </>
  );
}

export function ScrollBoxWrapper({ title, children, TooltipText, TooltipTitle }) {
  return (
    <WhiteBox
      title={title}
      TooltipText={TooltipText} 
      TooltipTitle={TooltipTitle}
      titleStyle={{
        fontSize: "24px",
        fontWeight: "700",
        color: "#384259",
        marginBottom: "24px",
      }}
      style={{
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
        padding: "24px  0px 36px 32px",
        margin: "12px 0px",
      }}
    >
      <div className="h-auto max-h-[150px]  overflow-auto ">
        <div className="mr-[24px] min-h-full ">{children}</div>
      </div>
    </WhiteBox>
  );
}

export function ScrollBoxWrapperForcompareView({ title, children, TooltipText, TooltipTitle }) {
  return (
    <WhiteBox
      title={title}
      TooltipText={TooltipText} 
      TooltipTitle={TooltipTitle}
      titleStyle={{
        fontSize: "22px",
        fontWeight: "700",
        color: "#384259",
        marginBottom: "24px",
      }}
      style={{
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
        padding: "24px  0px 36px 32px",
        margin: "12px 0px",
      }}
    >
      <div className="h-[150px] max-h-[150px]  overflow-auto ">
        <div className="mr-[24px] min-h-full ">{children}</div>
      </div>
    </WhiteBox>
  );
}

export function InfoBoxes({ title, TooltipTitle, TooltipText, hasIcon }) {
  return (
    <span className="text-base font-normal text-[#384259] ">
      <span className="pr-1">{title}</span>
      {!hasIcon && (
        <Tooltip
          placement="top"
          title={
            <div className="rounded-md">
              {TooltipTitle && (
                <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                  {TooltipTitle}
                </div>
              )}
              <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                {TooltipText}
              </div>
            </div>
          }
          arrow
        >
          <InfoIcon
            className="text-secondaryBalck cursor-pointer"
            fontSize="small"
          />
        </Tooltip>
      )}
    </span>
  );
}

export function SelectPoolField({
  onChange,
  selectItems = [],
  label,
  labelId,
  id,
  value,
  name,
  placeholder,
  inputStyle,
  isCustom,
  isMultiple = false,
  required = false,
}) {
  const classes = useStyle();
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth className={classes.select}>
        <InputLabel id={labelId} className={classes.inputLabel}>
          {label && label}
          {required ? " *" : null}
        </InputLabel>
        <Select
          labelId={labelId}
          id={id}
          value={value}
          label="test"
          onChange={onChange}
          name={name}
          className={classes.select}
          sx={inputStyle}
          multiple={isMultiple}
          displayEmpty={true}
          renderValue={(selected) => {
            if (selected === undefined) {
              return placeholder;
            } else {
              let filteredObj = selectItems.filter((item) =>
                isCustom ? item._id === selected : item
              );
              return isCustom
                ? filteredObj[0].candidate_pool_name
                : filteredObj[0];
            }
          }}
        >
          {selectItems.map((item, i) => (
            <MenuItem key={i} value={isCustom ? item._id : item}>
              {isCustom ? item.candidate_pool_name : item}
            </MenuItem>
          ))}
        </Select>
        {/* <span
          className={`absolute top-[50%] left-[20px] text-[14px] text-[#828282] ${
            label ? "" : "translate-y-[-50%]"
          }  ${value ? "hidden" : "block"} `}
        >
          {placeholder}
        </span> */}
      </FormControl>
    </Box>
  );
}
