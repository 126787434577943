import React from "react";
import {ReactComponent as ImgSlide1} from '../../../../../assets/PurposeStatement1.svg'

function Slide1() {
  return (
    <div className="flex items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          {/* <img src={Bussiness} alt="" className="w-full h-full" /> */}
          <ImgSlide1 />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          Personal purpose statement (PPS)
        </h5>
        <p className="text-base text-pirmaryBlue mb-6">
          As part of creating a personal business model, starting with a Personal Purpose Statement (PPS) that defines your desired achievements and reflects your core values, beliefs, and objectives is recommended.
        </p>

        <h5 className="text-base text-pirmaryBlue mb-3">Consider the following examples of PPS from famous individuals:</h5>
        <p className="text-base text-pirmaryBlue mb-3">
          "To be a teacher and to be known for inspiring my students to be more than they thought they could be." - Oprah Winfrey, founder of WON, the Oprah Winfrey Network.
        </p>
        <p className="text-base text-pirmaryBlue mb-3">
          "To act in a manner that brings out the best in me and those important to me, especially when it might be most justifiable to act otherwise." - Mahatma Gandhi.
        </p>
        <p className="text-base text-pirmaryBlue mb-3">
          "To have fun in my journey through life and learn from my mistakes." - Sir Richard Branson, founder of the Virgin Group.
        </p>
        <p className="text-base text-pirmaryBlue mb-3">
          What qualities, values, and beliefs do you associate with these individuals and their brands?
        </p>
        <p className="text-base text-pirmaryBlue mb-3">
          Which qualities, values, and beliefs do you share with them, and which do you want your brand to be associated with?
        </p>
        <p className="text-base text-pirmaryBlue mb-3">
          How can your personal purpose statement serve as a compass to guide your decisions and actions toward achieving your desired outcomes?
        </p>
      </div>
    </div>
  );
}

export default Slide1;
