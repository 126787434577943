import React, { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  TabPanel,
  tabsStyle,
  PasswordField,
  formValidate,
} from "./reusableComponents";
import { ReactComponent as TripaLogo } from "../../../assets/logo.svg";
import LoginWithGoogle from "../../sharedComponents/loginWithGoogle/LoginWithGoogle";
import LoginWithGithub from "../../sharedComponents/loginWithGithub/loginWithGithub";
import SuccessBtn from "../../sharedComponents/buttons/successBtn/SuccessBtn";
import Modal from "../../sharedComponents/modal/Modal";
import Header from "../../sharedComponents/header/header";
import Footer from "../../sharedComponents/footer/footer";
import Checkbox from "@mui/material/Checkbox";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../styles/swiper.css";
import { Pagination, Navigation } from "swiper";
import { signUpApiJC } from "../../../../api/jc";
import IdentifyYourValue from "../../../assets/identifyyourvalue.svg";
import CareerPathInsights from "../../../assets/careerpathinsights.svg";
import DashboardCoaching from "../../../assets/dashboard_coaching.svg";
import WorkForce from "../../../assets/workforce.svg";
import Dashboard from "../../../assets/dashboard.svg";
import PowerTeam from "../../../assets/powerteam.svg";
import privacy from "./privacy";
import terms from "./terms";

export default function RegisterCandidate() {
  const params = useParams();
  // const [value, setValue] = useState(0);
  const value = 0;
  const [coachingData, setCoachingData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
    agreeToReceiveEmail: false,
  });

  const [coachPassShown, setCoachPassShown] = useState(false);
  const [coachConfirmPassShown, setCoachConfirmPassShown] = useState(false);
  const [isCoachingModalOpen, setIsCoachingModalOpen] = useState(false);
  const [isBusinessModalOpen, setIsBusinessModalOpen] = useState(false);

  const handleCoachingData = (event) => {
    const name = event.target.name;

    if (event.target.type === "checkbox") {
      setCoachingData((prevData) => ({
        ...prevData,
        [name]: event.target.checked,
      }));
    } else {
      setCoachingData((prevData) => ({
        ...prevData,
        [name]: event.target.value,
      }));
    }
  };
  const coachingSignUpHandler = () => {
    let refferal =
      "refferal" in params
        ? params["refferal"]
        : "token" in params
        ? params["token"]
        : "new";
    const error = formValidate(coachingData);
    let temp = {
      email_address: coachingData.email,
      password: coachingData.password,
      roles: ["job_candidate"],
    };
    if (!error) {
      signUpApiJC(temp, refferal)
        .then((res) => {
          console.log(res);
          res.status === 200 && setIsCoachingModalOpen(true);
        })
        .catch((error) => console.error(error));
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const classes = tabsStyle();
  const termsAlert = () => {
    alert(terms);
  };

  const privacyAlert = () => {
    alert(privacy);
  };

  {/* Start - This comment for New Register stop  */}
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate('/coaching/login', {
      state: { isFromLandingPage: true },
    });
  });
  {/* End - for New Register stop  */}

  return (
    <div className="flex flex-col min-h-[100vh]">
      <Header />
      <div className="login-reg-container">
        <div className="h-full col-span-1 md:w-full bg-white login-reg-wrapper">
          <div className="">
            <div className="bg-[#f8f9fa] h-full swiper-parent">
              <div className="flex flex-col justify-center items-center">
                <h2 className="font-bold text-[#000000] text-center text-[23px] w-full max-w-[550px] leading-[31px] mb-[30px]">
                  Maximize your potential with invaluable insights that empower you to uncover the perfect job opportunity. Don't settle for professional paths that fail to fully harness your unique strengths and exceptional abilities.
                </h2>
                <img src={DashboardCoaching} alt="" width={'550px'} />
                {/* {value === 0 && (
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    // navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="flex flex-col items-center justify-center overflow-hidden">
                        <div className="mb-[30px] w-full max-w-[500px] px-4">
                          <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                            Dashboard
                          </h2>
                          <p className="text-[#828282] text-[14px] text-center mt-2">
                            Take the manual and guesswork from your candidate
                            screening process to identify the success predictors
                            of those who will help you create greater value for
                            your business.
                          </p>
                        </div>
                        <img src={DashboardCoaching} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="flex flex-col items-center justify-center overflow-hidden">
                        <div className="mb-[30px] w-full max-w-[500px] px-4">
                          <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                            Career path insights
                          </h2>
                          <p className="text-[#828282] text-[14px] text-center mt-2">
                            Take the manual and guesswork from your candidate
                            screening process to identify the success predictors
                            of those who will help you create greater value for
                            your business.
                          </p>
                        </div>
                        <img src={CareerPathInsights} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="flex flex-col items-center justify-center overflow-hidden">
                        <div className="mb-[30px] w-full max-w-[500px] px-4">
                          <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                            Identify your value
                          </h2>
                          <p className="text-[#828282] text-[14px] text-center mt-2">
                            Take the manual and guesswork from your candidate
                            screening process to identify the success predictors
                            of those who will help you create greater value for
                            your business.
                          </p>
                        </div>
                        <img src={IdentifyYourValue} alt="" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                )}
                {value === 1 && (
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    // navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="flex flex-col items-center justify-center overflow-hidden">
                        <div className="mb-[30px] w-full max-w-[500px] px-4">
                          <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                            Workforce
                          </h2>
                          <p className="text-[#828282] text-[14px] text-center mt-2">
                            Take the manual and guesswork from your candidate
                            screening process to identify the success predictors
                            of those who will help you create greater value for
                            your business.
                          </p>
                        </div>
                        <img src={WorkForce} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="flex flex-col items-center justify-center overflow-hidden">
                        <div className="mb-[30px] w-full max-w-[500px] px-4">
                          <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                            Dashboard
                          </h2>
                          <p className="text-[#828282] text-[14px] text-center mt-2">
                            Take the manual and guesswork from your candidate
                            screening process to identify the success predictors
                            of those who will help you create greater value for
                            your business.
                          </p>
                        </div>
                        <img src={Dashboard} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="flex flex-col items-center justify-center overflow-hidden">
                        <div className="mb-[30px] w-full max-w-[500px] px-4">
                          <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                            Power Team
                          </h2>
                          <p className="text-[#828282] text-[14px] text-center mt-2">
                            Take the manual and guesswork from your candidate
                            screening process to identify the success predictors
                            of those who will help you create greater value for
                            your business.
                          </p>
                        </div>
                        <img src={PowerTeam} alt="" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                )} */}
              </div>
            </div>
          </div>
          <div className="form-section-container">
            {/* start tab Coaching */}
            <p className="text-[28px] font-bold">
              Create your TRIPA Coaching account
              <span className="ml-2">&#9996;</span>
            </p>
            <div className="text-secondaryBalck text-[14px] mt-[10px] mb-7">
              Already have an account?
              <Link to="/coaching/login" className="text-success ml-1">
                Log in
              </Link>
            </div>
            <TabPanel>
              <div className="">
                {/* <div className="w-[100%] flex flex-col lg:flex-row">
                  <div className="lg:w-[50%] w-[100%] lg:my-8">
                    <LoginWithGoogle
                      responseGoogle={"job_candidate"}
                      responseGoogleFail={() => {}}
                      isSignup={true}
                    />
                  </div>

                  <div className="lg:w-[50%] w-[100%] lg:pl-4 lg:my-8 mb-8">
                    <div className="lg:w-[100%] w-[100%] ml-1">
                      {" "}
                      <LoginWithGithub
                        responseGithub={"job_candidate"}
                        responseGithubFail={() => {}}
                        isSignup={true}
                      />{" "}
                    </div>
                    <div className="mx-3"></div>
                  </div>
                </div>

                <div className="border-t-2 relative mb-[33px]">
                  <span className="bg-white w-[50px] text-center text-[#768798] absolute top-[-15px] left-[50%] translate-x-[-50%]">
                    OR
                  </span>
                </div> */}

                <div className="flex flex-col mb-4">
                  <label
                    htmlFor="Coaching-emailInput"
                    className="text-pirmaryBlue"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={coachingData.email || ""}
                    onChange={handleCoachingData}
                    id="Coaching-emailInput"
                    placeholder="Enter your email"
                    className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                  />
                </div>
                <div className="flex lg:flex-row flex-col items-center w-[100%]">
                  <div className="flex flex-col w-[100%] lg:pr-3">
                    <PasswordField
                      isPassword={coachPassShown}
                      setIsPassword={setCoachPassShown}
                      name={"password"}
                      value={coachingData.password || ""}
                      label={"Password"}
                      labelFor={"Coaching-password"}
                      id={"Coaching-password"}
                      onChange={handleCoachingData}
                      placeholder={"Enter your password"}
                      className={
                        "focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                      }
                    />
                  </div>
                  <div className="flex flex-col  w-[100%] lg:pl-3">
                    <PasswordField
                      isPassword={coachConfirmPassShown}
                      setIsPassword={setCoachConfirmPassShown}
                      name={"confirmPassword"}
                      value={coachingData.confirmPassword || ""}
                      label={"Confirm password"}
                      labelFor={"Coaching-confirmPassword"}
                      id={"Coaching-confirmPassword"}
                      onChange={handleCoachingData}
                      placeholder={"Enter your password"}
                      className={
                        "focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                      }
                    />
                  </div>
                </div>
                <div className="my-4">
                  <div className="flex flex-row items-center my-2">
                    <Checkbox
                      {...label}
                      id="Coaching-terms"
                      value={coachingData.terms || ""}
                      onChange={handleCoachingData}
                      className={classes.checkbox}
                      name="terms"
                    />
                    <label
                      htmlFor="Coaching-terms"
                      className="text-secondaryBalck ml-4 cursor-pointer"
                    >
                      By signing up, you agree to TRIPA's{" "}
                      <span className="text-success">
                        <a
                          target={"_blank"}
                          href="https://tripa.ai/general-terms-conditions/"
                        >
                          Terms of Use and Conditions
                        </a>
                      </span>{" "}
                      and
                      <span className="text-success">
                        {" "}
                        <a
                          target={"_blank"}
                          href="https://tripa.ai/privacy-policy/"
                        >
                          Privacy Policy
                        </a>
                      </span>
                      , which outline your rights and obligations concerning the
                      use of the services and processing of your data.
                    </label>
                  </div>

                  <div className="flex flex-row items-center my-2">
                    <Checkbox
                      {...label}
                      id="Coaching-agreeToReceiveEmail"
                      value={coachingData.agreeToReceiveEmail || ""}
                      onChange={handleCoachingData}
                      className={classes.checkbox}
                      name="agreeToReceiveEmail"
                    />
                    <label
                      htmlFor="Coaching-agreeToReceiveEmail"
                      className="text-secondaryBalck ml-4"
                    >
                      You agree to receive subsequent emails and third-party
                      communication, which you may opt out of or unsubscribe
                      from at any time.
                    </label>
                  </div>
                </div>

                {/* <SuccessBtn
                  onClick={coachingSignUpHandler}
                  customStyle={{ width: "100%", height: "55px" }}
                >
                  SIGN UP
                </SuccessBtn> */}
              </div>
            </TabPanel>
          </div>
          {/* end tab Coaching */}
        </div>

        <Modal
          title="Email verification"
          open={isCoachingModalOpen}
          setOpen={setIsCoachingModalOpen}
          mdUpWidth={550}
          style={{ maxWidth: "550px", width: "100%" }}
        >
          <div className="flex justify-center mb-5">
          <TripaLogo className="h-9 w-28"/>
          </div>
          <p className="text-base text-pirmaryBlue mb-[10px]">
            An email was sent to {coachingData.email} click on the link to
            confirm
          </p>
          <p className="text-[14px] text-[#828282] mb-[35px]">
            Don’t see the email from no-reply@tripa.ai check your spam or junk
            inbox.
          </p>
          <Link to="/coaching/login" className="flex justify-center">
            <SuccessBtn> Login</SuccessBtn>
          </Link>
        </Modal>
        <Modal
          title="Welcome"
          open={isBusinessModalOpen}
          setOpen={setIsBusinessModalOpen}
          mdUpWidth={550}
          style={{ maxWidth: "550px", width: "100%" }}
        >
          <div className="flex justify-center mb-5">
            <TripaLogo className="h-9 w-28"/>
          </div>
          <p className="text-base text-[#384259] mb-[30px] text-center">
            Welcome to TRIPA <br /> Before continuing, we need a bit more
            information.
          </p>
          <Link to="/complete-your-profile" className="flex justify-center">
            <SuccessBtn> Next</SuccessBtn>
          </Link>
        </Modal>
      </div>
      <Footer />
    </div>
  );
}
