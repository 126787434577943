import React, { useState } from 'react'
import InfoBoxes from '../../sharedComponents/profile/infoBox'
import WorkExperience from './shared/workExperience'
import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import Modal from '../../sharedComponents/modal/Modal';
import WorkExperienceModal from './modalCompnents/workExperience';
export default function WorkExperinces({ handleReload }) {
  const [addInfo, setAddInfo] = useState(false);
  const [selected, setSelected] = useState(null);
  const handleSelectItem=(item)=>{
    setAddInfo(true)
    setSelected(item)
  }
  // React.useEffect(() => {
  //   if(!addInfo) setSelected(null)
  // }, [addInfo]);
  return (
    <>
      <InfoBoxes title="Work experience" icon={<AddCircleOutlineTwoToneIcon />}
        handleClickAdd={() => {setSelected(null); setAddInfo(true); }}
        TooltipText="Add your work experience by entering the role title, company name, and the time you hold that position (MM-YYYY)." TooltipTitle="Work experience">
        <>
          <WorkExperience handleSelectItem={handleSelectItem}/>
          <Modal title={`${selected ? 'Edit' : 'Add'} work experience`} open={addInfo} setOpen={setAddInfo} mdUpWidth={550}>
            <WorkExperienceModal
              selected={selected}
              handleReload={
                () => {
                  handleReload();
                  setAddInfo(false);
                  setSelected(null)
                }
              }
            />
          </Modal>
        </>
      </InfoBoxes>
    </>
  )
}
