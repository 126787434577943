import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function DynamicBoxDescription({
  data,
  staticData,
  title,
  style,
}) {
  return (
    <div
      style={style}
      className="rounded-2xl bg-white py-6 max-h-[500px] overflow-hidden relative border border-grayLight"
    >
      <p className=" text-pirmaryBlue pl-6 font-bold text-2xl mb-5 sticky top-0 bg-white lowercase first-letter:uppercase">
        {data?.section_fullname || title}
      </p>
      <div className="max-h-full px-6 pb-12 overflow-auto ">
        {staticData && (
          <div className="text-pirmaryBlue text-base mb-6">{staticData || ''}</div>
        )}
        <div className=''>
          {data?.subsection?.length &&
            data.subsection[0]?.statements?.length &&
            data.subsection[0].statements.map((item, index) => (
              <div
                className='text-base flex items-start justify-start  mb-3'
                key={index + 1}
              >
                <div className='flex items-center'>
                  <CheckCircleIcon
                    sx={{
                      '&.MuiSvgIcon-root': { fill: '#7AB794 !important' },
                    }}
                  />
                </div>
                <span className='text-base text-pirmaryBlue font-normal ml-2'>
                  {item}
                </span>
              </div>
            ))}
        </div>
        <div className="bg-gradient-to-b from-[rgba(255,255,255,0)] via-[rgba(255,255,255,0.74)] to-white left-0 h-10 absolute bottom-0 w-full"></div>
      </div>
    </div>
  );
}
