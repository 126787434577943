import { Tab, Tabs } from '@mui/material';
import React from 'react';
import WhiteBox from '../../../../../sharedComponents/whiteBox/WhiteBox';
import AccountDetails from './AccountDetails';
import BillingDetailsAndMialingAddress from './BillingDetailsAndMialingAddress';
import CreditCardDetail from './CreditCardDetail';
import { a11yProps, TabPanel, tabsStyle } from './reuseabelComponent';

function SubscriptionBilling() {
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState({
    accountName: '',
    accountEmail: '',
    nameOfTheAccountAdmin: '',
    nameOfTheCompany: '',
    companyAddress: '',
    companyPhone: '',
    password: '',
    confirmPassword: '',
    companySize: '',
    billingFullName: '',
    billingAddress: '',
    billingCity: '',
    billingZipCode: '',
    billingCountry: '',
    biilingSameAsMailing: true,
    mailingFullName: '',
    mailingAddress: '',
    mailingCity: '',
    mailingZipCode: '',
    mailingCountry: '',
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cVV: '',
    saveCard: false,
  });

  const handleChangeData = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNext = () => {
    if (value < 2) {
      setValue((prev) => prev + 1);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = tabsStyle();

  return (
    <>
      <WhiteBox>
        <Tabs
          className={classes.root}
          value={value}
          onChange={handleChange}
          variant='scrollable'
          allowScrollButtonsMobile
          aria-label='basic tabs example'
          sx={{
            '& .MuiTabs-scroller': {
              borderRadius: '4px',
              color: 'white',
            },
            '&.MuiTabs-root': {
              '&.MuiTabs-scroller': {
                '&.MuiTabs-indicator': {
                  backgroundColor: 'red !important',
                },
              },
            },
          }}
        >
          <Tab
            label='Account details'
            {...a11yProps(0)}
            className={classes.tab}
          />
          <Tab
            label='Billing details'
            {...a11yProps(1)}
            className={classes.tab}
          />
          <Tab label='Card details' {...a11yProps(2)} className={classes.tab} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <AccountDetails
            data={data}
            handleChangeData={handleChangeData}
            handleNext={handleNext}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <BillingDetailsAndMialingAddress
            data={data}
            handleChangeData={handleChangeData}
            handleNext={handleNext}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CreditCardDetail data={data} handleChangeData={handleChangeData} />
        </TabPanel>
      </WhiteBox>
      {/* {page === 0 && (
        <AccountDetails
          data={data}
          handleChangeData={handleChangeData}
          handleNext={handleNext}
        />
      )}
      {page === 1 && (
        <BillingDetailsAndMialingAddress
          data={data}
          handleChangeData={handleChangeData}
          handleNext={handleNext}
        />
      )}
      {page === 2 && (
        <CreditCardDetail data={data} handleChangeData={handleChangeData} />
      )} */}
    </>
  );
}

export default SubscriptionBilling;
