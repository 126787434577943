import React from "react";

import { ReactComponent as TwoArrows } from "../../../../../../assets/twoArrows.svg";
import LowComfortModal from "../modals/LowComfort";
import { mapBehaviourNameColor } from "../../../../../../../utils/getColors";

import avatar from "../../../../../../assets/Male.svg";

function TeamCard({ compatibility }) {
  const [lowComfort, setLowComfort] = React.useState(false);
  const getColors = () => {
    switch (compatibility.preferred_behavior_compatibility) {
      case "High comfort":
        return {
          bg: "#EBD5BB",
          border: "#C7883E",
        };

      case "Moderate comfort":
        return {
          bg: "#EFE5B9",
          border: "#D2B436",
        };

      case "Low comfort":
        return {
          bg: "#D6C7CF",
          border: "#895F75",
        };

      default:
        return {
          bg: "#f3f6f9",
          border: "#e3e6e9",
        };
    }
  };
  let colors = getColors();
  return (
    <>
      <div
        className={`border-2 min-w-[250px] max-w-[400px] rounded-2xl mb-3`}
        style={{ borderColor: colors.border }}
      >
        <div
          className={`w-full h-[36px] py-2 px-6 rounded-t-2xl flex items-center justify-between`}
          style={{ backgroundColor: colors.bg }}
        >
          <h2 className="text-base text-pirmaryBlue font-bold ">
            {compatibility.preferred_behavior_compatibility}
          </h2>
          <span className="cursor-pointer" onClick={() => setLowComfort(true)}>
            <TwoArrows />
          </span>
        </div>

        <div className=" flex flex-wrap items-center justify-around py-3 px-2">
          {/* <TeamTooltip
            alt={[compatibility.a.first_name, compatibility.a.last_name].join(
              " "
            )}
            name={[compatibility.a.first_name, compatibility.a.last_name].join(
              " "
            )}
            subName="Supportive and Analytical"
            src={compatibility.a.candidate_picture_upload_path || avatar}
            description="Data, fact, and analysis based. Precise and accurate trusts in the value of the structure, standards, and order. Sees the importance C of "
          > */}
            <div className="flex flex-col items-center justify-center mt-2">
              <div
                className="p-[5px] w-[65px] h-[65px] rounded-full border-2 border-[#C7CB52] border-solid"
                style={{ overflow: "hidden", 'border-color': mapBehaviourNameColor?.[compatibility.a.preferredBehavior]?.color }}
              >
                <img
                  src={compatibility.a.candidate_picture_upload_path || avatar}
                  alt={[
                    compatibility.a.first_name,
                    compatibility.a.last_name,
                  ].join(" ")}
                  className="w-full h-full rounded-full"
                />
              </div>

              <span className="h-[25px] text-base text-pirmaryBlue font-bold">
                {[compatibility.a.first_name, compatibility.a.last_name].join(
                  " "
                )}
              </span>
              <span className="h-[20px] text-sm text-pirmaryBlue">
                {compatibility.a.preferredBehavior}
              </span>
            </div>
          {/* </TeamTooltip> */}
          {/* <TeamTooltip
            alt={[compatibility.b.first_name, compatibility.b.last_name].join(
              " "
            )}
            name={[compatibility.b.first_name, compatibility.b.last_name].join(
              " "
            )}
            subName="Supportive and Analytical"
            src={compatibility.b.candidate_picture_upload_path || avatar}
            description="Data, fact, and analysis based. Precise and accurate trusts in the value of the structure, standards, and order. Sees the importance C of "
          > */}
            <div className="flex flex-col items-center justify-center mt-2">
              <div
                className="p-[5px] w-[65px] h-[65px] rounded-full border-2 border-[#C7CB52] border-solid"
                style={{ overflow: "hidden", 'border-color': mapBehaviourNameColor?.[compatibility.b.preferredBehavior]?.color}}
              >
                <img
                  src={compatibility.b.candidate_picture_upload_path || avatar}
                  alt={[
                    compatibility.b.first_name,
                    compatibility.b.last_name,
                  ].join(" ")}
                  className="w-full h-full rounded-full"
                />
              </div>

              <span className="h-[25px] text-base text-pirmaryBlue font-bold">
                {[compatibility.b.first_name, compatibility.b.last_name].join(
                  " "
                )}
              </span>
              <span className="h-[20px] text-sm text-pirmaryBlue">
                {compatibility.b.preferredBehavior}
              </span>
            </div>
          {/* </TeamTooltip> */}
        </div>
      </div>

      <LowComfortModal
        open={lowComfort}
        setOpen={setLowComfort}
        compatibility={compatibility}
        colors={colors}
      />
    </>
  );
}

export default TeamCard;
