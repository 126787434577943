import React from "react";

function Stpe1() {
  return (
    <>
      <p className="text-base text[#384259] mb-6">
        TRIPA's Team Chemistry tool uses the DISC human assessment framework that
        will help you identify the unconscious psychological forces that
        influence your team's behavior and performance.
      </p>
      <p className="text-base text[#384259] mb-6">
        TRIPA's Team Chemistry tool uses the DISC human assessment framework that
        will help you identify the unconscious psychological forces that
        influence your team's behavior and performance.
      </p>
      <p className="text-base text[#384259] mb-6">
        TRIPA's Team Chemistry tool uses the DISC human assessment framework that
        will help you identify the unconscious psychological forces that
        influence your team's behavior and performance.
      </p>
      <p className="text-base text[#384259] mb-6">
        TRIPA's Team Chemistry tool uses the DISC human assessment framework that
        will help you identify the unconscious psychological forces that
        influence your team's behavior and performance.
      </p>
      <p className="text-base text[#384259] mb-6">
        TRIPA's Team Chemistry tool uses the DISC human assessment framework that
        will help you identify the unconscious psychological forces that
        influence your team's behavior and performance.
      </p>
      <p className="text-base text[#384259] mb-6">
        TRIPA's Team Chemistry tool uses the DISC human assessment framework that
        will help you identify the unconscious psychological forces that
        influence your team's behavior and performance.
      </p>
    </>
  );
}

export default Stpe1;
