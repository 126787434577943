import React from "react";
import { toast } from "react-toastify";
import { getHRProfile, postHRProfile } from "../../../../../../../api/hr";

import BasicDetails1 from "./BasicDetails1";
import BasicDetails2 from "./BasicDetails2";
import ChangePassword from "./ChangePassword";

function AccountSettings() {
  const [image, setImage] = React.useState();
  const [data, setData] = React.useState({
    image: "",
    companyName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    country: "",
    city: "",
    companyIndustry: "",
    companyFunction: "",
    employees: "",
    companyTaxID: "",
    companyURL: "",
    companyJobURL: "",
  });

  const formValidate = (values) => {
    const myError = 0;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      toast.error("Email is required!");
      return 1;
    } else if (!regex.test(values.email)) {
      toast.error("This is not a valid email format!");
      return 1;
    }
    if (!values.companyName) {
      toast.error("Company name is required!");
      return 1;
    }
    if (!values.countryCode) {
      toast.error("Country code is required!");
      return 1;
    }
    if (!values.country) {
      toast.error("Country is required");
      return 1;
    }
    if (!values.city) {
      toast.error("City is required");
      return 1;
    }
    if (!values.employees) {
      toast.error("Employees is required");
      return 1;
    }
    return myError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = formValidate(data);
    if (!error) {
      try {
        let body = {
          company_name: data.companyName,
          country: data.country,
          city: data.city,
          country_code: data.countryCode,
          company_tax_id: data.companyTaxID,
          industry: data.companyIndustry,
          company_contact_number: data.phoneNumber,
          number_of_employee: data.employees,
          company_function: data.companyFunction,
          company_url: data.companyURL,
          company_job_url: data.companyJobURL,
          image: data.image,
        };
        const res = await postHRProfile(body);
        toast.success("Uploaded");
        console.log(res.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getProfileData = async () => {
    try {
      const res = await getHRProfile();
      console.log(res.data);
      if (res?.data) {
        let data = res.data;
        if (data.company_logo_upload_path)
          setImage(data.company_logo_upload_path);
        setData((prev) => ({
          ...prev,
          companyName: data.company_name,
          email: data.company_email,
          country: data.country,
          city: data.city,
          countryCode: data.country_code,
          companyTaxID: data.company_tax_id,
          companyIndustry: data.industry,
          phoneNumber: data.company_contact_number,
          employees: data.number_of_employee,
          companyFunction: data.company_function,
          companyURL: data.company_url,
          companyJobURL: data.company_job_url,
          image: data.company_logo_upload_path,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getProfileData();
  }, []);

  return (
    <div className="mt-[40px] mb-[24px]">
      <div className="grid grid-cols-7  gap-4">
        <div className="lg:col-start-1 lg:col-end-5 col-start-1 col-end-8 ">
          <BasicDetails1
            data={data}
            setData={setData}
            handleSubmit={handleSubmit}
            image={image}
            setImage={setImage}
            getProfileData={getProfileData}
          />
        </div>
        <div className="lg:col-start-5 lg:col-end-8 col-start-1 col-end-8">
          <ChangePassword />
        </div>
      </div>
      <div className="">
        <BasicDetails2
          data={data}
          setData={setData}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

export default AccountSettings;
