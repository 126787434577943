import React, { useState } from "react";
import InfoBoxes from "../../sharedComponents/profile/infoBox";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import { makeStyles } from "@mui/styles";
import LinearProgress from "@mui/material/LinearProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Modal from "../../sharedComponents/modal/Modal";
import FunctionArea from "./modalCompnents/functionArea";
import UpdateSalary from "./modalCompnents/updateSalary";
import AddSkills from "./modalCompnents/addSkills";
import Languages from "./modalCompnents/languages";
import Tooltip from "@mui/material/Tooltip";
import Awards from "./modalCompnents/awards";
import ReactApexChart from "react-apexcharts";
import { charMapping } from "../behavioralTraits/semiCircleChart";
import { allLanguages } from "../../../contants/languages";
import { NumericFormat } from 'react-number-format';
import { useSelector } from "react-redux";
import roleBasedBlured from '../../../assets/Profile-Role-based-blured.png';
import prefferedImageBlured from '../../../assets/preffered-blured.png';
import PremiumButton from '../premiumButton';
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
      },
    },
    low: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#828282 !important",
      },
    },
    mid: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#8B5086 !important",
      },
    },
    high: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#7AB794 !important",
      },
    },
  };
});


const mapDriverOfEngagmentColors = {
  Aesthetic: "#A9D18E",
  Economic: "#4472C4",
  Individualistic: "#ED7D33",
  Political: "#C2342C",
  Altruistic: "#E5C311",
  Regulatory: "#7F7F7F",
  Theoretical: "#7F6000",
}

const RightPart = ({ data, handleReload, graphData, roleBasedCompetanceAssessment, percent, result = [], isPremium = false, isLoading }) => {
  const progressstyles = useStyles();
  const [addFunction, setAddFunction] = useState(false);
  const [updateSalary, setUpdateSalary] = useState(false);
  const [addSkilss, setAddSkilss] = useState(false);
  const [languages, setLanguages] = useState(false);
  const [awards, setAwards] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedAward, setSelectedAward] = useState(-1);
  const roleBaseTooltip = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'RoleBasedCompetanceAssessment'
    )?.assessment?.assessment_content?.aiRoleCompetencies?.map(({ headingTitle }) => headingTitle.replace('?', '<br /><br />')) || []
  );
  const driverData = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.score || []
  );
  const behaviorData = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'BehaviorAssessment'
    )?.assessment?.assessment_content?.score || []
  );
  const optionschart = {
    options: {
      noData: {
        text: 'Loading...'
      },
      tooltip: {
        enabled: true,
        fixed: true,
        width: 200,
        custom: function ({ series, seriesIndex, w }) {
          return `
          <div class="min-w-[300px] xl:min-w-[350px] max-w-[400px]">
            <div class="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
              ${w.config.labels[seriesIndex]} : ${(Number(series[seriesIndex])).toFixed(1)}%
            </div>
            <div className='rounded-md tooltip-font-desc' style='padding:0.75rem;background-color:#4D5871;'>
              <div>${w.config.chart.tooltip[seriesIndex]}</div>
            </div>
          </div>
          `;
        },
      },
      series: { data: [6, 8, 9, 10, 12, 14] },
      colors: [
        "#9B7210",
        "#CA902C",
        "#AA554C",
        "#4FD1C5",
        "#7ABEEF",
        "#F6AD55",
        "#8B5086",
        "#7AB794",
      ],
      chart: {
        width: 380,
        type: "pie",
        tooltip: roleBaseTooltip,
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: [
            "#9B7210",
            "#CA902C",
            "#AA554C",
            "#4FD1C5",
            "#7ABEEF",
            "#F6AD55",
            "#8B5086",
            "#7AB794",
          ],
        },
      },

      legend: {
        show: false,
      },
      labels: roleBasedCompetanceAssessment?.length
        ? roleBasedCompetanceAssessment.map(({ type }) => type)
        : [],
    },
  };


  const max = Math.max(...result?.map((x) => Number(x?.value || 0)));
  const maxValue = result?.filter((x) => Number(x.value) === max);
  const extraValues = data?.driver_of_engagement?.split(',');
  return (
    <>
      <InfoBoxes
        title="Role-based competencies"
        TooltipText="The role-based competencies display the list of capacities resulting from your decision-making style’s unique combination (personal, practical, and analytical). It helps you understand your strengths and weaknesses that enable you to make the necessary changes to achieve your desired level of success."
        TooltipTitle="Role-based competencies"
      >
        {isLoading ? (
          <div className="flex flex-col items-center w-full">
            <Skeleton variant="circular" width={300} height={300} />
            <Skeleton className="w-full mt-4" height={30} />
            <Skeleton className="w-full mt-4" height={30} />
            <Skeleton className="w-full mt-4" height={30} />
          </div>
        ) : !isPremium ? (
          <PremiumButton>
            <img src={roleBasedBlured} alt='Role based competencies' />
          </PremiumButton>
        ) : (
          <>
            <p className='text-base text-[18px] text-center font-bold text-pirmaryBlue mb-1'>
              {data?.assessment_type}
            </p>
            <div id='chart' className='relative flex justify-center'>
              <span className='absolute self-center font-bold text-lg'>
                {percent && (percent / graphData?.length)?.toFixed(1) + ' %'}
              </span>
              <ReactApexChart
                height={'auto'}
                width={'100%'}
                options={optionschart.options}
                series={graphData}
                type='donut'
              />
            </div>
            {roleBasedCompetanceAssessment?.map((item, index) => (
              <div
                className={`flex flex-wrap text-pirmaryBlue justify-between items-center py-2 border-b`}
              >
                <span className='flex flex-grow justify-start items-center'>
                  <div className=''>
                    <span
                      style={{
                        backgroundColor: `${optionschart.options.dataLabels.style.colors[index]}`,
                      }}
                      className={`h-3 mr-2 inline-block w-3 rounded-full`}
                    ></span>
                  </div>
                  <span className='text-base leading-5 max-w-fit inline-block'>
                    {item?.type}
                  </span>
                </span>
                <span className='font-bold text-pirmaryBlue'>
                  {Number(item?.value).toFixed(1) + ' %'}
                </span>
              </div>
            ))}
          </>
        )}
      </InfoBoxes>

      <InfoBoxes
        title="Assessment insights summary"
        TooltipText="Your Insights summary provides a quick view of your Unique Selling Proposition (USP). In addition, it gives you a glance at your behavior style (your preference) and priorities (your top three (3)) values that can aid in determining a job opportunity that maximizes your peak performance."
        TooltipTitle="Assessment insights summary"
      >
        {isLoading ? (
          <div className="flex flex-col items-center w-full">
            <Skeleton className="w-full mt-4" height={30} />
            <Skeleton className="w-full mt-4" height={30} />
            <Skeleton className="w-full mt-4" height={30} />
          </div>
        ) : isPremium ? (
          <div className='flex flex-col divide-y'>
            <div className='flex flex-wrap justify-between py-3'>
              <span className='text-base text-pirmaryBlue'>
                Preferred behavior:{' '}
              </span>

              <div className=''>
                {maxValue?.map((preBehavior, index) => {
                  let behaviorTooltip = behaviorData?.find(({ type }) => type === preBehavior?.type)
                  return (
                    <>
                      <Tooltip
                        placement='top'
                        title={
                          <div className='rounded-md'>
                            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
                              {`${charMapping?.[preBehavior?.type]?.name} : ${behaviorTooltip?.value[0]?.value || 0
                                }%`}
                            </div>
                            <div
                              className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'
                              dangerouslySetInnerHTML={{
                                __html:
                                  behaviorTooltip?.statements.join('<br /><br />- ') ||
                                  '',
                              }}
                            />
                          </div>
                        }
                        arrow
                      >
                        <span
                          className='text-base font-bold cursor-pointer'
                          style={{ color: charMapping?.[preBehavior?.type]?.color }}
                        >
                          {charMapping?.[preBehavior?.type]?.name}
                        </span>
                      </Tooltip>
                      {index < maxValue.length - 1 && <>, &nbsp;</>}
                    </>
                  );
                })}
              </div>
            </div>
            <div className='flex flex-wrap justify-between py-3'>
              <span className='text-base text-pirmaryBlue'>
                Values:{' '}
              </span>
              <div className=''>
                {extraValues?.map((extra, index) => {
                  let data = driverData?.find(
                    ({ section_fullname }) => extra === section_fullname
                  );
                  return (
                    <>
                      <Tooltip
                        key={index}
                        placement='top'
                        title={
                          <div className='rounded-md'>
                            <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
                              {`${extra} : ${data?.value || 0}%`}
                            </div>
                            <div className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'>
                              {data?.statement[0] || ''}
                            </div>
                          </div>
                        }
                        arrow
                      >
                        <span
                          className='text-base font-bold cursor-pointer'
                          style={{ color: mapDriverOfEngagmentColors?.[extra] }}
                        >
                          {extra}
                        </span>
                      </Tooltip>
                      {index < extraValues.length - 1 && <>, &nbsp;</>}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <PremiumButton>
            <img src={prefferedImageBlured} alt='preferred behavior' />
          </PremiumButton>
        )}
      </InfoBoxes>
      <InfoBoxes
        handleClickAdd={() => {
          setSelected('')
          setAddFunction(true);
        }}
        title="Functional area of preference"
        TooltipText="Selects your preferred functional area, such as sales, marketing, finance, management, operations, accounting, information technology, and human resources, that aligns with your interest and skills. Your choice should be based on your strengths and passion, leading to greater job satisfaction and success."
        TooltipTitle="Functional area of preference"
        icon={<AddCircleOutlineTwoToneIcon />}
      >
        <div className="flex flex-col divide-y">
          {data?.function_area_preferences?.map((item, index) => (
            <div className="flex justify-between py-3" key={item}>
              <span className="text-base text-pirmaryBlue">{item}</span>
              <span className="text-base font-bold text-[#8B5086]">
                <EditOutlinedIcon
                  className="text-secondaryBalck cursor-pointer"
                  onClick={() => {
                    setAddFunction(true);
                    setSelected(item);
                  }}
                />
              </span>
            </div>
          ))}
        </div>
      </InfoBoxes>
      <InfoBoxes
        title="Salary expectation"
        TooltipText="When entering your salary expectation, separate the three spaces that indicate thousands; for instance, 1000 should be 1 000, 20,000 should be 20 000, and 150000 should be 150 000."
        TooltipTitle="Salary expectation"
      >
        <div className="flex flex-col divide-y">
          {data && (
            <div className="flex justify-between py-3">
              <span className="text-base text-pirmaryBlue font-bold">
                {data?.salary_currency || ""}{" "}
                <NumericFormat
                  value={data?.salary_expectation_min || ""}
                  thousandSeparator={' '}
                  thousandsGroupStyle='thousand'
                  displayType="text"
                />{" - "}
                <NumericFormat
                  value={data?.salary_expectation_max || ""}
                  thousandSeparator={' '}
                  thousandsGroupStyle='thousand'
                  displayType="text"
                />
              </span>
              <span className="text-base font-bold text-[#8B5086]">
                <EditOutlinedIcon
                  onClick={() => {
                    setUpdateSalary(true);
                  }}
                  className="text-secondaryBalck cursor-pointer"
                />
              </span>
            </div>
          )}
        </div>
      </InfoBoxes>
      <InfoBoxes
        title="Skills"
        handleClickAdd={() => {
          setAddSkilss(true);
        }}
        icon={<AddCircleOutlineTwoToneIcon />}
        TooltipText="Enter your specific technical knowledge or abilities gained through your life experience, formal or informal training, and required for the job you desire. For example, software development language, web design, project management, legal, accounting, and mathematics are some of the skills you could document."
        TooltipTitle="Skills"
      >
        <div className="flex flex-col ">
          {
            data?.skills?.map((val, key) =>
              <div className="flex flex-col mt-2">
                <div className="flex justify-between py-3">
                  <span className="text-base text-pirmaryBlue">{val.name}</span>
                  <span className="text-base font-bold text-pirmaryBlue">{val.level * 20}%</span>
                </div>
                <div className="-mt-2">
                  <LinearProgress
                    className={
                      val.level <= 2 ? progressstyles.low
                        : val.level <= 3 ? progressstyles.mid
                          : val.level <= 5 ? progressstyles.high : ""
                    }
                    variant="determinate"
                    value={val.level * 20}
                  />
                </div>
              </div>
            )
          }
        </div>
      </InfoBoxes>
      <InfoBoxes
        title="Languages"
        icon={<AddCircleOutlineTwoToneIcon />}
        handleClickAdd={() => {
          setLanguages(true);
        }}
        TooltipText="Enter or select the languages you speak and the level of proficiency.
        For native or native-like, you can indicate 100%, for intermediary 60 - 70%, and for beginners, 10 - 50%."
        TooltipTitle="Languages"
      >
        <div className="flex flex-col ">
          {

            data?.language_skill?.map((val, key) =>
              <div className="flex flex-col mt-2">
                <div className="flex justify-between py-3">
                  <span className="text-base text-pirmaryBlue">{allLanguages.find(({ name }) => name === val.name)?.name || ''}</span>
                  <span className="text-base font-bold text-pirmaryBlue">{val.level * 20}%</span>
                </div>
                <div className="-mt-2">
                  <LinearProgress
                    className={
                      val.level <= 2 ? progressstyles.low
                        : val.level <= 3 ? progressstyles.mid
                          : val.level <= 5 ? progressstyles.high : ""
                    }
                    variant="determinate"
                    value={val.level * 20}
                  />
                </div>
              </div>
            )
          }
        </div>
      </InfoBoxes>
      <InfoBoxes
        title="Awards"
        icon={<AddCircleOutlineTwoToneIcon />}
        handleClickAdd={() => {
          setAwards(true);
          setSelectedAward(-1)
        }}
        TooltipText="Please list any relevant awards you have received that you would like a potential employer to know about. This information can help increase your profile and demonstrate your achievements."
        TooltipTitle="Awards"
      >
        <div className="flex flex-col divide-y">
          {data &&
            data?.awards?.map((item, index) => (
              <div className="flex justify-between py-3" key={item + index}>
                <span className="text-base text-pirmaryBlue">{item}</span>
                <span className="text-base font-bold text-[#8B5086]">
                  <EditOutlinedIcon
                    className="text-secondaryBalck cursor-pointer"
                    onClick={() => {
                      setAwards(true);
                      setSelectedAward(index);
                    }}
                  />
                </span>
              </div>
            ))}
        </div>
      </InfoBoxes>
      {addFunction && (
        <Modal
          title={`${selected ? 'Edit' : 'Add'} functional area of preferences`}
          open={addFunction}
          setOpen={setAddFunction}
          mdUpWidth={550}
          onClose={() => { }}
        >
          <FunctionArea
            selected2={selected}
            handleReload={e => {
              setAddFunction(false)
              handleReload()
              setSelected('')
            }}
            all={data?.function_area_preferences}
            setSelected2={setSelected}
          />
        </Modal>
      )}
      {updateSalary && (
        <Modal
          title="Update salary expectation"
          open={updateSalary}
          setOpen={setUpdateSalary}
          mdUpWidth={550}
          onClose={() => {
            setSelected("");
          }}
        >
          <UpdateSalary
            data={data}
            handleReload={() => {
              handleReload();
              setSelected("");
              setUpdateSalary(false);
            }}
          />
        </Modal>
      )}
      {addSkilss && (
        <Modal
          title="Add skills"
          open={addSkilss}
          setOpen={setAddSkilss}
          mdUpWidth={550}
        >
          <AddSkills
            data={data}
            handleReload={e => {
              setAddSkilss(false)
              handleReload()
            }}
            getData={handleReload}
          />
        </Modal>
      )}
      {languages && (
        <Modal
          title="Add language"
          open={languages}
          setOpen={setLanguages}
          mdUpWidth={550}
        >
          <Languages
            data={data}
            handleReload={e => {
              setLanguages(false)
              handleReload()
            }}
            getData={handleReload}
          />
        </Modal>
      )}
      {awards && (
        <Modal
          title={`${selectedAward > -1 ? 'Edit' : 'Add'} award`}
          open={awards}
          setOpen={setAwards}
          mdUpWidth={550}
        >
          <Awards
            handleReload={e => {
              setAwards(false)
              handleReload()
            }}
            all={data && data.awards}
            selected={selectedAward}
            setSelected={setSelected}
          />
        </Modal>
      )}
    </>
  );
};

export default RightPart;
