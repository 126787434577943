import React from "react";

function Slide5() {
  return (
    <>
      <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
        The six dimensions of thoughts
      </h5>
      <p className="text-base text-pirmaryBlue  mb-4">
       <span className="font-bold text-[#7AB794]">Internal Dimension</span> (How you perceive yourself)
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        <span className="font-bold ">Self-esteem</span> or self-acceptance: This is your ability to subjectively understand, evaluate, realize, and appreciate your self-worth. Self-esteem is based on internal values that allow you to enjoy yourself not for your success, what you do, or your role but rather for how you judge yourself based on what you know about yourself.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        This dimension speaks to the clarity of understanding and the importance of how your choice will impact your value. 
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        <span className="font-bold">Role Awareness</span> (Current Impact role): Understanding one's role in the world or within a given environment. Role awareness is understanding the expectations placed on a position and seeing how those expectations are achieved. 
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        Role awareness and self-direction can be negatively impacted when seeking or starting a new job or career. If this is the case, a lower score in these two dimensions can be overlooked in the short term.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        <span className="font-bold ">Self-Direction</span> (future well-being): Measures individuals' ability to self-motivate and take charge of their personal and professional development. It also encompasses their vision for growth and their steps to achieve it. A low score in this dimension may indicate that an individual is in transition or uncertain about their path to self-improvement.
      </p>
    </>
  );
}

export default Slide5;
