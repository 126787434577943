import React from "react";
import Tooltip from "@mui/material/Tooltip";

function TextTooltip({ children, tooltipTitle, tooltipText, CustomClass }) {
  return (
    <Tooltip
      placement="top"
      title={
        <div className="rounded-md">
          <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
            {tooltipTitle}
          </div>
          <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
            {tooltipText}
          </div>
        </div>
      }
      arrow
    >
      <span className={CustomClass}>{children}</span>
    </Tooltip>
  );
}

export default TextTooltip;
