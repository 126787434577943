import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import { ReactComponent as ArrowLeft } from "../../../assets/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../../assets/arrowRight.svg";

export const useStyle = makeStyles({
  table: {
    "& .MuiTableRow-head": {
      "& .MuiTableCell-head": {
        background: "#F4F6F8",
        fontWeight: "700",
        color: "#384259",
        fontSize: "16px",
      },
    },
  },
  pagination: {
    margin: "24px 0px 30px 0px",

    "& .MuiSvgIcon-fontSizeMedium": {
      zIndex: 200,
      background: "red",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      opacity: 0,
    },
    "& .MuiPaginationItem-page": {
      border: " 1px solid #E5E5E5",
      borderRadius: "0px",
    },
    "& .Mui-selected": {
      background: "#00000000 !important",
      borderRadius: "0px",
      border: "1px solid #7AB794",
      ":hover": {
        // backgroundColor: "transparent",
      },
    },
  },
});

function TripaTable({
  columns,
  rows,
  CreateActionBtn,
  colActionName,
  handleDelete,
  handleEdit,
}) {
  const [page, setPage] = React.useState(1);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rowsPerPage = 10;
  const handleChange = (event, value) => {
    setPage(value);
  };

  const classes = useStyle();

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 750 }} className={classes.table}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      let value;

                      if (column.id === colActionName) {
                        value = (
                          <CreateActionBtn
                            editOnClick={() => {
                              handleEdit && handleEdit(row);
                            }}
                            deleteOnClick={() => {
                              handleDelete && handleDelete(row);
                            }}
                          />
                        );
                      } else {
                        value = row[column.id];
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="flex items-center justify-end">
        <div className="relative mr-5">
          <Pagination
            count={rows.length < 10 ? 1 : Math.ceil(rows.length / rowsPerPage)}
            page={page}
            onChange={handleChange}
            rowsPerPage={rowsPerPage}
            className={classes.pagination}
          />
          <ArrowLeft
            style={{
              position: "absolute",
              left: 0,
              top: "48%",
              transform: "translateY(-50%)",
              zIndex: 100,
              opacity: page - 1 === 0 ? 0.5 : 1,
            }}
          />
          <ArrowRight
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 100,
              opacity: page === Math.ceil(rows.length / rowsPerPage) ? 0.5 : 1,
            }}
          />
        </div>
      </div>
    </Paper>
  );
}

export default TripaTable;
