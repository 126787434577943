import React, { useState } from "react";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import { ReactComponent as Vectortick } from "../../../../assets/vectortick.svg";
import { useFormik } from "formik";
import { addReferralJC } from "../../../../../api/jc";
import { toast } from "react-toastify";

export default function RefferalModal({ handleReload }) {
  const [initialValues, setInitialValues] = useState({
    email: "",
    name: "",
    message: "",
  });
  const handleSubmit = (values) => {
    // let times = new Date(...formik.values.issue_date.split("-"))
    // console.log(times.getTime())
    console.log(formik.values);
    addReferralJC(formik.values).then((res) => {
      console.log(res.data);
      toast.success("Successfully done.");
      handleReload();
    });
  };
  const validate = (values) => {
    let errors = {};
    if (!formik.values.name) {
      errors.name = <span className="text-red-400"></span>;
    }
    if (!formik.values.email) {
      errors.email = <span className="text-red-400"></span>;
    }
    if (!formik.values.message) {
      errors.message = <span className="text-red-400"></span>;
    }
    return errors;
  };
  const validtaeEmail = (values) => {
    const myError = 0;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      toast.error("Email is required!");
      return 1;
    } else if (!regex.test(values.email)) {
      toast.error("This is not a valid email format!");
      return 1;
    }
  };
  const formik = useFormik({
    initialValues,
    handleSubmit,
    validate,
    enableReinitialize: true,
    onSubmit: (values) => {
      let error = validtaeEmail(values);
      if (!error) {
        handleSubmit(values);
      }
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <p className="text-[#384259] text-base mb-5">
          To qualify for the referral program, the five people you refer must sign up for a premium subscription and remain subscribed for at least three months. The discount will be applied to your next subscription renewal after the conditions have been met. TRIPA reserves the right to modify or terminate this program without notice.
        </p>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
          <div className="border-dashed px-4 border-success border-[1px] rounded-md bg-lightGreenBlue h-16 py-2 flex items-center col-span-2">
            <Vectortick className="h-7 w-7" />
            <span className="text-sm font-bold text-pirmaryBlue ml-3">
              Refer 3 friends or colleages to sign up and if they sign up for
              premium subscription, your premium subscription is free.
            </span>
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue"> Name</label>
            <input
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="name"
              type="text"
              placeHolder="Enter name"
              className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
            />
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">Email</label>
            <input
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              type="email"
              placeHolder="Enter email"
              className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
            />
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">Message</label>
            <textarea
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="message"
              placeHolder="Enter message"
              className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none resize-y min-h-[100px]"
            />
          </div>
          <div className="mt-2 col-span-2 flex justify-end">
            <SuccessBtn
              type="submit"
              // onClick={handleSubmit}
            >
              <span className="font-bold">Add referral</span>
            </SuccessBtn>
          </div>
        </div>
      </form>
    </>
  );
}
