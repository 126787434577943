import React, { useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import InfoBoxes from "../../sharedComponents/profile/infoBox";
import ReactApexChart from "react-apexcharts";
import "react-circular-progressbar/dist/styles.css";
import CharacteristicsModal from "./sharedcomponents/CharacteristicsModal";
import TickText from "../../sharedComponents/tickText/TickText";
import {
  a11yProps,
  TabPanel,
  tabstyleBbehavior,
} from "../login/reusableComponents";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ViewMoreViewLess from "../../sharedComponents/viewMoreViewLess/ViewMoreViewLess";
// tabs
import Decisive from "./tabscomponents/decisive";
import Interactive from "./tabscomponents/Interactive";
import Steady from "./tabscomponents/Steady";
import Cautious from "./tabscomponents/Cautious";
import SlideBox from "../../sharedComponents/slideBox/SlideBox";
// slides
import Slide1 from "./slides/Slide1";
import Slide2 from "./slides/Slide2";
import Slide3 from "./slides/Slide3";
import Slide4 from "./slides/Slide4";
import Slide5 from "./slides/Slide5";
import Slide6 from "./slides/Slide6";
import Slide7 from "./slides/Slide7";
import { getByUserId } from "../../../../api/jc";

import IconRED from "../../../assets/characteristicIconRED.svg";
import IconBlue from "../../../assets/characteristicIconBlue.svg";
import IconGreen from "../../../assets/characteristicIconGreen.svg";
import IconYellow from "../../../assets/characteristicIconYellow.svg";
import SemiCircleChart from "./semiCircleChart";
import { updateUser } from "../../../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import DynamicBoxDescription from "./sharedcomponents/dynamicBoxDescription";
import Modal from "../../sharedComponents/modal/Modal";
import { getPremiumStatus } from '../../../../api/jc'
import PremiumButton from "../premiumButton";
import chartBlured from '../../../assets/Behavioral-page-blured.png'
import { Skeleton } from "@mui/material";


const tooltipData = [{tooltip : [
  `The comment below highlights some of the traits specific to just your unique score. <br />

    - You prefer a minimum of direct supervision or control over your actions. <br />
    - You are pretty competitive in the things you do on both, the personal and professional side. <br />
    - You like challenging assignments that require you to work out of your comfort zone. <br />
    - You can be sufficiently assertive without being pushy or nervy. <br />
    - You are always interested in new approaches and new ways of doing things.
    - You like to have variety and a faster pace.`,
    

  `The comment below highlights some of the traits specific to just your unique score. <br />

    - You can be an effective coach or counselor for others. <br />
    - Your present yourself in a poised manner to both small or large groups of people. <br />
    - Your tend to be generous with your time in helping others. <br />
    - Your tend to meet new people in a confident and appropriate manner. <br />
    - You like democratic not dictatorial relationships on the job. <br />
    - You like a flexible environment that allows for creativity.`,

  `The comment below highlights some of the traits specific to just your unique score.<br />

    - You appreciate the need of others to have more freedom and less structure.<br />
    - You are flexible enough to deal with change openly and without fear.<br />
    - Change is fine with you as long as it is needed.<br />
    - Your respect the established ways, but are open to change when it is deemed necessary.<br />
    "- You are comfortable acting alone to determine the best course of action.<br />
    - You bring a metered sense of urgency to get things done now, but not without some planning and thought.<br />`,

  `The comment below highlights some of the traits specific to just your unique score. <br />

    - You think it is important to adhere to specific and detailed instructions or procedures. <br />
    - You are very conscientious in delivering high level of detail. <br />
    - You desire a great deal of explanation before beginning new tasks. <br />
    - You believe in maintaining high standards of quality control. <br />
    - You are excellent at gathering detailed information and examples. <br />
    - You possess excellent critical thinking and problem solving ability. <br />`,
]},{
  tooltip : [
  'Adaptive',
  'Adaptive',
  'Adaptive',
  'Adaptive',
]}

];

export default function BehavioralTraits() {
  const dispatch = useDispatch()
  let apiData = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'BehaviorAssessment'
    )?.assessment?.assessment_content
  );
  const behaviorChartTooltip = apiData?.score
  const behaviorData = apiData?.discDescriptor || []
  let authenticSection = behaviorData?.find(({ section }) => section === 'as');
  let adaptiveSection = behaviorData?.find(({ section }) => section === 'ms');
  const lastSectionData = behaviorData?.find(({ section }) => section === 'dos-dnt')
  const [openSlideBox, setOpenSlideBox] = React.useState(false);
  const [allData, setAllData] = useState({});
  const [value, setValue] = useState(0);
  const [isPremium, setIsPremium] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [chartSeries, setChartSeries] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [icon, setIcon] = useState("");
  const [selectedModalData, setSelectedModalData] = useState('Decisive');
  const [authenticModal, setAuthenticModal] = useState(false);
  const [adaptiveModal, setAdaptiveModal] = useState(false);

  const chartBarOption = {
    // dataLabels: {
    //   enabled: false, },
    chart: {
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
      labels: ["Decisive", "Interactive", "Steady", "Cautious"],
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#FF0000", "#E5C311", "#008000", "#0000FF"],
    stroke: {
      show: true,
      width: 6,
      colors: ["transparent"],
    },
    fill: {
      colors: ["#f00", "#D3D3D3"],
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
        // distributed: true,
      },
    },
    xaxis: {
      categories: ["Decisive", "Interactive", "Steady", "Cautious"],
    },
    yaxis: [
      {
        seriesName: "Column A",
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
        title: {
          // text: "Columns",
          show: false,
        },
      },
      {
        seriesName: "Column B",
        show: false
      },
    ],
    noData: {
      text: 'Loading...'
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      intersect: false,
      shared: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        if ( seriesIndex ===  0) {
        return `<div class="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
        ${w.config.chart.labels[dataPointIndex]} : ${series[seriesIndex][dataPointIndex]}%
        </div>
        <div className='rounded-md' style='font-size:0.75rem;padding:0.75rem;background-color:#4D5871;'>
              <div className='text-xs text-white p-3 rounded-b'>
                ${behaviorChartTooltip?.length && behaviorChartTooltip[dataPointIndex]?.statements.join('<br /><br />- ')}
              </div>
            </div>`;
        } 
        if ( seriesIndex ===  1) {
          return `<div class="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
        ${w.config.chart.labels[dataPointIndex]} 
        </div>
        <div className='rounded-md' style='font-size:0.75rem;padding:0.75rem;background-color:#4D5871;'>
              <div className='text-xs text-white p-3 rounded-b'>
                ${tooltipData[seriesIndex].tooltip[dataPointIndex]} : ${series[seriesIndex][dataPointIndex]}
              </div>
            </div>`;
        }
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
      show: false,
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = tabstyleBbehavior();
  
  const SlideBoxList = [
    <Slide1 />,
    <Slide2 />,
    // <Slide3 />,
    // <Slide4 />,
    <Slide5 />,
    // <Slide6 />,
    // <Slide7 />,
  ];

  let tabColor;
  if (value === 0) {
    tabColor = classes.red;
  } else if (value === 1) {
    tabColor = classes.yellow;
  } else if (value === 2) {
    tabColor = classes.green;
  } else {
    tabColor = classes.blue;
  }
  const [result, setResult] = useState([]);
  // const [succesTraits, setSuccesTraits] = useState({});
  const [dataChart, setdataChart] = useState([]);
  const [image, setImage] = useState('');
  const getData = async () => {
    getByUserId().then((res) => {
      dispatch(updateUser(res.data));
      setImage(res?.data?.candidate_picture_upload_path || '')
      let behaviorData = res.data.assessments.find(
        (i) => i.assessment.assessment_type === 'BehaviorAssessment'
      )?.assessment?.result;
      setdataChart(behaviorData);
      setResult(behaviorData);
      let temp = [];
      let temp2 = [];
      temp = behaviorData?.map((item) => Number(item.value));
      temp2 = behaviorData?.map((item) => Number(item.adaptiveValue));
      setChartSeries([
        {
          name: 'Column A',
          type: 'column',
          data: temp,
          tooltips: [
            `The comment below highlights some of the traits specific to just your unique score. <br />

              - You prefer a minimum of direct supervision or control over your actions. <br />
              - You are pretty competitive in the things you do on both, the personal and professional side. <br />
              - You like challenging assignments that require you to work out of your comfort zone. <br />
              - You can be sufficiently assertive without being pushy or nervy. <br />
              - You are always interested in new approaches and new ways of doing things.
              - You like to have variety and a faster pace.`,

            `The comment below highlights some of the traits specific to just your unique score. <br />

              - You can be an effective coach or counselor for others. <br />
              - Your present yourself in a poised manner to both small or large groups of people. <br />
              - Your tend to be generous with your time in helping others. <br />
              - Your tend to meet new people in a confident and appropriate manner. <br />
              - You like democratic not dictatorial relationships on the job. <br />
              - You like a flexible environment that allows for creativity.`,

            `The comment below highlights some of the traits specific to just your unique score.<br />

              - You appreciate the need of others to have more freedom and less structure.<br />
              - You are flexible enough to deal with change openly and without fear.<br />
              - Change is fine with you as long as it is needed.<br />
              - Your respect the established ways, but are open to change when it is deemed necessary.<br />
              "- You are comfortable acting alone to determine the best course of action.<br />
              - You bring a metered sense of urgency to get things done now, but not without some planning and thought.<br />`,

            `The comment below highlights some of the traits specific to just your unique score. <br />

              - You think it is important to adhere to specific and detailed instructions or procedures. <br />
              - You are very conscientious in delivering high level of detail. <br />
              - You desire a great deal of explanation before beginning new tasks. <br />
              - You believe in maintaining high standards of quality control. <br />
              - You are excellent at gathering detailed information and examples. <br />
              - You possess excellent critical thinking and problem solving ability. <br />`,
          ],
        },
        {
          name: "Column B",
          type: "column",
          data: temp2,
          tooltip: [
            `Adaptive`,
            `Adaptive`,
            `Adaptive`,
            `Adaptive`,
          ],
        },
      ]);
      setAllData(res.data);
    });
    const res = await getPremiumStatus()
    const isPremium = res?.data?.user_pricing_type === "premium"
    setIsPremium(isPremium)
    setIsLoading(false)
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="pb-24 flex flex-col">
        <div className="grid grid-cols-1 lg:grid-cols-3 md:gap-6">
          <div className="col-span-2 md:pb-6">
            <InfoBoxes
              containerStyle="h-full"
              hasIcon={true}
              title="Behavioral traits - executive summary"
              displayStyle="inline-block"
            >
              <span>
                <InfoIcon
                  className="cursor-pointer ml-1 text-secondaryBalck"
                  onClick={() => setOpenSlideBox(true)}
                />
              </span>
              {isLoading ? (
                  <div className='flex gap-5 justify-between items-end'>
                    <Skeleton
                      variant='rectangular'
                      width={100}
                      height={500}
                      className='h-full'
                    />
                    <Skeleton
                      variant='rectangular'
                      width={100}
                      height={400}
                      className='h-full'
                    />
                    <Skeleton
                      variant='rectangular'
                      width={100}
                      height={500}
                      className='h-full'
                    />
                    <Skeleton
                      variant='rectangular'
                      width={100}
                      height={200}
                      className='h-full'
                    />
                    <Skeleton
                      variant='rectangular'
                      width={100}
                      height={450}
                      className='h-full'
                    />
                    <Skeleton
                      variant='rectangular'
                      width={100}
                      height={200}
                      className='h-full'
                    />
                  </div>
                ) : !isPremium ? <PremiumButton><img src={chartBlured} /></PremiumButton>:<>
                <div className="chart-container">
                  <ReactApexChart
                    height={450}
                    width={"100%"}
                    options={chartBarOption}
                    series={chartSeries}
                    type="line"
                  />
                </div>
                <div
                  className={`flex flex-wrap md:justify-around justify-start items-center md:w-[80%] mx-auto text-pirmaryBlue italic`}
                >
                  **** Please note that a high score doesn't necessarily correlate with 'good' behavioral traits, just as a low score doesn't imply 'bad' traits. This is because our scoring system is based on a spectrum or continuum of behaviors, thereby reflecting the diversity and complexity of human traits.
                </div>
              </>}
            </InfoBoxes>
          </div>
          <div className="col-span-1 grid grid-cols-1">
            <InfoBoxes hasIcon={true} title='Authentic behavior'>
              <ViewMoreViewLess>
                Your authentic behavior refers to your primary style, the way you naturally behave when being true to yourself. This is the style you adopt when feeling most comfortable and at ease. However, when you're under pressure or stress, you'll often revert to this style as it helps to reduce tension and alleviate stress. Understanding and embracing your authentic style allows you to maximize your potential and operate more effectively in different situations.
              </ViewMoreViewLess>
            </InfoBoxes>
            <InfoBoxes hasIcon={true} title='Adaptive behavior'>
              <ViewMoreViewLess>
                The adaptive style refers to how you behave when you feel you are being observed or when you become conscious of your behavior and feel the need to adjust it accordingly. Spending too much time in this style can lead to stress and decreased effectiveness. Unlike your natural and authentic style, the adaptive style may not align with your preferences and tendencies.
              </ViewMoreViewLess>
            </InfoBoxes>
          </div>
        </div>
        <InfoBoxes hasIcon={true} title="Four components of behavioral traits">
          <div className=" overflow-x-auto">
            <table className="w-full">
              <thead className="bg-[#F4F6F8] h-16">
                <tr>
                  <th></th>
                  <th className="font-bold px-3 text-left text-2xl text-pirmaryBlue">
                    Preference
                  </th>
                  <th
                    colSpan={2}
                    className="font-bold px-3 text-left text-2xl text-pirmaryBlue"
                  >
                    Priority
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="h-16 border-b border-[#E5E5E5]">
                  <td className="whitespace-nowrap">
                    <span className="h-[14px] ml-3 w-[14px] bg-[#ff0000] rounded-full inline-block"></span>
                    <span className="ml-3 text-[#ff0000]">Decisive</span>
                  </td>
                  <td className="text-base text-pirmaryBlue px-3 whitespace-nowrap">
                    Making quick decision{" "}
                  </td>
                  <td
                    colSpan={2}
                    className="text-base text-pirmaryBlue px-3 whitespace-nowrap"
                  >
                    Problem solving and getting results
                  </td>
                </tr>
                <tr className="h-16 border-b border-[#E5E5E5]">
                  <td className="whitespace-nowrap">
                    <span className="h-[14px] ml-3 w-[14px] bg-[#E5C311] rounded-full inline-block"></span>
                    <span className="ml-3 text-[#E5C311]">Interactive</span>
                  </td>
                  <td className="text-base text-pirmaryBlue px-3 whitespace-nowrap">
                    Active engage others and show emotion
                  </td>
                  <td
                    colSpan={2}
                    className="text-base text-pirmaryBlue px-3 whitespace-nowrap"
                  >
                    Actively engage people
                  </td>
                </tr>
                <tr className="h-16 border-b border-[#E5E5E5]">
                  <td className="whitespace-nowrap">
                    <span className="h-[14px] ml-3 w-[14px] bg-[#008000] rounded-full inline-block"></span>
                    <span className="ml-3 text-[#008000]">Steady</span>
                  </td>
                  <td className="text-base text-pirmaryBlue px-3 whitespace-nowrap">
                    Systematic approach
                  </td>
                  <td
                    colSpan={2}
                    className="text-base text-pirmaryBlue px-3 whitespace-nowrap"
                  >
                    Pacing, persistence, plan development, and steadiness
                  </td>
                </tr>
                <tr className="h-16 border-b border-[#E5E5E5]">
                  <td className="whitespace-nowrap">
                    <span className="h-[14px] ml-3 w-[14px] bg-[#0000ff] rounded-full inline-block"></span>
                    <span className="ml-3 text-[#0000ff]">Cautious</span>
                  </td>
                  <td className="text-base text-pirmaryBlue px-3 whitespace-nowrap">
                    Detail Oriented
                  </td>
                  <td
                    colSpan={2}
                    className="text-base text-pirmaryBlue px-3 whitespace-nowrap"
                  >
                    Procedures, standards, and protocols
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </InfoBoxes>
        <InfoBoxes hasIcon={true} title="Success traits">
          <SemiCircleChart
            isPremium={isPremium}
            isLoading={isLoading}
            chartData={dataChart}
            allData={allData}
            image={image}
            setAuthenticModal={setAuthenticModal}
            setAdaptiveModal={setAdaptiveModal}
          />
        </InfoBoxes>
        <div className="rounded-md shadow-md px-8 bg-white py-6 mt-6">
          <Tabs
            className={`${classes.root} ${tabColor}`}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            aria-label="basic tabs example"
          >
            <Tab
              label="Decisive"
              {...a11yProps(0)}
              className={`${classes.tab} ${classes.redTab}`}
              onClick={(e) => {
                setSelectedModalData('Decisive')
                setIcon(IconRED)
              }}
            />
            <Tab
              label="Interactive"
              {...a11yProps(1)}
              className={`${classes.tab} ${classes.yellowTab}`}
              onClick={(e) => {
                setSelectedModalData('Interactive')
                setIcon(IconYellow)
              }}
            />
            <Tab
              label="Steady"
              {...a11yProps(2)}
              className={`${classes.tab} ${classes.greenTab}`}
              onClick={(e) => {
                setSelectedModalData('Steady')
                setIcon(IconGreen)
              }}
            />
            <Tab
              label="Cautious"
              {...a11yProps(3)}
              className={`${classes.tab} ${classes.blueTab}`}
              onClick={(e) => {
                setSelectedModalData('Cautious')
                setIcon(IconBlue)
              }}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Decisive data={result} setOpenModal={setOpenModal} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Interactive data={result} setOpenModal={setOpenModal} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Steady data={result} setOpenModal={setOpenModal} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Cautious data={result} setOpenModal={setOpenModal} />
          </TabPanel>
        </div>
        <div className="grid mt-6 grid-cols-1 gap-6 md:grid-cols-2">
          <DynamicBoxDescription
            data={behaviorData?.find(({ section }) => section === 'man')} staticData={"Based on your unique behavioral style, you can enhance your effectiveness by becoming aware of your preferences and behaviors. Below are some opportunities for professional development growth, which may explain why you excel in some areas while struggling in others. Here are some ways to increase your effectiveness:"}
          />
          <DynamicBoxDescription
            data={behaviorData?.find(({ section }) => section === 'mot')} staticData={"Based on your behavioral style, certain factors in your environment can influence your level of motivation. Being aware of these factors can help you stay more motivated and productive. Here are some things that you may want in your surroundings to feel optimally motivated:"}
          />
          <DynamicBoxDescription
            data={behaviorData?.find(({ section }) => section === 'sbi')} staticData={"Recognizing and leveraging your unique behavioral strengths is essential to achieving success and fulfillment. Your behavioral style has inherent strengths that arise from the interplay of its four dimensions. The following statements outline some of the strengths associated with your particular behavioral style:"}
          />
          <DynamicBoxDescription
            data={behaviorData?.find(({ section }) => section === 'ijc')} staticData={"Your preferred behavioral style is crucial in defining the environment you would enjoy working in. To help you identify the critical aspects of your ideal working climate, consider the following points based on your behavioral tendencies. Your perfect work environment would provide you with the following:"}
          />
          <DynamicBoxDescription
            data={behaviorData?.find(({ section }) => section === 'cqi')} staticData={"You could focus on a few areas for continual improvement based on your behavioral style. While these areas may not necessarily be weaknesses, they have the potential to become problematic if not acknowledged or addressed:"}
          />
          <DynamicBoxDescription
            data={behaviorData?.find(({ section }) => section === 'trn-lrn')} staticData={"Your behavioral tendencies influence your preferred training and learning style. Knowing your preferences can help you become more effective in teaching and learning. Here are some insights into your preferred style based on your behavioral traits:"}
          />
        </div>
        <div className="rounded-md shadow-md bg-white py-6 mt-6 overflow-hidden relative p-6">
          <p className=" text-pirmaryBlue font-bold text-2xl mb-5 sticky top-0 bg-white lowercase first-letter:uppercase">
            {lastSectionData?.section_fullname || ''}
          </p>
          <div className="text-pirmaryBlue text-base mb-6">
            This section guides those who interact with you on communicating more effectively by understanding and appealing to your natural behavioral style. The following list includes the communication approaches others <span className="font-bold">SHOULD</span> consider (Do's) when communicating with you to ensure better understanding. Conversely, the second list describes approaches others <span className="font-bold">SHOULD AVOID</span> (Don'ts) to avoid misunderstandings.
          </div>
          {lastSectionData?.subsection?.length && (
            <div className='grid mt-6 grid-cols-1 gap-6 md:grid-cols-2'>
              <div>
                <div className='text-pirmaryBlue text-base mb-4'>
                  Replace Things to do to communicate with you effectively with <span className="font-bold">Tips for effective communication with you:</span>
                </div>
                {lastSectionData.subsection[0]?.statements?.length &&
                  lastSectionData.subsection[0].statements.map(
                    (data, index) => <TickText key={index} text={data} />
                  )}
              </div>
              <div>
                <div className='text-pirmaryBlue text-base font-bold mb-4'>
                  {lastSectionData.subsection[1]?.title[0] || ''}
                </div>
                {lastSectionData.subsection[1]?.statements?.length &&
                  lastSectionData.subsection[1].statements.map(
                    (data, index) => <TickText key={index} text={data} />
                  )}
              </div>
            </div>
          )}
        </div>
      </div>

      <SlideBox
        title="About this report"
        componentList={SlideBoxList}
        open={openSlideBox}
        setOpen={setOpenSlideBox}
      />

      <CharacteristicsModal
        open={openModal}
        setOpen={setOpenModal}
        icon={icon}
        data={selectedModalData}
      />

      <Modal
        title="Authentic behavior"
        open={authenticModal}
        setOpen={setAuthenticModal}
        mdUpWidth={950}
        style={{ maxWidth: "550px", width: "100%" }}
      >
        <div className="flex items-strat gap-6">
          <div className="">
            {/* <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
              {authenticSection?.title[0]?.split(':')[0] || ''}
            </h5> */}
            <p className="text-base text-pirmaryBlue  mb-6">
              {/* {authenticSection?.title[0]?.split(':')?.slice(1).join(':')?.trim() || ''} */}
              Authentic behavior or natural style pattern refers to your behavior when you're not consciously thinking about it. This is your most comfortable and authentic self, and it's the style that you tend to revert to under stress or when you're moving too quickly to modify your behavior. In your daily roles, striving for authenticity and staying true to your natural style is essential, as it can yield better results with less effort and stress.
            </p>

            <p className="text-base text-pirmaryBlue  mb-6">
              Embracing your natural style and leveraging its unique strengths can help you thrive professionally and professionally.
            </p>

            <p className="text-base text-pirmaryBlue  mb-6">
              The following statements apply uniquely to your natural style:
            </p>
            
            {authenticSection
              ?.subsection[0]?.statements?.map((data, index) => (
                <TickText key={index} text={data} />
              ))}
          </div>
        </div>
      </Modal>

      <Modal
        title="Adaptive behavior"
        open={adaptiveModal}
        setOpen={setAdaptiveModal}
        mdUpWidth={950}
        style={{ maxWidth: "550px", width: "100%" }}
      >
        <div className="flex items-strat gap-6">
          <div className="">
            {/* <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
              {adaptiveSection?.title[0]?.split(':')[0] || ''}
            </h5> */}
            <p className="text-base text-pirmaryBlue  mb-6">
              {/* {adaptiveSection?.title[0]?.split(':')?.slice(1).join(':')?.trim() || ''} */}
              Adaptive behavior or style pattern refers to how you consciously modify your behavior to adapt to a particular situation or environment. Unlike your natural behavior pattern, this is not your default behavior but a style you adopt in response to external factors. For example, you may feel like you "should" behave in this manner based on the expectations of others or the demands of a specific situation.
            </p>
            <p className="text-base text-pirmaryBlue  mb-6">
              Understanding your Adaptive behavior pattern can help you navigate social and professional situations more effectively. In addition, by being aware of your tendencies to adapt your behavior, you can be more intentional about when and how you choose to do so.
            </p>
            <p className="text-base text-pirmaryBlue  mb-6">
              The statements below are specific to your Adaptive style and can help you better understand how you tend to modify your behavior in different situations.
            </p>
            {adaptiveSection
              ?.subsection[0]?.statements?.map((data, index) => (
                <TickText key={index} text={data} />
              ))}
          </div>
        </div>        
      </Modal>
    </>
  );
}
