import React, { useEffect, useState } from "react";
import avatar from "../../../../assets/avatartest.svg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import { Avatar, MenuItem, Select, TextField } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { DatePickerStyles } from "../../../sharedComponents/materialStyles";
import { allCountry } from "../../../../contants/countries";
import { CountryCode } from "../../../../contants/country-code";
import { industries } from "../../../../contants/industries";
import { useFormik } from "formik";
import { FocusError } from 'focus-formik-error'
import { deleteProfilePhoto, postJCProfile } from "../../../../../api/jc";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import cx from "classnames";
import moment from "moment";

export default function EditprofileComponent({ handleReload }) {
  const dateInputStyle = DatePickerStyles();
  const data = useSelector((state) => state.user);
  const [initialValues, setInitialValues] = useState({
    birthday: "",
    first_name: "",
    last_name: "",
    country: "",
    country_code: "",
    phone_number: "",
    email_id: "",
    gender: "",
    city: "",
    designation: "",
    about_me: "",
    gitlab_id: "",
    facebook_id: "",
    google_id: "",
    linkdin_id: "",
    current_position: "",
    industry: "",
  });
  const [image, setImage] = React.useState("");

  const handleSubmit = () => {
    const { first_name, last_name, phone_number, country_code, country } =
      formik.values;
    if (
      !first_name ||
      !last_name ||
      !phone_number ||
      !country_code ||
      !country
    ) {
      toast.error("Please fill all the required fields.");
      return;
    }
    let bodyFormData = new FormData();
    bodyFormData.append("file", formik.values.image);
    bodyFormData.append("body", JSON.stringify(formik.values));
    postJCProfile(bodyFormData).then(() => {
      toast.success("Your information updated successfully.");
      handleReload();
    });
  };
  const validate = (values) => {
    const errors = {};

    if (!values.first_name) errors.first_name = true;
    if (!values.last_name) errors.last_name = true;
    if (!values.phone_number) errors.phone_number = true;
    if (!values.country_code) errors.country_code = true;
    if (!values.country) errors.country = true;
    if (!values.city) errors.city = true;

    return errors;
  };
  const formik = useFormik({
    initialValues,
    handleSubmit,
    validate,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleChangeImage = (e) => {
    let files = e.target.files;
    formik.setFieldValue("image", files[0]);
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImage(e.target.result);
    };
  };

  const deleteImage = async () => {
    try {
      image && (await deleteProfilePhoto());
      setImage("");
      //handleReload();
    } catch (error) {
      console.error(error);
    }
  };

  // const uploadPhoto = async () => {
  //   try {
  //     if(image?.file){
  //       let fd = new FormData();
  //       fd.append('body', '{}')
  //       fd.append("file", image.file);
  //       await postJCProfile(fd)
  //       toast.success('Profile photo updated!')
  //     } else toast.info('Please select an image first!')
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const handleCountryCodeChange = (e) => {
    const selectedCountryCode = e.target.value;
    const selectedCountry = CountryCode.find(
      (item) => item.name + " " + item.dial_code === selectedCountryCode
    );

    if (selectedCountry) {
      formik.setFieldValue("country_code", selectedCountryCode);
      formik.setFieldValue("country", selectedCountry.name);
    }
  };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontSize: '35px',
        fontWeight: "700",
        width: "6rem",
        height: "6rem",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (fullName) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  useEffect(() => {
    if (data) {
      setImage(data?.candidate_picture_upload_path || "");
      setInitialValues({ ...data });
    }
  }, []);

  const { values, errors } = formik || {};

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FocusError formik={formik} />
        <div className="flex flex-col py-4">
          <div className="flex justify-start">
            <div className="relative flex">
              {image ? (
                <img
                  src={image || avatar}
                  className="h-24 w-24 rounded-md border border-success"
                  alt="user's profile"
                />
              ) : (
                <Avatar {...stringAvatar(`${data?.first_name || ''} ${data?.last_name || ''}`)} />
              )}
              {image && <span onClick={deleteImage} className="absolute w-[22px] h-[22px] inline-block rounded-full cursor-pointer text-sm -right-[11px] -top-[11px] bg-dangerLight">
                <ClearRoundedIcon
                  size="small"
                  className="right-[1px] bottom-[1px] relative text-danger p-1"
                />
              </span>}
              <input type="file" id="selectfile" onChange={handleChangeImage} className="hidden" />
              <label htmlFor="selectfile">
                <span className="absolute w-[22px] h-[22px] inline-block rounded-full cursor-pointer text-sm -right-[11px] -bottom-[11px] bg-success">
                  <EditOutlinedIcon
                    size="small"
                    className="text-white cursor-pointer right-[1px] p-1 bottom-[1px] relative"
                  />
                </span>
              </label>
            </div>
          </div>
          <div className="text-pirmaryBlue font-bold text-lg mt-4 mb-2 ">
            Change basic information
          </div>
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">First name *</label>
              <input
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="first_name"
                type="text"
                className={cx(
                  "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                  {
                    "border-red-500": errors.first_name,
                  },
                  "border-grayLight"
                )}
              />
              {errors.first_name && (
                <span className="text-red-500">This is required field</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">Last name *</label>
              <input
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="last_name"
                type="text"
                className={cx(
                  "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                  { "border-red-500": errors.last_name },
                  "border-grayLight"
                )}
              />
              {errors.last_name && (
                <span className="text-red-500">This is required field</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">Birth date</label>
              <TextField
                id="date"
                // label="Birthday"
                type="month"
                value={formik.values.birthday}
                inputProps={{ max: moment().format("YYYY-MM"), placeholder: "MM/YYYY" }}
                //defaultValue={formik.values.birthday}
                onChange={(e) => {
                  formik.setFieldValue("birthday", e.target.value);
                }}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
                className={dateInputStyle.root}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">Gender</label>
              <Select
                onChange={(e) => {
                  formik.setFieldValue("gender", e.target.value);
                }}
                displayEmpty
                value={formik.values.gender}
                className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
                MenuProps={MenuProps}
              >
                <MenuItem value='' selected disabled>
                  select
                </MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="Transgender">Transgender</MenuItem>
                <MenuItem value="Non-binary">Non-binary</MenuItem>
                <MenuItem value="Prefer not to answer">Prefer not to answer</MenuItem>
              </Select>
            </div>
          </div>
          <div className="text-pirmaryBlue font-bold text-lg mt-4 mb-2 ">
            Contact information:
          </div>
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">
                Country code *
              </label>
              <Select
                value={values.country_code}
                displayEmpty
                onChange={handleCountryCodeChange}
                className={cx(
                  "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                  { "border-red-500": errors.country_code },
                  "border-grayLight"
                )}
                MenuProps={MenuProps}
              >
                <MenuItem value="">select</MenuItem>
                {CountryCode.map((item, index) => (
                  <MenuItem key={item.name} value={item.name + " " + item.dial_code}>
                    {item.name + " " + item.dial_code}
                  </MenuItem>
                ))}
              </Select>
              {errors.country_code && (
                <span className="text-red-500">This is required field</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">
                Phone number *
              </label>
              <input
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="phone_number"
                type="tel"
                className={cx(
                  "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                  { "border-red-500": errors.phone_number },
                  "border-grayLight"
                )}
              />

              {errors.phone_number && (
                <span className="text-red-500">This is required field</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">Country *</label>
              <Select
                onChange={(e) => {
                  formik.setFieldValue("country", e.target.value);
                }}
                value={values.country}
                displayEmpty
                className={cx(
                  "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                  { "border-red-500": errors.country },
                  "border-grayLight"
                )}
                MenuProps={MenuProps}
              >
                <MenuItem value="">select</MenuItem>
                {allCountry?.map((item) => (
                  <MenuItem value={item} key={item}>{item}</MenuItem>
                ))}
              </Select>
              {errors.country && (
                <span className="text-red-500">This is required field</span>
              )}
            </div>
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">City *</label>
              <input
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="city"
                type="text"
                className={cx(
                  "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                  { "border-red-500": errors.city },
                  "border-grayLight"
                )}
              />

              {errors.city && (
                <span className="text-red-500">This is required field</span>
              )}
            </div>
            <div className="flex flex-col col-span-2">
              <label className="text-base text-pirmaryBlue">Email *</label>
              <input
                value={formik.values.email_id}
                name="email_id"
                type="email"
                disabled
                className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
              />
            </div>
          </div>
          <div className="text-pirmaryBlue font-bold text-lg mt-4 mb-2 ">
            Professional information
          </div>
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">
                Industry
                <Tooltip
                  placement="top"
                  title={
                    <div className="rounded-md">
                      <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                        Industry
                      </div>
                      <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                        Please indicate the industry you are currently working in or your interest in. Examples of industries include technology, finance, healthcare, education, marketing, retail, hospitality, and many more. This information will help determine the ideal job designation that best aligns with your competencies and required skillset for your role.
                      </div>
                    </div>
                  }
                  arrow
                >
                  <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
                </Tooltip>
              </label>
              <Select
                onChange={(e) => {
                  formik.setFieldValue("industry", e.target.value);
                }}
                displayEmpty
                value={values.industry}
                className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
                MenuProps={MenuProps}
              >
                <MenuItem value='' selected disabled>
                  select
                </MenuItem>
                {industries.map((item, index) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">
                Designation
                <Tooltip
                  placement="top"
                  title={
                    <div className="rounded-md w-[400px]">
                      <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                        Designation
                      </div>
                      <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                      Please provide your official job title or designation, such as Senior Sales Executive or Senior Full Stack Developer. This information will help explain your role and responsibilities and the skills and expertise you need for your position.<br/><br/>

                      A job designation, or a job title or position, refers to the official title given to a particular job or role within an organization. The designation typically describes the nature of the work, level of seniority, and degree of responsibility associated with the job. <br/><br/>
                      
                      For example, a Senior Sales Executive is a job title that typically involves managing a team of sales representatives, developing sales strategies, and meeting revenue targets. 
                      </div>
                    </div>
                  }
                  arrow
                >
                  <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
                </Tooltip>
              </label>
              <input
                value={formik.values.designation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="designation"
                type="text"
                className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
              />
            </div>
            <div className="flex flex-col col-span-2">
              <label className="text-base text-pirmaryBlue">
                Current position
              </label>
              <input
                value={formik.values.current_position}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="current_position"
                type="text"
                className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
              />
            </div>
          </div>
          <div className="text-pirmaryBlue font-bold text-lg mt-4 mb-2 ">
            Social media
          </div>
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">Gitlab</label>
              <input
                value={formik.values.gitlab_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="gitlab_id"
                type="text"
                className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">Google</label>
              <input
                value={formik.values.google_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="google_id"
                type="text"
                className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">Facebook</label>
              <input
                value={formik.values.facebook_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="facebook_id"
                type="text"
                className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-base text-pirmaryBlue">Linkedin</label>
              <input
                value={formik.values.linkdin_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="linkdin_id"
                type="text"
                className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
              />
            </div>
            <div className="flex flex-col col-span-2">
              <label className="text-base text-pirmaryBlue">
                About me
                <Tooltip
                  placement="top"
                  title={
                    <div className="rounded-md w-[400px]">
                      <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                        About me
                      </div>
                      <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                        Please briefly summarize the essential information you would like a hiring manager or human resource professional to know about you from your resume.  The "About me" section is an opportunity to provide a concise summary of your skills, experience, and career goals. This section should highlight the most important and relevant information about the candidate and provide a brief overview of what they can offer the employer.<br/><br/>

                        For example, I am an experienced marketing professional with over five (5) years of experience developing and executing successful marketing campaigns across various channels, including social media, email marketing, and PPC advertising. I am proficient in using marketing automation tools like HubSpot, Marketo, and Pardot. My passion for creativity and innovation has helped me consistently exceed performance targets and deliver measurable results. I am seeking a challenging marketing position that will allow me to leverage my skills and experience to drive business growth and positively impact the organization.
                      </div>
                    </div>
                  }
                  arrow
                >
                  <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
                </Tooltip>
              </label>
              <textarea
                value={formik.values.about_me}
                onChange={(e) => {
                  formik.setFieldValue("about_me", e.target.value);
                }}
                type="text"
                className="w-full rounded-md border min-h-[78px] p-2 text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none resize-y"
              />
            </div>
          </div>
          <div className="mt-2 flex justify-end">
            <SuccessBtn type="submit">
              <span className="font-bold">Update</span>
            </SuccessBtn>
          </div>
        </div>
      </form>
    </>
  );
}
