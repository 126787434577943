import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import cx from "classnames";
import DangerLightBtn from "../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import { DatePickerStyles } from "../../../sharedComponents/materialStyles";
import { useFormik } from "formik";
import {
  addCertificationJC,
  deleteCertificationJC,
} from "../../../../../api/jc";
import { toast } from "react-toastify";

export default function CertificateModal({
  handleReload,
  selected,
  setSelected,
}) {
  const dateInputStyle = DatePickerStyles();
  const [initialValues, setInitialValues] = useState({
    name: "",
    issuing_by_organization: "",
    issue_date: "",
    credential_id: "",
  });


  const handleSubmit = (values) => {

    if (Object.keys(selected).lenght !== 0) {

      let data ={
        id: formik.values._id,
        credential_id: formik.values.credential_id,
        issue_date: formik.values.issue_date,
        issuing_by_organization: formik.values.issuing_by_organization,
        name: formik.values.name,
        majors_obtained: formik.values.majors_obtained,
        __v: formik.values.__v
      }
      //let times = new Date(...data.issue_date.split("-"));
      let temp = {
        ...data,
        //issue_date: new Date(...data.issue_date.split("-")),
      };

        addCertificationJC(temp).then((res) => {
            console.log(res);
            toast.success("Done");
            handleReload();
        });
          console.log(formik.values);

      } else {

      //let times = new Date(...formik.values.issue_date.split("-"));
      let temp = {
        ...formik.values,
        //issue_date: new Date(...formik.values.issue_date.split("-")),
      };
      addCertificationJC(temp).then((res) => {
        console.log(res);
        toast.success("Done");
        handleReload();
      });
    }
    console.log(formik.values);
  };

  const validate = (values) => {
    const errors = {};
    
    if (!values.name) errors.name = true;
    if (!values.issuing_by_organization) errors.issuing_by_organization = true;
    if (!values.issue_date) errors.issue_date = true;
  
    return errors;
  };
  
  const formik = useFormik({
    initialValues,
    handleSubmit,
    validate,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleDelete = () => {
    console.log(selected._id);
    deleteCertificationJC({ id: selected._id }).then((res) => {
      toast.success("successfully removed.");
      handleReload();
      setSelected({});
    });
  };

  useEffect(() => {
    if (Object.keys(selected).lenght !== 0)
      setInitialValues({
        ...selected,
      });
  }, [selected]);

  const { errors } = formik || {};

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">Name *</label>
            <input
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="name"
              type="text"
              placeHolder="Enter name"
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.name },
                "border-grayLight"
              )}
            />
            {errors.name && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">
              Issuing organization *
            </label>
            <input
              value={formik.values.issuing_by_organization}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="issuing_by_organization"
              type="text"
              placeHolder="Enter issuing organiztion"
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.issuing_by_organization },
                "border-grayLight"
              )}
            />
            {errors.issuing_by_organization && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-base text-pirmaryBlue">Issue date *</label>
            <TextField
              id="date"
              // label="Birthday"
              type="date"
              error={errors.issue_date}
              //defaultValue=""
              name="issue_date"
              value={formik.values.issue_date}
              onChange={(e) => {
                formik.setFieldValue("issue_date", e.target.value);
              }}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              className={dateInputStyle.root}
            />
            {errors.issue_date && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">Certificate number</label>
            <input
              value={formik.values.credential_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="credential_id"
              type="text"
              placeHolder="Enter certificate number"
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.credential_id },
                "border-grayLight"
              )}
            />
            {errors.credential_id && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="mt-2 col-span-2 flex justify-between items-center">
            <div>
              {Object.keys(selected).length > 0 && (
                <DangerLightBtn onClick={handleDelete} type="button">
                  <span className="font-bold">Delete certificate</span>
                </DangerLightBtn>
              )}
            </div>
            <SuccessBtn type="submit">
              <span className="font-bold">
                {Object.keys(selected).length > 0 ? 'Update certificate' : 'Add certificate'}
              </span>
            </SuccessBtn>
          </div>
        </div>
      </form>
    </>
  );
}
