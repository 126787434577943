import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import { ReactComponent as ArrowLeft } from '../../../../../assets/arrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../../../assets/arrowRight.svg';
import SuccessBtn from '../../../../sharedComponents/buttons/successBtn/SuccessBtn';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  useStyle,
  rows,
  columns,
  CreateAvatar,
  CreateSelect,
} from './reusableComponent';
import CondidateListCard from './CondidateListCard';
import WhiteBox from '../../../../sharedComponents/whiteBox/WhiteBox';
import { ReactComponent as Female } from '../../../../../assets/Female.svg';
import { ReactComponent as Male } from '../../../../../assets/Male.svg';
import { ReactComponent as CandidateMatchingCompetencies } from '../../../../../assets/CandidateMatchingCompetencies.svg';
import { ReactComponent as CandidatesMatchingSkillsCompetencies } from '../../../../../assets/CandidatesMatchingSkillsCompetencies.svg';
import { ReactComponent as CandidatesMatchingSkills } from '../../../../../assets/CandidatesMatchingSkills.svg';
import { ReactComponent as CompletedAssessment } from '../../../../../assets/CompletedAssessment.svg';
import { ReactComponent as TotalAssessment } from '../../../../../assets/TotalAssessment.svg';
import { ReactComponent as PendingRequisitions } from '../../../../../assets/PendingRequisitions.svg';
import { ReactComponent as OpenRequisitions } from '../../../../../assets/OpenRequisitions.svg';
import { ReactComponent as OffersSent } from '../../../../../assets/OffersSent.svg';
import CondidateProfileModal from './modals/CondidateProfileModal';
import InterviewScoreModal from './modals/InterviewScoreModal';
import {
  barChartApi,
  candidateListApi,
  getHRProfile,
  updateCandidateStatus,
} from '../../../../../../api/hr';
import TextTooltip from '../../../../sharedComponents/textTooltip/TextTooltip';
import BarCharts from './BarCharts';
import { getDriverColors } from '../../../../../../utils/getColors';
import { getCompetencyTotal } from '../../../../../../utils/getCompetencyTotal';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

function CondidateList() {
  const navigate = useNavigate();
  const [candidProModal, setCandidProModal] = React.useState(false);
  const [interviewScoreModal, setInterviewScoreModal] = React.useState(false);
  const [interviewData, setInterviewData] = React.useState(false);
  const [selectedPool, setSelectedPool] = React.useState();
  const [isActive, setIsActive] = React.useState('Allcandidates');
  const [page, setPage] = React.useState(1);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const rowsPerPage = 5;
  const [counts, setCounts] = React.useState();
  const [chartData, setChartData] = React.useState();
  const [pools, setPools] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);

  const classes = useStyle();

  // For Search Start
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCandidates, setFilteredCandidates] = useState([]);

  const filterCandidates = () => {
    const query = searchQuery.toLowerCase();
    const filtered = candidates.filter(candidate => {
      const fullName = `${candidate.name.name} ${candidate.name.lastName}`.toLowerCase();
      const jobRequisition = candidate.jobRequisition.toLowerCase();
      const jobRequisitionID = candidate.jobRequisitionID.toLowerCase();
      const roleBasedCompetencies = candidate.roleBasedCometencies.toLowerCase();
      const competenciesScore = candidate.competenciesScore.toLowerCase();
      const defaultBehavior = candidate.defaultBehavior.props.text.toLowerCase();

      return (
        fullName.includes(query) ||
        jobRequisition.includes(query) ||
        jobRequisitionID.includes(query) ||
        roleBasedCompetencies.includes(query) ||
        competenciesScore.includes(query) ||
        defaultBehavior.includes(query)
      );
    });
    setFilteredCandidates(filtered);
  };

  useEffect(() => {
    filterCandidates();
  }, [searchQuery, candidates]);

  const displayedCandidates = searchQuery ? filteredCandidates : candidates;
  // For Search End

  //  For Sorting Start
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('default');

  const handleSort = (columnId) => {
    if (orderBy === columnId) {
      setOrder((prevOrder) => {
        if (prevOrder === 'default') return 'asc';
        if (prevOrder === 'asc') return 'desc';
        return 'default'; // Toggle to default if currently descending
      });
    } else {
      setOrderBy(columnId);
      setOrder('asc'); // Set default order to ascending
    }
  };
  // For Sorting End 

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleActive = (e) => {
    let value = e.target.innerHTML;
    value = value.split(' ').join('');
    setIsActive(value);
  };

  const getAllPools = async () => {
    try {
      const res = await getHRProfile();
      if (res?.data?.pool?.length > 0) {
        setPools(res.data.pool);
        getCandidates();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const CreateColorfulText = ({ text, color }) => {
    return (
      <span style={{ color: color, fontSize: '16px', cursor: 'pointer' }}>
        <TextTooltip
          tooltipTitle={text}
          tooltipText='The role-based competencies display the list of capacities resulting from your decision-making style’s unique combination (personal, practical, and analytical). It helps you understand your strengths and weaknesses that enable you to make the necessary changes to achieve your desired level of success.  '
        >
          {text}
        </TextTooltip>
      </span>
    );
  };

  const updateStatus = async (id, status) => {
    try {
      const res = await updateCandidateStatus({
        id,
        status,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getCandidates = async (pool_id) => {
    try {
      const { data } = await barChartApi({ pool_id });
      setChartData(data);
      const res = await candidateListApi({ pool_id });
      if (res?.data?.candidates?.length > 0) {
        let newObj = res.data.candidates.map((candidate, i) => {
          let {
            candidate: {
              canidate_profile_id = '',
              candidate_intreview_score_card = null,
              status = '',
              _id = '',
            },
            job_requisition = '',
            job_id = '',
          } = candidate;
          let {
            first_name = '',
            last_name = '',
            matchSkill = '',
            assessment_type = '',
            driver_of_engagement = '',
            candidate_picture_upload_path = '',
            preferred_behavior = '',
            assessments = [],
          } = canidate_profile_id;
          let name = {
            name: first_name,
            lastName: last_name,
            src: candidate_picture_upload_path,
          };

          return {
            ...candidate,
            _id,
            srNo: i + 1,
            name,
            candidate_profile_id: canidate_profile_id,
            candidate_intreview_score_card,
            jobRequisitionID: job_id,
            jobRequisition: job_requisition,
            roleBasedCometencies: assessment_type,
            skills: matchSkill,
            interviewScoreCard: 5,
            status,
            competenciesScore: getCompetencyTotal(assessments) + '%',
            defaultBehavior: (
              <CreateColorfulText text={preferred_behavior} color={'#6DA183'} />
            ),
            topValue:
              driver_of_engagement?.length > 0 &&
              driver_of_engagement?.split(',').map((data, i) => (
                <span className={`text-[${getDriverColors(data)}]`} id={i}>
                  <CreateColorfulText
                    text={data}
                    color={getDriverColors(data)}
                  />
                  {i !== driver_of_engagement.split(',').length - 1 && ', '}
                </span>
              )),
          };
        });
        setCandidates(newObj);
        setCounts(res.data.counts);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getAllPools();
  }, []);

  return (
    <>
      <div className='mt-[40px] mb-[24px]'>
        {counts && (
          <div className='grid 2xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4'>
            <CondidateListCard
              title='Female'
              number={counts.female}
              image={<Female />}
            />
            <CondidateListCard
              title='Male'
              number={counts.male}
              image={<Male />}
            />
            <CondidateListCard
              title='Candidate matching competencies'
              number={counts.candidatesMatchingCompetences}
              image={<CandidateMatchingCompetencies />}
            />
            <CondidateListCard
              title='Candidates matching skills & competencies'
              number={counts.candidatesMatchingSkillsAndCompetences}
              image={<CandidatesMatchingSkillsCompetencies />}
            />
            <CondidateListCard
              title='Candidates matching skills'
              number={counts.candidatesMatchingSkills}
              image={<CandidatesMatchingSkills />}
            />
          </div>
        )}

        {/* Candidate Pool Tabel start */}
        <WhiteBox
          style={{ width: '100%', minHeight: '600px', margin: '30px auto' }}
        >
          <div className='grid lg:grid-cols-5 grid-cols-1 gap-4 lg:divide-x-2'>
            <div className='col-span-1'>
              <h2 className='text-[24px] text-pirmaryBlue font-bold mb-[26px]'>
                Candidate pool
              </h2>
              <div
                className={`text-sm p-3 w-full rounded-md mb-3 cursor-pointer ${isActive === 'Allcandidates'
                  ? 'bg-success text-white'
                  : 'bg-transparent text-[#2c2c2c]'
                  }`}
                onClick={(e) => {
                  handleActive(e);
                  getCandidates();
                }}
              >
                All candidates
              </div>

              {pools?.length > 0 &&
                pools.map((pool) => (
                  <div
                    className={`text-sm p-3 w-full rounded-md mb-3 cursor-pointer ${isActive === pool.candidate_pool_name.split(' ').join('')
                      ? 'bg-success text-white'
                      : 'bg-transparent text-[#2c2c2c]'
                      }`}
                    onClick={(e) => {
                      handleActive(e);
                      setSelectedPool(pool);
                      getCandidates(pool._id);
                    }}
                  >
                    {pool.candidate_pool_name}
                  </div>
                ))}
              {/* <div
                className={`text-sm p-3 w-full rounded-md mb-3 cursor-pointer ${
                  isActive === "JavaDevelopers"
                    ? "bg-success text-white"
                    : "bg-transparent text-[#2c2c2c]"
                }`}
                onClick={handleActive}
              >
                Java Developers
              </div>
              <div
                className={`text-sm p-3 w-full rounded-md mb-3 flex items-center ${
                  isActive === "GotoPool"
                    ? "bg-success text-white"
                    : "bg-transparent text-[#2c2c2c]"
                }`}
              >
                <span className="mr-4">Go to Pool</span>
                <GoToPool />
              </div> */}
            </div>

            <div className=' col-span-4 px-[35px]'>
              <div className='mb-4 flex justify-end candidate-list-and-workforce-search'>
                <Paper
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                  onSubmit={(e) => {
                    e.preventDefault(); 
                    filterCandidates(); 
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search candidate"
                    inputProps={{ 'aria-label': 'search candidate' }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                    style={{ color: '#7AB794' }} 
                    onClick={filterCandidates} // Optionally, trigger the filter on button click
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
                {/* <IconButton color="error" aria-label="delete">
                <DeleteIcon fontSize="small" />
                </IconButton> */}
              </div>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ height: 450 }} className={classes.table}>
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={
                              column.id === 'status'
                                ? {
                                  minWidth: column.minWidth,
                                  position: 'sticky',
                                  right: 0,
                                  background: '#f4f6f8 !important',
                                  boxShadow: '-3px -3px 8px rgba(0, 0, 0, 0.1)',
                                  textAlign: 'center',
                                }
                                : { minWidth: column.minWidth, textAlign: 'center' }
                            }
                          >
                            {column.id === 'name' ||
                              column.id === 'roleBasedCometencies' ||
                              column.id === 'defaultBehavior'
                              ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={
                                    orderBy === column.id
                                      ? order === 'default'
                                        ? 'asc'
                                        : order
                                      : 'asc'
                                  }
                                  onClick={() => handleSort(column.id)}
                                >
                                  {column.label}
                                </TableSortLabel>
                              ) : (
                                column.label
                              )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedCandidates
                        .slice(
                          (page - 1) * rowsPerPage,
                          (page - 1) * rowsPerPage + rowsPerPage
                        )
                        .sort((a, b) => {
                          if (order === 'default') return 0; // No sorting, return the original order
                          if (orderBy === 'name') {
                            // Sort by name
                            if (order === 'asc') {
                              return a.name.name.localeCompare(b.name.name);
                            } else {
                              return b.name.name.localeCompare(a.name.name);
                            }
                          } else if (orderBy === 'roleBasedCometencies') {
                            // Sort by Role-based competencies
                            if (order === 'asc') {
                              return a.roleBasedCometencies.localeCompare(b.roleBasedCometencies);
                            } else {
                              return b.roleBasedCometencies.localeCompare(a.roleBasedCometencies);
                            }
                          } else if (orderBy === 'defaultBehavior') {
                            // Sort by Default behavior
                            if (order === 'asc') {
                              return a.defaultBehavior.props.text.localeCompare(b.defaultBehavior.props.text);
                            } else {
                              return b.defaultBehavior.props.text.localeCompare(a.defaultBehavior.props.text);
                            }
                          } else {
                            return 0; // No sorting for other columns
                          }
                        })
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role='checkbox'
                              tabIndex={-1}
                              key={index}
                            >
                              {columns.map((column) => {
                                let value;

                                if (column.id === 'name') {
                                  const nameData = row['name'];
                                  value = (
                                    <CreateAvatar
                                      src={nameData.src}
                                      name={
                                        nameData.name + ' ' + nameData.lastName
                                      }
                                      onClick={() => {
                                        console.log(row);
                                        setCandidProModal(row);
                                      }}
                                    />
                                  );
                                } else if (column.id === 'interviewScoreCard') {
                                  value = (
                                    <span className='flex items-center'>
                                      {row.interviewScoreCard}
                                      <EditOutlinedIcon
                                        className='text-[#828282] ml-2 cursor-pointer'
                                        onClick={() => {
                                          setInterviewScoreModal(true);
                                          setInterviewData(
                                            row.candidate_intreview_score_card
                                          );
                                        }}
                                      />
                                    </span>
                                  );
                                } else if (column.id === 'status') {
                                  value = (
                                    <CreateSelect
                                      defaultValue={row.status}
                                      onChange={(value) =>
                                        updateStatus(row._id, value)
                                      }
                                    />
                                  );
                                } else if (column.id === "actions") {
                                  value = (
                                    <IconButton
                                      // color="error"
                                      aria-label="delete"
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  );
                                } else {
                                  value = row[column.id];
                                }

                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    sx={
                                      column.id === 'status'
                                        ? {
                                          position: 'sticky',
                                          right: 0,
                                          background: '#ffffff !important',
                                          boxShadow:
                                            '-3px 3px 8px rgba(0, 0, 0, 0.1)',
                                        }
                                        : {}
                                    }
                                  >
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className='flex flex-wrap items-center justify-between'>
                  <SuccessBtn onClick={() => navigate('/business/team-chemistry')} customStyle={{ marginLeft: '20px' }}>
                    Check Team Chemistry
                  </SuccessBtn>
                  <div className='relative mr-5'>
                    <Pagination
                      count={
                        candidates.length < 10
                          ? 1
                          : Math.ceil(candidates.length / rowsPerPage)
                      }
                      page={page}
                      onChange={handleChange}
                      rowsPerPage={rowsPerPage}
                      className={classes.pagination}
                    />
                    <ArrowLeft
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: '48%',
                        transform: 'translateY(-50%)',
                        zIndex: 100,
                        opacity: page - 1 === 0 ? 0.5 : 1,
                      }}
                    />
                    <ArrowRight
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 100,
                        opacity:
                          page === Math.ceil(rows.length / rowsPerPage)
                            ? 0.5
                            : 1,
                      }}
                    />
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </WhiteBox>
        {/* Candidate Pool Tabel end */}

        {chartData && <BarCharts data={chartData} />}

        {counts && (
          <div className='grid 2xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mt-24'>
            <CondidateListCard
              title='Completed assessment'
              number={counts.completedAssessment}
              image={<CompletedAssessment />}
            />
            <CondidateListCard
              title='Total assessment'
              number={counts.totalAssessment}
              image={<TotalAssessment />}
            />
            <CondidateListCard
              title='Pending job requisitions'
              number={counts.pendingJobRequisitions}
              image={<PendingRequisitions />}
            />
            <CondidateListCard
              title='Open requisitions'
              number={counts.openRequisitions}
              image={<OpenRequisitions />}
            />
            <CondidateListCard
              title='Offers sent'
              number={counts.offersSent}
              image={<OffersSent />}
            />
          </div>
        )}
      </div>

      {/* modals start */}
      <CondidateProfileModal
        open={candidProModal}
        setOpen={setCandidProModal}
      />

      {interviewData && (
        <InterviewScoreModal
          open={interviewScoreModal}
          setOpen={setInterviewScoreModal}
          data={interviewData}
          setData={setInterviewData}
          getCandidates={() => getCandidates(selectedPool._id)}
        />
      )}
      {/* modals end */}
    </>
  );
}

export default CondidateList;
