import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { a11yProps, TabPanel, tabsStyle } from './reuseabelComponent';
import CreateJobPost from './createJob';
import AllJobs from './jobListing/tabs/AllJobs';
import DraftJobs from './jobListing/tabs/DraftJobs';
import { getHRJobPost } from '../../../../../../api/hr';
import WhiteBox from '../../../../sharedComponents/whiteBox/WhiteBox';

function JobRequisition() {
  const classes = tabsStyle();

  const [allJobs, setAllJobs] = React.useState([]);
  const [draftJobs, setDraftJobs] = React.useState([]);

  const getAllJobs = async () => {
    try {
      const res = await getHRJobPost();
      if (res?.data) {
        if (res.data?.job_post) {
          setAllJobs(
            res.data.job_post.filter((jobPost) => jobPost.is_draft === 'false')
          );
          setDraftJobs(
            res.data.job_post.filter((jobPost) => jobPost.is_draft === 'true')
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { state } = useLocation();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    getAllJobs();
  }, []);
  React.useEffect(() => {
    if (state?.tab) setValue(state.tab);
  }, [state]);
  return (
    <div className='mt-10'>
      <Tabs
        className={classes.root}
        value={value}
        onChange={handleChange}
        variant='scrollable'
        allowScrollButtonsMobile
        aria-label='basic tabs example'
        sx={{
          '& .MuiTabs-scroller': {
            borderRadius: '4px',
            color: 'white',
          },
          '&.MuiTabs-root': {
            '&.MuiTabs-scroller': {
              '&.MuiTabs-indicator': {
                backgroundColor: 'red !important',
              },
            },
          },
        }}
      >
        <Tab label='Create job' {...a11yProps(0)} className={classes.tab} />
        <Tab label='All jobs' {...a11yProps(1)} className={classes.tab} />
        <Tab label='Draft jobs' {...a11yProps(2)} className={classes.tab} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <CreateJobPost changeTab={setValue} getAllJobs={getAllJobs} />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <WhiteBox titleStyle={{ fontSize: '24px', fontWeight: '700' }}>
          <AllJobs jobPosts={allJobs} getAllJobs={getAllJobs} />
        </WhiteBox>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <WhiteBox titleStyle={{ fontSize: '24px', fontWeight: '700' }}>
          <DraftJobs
            changeTab={setValue}
            jobPosts={draftJobs}
            getAllJobs={getAllJobs}
          />
        </WhiteBox>
      </TabPanel>
    </div>
  );
}

export default JobRequisition;
