import React from "react";
import Modal from "../../../sharedComponents/modal/Modal";
import { Formik, Field, Form } from "formik";
import DangerLightBtn from "../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import cx from "classnames";
import moment from "moment";
import { Checkbox } from "@mui/material";

export default function ExperienceModalBody({
  values,
  isOpen,
  onSubmit,
  onClose,
  isNew,
}) {
  const {
    startDate,
    endDate,
    isPresent,
    companyName,
    role,
    experienceSummary,
    achievements,
  } = isNew ? {} : values || {};

  const validate = (values) => {
    const errors = {};
    
    if (!values.startDate) errors.startDate = true;
    if (!values.companyName) errors.companyName = true;
    if (!values.role) errors.role = true;
    if (values.startDate && values.endDate) {
      if (
        moment(values.startDate).isAfter(values.endDate) ||
        moment(values.startDate).isSame(values.endDate)
      )
        errors.date = true;
    }
  
    return errors;
  };

  return (
    <div>
      <Modal title={`${isNew ? 'Add' : 'Edit'} Work experience`} open={isOpen} setOpen={onClose} lgUpWidth={600}>
        <Formik
          initialValues={{
            startDate,
            endDate,
            isPresent,
            companyName,
            role,
            experienceSummary,
            achievements,
          }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={async (values) => {
            onSubmit(values);
          }}
        >
          {({ errors, values, setFieldValue}) => (
            <Form>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
              <div className="flex flex-col">
                <label htmlFor="startDate" className="text-base text-pirmaryBlue">
                  Start Date *
                </label>
                <Field
                  name="startDate"
                  type="date"
                  className={cx(
                    "focus:outline-none border-2 p-[12px] border-solid rounded",
                    { "border-red-500": errors.startDate || errors.date },
                    "border-grayLight"
                  )}
                />
                {errors.startDate && (
                  <span className="text-red-500">This is required field</span>
                )}
                {errors.date && (
                  <span className="text-red-500">start date should be less than end date</span>
                )}
              </div>
              <div className="flex flex-col">
                <label htmlFor="endDate" className="text-base text-pirmaryBlue">
                  End Date
                </label>
                <Field
                  name="endDate"
                  type="date"
                  disabled={values?.isPresent ? true : false}
                  className="focus:outline-none border-2 p-[12px] border-solid rounded border-grayLight"
                />
              </div>
              <div className="flex flex-col col-span-2">
                <div className="flex justify-start items-center">
                  <Checkbox
                    className=""
                    onChange={(e) =>
                      setFieldValue("isPresent", e.target.checked)
                    }
                    checked={values?.isPresent}
                    id="checkboxtick"
                    color="success"
                  />
                  <label
                    htmlFor="checkboxtick"
                    className="text-secondaryBalck text-base cursor-pointer"
                  >
                    Present
                  </label>
                </div>
              </div>
              <div className="flex flex-col col-span-2">
                <label htmlFor="companyName" className="text-base text-pirmaryBlue">
                  Company Name *
                </label>
                <Field
                  name="companyName"
                  type="text"
                  placeHolder="Enter Company Name"
                  className={cx(
                    "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                    { "border-red-500": errors.companyName },
                    "border-grayLight"
                  )}
                />
                {errors.companyName && (
                  <span className="text-red-500">This is required field</span>
                )}
              </div>
              <div className="flex flex-col col-span-2">
                <label htmlFor="role" className="text-base text-pirmaryBlue">
                  Role *
                </label>
                <Field
                  name="role"
                  type="text"
                  placeHolder="Enter Role"
                  className={cx(
                    "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                    { "border-red-500": errors.role },
                    "border-grayLight"
                  )}
                />
                {errors.role && (
                  <span className="text-red-500">This is required field</span>
                )}
              </div>
              <div className="flex flex-col col-span-2">
                <label htmlFor="experienceSummary" className="text-base text-pirmaryBlue">
                  Experience Summary
                </label>
                <Field
                  name="experienceSummary"
                  type="text"
                  placeHolder="Enter Experience Summary"
                  className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
                />
              </div>
              <div className="flex flex-col col-span-2">
                <label htmlFor="achievements" className="text-base text-pirmaryBlue">
                  Achievements
                </label>
                <Field name="achievements">
                  {({ field, props }) => (
                    <textarea
                      rows={4}
                      placeHolder="Enter Achievements"
                      className="focus:outline-none border-2 p-[12px] border-solid rounded border-grayLight"
                      {...props}
                      {...field}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className={`flex mt-3 ${!isNew ? 'justify-between' : 'justify-end'}`}>
              {!isNew && (
                <DangerLightBtn
                  type="button"
                  onClick={() => onSubmit()}
                >
                  Delete Experience
                </DangerLightBtn>
              )}
              <SuccessBtn
                type="submit"
              >
                {isNew ? "Add Experience" : "Update Experience"}
              </SuccessBtn>
            </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
