import { makeStyles } from '@mui/styles';

export const DatePickerStyles = makeStyles((theme) => {
  return {
    root: {
      borderRadius: "5px",
      "& *": {
        cursor: "pointer!important"
      },
      "& .MuiOutlinedInput-input": {
        borderRadius: "5px",
        height: "11px!important",
        color: "#828282!important"
      }
    }
  }
});