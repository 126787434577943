import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useStyle } from "../reusableComponents";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import WhiteBox from "../shared/WhiteBox";
import { updateAssesmentType } from "../../../../../redux/actions/steps";
import { postJCProfile } from "../../../../../api/jc";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function CandidateAssessment({ valueOrg }) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState({
    first_name: "",
    last_name: "",
    gender: "",
  });
  const [value, setValue] = React.useState(valueOrg);

  const handleChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const assesmentType = useSelector((state) => state.steps.assesmentType);
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (assesmentType === "") {
      toast.error("Please select an assessment type.");
      setError(true);
      return;
    }
    if (data.first_name === "") {
      toast.error("Please enter first name");
      setError(true);
      return;
    }
    if (data.last_name === "") {
      toast.error("Please enter last name");
      setError(true);
      return;
    }
    if (data.gender === "") {
      toast.error("Please select gender.");
      setError(true);
      return;
    }
    let fd = new FormData();
    fd.append(
      "body",
      JSON.stringify({ assessment_type: assesmentType, ...data })
    );
    postJCProfile(fd).then((res) => {
      let localStoreData = localStorage.getItem("accessData");
      localStoreData = localStoreData && JSON.parse(localStoreData);
      localStoreData = {
        ...localStoreData,
        firstNameAndLastName: {
          first_name: data.first_name,
          last_name: data.last_name,
          gender: data.gender,
        },
        assessmentType: {
          assessment_type: assesmentType,
          ai_report_id: getAiReportId(assesmentType),
        },
      };
      localStorage.setItem("accessData", JSON.stringify(localStoreData));
      navigate("/assessment/time", { state });
    });
  };

  useEffect(() => {
    let localStoreData = localStorage.getItem("accessData");
    if (![null, undefined].includes(localStoreData)) {
      localStoreData = localStoreData && JSON.parse(localStoreData);
      if (
        [null, undefined, ""].includes(localStoreData) &&
        typeof localStoreData.firstNameAndLastName === "object" &&
        !Array.isArray(localStoreData.firstNameAndLastName) &&
        localStoreData.firstNameAndLastName !== null
      )
        setData({
          ...data,
          ...localStoreData.firstNameAndLastName,
        });
      if (
        valueOrg === undefined &&
        localStoreData.assessmentType !== undefined &&
        localStoreData.assessmentType.assessment_type !== undefined
      ) {
        setValue(localStoreData.assessmentType.assessment_type);
        dispatch(
          updateAssesmentType(localStoreData.assessmentType.assessment_type)
        );
      }
    }
  }, []);

  const getAiReportId = (assesmentType) => {
    switch (assesmentType) {
      case "Leadership":
        return 209;
        // break;
      case "Management":
        return 64;
        // break;
      case "Sales":
        return 68;
        // break;
      case "Customer Service":
        return 65;
        // break;
      case "Information Technology":
        return 321;
        // break;
      case "Sales Management":
        return 87;
        // break;
      case "Financial Services":
        return 258;
        // break;
      default:
        return 208;
        // break;
    }
  };

  const classes = useStyle();
  return (
    <WhiteBox title="Select role-based competencies">
      <div className="">
        <p className="text-[#384259] mb-6 text-base font-[400]">
          Thank you for trusting us and signing up with TRIPA.  
        </p>

        <p className="text-[#384259] mb-6 text-base font-[400]">
          By taking our role-based competencies assessment, you will be in the top five percent of the global population, committed to achieving real success and fulfillment. By consistently seeking a higher self-awareness, you can align your career with your life purpose and unlock your full potential.
        </p>

        <p className="text-[#384259] mb-6 text-base font-[400]">
          Don't worry if you are unsure which category to choose - you can always pick the closest one to your desires and start your journey toward uncovering your Unique Selling Proposition (USP).   
        </p>

        <p className="text-[#384259] mb-6 text-base font-[400]">
          Don't settle for a mediocre career when you can have a fulfilling one. Take the first step today and unleash the greatness within you!
        </p>

        <form onSubmit={handleSubmit}>
          <FormControl sx={{ width: "100%" }} error={error} variant="standard">
            <RadioGroup
              aria-labelledby="demo-error-radios"
              name="quiz"
              value={value}
              key={value}
              onChange={(data) => {
                setValue(data.target.value);
                dispatch(updateAssesmentType(data.target.value));
              }}
            >
              <FormControlLabel
                value="Leadership"
                control={<Radio />}
                label="Leadership"
                className={classes.radioButton}
              />
              <FormControlLabel
                value="Management"
                control={<Radio />}
                label="Management"
                className={classes.radioButton}
              />
              <FormControlLabel
                value="Sales Management"
                control={<Radio />}
                label="Sales management"
                className={classes.radioButton}
              />
              <FormControlLabel
                value="Financial Services"
                control={<Radio />}
                label="Financial services"
                className={classes.radioButton}
              />
              <FormControlLabel
                value="Information Technology"
                control={<Radio />}
                label="Information technology"
                className={classes.radioButton}
              />
              <FormControlLabel
                value="Sales"
                control={<Radio />}
                label="Sales"
                className={classes.radioButton}
              />
              <FormControlLabel
                value="Customer Service"
                control={<Radio />}
                label="Customer services"
                className={classes.radioButton}
              />
            </RadioGroup>

            <h4 className="text-[22px] text-[#384259] font-bold mt-5">
              Basic Information
            </h4>
            <div className="flex flex-col md:flex-row justify-between mt-3">
              <div className="flex flex-col mb-4 w-full flex-1">
                <label
                  htmlFor="Coaching-emailInput"
                  className="text-pirmaryBlue"
                >
                  First Name *
                </label>
                <input
                  type="text"
                  name="first_name"
                  value={data.first_name || ""}
                  onChange={handleChange}
                  id="Coaching-emailInput"
                  placeholder="Enter your first name"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
              </div>
              <div className="flex flex-col mb-4 w-full flex-1 mx-0 md:mx-2">
                <label
                  htmlFor="Coaching-emailInput"
                  className="text-pirmaryBlue"
                >
                  Last Name *
                </label>
                <input
                  type="text"
                  name="last_name"
                  value={data.last_name || ""}
                  onChange={handleChange}
                  id="last_name"
                  placeholder="Enter your last name..."
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
              </div>
              <div className="flex flex-col mb-4 w-full flex-1">
                <label
                  htmlFor="Coaching-emailInput"
                  className="text-pirmaryBlue"
                >
                  Gender *
                </label>
                <select
                  name="gender"
                  id="gender"
                  className="focus:outline-none border-2 p-[12px] border-solid rounded border-grayLight"
                  value={data.gender}
                  onChange={handleChange}
                >
                  <option value="">Select option</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="Transgender">Transgender</option>
                  <option value="Non-binary">Non-binary</option>
                  <option value="Prefer not to answer">Prefer not to answer</option>
                </select>
              </div>
            </div>

            <div className="w-full flex items-center justify-end">
              <Button
                type="submit"
                variant="contained"
                color="success"
                className={classes.startTheStatmentBtn}
                endIcon={
                  <ArrowRightAltIcon
                    sx={{
                      "&.MuiSvgIcon-root": {
                        fontSize: "30px",
                        color: "white",
                      },
                    }}
                  />
                }
              >
                Start the Assesment
              </Button>
            </div>
          </FormControl>
        </form>
      </div>
    </WhiteBox>
  );
}

export default CandidateAssessment;
