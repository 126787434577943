import React from 'react';
import Modal from '../../../../../sharedComponents/modal/Modal';
import CondidateProfile from '../../../../../sharedComponents/candidateProfile/CondidateProfile';

function CondidateProfileModal({ open, setOpen }) {
  return (
    <Modal
      title='Candidate profile'
      open={open}
      setOpen={setOpen}
      mdUpWidth={1050}
      lgUpWidth={1150}
      xlUpWidth={1250}
    >
      {open && <CondidateProfile selectedCandidate={open} />}
    </Modal>
  );
}

export default CondidateProfileModal;
