import React from "react";

import { makeStyles } from "@mui/styles";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Avatar from "@mui/material/Avatar";
import condidate1 from "../../../../../assets/condidate1.svg";
import condidate2 from "../../../../../assets/condidate2.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SelectField from "../../../../sharedComponents/selectField/SelectField";
import TextTooltip from "../../../../sharedComponents/textTooltip/TextTooltip";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const useStyle = makeStyles({
  table: {
    "& .MuiTableRow-head": {
      "& .MuiTableCell-head": {
        background: "#F4F6F8",
        fontWeight: "700",
        color: "#384259",
        fontSize: "16px",
        padding: "10px 5px",
      },
    },
    "& .MuiTableBody-root": {
      "& .MuiTableCell-root": {
        padding: "10px",
      },
    },
  },
  pagination: {
    margin: "24px 0px 30px 0px",

    "& .MuiSvgIcon-fontSizeMedium": {
      zIndex: 200,
      background: "red",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      opacity: 0,
    },
    "& .MuiPaginationItem-page": {
      border: " 1px solid #E5E5E5",
      borderRadius: "0px",
    },
    "& .Mui-selected": {
      background: "#00000000 !important",
      borderRadius: "0px",
      border: "1px solid #7AB794",
      ":hover": {
        // backgroundColor: "transparent",
      },
    },
  },
  checkbox: {
    "& .Mui-checked": {
      color: "#7AB693 !important",
    },
    "& .MuiFormControlLabel-label": {
      color: "#828282",
    },
  },
});
export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const tabsStyle = makeStyles({
  root: {
    color: "#273164",
    textAlign: "left",
    display: "inline-block",
    marginBottom: "16px",

    "& .MuiTabs-flexContainer": {
      display: "inline-block",
    },
    "& .MuiBox-root": {
      padding: "0 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },
  tab: {
    fontSize: "12px !important",
    fontWeight: "400 !important",
    color: "#2C2C2C !important",
    border: "2px solid #E3E6E9 !important",
    margin: "0px 4px !important",
    "&.Mui-selected": {
      color: "#6DA183 !important",
      border: "2px solid #6DA183 !important",
      borderRadius: "2px",
    },
  },
});

export const CreateActionBtn = ({ editOnClick, deleteOnClick }) => {
  return (
    <span className="flex items-center">
      <ModeEditOutlineOutlinedIcon
        sx={{
          marginRight: "10px",
          background: "rgba(244, 246, 248, 0.61);",
          padding: "4px",
          width: "34px",
          height: "34px",
          borderRadius: "4px",
          color: "#828282",
          cursor: "pointer",
        }}
        onClick={editOnClick}
      />
      <DeleteOutlineOutlinedIcon
        sx={{
          background: "rgba(244, 246, 248, 0.61);",
          padding: "4px",
          width: "34px",
          height: "34px",
          borderRadius: "4px",
          color: "#828282",
          cursor: "pointer",
        }}
        onClick={deleteOnClick}
      />
    </span>
  );
};

export const CreateAvatar = ({ name, lastName, src, alt, onClick }) => {
  return (
    <span
      className="flex items-center text-base text-[#384259] cursor-pointer"
      onClick={onClick}
    >
      <Avatar src={src} alt={alt} sx={{ marginRight: "10px" }} /> {name}{" "}
      {lastName}
    </span>
  );
};

const CreateCheckbox = () => {
  const [checked, setChecked] = React.useState(false);

  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  const classes = useStyle();
  return (
    <>
      <FormGroup>
        <FormControlLabel
          checked={checked}
          onChange={handleCheckbox}
          control={<Checkbox />}
          name=""
          label=""
          inputProps={{ "aria-label": "controlled" }}
          className={classes.checkbox}
        />
      </FormGroup>
    </>
  );
};

const CreateColorfulText = ({ text, color }) => {
  return (
    <span style={{ color: color, fontSize: "16px", cursor: "pointer" }}>
      <TextTooltip
        tooltipTitle="Steady-Low"
        tooltipText="The role-based competencies display the list of capacities resulting from your decision-making style’s unique combination (personal, practical, and analytical). It helps you understand your strengths and weaknesses that enable you to make the necessary changes to achieve your desired level of success.  "
      >
        {text}
      </TextTooltip>
    </span>
  );
};

export const CreateSelect = ({ defaultValue = "", onChange }) => {
  const [value, setValue] = React.useState(defaultValue);
  const handleSelect = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };
  return (
    <SelectField
      selectItems={selectList}
      value={value}
      onChange={handleSelect}
      inputStyle={{ padding: "7px", minWidth: "180px" }}
    />
  );
};

const selectList = [
  "Offer Sent",
  "Offer Accepted",
  "Interview Sch...",
  "Reviewed",
];
const color = {
  green: "#6DA183",
  orange: "#F6AD55",
  blue: "#7ABEEF",
};

export const columns = [
  // { id: "checkbox", label: "", minWidth: 20, align: "center" },
  // { id: "srNo", label: "Sr.No.", minWidth: 20, align: "center" },
  { id: "name", label: "Name", minWidth: 180, align: "left" },
  {
    id: "gender",
    label: "Gender",
    minWidth: 90,
    align: "center",
  },
  {
    id: "company",
    label: "Company",
    minWidth: 100,
    align: "center",
  },
  {
    id: "tester",
    label: "Tester",
    minWidth: 100,
    align: "left",
  },
  {
    id: "assessmentStatus",
    label: "Assessment status",
    minWidth: 100,
    align: "center",
  },
  {
    id: "roleBasedCompetence",
    label: "Role-based competencies",
    minWidth: 120,
    align: "center",
  },
  {
    id: "competenceScore",
    label: "Competencies score",
    minWidth: 100,
    align: "center",
  },
  {
    id: "defaultBehaviour",
    label: "Default behavior",
    minWidth: 120,
    align: "center",
  },
  {
    id: "values",
    label: "Values",
    minWidth: 100,
    align: "center",
  },
  {
    id: "country",
    label: "Country",
    minWidth: 80,
    align: "center",
  },
  {
    id: "city",
    label: "City",
    minWidth: 80,
    align: "center",
  },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
    align: "center",
  },
];

export const rows = [
  {
    checkbox: <CreateCheckbox />,
    id: 1,
    srNo: 1,
    name: { name: "Robert", lastName: "Binny", src: condidate1 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.green} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 5,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 2,
    srNo: 2,
    name: { name: "Robert", lastName: "Binny", src: condidate2 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.blue} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 7,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 1,
    srNo: 1,
    name: { name: "Robert", lastName: "Binny", src: condidate1 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.green} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 5,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 2,
    srNo: 2,
    name: { name: "Robert", lastName: "Binny", src: condidate2 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.blue} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 7,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 1,
    srNo: 1,
    name: { name: "Robert", lastName: "Binny", src: condidate1 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.green} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 5,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 2,
    srNo: 2,
    name: { name: "Robert", lastName: "Binny", src: condidate2 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.blue} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 7,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 1,
    srNo: 1,
    name: { name: "Robert", lastName: "Binny", src: condidate1 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.green} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 5,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 2,
    srNo: 2,
    name: { name: "Robert", lastName: "Binny", src: condidate2 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.blue} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 7,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 1,
    srNo: 1,
    name: { name: "Robert", lastName: "Binny", src: condidate1 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.green} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 5,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 2,
    srNo: 2,
    name: { name: "Robert", lastName: "Binny", src: condidate2 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.blue} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 7,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 1,
    srNo: 1,
    name: { name: "Robert", lastName: "Binny", src: condidate1 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.green} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 5,
  },
  {
    checkbox: <CreateCheckbox />,
    id: 2,
    srNo: 2,
    name: { name: "Robert", lastName: "Binny", src: condidate2 },
    jobRequisitionID: 38,
    jobRequisition: "Java Developer",
    roleBasedCometencies: "Management",
    competenciesScore: "74.5%",
    defaultBehavior: (
      <CreateColorfulText text="Steady-Low" color={color.blue} />
    ),
    topValue: <CreateColorfulText text="Political" color={color.orange} />,
    skills: "3/10",
    interviewScoreCard: 7,
  },
];
