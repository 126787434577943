import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStyle } from '../../../../../JCComponents/settingsProfile/reusableComponent';
import { ScrollBoxWrapper } from '../../candidatePool/reuseableComponent';

export const competenciesList = [
  {
    value: 'Leadership',
    label: 'Leadership',
    children: [
      {
        value: 'Envision an outcome',
        label: 'Envision an outcome',
        color: '#4472c4',
        tooltip: `Leading begins with realizing and clearly envisioning the overall mission to accomplish. A mission is what is going to happen, not how. Realizing your mission leads to the understanding of where change is required and why it is needed now.`,
        subPoints: [
          {
            value: 'Conceptual Thinking',
            tooltip:
              'Evaluates Cristina’s ability to see the big picture and then to determine which direction to take, and how resources should be used to attain future goals.',
          },
          {
            value: 'Initiative',
            tooltip:
              'Evaluates Cristina’s ability to direct his energy toward the completion of a goal without an external catalyst.',
          },
          {
            value: 'Persistence',
            tooltip:
              'Evaluates Cristina’s ability to stay on course in times of difficulty.',
          },
          {
            value: 'Project and Goal Focus',
            tooltip:
              'Evaluates Cristina’s ability to stay on target regardless of circumstances.',
          },
          {
            value: 'Result Orientation',
            tooltip:
              'Evaluates Cristina’s ability to identify the actions necessary to complete tasks and to obtain results.',
          },
        ],
      },
      {
        value: 'Serving others',
        label: 'Serving others',
        color: '#ffc000',
        tooltip: `In order to engage people to follow you must clearly communicate your vision to them. The most powerful movements for change are created by people who have an emotional commitment to the mission and are passionate about it. Therefore you must be able to communicate with people not just through logical arguments, but in a way that touches them emotionally. You must first have that passion for your mission and to allow others to see your passion. It takes a certain amount of courage to champion a new idea, which by definition, others can't yet see.`,
        subPoints: [
          {
            value: 'Accountability for Others',
            tooltip:
              'Evaluates Cristina’s ability to be responsible for the consequences of the actions of those whom he manages.',
          },
          {
            value: 'Attitude Toward others',
            tooltip:
              'Evaluates Cristina’s ability to maintain a positive, open and objective attitude towards others.',
          },
          {
            value: 'Empathetic Outlook',
            tooltip:
              'Evaluates Cristina’s ability to perceive and understand the feelings and attitudes of others or to place himself in the shoes of another.',
          },
          {
            value: 'Evaluating Others',
            tooltip:
              'Evaluates Cristina’s ability to make realistic and accurate judgments about another, to evaluate his strengths and weaknesses, and to understand his manner of thinking, acting, and behaving.',
          },
        ],
      },
      {
        value: 'Inspiring others',
        label: 'Inspiring others',
        color: '#5b9bd5',
        tooltip: `In order to understand, motivate and lead others, you must first understand yourself. As Chris McCusker, Chairman and CEO of Motorola once said, "Leadership is going first in a new direction - and being followed". So before a person can lead others they must lead the way. This applies to helping people become better. Leaders must practice what they preach and be able to see and develop themselves before they can do so for others.`,
        subPoints: [
          {
            value: 'Developing others',
            tooltip:
              'Evaluates Cristina’s ability to understand the needs, interests, strengths and weaknesses of others, and to use effectively this information for the purposes of developing others.',
          },
          {
            value: 'Gaining Commitment',
            tooltip:
              'Evaluates Cristina’s ability to develop and invoke a self motivating attitude in his employees or coworkers in their pursuit of their goals.',
          },
          {
            value: 'Leading Others',
            tooltip:
              'Evaluates Cristina’s ability to organize and to motivate people into getting things accomplished in a way that makes everyone feel a sense of order and direction.',
          },
          {
            value: 'Long Range Planning',
            tooltip:
              'Evaluates Cristina’s ability to identify and to evaluate resources and to plan for their utilization throughout the execution of comprehensive, longrange projects',
          },
          {
            value: 'Persuading Others',
            tooltip:
              'Evaluates Cristina’s ability to present his viewpoint in such a way that it is accepted by others.',
          },
        ],
      },
      {
        value: 'Guiding others',
        label: 'Guiding others',
        color: '#70ad47',
        tooltip: `In taking action and moving toward completion of your mission and vision, there will inevitably be surprises and unexpected results. A person skilled in leading will continually assess the plan for achieving the stated goals and make course corrections along the way. Part of this process is to test, in the real world, the initial assumptions that were made. Leading requires a focus on the milestones along the way, not only on the long-term mission. Followers require some indication that they are on the right track and this builds confidence in the leader. A leader requires great courage and character to be tenacious about moving forward, and not exhibiting loss of confidence, even in the face of disappointment.`,
        subPoints: [
          {
            value: 'Flexibility',
            tooltip:
              'Evaluates Cristina’s ability to readily integrate, modify, and respond to changes with minimal personal resistance.',
          },
          {
            value: 'Practical Thinking',
            tooltip:
              'Evaluates Cristina’s ability to realistically identify problems and solutions in practical terms, rather than in theoretical or conceptual terms.',
          },
          {
            value: 'Proactive Thinking',
            tooltip:
              'Evaluates Cristina’s ability to determine the future implications of current decisions and actions.',
          },
          {
            value: 'Self Control',
            tooltip:
              'Evaluates Cristina’s ability to remain calm, rational and objective internally when faced with an external stressful and emotional situation.',
          },
        ],
      },
      {
        value: 'Developing yourself',
        label: 'Developing yourself',
        color: '#44546a',
        tooltip: `Understanding how your vision satisfies peoples\` perceived needs is crucial to engage them. You must know what changes others are receptive to and ready for. Listening carefully and objectively will insure that your mission is one that others will embrace. Without followers, you can't be a leader, and followers will only voluntarily engage in something they think satisfies their needs as well as your goals.`,
        subPoints: [
          {
            value: 'Role Awareness',
            tooltip:
              'Evaluates Cristina’s ability to be aware of his role in the world or within a given environment as well as understanding expectations and how those expectations are to be met.',
          },
          {
            value: 'Self Assessment',
            tooltip:
              'Evaluates Cristina’s s ability to identify his personal management strengths and weaknesses practically and objectively.',
          },
          {
            value: 'Self Confidence',
            tooltip:
              'Evaluates Cristina’s ability to develop and to maintain inner strength based on the desire to succeed and on his belief that he possesses the capabilities to succeed.',
          },
          {
            value: 'Self Direction',
            tooltip:
              'Evaluates Cristina’s internal drive to excel in and believe in his chosen career path.',
          },
        ],
      },
      {
        value: 'Communicating your vision',
        label: 'Communicating your vision',
        color: '#a5a5a5',
        tooltip: `People will not chase a difficult dream for very long unless they think it supports their own personal goals. You must insure that people connect both your vision and your actions with their own goals. They initially chose to follow you because they thought that by helping you they would help themselves. Now that they are engaged you must work at reinforcing the initial faith they placed in you.`,
        subPoints: [
          {
            value: 'Convery Role Value',
            tooltip:
              'Evaluates Cristina’s ability to use his own capacities (for empathy, interpersonal relationships, and leadership) to instill, in an employee, a sense of value for the task at hand.',
          },
          {
            value: 'Personal Commitment',
            tooltip:
              'Evaluates Cristina’s s ability to focus and to stay committed to a task: a measure of his internal, personal commitment.',
          },
          {
            value: 'Self Confidence',
            tooltip:
              'Evaluates Cristina’s ability to develop and to maintain inner strength based on the desire to succeed and on his belief that he possesses the capabilities to succeed.',
          },
          {
            value: 'Self Direction',
            tooltip:
              'Evaluates Cristina’s internal drive to excel in and believe in his chosen career path.',
          },
          {
            value: 'Self Esteem',
            tooltip:
              'Evaluates Cristina’s ability to realize and appreciate his own unique self worth.',
          },
        ],
      },
      {
        value: 'Understanding your supporters',
        label: 'Understanding your supporters',
        color: '#ed7d31',
        tooltip: `Embarking on difficult and uncertain journeys requires a special kind of energy in order to continue for the long term. Inspiration draws forth that special energy that can only come from the individual. Therefore, leading others for the long term requires that you are able to recognize and bring this energy. People become inspired when they start believing they have more ability than they thought they did. Therefore, leading includes challenging people to do more than they have before, and empowering them to make efforts that will yield a positive result. Sharing hope and courage will keep people motivated to continue on with the mission, even when it seems like the goal is still a long way off.`,
        subPoints: [
          {
            value: 'Empathetic Outlook',
            tooltip:
              'Evaluates Cristina’s capacity to perceive and understand the feelings and attitudes of others or to place himself in the shoes of another.',
          },
          {
            value: 'Evaluating Whatiss Said',
            tooltip:
              'Evaluates Cristina’s openness toward other people and his willingness to hear what others are saying, rather than what he thinks they should say or they are going to say.',
          },
          {
            value: 'Realistic Expectations',
            tooltip:
              'Evaluates whether Cristina’s expectations (in either quality of production or quality of performance) of others can realistically be met',
          },
          {
            value: 'Understanding Attitude',
            tooltip:
              'Evaluates Cristina’s ability to read between the lines and to understand body language, reticence, stress, and emotions.',
          },
        ],
      },
    ],
  },
  {
    value: 'Management',
    label: 'Management',
    children: [
      {
        value: 'Human resource management',
        label: 'Human resource management',
        color: '#4472c4',
        tooltip: `Leading begins with realizing and clearly envisioning the overall mission to accomplish. A mission is what is going to happen, not how. Realizing your mission leads to the understanding of where change is required and why it is needed now.`,
        subPoints: [
          {
            value: 'Evaluating Others',
            tooltip:
              "Evaluates Marek's ability to make realistic and accurate judgments strengths and weaknesses, and to understand his manner of thinking,",
          },
          {
            value: 'Correcting Others',
            tooltip:
              "Evaluates Marek's ability to confront controversial or difficult issues in non-emotional discussions about disciplinary matters",
          },
          {
            value: 'Developing Others',
            tooltip:
              "Evaluates Marek's ability to understand the needs, interests, strengths and weaknesses of others, and to use effectively this information for the purposes of developing others.<br />",
          },
          {
            value: 'Leading Others',
            tooltip:
              "Evaluates Marek's ability to organize and to motivate people into getting things accomplished in a way that makes everyone feel a sense of order and direction.",
          },
          {
            value: 'Realistic Goal Setting For Others',
            tooltip:
              "Evaluates Marek's ability to set goals for others that can be achieved by using the available resources and operating within a projected timeframe.",
          },
        ],
      },
      {
        value: 'Personal motivators',
        label: 'Personal motivators',
        color: '#ed7d31',
        tooltip: `In order to engage people to follow you must clearly communicate your vision to them. The most powerful movements for change are created by people who have an emotional commitment to the mission and are passionate about it. Therefore you must be able to communicate with people not just through logical arguments, but in a way that touches them emotionally. You must first have that passion for your mission and to allow others to see your passion. It takes a certain amount of courage to champion a new idea, which by definition, others can't yet see.`,
        subPoints: [
          {
            value: 'Status and Recognition',
            tooltip:
              'Evaluates the importance for Marek of social status and recognition.',
          },
          {
            value: 'Sense of Mission',
            tooltip:
              'Evaluates the importance and commitment Marek gives to his ideals and goals.',
          },
          {
            value: 'Sense of Belonging',
            tooltip:
              "Evaluates the importance of feeling like part of a team or a member of a group for Marek's motivation.",
          },
          {
            value: 'Self Improvement',
            tooltip: "Evaluates Marek's motivation to improve himself.",
          },
          {
            value: 'Personal Relationships',
            tooltip:
              'Evaluates how motivated Marek is in forming personal relationships with the people with whom he works.',
          },
          {
            value: 'Material Possessions',
            tooltip:
              "Evaluates the importance of money or material possessions in Marek's motivation.",
          },
        ],
      },
      {
        value: 'Performance management',
        label: 'Performance management',
        color: '#a5a5a5',
        tooltip: `In order to understand, motivate and lead others, you must first understand yourself. As Chris McCusker, Chairman and CEO of Motorola once said, "Leadership is going first in a new direction - and being followed". So before a person can lead others they must lead the way. This applies to helping people become better. Leaders must practice what they preach and be able to see and develop themselves before they can do so for others.`,
        subPoints: [
          {
            value: 'Human Awareness',
            tooltip:
              "Evaluates Marek's ability to be conscious of the feelings and opinions of others; and to value others as people instead of just as their organizational role or value.",
          },
          {
            value: 'Conveying Role Value',
            tooltip:
              "Evaluates Marek's ability to use his own capacities (for empathy, interpersonal relationships, and leadership) to instill, in an employee, a sense of value for the task at hand.",
          },
          {
            value: 'Gaining Commitment',
            tooltip:
              "Evaluates Marek's ability to develop and invoke a self motivating attitude in his employees or coworkers in their pursuit of their goals..",
          },
          {
            value: 'Understanding Motivational Needs',
            tooltip:
              "Evaluates Marek's ability to understand the needs and desires of employees and to use this knowledge to motivate them to succeed",
          },
        ],
      },
      {
        value: 'Planning & organizing',
        label: 'Planning & organizing',
        color: '#ffc000',
        tooltip: `In taking action and moving toward completion of your mission and vision, there will inevitably be surprises and unexpected results. A person skilled in leading will continually assess the plan for achieving the stated goals and make course corrections along the way. Part of this process is to test, in the real world, the initial assumptions that were made. Leading requires a focus on the milestones along the way, not only on the long-term mission. Followers require some indication that they are on the right track and this builds confidence in the leader. A leader requires great courage and character to be tenacious about moving forward, and not exhibiting loss of confidence, even in the face of disappointment.`,
        subPoints: [
          {
            value: 'Conceptual Thinking',
            tooltip:
              "Evaluates Marek's ability to see the big picture and then to determine which direction to take, and how resources should be used to attain future goals.",
          },
          {
            value: 'Concrete Organization',
            tooltip:
              "Evaluates Marek's understanding of the immediate, concrete needs of a situation and to establish an effective plan of action for meeting those needs.",
          },
          {
            value: 'Long Range Planning',
            tooltip:
              "Evaluates Marek's ability to identify and to evaluate resources and to plan for their utilization throughout the execution of comprehensive, long-range projects.",
          },
          {
            value: 'Proactive Thinking',
            tooltip:
              "Evaluates Marek's ability to determine the future implications of current decisions and actions.",
          },
        ],
      },
      {
        value: 'Production management',
        label: 'Production management',
        color: '#5b9bd5',
        tooltip: `Understanding how your vision satisfies peoples\` perceived needs is crucial to engage them. You must know what changes others are receptive to and ready for. Listening carefully and objectively will insure that your mission is one that others will embrace. Without followers, you can't be a leader, and followers will only voluntarily engage in something they think satisfies their needs as well as your goals.`,
        subPoints: [
          {
            value: 'Project Scheduling',
            tooltip:
              "Evaluates Marek's ability to understand the proper allocation of resources for the purpose of getting things done within a defined timeframe.",
          },
          {
            value: 'Problem and Situation Analysis',
            tooltip:
              "Evaluates Marek's ability to identify the elements of a problematic situation and to understand which components are critical.",
          },
          {
            value: 'Problem Solving',
            tooltip:
              "Evaluates Marek's ability to identify alternative solutions to a problem and to select the best option.",
          },
          {
            value: 'Quality Orientation',
            tooltip:
              "Evaluates Marek's affinity for seeing details, grading them against a preset standard (either his own or externally assigned), and identifying flaws",
          },
          {
            value: 'Problem Management',
            tooltip:
              "Evaluates Marek's ability to keep critical issues in context so that he can understand what is happening and effectively use available resources to solve the problem.",
          },
          {
            value: 'Results Orientation',
            tooltip:
              "Evaluates Marek's ability to identify the actions necessary to complete tasks and to obtain results.",
          },
        ],
      },
      {
        value: 'Self management',
        label: 'Self management',
        color: '#70ad47',
        tooltip: `People will not chase a difficult dream for very long unless they think it supports their own personal goals. You must insure that people connect both your vision and your actions with their own goals. They initially chose to follow you because they thought that by helping you they would help themselves. Now that they are engaged you must work at reinforcing the initial faith they placed in you.`,
        subPoints: [
          {
            value: 'Self Confidence',
            tooltip:
              "Evaluates Marek's ability to develop and to maintain inner strength based on the desire to succeed and on his belief that he possesses the capabilities to succeed.",
          },
          {
            value: 'Self Assessment',
            tooltip:
              "Evaluates Marek's ability to identify his personal management strengths and weaknesses practically and objectively.",
          },
          {
            value: 'Personal Accountability',
            tooltip:
              "Evaluates Marek's ability to be responsible for the consequences of his own decisions and actions, and not shift the focus or blame for poor performance to somewhere else or on others.",
          },
          {
            value: 'Self Control',
            tooltip:
              "Evaluates Marek's ability to remain calm, rational and objective internally when faced with an external stressful and emotional situation.",
          },
          {
            value: 'Handling Stress',
            tooltip:
              "Evaluates Marek's ability to balance and to defuse inner tensions and stresses, which if allowed to build up, might interfere with his ability to perform up to his potential.",
          },
          {
            value: 'Self Discipline and Sense of Duty',
            tooltip:
              'Evaluates how strongly Marek feels the need to be consistent and true to himself in his actions.',
          },
          {
            value: 'Realistic Personal Goal Setting',
            tooltip:
              "Evaluates Marek's ability to set goals for himself that can be achieved using available resources and operating within a projected timeframe",
          },
        ],
      },
    ],
  },
  {
    value: 'Sales Management',
    label: 'Sales Management',
    children: [
      {
        value: 'Developing talent',
        label: 'Developing talent',
      },
      {
        value: 'Finding Talent',
        label: 'Finding Talent',
      },
      {
        value: 'Goal Achivement',
        label: 'Goal Achivement',
      },
      {
        value: 'Guiding Vision',
        label: 'Guiding Vision',
      },
      {
        value: 'Leading Talent',
        label: 'Leading Talent',
      },
      {
        value: 'Rough Water Navigation',
        label: 'Rough Water Navigation',
      },
      {
        value: 'Strategic Planning',
        label: 'Strategic Planning',
      },
    ],
  },
  {
    value: 'Financial Services',
    label: 'Financial Services',
    children: [
      {
        value: 'Critical Attributes for Sales',
        label: 'Critical Attributes for Sales',
      },
      {
        value: 'Scope of Goals',
        label: 'Scope of Goals',
      },
      {
        value: 'Integrity and Trust',
        label: 'Integrity and Trust',
      },
      {
        value: 'Planning and Organization',
        label: 'Planning and Organization',
      },
      {
        value: 'Capacity for Problem Solving',
        label: 'Capacity for Problem Solving',
      },
      {
        value: 'Ability to Start Projects',
        label: 'Ability to Start Projects',
      },
    ],
  },
  {
    value: 'Information Technology',
    label: 'Information Technology',
    children: [
      {
        value: 'Collaboration Relations',
        label: 'Collaboration Relations',
      },
      {
        value: 'Communication Skills',
        label: 'Communication Skills',
      },
      {
        value: 'Personal Motivators',
        label: 'Personal Motivators',
      },
      {
        value: 'Self-awareness',
        label: 'Self-awareness',
      },
      {
        value: 'Self-direction',
        label: 'Self-direction',
      },
      {
        value: 'Social Conscience',
        label: 'Social Conscience',
      },
      {
        value: 'Social Skills',
        label: 'Social Skills',
      },
    ],
  },
  {
    value: 'Sales',
    label: 'Sales',
    children: [
      {
        value: 'Prospect',
        label: 'Prospect',
      },
      {
        value: 'Greet',
        label: 'Greet',
      },
      {
        value: 'Qualify',
        label: 'Qualify',
      },
      {
        value: 'Show',
        label: 'Show',
      },
      {
        value: 'Influence',
        label: 'Influence',
      },
      {
        value: 'Close',
        label: 'Close',
      },
    ],
  },
  {
    value: 'Customer Service',
    label: 'Customer Service',
    children: [
      {
        value: 'Communication with Customer',
        label: 'Communication with Customer',
      },
      {
        value: 'Personal Work Attitude',
        label: 'Personal Work Attitude',
      },
      {
        value: 'Problem Solving Capacity',
        label: 'Problem Solving Capacity',
      },
      {
        value: 'Relation With Others',
        label: 'Relation With Others',
      },
      {
        value: 'Work Ethic',
        label: 'Work Ethic',
      },
    ],
  },
];

export const otherCompetenciesList = [
  'Theoretical Problem Solving',
  'Concrete Organization',
  'Conceptual Thinking',
  'Intuitive Decision Making',
  'Attention To Detail',
  'Consistency and Reliability',
  'Personal Accountability',
  'Accountability For Others',
  'Project and Goal Focus',
  'Personal Commitment',
  'Results Orientation',
  'Surrendering Control',
  'Balanced Decision Making',
  'Attitude Toward Others',
  'Freedom From Prejudices',
  'Realistic Expectations',
  'Self Confidence',
  'Self-Assessment',
  'Personal Drive',
  'Handling Stress',
  'Self-Discipline and Sense of Duty',
  'Realistic Personal Goal Setting',
  'Evaluating Others',
  'Correcting Others',
  'Developing Others',
  'Leading Others',
  'Realistic Goal Setting For Others',
  'Project Scheduling',
  'Problem and Situation Analysis',
  'Problem Solving',
  'Quality Orientation',
  'Problem Management',
  'Human Awareness',
  'Conveying Role Value',
  'Gaining Commitment',
  'Understanding Motivational Needs',
  'Long Range Planning',
  'Proactive Thinking',
  'Handling Rejection',
  'Meeting Standards',
  'Respect For Policies',
  'Job Ethic',
  'Integrative Ability',
  'Using Common Sense',
  'Seeing Potential Problems',
  'Emotional Control',
  'Persuading Others',
  'Relating To Others',
  'Self Esteem',
  'Role Confidence',
  'Self-Control',
  'Sensitivity To Others',
  'Initiative',
  'Evaluating What Is Said',
  'Sense of Timing',
  'Understanding Attitude',
  'Persistence',
  'Respect For Property',
  'Empathetic Outlook',
  'Monitoring Others',
  'Self-Direction',
  'Systems Judgment',
  'Status and Recognition',
  'Sense of Mission',
  'Sense of Belonging',
  'Self-Improvement',
  'Personal Relationships',
  'Material Possessions',
  'Attitude Toward Honesty',
  'Role Awareness',
  'Enjoyment Of The Job',
  'Self-Starting Ability',
  'Self-Management',
  'Practical Thinking',
  'Creativity',
  'Diplomacy',
  'Flexibility',
];

const ListCompetencies = ({ competencies, assessment, roleBaseData }) => {
  const classes = useStyle();
  // const [progress, setProgress] = useState(70);
  const [list, setList] = useState([]);
  const [showSubCompetencies, setShowSubCompetencies] = useState(false);
  const [selectedSubCompetency, setSelectedSubCompetency] = useState(null);
  const [colorIndex, setColorIndex] = useState(1);

  let roleColors = [
    "#9B7210",
    "#CA902C",
    "#AA554C",
    "#4FD1C5",
    "#7ABEEF",
    "#F6AD55",
    "#8B5086",
    "#7AB794",
  ];

  const subCompetenciesRender = (values, index) => {
    setShowSubCompetencies(values);
    setSelectedSubCompetency(values?.title);
    setColorIndex(index)
    let element = document.getElementById('scrollTop');
    element.scrollTo(0, 0);
  };

  useEffect(() => {
    setShowSubCompetencies(list[0]);
  }, [list]);

  useEffect(() => {
    let allCompetencies = [];
    
    let list = competenciesList.filter((currentValue) => {
      return currentValue.label === assessment;
    });

    list[0]?.children?.forEach((data, id) =>
      allCompetencies.push({
        key: id,
        label: data.value,
        value: competencies[id]?.value,
        tooltip: data.tooltip,
        color: data.color,
        subValues: data?.subPoints ? data.subPoints : [],
      })
    );
    setList(allCompetencies);
    setSelectedSubCompetency(allCompetencies[0]?.label);
  }, [competencies]);
  return (
    <>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-6">
        {/* <ScrollBoxWrapper title={`Role-based competencies: ${assessment || ''}`} TooltipTitle={"Role-based competencies (and sub-competencies description)"} TooltipText={"List the role type competencies and their respective sub-competencies that ensure the candidate has the ability to do the job and growth potential."}> */}
        <div className="h-auto max-h-[150px]  overflow-auto ">
          <div className="mr-[24px] min-h-full ">
            {roleBaseData?.length > 0 &&
              roleBaseData.map((data, i) => (
                <div
                  className='flex flex-col cursor-pointer'
                  id={i}
                  onClick={() => subCompetenciesRender(data, i)}
                >
                  <div
                    className={`flex justify-between ${i === 0 ? 'pb-3' : 'py-3'}`}
                  >
                    <span className='text-base text-pirmaryBlue'>{data.title}</span>
                    <span className='text-base font-bold text-pirmaryBlue'>
                      {data.value}%
                    </span>
                  </div>
                  <div className='-mt-2'>
                    <LinearProgress
                      className={classes.progressbar}
                      variant='determinate'
                      value={data.value}
                      sx={{
                        '& .MuiLinearProgress-barColorPrimary': {
                          backgroundColor: `${roleColors[i]} !important`,
                        },
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* </ScrollBoxWrapper> */}
        <div className="h-auto max-h-[150px]  overflow-auto ">
          <div className="mr-[24px] min-h-full ">
            <SubCompetenciesList
              show={showSubCompetencies}
              selectedSubCompetency={selectedSubCompetency}
              colorIndex={colorIndex}
              roleBaseData={roleBaseData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const SubCompetenciesList = ({
  show = false,
  selectedSubCompetency = '',
  colorIndex,
  roleBaseData
}) => {
  const classes = useStyle();
  const [color, setColor] = useState();
  const [subList, setSubList] = useState([]);
  
  let roleColors = [
    "#9B7210",
    "#CA902C",
    "#AA554C",
    "#4FD1C5",
    "#7ABEEF",
    "#F6AD55",
    "#8B5086",
    "#7AB794",
  ];

  useEffect(() => {
    if (show) {
      setColor(show?.color);
      setSubList(show?.subRoleCompetencies);
    }
    if(roleBaseData && !show?.subRoleCompetencies){
      setSubList(roleBaseData[0]?.subRoleCompetencies);
    }
  }, [show]);

  return (<>
      <h5 className={`text-[16px] font-bold text-center`} style={{ color: roleColors[colorIndex] }}>
        {`${show?.value || 0}% : ${selectedSubCompetency}`}
      </h5>
      {subList?.length > 0 &&
        subList.map((data, index) => (
      <div className='flex flex-col' key={index}>
        <div className='flex justify-between py-3'>
          <span className='text-base text-pirmaryBlue'>
            {data?.title || ''}
          </span>
          <span className='text-base font-bold text-pirmaryBlue'>
            {data?.value}%
          </span>
        </div>
        <div className='-mt-2'>
          <LinearProgress
            className={classes.progressbar}
            variant='determinate'
            value={data?.value}
            sx={{
              '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: `${roleColors[colorIndex]} !important`,
              },
            }}
          />
        </div>
      </div>
      ))}
    </>
  );
};
export default ListCompetencies;

export const RandomValues = (total, numberOfValues) => {
  let arr = [];
  let number = total;
  let odd = number % numberOfValues;
  number = number - odd;
  number = number / numberOfValues;
  for (let i = 0; i < numberOfValues; i++) {
    arr.push(number);
  }
  arr[0] = arr[0] + odd;
  return arr;
};
