import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  root: {},
  completeSignupBtn: {
    "&.MuiButton-containedSuccess": {
      backgroundColor: "#7AB794 !important",
      fontSize: "16px",
      marginTop: "25px",
    },
  },
}));
