import React from 'react';

import Modal from '../../../../../sharedComponents/modal/Modal';
import { InfoBoxes } from '../reuseableComponent';
import SelectField from '../../../../../sharedComponents/selectField/SelectField';
import SuccessBtn from '../../../../../sharedComponents/buttons/successBtn/SuccessBtn';
import { postHRPool } from '../../../../../../../api/hr';
import datas from "../../../../../../contants/cities.json"

function AddCandidate({ open, setOpen, jobReq, getAllPools, getJobIds }) {
  const [jobIds, setJobIds] = React.useState([]);
  const [data, setData] = React.useState({
    candidate_pool_name: '',
    city: '',
    country: '',
    job_requisition: '',
    job_id: '',
  });

  const handleChangeData = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((prevData) => ({ ...prevData, [name]: value }));
    if (name === 'job_requisition') {
      const allIds = await getJobIds(value);
      setJobIds(allIds);
    }
  };

  const handleSubmit = async () => {
    try {
      await postHRPool(data);
      getAllPools();
      setData({
        candidate_pool_name: '',
        city: '',
        country: '',
        job_requisition: '',
        job_id: '',
      });
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const countries = Object.keys(datas);
  const cities = data.country ? datas[data.country] : [];

  return (
    <Modal title='Add candidate pool' open={open} setOpen={setOpen}>
      <div className='flex flex-col my-4'>
        <label htmlFor='candidate_pool_name' className='text-pirmaryBlue'>
          <InfoBoxes title='Pool name' TooltipText='Please write a name for your candidates database that has the potential to meet your business`s immediate and long-term needs.' TooltipTitle='Pool name' />
        </label>
        <input
          type='text'
          name='candidate_pool_name'
          value={data.candidate_pool_name || ''}
          onChange={handleChangeData}
          id='candidate_pool_name'
          placeholder='Pool Name'
          className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
        />
      </div>
      
      <div className='flex flex-col my-4 '>
        <label htmlFor='country' className='text-pirmaryBlue'>
          <InfoBoxes title='Country' TooltipText='Please write the name of the country where the organizational unit or business is located.' TooltipTitle='Country' />
        </label>
        <SelectField
          selectItems={countries}
          label=''
          labelId='country'
          id='country'
          value={data.country}
          onChange={handleChangeData}
          name='country'
          placeholder='Select country'
          inputStyle={{ padding: '14px' }}
        />
      </div>

      <div className='flex flex-col my-4 '>
        <label htmlFor='city' className='text-pirmaryBlue'>
          <InfoBoxes title='City' TooltipText='Please write the name of the city where the organizational unit or business is located.' TooltipTitle='City' />
        </label>
        <SelectField
          selectItems={cities}
          label=''
          labelId='city'
          id='city'
          value={data.city}
          onChange={handleChangeData}
          name='city'
          placeholder='Select city'
          inputStyle={{ padding: '14px' }}
          disabled={cities?.length > 0 ? false : true}
        />
      </div>
      
      <div className='flex flex-col my-4 '>
        <label htmlFor='job_requisition' className='text-pirmaryBlue'>
          <InfoBoxes title='Job requisition' TooltipText='Please write the name of the job description request or the new position you would like to advertise.TRIPA will use the job requisition to promote the open vacancy.' TooltipTitle='Job requisition' />
        </label>
        <SelectField
          selectItems={jobReq || []}
          label=''
          labelId='job_requisition'
          id='job_requisition'
          value={data.job_requisition}
          onChange={handleChangeData}
          name='job_requisition'
          placeholder='Select'
          inputStyle={{ padding: '14px' }}
        />
      </div>
      <div className='flex flex-col my-4 '>
        <label htmlFor='job_id' className='text-pirmaryBlue'>
          <InfoBoxes title='Job id' TooltipText='Please give or enter the unique numeric identification code set by HR, the hiring manager, or for financial records.' TooltipTitle='Job id' />
        </label>
        <SelectField
          selectItems={jobIds}
          label=''
          labelId='job_id'
          id='job_id'
          value={data.job_id}
          onChange={handleChangeData}
          name='job_id'
          placeholder='Select'
          inputStyle={{ padding: '14px' }}
        />
      </div>
      <div className='flex items-center justify-end'>
        <SuccessBtn onClick={handleSubmit}>Add candidate pool</SuccessBtn>
      </div>
    </Modal>
  );
}

export default AddCandidate;
