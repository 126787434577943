import React from "react";

function Slide3() {
  return (
    <>
      <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
        Components
      </h5>
      <p className="text-base text-pirmaryBlue  mb-4">
        The two components measured in this report are clarity/understanding and bias/attention.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        <span className="font-bold">Clarity/understanding</span> refers to your ability to see and understand your thinking style's different areas. In contrast, <span className="font-bold">bias/attention</span> refers to the importance and value you apply to the other areas of your thinking style as you prepare to decide.
      </p>
    </>
  );
}

export default Slide3;
