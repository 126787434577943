import React from "react";
import BoxDescriptionInfo from "../../behavioralTraits/sharedcomponents/boxDescriptionInfo";
import { ReactComponent as Regulatory } from "../../../../assets/Regulatory.svg";
import { useSelector } from "react-redux";
import getTitle from "../reusableComponent";

// const list1 = [
//   "You prefer documenting activity and like lists.",
//   "You tend to be highly organized.",
//   `You are likely to have your own specific "way" of doing many things.`,
//   "You prefer routine and order.",
//   "You respect tradition a lot.",
// ];
// const list2 = [
//   "Ensure adequate information, resources and time to complete tasks.",
//   "You should make sure the reasons behind instructions are clearly understood.",
//   "You should avoid disrupting your schedules and flow unnecessarily.",
//   "You prefer detailed, written and specific guidelines to follow.",
//   "In order to change the established way of doing things, it has to be justified by a significant need.",
// ];
// const list3 = [
//   "You provide a sound stabilizing base for dynamic situations.",
//   "You are reliable and dependable.",
//   "You maintain focus throughout projects.",
//   "You have high attention to details.",
//   "You produce detailed and accurate work.",
// ];
// const list4 = [
//   "You will prefer learning activities that are structured and detailed..",
//   "You are a well disciplined learner.",
//   "You like to understand the why behind the what when learning new things.",
// ];
// const list5 = [
//   "You should be consistent in enforcing rules for everyone.",
//   "You should try being a little more flexible.",
//   "You should realize that others have their own way of doing things too and be open to that.",
//   "You should put things in writing.",
//   "You should realize that change can be good, productive and needed.",
// ];

function RegulatoryTab() {
  const data = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.valueDescriptor?.find(({ section }) => section === 'reg')?.subsection || []
  );

  const userLevel = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.score
  );

  return (
    <>
      <div className="flex md:flex-row flex-col my-6">
        <div className="flex flex-col items-center justify-center mr-6 mb-3 md:mb-0">
          <Regulatory className="scale-150 mb-2" />
          <p className="text-[24px] text-[#7F7F7F] font-bold">Regulatory</p>
        </div>
        {/* <p className="text-base text-pirmaryBlue">
          The Regulatory driver indicates one's motivation to establish order,
          routine, and structure. This motivation promotes rules and policies, a
          traditional approach, and security through standards and protocols.
          Your score shows a Very High score on Regulatory value. The following
          comments and information highlight the specifics to you:
          <div className="font-bold mt-5">
            You are well disciplined and follow standard operating protocol and
            traditional ways.
          </div>
        </p> */}
        <p className="text-base text-pirmaryBlue">
          The Regulatory driver refers to an individual's motivation to establish order, routine, and structure. This drive is associated with a preference for rules, policies, and traditional approaches to promoting security and stability.
          <div className="text-base text-pirmaryBlue mt-3">
            Your assessment score for this value is in the {userLevel[5]?.level} range.
          </div>
          <div className="text-base text-pirmaryBlue mt-3">
            This suggests that {userLevel[5]?.statement} However, remember that this is just one aspect of your overall value system, and other values may influence your choices differently.
          </div>
          <div className="font-bold mt-5">
            You are well disciplined and follow standard operating protocol and
            traditional ways.
          </div>
        </p>
      </div>

      <div className="grid mt-6 grid-cols-1 gap-6 md:grid-cols-2">
        {data?.map(({ title = '', statements = [] }, index) => (
          <BoxDescriptionInfo
            key={index}
            title={getTitle(title)}
            description=""
            caption=""
            list={statements}
          />
        ))}
      </div>
    </>
  );
}

export default RegulatoryTab;
