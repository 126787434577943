import React from 'react'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function TickText({text}) {
  return (
    
    <div className="text-base flex justify-start items-center my-1 mb-3">
    <CheckCircleIcon
      sx={{
        "&.MuiSvgIcon-root": { fill: "#7AB794 !important", marginTop: 0,  },
      }}
    />
    <span className="text-pirmaryBlue ml-2 text-base">{text}</span>
  </div>
  );
}

export default TickText