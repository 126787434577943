import React, { useEffect, useState } from "react";

import { BehaviorStepper } from "../reusableComponents";

import WhiteBox from "../shared/WhiteBox";
import { uploadQuestions } from "../../../../../api/jc";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AssessmentIframe from "./AssessmentIframe";
import config from "../../../../../config/config";

function DriverOfEngagementStepFour() {

  const [mappedQuestions, setMappedQuestions] = useState([]);
  const [iFrameLink, setIFrameLink] = useState('')
  const questions = useSelector(state => state.behaviour.driverOfEngagement);
  const choice = useSelector(state => state.steps.driverOfEngagement);
  const navigate = useNavigate();

  useEffect(() => {
    if (questions?.length) {
      setMappedQuestions(questions.map(data => data?.question));
    }
  }, [questions])

  const handelSubmit = () => {

    const formData = []

    choice.forEach(x => {
      formData.push(questions.find(y => y.question === x));
    })
    let temp = {
      assessment: {
        assessment_type: "DriverOfEngagementDiagnostic",
        assessment_data_ordinality: formData
      }
    }
    uploadQuestions(temp).then((res) => {
      toast.success("Updated.")
      navigate('/assessment/rolebased/start')
    });

  }

  React.useEffect(() => {
    let data = localStorage.getItem('accessData');
    data = data && JSON.parse(data)
    console.log(data);
    let code = data?.isDriverOfEngagementDiagnosticCompleted || ''
    let fName = data?.firstNameAndLastName?.first_name || 'Abc'
    let lName = data?.firstNameAndLastName?.last_name || 'Abc'
    let gender = data?.firstNameAndLastName?.gender === 'male' ? 'm' : 'f'
    let email = data?.email_address || ''
    let iFrameSrc = `${config.assessmentUrlApi}/remote/Values/${code}/instrument/?fname=${fName}&lname=${lName}&gender=${gender}&email=${email}&completeURL=${window.location.origin}/assessment/values/complete/?code=`
    setIFrameLink(iFrameSrc)
  }, []);

  return (
    <>
      <BehaviorStepper step={1} />
      <WhiteBox title="Welcome to the values test! ">

        <p className="text-[#384259] text-base mb-5">
          This test and report are designed to help you understand the seven unique motivations that influence your sense of purpose. By understanding your values, you can find a job opportunity that offers excellent professional growth, spontaneity, variety, security, flexibility, and other elements that will enable you to maximize your potential.
        </p>

        <p className="text-[#384259] text-base mb-5">
          To get started, drag and drop the seven items on the left to the right in the order of preference, with the <span className="font-bold">most important</span> item at the top (<span className="font-bold">First</span>) and the <span className="font-bold">least important</span> item at the bottom (<span className="font-bold">Last</span>). You can also move the items around to adjust their order until you feel it's most accurate.
        </p>
        
        <p className="text-[#384259] text-base mb-5">
          Once you've completed this set of seven answers, click "<span className="font-bold">Submit</span>" to be taken to the next stage in the series. There are ten in total, so take your time and answer each question as honestly and accurately as possible.
        </p>

        <p className="text-[#384259] text-base">
          We hope you find this journey of self-discovery to be insightful and empowering. 
        </p>

        {/* <p className="text-base mb-5 font-bold">
          Before you start, read the following instructions:
        </p>

        <p className="text-base mb-5">
          {" "}
          To the right are seven items (Move term from here), each representing
          an answer to the question above. Click on the item that is the best
          answer on the left and drag it to the right (First, Last).
        </p>

        <p className="text-base mb-5">
          {" "}
          Continue moving items from the left to the right until the order
          represents your thoughts from the "most preferential" answer to the
          least preferential answer.
        </p>

        <p className="text-base mb-5">
          You can also move answers around to adjust their order until you feel
          accurate. When you have completed this set of seven responses, click
          "Submit" to be taken to the next stage in the series. There are ten in
          total.
        </p>

        <h2 className="text-[20px] font-bold text-[#384259] mb-4">
          Which would you read first ?
        </h2>

        <TransferList
          choicesArray={mappedQuestions}
          choicesTitle={"Move term from here"}
          handleData={updateDriverEngagmentChoice}
          chosenTitle={"To here"}
          chosenTopOfList={"First"}
          chosenBottomOfList={"Last"}
          heightStyle={"470px"}
          fileName={"driverOfEngagement2"}
        /> */}
        <AssessmentIframe iFrameLink={iFrameLink} />
      </WhiteBox>
      {/* <div className="max-w-[950px] w-full mx-auto my-5 flex items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate('/assessment/behavior/finish')}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#E3E6E9",
              fontSize: "16px",
              color: "#828282",
            },
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => {
            if (choice.length < mappedQuestions.length) {
              toast.warning("Please Move All Items To Hear!!")
            } else {
              handelSubmit()
            }
          }}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#7AB794",
              fontSize: "16px",
            },
          }}
        >
          Submit
        </Button>
      </div> */}
    </>
  );
}

export default DriverOfEngagementStepFour;
