import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

export const useStyle = makeStyles((theme) => ({
  root: {},
  startTheStatmentBtn: {
    "&.MuiButton-containedSuccess": {
      backgroundColor: "#7AB794 !important",
      fontSize: "16px",
      marginTop: "25px",
    },
  },
  radioButton: {
    "& .Mui-checked": {
      color: "#7AB794 !important",
    },
  },
  stepper: {
    "&.MuiStepper-root": {
      [theme.breakpoints.down("lg")]: {
        alignItems: "start",
        flexDirection: "column !important",
        paddingTop: "20px",
      },
    },
  },
  step: {
    "& .MuiStepIcon-root": {
      color: "#f8f9fa",
      width: "35px",
      height: "35px",
      border: "1px solid #7AB794",
      borderRadius: "50%",
    },
    "& .MuiStepIcon-text": {
      fill: "#828282",
      size: "16px",
    },
    "& .Mui-active .MuiStepIcon-text": { fill: "#FFFFFF !important" },
    "& .Mui-active": { color: "#7AB794 !important" },
    "& .Mui-completed": { color: "#7AB794 !important" },
  },
  stepLabel: {
    "& .MuiStepLabel-label": {
      color: "#828282 !important",
      fontSize: "16px !important",
      fontWeight: "700 !important",
    },
  },
}));
export const TransferListStyle = makeStyles((theme) => {
  return {
    root: {},
    paper: {
      [theme.breakpoints.down("md")]: {
        height: "300px !important",
      },
    },
    grid: {
      width: "100%",
      maxWidth: "400px",
    },
    listItem: {
      border: " 1px solid #7AB794 !important",
      borderRadius: "8px !important",
      display: "flex !important",
      justifyContent: "center!important !important",
      alignItems: "center !important",
      textAlign: "center !important",

      "&.MuiListItem-dense": {
        color: "#7AB794 !important",
        height: "45px",
      },
    },
    listItemText: {
      "& .MuiTypography-root": {
        fontSize: "16px !important",
        fontWeight: "700 !important",
      },
    },
    button: {
      margin: "5px 0px!important",
      color: "#7AB794 !important",
      border: "2px solid #7AB794 !important",
      fontSize: "18px !important",
      fontWeight: "800 !important",

      "&.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.26) !important",
        border: "2px solid rgba(0, 0, 0, 0.12) !important",
      },
    },
  };
});

const steps = [
  "Behavior traits",
  "Values",
  "Role-based competencies",
];

export const BehaviorStepper = ({ step }) => {
  const stepperStyle = useStyle();
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1040px",
        marginTop: "40px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Stepper activeStep={step} className={stepperStyle.stepper}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps} className={stepperStyle.step}>
              <StepLabel {...labelProps} className={stepperStyle.stepLabel}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

// export const transformListCheckbox = () => {
//   <>
//     <ListItem
//       key={value}
//       role="listitem"
//       button
//       onClick={handleToggle(value)}
//       className={`bg-[#000000] ${classes.listItem}`}
//     >
//       <ListItemText
//         className="label"
//         id={labelId}
//         primary={`List item ${value}`}
//       />
//     </ListItem>
//     <ListItemIcon>
//       <Checkbox
//         checked={checked.indexOf(value) !== -1}
//         tabIndex={-1}
//         disableRipple
//         // sx={{ maxHeight: 0, opacity: 0, overflow: "hidden" }}
//         inputProps={{
//           "aria-labelledby": labelId,
//         }}
//         className="checkbox"
//       />
//     </ListItemIcon>
//   </>;
// };
