import React from "react";

function Slide4() {
  return (
    <>
      <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
        The six dimensions of thoughts
      </h5>
      <p className="text-base text-pirmaryBlue  mb-4">
        <span className="font-bold text-[#8B5086]">External Dimension</span>{" "} (How people perceive what you do)
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        <span className="font-bold">Empathy</span> (other people): The ability to perceive and understand how your decision will impact others. It can also be defined as the ability to place oneself in another person's shoes to see their circumstances from their perspective.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        Empathy speaks to the clarity of understanding and the importance of how others will receive your choice.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        <span className="font-bold">Practical thinking:</span> This is the ability to identify and understand problems and the available options and how those options practically compare against each other to achieve the expected result. It is the ability to make pragmatic, hands-on, and common-sense decisions.{" "}
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        Practical thinking speaks to the understanding and the importance of how your choice and solutions will influence the expected results.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        <span className="font-bold">System judgment</span> (Structure or rules): This is the ability to operate within external policies, regulations, and structured systems. The system of judgment speaks to the clarity of understanding and the importance assigned to how your choice will work with the established structure, design, rules, and standards.
      </p>
      <p className="text-base text-pirmaryBlue  mb-4">
        These external dimensions are unique to each individual.
      </p>
    </>
  );
}

export default Slide4;
