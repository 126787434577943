import React from "react";
// import WideModal from "../../sharedComponent/WideModal";
import WideModal from "../../../../../../sharedComponents/modal/Modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";

import { makeStyles } from "@mui/styles";

import { rows, columns } from "./reuseableComponent";
import CreateAvatar from "../../sharedComponent/CreateAvatar";
import { mapBehaviourColor } from "../../../../../../../../utils/getColors";

export const useStyle = makeStyles({
  table: {
    "& .MuiTableRow-head": {
      "& .MuiTableCell-head": {
        background: "#F4F6F8",
        fontWeight: "700",
        color: "#384259",
        fontSize: "16px",
      },
    },
  },
  pagination: {
    margin: "24px 0px 30px 0px",

    "& .MuiSvgIcon-fontSizeMedium": {
      zIndex: 200,
      background: "red",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      opacity: 0,
    },
    "& .MuiPaginationItem-page": {
      border: " 1px solid #E5E5E5",
      borderRadius: "0px",
    },
    "& .Mui-selected": {
      background: "#00000000 !important",
      borderRadius: "0px",
      border: "1px solid #7AB794",
      ":hover": {
        // backgroundColor: "transparent",
      },
    },
  },
});

function BehaviorChart({ selectedCandidatesDetail, open, setOpen }) {

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(13);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const TeamMembers = ({behaviorType}) => (
    <>
      {selectedCandidatesDetail.map((candidateData, index) => {
        let candidate = candidateData?.candidate_profile_id
        let behaviorResultData = candidate?.assessments.filter(
            (i) => i.assessment.assessment_type === "BehaviorAssessment"
        )?.[0]?.assessment?.result || []

        const max = Math.max(...behaviorResultData?.map((x) => Number(x?.value || 0)));
        let maxdefaultBehavior = behaviorResultData?.find((x) => Number(x.value) === max);
        let bType = mapBehaviourColor?.[maxdefaultBehavior?.type]?.name
        
        if(behaviorType==bType){
          return (
            <div className="pt-[10px]">
              <CreateAvatar src={candidate?.candidate_picture_upload_path || ''} name={candidate?.first_name || ''} lastName={candidate?.last_name || ''} />
            </div>
          )
        }
      })}
    </>
  )
  

  const classes = useStyle();

  return (
    <WideModal mdUpWidth={1050} title="Power team" open={open} setOpen={setOpen}>
      <TableContainer className={classes.table}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              // .slice(
              //   (page - 1) * rowsPerPage,
              //   (page - 1) * rowsPerPage + rowsPerPage
              // )
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      let value;

                      value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              <TableRow hover tabIndex={-1}>
                  <TableCell>
                    <p className=" text-sm text-[#384259] font-bold">{'Team Members'}</p>
                  </TableCell>
                  <TableCell>
                    <TeamMembers behaviorType={'Decisive'} />
                  </TableCell>
                  <TableCell>
                    <TeamMembers behaviorType={'Interactive'} />
                  </TableCell>
                  <TableCell>
                    <TeamMembers behaviorType={'Steady'} />
                  </TableCell>
                  <TableCell>
                    <TeamMembers behaviorType={'Cautious'} />
                  </TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </WideModal>
  );
}

export default BehaviorChart;
