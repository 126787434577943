import React from 'react';
import { useDispatch } from 'react-redux';
import { togglePremiumModal } from '../../../redux/actions/premium';
import SuccessBtn from '../sharedComponents/buttons/successBtn/SuccessBtn';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const PremiumButton = ({ children }) => {
  const dispatch = useDispatch();
  return (
    <div className='relative'>
      <SuccessBtn
        className='absolute top-[40%] left-[38%] bg-success px-10 py-3 rounded-xl text-white'
        onClick={() => dispatch(togglePremiumModal())}
      >
        <span className="font-bold"><LockOpenIcon className='h-3' /> &nbsp;Unlock Premium</span>
      </SuccessBtn>
      {children}
    </div>
  );
};

export default PremiumButton;
