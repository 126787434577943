import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveAssessment } from '../../../../../api/jc';

const Step6Completed = () => {
  const navigate = useNavigate()
  const searchParams = useLocation().search;
  const code = new URLSearchParams(searchParams).get('code');
  const isFirst = new URLSearchParams(searchParams).get('isFirst');
  const saveData = async () => {
    try {
      await saveAssessment({
        assessment_type: 'RoleBasedAssessment',
        remote_code: code,
      });
      navigate('/resumeform/upload')
    } catch (error) {
      console.error(error);
    }
  };
  React.useEffect(() => {
    if(!code) navigate('/assessment/rolebased/start')
    if (!isFirst) {
      window.parent.location.href = `${window.parent.location.origin}/assessment/rolebased/complete?code=${code}&isFirst=yes`;
    } else {
      saveData();
    }
  }, []);
};

export default Step6Completed;
