import React, { useState } from "react";
import InfoBoxes from "../../sharedComponents/profile/infoBox";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import EducationModal from "./modalCompnents/educationModal";
import Modal from "../../sharedComponents/modal/Modal";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import moment from "moment";

export default function Educations({ handleReload }) {
  const [addInfo, setAddInfo] = useState(false);
  const [selected, setSelected] = useState();
  const education = useSelector(state => state.user.education);
  const handleSelectItem = (item) => {
    setAddInfo(true);
    setSelected(item);
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontWeight: "700",
        // width: "56px",
        // height: "56px",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (fullName) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  return (
    <>
      <InfoBoxes
        title="Educations"
        icon={<AddCircleOutlineTwoToneIcon />}
        handleClickAdd={() => {setAddInfo(true); setSelected()}}
        TooltipText="Please list the institutions you attended to receive education or training in a particular subject, including the degree or certificate earned and the month and year of graduation (MM-YYYY)."
        TooltipTitle="Education"
      >
        <div className="flex flex-col divide-y">
          {education &&
            education?.map((item) => (
              <div className="grid grid-cols-12 py-4 " key={item.id}>
                <div className="col-span-1">
                <Avatar {...stringAvatar(`${item?.institution_name}`)} />
                </div>
                <div className="col-span-11 pl-2">
                  <div className="flex justify-between">
                    <span className="text-lg font-bold text-pirmaryBlue">
                      {item?.institution_name}
                    </span>
                    <EditOutlinedIcon
                      className="text-secondaryBalck cursor-pointer"
                      onClick={() => handleSelectItem(item)}
                    />
                  </div>
                  <div className="text-base leading-5 text-pirmaryBlue">
                    {item?.field_of_study}
                  </div>
                  <div className="text-secondaryBalck">
                    {moment(item?.from_date).format('DD/MM/YYYY')} to{" "}
                    {moment(item?.to_date).format('DD/MM/YYYY')}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </InfoBoxes>
      <Modal
        onClose={() => {
          setSelected();
        }}
        title={`${selected ? 'Edit' : 'Add'} education`}
        open={addInfo}
        setOpen={setAddInfo}
        mdUpWidth={550}
      >
        <EducationModal
          setSelected={setSelected}
          selected={selected}
          handleReload={() => {
            setAddInfo(false);
            handleReload();
            setSelected();
          }}
        />
      </Modal>
    </>
  );
}
