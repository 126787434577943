import React from "react";
import "react-circular-progressbar/dist/styles.css";
import CircleProgressBar from "../../../sharedComponents/circleProgressBar/CircleProgressBar";
import { ReactComponent as IconRED } from "../../../../assets/characteristicIconRED.svg";
import TickText from "../../../sharedComponents/tickText/TickText";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export default function Decisive({ setOpenModal, data }) {
  const content = useSelector(
    (state) =>
      state.user?.assessments
        ?.find(
          ({ assessment }) =>
            assessment?.assessment_type === 'BehaviorAssessment'
        )
        ?.assessment?.assessment_content?.score?.find(({ type }) => type === 'D')
        ?.statements
  );
  const [allData, setAllData] = useState({})
  useEffect(() => {
    setAllData(data?.filter(i => i.type === "D")[0])
  }, [data])
  return (
    <div className="pt-4">
      <p className="text-[#ff0000] font-bold text-2xl mb-2">Decisive</p>
      <div className="text-base font-bold text-pirmaryBlue">
        Approach to problem-solving and obtaining results.
      </div>
      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="text-base lg:col-span-8 col-span-1 text-pirmaryBlue mt-4 mb-10 pr-4">
          <span className="font-bold text-[#ff0000]">HIGHER D </span>individuals with a higher D score are characterized by their quick and assertive problem-solving approach, particularly concerning unprecedented problems. They prefer an active and direct approach to achieve desired results, even if it involves some level of risk-taking. Though the possibility of developing an incorrect solution or taking the wrong approach exists, those with a high D score are willing to take those risks.
        </div>
        <div className=" lg:col-span-2 col-span-1 px-4 py-4 justify-self-center">
          <CircleProgressBar percentage={allData && Number(allData?.value)} pathColor="#DC2626" />
        </div>
      </div>
      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="text-base lg:col-span-8 col-span-1 text-pirmaryBlue mt-4 mb-10">
          <span className="font-bold text-[#ff0000]">LOWER D </span> individuals with a lower D score tend to approach new problems deliberately, controlled, and organized, particularly when faced with unique and unprecedented problems. They tend to solve routine problems quickly since the outcome is already known. However, they tend to take a more calculated and cautious approach when faced with uncertain and unknown outcomes. They take their time to think through the situation carefully before taking action. <br /><br />

          Both approaches have strengths and weaknesses; the best approach depends on the specific situation and the individual's skills and abilities.
        </div>

        <div className=" lg:col-span-2 col-span-1 px-4 py-4 justify-self-center">
          <CircleProgressBar percentage={allData && Number(allData?.adaptiveValue)} pathColor="grey" />
        </div>
      </div>

      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="lg:col-span-8 col-span-1 mt-4 mb-10 lg:w-[90%]">
          {content?.length && (
            <>
              <div className="text-base font-bold mb-4">{content[0]}</div>
              {content.slice(1, content.length - 1).map((data, i) => <TickText key={i} text={data} />)}
            </>
          )}

          <div className=" text-secondaryBalck text-sm mt-4">
            * High score doesn’t mean good, and a low score doesn’t mean bad, as
            this is a spectrum or continuum of BEHAVIORAL TRAITS.
          </div>
        </div>

        <div
          className="lg:col-span-2 col-span-1 mt-4 mb-10 flex flex-col
          items-center text-center cursor-pointer"
          onClick={() => setOpenModal(true)}
        >
          <IconRED />
          <p className="text-sm font-bold text-pirmaryBlue mt-4">
            Observable characteristic of High <br />{" "}
            <span className="text-[#FF0000]">Decisive</span> behavior <Tooltip placement="bottom"
              title={
                <div className="rounded-md">
                  <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                    Decisive Behavior
                  </div>
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Decisive individuals are action-oriented, confident, and comfortable with risk. They're quick decision-makers, able to prioritize effectively and stand firm in their choices. Their ability to handle criticism, take responsibility, and learn from their experiences makes them resilient and successful leaders.
                  </div>
                </div>
              }
              arrow
            >
              <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
            </Tooltip>
          </p>
        </div>
      </div>
    </div>
  );
}
