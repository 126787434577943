import React from "react";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import InfoBox from "../../../sharedComponents/profile/infoBox";
import { BudgetTable, getBudgetData } from "../reusableComponent";

const columns = [
  { id: "incomeExpenses", label: "Income and expenses", minWidth: 200 },
  { id: "Jan", label: "January", minWidth: 100, align: "right" },
  { id: "Feb", label: "February", minWidth: 100, align: "right" },
  { id: "Mar", label: "March", minWidth: 100, align: "right" },
  { id: "Apr", label: "April", minWidth: 100, align: "right" },
  { id: "May", label: "May", minWidth: 100, align: "right" },
  { id: "Jun", label: "June", minWidth: 100, align: "right" },
  { id: "Jul", label: "July", minWidth: 100, align: "right" },
  { id: "Aug", label: "August", minWidth: 100, align: "right" },
  { id: "Sep", label: "September", minWidth: 100, align: "right" },
  { id: "Oct", label: "October", minWidth: 100, align: "right" },
  { id: "Nov", label: "November", minWidth: 100, align: "right" },
  { id: "Dec", label: "December", minWidth: 100, align: "right" },
];

function Step3({ setActiveStep, month, year, data, reload }) {
  // const startingBalanceRef = useRef();
  const [allData, setAllData] = React.useState([])

  React.useEffect(() => {
    getBudgetData(year, setAllData)
  }, []);

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="col-span-1">
        <InfoBox title="Annual budget by month" TooltipTitle="Annual budget by month" TooltipText="An annual budget by months is your yearly forecast divided by months, and it details your revenues and expenses expected each month of a year." containerStyle="h-full">
          <div className="max-h-[500px] overflow-y-auto">
            <BudgetTable rows={allData} columns={columns} />
          </div>
          {/* <div className="flex flex-col my-6 w-52">
            <label
              htmlFor="plannedExpensesExpenditure"
              className="text-pirmaryBlue text-base font-normal"
            >
              Starting Balance
            </label>
            <input
              type="text"
              name="plannedExpensesExpenditure"
              ref={startingBalanceRef}
              id="plannedExpensesExpenditure"
              placeholder="Enter Starting Balance"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div> */}
          <div className="flex justify-end mt-6">
            <SuccessBtn onClick={() => setActiveStep((prev) => prev + 1)}>
              Generate Report
            </SuccessBtn>
          </div>
        </InfoBox>
      </div>
    </div>
  );
}

export default Step3;
