import React, { useState } from "react";
import InfoBoxes from "../../sharedComponents/profile/infoBox";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Modal from "../../sharedComponents/modal/Modal";
import TraningsModal from "./modalCompnents/traningsModal";
import { Avatar } from "@mui/material";
import moment from "moment";

export default function Tranings({ handleReload,data }) {
  const [addInfo, setAddInfo] = useState(false);
  const [selected, setSelected] = useState({})
  const handleSelectItem = (item) => {
    setAddInfo(true)
    setSelected(item)
    console.log(item)
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontWeight: "700",
        // width: "56px",
        // height: "56px",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (fullName) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }
  
  return (
    <>
      <InfoBoxes
        title="Trainings"
        handleClickAdd={() => {
          setSelected({})
          setAddInfo(true)
        }}
        icon={<AddCircleOutlineTwoToneIcon />}
        TooltipText="Enter the name of the institution where you received knowledge, training, or instruction in a particular subject, along with the title obtained and the month and year (MM-YYYY)."
        TooltipTitle="Training"
      >
        <div className="flex flex-col divide-y">
          {
            data && data?.trainings?.map((item, index) => (
              <div className="grid grid-cols-12 py-4" key={index}>
                <div className="col-span-1">
                <Avatar {...stringAvatar(`${item?.name}`)} />
                </div>
                <div className="col-span-11 pl-2">
                  <div className="flex justify-between">
                    <span className="text-lg font-bold text-pirmaryBlue">
                      {item?.name_of_certificate}
                    </span>
                    <EditOutlinedIcon className="text-secondaryBalck cursor-pointer" onClick={() => handleSelectItem(item)} />
                  </div>
                  <div className="text-base leading-5 text-pirmaryBlue">
                    {item?.institution_ame}
                  </div>
                  <div className="text-base leading-5 text-pirmaryBlue">
                    {item?.field_of_study}
                  </div>
                  <div className="text-secondaryBalck">{moment(item?.start_date).format('DD/MM/YYYY')} to {moment(item?.end_date).format('DD/MM/YYYY')}</div>
                  <div className="text-secondaryBalck text-sm leading-[18px] mt-2">
                    {item?.description}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </InfoBoxes>
      <Modal title={`${Object.keys(selected).length > 0 ? 'Edit' : 'Add'} training`} open={addInfo} setOpen={setAddInfo} lgUpWidth={600}>
        <TraningsModal selected={selected} handleReload={() => {
          setAddInfo(false)
          handleReload()
          setSelected({})
        }} />
      </Modal>
    </>
  );
}
