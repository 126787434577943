import { createReducer } from "@reduxjs/toolkit"
import { openPremiumModal, togglePremiumModal } from "../actions/premium"

const showModal = false

const premiumModalReducer = createReducer(showModal, (builder) => {
  builder
    .addCase(togglePremiumModal, (state) => {
      return !state
    })
    .addCase(openPremiumModal, (state) => {
      return true
    })
})

export default premiumModalReducer;