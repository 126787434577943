import React from "react";
import BoxDescriptionInfo from "../../behavioralTraits/sharedcomponents/boxDescriptionInfo";
import { ReactComponent as Aesthetic } from "../../../../assets/aesthetic.svg";
import { useSelector } from "react-redux";
import getTitle from "../reusableComponent";

// const list1 = [
//   "You possess the average level of aesthetic appreciation, near the national mean.",
//   "You possess a healthy balance between form and function.",
//   "You can work well with others to create and innovate.",
//   "You respect other’ needs to express their creativity.",
//   "You work equally  well with the artistic and non-artistic types.",
// ];
// const list2 = [
//   "You appreciate the need for conservation efforts and preserving balance and harmony in the surroundings.",
//   "You believe that art and form can be uplifting and positive, even for those with no artistic ability.",
//   "You balance team efforts between doing it right and doing it enjoyably.",
//   "To you, balance between work and life is important.",
//   "You possess a level of artistic interest or appreciation that is right at the national mean.",
// ];
// const list3 = [
//   "You appreciate a sense of balance between work and life, but are not paralyzed without it.",
//   "You appreciate renewable and environmentally friendly (green) approaches.",
//   "You value conservation efforts as both renewing but also practical.",
//   "You are willing to help others strictly out of support or appreciation of their efforts.",
//   "You enjoy certain artistic aspects or ideas, but on a normal level without being extreme.",
// ];
// const list4 = [
//   "You are supportive of a variety of training and professional development efforts.",
//   "You work fine with either team learning, or individual learning.",
//   "You like to learn for the sake of personal development and growth.",
//   "Your learning should be equal parts form and function, not all function.",
// ];
// const list5 = [
//   "Since your score is at the national mean, it is important to review other Values drives for a more complete look at areas for quality improvement.",
//   "You may benefit by taking a more visible position on teams.",
//   "You could benefit by ensuring your life is well balanced between personal and professional worlds.",
//   "Remember that it is OK that some don't appreciate artistry, balance or harmony and you can serve as a bridge between those who do and don't see value in such things.",
// ];

function Asthatic() {
  const data = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.valueDescriptor?.find(({ section }) => section === 'aes')?.subsection || []
  );

  const userLevel = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.score
  );

  return (
    <>
      <div className="flex md:flex-row flex-col my-6">
        <div className="flex flex-col items-center justify-center mr-6 mb-3 md:mb-0">
          <Aesthetic className="scale-150 mb-2"/>
          <p className="text-[24px] text-[#A9D18E] font-bold">Aesthetic</p>
        </div>
        {/* <p className="text-base text-pirmaryBlue">
          The main motivation in this value is the drive to achieve balance,
          harmony and find form or beauty. Environmental concerns or “green”
          initiatives are also typically prized by this dimension. Your score
          shows a Low Average score on Aesthetic value. The following comments
          and information highlight the specifics:
          <div className="font-bold mt-5">
            You have a bottom-line approach focusing on functionality over form
            or aesthetics.
          </div>
        </p> */}
        <p className="text-base text-pirmaryBlue">
          The Aesthetic value is primarily motivated by the drive to achieve balance, harmony, and beauty, often including concern for environmental sustainability.
          <div className="text-base text-pirmaryBlue mt-3">
            Your assessment score for this value is in the {userLevel[0]?.level} range.
          </div>
          <div className="text-base text-pirmaryBlue mt-3">
            This suggests that {userLevel[0]?.statement} However, remember that this is just one aspect of your overall value system, and other values may influence your choices differently.
          </div>
          <div className="font-bold mt-5">
            You have a bottom-line approach focusing on functionality over form
            or aesthetics.
          </div>
        </p>
      </div>

      <div className="grid mt-6 grid-cols-1 gap-6 md:grid-cols-2">
        {data?.map(({ title = '', statements = [] }, index) => (
          <BoxDescriptionInfo
            key={index}
            title={getTitle(title)}
            description=""
            caption=""
            list={statements}
          />
        ))}
      </div>
    </>
  );
}

export default Asthatic;
