import React, { useRef } from "react";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import InfoBox from "../../../sharedComponents/profile/infoBox";
import {
  BudgetTable,
  handleGetMonthlyExpenses,
  handleGetPlannedExpenses,
} from "../reusableComponent";
import { savePersonalBudget } from "../../../../../api/jc";
import { toast } from "react-toastify";

const columns = [
  { id: "espenses", label: "Espenses", minWidth: 350 },
  { id: "cost", label: "Costs", minWidth: 50, align: "right" },
];

const PlannedColumns = [
  { id: "expenditure", label: "Expenditure", minWidth: 150 },
  { id: "month", label: "Months", minWidth: 150 },
  { id: "amount", label: "Amount", minWidth: 50, align: "right"},
];

function Step2({ setActiveStep, month, year, data, reload }) {
  const [monthlyExpenseData, setMonthlyExpenseData] = React.useState([]);
  const [plannedExpensesData, setPlannedExpensesData] = React.useState([]);

  const monthlyExpenses = useRef();
  const monthlyCost = useRef();
  const plannedExpensesExpenditure = useRef();
  const plannedExpenseAmount = useRef();

  // const handleInfo = () => {
  //   const data = {
  //     monthlyExpenses: monthlyExpenses.current.value,
  //     monthlyCost: monthlyCost.current.value,
  //     plannedExpensesExpenditure: plannedExpensesExpenditure.current.value,
  //     plannedExpenseAmount: plannedExpenseAmount.current.value,
  //   };
  //   console.log(data);
  // };

  const handleMonthlyExpenses = async () => {
    try {
      if (monthlyExpenses.current.value && monthlyCost.current.value) {
        if (monthlyCost.current.value <= 0) {
          toast.error("Amount must be greater than 0");
        } else {
          let data = {
            year,
            month,
            monthly_expenses: [
              {
                expensess_name: monthlyExpenses.current.value,
                cost: monthlyCost.current.value,
              },
            ],
          };
          await savePersonalBudget(data);
          toast.success("Monthly expense updated!");
          monthlyExpenses.current.value = "";
          monthlyCost.current.value = "";
          reload();
        }
      } else {
        toast.error("Please fill all fields of monthtly expenses!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePlannedExpenses = async () => {
    try {
      if (
        plannedExpensesExpenditure.current.value &&
        plannedExpenseAmount.current.value
      ) {
        if (plannedExpenseAmount.current.value <= 0) {
          toast.error("Amount must be greater than 0");
        } else {
          let data = {
            year,
            month,
            planned_expenses: [
              {
                expenditure: plannedExpensesExpenditure.current.value,
                amount: plannedExpenseAmount.current.value,
              },
            ],
          };
          await savePersonalBudget(data);
          toast.success("Planned expense updated!");
          plannedExpensesExpenditure.current.value = "";
          plannedExpenseAmount.current.value = "";
          reload();
        }
      } else {
        toast.error("Please fill all fields of planned expenses!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    handleGetMonthlyExpenses(data, setMonthlyExpenseData);
    handleGetPlannedExpenses(data, setPlannedExpensesData);
  }, [data]);

  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div className="col-span-1">
          <InfoBox title="Monthly expenses" TooltipTitle="Monthly expenses" TooltipText="Monthly expenses are standard cost that occurs every month, on a specific day, and for a certain amount. For example, your monthly expenses could include mortgage, rent, utility services such as water, electricity, cell phone, car payment, insurance, gym membership, food, and entertainment, among other recurring expenses." containerStyle="h-full">
            <form className="grid md:grid-cols-auto-fit-250 grid-cols-auto-fit-250 gap-4 mt-[24px]">
              <div className="flex flex-col">
                <label
                  htmlFor="monthlyExpenses"
                  className="text-pirmaryBlue text-base font-normal"
                >
                  Enter Expenses *
                </label>
                <input
                  type="text"
                  name="monthlyExpenses"
                  ref={monthlyExpenses}
                  id="monthlyExpenses"
                  placeholder="Enter Expenses"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="monthlyCost"
                  className="text-pirmaryBlue text-base font-normal"
                >
                  Enter cost *
                </label>
                <input
                  type="text"
                  name="monthlyCost"
                  ref={monthlyCost}
                  id="monthlyCost"
                  placeholder="Enter cost"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
              </div>
            </form>
            <div className="my-6">
              <SuccessBtn type="button" onClick={handleMonthlyExpenses}>
                Add
              </SuccessBtn>
            </div>
            <div className="max-h-[500px] overflow-y-auto">
              <BudgetTable rows={monthlyExpenseData} columns={columns} />
            </div>
          </InfoBox>
        </div>
        <div className="col-span-1">
          <InfoBox title="Planned expenses" TooltipTitle="Planned expenses" TooltipText="Planned expenses refer to the fixed funds you plan to use for activities or obligations you programmed, such as vacations, new clothes, studies, rentals, insurance, and utilities." containerStyle="h-full">
            <form className="grid md:grid-cols-auto-fit-250 grid-cols-auto-fit-250 gap-4 mt-[24px]">
              <div className="flex flex-col">
                <label
                  htmlFor="plannedExpensesExpenditure"
                  className="text-pirmaryBlue text-base font-normal"
                >
                  Enter expenditure *
                </label>
                <input
                  type="text"
                  name="plannedExpensesExpenditure"
                  ref={plannedExpensesExpenditure}
                  id="plannedExpensesExpenditure"
                  placeholder="Enter expenditure"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="plannedExpenseAmount"
                  className="text-pirmaryBlue text-base font-normal"
                >
                  Enter Amount *
                </label>
                <input
                  type="text"
                  name="plannedExpenseAmount"
                  ref={plannedExpenseAmount}
                  id="plannedExpenseAmount"
                  placeholder="Enter Amount"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
              </div>
            </form>
            <div className="my-6">
              <SuccessBtn type="button" onClick={handlePlannedExpenses}>
                Add
              </SuccessBtn>
            </div>
            <div className="max-h-[500px] overflow-y-auto">
              <BudgetTable
                rows={plannedExpensesData}
                columns={PlannedColumns}
              />
            </div>
          </InfoBox>
        </div>
      </div>

      <div className="flex justify-end items-center mt-8">
        <SuccessBtn onClick={() => setActiveStep((prev) => prev + 1)}>
          Next
        </SuccessBtn>
      </div>
    </>
  );
}

export default Step2;
