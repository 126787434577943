import React from 'react';
import SelectField from '../../../../../../sharedComponents/selectField/SelectField';
import Autocompletes from '../../../../../../sharedComponents/Autocompletes/Autocompletes';
import MultipleSelectCheckmarks from '../../../../../../sharedComponents/selectField/MultipleSelectCheckmarks';
import { allLanguages } from '../../../../../../../contants/languages';
import {
  competenciesData,
  educationData,
  experienceData,
  otherCompetenciesList,
  skillsData,
} from '../../../../../../../contants/jobReqData';
import { DeleteOutlined } from '@mui/icons-material';
import { Divider, Tooltip } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";

function Skill({ data, handleAutoCompeteData, handleChangeData, handleRoleBasedCometencies, handleAdditionalRoleBasedCometencies, formErrors }) {
  return (
    <>
      <form className='mt-[24px] my-[24px]'>
        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 mt-[16px]'>
          <div className='flex flex-col'>
            <Autocompletes
              itemsData={skillsData}
              label='Skill'
              labelId='skills'
              id='skills'
              value={data.skills}
              onChanges={(e, val) => {
                handleAutoCompeteData('skills', val)
              }}
              name='skills'
              placeholder='Type skill'
              isMultiple={true}
              freeSolo={true}
              required
            />
            {formErrors.skills && (
              <span className="text-red-500">{formErrors.skills}</span>
            )}
          </div>

          <div className='flex flex-col'>
            <Autocompletes
              itemsData={educationData}
              label='Education'
              labelId='education'
              id='education'
              value={data.education}
              onChanges={(e, val) => {
                handleAutoCompeteData('education', val)
              }}
              name='education'
              placeholder='Type education'
              isMultiple={true}
              freeSolo={true}
              required
            />
            {formErrors.education && (
              <span className="text-red-500">{formErrors.education}</span>
            )}
          </div>
        </div>
        
        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 mt-[16px]'>
          <div className='flex flex-col'>
            <Autocompletes
              itemsData={allLanguages.map((option) => option.name)}
              label='Languages'
              labelId='languages'
              id='languages'
              value={data.languages}
              onChanges={(e, val) => {
                handleAutoCompeteData('languages', val)
              }}
              name='languages'
              placeholder='Type language'
              isMultiple={true}
              required
            />
            {formErrors.languages && (
              <span className="text-red-500">{formErrors.languages}</span>
            )}
          </div>

          <div className='flex flex-col'>
            <SelectField
              selectItems={experienceData}
              label='Work experience'
              labelId='experience'
              id='experience'
              value={data.experience}
              onChange={handleChangeData}
              name='experience'
              placeholder='Select work experience'
              inputStyle={{ padding: '14px' }}
              required
            />
            {formErrors.experience && (
              <span className="text-red-500">{formErrors.experience}</span>
            )}
          </div>
        </div>
        
        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 mt-[16px]'>
          <div className='flex flex-col'>
            <SelectField
              selectItems={competenciesData}
              label='Role-based competencies'
              labelId='competencies'
              id='competencies'
              value={data.competencies}
              onChange={handleChangeData}
              name='competencies'
              placeholder='Select role-based competencies'
              inputStyle={{ padding: '14px' }}
              required
            />
            {formErrors.competencies && (
              <span className="text-red-500">{formErrors.competencies}</span>
            )}
          </div>

          <div className='flex flex-col'>
            <label className="text-base text-pirmaryBlue">
              Additional competencies
              <Tooltip
                placement="top"
                title={
                  <div className="rounded-md">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Additional competencies
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                      You can customize the selected role by adding up to 10 more competencies. 
                      <br/><br/>
                      You can customize the role in batches of 3, 6, and 10. However, the customization has an additional cost that will be invoiced at the end of your billing cycle.
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
              </Tooltip>
            </label>
            <MultipleSelectCheckmarks
              selectItems={otherCompetenciesList}
              labelId='additionalRoleCompetencies'
              id='additionalRoleCompetencies'
              value={data.additionalRoleCompetencies}
              onChange={handleChangeData}
              name='additionalRoleCompetencies'
              placeholder='Select additional competencies'
              isMultiple={true}
            />
            {formErrors.additionalRoleCompetencies && (
              <span className="text-red-500">{formErrors.additionalRoleCompetencies}</span>
            )}
          </div>
        </div>        
      </form>
      
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 mt-[16px]'>
        <div className='flex flex-col'>
          {data.roleBasedCometencies?.map((roleBasedItem) => (
            <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {roleBasedItem}
                <DeleteOutlined onClick={() => handleRoleBasedCometencies(roleBasedItem)} style={{color: 'red'}} />
            </div>
            <Divider style={{margin: '5px 0'}} />
            </div>
          ))}
        </div>
        <div className='flex flex-col'>
          {data.additionalRoleCompetencies?.map((additionalRoleBasedItem) => (
            <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {additionalRoleBasedItem}
                <DeleteOutlined onClick={() => handleAdditionalRoleBasedCometencies(additionalRoleBasedItem)} style={{color: 'red'}} />
            </div>
            <Divider style={{margin: '5px 0'}} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Skill;