import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import WhiteBox from "../../sharedComponents/whiteBox/WhiteBox";
import SuccessBtn from "../../sharedComponents/buttons/successBtn/SuccessBtn";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as ArrowLeft } from "../../../assets/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../../assets/arrowRight.svg";
import { ReactComponent as TeslaLarge } from "../../../assets/teslaLargeLogo.svg";

import { rows, columns, useStyle, Modal } from "./reusableComponent";
import { Link } from "react-router-dom";
import { addToJobPostApplicationJC, getAllJobs } from "../../../../api/jc";
import { Avatar } from "@mui/material";
import { toast } from "react-toastify";

function JoblistProfile() {
  const [open, setOpen] = React.useState(false);
  const [jobs, setJobs] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [selectedJobData, setSelectedJobData] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const previweHandler = (rowData) => {
    setOpen(true);
    setSelectedJobData(rowData)
  };

  const classes = useStyle();

  const applyForJob = async (job_post_id) => {
    try {
      const res = await addToJobPostApplicationJC({
        job_post_id,
      })
      getAllJobs()
      setOpen(false)
      setSelectedJobData()
      toast.success('You have successfully applied for this job!')
    } catch (error) {
      console.error(error);
    }
  }

  const getJobs = async () => {
    try {
      const res = await getAllJobs();
      if (res?.data?.length > 0) {
        setJobs(
          res.data.map((jobData, index) => {
            let {
              company_name: companyName = '',
              logo_file = '',
              job_title: jobTitle = '',
              country = '',
              city = '',
              employment_type: employmentType = '',
              job_application_status = ''
            } = jobData;
            let location = `${country}, ${city}`;
            return {
              ...jobData,
              id: index + 1,
              logo: <Avatar src={logo_file} className='mx-auto' />,
              companyName,
              jobTitle,
              location,
              employmentType,
              applicationStatus: job_application_status || '-',
            };
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getJobs()
  }, []);

  return (
    <>
      <WhiteBox>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 750 }} className={classes.table}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          let value;

                          if (column.id === "preview") {
                            value = (
                              <VisibilityOutlinedIcon
                                className={classes.perview}
                                onClick={() => previweHandler(row)}
                              />
                            );
                          } else {
                            value = row[column.id];
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="flex items-center justify-end">
            <div className="relative mr-5">
              <Pagination
                count={
                  jobs.length < 10 ? 1 : Math.ceil(jobs.length / rowsPerPage)
                }
                page={page}
                onChange={handleChange}
                rowsPerPage={rowsPerPage}
                className={classes.pagination}
              />
              <ArrowLeft
                style={{
                  position: "absolute",
                  left: 0,
                  top: "48%",
                  transform: "translateY(-50%)",
                  zIndex: 100,
                  opacity: page - 1 === 0 ? 0.5 : 1,
                }}
              />
              <ArrowRight
                style={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 100,
                  opacity:
                    page === Math.ceil(jobs.length / rowsPerPage) ? 0.5 : 1,
                }}
              />
            </div>
          </div>
        </Paper>
      </WhiteBox>

      <Modal title="Job preview" open={open} setOpen={(data) => {
        setOpen(data)
        setSelectedJobData()
      }}>
        <div className="flex items-center">
          <div className="mr-6">
            <TeslaLarge />
          </div>
          <div className="flex flex-col">
            <h4 className={classes.title}>Management Consulting</h4>
            <p className="text-[18px] text-[#384259]">
              <span>Tesla</span>-<span>Veinna, Austria</span>
            </p>
          </div>
        </div>

        <div className="border-[1px] border-dashed border-[#C4C4C4] my-6 "></div>

        <div className='grid grid-cols-auto-fit-200 gap-4 rounded border-[1px] border-solid border-[#E5E5E5] p-[30px] mb-6'>
          <div className='flex flex-col'>
            <h4 className={classes.subTitle}>Seniority Level</h4>
            <p className={classes.text}>
              {selectedJobData?.seniority_level || '-'}
            </p>
          </div>
          <div className='flex flex-col'>
            <h4 className={classes.subTitle}>Industry</h4>
            <p className={classes.text}>
              {selectedJobData?.company_industry || '-'}
            </p>
          </div>
          <div className='flex flex-col'>
            <h4 className={classes.subTitle}>Employment Type</h4>
            <p className={classes.text}>
              {selectedJobData?.employment_type || '-'}
            </p>
          </div>
          <div className='flex flex-col'>
            <h4 className={classes.subTitle}>Job function</h4>
            <p className={classes.text}>
              {selectedJobData?.job_function || '-'}
            </p>
          </div>
          <div className='flex flex-col'>
            <h4 className={classes.subTitle}>Salary range</h4>
            <p className={classes.text}>
              {selectedJobData?.salary_range_from || ''} -{' '}
              {selectedJobData?.salary_range_to || ''}{' '}
              {selectedJobData?.salary_currency || '-'}
            </p>
          </div>
          <div className="flex flex-col">
            <h4 className={classes.subTitle}>Experience</h4>
            <p className={classes.text}>{selectedJobData?.experience || '-'}</p>
          </div>
          <div className='flex flex-col'>
            <h4 className={classes.subTitle}>URL</h4>
            <Link
              to={selectedJobData?.web_url || '#'}
              className='text-[#4472C4] text-sm'
            >
              {selectedJobData?.web_url || '-'}
            </Link>
          </div>
          <div className="flex flex-col">
            <h4 className={classes.subTitle}>Job Funnel</h4>
            <p className={classes.text}>{selectedJobData?.job_funnel || '-'}</p>
          </div>
          <div className='flex flex-col'>
            <h4 className={classes.subTitle}>Application Through Tripa:</h4>
            <p className={classes.text}>
              {selectedJobData?.application_through_tripa || '-'}
            </p>
          </div>
        </div>

        <div className="mb-4">
          <h2 className={classes.title}>Job Details</h2>
          <div className="mt-4">
            <h5 className="text-[14px] font-bold  text-[#384259]">
              Your Mission
            </h5>
            <p
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: selectedJobData?.your_mission || '-',
              }}
            />
          </div>
          <div className="mt-4">
            <h5 className="text-[14px] font-bold  text-[#384259]">
              Your Focus
            </h5>
            <p
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: selectedJobData?.your_focus || '-',
              }}
            />
          </div>
          <div className="mt-4">
            <h5 className="text-[14px] font-bold  text-[#384259]">Why Us</h5>
            <p
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: selectedJobData?.why_us || '-',
              }}
            />
          </div>
          <div className="mt-4">
            <h5 className="text-[14px] font-bold  text-[#384259]">Why You</h5>
            <p
              className={classes.text}
              dangerouslySetInnerHTML={{
                __html: selectedJobData?.why_you || '-',
              }}
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="lg:min-w-[430px] min-w-full">
            <h2 className={`${classes.title} mb-5`}>
              Knowledge, Abilities & Skills
            </h2>
            {selectedJobData?.skills?.length > 0 &&
              selectedJobData.skills.map((skill, index) => (
                <p className='flex mb-3' key={index}>
                  <CheckCircleIcon className={classes.checkCircle} />
                  <span className={`text-[#384259] text-base ml-3`}>
                    {skill || ''}
                  </span>
                </p>
              ))}
          </div>
          <div className="lg:min-w-[430px] min-w-full">
            <h2 className={`${classes.title} mb-5`}>Competencies</h2>
            {selectedJobData?.competencies?.length > 0 &&
              selectedJobData.competencies.map((competency, index) => (
                <p className='flex mb-3' key={index}>
                  <CheckCircleIcon className={classes.checkCircle} />
                  <span className={`text-[#384259] text-base ml-3`}>
                    {competency || ''}
                  </span>
                </p>
              ))}
          </div>
        </div>
        <div className="flex flex-wrap mt-6">
          <div className="lg:min-w-[430px] min-w-full">
            <h2 className={`${classes.title} mb-5`}>Language skills</h2>
            {selectedJobData?.languages?.length > 0 &&
              selectedJobData.languages.map((lang, index) => (
                <p className='flex mb-3' key={index}>
                  <CheckCircleIcon className={classes.checkCircle} />
                  <span className={`text-[#384259] text-base ml-3`}>
                    {lang || ''}
                  </span>
                </p>
              ))}
          </div>
          <div className="lg:min-w-[430px] min-w-full">
            <h2 className={`${classes.title} mb-5`}>
              Education & Qualification
            </h2>
            {selectedJobData?.education?.length > 0 &&
              selectedJobData.education.map((education, index) => (
                <p className='flex mb-3' key={index}>
                  <CheckCircleIcon className={classes.checkCircle} />
                  <span className={`text-[#384259] text-base ml-3`}>
                    {education || ''}
                  </span>
                </p>
              ))}
          </div>
        </div>

        <div className="flex items-center justify-end mt-8">
          <div className="flex">
            <SuccessBtn
              type='button'
              onClick={() => {
                setOpen(false)
                setSelectedJobData()
              }}
              customStyle={{ marginRight: '24px' }}
            >
              OKAY
            </SuccessBtn>
            <SuccessBtn
              onClick={() =>
                applyForJob(selectedJobData?._id)
              }
              className={`${selectedJobData?.job_application_status && 'opacity-70'}`}
              type='button'
              disabled={selectedJobData?.job_application_status ? true : false}
            >
              APPLY FOR JOB
            </SuccessBtn>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default JoblistProfile;
