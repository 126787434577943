import { createReducer } from "@reduxjs/toolkit"
import { updateAssesmentType, updateBehaviourChoice, updateDriverEngagmentChoice, updateResume, updateRoleBasedChoice, updateRoleBasedChoiceStep6 } from "../actions/steps"

const initUser = {
  resume: null,
  assesmentType: "",
  behaviourChoice: [],
  driverOfEngagement:[],
  roleBasedChoiceStep6: [],
  roleBasedChoice:[]
}

const stepReducer = createReducer(initUser, (builder) => {
  builder
    .addCase(updateResume, (state, action) => {
      state.resume = action.payload;
    })
    .addCase(updateAssesmentType, (state, action) => {
      state.assesmentType = action.payload;
    })
    .addCase(updateBehaviourChoice, (state, action) => {
      state.behaviourChoice = action.payload;
    })
    .addCase(updateDriverEngagmentChoice, (state, action) => {
      state.driverOfEngagement = action.payload;
    })
    .addCase(updateRoleBasedChoiceStep6, (state, action) => {
      state.roleBasedChoiceStep6 = action.payload;
    })
    .addCase(updateRoleBasedChoice, (state, action) => {
      state.roleBasedChoice = action.payload;
    })

})

export default stepReducer;