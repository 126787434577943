import React from 'react';
import Modal from '../../../../../sharedComponents/modal/Modal';
import SuccessBtn from '../../../../../sharedComponents/buttons/successBtn/SuccessBtn';
import DangerLightBtn from '../../../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn';
import { deleteHRPool } from '../../../../../../../api/hr';

function DeleteCandidate({ open, setOpen, getAllPools }) {
  const handleDelete = async () => {
    try {
      await deleteHRPool({ id: open });
      getAllPools();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Modal mdUpWidth={340} title='Delete candidate pool' open={open} setOpen={setOpen}>
      <p className='text-base text-[#384259] my-4'>
        Are you sure to delete this Pool?
      </p>
      <div className='flex items-center justify-between'>
        <DangerLightBtn onClick={() => setOpen(false)}>No</DangerLightBtn>
        <SuccessBtn onClick={handleDelete}>Yes</SuccessBtn>
      </div>
    </Modal>
  );
}

export default DeleteCandidate;
