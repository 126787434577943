import React, { useEffect } from "react";
import InfoBoxes from "../../../../sharedComponents/profile/infoBox";
import SuccessBtn from "../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import AnyChart from "anychart-react";
import anychart from "anychart";
import avatar from "../../../../../assets/Male.svg";
import { getCandidateBehaviorDetailsForRadarPlotting, getCandidateBehaviorForRadarPlotting } from "../../../../../../utils/getTeamPlotting";

function PlottingLocPres({
  setChartModal,
  setBehaviorChart,
  candidateList,
  selectedCandidatesDetail,
  chartModal,
  behaviorChart,

}) {
  var polarChart = anychart.polar();
  var behaviorDescrption;
  var candidateBehaviorType;

  const initializeChart = async () => {
    polarChart.yAxis().labels().enabled(false);
    polarChart.xAxis().labels().enabled(false);

    // set chart yScale settings
    polarChart.yScale().minimum(0).maximum(100);
    polarChart.yScale().ticks().interval(20);

    // set chart xScale settings
    polarChart.xScale().minimum(0).maximum(360);
    polarChart.xScale().ticks().interval(90);

    polarChart.contextMenu().enabled(false);

    selectedCandidatesDetail.map((candidate, index) => {
      // let xCo =
      //   candidate?.candidate_profile_id?.assessments[0]?.assessment?.team_plotting?.x;
      // let yCo =
      //   candidate?.candidate_profile_id?.assessments[0]?.assessment?.team_plotting?.y;
      // let cell =
      //   candidate?.candidate_profile_id?.assessments[0]?.assessment?.team_plotting?.cell;
      // let candidateInfo = getCandidateBehaviorForRadarPlotting(cell);
      let series = polarChart.marker(computeCoordinates(candidate));
      let candidateInfo = getCandidateBehaviorDetailsForRadarPlotting(calculatedCoordinatesX);
      series.name("Signal A");
      series.type("circle");
      series.size(14);
      series.hovered().size(12);
      series.fill({
        src:
          candidate?.candidate_profile_id?.candidate_picture_upload_path ||
          avatar,
        mode: "fit-max",
        opacity: 1,
      });
      series.stroke(false);
      let tooltip = series.tooltip();
      tooltip.useHtml(true);
      tooltip.background().fill("#ffffff");
      tooltip.titleFormat(
        `<div class="team-dynamics-chart-tooltip">
                <div class="td-chart-image">
                  <img src="` +
        (candidate?.candidate_profile_id?.candidate_picture_upload_path ||
          avatar) +
        `" />
                </div>
                <div class="td-chart-user-blk">
                <span class="text-base text-pirmaryBlue font-bold">
                  ` +
        [
          candidate?.candidate_profile_id?.first_name,
          candidate?.candidate_profile_id?.last_name,
        ].join(" ") +
        `
                </span>
                <span class="text-sm text-pirmaryBlue">` +
        candidateBehaviorType
        +
        `</span>
                </div>
              </div>`
      );
      tooltip.format(
        '<div class="bar" style="background-color:' + candidateInfo.color + '"></div><p>' +
        // candidateInfo.details 
        behaviorDescrption
        +
        "</p>"
      );
    });
  };

  let calculatedCoordinatesX;

  const xOffsetMap = {};

  const computeCoordinates = (candidate) => {
    const assessment = candidate?.candidate_profile_id?.assessments[0]?.assessment;

    if (assessment) {
      const results = assessment.result;
      let highestScore = -1;
      let behaviorType;

      for (let i = 0; i < results.length; i++) {
        const score = results[i]?.value;
        if (score > highestScore) {
          highestScore = score;
          behaviorType = results[i]?.type;
        }
      }

      if (!behaviorType) {
        throw new Error("Invalid behavior type.");
      }

      let startAngle, endAngle;

      switch (behaviorType) {
        case "I":
          startAngle = 0;
          endAngle = 90;
          candidateBehaviorType = "Interactive";
          behaviorDescrption = "Individuals with this default behavior trait tend to be exceedingly outgoing and persuasive, thriving on interactions with people, with a knack for communication and an optimistic outlook. Yet, their talkative nature can sometimes overshadow their focus on details."
          break;
        case "S":
          startAngle = 90;
          endAngle = 180;
          candidateBehaviorType = "Steady";
          behaviorDescrption = "Individuals with this default behavior trait tend to be balanced and team-oriented, valuing patience and, stability and structure. They operate at an even pace, often avoiding risks."
          break;
        case "C":
          startAngle = 180;
          endAngle = 270;
          candidateBehaviorType = "Cautious";
          behaviorDescrption = "Individuals with this default behavior trait tend to be precise and value-driven individuals who trust in structures, standards, and rules. They rely on data, facts, and analysis, showing discomfort with ambiguity."
          break;
        case "D":
          startAngle = 270;
          endAngle = 360;
          candidateBehaviorType = "Decisive";
          behaviorDescrption = "Individuals with this default behavior trait tend to be very convincing, straightforward, goal-oriented, and assertive individuals embracing challenges head-on. However, they can come across as aggressive or impatient due to their intense desire to lead."
          break;
        default:
          throw new Error("Invalid behavior type.");
      }

      // Calculate the specific angle within the quadrant based on the score
      const angle = startAngle + (endAngle - startAngle) * (highestScore / 100);
      const radius = 100 - highestScore; // Near center for high scores

      // Convert polar coordinates to Cartesian for plotting
      let x = radius * Math.cos(angle * (Math.PI / 180));

      // Adjust the angle based on behavior type
      if (x < 0) {
        switch (behaviorType) {
          case "I":
            x += 90;
            break;
          case "S":
            x += 180;
            break;
          case "C":
            x += 270;
            break;
          case "D":
            x += 360;
            break;
          default:
            x += 0;
        }
      }

      if (xOffsetMap[x] !== undefined) {
        const maxOffset = 40;
        const randomOffset = (Math.random() - 0.5) * maxOffset;

        if (Math.abs(randomOffset) <= maxOffset) {
          x += randomOffset;
          xOffsetMap[x] = randomOffset;
        }
      } else {
        xOffsetMap[x] = 0;
      }

      // Ensure X coordinate stays within bounds based on behavior type
      if (behaviorType === "I") {
        x = Math.max(2, Math.min(x, 88));
      } else if (behaviorType === "S") {
        x = Math.max(92, Math.min(x, 178));
      } else if (behaviorType === "C") {
        x = Math.max(182, Math.min(x, 268));
      } else if (behaviorType === "D") {
        x = Math.max(272, Math.min(x, 358));
      }

      let y = 100 - highestScore; 

      if (y < 7) {
        const randomYOffset = 5 + Math.random() * 5;
        y += randomYOffset;
      }

      if (behaviorType === "D") {
        if (x <= 272) {
          const randomXOffset = 15 + Math.random() * 5;
          x += randomXOffset + 30;
        }
      }
      calculatedCoordinatesX = x;
      return [[x, y]];
    }
  };

  useEffect(() => {
    initializeChart();
  }, [selectedCandidatesDetail, chartModal, behaviorChart]);

  return (
    <>
      <InfoBoxes
        hasIcon={false}
        title="Plotting location and presentation"
        TooltipText={
          <>
            <p>
              The TRIPA team chemistry plotting visualizes and helps you understand and develop how well your team functions and performs.
            </p>
            <br />
            <p>
              Assembling the ideal team is not easy; however, the TRIPA team chemistry provide the unconscious and psychological insights that influence each member of your team's performance. The team chemistry will help you lead your team with blended behavior styles, priorities, fears, motivations, and leverage your team's strengths, and work effectively with individual vulnerabilities and potential.
            </p>
          </>
        }
      >
        {/* <div
          className="team-dynamics-chart-wrapper"
          id="team-dynamics-chart-wrapper"
        >
          <AnyChart instance={tdChart} height={400} width="100%" />
        </div> */}
        <div className="ploating-polar-chart">
          <AnyChart instance={polarChart} height={400} width="100%" />
        </div>
      </InfoBoxes>
      <div className="flex flex-wrap items-center justify-between">
        <SuccessBtn
          onClick={() => setChartModal(true)}
          customStyle={{ marginTop: "28px" }}
        >
          Team Chemistry Chart
        </SuccessBtn>
        <SuccessBtn
          onClick={() => setBehaviorChart(true)}
          customStyle={{ marginTop: "28px" }}
        >
          Workforce Strategic Alignment
        </SuccessBtn>
        {/* <SuccessBtn
          onClick={() => setBehaviorChart(true)}
          customStyle={{ marginTop: "28px" }}
        >
          Team Insights
        </SuccessBtn> */}
      </div>
    </>
  );
}

export default PlottingLocPres;