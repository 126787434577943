import React from "react";
import BoxDescriptionInfo from "../../behavioralTraits/sharedcomponents/boxDescriptionInfo";
import { ReactComponent as Theoritical } from "../../../../assets/Theoritical.svg";
import getTitle from "../reusableComponent";
import { useSelector } from "react-redux";

// const list1 = [
//   "You demonstrate a logical approach to problem solving and patience to analyze all of the options for solutions.",
//   "You bring a strong knowledge-driven ethic.",
//   "You score as an active problem-solver, seeking solutions.",
//   "At a team meeting you will come prepared, with pre-work completed.",
// ];
// const list2 = [
//   "Realize that as much as you have learned, you still want to learn more.",
//   "Provide your knowledge and expertise whenever possible at team meetings, and when in problem-solving mode.",
//   "You can be a constructive critic for new ideas or when evaluating existing projects and protocol.",
//   "Be certain to look for knowledge-based incentives, such as new training courses, books, subscriptions, and journals.",
//   "You will provide technical credibility when dealing with customers or internal stakeholders who need detailed information for decision-making.",
// ];
// const list3 = [
//   "You score like those who may have their own on-going personal development program already in progress.",
//   "You enjoy learning even for its own sake and will be supportive of most training and development endeavors.",
//   "You can be depended upon to do your homework… thoroughly and accurately.",
//   "You are actively engaged in learning both on and off the job.",
// ];
// const list4 = [
//   "You score like those who may have their own on-going personal development program already in progress.",
//   "You enjoy learning even for its own sake and will be supportive of most training and development endeavors.",
//   "You can be depended upon to do your homework… thoroughly and accurately.",
//   "You are actively engaged in learning both on and off the job.",
// ];
// const list5 = [
//   "You may have a tendency to wait on some projects, especially if more helpful information may be forthcoming if more time is allowed on the calendar.",
//   "You may be somewhat selfish at times in sharing ideas with others, until others have established their own technical credibility.",
//   "Your sense of urgency may vacillate, depending on the intellectual importance you assign to the issue.",
//   "You may sometimes bog down in details and minutia when needing to see the big picture.",
//   "You need to bring a balance, at times, between the strong desire to acquire new knowledge base and the reality of the practical applications, if any.",
// ];

function TheoriticalTab() {
  const data = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.valueDescriptor?.find(({ section }) => section === 'the')?.subsection || []
  );

  const userLevel = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.score
  );

  return (
    <>
      <div className="flex my-6 md:flex-row flex-col">
        <div className="flex flex-col items-center justify-center mr-6 mb-3 md:mb-0">
          <Theoritical className="scale-150 mb-2" />
          <p className="text-2xl text-[#7F6000] font-bold">Theoretical</p>
        </div>
        {/* <p className="text-base text-pirmaryBlue">
          Theoretical is the motivation to understand, gain knowledge, or
          discover the "truth." This drive can often be to gain an understanding
          for knowledge's sake. Rational thinking, reasoning, and
          problem-solving are essential to this dimension. Your score shows a
          High score on Theoretical value. The following comments and
          information highlight the specifics to you:
          <div className="font-bold mt-5">
            You have a high-interest level in understanding all aspects of a
            situation or subject.
          </div>
        </p> */}
        <p className="text-base text-pirmaryBlue">
          The Theoretical driver reflects an individual's motivation to seek knowledge, understand concepts, and discover the truth. This drive is associated with rational thinking, problem-solving, and a curiosity to gain knowledge for its own sake.
          <div className="text-base text-pirmaryBlue mt-3">
            Your assessment score for this value is in the {userLevel[6]?.level} range.
          </div>
          <div className="text-base text-pirmaryBlue mt-3">
            This suggests that {userLevel[6]?.statement} However, remember that this is just one aspect of your overall value system, and other values may influence your choices differently.
          </div>
          <div className="font-bold mt-5">
            You have a high-interest level in understanding all aspects of a
            situation or subject.
          </div>
        </p>
      </div>

      <div className="grid mt-6 grid-cols-1 gap-6 md:grid-cols-2">
        {data?.map(({ title = '', statements = [] }, index) => (
          <BoxDescriptionInfo
            key={index}
            title={getTitle(title)}
            description=""
            caption=""
            list={statements}
          />
        ))}
      </div>
    </>
  );
}

export default TheoriticalTab;
