import React from "react";
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import { Autocomplete } from "@mui/material";

const useStyle = makeStyles((theme) => ({
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E3E6E9 !important",
      backgroundColor: "transparent !important",
      
      "& .css-1in441m": {
        display: "none",
      },
      "& .css-1ftyaf0": {
        display: "none",
      },
    },
    "& .MuiSelect-nativeInput": {
      bottom: "50%",
      transform: "translateY(50%)",
      left: "20px !important",
      opacity: 0,
    },
    "& .MuiSelect-icon": {
      right: "20px !important",
      fill: "#7E8B96 !important",
    },
    "& .MuiInputBase-input": {
      padding: "0px",
    },
  },
  inputLabel: {
    "&.Mui-focused ": {
      color: "#384259 !important",
    },
    "&.MuiInputLabel-root": {
      color: "#384259 !important",
      fontSize: "16px",
      transform: "none !important",
      position: "static !important",
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px",
      color: "#323131"
    }
  }
}));

function Autocompletes({
  onChanges,
  itemsData = [],
  label,
  labelId,
  id,
  value,
  name,
  placeholder,
  inputStyle,
  isMultiple = true,
  disabled = false,
  defaultValue = "",
  required = false,
  freeSolo = false,
}) {
  const classes = useStyle();
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth  disabled={disabled}>
        <InputLabel id={labelId} className={classes.inputLabel}>
          {label && label}
          {required ? " *" : null}
        </InputLabel>
        <Autocomplete
          multiple={isMultiple}
          id={id}
          name={name}
          options={itemsData}
          getOptionLabel={(option) => typeof option === "string" ? option : option?.name}
          freeSolo={freeSolo}
          onChange={onChanges}
          value={value}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                label={typeof option === "string" ? option : option?.name}
                {...getTagProps({ index })}
              />
            ));
          }}
          renderInput={(params) => (
            <TextField
              classes={{ root: classes.customTextField }}
              {...params}
              placeholder={placeholder}
            />
          )}
          sx={inputStyle}
          className={`${
            classes.select
          }`}
        />
      </FormControl>
    </Box>
  );
}

export default Autocompletes;
