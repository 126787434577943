import React from "react";
import BoxDescriptionInfo from "../../behavioralTraits/sharedcomponents/boxDescriptionInfo";
import { ReactComponent as Altruistic } from "../../../../assets/Altruistic.svg";
import getTitle from "../reusableComponent";
import { useSelector } from "react-redux";

// const list1 = [
//   `Your philosophy is "Better to give than receive," sometimes even in an organizational environment.`,
//   "You have a strong desire to help others grow and develop as professionals.",
//   "Your high sincerity-factor may be exhibited in your tone of voice in communicating with others.",
//   "You have a high need to help and support others on their own pathway to success.",
//   "You find value in work, beyond just a paycheck, especially when it aids others.",
// ];
// const list2 = [
//   "Self-monitor to make sure your giving doesn't become too much or impractical.",
//   "You should remember that you tend to be a very 'giving' person, and enjoy helping others.",
//   "You should provide flexibility to allow for helping others on the team.",
//   "You should provide an environment in which there is opportunity to help others achieve and grow professionally.",
//   "You should treat yourself with the same level of sincere concern and interest that you give others.",
// ];
// const list3 = [
//   "You are a very good team player.",
//   "You are enthusiastic and enjoy working in team environments or social settings.",
//   "You have an empathetic attitude towards others.You treat others with high personal regard and respect.",
//   "You treat others with high personal regard and respect.",
//   "You are often very aware of what others need, even when not stated by them.",
// ];
// const list4 = [
//   "Your learning and development should be linked to the potential to help others.",
//   "You should link courses and training to the knowledge gained that may potentially be shared with others on the team, or externally.",
//   "Your training and professional development will assist in personal growth and development and in being better at any job role.",
// ];
// const list5 = [
//   "You need to remember that support and service efforts need to be practical as well.",
//   "You can give away too much time, talent and energy.",
//   `You can lose focus on personal work in favor of helping others with theirs. § You may need to say "no" more often.`,
//   "You could benefit from resisting the urge to go into teaching mode unless clearly desired by others.",
// ];

function AltruisticTab() {
  const data = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.valueDescriptor?.find(({ section }) => section === 'alt')?.subsection || []
  );

  const userLevel = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'DriverOfEngagementDiagnostic'
    )?.assessment?.assessment_content?.score
  );

  return (
    <>
      <div className="flex md:flex-row flex-col my-6">
        <div className="flex flex-col items-center justify-center mr-6 mb-3 md:mb-0">
          <Altruistic className="scale-150 mb-2" />
          <p className="text-[24px] text-[#E5C311] font-bold">Altruistic</p>
        </div>
        {/* <p className="text-base text-pirmaryBlue">
          This dimension is an expression of the need or drives to benefit
          others in a humanitarian sense. There is a genuine sincerity in this
          dimension to help others, give one’s time, resources, and energy to
          aid others. Your score shows an Average score on Altruist value. The
          following comments and information highlight the specifics to you:
          <div className="font-bold mt-5">
            You can perceive and create a balance between the need for economic
            return and other requirements.
          </div>
        </p> */}
        <p className="text-base text-pirmaryBlue">
          The dimension of altruism refers to the desire and motivation to benefit others in a humanitarian sense. It involves a sincere willingness to assist others by devoting time, resources, and energy.
          <div className="text-base text-pirmaryBlue mt-3">
            Your assessment score for this value is in the {userLevel[4]?.level} range.
          </div>
          <div className="text-base text-pirmaryBlue mt-3">
            This suggests that {userLevel[4]?.statement} However, remember that this is just one aspect of your overall value system, and other values may influence your choices differently.
          </div>
          <div className="font-bold mt-5">
            You can perceive and create a balance between the need for economic
            return and other requirements.
          </div>
        </p>
      </div>

      <div className="grid mt-6 grid-cols-1 gap-6 md:grid-cols-2">
        {data?.map(({ title = '', statements = [] }, index) => (
          <BoxDescriptionInfo
            key={index}
            title={getTitle(title)}
            description=""
            caption=""
            list={statements}
          />
        ))}
      </div>
    </>
  );
}

export default AltruisticTab;
