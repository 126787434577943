import React from "react";
import WhiteBox from "../../../../../sharedComponents/whiteBox/WhiteBox";

import PasswordField from "../../../../../sharedComponents/PasswordField/PasswordField";
import SuccessBtn from "../../../../../sharedComponents/buttons/successBtn/SuccessBtn";

import { toast } from "react-toastify";
import { changePassword } from "../../../../../../../api/hr";

function ChangePassword() {
  let initData = {
    currentPassword: "",
    newPssword: "",
    confirmNewPssword: "",
  }
  const [data, setData] = React.useState(initData);
  
  const resetForm = () => setData(initData)

  // const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  // const [showNewPssword, setShowNewPssword] = React.useState(false);
  // const [showConfirmNewPssword, setShowConfirmNewPssword] =
  //   React.useState(false);

  const handleChangeData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const formValidate = (values) => {
    const myError = 0;
    const regularExpressionPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!values.currentPassword) {
      toast.error("Current password is required.");
      return 1;
    }
    if (!values.newPssword) {
      toast.error("New Pssword is required.");
      return 1;
    } else if (values.newPssword.length < 8) {
      toast.error("Password must be more than 8 characters.");
      return 1;
    } else if (!regularExpressionPassword.test(values.newPssword)) {
      toast.error("The password you create must be a combination of alphanumeric characters, which includes both letters (A-Z, a-z) and numbers (0-9). In addition, at least one special character (such as !, @, #, $, %, &, *, etc.) should be included. Please ensure you adhere to these guidelines when setting your password.");
      return 1;
    } else if (values.newPssword === values.currentPassword) {
      toast.error("New password and current password can not be the same.");
      return 1;
    }
    if (!values.confirmNewPssword) {
      toast.error("Confirm password is required.");
      return 1;
    } else if (values.confirmNewPssword !== values.newPssword) {
      toast.error("Confirm password and New passwordis is not the same.");
      return 1;
    }
    return myError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = formValidate(data);
    if (!error) {
      try {
        let body = {
          password: data.currentPassword,
          newPassword: data.newPssword
        }
        const res = await changePassword(body)
        resetForm()
        toast.success("Password changed");  
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <WhiteBox
      title="Change password"
      titleStyle={{ fontSize: "24px", margin: "20px auto !important" }}
      style={{ height: "87%" }}
    >
      <form action="#" className="" onSubmit={handleSubmit}>
        <PasswordField
          // isPasswordShow={showCurrentPassword}
          // setIsPasswordShow={setShowCurrentPassword}
          name={"currentPassword"}
          value={data.currentPassword || ""}
          label={"Current password"}
          labelFor={"currentPassword"}
          id={"currentPassword"}
          onChange={handleChangeData}
          placeholder={"Enter your password"}
          className={"focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"}
        />
        <PasswordField
          // isPasswordShow={showNewPssword}
          // setIsPasswordShow={setShowNewPssword}
          name={"newPssword"}
          value={data.newPssword || ""}
          label={"New Pssword"}
          labelFor={"newPssword"}
          id={"newPssword"}
          onChange={handleChangeData}
          placeholder={"Enter your password"}
          className={"focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"}

        />
        <PasswordField
          // isPasswordShow={showConfirmNewPssword}
          // setIsPasswordShow={setShowConfirmNewPssword}
          name={"confirmNewPssword"}
          value={data.confirmNewPssword || ""}
          label={"Confirm new password"}
          labelFor={"confirmNewPssword"}
          id={"confirmNewPssword"}
          onChange={handleChangeData}
          placeholder={"Enter your password"}
          className={"focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"}
        />
        <div className="flex justify-end items-center mt-[25px]">
          <SuccessBtn type="submit">Update Password</SuccessBtn>
        </div>
      </form>
    </WhiteBox>
  );
}

export default ChangePassword;
