import React, { useState, useEffect } from 'react'
import SuccessBtn from '../../../sharedComponents/buttons/successBtn/SuccessBtn'
import { jobFunctins } from '../../../../contants/functions'
import DangerLightBtn from '../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn'
import { toast } from 'react-toastify'
import { postJCProfile } from '../../../../../api/jc'
import { MenuItem, Select } from '@mui/material'

export default function FunctionArea({ selected2, setSelected2, all, handleReload }) {
  const [selected, setSelected] = useState("")
  const handleSubmit = () => {
    let help = [...all]
    let helpIndex = all.findIndex(item => item === selected2)
    console.log(helpIndex)
    if (selected !== "") {
      if (helpIndex > -1) {
        help[helpIndex] = selected;
        let fd = new FormData();
        fd.append("body", JSON.stringify({ function_area_preferences: help }));
        postJCProfile(fd)
          .then((res) => {
            console.log(res.data);
            handleReload && handleReload();
            toast.success("Updated.")
          })
      } else {
        let fd = new FormData();
        help.push(selected);
        fd.append("body", JSON.stringify({ function_area_preferences: help }));
        postJCProfile(fd)
          .then((res) => {
            handleReload && handleReload();
            toast.success("Updated.")
            console.log(res.data)
          })
      }
    } else {
      toast.warn("You need to select an item.")
    }
  }

  const deleteItem = () => {
    const data = all?.filter(x => x !== selected2) || [];
    let fd = new FormData();
    fd.append("body", JSON.stringify({ function_area_preferences: data }));
    postJCProfile(fd).then(res => {
      toast.success('Deleted!');
      setSelected("");
      handleReload && handleReload();
    }).catch(e => toast.error('Error While deleting!'));
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
  useEffect(() => {
    return () => setSelected("")
  }, [])
  return (
    <>
      <Select 
        displayEmpty 
        value={selected || selected2}
        onChange={(e) => {
          setSelected(e.target.value)
        }}
        MenuProps={MenuProps} 
        placeHolder="Please select" className='w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none'>
        <MenuItem value='' selected disabled>Please Select</MenuItem>
        {
          jobFunctins.map((item, index) => (
            <MenuItem selected={selected2 && selected2 === item} disabled={all.find((val) => val === item)} value={item} key={item}>{item}</MenuItem>
          ))
        }
      </Select>
      <div className='mt-2 col-span-2 flex justify-between items-center'>
        <div>
          {
            selected2 &&
            <DangerLightBtn>
              <span className="font-bold" onClick={deleteItem}>
                Delete
              </span>
            </DangerLightBtn>
          }
        </div>
        <SuccessBtn onClick={handleSubmit} >
          <span className="font-bold">
            {selected2 ? 'Update' : 'Submit'}
          </span>
        </SuccessBtn>
      </div>
    </>
  )
}
