import React from "react";
import { Avatar } from "@mui/material";

function Profile({ src, alt, name = '', last_name = '', email }) {

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontSize: '35px',
        fontWeight: "700",
        margin: '0 auto',
        width: "96px",
        height: "96px",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (fullName) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  return (
    <div className="d-flex justify-center items-center text-center">
      {src ? (
        <img
          alt={alt}
          src={src}
          className="mx-auto rounded-full w-[96px] h-[96px]"
          // sx={{ width: "96px", height: "96px" }}
        />
      ) : (
        <Avatar {...stringAvatar(`${name} ${last_name}`)} />
      )}
      {(name || email) && (
        <div>
          <h3 className="text-white">{name}</h3>
          <p className="text-secondaryBalck">{email}</p>
        </div>
      )}
    </div>
  );
}

export default Profile;
