import React from "react";
import WhiteBox from "../shared/WhiteBox";
import { ReactComponent as UploadIcon } from "../../../../assets/uploadIcon.svg";
import { toast } from "react-toastify";
import { postUploadResume } from "../../../../../api/jc";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { updateResume } from "../../../../../redux/actions/steps";
import { useDispatch } from "react-redux";

function UploadResume() {
  const formData = new FormData();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2000000) {
        toast.error("The file is more than 2MB");
      } else {
        setLoading(true);
        formData.append("file", file, file.name);
        postUploadResume(formData)
          .then((res) => {
            console.log("upload resume result", res.data);
            dispatch(updateResume(res.data));
            navigate('/resumeform/form')
            // handleReload()
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      }
    }
  };

  return (
    <WhiteBox title="Upload your most recent résumé | Almost here ! &#128077;">
      <p className="mb-5 text-[#384259]">
        We need to collect additional information to help you discover your
        Unique Selling Proposition (USP).
      </p>
      <p className="mb-5 text-[#384259]">
        Please Include your most recent résumé.
      </p>

      <div
        className="relative w-full h-[230px] flex flex-col items-center justify-center p-4 rounded-[10px] bg-[#F3F6F9] border-dashed border-[2px] border-[#C4C4C4] cursor-pointer"
      >
        <input
          type="file"
          id="uplodFile"
          accept="application/pdf,application/msword,
          application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          className="absolute h-full w-full opacity-0"
          onChange={handleUpload}
        />
        {isLoading ? (
          <CircularProgress color="success" />
        ) : (
          <>
            <UploadIcon />
            <h2 className="text-base text-[#000000] font-bold mt-[10px]">
              Upload your most recent résumé
            </h2>
            <p className="text-[#000000] ">or drag and drop it here</p>
            <span className="text-[#828282] text-[13px] mt-[10px]">
              DOC, DOCX, PDF upto 2MB are supported
            </span>
          </>
        )}
      </div>

      <p className="text-[#384259] font-normal pt-5 mb-3">
        {" "}
        or{" "}
        <span onClick={
            () => navigate('/resumeform/form')
        } className="text-[#7AB794] cursor-pointer"> Type your résumé</span>
      </p>
      
      <p className="text-base font-bold text-pirmaryBlue mb-3">
        Please validate your résumé to ensure the CV parsing is accurate, as the automation may omit or misplace some details. If you have substantial information to validate, please ensure that you enter at least one work experience and education.
      </p>
      <p className="text-base text-pirmaryBlue mb-6">
        You can always update your résumé when you are ready. However, we encourage you to keep your profile up-to-date.
      </p>
    </WhiteBox>
  );
}

export default UploadResume;
