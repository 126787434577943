import React from "react";
import Modal from "../../../../../../sharedComponents/modal/Modal";
import SelectField from "../../../../../../sharedComponents/selectField/SelectField";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import InfoBoxes from "../../createJob/reuseableComponent";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import SuccessBtn from "../../../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import { postHRJobPost } from "../../../../../../../../api/hr";

import { jobFunctins } from "../../../../../../../contants/functions";
import { industries } from "../../../../../../../contants/industries";
import { currency } from "../../../../../../../contants/currency";
import { allLanguages } from "../../../../../../../contants/languages";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { ContentState } from "draft-js";
import { convertFromHTML } from "draft-js";
import PurpleBtn from "../../../../../../sharedComponents/buttons/purpleBtn/PurpleBtn";
import {
  competenciesData,
  educationData,
  experienceData,
  otherCompetenciesList,
  skillsData,
  subCompetenciesData,
} from "../../../../../../../contants/jobReqData";
import { Avatar, Divider, Tooltip } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { CustomTooltip } from "../../../dashboard/reusableComponent";
import datas from "../../../../../../../contants/cities.json"
import Autocompletes from "../../../../../../sharedComponents/Autocompletes/Autocompletes";
import MultipleSelectCheckmarks from "../../../../../../sharedComponents/selectField/MultipleSelectCheckmarks";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import { DeleteOutlined } from "@mui/icons-material";

function EditModal({
  selectedJob,
  open,
  setOpen,
  isDraft = false,
  getAllJobs,
  changeTab,
}) {
  const [data, setData] = React.useState(selectedJob);
  const [image, setImage] = React.useState();
  const [formErrors, setFormErrors] = React.useState({});
  const [editorState, setEditorState] = React.useState({
    your_mission: "",
    your_focus: "",
    why_you: "",
    why_us: "",
  });


  const countries = Object.keys(datas);
  const cities = data?.country ? datas[data?.country] : [];

  const handleImgFile = (e) => {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImage(e.target.result);
      setData((prev) => ({ ...prev, image: files[0] }));
    };
  };

  const deleteImage = () => {
    setImage();
    setData((prev) => ({ ...prev, image: "" }));
  };

  const handleChangeData = async (e) => {
    const name = e.target.name;
    let value = null;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
      await setData((prev) => ({ ...prev, [name]: true }));
    } else {
      value = e.target.value;
      if(name==='competencies'){
        let newVal = [];
        const subRoleCompData = subCompetenciesData?.find((x) => x.value === value);
        subRoleCompData.children.forEach(data => {
            newVal.push(data.value);
        })
        await setData((prev) => ({ ...prev, sub_competencies: newVal }));
      }
      await setData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAutoCompeteData = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoleBasedCometencies = (removedRole) => {
    const competenciesData = data.sub_competencies?.filter((role) => role !== removedRole);
    setData((prev) => ({ ...prev, sub_competencies: competenciesData }));
  };

  const handleAdditionalRoleBasedCometencies = (removedRole) => {
    const competenciesData = data.additional_competencies?.filter((role) => role !== removedRole);
    setData((prev) => ({ ...prev, additional_competencies: competenciesData }));
  };

  const handleEditor = (event, name) => {
    let editorSourceHTML = draftToHtml(convertToRaw(event.getCurrentContent()));
    setData((prev) => ({
      ...prev,
      [name]: editorSourceHTML,
    }));
    setEditorState((prev) => ({
      ...prev,
      [name]: event,
    }));
  };

  const validate = (values) => {
    let errors = {};
    
    if (!values.company_name) {
      errors.company_name = "This is required field";
    }
    if (!values.job_title) {
      errors.job_title = "This is required field";
    }
    if (!values.job_function) {
      errors.job_function = "This is required field";
    }
    if (!values.company_industry) {
      errors.company_industry = "This is required field";
    }
    if (!values.country) {
      errors.country = "This is required field";
    }
    if (!values.city) {
      errors.city = "This is required field";
    }
    if (!values.employment_type) {
      errors.employment_type = "This is required field";
    }
    if (!values.salary_currency) {
      errors.salary_currency = "This is required field";
    }
    if (!values.salary_range_from) {
      errors.salary_range_from = "This is required field";
    }
    if (!values.salary_range_to) {
      errors.salary_range_to = "This is required field";
    }
    if (!values.seniority_level) {
      errors.seniority_level = "This is required field";
    }
    
    if (values.skills && values.skills.length<=0) {
      errors.skills = "This is required field";
    }
    if (values.education && values.education.length<=0) {
      errors.education = "This is required field";
    }
    if (values.languages && values.languages.length<=0) {
      errors.languages = "This is required field";
    }
    if (!values.experience) {
      errors.experience = "This is required field";
    }
    if (!values.competencies) {
      errors.competencies = "This is required field";
    }    
    if (values.additional_competencies && values.additional_competencies.length>10) {
      errors.additional_competencies = "You are only allowed to add a maximum of 10 additional competencies";
    }

    if (!values.application_through_tripa) {
      errors.application_through_tripa = "This is required field";
    }
        
    return errors;
  };

  const handleEdit = async (isDraft = false) => {
    try {
      
      let formErrors = {}
      if (!isDraft) {
        formErrors = validate(data)
        setFormErrors(formErrors)
      }
      
      if (Object.keys(formErrors).length === 0) {
        let body = {
          ...data,
          id: data._id,
          salary_range_from: data.salary_range_from.replace(/\s/g, ''),
          salary_range_to: data.salary_range_to.replace(/\s/g, ''),
          is_draft: isDraft,
        };
        
        await postHRJobPost(body);
        if (!isDraft && changeTab) changeTab(1);
        getAllJobs();
        setOpen(false);
      }      
    } catch (error) {
      console.error(error);
    }
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontSize: '35px',
        fontWeight: "700",
        width: "6rem",
        height: "6rem",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (name) => {
    const allNames = name.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  const getEditorState = (value) => {
    const blocksFromHtml = convertFromHTML(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState);
  };

  React.useEffect(() => {
    if (selectedJob) {
      setData(selectedJob);
      setImage(selectedJob.logo_file || "");
      setEditorState({
        your_mission: getEditorState(selectedJob.your_mission || ""),
        your_focus: getEditorState(selectedJob.your_focus || ""),
        why_you: getEditorState(selectedJob.why_you || ""),
        why_us: getEditorState(selectedJob.why_us || ""),
      });
    }
  }, [selectedJob]);

  return (
    <Modal
      title="Update job"
      open={open}
      setOpen={setOpen}
      mdUpWidth={950}
      lgUpWidth={1050}
      xlUpWidth={1150}
    >
      <div className="relative inline-block">
        <span className="w-[105px] h-[105px] bg-white border-2 border-solid border-[#E5E5E5] flex justify-center items-center md:mr-[12px] mb:my-0">
          {image ? (
            <img src={image} alt="" className="h-full" />
          ) : (
            <Avatar {...stringAvatar(`${data?.company_name || 'T'}`)} />
          )}
        </span>
        {image && (
          <ClearIcon
            onClick={deleteImage}
            className="absolute top-[-10px] right-[-10px] bg-[#FECACA] text-[#DC2626] rounded-full p-1"
          />
        )}
        <label
          htmlFor="updateImg"
          className="absolute bottom-[-10px] right-[-10px]  cursor-pointer"
        >
          <ModeEditIcon className="bg-[#7AB794] text-[#FFFFFF] rounded-full p-1" />
        </label>
        <input
          type="file"
          name="image"
          onChange={handleImgFile}
          className="hidden"
          id="updateImg"
        />
      </div>

      <div className="grid md:grid-cols-auto-fit-450 grid-cols-auto-fit-250 gap-4 my-6">
        <div className="flex flex-col">
          <label
            htmlFor="company_name"
            className="text-pirmaryBlue text-base font-normal"
          >
            Company Name *
          </label>
          <input
            type="text"
            name="company_name"
            value={data.company_name || ""}
            onChange={handleChangeData}
            id="company_name"
            placeholder="Company Name"
            className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
          />
          {formErrors.company_name && (
            <span className="text-red-500">{formErrors.company_name}</span>
          )}
        </div>

        <div className="flex flex-col relative">
          <label
            htmlFor="job_title"
            className="text-pirmaryBlue text-base font-normal flex items-center"
          >
            Job Title *
            <InfoBoxes TooltipTitle='Job title' TooltipText='Job title is the official name that describes the job or positions your employee holds'></InfoBoxes>
          </label>
          <input
            type="text"
            name="job_title"
            value={data.job_title || ""}
            onChange={handleChangeData}
            id="job_title"
            placeholder="Job Title"
            className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
          />
          {formErrors.job_title && (
            <span className="text-red-500">{formErrors.job_title}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={countries}
            label="Country"
            labelId="country"
            id="country"
            value={data.country}
            onChange={handleChangeData}
            name="country"
            placeholder="Country"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.country && (
            <span className="text-red-500">{formErrors.country}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={cities}
            label="City"
            labelId="city"
            id="city"
            value={data.city}
            onChange={handleChangeData}
            name="city"
            placeholder="Select City"
            inputStyle={{ padding: "14px" }}
            required
            disabled={cities?.length > 0 ? false : true}
          />
          {formErrors.city && (
            <span className="text-red-500">{formErrors.city}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={jobFunctins}
            label="Job function"
            labelId="job_function"
            id="job_function"
            value={data.job_function}
            onChange={handleChangeData}
            name="job_function"
            placeholder="Job Function"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.job_function && (
            <span className="text-red-500">{formErrors.job_function}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={[
              "Full time",
              "Part time",
              "Contract",
              "Temporary",
              "Volunteer",
              "Internship",
            ]}
            label="Employment type"
            labelId="employment_type"
            id="employment_type"
            value={data.employment_type}
            onChange={handleChangeData}
            name="employment_type"
            placeholder="Employment type"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.employment_type && (
            <span className="text-red-500">{formErrors.employment_type}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={industries}
            label="Company industry"
            labelId="company_industry"
            id="company_industry"
            value={data.company_industry}
            onChange={handleChangeData}
            name="company_industry"
            placeholder="Company Industry"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.company_industry && (
            <span className="text-red-500">{formErrors.company_industry}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={[
              "Intership",
              "Entry level",
              "Mid-Senior level",
              "Associate",
              "Director",
              "Executive",
            ]}
            label="Seniority Level"
            labelId="seniority_level"
            id="seniority_level"
            value={data.seniority_level}
            onChange={handleChangeData}
            name="seniority_level"
            placeholder="Seniority Level"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.seniority_level && (
            <span className="text-red-500">{formErrors.seniority_level}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={currency}
            label="Currency"
            labelId="salary_currency"
            id="salary_currency"
            value={data.salary_currency}
            onChange={handleChangeData}
            name="salary_currency"
            placeholder="Currency"
            inputStyle={{ padding: "14px" }}
            required
          />
          {formErrors.salary_currency && (
            <span className="text-red-500">{formErrors.salary_currency}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="companyName"
            className="text-pirmaryBlue text-base font-normal flex items-center"
          >
            Salary *
            <span className="ml-1">
              <CustomTooltip
                TooltipTitle={"Salary range"}
                TooltipText={
                  "When entering your salary expectation, ensure you separate the three spaces that indicate thousands. For instance, 1000 should be 1 000; 20000 should be 20 000, and 150000 should be 150 000"
                }
              />
            </span>
          </label>
          <div className="grid grid-cols-auto-fit-200 gap-4">
            <div className="flex flex-col">
            <NumericFormat
              name="salary_range_from"
              value={data.salary_range_from || ""}
              onChange={handleChangeData}
              id="salary_range_from"
              placeholder="From"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded "
              thousandSeparator={' '}
              thousandsGroupStyle='thousand'
            />
            {formErrors.salary_range_from && (
              <span className="text-red-500">{formErrors.salary_range_from}</span>
            )}
            </div>
            <div className="flex flex-col">
            <NumericFormat
              name="salary_range_to"
              value={data.salary_range_to || ""}
              onChange={handleChangeData}
              id="salary_range_to"
              placeholder="To"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded  "
              thousandSeparator={' '}
              thousandsGroupStyle='thousand'
            />
            {formErrors.salary_range_to && (
              <span className="text-red-500">{formErrors.salary_range_to}</span>
            )}
            </div>
          </div>
        </div>
      </div>

      <InfoBoxes
        title="Your mission"
        TooltipTitle="Your mission"
        TooltipText="Provide a summary of the role and what the organization expects the job candidate to accomplish"
      >
        <Editor
          editorState={editorState.your_mission}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(event) => {
            handleEditor(event, "your_mission");
          }}
        />
      </InfoBoxes>

      <InfoBoxes
        title="Your focus"
        TooltipTitle="Your focus"
        TooltipText="List the activities and tasks necessary to achieve the role's mission."
      >
        <Editor
          editorState={editorState.your_focus}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(event) => {
            handleEditor(event, "your_focus");
          }}
        />
      </InfoBoxes>

      <InfoBoxes
        title="Why you"
        TooltipTitle="Why you"
        TooltipText="List education, skills, and abilities expect the job candidate has to achieve the mission."
      >
        <Editor
          editorState={editorState.why_you}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(event) => {
            handleEditor(event, "why_you");
          }}
        />
      </InfoBoxes>

      <InfoBoxes
        title="Why us"
        TooltipTitle="Why us"
        TooltipText="Provide a succinct summary of the reasons why the job candidate should work for you."
      >
        <Editor
          editorState={editorState.why_us}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={(event) => {
            handleEditor(event, "why_us");
          }}
        />
      </InfoBoxes>
      
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 mt-[16px]'>
        <div className="">
          <Autocompletes
            itemsData={skillsData}
            label='Skill'
            labelId='skills'
            id='skills'
            value={data.skills}
            onChanges={(e, val) => {
              handleAutoCompeteData('skills', val)
            }}
            name='skills'
            placeholder='Type skill'
            isMultiple={true}
            freeSolo={true}
          />
          {formErrors.skills && (
            <span className="text-red-500">{formErrors.skills}</span>
          )}
        </div>
        <div className="">
          <Autocompletes
            itemsData={educationData}
            label='Education'
            labelId='education'
            id='education'
            value={data.education}
            onChanges={(e, val) => {
              handleAutoCompeteData('education', val)
            }}
            name='education'
            placeholder='Type education'
            isMultiple={true}
            freeSolo={true}
          />
          {formErrors.education && (
            <span className="text-red-500">{formErrors.education}</span>
          )}
        </div>
      </div>
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 mt-[16px]'>
        <div>
          <Autocompletes
            itemsData={allLanguages.map((option) => option.name)}
            label='Languages'
            labelId='languages'
            id='languages'
            value={data.languages}
            onChanges={(e, val) => {
              handleAutoCompeteData('languages', val)
            }}
            name='languages'
            placeholder='Type language'
            isMultiple={true}
          />
          {formErrors.languages && (
            <span className="text-red-500">{formErrors.languages}</span>
          )}
        </div>
        <div className="">
          <SelectField
            selectItems={experienceData}
            label='Work experience'
            labelId='experience'
            id='experience'
            value={data.experience}
            onChange={handleChangeData}
            name='experience'
            placeholder='Select work experience'
            inputStyle={{ padding: '14px' }}
          />
          {formErrors.experience && (
            <span className="text-red-500">{formErrors.experience}</span>
          )}
        </div>
        </div>
      <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 mt-[16px]'>
        <div className="">
          <SelectField
            selectItems={competenciesData}
            label='Role-based competencies'
            labelId='competencies'
            id='competencies'
            value={data.competencies}
            onChange={handleChangeData}
            name='competencies'
            placeholder='Select role-based competencies'
            inputStyle={{ padding: '14px' }}
          />
          {formErrors.competencies && (
            <span className="text-red-500">{formErrors.competencies}</span>
          )}
        </div>
        <div className="">
          <label className="text-base text-pirmaryBlue">
            Additional competencies
            <Tooltip
              placement="top"
              title={
                <div className="rounded-md">
                  <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                    Additional competencies
                  </div>
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    You can customize the selected role by adding up to 10 more competencies. 
                    <br/><br/>
                    You can customize the role in batches of 3, 6, and 10. However, the customization has an additional cost that will be invoiced at the end of your billing cycle.
                  </div>
                </div>
              }
              arrow
            >
              <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
            </Tooltip>
          </label>
          <MultipleSelectCheckmarks
            selectItems={otherCompetenciesList}
            labelId='roleBasedCometencies'
            id='roleBasedCometencies'
            value={data.additional_competencies}
            onChange={handleChangeData}
            name='additional_competencies'
            placeholder='Select additional competencies'
            isMultiple={true}
          />
          {formErrors.additional_competencies && (
            <span className="text-red-500">{formErrors.additional_competencies}</span>
          )}
        </div>
      </div>

      <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3 mt-[16px]'>
        <div className='flex flex-col'>
          {data.sub_competencies?.map((roleBasedItem) => (
            <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {roleBasedItem}
                <DeleteOutlined onClick={() => handleRoleBasedCometencies(roleBasedItem)} style={{color: 'red'}} />
            </div>
            <Divider style={{margin: '5px 0'}} />
            </div>
          ))}
        </div>
        <div className='flex flex-col'>
          {data.additional_competencies?.map((additionalRoleBasedItem) => (
            <div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {additionalRoleBasedItem}
                <DeleteOutlined onClick={() => handleAdditionalRoleBasedCometencies(additionalRoleBasedItem)} style={{color: 'red'}} />
            </div>
            <Divider style={{margin: '5px 0'}} />
            </div>
          ))}
        </div>
      </div>

      <div className="grid md:grid-cols-auto-fit-450 grid-cols-auto-fit-250 gap-4 my-6">
        <div className="">
          <SelectField
            selectItems={["Yes (Recommended)", "No"]}
            label="Applicants can apply through TRIPA"
            labelId="application_through_tripa"
            id="application_through_tripa"
            value={data.application_through_tripa}
            onChange={handleChangeData}
            name="application_through_tripa"
            placeholder="Select"
            inputStyle={{ padding: "14px" }}
          />
          {formErrors.application_through_tripa && (
            <span className="text-red-500">{formErrors.application_through_tripa}</span>
          )}
        </div>
        <div className="">
          <label htmlFor="web_url" className="text-pirmaryBlue text-base font-normal">
            Direct your job applicants to your external web site(s) to apply
          </label>
          <input
            type="text"
            name="web_url"
            value={data.web_url || ""}
            onChange={handleChangeData}
            id="web_url"
            placeholder="www.yourdomain.com"
            className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded w-full"
            disabled={true}
          />
        </div>
        <div className="">
          <SelectField
            selectItems={["Posted", "Option 2", "Option 3"]}
            label="Add your job candidata funnel"
            labelId="job_funnel"
            id="job_funnel"
            value={data?.job_funnel || ""}
            onChange={handleChangeData}
            name="job_funnel"
            placeholder="Select"
            inputStyle={{ padding: "14px" }}
            disabled={true}
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="open_jobposting" className="text-pirmaryBlue text-base font-normal">
            Keep Job posting open until
          </label>
          <input
            id="open_jobposting"
            type="date"
            name="open_jobposting"
            value={data.open_jobposting}
            onChange={handleChangeData}
            min={moment().format("YYYY-MM-DD")}
            className="focus:outline-none border-2 border-solid border-grayLight p-3 rounded"
          />
        </div>
      </div>

      <div className="flex justify-end gap-x-2 mt-6">
        <SuccessBtn onClick={() => handleEdit(isDraft)}>Update</SuccessBtn>
        {isDraft && (
          <PurpleBtn onClick={() => handleEdit()}>Post job</PurpleBtn>
        )}
      </div>
    </Modal>
  );
}

export default EditModal;
