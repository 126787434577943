import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { a11yProps, TabPanel, tabsStyle } from './reuseabelComponent';
import SubscriptionBilling from './SubscriptionBilling';
import AccountProfile from './profile';

function AccountSettings() {
  const { state } = useLocation();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = tabsStyle();

  React.useEffect(() => {
    console.log(state);
    if (state?.tab) setValue(state.tab);
  }, [state]);

  return (
    <div className='mt-10'>
      <Tabs
        className={classes.root}
        value={value}
        onChange={handleChange}
        variant='scrollable'
        allowScrollButtonsMobile
        aria-label='basic tabs example'
        sx={{
          '& .MuiTabs-scroller': {
            borderRadius: '4px',
            color: 'white',
          },
          '&.MuiTabs-root': {
            '&.MuiTabs-scroller': {
              '&.MuiTabs-indicator': {
                backgroundColor: 'red !important',
              },
            },
          },
        }}
      >
        <Tab label='My Profile' {...a11yProps(0)} className={classes.tab} />
        <Tab
          label='Subscription & Billing'
          {...a11yProps(1)}
          className={classes.tab}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        <AccountProfile />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <SubscriptionBilling />
      </TabPanel>
    </div>
  );
}

export default AccountSettings;
