import React from "react";
import Modal from "../../../sharedComponents/modal/Modal";
import RedImg from "../../../../assets/characteristicIconRED.svg";
import personBlueImg, {
  ReactComponent as BlueImg,
} from "../../../../assets/characteristicIconBlue.svg";
import tripaLogo from '../../../../assets/logo_bg.png'
import ModalCard from "./ModalCard";
import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  voiceTone: {
    position: "relative",
    "&::before": {
      content: '""',
      right: "4px",
      width: "483px",
      display: "block",
      position: "absolute",
      bottom: 0,
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(254deg)",
      transformOrigin: "top right",
    },
  },
  fears: {
    position: "relative",
    "&::before": {
      content: '""',
      left: "-321px",
      width: "452px",
      display: "block",
      position: "absolute",
      bottom: 0,
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(270deg)",
      transformOrigin: "top right",
    },
  },
  verbalComminication: {
    position: "relative",
    "&::before": {
      content: '""',
      left: "-481px",
      width: "481px",
      display: "block",
      position: "absolute",
      bottom: 0,
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(286deg)",
      transformOrigin: "top right",
    },
  },
  underPressure: {
    position: "relative",
    "&::before": {
      content: '""',
      right: "1px",
      width: "275px",
      display: "block",
      position: "absolute",
      bottom: 0,
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(248deg)",
      transformOrigin: "top right",
    },
  },
  nonVerbal: {
    position: "relative",
    "&::before": {
      content: '""',
      left: "-274px",
      width: "275px",
      display: "block",
      position: "absolute",
      bottom: 0,
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(292deg)",
      transformOrigin: "top right",
    },
  },
  growthArea: {
    position: "relative",
    "&::before": {
      content: '""',
      right: "1px",
      width: "120px",
      display: "block",
      position: "absolute",
      bottom: "50%",
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(221deg)",
      transformOrigin: "top right",
    },
  },
  priority: {
    position: "relative",
    "&::before": {
      content: '""',
      left: "-120px",
      width: "120px",
      display: "block",
      position: "absolute",
      bottom: "50%",
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(319deg)",
      transformOrigin: "top right",
    },
  },
  workPlace: {
    position: "relative",
    "&::before": {
      content: '""',
      right: "1px",
      width: "141px",
      display: "block",
      position: "absolute",
      top: 0,
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(137deg)",
      transformOrigin: "top right",
    },
  },
   tendencies: {
    position: "relative",
    "&::before": {
      content: '""',
      left: "-135px",
      width: "135px",
      display: "block",
      position: "absolute",
      top: 0,
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(403deg)",
      transformOrigin: "top right",
    },
  },
   negotiation: {
    position: "relative",
    "&::before": {
      content: '""',
      right: "1px",
      width: "333px",
      display: "block",
      position: "absolute",
      top: 0,
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(113deg)",
      transformOrigin: "top right",
    },
  },
  roleAlignment: {
    position: "relative",
    "&::before": {
      content: '""',
      left: "-157px",
      width: "295px",
      display: "block",
      position: "absolute",
      top: 0,
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(-271deg)",
      transformOrigin: "top right",
    },
  },
  emotions: {
    position: "relative",
    "&::before": {
      content: '""',
      left: "-330px",
      width: "330px",
      display: "block",
      position: "absolute",
      top: 0,
      borderBottom: "1px dashed #d7dbdd",
      transform: "rotate(66deg)",
      transformOrigin: "top right",
    },
  },
});

function CharacteristicsModal({ open, setOpen, icon, data }) {
  const Icon = icon ? icon : RedImg;
  const classes = useStyle();
  const [content, setContent] = React.useState({
    title: '',
    voiceTone: [],
    fears: [],
    verbalComminication: [],
    underPressure: [],
    nonVerbal: [],
    growthArea: [],
    priority: [],
    workPlace: [],
    tendencies: [],
    negotiation: [],
    roleAlignment: [],
    emotions: []
  });

  const setData = () => {
    switch (data) {
      case 'Decisive':
        setContent({
          title: 'Observable characteristics of highly decisive behavior',
          voiceTone: [
            'Direct, straightforward',
            'Strong tone',
            'High volume and quick speech',
            'Confrontational tone',
          ],
          fears: ['Fear of being taken advantage of or losing control'],
          verbalComminication: [
            'Assertive, often affirming more than asked',
            'Speaks more than listens',
            'Energetic in affirmations',
            'Focused on achieving goals',
          ],
          underPressure: [
            'Dictatorial',
            'Critical',
            'Nervous',
            'Aggressive',
            'Self-centered',
          ],
          nonVerbal: [
            'Firm handshake',
            'Uses gestures to emphasize points',
            'Steady gaze',
            'Shows impatience',
            'Maintains a firm posture',
          ],
          growthArea: [
            'Needs to improve patience',
            'Needs to improve listening skills',
            'Could be more sensitive to others',],
          priority: [
            'Prefers problem-solving',
            'Goal-oriented, focused on getting results',
            'Enjoys challenges',
          ],
          workPlace: [
            'Values efficiency',
            'Prefers a structured environment',
            'Often busy',
          ],
          tendencies: [
            'Tends to be direct and guarded',
            'Tends to act quickly and make decisions rapidly',
            'Desires to lead and be in charge',
          ],
          negotiation: ["Quick"],
          roleAlignment: [
            "Leadership",
            "Administration",
            "Management",
            "Pioneering",
          ],
          emotions: [
            "Tends to be direct and guarded",
            "Makes quick decisions"
          ]
        });
        break;
      case 'Interactive':
        setContent({
          title: 'Observable characteristics of highly interactive behavior',
          voiceTone: [
            'Varied pitch',
            'Strong volume',
            'Theatrical',
            'Speaks fast',
          ],
          fears: [
            'Fear of being left out',
            'Fear of rejection',
            'Fear of losing social approval',],
          verbalComminication: [
            'Tells stories, shares anecdotes',
            'Shares personal feelings',
            'Expresses thoughts without formalities',
            'Has a flexible perception of time',
          ],
          underPressure: [
            'May become attacking',
            'Tends to be sarcastic',
            'Can be superficial',
            'Promotes themself',
            'Becomes more talkative',
          ],
          nonVerbal: [
            'Animated facial expressions',
            'Uses many gestures (hands, body)',
            'Oriented toward physical contact',
            'Expresses impatience',
            'Takes spontaneous actions',
            'Relaxed posture',
          ],
          growthArea: [
            'Inattention to detail',
            'Short attention span',
            'Low follow-through',
          ],
          priority: [
            'Interacts and actively engages people',
            'Prefers a friendly environment',
            'Needs to be known by others',
          ],
          workPlace: [
            'Interactive',
            'Busy',
          ],
          tendencies: [
            'Tends to be direct and open',
            'Makes quick decisions',
            'Desires to lead and be in charge',
          ],
          negotiation: ["Open"],
          roleAlignment: [
            'Sales',
            'Motivator',
            'Entertainer',
            'Customer-facing roles',
          ],
          emotions: [
            'Optimism',
            'Spontaneity',
            'Risk-taker',
          ]
        });
        break;
      case 'Steady':
        setContent({
          title: 'Observable characteristics of highly steady behavior',
          voiceTone: [
            'Closed, warm',
            'Moderate tone',
            'Low volume',
            'Speaks slowly',
          ],
          fears: [
            'Fear of sudden changes',
            'Fear of loss of stability and security',],
          verbalComminication: [
            'Listens more than they speak',
            'Reserved with their opinion',
            'Limited verbal communication',
          ],
          underPressure: [
            'Can become dictatorial',
            'Tends to be critical',
            'Displays signs of nervousness',
            'Can become aggressive',
            'May act selfishly',
          ],
          nonVerbal: [
            'Inconsistent eye contact',
            'Soft greeting',
            'Displays patience',
            'Slow movements',
            'Relaxed posture',
          ],
          growthArea: [
            'Hesitant to speak up',
            'Needs to improve listening skills',
            'Slow to initiate action',
            'Can be overly sensitive',
          ],
          priority: [
            'Prefers a stable team and work environment',
            'Likes to pace things in their environment',
          ],
          workPlace: [
            'Friendly',
            'Personal',
            'Functional',
          ],
          tendencies: [
            'Tends to be reserved and guarded',
            'Slow in decision-making',
            'Shows a desire to lead and be in charge',
          ],
          negotiation: ["Slow"],
          roleAlignment: [
            'Coaching',
            'Teamwork',
            'Follow-through',
          ],
          emotions: [
            'Patient',
            'Stabilizer',
          ]
        });
        break;
      case 'Cautious':
        setContent({
          title: 'Observable characteristics of highly cautious behavior',
          voiceTone: [
            'Not very flexible',
            'Stable, with little variation',
            'Speaks slowly',
          ],
          fears: [
            'Fear of criticism',
            'Fear of loss of accuracy and quality',
          ],
          verbalComminication: [
            'Focuses on tasks and facts',
            'Limits sharing of personal feelings',
            'Formal and compliant',
            'Stays focused on the discussion',
          ],
          underPressure: [
            'Tends to withdraw',
            'Can be headstrong',
            'Can be pessimistic',
            'Difficult to please',
            'Meticulous',
            'May be overly critical',
          ],
          nonVerbal: [
            'Inconsistent eye contact',
            '"Cold" greeting',
            'Shows rigorous attention',
            'Minimal movement',
            'Maintains a strict, controlled, austere posture',
          ],
          growthArea: [
            'Can be perfectionistic',
            'Tends to be critical',
            'May be unresponsive',
          ],
          priority: [
            'Prefers established protocols, procedures, and standards',
            'Prefers to have rules to follow and data to analyze',
          ],
          workPlace: [
            'Formal',
            'Structured',
            'Functional',
          ],
          tendencies: [
            'Tends to be a perfectionist',
            'Appreciates precision',
            'Demonstrates personal discipline',
          ],
          negotiation: ["Reserved"],
          roleAlignment: [
            'Prefers planning and structuring tasks',
            'Thoroughness',
            'Sets high standards',
            'Excels in orchestration and systemizing tasks',
          ],
          emotions: [
            'Cautious',
            'Makes careful decisions',
            'Analytical',
          ]
        });
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    setData()
  }, [data]);
  return (
    <Modal
      title={content.title}
      open={open}
      setOpen={setOpen}
      mdUpWidth={750}
      lgUpWidth={950}
    >
      <div className="grid xl:grid-cols-3 grid-cols-1 gap-10 relative">
        {/* <svg height="100%" width="100%" className="absolute top-0">
          <line
            x1="50"
            y1="40"
            x2="500"
            y2="10"
            style={{ stroke: "rgb(255,0,0)", strokeWidth: 2 }}
          />

          
        </svg>*/}

        <div
          className="xl:absolute xl:top-[50%] flex justify-center xl:left-[50%] xl:translate-y-[-50%] xl:translate-x-[-50%] 
          "
        >
          <div className="relative border border-[#AFB7BC] border-dashed p-8 rounded-full">
            {/* <Icon className="scale-125" /> */}
            <img src={Icon} alt="icon" height={100} width={100} className="scale-125"/>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[-3px] right-[50%]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[7px] right-[23%]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[36px] right-[8px]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[50%] translate-y-[-50%] right-[-3px]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[123px] right-[9px]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[154px] right-[43px]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[161px] right-[50%]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[148px] right-[124px]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[148px] right-[124px]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[120px] right-[150px]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[50%] translate-y-[-50%] right-[161px]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[38px] right-[150px]"
            ></div>
            <div
              className="w-1.5 h-1.5 rounded-full bg-[#AFB7BC] absolute 
            top-[9px] right-[122px]"
            ></div>
          </div>
        </div>

        <ModalCard
          title="VOICE TONE"
          optionList={content.voiceTone}
          linestyle={`${classes.voiceTone}`}
        />
        <ModalCard
          title="FEARS"
          optionList={content.fears}
          classstyle=""
          linestyle={`${classes.fears}`}
        />
        <ModalCard
          title="VERBAL COMMUNICATION"
          optionList={content.verbalComminication}
          linestyle={`${classes.verbalComminication}`}
        />
        <ModalCard
          title="UNDER PRESSURE"
          optionList={content.underPressure}
          classstyle={`col-start-1 col-end-2`}
          linestyle={`${classes.underPressure}`}
        />
        <div className="flex justify-center items-start"><img src={tripaLogo} /></div>
        <ModalCard
          title="NON - VERBAL COMMUNICATION"
          optionList={content.nonVerbal}
          classstyle="xl:col-start-3 xl:col-end-4 col-start-1 col-end-2"
          linestyle={`${classes.nonVerbal}`}
        />

        <ModalCard
          title="GROWTH AREAS"
          optionList={content.growthArea}
          classstyle="col-start-1 col-end-2"
          linestyle={`${classes.growthArea}`}
        />

        <ModalCard
          title="PRIORITY & NEED"
          optionList={content.priority}
          classstyle="xl:col-start-3 xl:col-end-4 col-start-1 col-end-2"
          linestyle={`${classes.priority}`}
        />

        <ModalCard
          title="WORKPLACE"
          optionList={content.workPlace}
          classstyle="col-start-1 col-end-2"
          linestyle={`${classes.workPlace}`}
        />

        <ModalCard
          title="TENDENCIES"
          optionList={content.tendencies}
          classstyle="xl:col-start-3 xl:col-end-4 col-start-1 col-end-2"
          linestyle={`${classes.tendencies}`}
        />

        <ModalCard title="NEGOTIATION" optionList={content.negotiation} linestyle={`${classes.negotiation}`} />
        <ModalCard
          title="ROLE ALIGNMENT"
          optionList={content.roleAlignment}
          linestyle={`${classes.roleAlignment}`}
          
        />

        <ModalCard
          title="EMOTIONS"
          optionList={content.emotions}
          linestyle={`${classes.emotions}`}
        />
      </div>
    </Modal>
  );
}

export default CharacteristicsModal;
