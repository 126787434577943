import React, { useRef } from "react";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import InfoBox from "../../../sharedComponents/profile/infoBox";
import {
  BudgetTable,
  handleGetAdditionalIncome,
  handleGetMonthlyNetIncome,
} from "../reusableComponent";
import { savePersonalBudget } from "../../../../../api/jc";
import { toast } from "react-toastify";

const columns = [
  { id: "incomeType", label: "Income Type", minWidth: 350 },
  { id: "amount", label: "Amount", minWidth: 50, align: "right" },
];

const AdditionalColumns = [
  { id: "details", label: "Details", minWidth: 150 },
  { id: "month", label: "Months", minWidth: 150 },
  { id: "amount", label: "Amount", minWidth: 50, align: "right" },
];

function Step1({ setActiveStep, month, year, data, reload }) {
  const monthlyIncomeType = useRef();
  const monthlyAmount = useRef();
  const additionalDetails = useRef();
  const additionalAmount = useRef();

  const [monthlyIncomeData, setMonthlyIncomeData] = React.useState([]);
  const [additionalIncomeData, setAdditionalIncomeData] = React.useState([]);

  // const handleInfo = () => {
  //   const data = {
  //     monthlyIncomeType: monthlyIncomeType.current.value,
  //     monthlyAmount: monthlyAmount.current.value,
  //     additionalDetails: additionalDetails.current.value,
  //     additionalAmount: additionalAmount.current.value,
  //   };
  //   console.log(data);
  // };

  const handleMonthlyNetIncome = async () => {
    try {
      if (monthlyIncomeType.current.value && monthlyAmount.current.value) {
        if (monthlyAmount.current.value <= 0) {
          toast.error("Amount must be greater than 0");
        } else {
          let data = {
            year,
            month,
            monthly_net_income: [
              {
                income_type: monthlyIncomeType.current.value,
                amount: monthlyAmount.current.value,
              },
            ],
          };
          await savePersonalBudget(data);
          toast.success("Monthly income updated!");
          monthlyIncomeType.current.value = "";
          monthlyAmount.current.value = "";
          reload();
        }
      } else {
        toast.error("Please fill all fields of monthtly income!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAdditionalIncome = async () => {
    try {
      if (
        additionalDetails.current.value &&
        additionalAmount.current.value
      ) {
        if (additionalAmount.current.value <= 0) {
          toast.error("Amount must be greater than 0");
        } else {
          let data = {
            year,
            month,
            additional_income: [
              {
                details: additionalDetails.current.value,
                amount: additionalAmount.current.value,
              },
            ],
          };
          await savePersonalBudget(data);
          toast.success("Additional income updated!");
          additionalDetails.current.value = "";
          additionalAmount.current.value = "";
          reload();
        }
      } else {
        toast.error("Please fill all fields of additional income!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    handleGetMonthlyNetIncome(data, setMonthlyIncomeData);
    handleGetAdditionalIncome(data, setAdditionalIncomeData);
  }, [data]);

  return (
    <>
      <div className="grid xl:grid-cols-2 grid-cols-1 gap-4">
        <div className="col-span-1">
          <InfoBox title="Monthly net income" TooltipTitle="Monthly net income" TooltipText="The monthly net income is the money you bring home after taxes and deductions are taken from your paycheck or monthly salary." containerStyle="h-full">
            <form className="grid md:grid-cols-auto-fit-200 gap-4 mt-[24px]">
              <div className="flex flex-col">
                <label
                  htmlFor="monthlyIncomeType"
                  className="text-pirmaryBlue text-base font-normal"
                >
                  Enter Income Type *
                </label>
                <input
                  type="text"
                  name="monthlyIncomeType"
                  ref={monthlyIncomeType}
                  id="monthlyIncomeType"
                  placeholder="Enter Income Type"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="monthlyAmount"
                  className="text-pirmaryBlue text-base font-normal"
                >
                  Enter Amount *
                </label>
                <input
                  type="text"
                  name="monthlyAmount"
                  ref={monthlyAmount}
                  id="monthlyAmount"
                  placeholder="Enter Amount"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
              </div>
            </form>
            <div className="my-6">
              <SuccessBtn type="button" onClick={handleMonthlyNetIncome}>
                Add
              </SuccessBtn>
            </div>
            <div className="max-h-[500px] overflow-y-auto">
              <BudgetTable rows={monthlyIncomeData} columns={columns} />
            </div>
          </InfoBox>
        </div>
        <div className="col-span-1">
          <InfoBox
            title="Additional income"
            containerStyle="h-full"
            TooltipTitle="Additional income"
            TooltipText="Additional income is the money you earn in addition to your steady paycheck. Whether your extra income comes from an investment or side gig, it supplements your purchasing power."
          >
            <form className="grid md:grid-cols-auto-fit-200 gap-4 mt-[24px]">
              <div className="flex flex-col">
                <label
                  htmlFor="additionalDetails"
                  className="text-pirmaryBlue text-base font-normal"
                >
                  Enter details *
                </label>
                <input
                  type="text"
                  name="additionalDetails"
                  ref={additionalDetails}
                  id="additionalDetails"
                  placeholder="Enter details"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="additionalAmount"
                  className="text-pirmaryBlue text-base font-normal"
                >
                  Enter Amount *
                </label>
                <input
                  type="text"
                  name="additionalAmount"
                  ref={additionalAmount}
                  id="additionalAmount"
                  placeholder="Enter Amount"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
              </div>
            </form>
            <div className="my-6">
              <SuccessBtn type="button" onClick={handleAdditionalIncome}>
                Add
              </SuccessBtn>
            </div>
            <div className="max-h-[500px] overflow-y-auto">
              <BudgetTable
                rows={additionalIncomeData}
                columns={AdditionalColumns}
              />
            </div>
          </InfoBox>
        </div>
      </div>

      <div className=" flex justify-end items-center mt-8">
        <SuccessBtn onClick={() => setActiveStep((prev) => prev + 1)}>
          Next
        </SuccessBtn>
      </div>
    </>
  );
}

export default Step1;
