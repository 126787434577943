import React from "react";
import { toast } from "react-toastify";
import { sendEmailToReactiveJC } from "../../../../api/jc";
import SuccessBtn from "../../sharedComponents/buttons/successBtn/SuccessBtn";
import Modal from "../../sharedComponents/modal/Modal";

const ReActiveAccount = ({ reActivePopUp, setReActivePopUp, email = "" , setReActive}) => {
  const [value, setValue] = React.useState("");
  const getReActiveMail = async () => {
    try {
      await sendEmailToReactiveJC(value);
      toast.success("Reactive Mail sent, please check your inbox!");
      setReActivePopUp(false);
      setReActive(false);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    email && setValue(email);
  }, [email]);

  return (
    <>
      <Modal
        title="Reactive your account"
        open={reActivePopUp}
        setOpen={setReActivePopUp}
        mdUpWidth={550}
      >
        <div className="flex flex-col">
          <label className="text-base text-pirmaryBlue">Email *</label>
          <input
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            type="text"
            placeHolder="Enter your email"
            className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
          />
        </div>
        <SuccessBtn onClick={getReActiveMail} className="mt-5" type="submit">
          <span className="font-bold">Reactive your Account</span>
        </SuccessBtn>
      </Modal>
    </>
  );
};

export default ReActiveAccount;
