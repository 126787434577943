import React from "react";
import InfoBox from "../../../sharedComponents/profile/infoBox";
import { BudgetTable, getBudgetData, handleGetAdditionalIncome, handleGetMonthlyExpenses, handleGetMonthlyNetIncome, handleGetPlannedExpenses } from "../reusableComponent";
import ReactApexChart from "react-apexcharts";
import { getAnnualBudget } from "../../../../../api/jc";

const monthlyNetIncomeColumns = [
  { id: "incomeType", label: "Income Type", minWidth: 350 },
  { id: "amount", label: "Amount", minWidth: 50, align: "right" },
];

const additionalIncomeColumns = [
  { id: "details", label: "Details", minWidth: 150 },
  { id: "month", label: "Months", minWidth: 150 },
  { id: "amount", label: "Amount", minWidth: 50, align: "right" },
];

const monthlyExpensesColumns = [
  { id: "espenses", label: "Espenses", minWidth: 350 },
  { id: "cost", label: "Costs", minWidth: 50, align: "right" },
];

const plannedExpensesColumns = [
  { id: "expenditure", label: "Expenditure", minWidth: 150 },
  { id: "months", label: "Months", minWidth: 150 },
  { id: "amount", label: "Amount", minWidth: 50, align: "right" },
];

const annualBudgetMonthColumns = [
  { id: "incomeExpenses", label: "Income and expenses", minWidth: 200 },
  { id: "Jan", label: "January", minWidth: 100, align: "right" },
  { id: "Feb", label: "February", minWidth: 100, align: "right" },
  { id: "Mar", label: "March", minWidth: 100, align: "right" },
  { id: "Apr", label: "April", minWidth: 100, align: "right" },
  { id: "May", label: "May", minWidth: 100, align: "right" },
  { id: "Jun", label: "June", minWidth: 100, align: "right" },
  { id: "Jul", label: "July", minWidth: 100, align: "right" },
  { id: "Aug", label: "August", minWidth: 100, align: "right" },
  { id: "Sep", label: "September", minWidth: 100, align: "right" },
  { id: "Oct", label: "October", minWidth: 100, align: "right" },
  { id: "Nov", label: "November", minWidth: 100, align: "right" },
  { id: "Dec", label: "December", minWidth: 100, align: "right" },
];

function Step4({ month, year, data, reload }) {

  const [monthlyIncomeData, setMonthlyIncomeData] = React.useState([]);
  const [additionalIncomeData, setAdditionalIncomeData] = React.useState([]);
  const [monthlyExpenseData, setMonthlyExpenseData] = React.useState([]);
  const [plannedExpensesData, setPlannedExpensesData] = React.useState([]);
  const [annualBudgetMonthRows, setAnnualBudgetMonthRows] = React.useState([])
  const [graphData, setGraphData] = React.useState([]);
  const barOptions = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#7AB794"],
    series: [
      {
        name: "Amount",
        type: "column",
        data: graphData?.map(({ savings }) => savings),
      },
    ],
    stroke: {
      width: [2],
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
      },
    },
    xaxis: {
      categories: graphData?.map(({ month }) => month),
    },
    yaxis: [
      {
        seriesName: "Column A",
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
        title: {
          // text: "Columns",
          show: false,
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => { return `US $${value}` },
      },
      },
    ],
    tooltip: {
      shared: false,
      intersect: true,
      enabled: true,
      x: {
        show: false,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
      show: false,
    },
  };

  React.useEffect(() => {
    handleGetMonthlyNetIncome(data, setMonthlyIncomeData)
    handleGetAdditionalIncome(data, setAdditionalIncomeData)
    handleGetMonthlyExpenses(data, setMonthlyExpenseData)
    handleGetPlannedExpenses(data, setPlannedExpensesData)
  }, [data]);

  const getGraphData = async () => {
    try {
      const res = await getAnnualBudget({ year })
      if(res?.data?.length) {
        setGraphData(res.data)
      }
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    getBudgetData(year, setAnnualBudgetMonthRows)
    getGraphData()
  }, []);

  return (
    <>
      <div className="grid xl:grid-cols-2 grid-cols-1 gap-4 mb-6">
        <div className="col-span-1">
          <InfoBox title="Monthly net income" containerStyle="h-full" TooltipTitle="Monthly net income" TooltipText='The monthly net income is the money you bring home after taxes and deductions are taken from your paycheck or monthly salary.'>
            <div className="max-h-[500px] overflow-y-auto">
              <BudgetTable
                rows={monthlyIncomeData}
                columns={monthlyNetIncomeColumns}
              />
            </div>
          </InfoBox>
        </div>
        <div className="col-span-1">
          <InfoBox title="Additional income" containerStyle="h-full" TooltipTitle="Additional income" TooltipText='Additional income is the money you earn in addition to your steady paycheck. Whether your extra income comes from an investment or side gig, it supplements your purchasing power.'>
            <div className="max-h-[500px] overflow-y-auto">
              <BudgetTable
                rows={additionalIncomeData}
                columns={additionalIncomeColumns}
              />
            </div>
          </InfoBox>
        </div>
      </div>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 mb-14">
        <div className="col-span-1">
          <InfoBox title="Monthly expenses" TooltipTitle="Monthly expenses" TooltipText="Monthly expenses are standard cost that occurs every month, on a specific day, and for a certain amount. For example, your monthly expenses could include mortgage, rent, utility services such as water, electricity, cell phone, car payment, insurance, gym membership, food, and entertainment, among other recurring expenses." containerStyle="h-full">
            <div className="max-h-[500px] overflow-y-auto">
              <BudgetTable
                rows={monthlyExpenseData}
                columns={monthlyExpensesColumns}
              />
            </div>
          </InfoBox>
        </div>
        <div className="col-span-1">
          <InfoBox title="Planned expenses" TooltipTitle="Planned expenses" TooltipText="Planned expenses refer to the fixed funds you plan to use for activities or obligations you programmed, such as vacations, new clothes, studies, rentals, insurance, and utilities."  containerStyle="h-full">
            <div className="max-h-[500px] overflow-y-auto">
              <BudgetTable
                rows={plannedExpensesData}
                columns={plannedExpensesColumns}
              />
            </div>
          </InfoBox>
        </div>
      </div>

      <InfoBox title="Annual budget by month" TooltipTitle="Annual budget by month" TooltipText="An annual budget by months is your yearly forecast divided by months, and it details your revenues and expenses expected each month of a year." containerStyle="h-full">
        <div className="max-h-[500px] overflow-y-auto">
          <div className="max-h-[500px] overflow-y-auto">
            <BudgetTable
              rows={annualBudgetMonthRows}
              columns={annualBudgetMonthColumns}
            />
          </div>
        </div>
      </InfoBox>

      <InfoBox title="Savings" TooltipTitle="Savings" TooltipText="Saving is the portion of your monthly income not spent on current expenditures included in the list of your monthly expenses. In other words, it is the money you set aside for future use and not spent immediately. For example, your saving could include the amount of money you can set aside for a particular period." containerStyle="h-full">
        {graphData?.length > 0 && (
          <ReactApexChart
            height={'auto'}
            width={'100%'}
            options={barOptions}
            series={barOptions.series}
            type='line'
          />
        )}
      </InfoBox>
    </>
  );
}

export default Step4;
