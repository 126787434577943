import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ReactComponent as YellowGo } from "../../../assets/yellowGo.svg";
import { ReactComponent as BlackGo } from "../../../assets/blackGo.svg";
import { ReactComponent as Google } from "../../../assets/Google.svg";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const tabsStyle = makeStyles({
  root: {
    flexGrow: 1,
    color: "#273164",
    textAlign: "right",
    borderBottom: "1px solid #E3E6E9  !important",
    "& .MuiBox-root": {
      padding: "0 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#7AB794 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },
  tab: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    // borderBottom: "1px solid #E3E6E9 !important",
    "&.Mui-selected": {
      color: "#6DA183 !important",
      fontSize: "16px",
      fontWeight: "700",
    },
  },
});

export const useStyle = makeStyles({
  checkbox: {
    "& .Mui-checked": {
      color: "#7AB693 !important",
    },
    "& .MuiFormControlLabel-label": {
      color: "#828282",
    },
  },
  deleteBtn: {
    color: "#828282",
    background: "#F8FAFB",
    width: "34px !important",
    height: "34px !important",
    padding: "5px",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "10px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#384259",
  },
  table: {
    "& .MuiTableRow-head": {
      "& .MuiTableCell-head": {
        background: "#F4F6F8",
        fontWeight: "700",
        color: "#384259",
        fontSize: "16px",
      },
    },
  },
  pagination: {
    margin: "24px 0px 30px 0px",

    "& .MuiSvgIcon-fontSizeMedium": {
      zIndex: 200,
      background: "red",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      opacity: 0,
    },
    "& .MuiPaginationItem-page": {
      border: " 1px solid #E5E5E5",
      borderRadius: "0px",
    },
    "& .Mui-selected": {
      background: "#00000000 !important",
      borderRadius: "0px",
      border: "1px solid #7AB794",
      ":hover": {
        // backgroundColor: "transparent",
      },
    },
  },
  search: {
    "&.MuiAutocomplete-root": {
      width: "100%",
      paddingRight: "10px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E3E6E9 !important",
      backgroundColor: "transparent !important",
      top: 0,

      "& .css-1in441m": {
        display: "none",
      },
      "& .css-1ftyaf0": {
        display: "none",
      },
    },
    "& .MuiSelect-nativeInput": {
      bottom: "50%",
      transform: "translateY(50%)",
      left: "20px !important",
      opacity: 0,
    },
    "& .MuiSelect-icon": {
      right: "20px !important",
      fill: "#7E8B96 !important",
    },
    "& .MuiInputBase-input": {
      padding: "0px",
    },
    "& .Mui-focused ": {
      color: "#384259 !important",
    },
    "& .MuiInputLabel-root": {
      color: "#384259 !important",
      fontSize: "16px",
      transform: "none !important",
      position: "static !important",
    },
  },
});

export const billingColumns = [
  { id: "date", label: "Date", minWidth: 200, align: "left" },
  { id: "amount", label: "Amount", minWidth: 100, align: "center" },
  {
    id: "description",
    label: "Description",
    minWidth: 400,
    align: "left",
  },
  {
    id: "link",
    label: "Receipt",
    minWidth: 50,
    align: "left",
  },
];

export const billingRows = [
  {
    id: 1,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 2,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 3,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 4,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 5,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 6,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 7,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 8,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 9,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 10,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 11,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
  {
    id: 12,
    date: "February 19, 2017",
    amount: "$120.00",
    description: "12 month subscription, TRIPA premium",
  },
];

const AwaitingName = ({ name, email }) => {
  return (
    <div className="flex flex-col">
      <h5 className="text-sm text-[#384259]">{name}</h5>
      <p className="text-[12px] text-[#828282]">{email}</p>
    </div>
  );
};
export const awaitingColumns = [
  { id: "name", label: "Name", minWidth: 100, align: "left" },
  { id: "email_id", label: "Email", minWidth: 100, align: "left" },
  { id: "position", label: "Position", minWidth: 150, align: "center" },
  {
    id: "relationship",
    label: "Relationship",
    minWidth: 250,
    align: "left",
  },
  {
    id: "comment",
    label: "Comment",
    minWidth: 300,
    align: "left",
  },
];

export const awaitingRows = [
  {
    id: 1,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 2,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 3,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 4,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 5,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 6,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 7,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 8,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 9,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 10,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 11,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
  {
    id: 12,
    name: <AwaitingName name="Candidate" email="Candidate@tripa.ai" />,
    position: "Director",
    relationship: "Candidate managed you directly",
    comment: "Hi Candidate, Could you write me a recommendation?",
  },
];

export const CompanyCard = ({ img, name }) => {
  const classes = useStyle();
  return (
    <div className="flex justify-between items-center py-4">
      <div className="flex items-center flex-wrap">
        <span className="w-14 h-14 flex justify-center items-center">
          {img}
        </span>
        <span className="ml-4 text-[18px] font-bold text-[#384259]">
          {name}
        </span>
      </div>
      <DeleteOutlineOutlinedIcon className={classes.deleteBtn} />
    </div>
  );
};

export const searchList = [
  // { title: "The Shawshank Redemption", year: 1994 },
  {
    title: "Google",
    el: (
      <p className="text-sm text-pirmaryBlue flex items-center">
        <span className="mr-3">
          <Google />
        </span>
        Google
      </p>
    ),
  },
  {
    title: "Go",
    el: (
      <p className="text-sm text-pirmaryBlue flex items-center">
        <span className="mr-3">
          <BlackGo />
        </span>
        Go
      </p>
    ),
  },
  {
    title: "Go",
    el: (
      <p className="text-sm text-pirmaryBlue flex items-center">
        <span className="mr-3">
          <YellowGo />
        </span>
        Go
      </p>
    ),
  },
];
