import React from "react";
import SlideBox from "../../../../sharedComponents/slideBox/SlideBox";
import Slide1 from "../businessModel/slide1";
import Slide2 from "../businessModel/slide2";
import Slide3 from "../businessModel/slide3";

function BusinessModel({businessModel, setBusinessModel}) {
  const list = [<Slide1 />, <Slide2 />, <Slide3 />];
  return (
    <SlideBox
      title="Personal business model"
      componentList={list}
      open={businessModel}
      setOpen={setBusinessModel}
    />
  );
}

export default BusinessModel;
