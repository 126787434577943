import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { a11yProps, tabsStyle } from './reuseableComponent';
import WhiteBox from '../../../../sharedComponents/whiteBox/WhiteBox';
import CompareCandidateProfile from '../../../../sharedComponents/candidateProfile/CompareCandidateProfile';
import { getCandidatesByPool, getHRProfile } from '../../../../../../api/hr';
import { SelectField } from './reuseableComponent';

function CandidateComparison() {
  const [value, setValue] = React.useState();
  const [candidates, setCandidates] = React.useState([]);
  const [firstCandidate, setFirstCandidate] = React.useState();
  const [secondCandidate, setSecondCandidate] = React.useState();
  const [pools, setPools] = React.useState([]);
  const handleChangeData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let candidate = candidates.find(({ _id }) => _id === value);
    console.log(candidate);
    name === 'firstCandidate'
      ? setFirstCandidate(candidate)
      : setSecondCandidate(candidate);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = tabsStyle();

  const getAllPools = async () => {
    try {
      const res = await getHRProfile();
      if (res?.data?.pool?.length > 0) {
        setPools(res.data.pool);
        setValue(res.data.pool[0]._id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCandidates = async (pool_id) => {
    try {
      const res = await getCandidatesByPool({ pool_id });
      setCandidates(res?.data?.candidates || []);
    } catch (error) {
      console.error(error);
    }
  };
  React.useEffect(() => {
    getAllPools();
  }, []);

  React.useEffect(() => {
    getCandidates(value);
  }, [value]);
  return (
    <div className='lg:mx-auto md:m-10 mx-1 my-5'>
      <h2 className='text-[24px] text-[#384259] font-bold'>
        Candidate comparison
      </h2>
      <Tabs
        className={classes.root}
        value={value}
        onChange={handleChange}
        variant='scrollable'
        allowScrollButtonsMobile
        aria-label='basic tabs example'
        sx={{
          '& .MuiTabs-scroller': {
            borderRadius: '4px',
            color: 'white',
          },
          '&.MuiTabs-root': {
            '&.MuiTabs-scroller': {
              '&.MuiTabs-indicator': {
                backgroundColor: 'red !important',
              },
            },
          },
        }}
      >
        {pools?.map((pool) => (
          <Tab
            label={pool.candidate_pool_name}
            value={pool._id}
            {...a11yProps(pool._id)}
            className={classes.tab}
          />
        ))}
      </Tabs>

      {/* <TabPanel value={value} index={0}>
        <UnderConstruction />
      </TabPanel> */}

      <div className='grid xl:grid-cols-2 grid-cols-1 gap-6 min-h-[80vh]'>
        <WhiteBox title='Please select any candidate'>
          <div className='mb-6'>
            <SelectField
              selectItems={
                candidates?.length > 0
                  ? candidates
                      .filter(({ _id }) => secondCandidate?._id !== _id)
                      .map(
                        ({
                          candidate_profile_id: { first_name, last_name },
                          _id,
                        }) => ({ id: _id, name: `${first_name} ${last_name}` })
                      )
                  : []
              }
              label=''
              labelId='firstCandidate'
              id='jobFunction'
              onChange={handleChangeData}
              name='firstCandidate'
              placeholder='Select'
              inputStyle={{ padding: '14px' }}
            />
          </div>
          {firstCandidate && (
            <CompareCandidateProfile selectedCandidate={firstCandidate} />
          )}
        </WhiteBox>
        <WhiteBox title='Please select any candidate'>
          <div className='mb-6'>
            <SelectField
              selectItems={
                candidates?.length > 0
                  ? candidates
                      .filter(({ _id }) => firstCandidate?._id !== _id)
                      .map(
                        ({
                          candidate_profile_id: { first_name, last_name },
                          _id,
                        }) => ({ id: _id, name: `${first_name} ${last_name}` })
                      )
                  : []
              }
              label=''
              labelId='secondCandidate'
              id='jobFunction'
              onChange={handleChangeData}
              name='secondCandidate'
              placeholder='Select'
              inputStyle={{ padding: '14px' }}
            />
          </div>
          {secondCandidate && (
            <CompareCandidateProfile selectedCandidate={secondCandidate} />
          )}
        </WhiteBox>
      </div>

      {/* <TabPanel value={value} index={2}>
        <UnderConstruction />
      </TabPanel> */}
    </div>
  );
}

export default CandidateComparison;
