import React from "react";
import WhiteBox from "../../../../../sharedComponents/whiteBox/WhiteBox";
import SelectField from "../../../../../sharedComponents/selectField/SelectField";
import SuccessButton from "../../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import DangerButton from "../../../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import { allCountryCode } from "../../../../../../contants/countries";
import { deleteProfileLogo } from "../../../../../../../api/hr/index";
import { Avatar } from "@mui/material";

function BasicDetails1({
  image,
  setImage,
  data,
  setData,
  handleSubmit,
  getProfileData,
}) {
  const handleChangeData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
  };
  const handleImgFile = (e) => {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImage(e.target.result);
      setData((prev) => ({ ...prev, image: files[0] }));
    };
  };

  const deleteImage = async () => {
    try {
      await deleteProfileLogo();
      getProfileData();
      setImage("");
    } catch (error) {
      console.error(error);
    }
  };

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontSize: '35px',
        fontWeight: "700",
        width: "6rem",
        height: "6rem",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (name) => {
    const allNames = name.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  return (
    <WhiteBox
      title="Basic details"
      titleStyle={{ fontSize: "24px", margin: "20px auto !important" }}
    >
      <div className=" flex md:flex-row flex-col items-center w-[100%] ">
        <div className="w-[105px] h-[105px] rounded-full bg-white border-2 border-solid border-[#E5E5E5] flex justify-center items-center md:mr-[24px] mb-6 mb:my-0">
          <span className="min-w-[95px] min-h-[95px] flex justify-center items-center p-3">
            {image ? (
              <img src={image} alt="" className="mx-auto rounded-full" />
            ) : (
              <Avatar {...stringAvatar(`${data?.companyName || 'T'}`)} />
            )}
          </span>
        </div>
        <div className="">
          <div className="flex justify-center flex-wrap md:justify-start items-center my-2 md:my-0">
            <div className="relative">
              <SuccessButton customStyle={{ marginRight: "16px" }}>
                upload a logo
              </SuccessButton>
              <input
                className="absolute left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                type="file"
                name="image"
                onChange={(e) => handleImgFile(e)}
              />
            </div>
            {/* <DangerButton>Delete </DangerButton> */}
            <DangerButton
              onClick={deleteImage}
              // customStyle={{ marginTop: "5px" }}
            >
              Delete{" "}
            </DangerButton>
          </div>
          <p className="text-[#828282] text-[14px] font-normal mt-[16px]">
            * Image size should be at least 512px X 512px and max size of 500kb,
            allowed files .png and .jpg{" "}
          </p>
        </div>
      </div>

      <form action="" onSubmit={handleSubmit} className="my-[25px]">
        <div className="flex md:flex-row flex-col items-center w-[100%]">
          <div className="flex flex-col my-3 w-[100%] md:pr-3">
            <label htmlFor="companyName" className="text-pirmaryBlue">
              Company name *
            </label>
            <input
              type="text"
              name="companyName"
              value={data.companyName || ""}
              onChange={handleChangeData}
              id="companyName"
              placeholder="Enter Company Name"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>

          <div className="flex flex-col my-3 w-[100%] md:pl-3">
            <label htmlFor="email" className="text-pirmaryBlue">
              Email *
            </label>
            <input
              type="email"
              name="email"
              value={data.email || ""}
              onChange={handleChangeData}
              id="email"
              disabled
              placeholder="Enter your Email"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
        </div>

        <div className="flex md:flex-row flex-col items-center w-[100%]">
          <div className="flex flex-col my-3 w-[100%] md:pr-3">
            <SelectField
              selectItems={allCountryCode}
              label="Country code"
              labelId="countryCode"
              id="countryCode"
              value={data.countryCode}
              onChange={handleChangeData}
              name="countryCode"
              placeholder="Select Country Code"
              inputStyle={{ padding: "14px" }}
              required
            />
          </div>

          <div className="flex flex-col my-3 w-[100%] md:pl-3">
            <label htmlFor="phoneNumber" className="text-pirmaryBlue">
              Phone number
            </label>
            <input
              type="text"
              name="phoneNumber"
              value={data.phoneNumber || ""}
              onChange={handleChangeData}
              id="phoneNumber"
              placeholder="Enter Phone Number"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
        </div>
        <div className="flex items-center justify-end w-full mt-[25px]">
          <SuccessButton type="submit">Update</SuccessButton>
        </div>
      </form>
    </WhiteBox>
  );
}

export default BasicDetails1;
