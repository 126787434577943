import React from "react";
import TeslaLogo from "../../../../../../assets/teslaLogo.svg";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar } from "@mui/material";

export const useStyle = makeStyles({
  table: {
    "& .MuiTableRow-head": {
      "& .MuiTableCell-head": {
        background: "#F4F6F8",
        fontWeight: "700",
        color: "#384259",
        fontSize: "16px",
      },
    },
  },
  pagination: {
    margin: "24px 0px 30px 0px",

    "& .MuiSvgIcon-fontSizeMedium": {
      zIndex: 200,
      background: "red",
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      opacity: 0,
    },
    "& .MuiPaginationItem-page": {
      border: " 1px solid #E5E5E5",
      borderRadius: "0px",
    },
    "& .Mui-selected": {
      background: "#00000000 !important",
      borderRadius: "0px",
      border: "1px solid #7AB794",
      ":hover": {
        // backgroundColor: "transparent",
      },
    },
  },
  perview: {
    marginRight: "10px",
    background: "rgba(244, 246, 248, 0.61);",
    padding: "4px",
    width: "30px !important",
    height: "30px !important",
    borderRadius: "4px",
    color: "#C4C4C4",
    cursor: "pointer",
  },
  edit: {
    marginRight: "10px",
    background: "rgba(244, 246, 248, 0.61);",
    padding: "4px",
    width: "30px !important",
    height: "30px !important",
    borderRadius: "4px",
    color: "#828282",
    cursor: "pointer",
  },
  delete: {
    background: "rgba(244, 246, 248, 0.61);",
    padding: "4px",
    width: "30px !important",
    height: "30px !important",
    borderRadius: "4px",
    color: "#828282",
    cursor: "pointer",
  },

  title: {
    fontSize: "18px",
    color: "#384259",
    fontWeight: "700",
  },
  subTitle: {
    fontSize: "16px",
    color: "#384259",
    fontWeight: "700",
  },
  text: {
    fontSize: "14px",
    color: "#828282",
    fontWeight: "400",
  },
  checkCircle: {
    "&.MuiSvgIcon-root": { fill: "#7AB794 !important" },
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "15px!important",
  },
  [theme.breakpoints.up("md")]: {
    "& .MuiPaper-root": {
      minWidth: "800px",
    },
  },
  [theme.breakpoints.up("lg")]: {
    "& .MuiPaper-root": {
      minWidth: "950px",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& .MuiPaper-root": {
      margin: "20px",
      minWidth: "100%",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function InfoBoxes({
  title,
  icon,
  children,
  TooltipTitle,
  TooltipText,
  handleClickAdd,
}) {
  return (
    <>
      <div className="py-6 px-8 border border-[#E3E6E9] mb-6 rounded-2xl">
        <div className="flex items-center">
          {title && <p className="font-bold text-2xl ">{title}</p>}
          <Tooltip
            placement="top"
            title={
              <div className="rounded-md">
                <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                  {TooltipTitle}
                </div>
                <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                  {TooltipText}
                </div>
              </div>
            }
            arrow
          >
            <InfoIcon
              className="text-secondaryBalck cursor-pointer"
              fontSize="small"
              sx={{ marginLeft: "5px" }}
            />
          </Tooltip>

          <span
            className="text-secondaryBalck cursor-pointer relative top-1"
            onClick={handleClickAdd}
          >
            {/* <EditOutlinedIcon className='text-secondaryBalck cursor-pointer' /> */}
            {icon}
          </span>
        </div>
        {children}
      </div>
    </>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "#7AB794",
        color: "#ffffff",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function Modal({ title, children, open, setOpen, style }) {
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={style}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span className="font-bold text-lg">{title}</span>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ padding: "20px 30px 40px 30px !important" }}
        >
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
export const CreateAvatar = ({ src, alt }) => {
  return (
    <span
      className="flex items-center text-base text-[#384259] cursor-pointer"
    >
      <Avatar src={src} alt={alt} />
    </span>
  );
};

export const columns = [
  { id: "id", label: "ID#", minWidth: 130, align: "center" },
  { id: "logo", label: "Logo", minWidth: 130, align: "left" },
  {
    id: "job_title",
    label: "Job title",
    minWidth: 230,
    align: "left",
  },
  {
    id: "job_function",
    label: "Job function",
    minWidth: 170,
    align: "left",
  },
  {
    id: "employment_type",
    label: "Employment type",
    minWidth: 170,
    align: "left",
  },
  {
    id: "country",
    label: "Location",
    minWidth: 150,
    align: "left",
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   minWidth: 170,
  //   align: "center",
  // },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "left",
  },
];

export const rows = [
  {
    id: 1,
    logo: <CreateAvatar src={TeslaLogo} />,
    jobTitle: "Management Consulting",
    jobFunction: "Management",
    employmentType: "Full time",
    location: "Veinna, Austria",
    status: "Posted",
  },
  {
    id: 2,
    logo: <CreateAvatar src={TeslaLogo} />,
    jobTitle: "Management Consulting",
    jobFunction: "Management",
    employmentType: "Full time",
    location: "Veinna, Austria",
    status: "Posted",
  },
  {
    id: 3,
    logo: <CreateAvatar src={TeslaLogo} />,
    jobTitle: "Management Consulting",
    jobFunction: "Management",
    employmentType: "Full time",
    location: "Veinna, Austria",
    status: "Posted",
  },
  {
    id: 4,
    logo: <CreateAvatar src={TeslaLogo} />,
    jobTitle: "Management Consulting",
    jobFunction: "Management",
    employmentType: "Full time",
    location: "Veinna, Austria",
    status: "Posted",
  },
];

const customeSelectStyle = makeStyles({
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E3E6E9 !important",
      backgroundColor: "transparent !important",
      top: 0,

      "& .css-1in441m": {
        display: "none",
      },
      "& .css-1ftyaf0": {
        display: "none",
      },
    },
    "& .MuiSelect-nativeInput": {
      bottom: "50%",
      transform: "translateY(50%)",
      left: "20px !important",
      opacity: 0,
    },
    "& .MuiSelect-icon": {
      right: "20px !important",
      fill: "#7E8B96 !important",
      display: "none",
    },
    "& .MuiInputBase-input": {
      padding: "0px",
    },
  },
  menuItem: {
    "&.Mui-selected ": {
      backgroundColor: "#7AB69344 !important",
    },
    "& .MuiButtonBase-root-MuiMenuItem-root .Mui-selected": {
      color: "red",
    },
    "& .Mui-checked ": {
      color: "#7AB693 !important",
    },
  },
  inputLabel: {
    "&.Mui-focused ": {
      color: "#384259 !important",
    },
    "&.MuiInputLabel-root": {
      color: "#384259 !important",
      fontSize: "16px",
      transform: "none !important",
      position: "static !important",
    },
  },
});

export const CustomeSelect = ({ lable, optionList }) => {
  const [selectedList, setSelectedList] = React.useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        height: "50vh",
        width: 250,
      },
    },
  };
  const handleChangePositoin = (event) => {

    const {
      target: { value },
    } = event;
    setSelectedList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const hadleDelete = (itemIndex) => {
    const resArray = selectedList.filter((item, index) => {
      return index !== itemIndex
    })
    setSelectedList(resArray)
  }

  const classes = customeSelectStyle();
  return (
    <>
      <FormControl
        sx={{ margin: "15px 0px", width: "100%" }}
        className={classes.select}
      >
        <InputLabel
          id="demo-multiple-checkbox-label"
          className={classes.inputLabel}
        >
          {lable}
        </InputLabel>

        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedList}
          onChange={handleChangePositoin}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          className={classes.select}
          sx={{
            padding: "14px 40px 14px 14px",
            borderRadius: "4px",
            zIndex: "200",
          }}
        >
          {optionList &&
            optionList.map((item, index) => (
              <MenuItem key={index} value={item} className={classes.menuItem}>
                {item}
              </MenuItem>
            ))}
        </Select>
        <span
          className={`absolute top-[50%] left-[20px] text-[14px] text-[#828282] 
                       ${selectedList.length !== 0 ? "hidden" : "block"} 
                       ${lable ? "" : "translate-y-[-50%]"} `}
        >
          Select
        </span>
        <span
          className={`absolute top-[50%] ${
            lable ? "" : " translate-y-[-50%]"
          } right-[20px] text-[14px] text-[#828282] cursor-pointer`}
        >
          <KeyboardArrowDownIcon
            sx={{
              color: "#7E8B96",
              zIndex: "100",
              fontSize: "30px",
            }}
          />
        </span>
      </FormControl>
      <div className="mt-2">
        {selectedList.map((item, index) => {
          return (
            <div className="flex flex-wrap items-center justify-between mb-3 px-3">
              <div className="text-[#8EC7F1] text-sm underline ">
                {item}
              </div>
              <DeleteOutlineOutlinedIcon sx={{color: "#828282", cursor: "pointer"}} onClick={() => hadleDelete(index)}/>
            </div>
          );
        })}
      </div>
    </>
  );
};
