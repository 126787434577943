import React from "react";
import BehavioralTraitsSlide1 from "../../../../assets/BehavioralTraitsSlide1.svg";

function Slide1() {
  return (
    <div className="flex md:flex-row flex-col items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <img src={BehavioralTraitsSlide1} alt="" className="w-full h-full" />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          Understanding your report
        </h5>
        <p className="text-base text-pirmaryBlue  mb-6">
          This report is designed to help you understand your seven areas of motivation and how they influence your sense of purpose. Our values - our guiding principles, beliefs, and attitudes - affect our behavior and reflect what's important to us.
        </p>
        <p className="text-base text-pirmaryBlue  mb-6">
          Your report includes the following sections:
        </p>

        <ul className="list-disc pl-5 text-base text-pirmaryBlue">
          <li>The value report measures your seven areas of motivation and helps you make decisions that fit your style.</li>
          <li>Descriptions of the seven independent areas of motivation that influence your sense of purpose.</li>
          <li>A values summary that provides a comparison graph of your unique values measurements and descriptions.</li>
          <li>Value-based insights that help you understand your unique motivators, key strengths, learning insights, and areas for improvement.</li>
        </ul>
      </div>
    </div>
  );
}

export default Slide1;
