import React from "react";
import BehavioralTraitsSlide2 from "../../../../assets/BehavioralTraitsSlide2.svg";

function Slide2() {
  return (
    <div className="flex md:flex-row flex-col items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <img src={BehavioralTraitsSlide2} alt="" className="w-full h-full" />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          What does the report include?
        </h5>
        <p className="text-base text-pirmaryBlue  mb-6">
          The report measures four dimensions of your behavior style: Decisive, Interactive, Stabilizer, and Cautious. It provides detailed information on each of these elements, including:
        </p>
        <ul className="list-disc pl-5 text-base text-pirmaryBlue">
          <li>Your preferred behavioral style descriptor: An infographic that illustrates your default behavior</li>
          <li>Style summary: A comparison graph of your natural and adaptive behavioral styles</li>
          <li>Success traits: Detailed information on your four unique DISC elements</li>
          <li>Natural style pattern: Information about your behavior when you are most comfortable</li>
          <li>Adaptive style pattern: Information about your behavior when trying to fit a situation better</li>
          <li>Ideas for being more effective: Insights into becoming more effective by understanding your behavior</li>
          <li>Ideas for staying motivated: Ways to ensure your environment is motivational</li>
          <li>Strengths-based insights: Understanding your unique behavioral strengths and working for your success and satisfaction</li>
          <li>Areas of continual improvement: Become aware of potential problems because of your behavioral style</li>
          <li>Ideal job climate: Identify the environment that will help you succeed based on how you prefer to behave</li>
          <li>Preferred training and learning styles: Identify your desired ways to share and convey information</li>
          <li>Communication insights: Effective and ineffective ways to communicate with you based on how you choose to behave</li>
        </ul>
      </div>
    </div>
  );
}

export default Slide2;
