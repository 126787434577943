import React from "react";
import Profile from "../profile/Profile";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as TripalogoLight } from "../../../assets/tripalogowhite.svg";
import { ReactComponent as Collapssidebar } from "../../../assets/collapssidebar.svg";
import { ReactComponent as Profileicon } from "../../../assets/profileicon.svg";
import { ReactComponent as Dashboardicon } from "../../../assets/dashboardicon.svg";
import { ReactComponent as Behavioricon } from "../../../assets/behavioricon.svg";
import { ReactComponent as Valueicon } from "../../../assets/valueicon.svg";
import { ReactComponent as Rolebaseicon } from "../../../assets/rolebaseicon.svg";
import { ReactComponent as Joblisticon } from "../../../assets/joblisticon.svg";
import { ReactComponent as Settingsicon } from "../../../assets/settingsicon.svg";
import { ReactComponent as SignOutIcon } from "../../../assets/signOutIcon.svg";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { makeStyles } from "@mui/styles";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { handleBudgetInstructions } from "../../../../redux/actions/personalBudget";
const barIconStyle = makeStyles((theme) => {
  return {
    root: {
      width: "32px!important",
      height: "32px!important",
      cursor: "pointer",
      position: "relative",
    },
  };
});

export default function SidebarProfile({ setopenSide, openSide, routeName, setOpenSignOut, openSignOut }) {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user);
  // const [allData, setAllData] = useState({});
  const classes = barIconStyle();
  // const getData = () => {
  //   getByUserId().then((res) => {
  //     setAllData(res.data);
  //   });
  // };

  // useEffect(() => {
  //   getData();
  // }, []);
  return (
    <div>
      <div
        className={`fixed top-0 z-10 bg-white h-16 shadow-md transition-all right-0 ${!openSide ? "w-[calc(100%-280px)]" : "w-full"
          }`}
      >
        {openSide && (
          <DehazeIcon
            className={classes.root + " shadow-lg rounded-md top-4 left-4"}
            onClick={() => setopenSide((prev) => !prev)}
          />
        )}
        <div className="flex justify-between w-[calc(100%-128px)] h-full items-center absolute top-0 mx-16">
          <div className=" items-center font-bold relative">

            {
              (() => {
                if (routeName === 'BEHAVIORALTRAITS')
                  return 'BEHAVIORAL TRAITS'
                if (routeName === 'ROLEBASE PROFILE')
                  return 'ROLE-BASED COMPETENCIES'
                if (routeName === 'VALUE PROFILE')
                  return 'VALUES'
                if (routeName === 'JOBLIST PROFILE')
                  return 'JOB LIST'
                if (routeName === 'PROFILE/SETTINGS')
                  return 'PROFILE SETTINGS'
                if (routeName === 'PERSONAL BUDGET')
                  return 'FINANCIAL WELLNESS'
                else
                  return <>{routeName}</>
              })()
            }

            {routeName === 'PERSONAL BUSINESS MODEL' && (
              <InfoIcon
                className='text-secondaryBalck cursor-pointer ml-1'
                fontSize='small'
                onClick={() => dispatch(handleBudgetInstructions(true))}
              />
            )}
            {routeName === 'PERSONAL BUDGET' && (
              <Tooltip placement="top"
                title={
                  <div className="rounded-md">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Financial wellness
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                      Financial wellness involves implementing a powerful tool known as a budget, which aids in keeping track of your income and expenses over a specific period. By creating a spending plan, you can gain control over your finances, ensuring your expenditures don't exceed your earnings.
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
              </Tooltip>
            )}
            {routeName === 'VALUE PROFILE' && (
              <Tooltip placement="top"
                title={
                  <div className="rounded-md w-[500px]">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Values
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                      This report will help you know and understand your seven areas and unique motivations influencing your sense of purpose. This report will help you know and understand your seven (7) areas of unique motivation that influence your behavior, performance, and sense of purpose. By understanding your unique values, you can gain insight into what drives you and how you can align your actions and decisions with your personal and professional goals. <br /><br />

                      Our analysis of your values is based on a comprehensive assessment that evaluates your beliefs, attitudes, and priorities. We have identified the core values that are most important to you and how they shape your behavior and decision-making. <br /><br />

                      Understanding your values and how they relate to your goals and aspirations allows you to make more informed choices and act aligned with your personal and professional vision. This can help you build meaningful relationships, succeed tremendously, and find purpose and fulfillment. <br /><br />

                      This executive summary provides a valuable tool for self-reflection and personal growth, helping you better understand yourself and your motivations.
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
              </Tooltip>
            )}
            {routeName === 'BEHAVIORALTRAITS' && (
              <Tooltip placement="top"
                title={
                  <div className="rounded-md w-[500px]">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Behavioral traits
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                      This executive summary aims to explain your behavior by breaking it down into individual traits and examining their chemistry combinations. Our analysis is based on the original DISC self-assessment tool created by psychologist William Moulton Marston in 1928, which uses emotional and behavioral theory to identify specific traits. <br /><br />

                      Our analysis identified your unique combination of <span className='text-[#FF0000] font-bold'>Dominance</span>, <span className='text-[#E5C311] font-bold'>Influence</span>, <span className='text-[#008000] font-bold'>Steadiness</span>, and <span className='text-[#0000FF] font-bold'>Conscientiousness</span> traits. Dominance measures your assertiveness and willingness to take charge, while Influence measures your social skills and ability to connect with others. Steadiness measures your patience and tendency to maintain stability, while Conscientiousness measures your attention to detail and willingness to follow the rules.<br /><br />

                      By understanding your individual traits and how they interact with each other, you can gain valuable insights into your own behavior and how it may be perceived by others. This can be useful in both personal and professional contexts, helping you to communicate more effectively, work more collaboratively, and achieve your goals more efficiently.
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
              </Tooltip>
            )}
            {routeName === 'DASHBOARD' && (
              <Tooltip
                placement="top"
                title={
                  <div className="rounded-md w-[500px]">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Dashboard
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                      The Dashboard is a tool that provides a comprehensive snapshot of your behavior traits, motivations, and critical thinking abilities that are specific to your role. This information can help you better understand yourself and your strengths, which can be valuable in your personal and professional life. <br /><br />

                      The Dashboard measures your unique motivations influencing your sense of purpose, such as what drives you to pursue specific goals or interests. For example, it may measure your desire for achievement, recognition, or personal growth.<br /><br />

                      It also evaluates your role-specific competencies and decision-making style, which define your critical thinking abilities. This can include problem-solving, analytical thinking, and strategic planning skills.<br /><br />

                      Using the Dashboard, you can gain valuable insights into your behavior and thinking, which can help you make better decisions and achieve your goals more effectively.
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon
                  className="text-secondaryBalck cursor-pointer ml-1"
                  fontSize="small"
                />
              </Tooltip>
            )}
          </div>
          <div className="">
            {/* <Stack spacing={4} direction="row" sx={{ color: "action.active" }}>
              <Badge color="success" badgeContent={99}>
                <NotificationsIcon />
              </Badge>
            </Stack> */}
          </div>
        </div>
      </div>
      {!openSide && (
        <div
          className="fixed lg:hidden top-0 left-0 right-0 bottom-0 bg-gray-200 z-20"
          onClick={() => setopenSide((prev) => !prev)}
        ></div>
      )}
      <div
        className={`w-[280px] bg-[#1C1D1F] top-0 transition-all min-h-screen px-3 py-6 z-30 fixed h-screen overflow-y-auto ${openSide ? "-left-[280px]" : "left-0"
          }`}
      >
        <div className="flex justify-between z-30">
          <Link to="/coaching/profile">
            <TripalogoLight className="h-9 w-28" />
          </Link>
          <Collapssidebar
            className={`cursor-pointer relative top-2 ${openSide ? "rotate-180" : "rotate-0"
              } transition-all`}
            onClick={() => setopenSide((prev) => !prev)}
          />
        </div>
        <div className="my-5">
          <Profile
            alt="profile"
            src={user?.candidate_picture_upload_path}
            name={user?.first_name}
            last_name={user?.last_name}
            email={user?.email_id}
          />
        </div>
        {/*  */}
        {/* <div> */}
        <div>
          <ul>
            <Tooltip placement="right"
              title={
                <div className="rounded-md">
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    My profile is an overview of your professional background, skills, training, and unique motivations that drive your sense of purpose. It includes behavior traits and role-specific competencies that make you stand out in the workplace. This profile is designed to showcase your strengths and help potential employers and colleagues get to know you better. Keep your profile up to date and make the most of your professional identity.
                  </div>
                </div>
              }
              arrow
            >
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/coaching/profile"
                  className={({ isActive }) =>
                    "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                    (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                  }
                >
                  <Profileicon className="h-5 w-5 mr-2" />
                  My profile
                </NavLink>

              </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                <div className="rounded-md">
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    The primary purpose of the dashboard is to give you a quick overview of your behavior traits, unique motivations, and role-specific competencies. It also helps you understand your decision-making style and critical thinking skills. Finally, use the Dashboard to track your progress and identify improvement areas. By regularly checking your Dashboard, you can stay on top of your professional development and make informed decisions for your career.
                  </div>
                </div>
              }
              arrow
            >
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/coaching/dashboard"
                  className={({ isActive }) =>
                    "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                    (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                  }
                >
                  <Dashboardicon className="h-5 w-5 mr-2" />
                  Dashboard
                </NavLink>
              </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                <div className="rounded-md">
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    The behavior traits report provides a detailed breakdown of your behavior traits as individual entities and in chemistry combinations. By understanding your report, you can leverage your natural talents, identify areas for improvement, and enhance your performance to achieve your desired success. Use this report as a valuable tool for self-reflection and personal growth, and take advantage of the insights it provides to enhance your professional and personal life.
                  </div>
                </div>
              }
              arrow
            >
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/coaching/behavioraltraits"
                  className={({ isActive }) =>
                    "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                    (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                  }
                >
                  <Behavioricon className="h-5 w-5 mr-2" />
                  Behavioral traits
                </NavLink>
              </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                <div className="rounded-md">
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    The values report is designed to help you identify your unique set of values and motivations that shape your sense of purpose. By understanding these values, you can determine what type of job opportunities will provide you with the necessary elements to reach your peak performance, such as professional growth, variety, security, flexibility, and more. In addition, the report aims to guide you toward a fulfilling career path that aligns with your values and goals.
                  </div>
                </div>
              }
              arrow
            >
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/coaching/value-profile"
                  className={({ isActive }) =>
                    "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                    (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                  }
                >
                  <Valueicon className="h-5 w-5 mr-2" />
                  Values
                </NavLink>
              </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                <div className="rounded-md">
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    The role-based competencies report offers a comprehensive list of skills based on your unique combination of decision-making styles: personal, practical, and analytical. By identifying your strengths and weaknesses, this report helps you make the necessary adjustments to achieve your desired level of performance. With this information, you can focus on developing your competencies and refining your skills to excel in your role and advance your career.
                  </div>
                </div>
              }
              arrow
            >
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/coaching/rolebase-profile"
                  className={({ isActive }) =>
                    "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                    (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                  }
                >
                  <Rolebaseicon className="h-5 w-5 mr-2" />
                  Role-based competencies
                </NavLink>
              </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                <div className="rounded-md">
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    The job list provides a list of job openings from companies participating in the TRIPA platform that match your unique set of skills, behavioral traits, role-specific competencies, and values. This feature is a valuable tool for job seekers to find relevant job opportunities that align with their career aspirations and personal goals. In addition, the job list feature is designed to be user-friendly and efficient, allowing users to quickly and easily navigate the list and apply to job postings that interest them.
                  </div>
                </div>
              }
              arrow
            >
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/coaching/joblist-profile"
                  className={({ isActive }) =>
                    "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                    (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                  }
                >
                  <Joblisticon className="h-5 w-5 mr-2" />
                  Job list
                </NavLink>
              </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                <div className="rounded-md">
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Financial wellness involves implementing a powerful tool known as a budget, which aids in keeping track of your income and expenses over a specific period. By creating a spending plan, you can gain control over your finances, ensuring your expenditures don't exceed your earnings. <br /><br />

                    With a budget, you can plan for short- and long-term expenses, fostering discipline for making smart financial decisions. Tracking your spending allows you to identify areas for potential cutbacks, creating opportunities to save money and adjust your budget accordingly. Consequently, adhering to a budget can help you achieve your financial goals and construct a secure financial future.
                  </div>
                </div>
              }
              arrow
            >
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/coaching/personal-budget"
                  className={({ isActive }) =>
                    "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                    (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                  }
                >
                  <Joblisticon className="h-5 w-5 mr-2" />
                  Financial wellness
                </NavLink>
              </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                <div className="rounded-md">
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    TRIPA offers a personal business model tool that helps you identify and describe your unique selling proposition (USP) to create and capture economic and social value. With this tool, you can also develop a Personal Purpose Statement (PPS) based on your core values, beliefs, and objectives to chart your life's purpose. The Personal Business Model is an effective way to help you create a clear roadmap for your personal and professional growth.
                  </div>
                </div>
              }
              arrow
            >
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/coaching/personal-business-model"
                  className={({ isActive }) =>
                    "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                    (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                  }
                >
                  <Joblisticon className="h-5 w-5 mr-2" />
                  Personal business model
                </NavLink>
              </li>
            </Tooltip>
            <Tooltip placement="right"
              title={
                <div className="rounded-md">
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    The Account settings feature allows you to manage and update your account information. You can use this feature to perform various actions such as uploading a profile picture, changing your password, restricting access to your account by certain companies, deactivating your account, deleting your account, and managing your subscription. This way, you can ensure that your account is up-to-date and secure and that you have control over your TRIPA experience.
                  </div>
                </div>
              }
              arrow
            >
              <li className="rounded-lg mb-2">
                <NavLink
                  to="/coaching/profile/settings"
                  className={({ isActive }) =>
                    "w-full p-3 flex rounded-md text-white transition-all text-sm nav-link" +
                    (!isActive ? " bg-[#1c1d1f]" : " bg-[#37383A]")
                  }
                >
                  <Settingsicon className="h-5 w-5 mr-2" />
                  Account settings
                </NavLink>
              </li>
            </Tooltip>
            <li className="rounded-lg mb-2">
              <NavLink
                to="#"
                className={`w-full p-3 flex rounded-md text-white transition-all text-sm nav-link bg-[#1c1d1f] "
                `}
                onClick={() => setOpenSignOut(true)}
              >
                <SignOutIcon className="h-5 w-5 mr-2" />
                Sign out
              </NavLink>
            </li>

          </ul>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
