import React from "react";

function DangerLightBtn({ children, onClick, customStyle, type }) {
  return (
    <button
      onClick={onClick}
      className="bg-dangerLight hover:bg-[#f5b8b8] py-[9px] px-[21px] d-flex flex-row 
      items-center rounded text-danger text-base font-bold transition-all"
      style={customStyle}
      type={type}
    >
      {children}
    </button>
  );
}

export default DangerLightBtn;
