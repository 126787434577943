import React from "react";

import BarCharts from "./BarCharts";
import { getHrDashboardData, getHRProfile } from "../../../../../../../api/hr";
import CandidateList from "./CandidateList";
import LineChart from "./LineChart";
import DashboardPieChart from "./DashboardPieChart";
import DashboardCounts from "./DashboardCounts";

function MainDashboard() {
  const [pools, setPools] = React.useState([]);
  const [counts, setCounts] = React.useState();
  const [pieChartData, setPieChartData] = React.useState([]);
  const [lineChartData, setLineChartData] = React.useState([]);

  const getAllPools = async () => {
    try {
      const res = await getHRProfile();
      if (res?.data?.pool?.length > 0) {
        setPools(res.data.pool);
        res.data?._id && getDashboardData(res.data._id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDashboardData = async (company_profile_id) => {
    try {
      const res = await getHrDashboardData({ company_profile_id });
      if (res?.data) {
        res.data?.counts && setCounts(res.data.counts);
        res.data?.openPositionsChart &&
          setPieChartData(res.data.openPositionsChart);
        res.data?.newApplicants && setLineChartData(res.data.newApplicants);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getAllPools();
  }, []);

  return (
    <>
      <DashboardCounts counts={counts} />
      <div className="grid xl:grid-cols-10 grid-cols-1 gap-4">
        <div className="xl:col-span-4 col-span-1">
          <DashboardPieChart data={pieChartData} />
        </div>
        <div className="xl:col-span-6 col-span-1 ">
          <LineChart data={lineChartData} />
        </div>
      </div>
      <BarCharts pools={pools} />
      <CandidateList />
    </>
  );
}

export default MainDashboard;
