import React, { useEffect, useState } from "react";
import InfoBoxes from "../../../../sharedComponents/profile/infoBox";
import TeamCard from "./sharedComponent/TeamCard";

import condidate1 from "../../../../../assets/condidate1.svg";
import condidate2 from "../../../../../assets/condidate2.svg";
import SuccessBtn from "../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import {
  getTeamCompatibility,
  addCandidateToCompatibility,
} from "../../../../../../api/hr";
import { CircularProgress } from "@mui/material";

function TeamCompatibility({ selectedTeam, selectedCandidatesDetail }) {
  const [loading, setLoading] = useState(false);
  const [teamCompatibility, setTeamCompatibility] = useState([]);
  const generateTeamCompatibility = async () => {
    const res = await getTeamCompatibility({
      id: selectedTeam,
    });
    if (
      res?.status === 200 &&
      res?.data?.team_compatibility &&
      Array.isArray(res.data.team_compatibility)
    ) {
      setTeamCompatibility(res.data.team_compatibility);
    }else{
      setTeamCompatibility([]);
    }
  };
  const addCandidateToCompatibilityTeam = async () => {
    setLoading(true);
    const res = await addCandidateToCompatibility({
      id: selectedTeam,
      compatibility_candidates: selectedCandidatesDetail.map((candidate) => {
        return { candidate_profile_id: candidate?.candidate_profile_id?._id };
      }),
    });
    if (res?.status === 200) {
      generateTeamCompatibility();
    }
    setLoading(false);
  };
  useEffect(() => {
    if (selectedTeam !== undefined) {
      generateTeamCompatibility();
    }
  }, [selectedTeam, selectedCandidatesDetail]);
  useEffect(() => {
    addCandidateToCompatibilityTeam();
  }, [selectedCandidatesDetail]);
  return (
    
    <InfoBoxes
      title="Team compatibility"
      containerStyle="xl:max-h-[80vh] max-h-[60vh] overflow-y-auto overflow-hidden flex flex-col"
      TooltipText={
        <>
          <p>
          It shows the relationship between two or more team members' behavioral traits, compatibility, and potential conflict.     
           </p>
        </>
      }
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {teamCompatibility.map((compatibility, index) => {
            return <TeamCard compatibility={compatibility} key={index} />;
          })}
        </>
      )}
    </InfoBoxes>
  );
}

export default TeamCompatibility;
