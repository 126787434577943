import React from "react";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

function PasswordField({
  name,
  label,
  labelFor,
  id,
  value,
  onChange,
  placeholder,
  style,
  className,
}) {
  const [isPasswordShow, setIsPasswordShow] = React.useState(false);

  const togglePassword = () => {
    setIsPasswordShow(!isPasswordShow);
  };

  return (
    <div className="flex flex-col relative w-full">
      <label
        htmlFor={labelFor}
        className="text-pirmaryBlue text-base font-normal"
      >
        {label && label}
      </label>
      <input
        type={isPasswordShow ? "text" : "password"}
        className={`focus:outline-none border-2 border-solid border-grayLight p-[12px] pr-12 rounded ${className}`}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={style}
      />
      <button
        type="button"
        onClick={togglePassword}
        className={`absolute right-4 top-[50%]
          ${!label && " translate-y-[-50%]"}
          `}
      >
        {isPasswordShow ? (
          <VisibilityOutlinedIcon sx={{ color: "#828282" }} />
        ) : (
          <VisibilityOffOutlinedIcon sx={{ color: "#828282" }} />
        )}
      </button>
    </div>
  );
}

export default PasswordField;
