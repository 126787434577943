import axios from "axios";
import { toast } from "react-toastify";
import config from "../../config/config";

export const baseURL = `${config.backEndUrl}/auth/api/tripa/`;

export const headerJson = () => {
  const token = localStorage.getItem("access");
  return {
    "Content-Type": "application/json",
    Authorization: `bearer ${token}`,
    "x-access-token": token,
  };
};

function headerFormData() {
  const token = localStorage.getItem("access");
  return {
    "Content-Type": "multipart/form-data",
    Authorization: `bearer ${token}`,
    "x-access-token": token,
  };
}
axios.defaults.withCredentials = true;
const Api = axios.create({
  baseURL: `${baseURL}`,
  timeout: 30000,
  withCredentials: true,
  credentials: "include",
});

Api.interceptors.response.use(
  function (response) {
    if (response?.data?.error) {
      toast.warning(response.data.error);
      return response;
    }
    return response;
    // should be edit ....
  },
  async function (error) {
    let errorMessage = "";
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        errorMessage = error.response.data.message;
        toast.warning(errorMessage);
        return Promise.reject(error);
      } 
      if (status === 403) {
        return false;
      } 
      if (status === 400) {
        errorMessage = error.response.data.message;
        toast.warning(errorMessage);
        return Promise.reject(error);
      }
      if (status === 404) {
        errorMessage = error.response.data.message;
        toast.warning(errorMessage);
        return Promise.reject(error);
      } 
      if (error.response.data.message === 'token is not valid!') {
        return false;
        // do nothing
      } else {
        errorMessage = error.response.data.message;
        toast.warning(errorMessage);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const signInApiHR = (body) => {
  return Api.post("signin", body);
};
export const signUpApiHR = (body, referral) => {
  return Api.post(`signup/${referral}`, body);
};

// Hr dashboard api
export const getHrDashboardData = (body) => {
  return Api.post("company/dashboard/dashboard", body, {
    headers: headerJson(),
  });
};

// chart api
export const barChartApi = (body) => {
  return Api.post("company/pool/candidateCharts", body, {
    headers: headerJson(),
  });
};

// send email to verify
export const sendEmailToVerify = (email) => {
  return Api.post("auth/sendresetPassword", {
    email_address: email,
    user_type: 'hr'
  });
};

// change password
export const changePassword = (body) => {
  return Api.post("auth/changePassword", body, {
    headers: headerJson(),
  });
};

// profile
export const getHRProfile = () => {
  return Api.get("company/profile/findByUserId", {
    headers: headerJson(),
  });
};

// subscription and billing
export const getCustomerStripeData = (body) => {
  return Api.post("company/accountSettings/getCustomer", body, {
    headers: headerJson(),
  });
};

export const saveCustomerStripeData = (body) => {
  return Api.post("company/accountSettings/billingDetails", body, {
    headers: headerJson(),
  });
};

export const saveCardData = (body) => {
  return Api.post("company/accountSettings/cardSave", body, {
    headers: headerJson(),
  });
};

export const getCardData = (body) => {
  return Api.post("company/accountSettings/cardList", body, {
    headers: headerJson(),
  });
};

export const postHRProfile = (body) => {
  let bodyFormData = new FormData();
  bodyFormData.append("body", JSON.stringify(body));
  body.image &&
    typeof body.image !== "string" &&
    bodyFormData.append("file", body.image);
  return Api.post("company/profile/saveAndUpdate", bodyFormData, {
    headers: headerFormData(),
  });
};

// pool
export const postHRPool = (body) => {
  return Api.post("company/pool/saveAndUpdate", body, {
    headers: headerJson(),
  });
};
export const getCandidatesByPool = (body) => {
  return Api.post("company/pool/candidatesList", body, {
    headers: headerJson(),
  });
};
export const addToPool = (body) => {
  return Api.post("company/pool/addCandidateToPool", body, {
    headers: headerJson(),
  });
};

// candidate list
export const candidateListApi = (body) => {
  return Api.post("company/pool/matchCandidatesList", body, {
    headers: headerJson(),
  });
};

export const updateCandidateStatus = (body) => {
  return Api.post("company/pool/updateStatus", body, {
    headers: headerJson(),
  });
};

// interview score card
export const saveScoreCard = (body) => {
  return Api.post("company/interviewScoreCard/saveAndUpdate", body, {
    headers: headerJson(),
  });
};

// remove
export const deleteHRPool = (data) => {
  return Api.delete("company/pool/deleteById", {
    headers: headerJson(),
    data,
  });
};

// jobPost
export const getHRJobPost = () => {
  return Api.get("company/profile/findByUserId", {
    headers: headerJson(),
  });
};
export const postHRJobPost = (body) => {
  let bodyFormData = new FormData();
  bodyFormData.append("body", JSON.stringify(body));
  body.image &&
    typeof body.image !== "string" &&
    bodyFormData.append("file", body.image);
  return Api.post("company/jobPost/saveAndUpdate", bodyFormData, {
    headers: headerFormData(),
  });
};
export const getPoolsByTitle = (body) => {
  return Api.post("company/jobPost/getByJobTitle", body, {
    headers: headerJson(),
  });
};
// remove
export const deleteHRJobPost = (data) => {
  return Api.delete("company/jobPost/deleteById", {
    headers: headerJson(),
    data,
  });
};

//contacts
export const getAllContacts = () => {
  return Api.get("auth/contactList/findByUserId", {
    headers: headerJson(),
  });
};
export const postContact = (body) => {
  return Api.post("auth/contactList/saveAndUpdate", body, {
    headers: headerJson(),
  });
};
export const deleteContact = (data) => {
  return Api.delete("auth/contactList/deleteById", {
    headers: headerJson(),
    data,
  });
};

// send Assessment
export const postHRSendAssessment = (body) => {
  return Api.post("company/sendAssessment/saveAndUpdate", body, {
    headers: headerJson(),
  });
};

// remove
export const deleteHRSendAssessment = (body) => {
  return Api.post("company/sendAssessment/deleteById", body, {
    headers: headerJson(),
  });
};

// get applications
export const getRecievedApplications = () => {
  return Api.get("company/jobPost/getCompnayApplications", {
    headers: headerJson(),
  });
};

// job Post
export const postHRJobPostApplication = (body) => {
  return Api.post("company/jobPost/application", body, {
    headers: headerJson(),
  });
};
// remove ---> check it plz parham
export const deleteHRJobPostApplication = (body) => {
  return Api.delete("company/jobPost/application", body, {
    headers: headerJson(),
  });
};

//remove logo - Added by Niraj

export const deleteProfileLogo = () => {
  return Api.delete("company/profile/deleteLogoByUserId", {
    headers: headerJson(),
  });
};

export const isCompanyReturnBoolean = () => {
  return Api.get("company/checkRole", {
    headers: headerJson(),
  });
};
export const getTeamList = (body) => {
  return Api.post("company/team/getTeams", body, {
     headers: headerJson(),
  });
};

export const getCandidateListForTeam = (body) => {
  return Api.post("company/team/candidatesList", body, {
    headers: headerJson(),
  });
};

export const teamSaveUpdate = (body) => {
  return Api.post("company/team/saveAndUpdate", body, {
    headers: headerJson(),
  });
};

export const addCandidateToTeam = (body) => {
  return Api.post("company/team/saveCandidateToTeam", body, {
    headers: headerJson(),
  });
};

export const deleteTeam = (body) => {
  return Api.post("company/team/deleteById", body, {
    headers: headerJson(),
  });
};

export const getTeamById = (body) => {
  return Api.post("company/team/getById", body, {
    headers: headerJson(),
  });
};

export const addCandidateToCompatibility = (body) => {
  return Api.post("company/team/saveCompatibilityCandidateToTeam", body, {
    headers: headerJson(),
  });
};

export const getTeamCompatibility = (body) => {
  return Api.post("company/team/teamCompatibility", body, {
    headers: headerJson(),
  });
};

export const getWorkforceList = (body) => {
  return Api.post("company/pool/workforceList", body, {
    headers: headerJson(),
  });
};

export const sendVerifyMail = (email) => {
  return Api.post("sendVerifyMail", {
    roles: 'compnay',
    email_address: email,
  });
};

// *********************************************** //
// -------------- HR apis completed -------------- //
// *********************************************** //
