import React from "react";

function SecondaryBtn({children, onClick, customStyle}) {
  return (
    <button onClick={onClick}
      className="bg-[#E3E6E9] hover:bg-[#d2d3d4] py-[9px] px-[32px] d-flex flex-row 
      items-center rounded text-[#828282] text-base transition-all"
      style={customStyle}
    >
      {children}
    </button>
  );
}

export default SecondaryBtn;
