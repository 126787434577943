import React from 'react';
import Infobox from '../sharedComponent/Infobox';

function RoleBaseTab({ data, color }) {
  return (
    <>
      <p className='my-6 text-base text-pirmaryBlue' dangerouslySetInnerHTML={{ __html: data?.headingTitle.replace('?', '? <br /><br />') || '' }} />

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4'>
        {data?.subRoleCompetencies?.length &&
          data.subRoleCompetencies.map(
            ({ title = '', description = '', value = 0 }, index) => (
              <Infobox
                key={index}
                title={title}
                description={description}
                percentage={value}
                pathColor={color}
              />
            )
          )}
      </div>
    </>
  );
}

export default RoleBaseTab;
