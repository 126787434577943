import { Checkbox, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePickerStyles } from "../../../sharedComponents/materialStyles";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useFormik } from "formik";
import DangerLightBtn from "../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import { addWorkExperienceJC, deleteWorkExperienceJC } from "../../../../../api/jc";
import { toast } from "react-toastify";
import moment from "moment";
import cx from "classnames";

export default function WorkExperienceModal({ handleReload, selected }) {
  const themeDataPicker = DatePickerStyles();
  const [file, setFile] = useState("");
  const formData = new FormData();

  const handleSubmit = () => {
    const id = selected ? {id: selected._id} : {}
    formData.append("body", JSON.stringify({...formik.values, ...id}));
    if (file) {
      formData.append("file", file, file.name);
    }
    addWorkExperienceJC(formData).then(() => {
      toast.success(selected ? 'Updated experience': "New Experience Added!")
      handleReload();
    }).catch(err =>
      {
        console.log(err)
        toast.error(selected ? 'Error while updating experience' : 'Error while creating the experienc')
      })

  };

  const deleteExperience = (e) => {
    e.preventDefault();
    // const data = FormData();
    deleteWorkExperienceJC({
      id: selected._id
    }).then(res => {
      toast.success('Experience deleted.');
      handleReload();
    }).catch(err => {
      toast.error('Error while deleting the experience.')
    })
  }

  const validate = (values) => {
    const errors = {};
    
    if (!values.from_date) errors.from_date = true;
    if (!values.company_name) errors.company_name = true;
    if (!values.role) errors.role = true;
    if (values?.from_date && values?.to_date) {
      if (
        moment(values.from_date).isAfter(values.to_date) ||
        moment(values.from_date).isSame(values.to_date)
      )
        errors.date = true;
    }
  
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    validate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    initialValues: {
      from_date: '',
      to_date: '',
      isPresent: false,
      company_name: '',
      role: '',
      experience_summary: '',
      achievements: '',
    }
  });

  useEffect(() => {
    if (selected) {
      const { from_date, to_date , company_name , role , isPresent, achievements, experience_summary } =
        selected || {};
      formik.setValues({
        from_date: moment(from_date).format('YYYY-MM-DD') || "",
        to_date: moment(to_date).format('YYYY-MM-DD') || "",
        company_name: company_name || "",
        role: role || "",
        experience_summary: experience_summary || "",
        achievements: achievements || "",
        isPresent: isPresent || false
      });
    }
  }, []);

  const { errors } = formik || {};

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
          <div className="flex flex-col">
            <label className="text-base text-pirmaryBlue">Start date *</label>
            <TextField
              type="date"
              value={formik?.values?.from_date}
              onChange={(e) => {
                formik.setFieldValue("from_date", e.target.value);
              }}
              error={errors.from_date || errors.date}
              defaultValue="2017-05-24"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              className={themeDataPicker.root}
            />
            {errors.from_date && (
              <span className="text-red-500">This is required field</span>
            )}
            {errors.date && (
              <span className="text-red-500">start date should be less than end date</span>
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-base text-pirmaryBlue">End date</label>
            <TextField
              id="date"
              type="date"
              sx={{ width: 220 }}
              disabled={formik?.values?.isPresent ? true : false}
              value={formik?.values?.to_date}
              onChange={(e) => {
                formik.setFieldValue("to_date", e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              className={themeDataPicker.root}
            />
          </div>
          <div className="flex flex-col col-span-2">
            <div className="flex justify-start items-center">
              <Checkbox
                className=""
                onChange={(e) =>
                  formik.setFieldValue("isPresent", e.target.checked)
                }
                checked={formik?.values?.isPresent}
                id="checkboxtick"
                color="success"
              />
              <label
                htmlFor="checkboxtick"
                className="text-secondaryBalck text-base cursor-pointer"
              >
                Present
              </label>
            </div>
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">Company name *</label>
            <input
              value={formik?.values?.company_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="company_name"
              type="text"
              placeHolder="Enter Company Name"
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.company_name },
                "border-grayLight"
              )}
            />
            {errors.company_name && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">Role *
              <Tooltip placement="top" 
              title={
                  <div className="rounded-md">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Role
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                      Please enter the job title that best describes the responsibilities and duties, required skills and qualifications, and expected experience level of the position you currently or previously held in this work experience. This information will help TRIPA to understand your professional background and qualifications better.
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
              </Tooltip>
            </label>
            <input
              value={formik?.values?.role}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="role"
              type="text"
              placeHolder="Enter Role"
              className={cx(
                "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                { "border-red-500": errors.role },
                "border-grayLight"
              )}
            />
            {errors.role && (
              <span className="text-red-500">This is required field</span>
            )}
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">
              Experience summary
              <Tooltip placement="top" 
              title={
                  <div className="rounded-md w-[500px]">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Experience summary
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    When writing your experience summary, it's crucial to highlight your most relevant experiences and accomplishments concisely and clearly. One way to approach this is by focusing on your impact in previous roles and specific corporate contributions. <br/><br/>
                    This will help demonstrate your skills and competencies and set you apart from other candidates.<br/><br/>

                    For example, suppose you worked as a Marketing Manager. In that case, you might highlight your success in developing and executing successful marketing campaigns, ability to analyze data and make informed decisions, and proficiency in various marketing tools and platforms.<br/><br/>
                    Similarly, you worked as a Senior Full Stack Developer. In that case, mention your expertise in programming languages, experience building and maintaining complex software applications, and ability to work collaboratively with cross-functional teams.<br/><br/>

                    By showcasing your strengths and accomplishments in your experience summary, you can demonstrate to potential employers that you are a strong candidate with a proven track record of success in your field. This can increase your chances of getting hired and help you stand out among other applicants.
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
              </Tooltip>
            </label>
            <input
              value={formik?.values?.experience_summary}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="experience_summary"
              type="text"
              placeHolder="Enter Experience Summary"
              className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
            />
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">
              Achievements
              <Tooltip placement="top" 
              title={
                  <div className="rounded-md w-[500px]">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Achievements
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    In this section of your work experience, you should highlight your most impressive accomplishments in this role. Try to be as concise as possible while providing context and tangible results. Quantify your achievements using percentages in the currency of the region you work or worked. <br/><br/>

                    For example, if you worked as a Sales Manager, you might highlight your success in increasing annual sales by 50% or reducing customer complaints by 25%. If you worked as a Project Manager, you might mention how you streamlined workflow and reduced work hours from 40 a week to 25, resulting in a 20% increase in productivity.<br/><br/>

                    By highlighting your most impressive achievements, you can demonstrate the value you provided to your previous employer(s) and the potential value you can bring to the new employer. This can make you a more attractive candidate and increase your chances of getting hired.
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
              </Tooltip>
            </label>
            <textarea
              value={formik?.values?.achievements}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="achievements"
              type="text"
              placeHolder="Enter Achievements"
              className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none resize-y min-h-[100px]"
            />
          </div>
          <div className="flex flex-col col-span-2">
            <label className="text-base text-pirmaryBlue">
              Upload recommendation
              <Tooltip placement="top" 
              title={
                  <div className="rounded-md w-[500px]">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Upload recommendation
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    To strengthen your job application, consider submitting a recommendation from a former manager, colleague, or professor who can attest to your professional abilities and character. Many employers value recommendations as they provide additional insight into a candidate's work history and potential fit for the position.<br/><br/>

                    If you upload a recommendation, you may be asked to provide contact information for the person providing the letter of recommendation. You may also be asked to upload a document or provide a link to an online recommendation platform.<br/><br/>

                    Choose a recommender who knows you well and can speak to your strengths and qualifications for the position. And remember to thank them for their time and effort in providing the recommendation.
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
              </Tooltip>
            </label>
            <div className="border border-dashed border-x-gray-300 h-[150px] rounded-md flex justify-center items-center">
              <div className="flex text-sm">
                <label
                  htmlFor="selectfile"
                  className="cursor-pointer text-secondaryBalck rotate-45"
                >
                  <AttachFileRoundedIcon />
                </label>
                <input
                  name="work_recommendation_upload_path"
                  // value={formik.values.work_recommendation_upload_path}
                  onChange={(e) => setFile(e.target.files[0])}
                  onBlur={formik.handleBlur}
                  className="hidden "
                  type="file"
                  id="selectfile"
                />

                <label
                  className="text-blueSelectFile cursor-pointer"
                  htmlFor="selectfile"
                >
                  Add File &nbsp;
                </label>
                <span className=""> or drop 
                files here</span>
              </div>
            </div>
          </div>
          <div className="mt-2 col-span-2 flex justify-between">
            <div>
              {selected && (
                <DangerLightBtn onClick={deleteExperience} >
                  <span className="font-bold">Delete experience</span>
                </DangerLightBtn>
              )}
            </div>
            <SuccessBtn type="submit">
              <span className="font-bold">{ selected ? "Update experience" : "Add experience"}</span>
            </SuccessBtn>
          </div>
        </div>
      </form>
    </>
  );
}
