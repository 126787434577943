import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CoachingSlide1 from "../../../assets/business_slide_1.svg";
import CoachingSlide2 from "../../../assets/business_slide_2.svg";
import CoachingSlide3 from "../../../assets/business_slide_3.svg";
import ProfessionalBusiness from "../../../assets/professionalbusiness.svg";
import cx from "classnames";
import {
  TabPanel,
  tabsStyle,
  PasswordField,
  formValidate,
} from "./reusableComponents";
import LoginWithGoogle from "../../sharedComponents/loginWithGoogle/LoginWithGoogle";
import LoginWithGithub from "../../sharedComponents/loginWithGithub/loginWithGithub";
import SuccessBtn from "../../sharedComponents/buttons/successBtn/SuccessBtn";
import Header from "../../sharedComponents/header/header";
import Footer from "../../sharedComponents/footer/footer";
import Cookies from "universal-cookie";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../styles/swiper.css";
import { Pagination, Navigation } from "swiper";
import { signInApiHR } from "../../../../api/hr";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "../ChangePassword/ResetPasswordModal";
import ResendMailVerify from "./ResendMailVerify";
import ReActiveAccount from "./ReActiveAccount";
import CryptoJS from "crypto-js";
import config from "../../../../config/config";

export default function LoginBusiness() {
  const { state } = useLocation();
  const history = useNavigate();
  const [value, setValue] = useState(0);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [resendVerifyPopUp, setResendVerifyPopUp] = useState(false);
  const [resendVerify, setResendVerify] = useState(false);
  const [reActivePopUp, setReActivePopUp] = useState(false);
  const [reActive, setReActive] = useState(false);
  const cookies = new Cookies();
  const [businessData, setBusinessData] = useState({
    email: "",
    password: "",
    keepMeLogin: false,
    emailIdError: null,
    passwordError: null,
    emailIdErrorMessage: null,
    passwordErrorMessage: null,
  });
  const [businessPassShown, setBusinessPassShown] = useState(false);

  const handleBusinessData = (event) => {
    // validateEmailBussines(businessData);
    // validatePasswordBussines(businessData);
    const name = event.target.name;
    if (event.target.type === "checkbox") {
      setBusinessData((prevData) => ({
        ...prevData,
        [name]: event.target.checked,
      }));
    } else {
      const value = event.target.value;
      setBusinessData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const validateEmailBussines = (values) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      setBusinessData((prevData) => ({
        ...prevData,
        emailIdErrorMessage: "Your Email address is not correct",
        emailIdError: true,
      }));
      return;
    }
    if (!regex.test(values.email)) {
      setBusinessData((prevData) => ({
        ...prevData,
        emailIdErrorMessage: "Your Email address is not correct",
        emailIdError: true,
      }));
      return;
    }
    setBusinessData((prevData) => ({
      ...prevData,
      emailIdError: false,
    }));
    return;
  };

  const validatePasswordBussines = (values) => {
    if (!values.password) {
      setBusinessData((prevData) => ({
        ...prevData,
        passwordErrorM: true,
        passwordErrorMessage: "Your passwrod is not correct",
      }));
      return;
    }
    // if (values.password.length < 8) {
    //   setBusinessData((prevData) => ({
    //     ...prevData,
    //     passwordError: true,
    //     passwordErrorMessage: "Password should be at least 8 charcter",
    //   }));
    //   return;
    // }
    // if (values.password.length > 20) {
    //   setBusinessData((prevData) => ({
    //     ...prevData,
    //     passwordError: true,
    //     passwordErrorMessage: "Password must be maximum 20 charcter",
    //   }));
    //   return;
    // }
    setBusinessData((prevData) => ({
      ...prevData,
      passwordError: false,
    }));
    return errors;
  };
  const errors = {};

  const businessformHandler = async (e) => {
    setBusinessData((prevData) => ({
      ...prevData,
      isLoading: true,
    }));
    e.preventDefault();
    validateEmailBussines(businessData);
    validatePasswordBussines(businessData);
    const error = formValidate(businessData);

    let temp = {
      email_address: businessData.email,
      password: businessData.password,
      roles: ["company"],
    };
    if (error) {
      setBusinessData((prevData) => ({
        ...prevData,
        isLoading: false,
      }));
    }
    if (!error) {
      signInApiHR(temp)
        .then((res) => {
          localStorage.setItem("access", res.data.Token);
          cookies.set("token-session", res.data.Token);
          var ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify("company"),
            config.cookieSecret
          ).toString();
          localStorage.setItem("role", ciphertext);
          if (businessData.keepMeLogin){
          var keepMeLogin = CryptoJS.AES.encrypt(
            JSON.stringify("true"),
            config.cookieSecret
          ).toString();
          localStorage.setItem('keepMe', keepMeLogin);
          }
          history("/business/dashboard");
        })
        .catch((e) => {
          console.log("error", e);
          setBusinessData((prevData) => ({
            ...prevData,
            isLoading: false,
          }));
        });
    }
    setBusinessData((prevData) => ({
      ...prevData,
      isLoading: false,
    }));
  };

  const classes = tabsStyle();

  React.useEffect(() => {
    if (state?.isResetPassword) {
      setResetPassword(state.token);
    }
  }, [state]);

  return (
    <div className="flex flex-col min-h-[100vh]">
      <Header />
      <div>
        <div className="login-reg-container">
          <div className="h-full col-span-1 md:w-full bg-white login-reg-wrapper">
            <div className="">
              <div className="bg-[#f8f9fa] h-full swiper-parent">
                <div className="flex flex-col justify-center items-center ">
                  <h2 className="font-bold text-[#000000] text-center text-[23px] w-full max-w-[550px] leading-[31px] mb-[30px]">  
                    Gain deep insights into your blended workforce's success traits with TRIPA Business's comprehensive psychographic analysis. Unlock the potential of your team and drive success.
                  </h2>
                  <img src={CoachingSlide2} alt="" width={'550px'} />
                  {/* {value === 0 && (
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={0}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      // navigation={true}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center ">
                          <h2 className="font-bold text-[#000000] text-[24px] px-4 w-full max-w-[550px] leading-[31px] mb-[30px]">
                            Discover your USP and increase your level of
                            performance through self-awareness
                          </h2>

                          <img src={CoachingSlide1} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center ">
                          <h2 className="font-bold text-[#000000] text-[24px] px-4 w-full max-w-[550px] leading-[31px] mb-[30px]">
                          Obtain a comprehensive snapshot of your blended workforce's psychological traits.
                          </h2>

                          <img src={CoachingSlide2} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center ">
                          <h2 className="font-bold text-[#000000] text-[24px] px-4 w-full max-w-[550px] leading-[31px] mb-[30px]">
                            Obtain a comprehensive snapshot of your blended workforce behavior traits, the unique motivation that influences their behavior, their approach to decision-making, role-specific competencies, and other trends that affect positively or negatively their peak performance.
                          </h2>

                          <img src={CoachingSlide3} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  )} */}
                  {/* {value === 1 && (
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={0}
                      loop={true}
                      pagination={{
                        clickable: true,
                      }}
                      // navigation={true}
                      modules={[Pagination, Navigation]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center overflow-hidden">
                          <div className="mb-[30px] w-full max-w-[500px] px-4">
                            <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                              Success predictor
                            </h2>
                            <p className="text-[#828282] text-[14px] text-center mt-2">
                              Take the manual and guesswork from your candidate
                              screening process to identify the success
                              predictors of those who will help you create
                              greater value for your business.
                            </p>
                          </div>
                          <img src={ProfessionalBusiness} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center overflow-hidden">
                          <div className="mb-[30px] w-full max-w-[500px] px-4">
                            <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                              Success predictor
                            </h2>
                            <p className="text-[#828282] text-[14px] text-center mt-2">
                              Take the manual and guesswork from your candidate
                              screening process to identify the success
                              predictors of those who will help you create
                              greater value for your business.
                            </p>
                          </div>
                          <img src={ProfessionalBusiness} alt="" />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="flex flex-col items-center justify-center overflow-hidden">
                          <div className="mb-[30px] w-full max-w-[500px] px-4">
                            <h2 className="font-bold text-[#000000] text-[24px] leading-[31px] ">
                              Success predictor
                            </h2>
                            <p className="text-[#828282] text-[14px] text-center mt-2">
                              Take the manual and guesswork from your candidate
                              screening process to identify the success
                              predictors of those who will help you create
                              greater value for your business.
                            </p>
                          </div>
                          <img src={ProfessionalBusiness} alt="" />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  )} */}
                </div>
              </div>
            </div>
            <div className="form-section-container">
              {/* start tab Business */}
              <p className="text-[24px] font-bold">Log In 👍</p>
              <div className="text-secondaryBalck text-[14px] mt-[10px] mb-7">
                {/* Don't have an account?
                <Link to="/business/register" className="text-success ml-1">
                  <span className="ml-2">Sign Up</span>
                </Link> */}
              </div>
              <TabPanel>
                <div>
                  {/* <div className="w-[100%] flex flex-col lg:flex-row">
                    <div className="lg:w-[50%] w-[100%] lg:my-8">
                      {" "}
                      <LoginWithGoogle
                        responseGoogle={"company"}
                        responseGoogleFail={() => {}}
                      />{" "}
                    </div>

                    <div className="lg:w-[50%] w-[100%] lg:pl-4 lg:my-8 mb-8">
                          <div className="lg:w-[100%] w-[100%] ml-1">
                          {" "}
                      <LoginWithGithub
                        responseGithub={"company"}
                        responseGithubFail={() => {}}
                      />{" "}
                        </div>
                        <div className="mx-3"></div>
                    </div>
                  </div>

                  <div className="border-t-2 relative">
                    <span className="bg-white w-[50px] h-[50px] text-center text-[#768798] absolute top-[-15px] left-[50%] translate-x-[-50%]">
                      OR
                    </span>
                  </div> */}
                  <div className="flex flex-col my-4">
                    <label
                      htmlFor="Coaching-emailInput"
                      className="text-pirmaryBlue"
                    >
                      Email address *
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={businessData.email || ""}
                      onChange={handleBusinessData}
                      id="business-emailInput"
                      placeholder="Enter your corporate email"
                      className={cx(
                        "focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded",
                        { "border-red-500": businessData.emailIdError },
                        "border-grayLight"
                      )}
                    />
                    {businessData.emailIdError && (
                      <span className="text-red-500">
                        {businessData.emailIdErrorMessage}
                      </span>
                    )}
                  </div>
                  <PasswordField
                    isPassword={businessPassShown}
                    setIsPassword={setBusinessPassShown}
                    name={"password"}
                    value={businessData.password || ""}
                    label={"Password"}
                    labelFor={"business-password"}
                    id={"business-password"}
                    onChange={handleBusinessData}
                    placeholder={"Enter your password"}
                    className={cx(
                      "focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded",
                      { "border-red-500": businessData.passwordError },
                      "border-grayLight"
                    )}
                  />
                  {businessData.passwordError && (
                    <span className="text-red-500">
                      {businessData.passwordErrorMessage}
                    </span>
                  )}
                  <div className="flex items-center justify-between">
                    <div className="flex flex-row items-center my-4">
                      <FormGroup>
                        <FormControlLabel
                          checked={businessData.keepMeLogin}
                          onChange={handleBusinessData}
                          control={<Checkbox />}
                          name="keepMeLogin"
                          label="Keep me logged in"
                          inputProps={{ "aria-label": "controlled" }}
                          className={classes.checkbox}
                        />
                      </FormGroup>
                    </div>
                    {resendVerify ? <p
                      onClick={() => setResendVerifyPopUp(true)}
                      className="text-success cursor-pointer hover:underline hover:decoration-1 hover:decoration-success"
                    >
                      Resend verify mail
                    </p> : <p/>}
                    {reActive ? <p
                      onClick={() => setReActivePopUp(true)}
                      className="text-success cursor-pointer hover:underline hover:decoration-1 hover:decoration-success"
                    >
                      Reactive you account
                    </p> : <p/>}
                    <p
                      onClick={() => setForgotPassword(true)}
                      className="text-success cursor-pointer hover:underline hover:decoration-1 hover:decoration-success"
                    >
                      Forgot your password?
                    </p>
                  </div>

                  <SuccessBtn
                    type="submit"
                    onClick={businessformHandler}
                    customStyle={{ width: "100%", height: "55px", backgroundColor: "#B07FAA" }}
                  >
                    LOGIN
                  </SuccessBtn>
                </div>
              </TabPanel>
              {/* end tab Business */}
            </div>
          </div>
        </div>
      </div>
      <ForgotPassword
        forgotPassword={forgotPassword}
        setForgotPassword={setForgotPassword}
        email={businessData?.email}
      />
      <ResendMailVerify
        resendVerifyPopUp={resendVerifyPopUp}
        setResendVerifyPopUp={setResendVerifyPopUp}
        email={businessData?.email}
        setResendVerify={setResendVerify}
      />
      <ReActiveAccount
        reActivePopUp={reActivePopUp}
        setReActivePopUp={setReActivePopUp}
        email={businessData?.email}
        setReActive={setReActive}
      />
      <ResetPassword
        resetPassword={resetPassword}
        setResetPassword={setResetPassword}
      />
      <Footer />
    </div>
  );
}
