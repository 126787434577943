import React from "react";
import CircleProgressBar from "../../../sharedComponents/circleProgressBar/CircleProgressBar";

function Infobox({ title, description, percentage, pathColor }) {
  return (
    <>
      <div className="flex mb-6 sm:flex-nowrap flex-wrap justify-center sm:justify-start col-span-1">
        <CircleProgressBar percentage={percentage} pathColor={pathColor} />
        <div className="ml-4 text-center sm:text-left mt-2 sm:mt-0">
          <h2 className="text-pirmaryBlue text-[18px] font-bold lowercase first-letter:uppercase">{title}</h2>
          <p className="text-base text-[#828282]">{description.slice(description.indexOf(')')).slice(1).trim()}</p>
        </div>
      </div>
    </>
  );
}

export default Infobox;
