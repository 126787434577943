import React from "react";
import WhiteBox from "../../../../../sharedComponents/whiteBox/WhiteBox";
import PurpleBtn from "../../../../../sharedComponents/buttons/purpleBtn/PurpleBtn";
import SuccessBtn from "../../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import SelectField from "../../../../../sharedComponents/selectField/SelectField";

import { useStyle } from "../reuseabelComponent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getCardData, saveCardData } from "../../../../../../../api/hr";
import { toast } from "react-toastify";
  import { CustomTooltip } from "../../../pages/dashboard/reusableComponent";


let initCardData = {
  card_number: '',
  month: '',
  year: '',
  cvv: '',
  is_default: false,
}

function CreditCardDetail({ data }) {
  const classes = useStyle();

  const [cardData, setCardData] = React.useState(initCardData)

  const handleSubmit = async () => {
    try {
      const res = await saveCardData(cardData);
      if (res.status === 200) toast.success('Card details saved successfully!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeData = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    setCardData((prev) => ({ ...prev, [name]: value }));
  };

  const getCustomerData = async () => {
    try {
      const res = await getCardData();
      if (res?.data?.length > 0) {
        let { last4 = '', exp_month:month = '', exp_year:year = '' } = res.data[0];
        setCardData({
          card_number: `XXXXXXXXXXXX${last4}`,
          month,
          year,
          cvv: '',
          is_default: true,          
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getCustomerData();
  }, []);


  return (
    <>
      <WhiteBox title="Credit card details">
        <form className=" mt-[24px]">
          <div className="grid md:grid-cols-auto-fit-450 grid-cols-auto-fit-200 gap-4">
            <div className="flex flex-col">
              <label
                htmlFor="card_number"
                className="text-pirmaryBlue text-base font-normal"
              >
                Card number
              </label>
              <input
                type="text"
                name="card_number"
                value={cardData.card_number || ""}
                onChange={handleChangeData}
                id="card_number"
                placeholder="Card Number"
                className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
              />
            </div>

            <div className="">
              <SelectField
                selectItems={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']}
                label="Expiry Month"
                labelId="month"
                id="month"
                value={cardData.month}
                onChange={handleChangeData}
                name="month"
                placeholder="Select"
                inputStyle={{ padding: "14px" }}
              />
            </div>

            <div className="">
              <SelectField
                selectItems={[
                  '2023',
                  '2024',
                  '2025',
                  '2026',
                  '2027',
                  '2028',
                  '2029',
                  '2030',
                  '2031',
                  '2032',
                  '2033',
                  '2034',
                ]}
                label="Expiry year"
                labelId="year"
                id="year"
                value={cardData.year}
                onChange={handleChangeData}
                name="year"
                placeholder="Select"
                inputStyle={{ padding: "14px" }}
              />
            </div>

            <div className="relative">
              <div className="flex flex-col">
                <label
                  htmlFor="cvv"
                  className="text-pirmaryBlue text-base font-normal"
                >
                  CVV
                </label>
                <input
                  type="text"
                  name="cvv"
                  min={3}
                  max={3}
                  value={cardData.cvv || ""}
                  onChange={handleChangeData}
                  id="cvv"
                  placeholder="cvv"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
                />
                <span className="ml-1 absolute top-[50%] right-4">
            <CustomTooltip
              TooltipTitle={"CVV"}
              TooltipText={
                "3 or 4 digit usually found on the signature strip"
              }
            />
          </span>

              </div>
            </div>
          </div>
          <FormGroup sx={{ marginTop: "20px" }}>
            <FormControlLabel
              checked={cardData.is_default}
              onChange={(e) => setCardData(prevData => ({...prevData, is_default: e.target.checked}))}
              control={<Checkbox />}
              name="is_default "
              label="Save card for quick payment"
              // inputProps={{ "aria-label": "controlled" }}
              className={classes.checkbox}
            />
          </FormGroup>
        </form>
      </WhiteBox>
      <div className="flex items-center justify-end">
        <SuccessBtn onClick={() => setCardData(initCardData)}>
          Add a new card
        </SuccessBtn>
        <PurpleBtn onClick={handleSubmit} customStyle={{ marginLeft: '24px' }}>
          Proceed
        </PurpleBtn>
        {/* <PaypalBtn customStyle={{ marginLeft: "24px" }}>
          Pay with <span>PayPal</span>
        </PaypalBtn> */}
      </div>
    </>
  );
}

export default CreditCardDetail;
