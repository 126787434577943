import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { allLanguages } from "../../../../contants/languages";
import {
  addLanguageSkillJC,
} from "../../../../../api/jc";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { MenuItem, Select, Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
      },
    },
    low: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#828282 !important",
      },
    },
    mid: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#8B5086 !important",
      },
    },
    high: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#7AB794 !important",
      },
    },
  };
});
export default function Languages({ data, handleReload, getData }) {
  const [progress, setProgress] = useState(50);
  const [languages, setLanguages] = useState([]);
  const [deletedLanguages, setDeletedLanguages] = useState([]);
  const [newLanguages, setNewLanguages] = useState([]);
  const [languageSelectItem, setLanguageSelectItem] = useState("");
  const [languageLevelItem, setLanguageLevelItem] = useState("");

  const [initialValues, setInitialValues] = useState({
    name: "",
    level: "",
  });

  const addLanguage = () => {
    let item = languages;
    console.log(languageSelectItem);
    console.log(languageLevelItem);
    if (languageSelectItem && languageLevelItem) {
      item = {
        name: languageSelectItem?.name || "",
        level: languageLevelItem,
      };
      setLanguages([...languages, item]);
      setNewLanguages((prevSkills) => [...prevSkills, item]);
      setLanguageSelectItem("");
      setLanguageLevelItem("");
    }
  };
  const deleteLangItem = (data) => {
    let item = data.name;
    if (data.id) {
      let deleted_item = { id: data.id };
      setDeletedLanguages([...deletedLanguages, deleted_item]); 
    }

    setNewLanguages((prevLangs) =>
      prevLangs.filter((lang) => lang.name !== item)
    );
    setLanguages((prevLangs) =>
      prevLangs.filter((lang) => lang.name !== item)
    );
  };

  const handleSubmit = (values) => {
    addLanguageSkillJC({ candidateLanguageSkill: newLanguages, candidateDeletedLanguages: deletedLanguages }).then((res) => {
      console.log(res.data);
      handleReload();
      toast.success("Updated.");
    });
  };

  const validate = (values) => {
    let errors = {};
    if (!formik.values.name) {
      errors.name = <span className="text-red-400"></span>;
    }
    if (!formik.values.level) {
      errors.level = <span className="text-red-400"></span>;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    handleSubmit,
    //validate,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    setLanguages([...data?.language_skill, ...newLanguages]);
  }, [data]);

  const progressstyles = useStyles();
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-2 gap-6">
          <div className="felx flex-col">
            <label className="text-base text-pirmaryBlue">
              Select or type language
            </label>
            <Autocomplete
              id="languages"
              options={allLanguages}
              getOptionLabel={(option) => option?.name}
              getOptionDisabled={(option) =>
                languages.find(({ name }) => name === option.name)
                  ? true
                  : false
              }
              renderInput={(params) => <TextField {...params} placeholder="Select language" />}
              value={languageSelectItem || null}
              onChange={(e, val) => {
                setLanguageSelectItem(val);
                formik.setFieldValue("name", val.name);
              }}
            />
          </div>
          <div className="felx flex-col">
            <label className="text-base text-pirmaryBlue">
              Proficiency level: (1 - 5)
              <Tooltip
                placement="top"
                title={
                  <div className="rounded-md">
                    <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                      Proficiency level
                    </div>
                    <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Enter or select the languages you speak and the level of proficiency: <br/><br/> For native or native-like, you can select level 5; for professional working proficiency, choose level 3; and so on. <br/><br/> 1 - &nbsp;&nbsp;20%  &nbsp;Elementary Proficiency<br/>2 - &nbsp;&nbsp;40%  &nbsp;Limited Working Proficiency<br/>3 - &nbsp;&nbsp;60%  &nbsp;Professional Working Proficiency<br/>4 - &nbsp;&nbsp;80%  &nbsp;Full Professional Proficiency<br/>5 - 100%  &nbsp;Native / Bilingual Proficiency
                    </div>
                  </div>
                }
                arrow
              >
                <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
              </Tooltip>
            </label>
            <Select
              type="text"
              displayEmpty
              className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
              // onChange={e => setLanguageLevelItem(e.target.value)}
              value={languageLevelItem}
              onChange={(e) => {
                setLanguageLevelItem(e.target.value);
                formik.setFieldValue("level", e.target.value);
              }}
            >
              <MenuItem select value="">
                Select level
              </MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </div>
          <div className="my-2 col-span-2 flex justify-end">
            <SuccessBtn
              type="button"
              onClick={addLanguage}
              //disabled={!(languageSelectItem && languageLevelItem)}
            >
              <span className="font-bold">Add languages</span>
            </SuccessBtn>
          </div>
        </div>
        <div className="font-bold text-2xl">Selected languages</div>
        {languages?.map((val, key) => (
          <div key={key} className="grid grid-cols-8">
            <div className="grid col-span-7">
              <div className="flex flex-col mt-2">
                <div className="flex justify-between py-3">
                  <span className="text-base text-pirmaryBlue">
                    {allLanguages.find(({ name }) => name === val.name)?.name ||
                      ""}
                  </span>
                  <span className="text-base font-bold text-pirmaryBlue">
                    {parseInt(val.level) * 20}%
                  </span>
                </div>
                <div className="-mt-2">
                  <LinearProgress
                    className={
                      val.level <= 2
                        ? progressstyles.low
                        : val.level <= 3
                        ? progressstyles.mid
                        : val.level <= 5
                        ? progressstyles.high
                        : ""
                    }
                    variant="determinate"
                    value={parseInt(val.level) * 20}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-1 flex justify-end items-center">
              <DeleteRoundedIcon
                className="cursor-pointer"
                onClick={(e) =>
                  deleteLangItem({ id: val?._id, name: val.name })
                }
              />
            </div>
          </div>
        ))}
        <div className="mt-8 col-span-2 flex justify-end">
          <SuccessBtn type="submit">
            <span className="font-bold">Update languages</span>
          </SuccessBtn>
        </div>
      </form>
    </>
  );
}
