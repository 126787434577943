import Avatar from "@mui/material/Avatar";
import condidate1 from "../../../../../../../assets/condidate1.svg";
import IconTooltip from "../../../../../../sharedComponents/iconTootip/IconTooltip";
import TextTooltip from "../../../../../../sharedComponents/textTooltip/TextTooltip";

const CreateAvatar = ({ name, lastName, src, alt }) => {
  return (
    <span className="flex items-center text-base text-[#384259]">
      <Avatar src={src} alt={alt} sx={{ marginRight: "10px" }} /> {name}{" "}
      {lastName}
    </span>
  );
};

const CreateTitle = ({ name, tooltipTitle, tooltipText }) => {
  return (
    <p className=" flex items-center">
      <span className="text-sm text-[#384259]">{name}</span>
      <IconTooltip
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      ></IconTooltip>
    </p>
  );
};
const CreateRowTitle = ({ name }) => {
  return <p className=" text-sm text-[#384259] font-bold">{name}</p>;
};
const CreateView = ({ tooltipTitle, tooltipText }) => {
  return (
    <TextTooltip tooltipText={tooltipText} tooltipTitle={tooltipTitle}>
      <span className="text-sm underline text-[#6DA183]">View</span>
    </TextTooltip>
  );
};

const CreateDos = ({ tooltipTitle, tooltipText }) => {
  return (
    <TextTooltip tooltipText={tooltipText} tooltipTitle={tooltipTitle}>
      <span className="text-sm underline text-[#6DA183]">View</span>
    </TextTooltip>
  );
};
const CreateDonts = ({ tooltipTitle, tooltipText }) => {
  return (
    <TextTooltip tooltipText={tooltipText} tooltipTitle={tooltipTitle}>
      <span className="text-sm underline text-[#E82127]">Dont’s</span>
    </TextTooltip>
  );
};

export const columns = [
  { id: "title", label: "", minWidth: 220 },
  {
    id: "decisive",
    label: (
      <CreateTitle
        name="Decisive"
        tooltipTitle="Decisive"
        tooltipText={
          <>
            <p>Approach to problem-solving and obtaining results</p>
            <br />
            <p>
              High Decisive tend to solve new problems quickly and assertively.
              They take an active and direct approach to obtaining results. The
              key here is new problems, such as unprecedented or haven't
              happened before. There may also be an element of risk in taking
              the wrong approach or developing an incorrect solution, but those
              with a High Decisive score are willing to take those risks, even
              if they may be inaccurate.
            </p>
            <br />
            <p>
              Low Decisive tends to solve new problems more deliberate,
              controlled, and organized manner. But, again, the key here is new
              and unprecedented issues. The Low Decisive style will solve
              routine problems quickly because the outcomes are already known.
              But, when the results are unknown, and the problem is uncertain,
              the Lower Decisive style will approach the new situation
              rationally and deliberately by thinking things through very
              carefully before acting.
            </p>
          </>
        }
      />
    ),
    minWidth: 150,
  },
  {
    id: "interactive",
    label: (
      <CreateTitle
        name="Interactive"
        tooltipTitle="Interactive"
        tooltipText={
          <>
            <p>Approach to interacting with people and display of emotions.</p>
            <br />
            <p>
              High Interactive tends to meet new people in an outgoing,
              gregarious, and socially assertive manner. The key here is new
              people whom one hasn't met before. Many other styles are
              talkative, but more so with people, they've known for some time.
              High Interactive scores are talkative, interactive, and open even
              with people they have just met. People scoring in this range may
              also be a bit impulsive. Generally speaking, those with the Higher
              I scores are usually talkative and outgoing.
            </p>
            <br />
            <p>
              Low Interactive tends to meet new people in a controlled, quiet,
              and reserved manner. Here's where the keyword "new people" enters
              the equation. Those with Low Interaction scores are talkative with
              their friends and close associates but tend to be more reserved
              with people they've recently met. They tend to prioritize the
              control of emotions and approach new relationships with a more
              reflective approach than an emotional one.
            </p>
          </>
        }
      />
    ),
    minWidth: 150,
  },
  {
    id: "stabilizing",
    label: (
      <CreateTitle
        name="Stabilizing"
        tooltipTitle="Stabilizing"
        tooltipText={
          <>
            <p>Approach to the pace of the work environment.</p>
            <br />
            <p>
              High Stabilizing prefers a more controlled, deliberative, and
              predictable environment. They place a premium on the security of a
              work situation and disciplined behavior. They also tend to show a
              sense of loyalty to a team or organization and, as a result, may
              have greater longevity or tenure in a position than some other
              styles. In addition, they have an excellent listening style and
              are very patient coaches and teachers for others on the team.
            </p>
            <br />
            <p>
              Low Stabilizing prefers a more flexible, chemistry, unstructured
              work environment. They value freedom of expression and the ability
              to change quickly from one activity to another. As a result, they
              become bored with the same routine that brings security to the
              High Stabilizing traits. As a result, they will seek opportunities
              and outlets for their high sense of urgency and high activity
              levels, as they prefer spontaneity.
            </p>
          </>
        }
      />
    ),
    minWidth: 150,
  },
  {
    id: "cautious",
    label: (
      <CreateTitle
        name="Cautious"
        tooltipTitle="Decisive style"
        tooltipText={
          <>
            <p>Approach to standards, procedures, and expectations.</p>
            <br />
            <p>
              High Cautious tend to adhere to rules, standards, procedures, and
              protocols set by those in authority whom they respect. According
              to the operating manual, they like things to be done the right
              way. "Rules are made to be followed" is an appropriate motto for
              those with High Cautious scores. They have some of the highest
              quality control interests of any style and frequently wish others
              would do the same.
            </p>
            <br />
            <p>
              Low Cautious tends to operate more independently from the rules
              and standard operating procedures. They tend to be bottom-line
              oriented. If they find an easier way to do something, they'll do
              it by developing various strategies as situations demand. To the
              Lower C scores, rules are only guidelines and may be bent or
              broken as necessary to obtain results.
            </p>
          </>
        }
      />
    ),
    minWidth: 150,
  },
];
export const rows = [
  {
    title: <CreateRowTitle name="Strengths" />,
    decisive: "Leadership",
    interactive: "Persuading",
    stabilizing: "Listening",
    cautious: "Planning",
  },
  {
    title: "",
    decisive: "Administration",
    interactive: "Motivating",
    stabilizing: "Teamwork",
    cautious: "Systemizing",
  },
  {
    title: "",
    decisive: "Pioneering",
    interactive: "Entertainig",
    stabilizing: "Follow-through",
    cautious: "Orchestation",
  },
  {
    title: <CreateRowTitle name="At Work Behavior" />,
    decisive: "Efficient",
    interactive: "Personal",
    stabilizing: "Personal",
    cautious: "Formal",
  },
  {
    title: "",
    decisive: "Structured",
    interactive: "Interactive",
    stabilizing: "Friendly",
    cautious: "Structured",
  },
  {
    title: "",
    decisive: "Busy",
    interactive: "Busy",
    stabilizing: "Functional",
    cautious: "Functional",
  },
  {
    title: <CreateRowTitle name="Behavior Pace & Priority" />,
    decisive: "Faster Pace",
    interactive: "Faster Pace",
    stabilizing: "Slower Pace",
    cautious: "Slower Pace",
  },
  {
    title: "",
    decisive: "Task Oriented",
    interactive: "People Oriented",
    stabilizing: "People Oriented",
    cautious: "Task Oriented",
  },
  {
    title: <CreateRowTitle name="Tendency" />,
    decisive: "Problem solving, making decisions, and position of authority.",
    interactive:
      "Interact with others, share opinions, have a friendly environment, and social relationships",
    stabilizing: "Pace things in their environment, teams, and systems.",
    cautious:
      "Preference for established rules, protocols, and standards. Analyze data.",
  },
  {
    title: <CreateRowTitle name="Emotions" />,
    decisive: "Decide and Risk-taker",
    interactive: "Optimistic, and Trust Others",
    stabilizing: "Patience and Stabilizer",
    cautious: "Carefully take decisions. Cautious",
  },
  {
    title: <CreateRowTitle name="Fears" />,
    decisive: "Being taken advantage of and lack of control.",
    interactive: "Being left out, loss of social approval",
    stabilizing: "Sudden change, loss of stability and security.",
    cautious: "Being criticized, loss of accuracy and quality.",
  },
  {
    title: <CreateRowTitle name="Areas of Improvement" />,
    decisive: "Impatient",
    interactive: "Inattentive to details",
    stabilizing: "Oversensitive",
    cautious: "Perfectionist",
  },
  {
    title: "",
    decisive: "Insensitive to others",
    interactive: "Short attention span",
    stabilizing: "Slow to begin action",
    cautious: "Critical",
  },
  {
    title: "",
    decisive: "Poor listener",
    interactive: "Low follow-through",
    stabilizing: "Lacks global perspective",
    cautious: "Unresponsive",
  },
  {
    title: <CreateRowTitle name="Measure worth" />,
    decisive: "Impact or results",
    interactive: "Acknowledgments",
    stabilizing: "Compatibility with other",
    cautious: "Precision",
  },
  {
    title: "",
    decisive: "Track records and products",
    interactive: "Applause",
    stabilizing: "Depth of contribution",
    cautious: "Accuracy",
  },
  {
    title: "",
    decisive: "",
    interactive: "Compliments",
    stabilizing: "",
    cautious: "Quality of results",
  },
  {
    title: <CreateRowTitle name="Obtain Security" />,
    decisive: "Control",
    interactive: "Playfulness",
    stabilizing: "Submissive",
    cautious: "Withdrawn",
  },
  {
    title: "",
    decisive: "Leadership",
    interactive: "Other's approval",
    stabilizing: "Indecisive",
    cautious: "Headstrong",
  },
  {
    title: <CreateRowTitle name="Annoyances" />,
    decisive: "Inefficiency",
    interactive: "Routines",
    stabilizing: "Insensitivity",
    cautious: "Disorganization",
  },
  {
    title: "",
    decisive: "Indecision",
    interactive: "Complexity",
    stabilizing: "Impatience",
    cautious: "Impropriety",
  },
  {
    title: <CreateRowTitle name="Adapting in Learning Style" />,
    decisive: (
      <CreateView
        tooltipText={
          <ul className="tooltip-list">
            <li>
              Likes to learn quickly; may be frustrated with a slower pace
            </li>
            <li>
              Has own internal motivation-clock, learns for their own reasons,
              not for anyone else's reasons
            </li>
            <li>May like to structure their own learning design</li>
            <li>Does okay with independent self-study</li>
            <li>Defines own goals</li>
            <li>May have a short attention span</li>
          </ul>
        }
        tooltipTitle="Decisive Style"
      />
    ),
    interactive: (
      <CreateView
        tooltipText={
          <ul className="tooltip-list">
            <li>Likes learning in groups</li>
            <li>Interacts frequently with others</li>
            <li>Responds to extrinsic motivation, praise, and encouragement</li>
            <li>
              Needs structure from the facilitator; may lose track of time
            </li>
            <li>Needs “what to do” and “when to do it”</li>
            <li>
              May exceed deadlines if left on their own and learning may be
              completed late
            </li>
          </ul>
        }
        tooltipTitle="Interactive style"
      />
    ),
    stabilizing: (
      <CreateView
        tooltipText={
          <ul className="tooltip-list">
            <li>Accepts a balance between individual and group work</li>
            <li>Shows patience with detailed or technical processes</li>
            <li>Likes journaling and follow-through</li>
            <li>Prefers explicit Instructions</li>
            <li>Wants to know the performance outcomes and expectations</li>
            <li>
              May need help in prioritizing tasks if a long assignment, may take
              criticism personally
            </li>
          </ul>
        }
        tooltipTitle="Stabilizing style"
      />
    ),
    cautious: (
      <CreateView
        tooltipText={
          <ul className="tooltip-list">
            <li>Prefers individual work over group interaction</li>
            <li>
              Accepts more impersonal training, such as remote or on-line{" "}
            </li>
            <li>Has high expectations of their own performance</li>
            <li>
              Will structure their own activities only with explicit goals and
              outcomes established
            </li>
            <li>
              Emphasizes details, deep thinking, and theoretical bases for the
              learning
            </li>
            <li>
              May get overly bogged down in details, especially if the learning
              climate is pressured
            </li>
          </ul>
        }
        tooltipTitle="Cautious style"
      />
    ),
  },
  {
    title: <CreateRowTitle name="Adapting at Work" />,
    decisive: (
      <CreateView
        tooltipText={
          <>
            <span>HELP THEM TO:</span>
            <ul className="tooltip-list">
              <li>More realistically gauge risks </li>
              <li>
                Exercise more caution and deliberation before making decisions
              </li>
              <li>Follow pertinent rules, regulations, and expectations </li>
              <li>Recognize and solicit others' contributions</li>
              <li>Tell others the reasons for decisions</li>
              <li>Cultivate more attention/responsiveness to emotions</li>
            </ul>
          </>
        }
        tooltipTitle="Decisive style"
      />
    ),
    interactive: (
      <CreateView
        tooltipText={
          <>
            <span>HELP THEM TO:</span>
            <ul className="tooltip-list">
              <li>Prioritize and organize</li>
              <li>See tasks through to completion </li>
              <li>View people and tasks more objectively</li>
              <li>Avoid overuse of giving and taking advice</li>
              <li>Write things down</li>
            </ul>
          </>
        }
        tooltipTitle="Interactive style"
      />
    ),
    stabilizing: (
      <CreateView
        tooltipText={
          <>
            <span>HELP THEM TO:</span>
            <ul className="tooltip-list">
              <li>Utilize shortcuts and discard unnecessary steps </li>
              <li>Track their growth</li>
              <li>Avoid doing things the same way</li>
              <li>Realize there is more than one approach to tasks </li>
              <li>Become more open to some risks and changes </li>
              <li>Feel sincerely appreciated </li>
              <li>Speak up and voice their thoughts and feelings</li>
            </ul>
          </>
        }
        tooltipTitle="Stabilizing style"
      />
    ),
    cautious: (
      <CreateView
        tooltipText={
          <>
            <span>HELP THEM TO:</span>
            <ul className="tooltip-list">
              <li>Share their knowledge and expertise with others</li>
              <li>
                Stand up for themselves with the people they prefer to avoid
              </li>
              <li>Shoot for realistic deadlines and parameters </li>
              <li>View people and tasks less seriously and critically </li>
              <li>Balance their lives with both interaction and tasks </li>
              <li>Keep on course with tasks, less checking</li>
              <li>
                Maintain high expectations for high priority items, not
                everything
              </li>
            </ul>
          </>
        }
        tooltipTitle="Cautious style"
      />
    ),
  },
  {
    title: <CreateRowTitle name="Communication Style" />,
    decisive: (
      <CreateDos
        tooltipText={
          <>
            <span>DOs</span>
            <ul className="tooltip-list">
              <li>Show them how to win, new opportunities</li>
              <li>Display reasoning</li>
              <li>Provide concise data </li>
              <li>
                Agree on goal and boundaries, the support or get out of their
                way
              </li>
              <li>Allow them to "do their thing", within limits </li>
              <li>Vary routine </li>
              <li>Look for opportunities to modify their workload focus </li>
              <li>Compliment them on what they've done </li>
              <li>
                Let them take the lead, when appropriate, but give them
                parameters
              </li>
              <li>
                If necessary, argue with conviction on points of disagreement,
                backed up with facts; don't argue on a "personality" basis
              </li>
            </ul>
          </>
        }
        tooltipTitle="The DOs to Effective Communication"
      />
    ),
    interactive: (
      <CreateDos
        tooltipText={
          <>
            <span>DOs</span>
            <ul className="tooltip-list">
              <li>Show them that you admire and like them </li>
              <li>Behave optimistically and provide upbeat setting </li>
              <li>Support their feelings when possible</li>
              <li>Avoid involved details, focus on the "big picture" </li>
              <li>Interact and participate with them </li>
              <li>
                Vary the routine; avoid requiring long-term repetition by them{" "}
              </li>
              <li>Compliment them personally and often </li>
              <li>Do it together </li>
              <li>Keep up a fast, lively, pace </li>
              <li>
                Support their ideas and don't poke holes in their dreams; show
                them your positive side
              </li>
              <li>
                Mention their accomplishments, progress and your other genuine
                appreciation
              </li>
            </ul>
          </>
        }
        tooltipTitle="The DOs to Effective Communication"
      />
    ),
    stabilizing: (
      <CreateDos
        tooltipText={
          <>
            <span>DOs</span>
            <ul className="tooltip-list">
              <li>Show how your idea minimizes risk </li>
              <li>Show reasoning </li>
              <li>Provide data and proof </li>
              <li>Demonstrate your interest in them </li>
              <li>
                Provide outline and/or one-two-three instructions as you
                personally "walk them through"{" "}
              </li>
              <li>Compliment them for their steady follow-through </li>
              <li>Give them personal assurances </li>
              <li>
                Act non-aggressively, focus on common interest or needed support{" "}
              </li>
              <li>Allow them to provide service or support for others </li>
              <li>Provide a relaxing, friendly atmosphere</li>
              <li>Provide them with a cooperative group </li>
              <li>
                Acknowledge their easygoing manner and helpful efforts, when
                appropriate
              </li>
            </ul>
          </>
        }
        tooltipTitle="The DOs to Effective Communication"
      />
    ),
    cautious: (
      <CreateDos
        tooltipText={
          <>
            <span>DOs</span>
            <ul className="tooltip-list">
              <li>Approach them in an indirect, nonthreatening way</li>
              <li>Show your reasoning</li>
              <li>Give data to them in writing</li>
              <li>Provide explanations and rationale</li>
              <li>
                Allow them to think, inquire and check before they make
                decisions
              </li>
              <li>
                When delegating, let them check procedures, and other progress
                and performance before they make decisions
              </li>
              <li>
                Compliment them on their thoroughness and correctness when
                appropriate
              </li>
              <li>
                Let them assess and be involved in the process when possible
              </li>
              <li>
                Tactfully ask for clarification and assistance you may need
              </li>
              <li>
                Allow them time to find the best or "correct" answer, within
                available limits
              </li>
              <li>Tell them "why" and "how"</li>
            </ul>
          </>
        }
        tooltipTitle="The DOs to Effective Communication"
      />
    ),
  },
  // {
  //   title: <CreateRowTitle name="Team Members" />,
  //   decisive: '',
  //   interactive: '',
  //   stabilizing: '',
  //   cautious: '',
  // },
];
