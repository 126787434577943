import React, { useRef } from "react";
import Modal from "../../../sharedComponents/modal/Modal";
import { Formik, Field, Form } from "formik";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import DangerLightBtn from "../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import cx from "classnames";
import moment from "moment";

export default function TrainingModalBody({
  values,
  isOpen,
  onSubmit,
  onClose,
  isNew
}) {
  const formik = useRef();
  const {
    institution_ame,
    name_of_certificate,
    field_of_study,
    start_date,
    end_date,
    description,
  } = isNew ? {} : values || {};

  const validate = (values) => {
    const errors = {};
    
    if (!values.institution_ame) errors.institution_ame = true;
    if (!values.field_of_study) errors.field_of_study = true;
    if (!values.start_date) errors.start_date = true;
    if (values.start_date && values.end_date) {
      if (
        moment(values.start_date).isAfter(values.end_date) ||
        moment(values.start_date).isSame(values.end_date)
      )
        errors.date = true;
    }
  
    return errors;
  };

  return (
    <div>
      <Modal title={`${isNew ? 'Add' : 'Edit'} Training`} open={isOpen} setOpen={onClose} lgUpWidth={600}>
        <Formik
          initialValues={{
            institution_ame,
            name_of_certificate,
            field_of_study,
            start_date,
            end_date,
            description,
          }}
          validate={validate}
          validateOnBlur={false}
          validateOnChange={false}
          innerRef={formik}
          onSubmit={async (values) => {
            onSubmit(values);
          }}
        >
          {({ errors }) => (
            <Form>
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-7 gap-y-3">
                <div className="flex flex-col">
                  <label htmlFor="start_date" className="text-base text-pirmaryBlue">Start date *</label>
                  <Field
                    name="start_date"
                    type="date"
                    className={cx(
                      "focus:outline-none border-2 p-[12px] border-solid rounded",
                      { "border-red-500": errors.start_date || errors.date },
                      "border-grayLight"
                    )}
                  />
                  {errors.start_date && (
                    <span className="text-red-500">This is required field</span>
                  )}
                  {errors.date && (
                    <span className="text-red-500">start date should be less than end date</span>
                  )}
                </div>
                <div className="flex flex-col">
                  <label htmlFor="end_date" className="text-base text-pirmaryBlue">
                    End date
                  </label>
                  <Field
                    name="end_date"
                    type="date"
                    className="focus:outline-none border-2 p-[12px] border-solid rounded border-grayLight"
                  />
                </div>
                <div className="flex flex-col col-span-2">
                  <label htmlFor="name_of_certificate" className="text-base text-pirmaryBlue">
                  Name of certificate
                  </label>
                  <Field
                    name="name_of_certificate"
                    type="text"
                    className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
                  />
                </div>
                <div className="flex flex-col col-span-2">
                  <label htmlFor="institution_ame" className="text-base text-pirmaryBlue">
                    Institution name *
                  </label>
                  <Field
                    name="institution_ame"
                    type="text"
                    className={cx(
                      "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                      { "border-red-500": errors.institution_ame },
                      "border-grayLight"
                    )}
                  />
                  {errors.institution_ame && (
                    <span className="text-red-500">This is required field</span>
                  )}
                </div>
                <div className="flex flex-col col-span-2">
                  <label htmlFor="field_of_study" className="text-base text-pirmaryBlue">
                    Field of study *
                  </label>
                  <Field
                    name="field_of_study"
                    type="text"
                    className={cx(
                      "w-full rounded-md border text-sm text-pirmaryBlue h-11 px-2 focus:outline-none",
                      { "border-red-500": errors.field_of_study },
                      "border-grayLight"
                    )}
                  />
                  {errors.field_of_study && (
                    <span className="text-red-500">This is required field</span>
                  )}
                </div>
              </div>
              <div className="flex flex-col mt-3">
                <label htmlFor="description" className="text-base text-pirmaryBlue">
                  Description
                </label>
                <Field name="description">
                  {({ field, props }) => (
                    <textarea
                      className="focus:outline-none border-2 p-[12px] border-solid rounded border-grayLight"
                      rows={4}
                      {...props}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <div className="flex justify-between mt-3">
                <div>
                  {!isNew && (
                    <DangerLightBtn onClick={() => onSubmit()} type='button'>
                      <span className='font-bold'>Delete Training</span>
                    </DangerLightBtn>
                  )}
                </div>
                <SuccessBtn
                  type="submit"
                >
                  { isNew ? "Add Training" : "Update Training"}
                </SuccessBtn>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
