import React from 'react';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import { BudgetStepperArrow } from './reusableComponent';
import InfoBox from '../../sharedComponents/profile/infoBox';
import SelectField from '../../sharedComponents/selectField/SelectField';
import moment from 'moment';
import { getPersonalBudget } from '../../../../api/jc';

const componentList = [Step1, Step2, Step3, Step4];

function PersonalBudget() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [data, setData] = React.useState();
  const [selected, setSelected] = React.useState({
    month: moment().format('MMM'),
    year: moment().year(),
  });

  const handleChange = (e) => {
    let { name, value } = e.target;
    setSelected((prevData) => ({ ...prevData, [name]: value }));
  };

  const getBudgetData = async () => {
    try {
      const res = await getPersonalBudget(selected);
      setData(res.data.length ? res.data[0] : []);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getBudgetData();
  }, [selected]);

  return (
    <>
      <InfoBox
        hasIcon={true}
        containerStyle={activeStep === componentList.length - 1 ? 'hidden' : ''}
      >
        <BudgetStepperArrow step={activeStep} />
      </InfoBox>

      <InfoBox
        hasIcon={true}
        containerStyle={activeStep === componentList.length - 1 ? 'hidden' : ''}
      >
        <div className='flex flex-col md:flex-row justify-center md:justify-between items-center text-2xl font-bold -mt-6'>
          <div className='flex-1 w-full text-center md:text-left'>
            Select month and year
          </div>
          <div className='flex flex-col sm:flex-row mt-5 md:mt-0 w-full justify-center items-center flex-1'>
            <div className='flex-1 w-full px-2'>
              <SelectField
                onChange={handleChange}
                selectItems={[
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ]}
                label='Select month'
                labelId='month'
                id='month'
                // value={profileData.month}
                name='month'
                inputStyle={{ padding: '14px' }}
                defaultValue={moment().format('MMM')}
              />
            </div>
            <div className='flex-1 w-full px-2 mt-2 sm:mt-0'>
              <SelectField
                onChange={handleChange}
                selectItems={['2022', '2023', '2024', '2025']}
                label='Select year'
                labelId='year'
                id='year'
                // value={profileData.year}
                name='year'
                inputStyle={{ padding: '14px' }}
                defaultValue={moment().year()}
              />
            </div>
          </div>
        </div>
      </InfoBox>

      {componentList.map((Component, index) => {
        return (
          activeStep === index && (
            <Component
              setActiveStep={setActiveStep}
              month={selected?.month || ''}
              year={selected?.year || ''}
              data={data}
              reload={getBudgetData}
            />
          )
        );
      })}
    </>
  );
}

export default PersonalBudget;
