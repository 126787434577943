import React from "react";
import SuccessBtn from "../../../../sharedComponents/buttons/successBtn/SuccessBtn";
import DeleteBtn from '../../../../sharedComponents/buttons/dnagerBtn/DnagerBtn';
import { stripeCheckoutSession, cancelSubscription } from "../../../../../../api/jc";
import { toast } from "react-toastify";
import DangerLightBtn from "../../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import config from "../../../../../../config/config";

function ChangePlan({ setIsPlanPage, setIsSubscribed, isSubscribed }) {
  const [activePlan, setActivePlan] = React.useState("annual");
  const [data, setData] = React.useState({
    price_id: config.stripePremiumPrice,
    url: window.location.origin + '/coaching/profile/settings'
  });

  const handleActive = (plan) => {
    setActivePlan(plan)
    let url = window.location.origin + '/coaching/profile/settings';
    switch (plan) {
      case 'annual':
        setData({
          price_id: config.stripePremiumPrice,
          url
        })
        break;   
      default:
        break;
    }
  };

  const handleCheckout = async () => {
    try {
      const res = await stripeCheckoutSession(data)
      if (res?.data?.url) window.location.href = res.data.url;
    } catch (error) {
      console.error(error);
    }
  }


  const handleFeemimuPlan = async () =>{
    try{
      const res = await cancelSubscription()
      if (res?.data) {
        if(res.data?.subscription_cancel){
          setIsPlanPage(false)
          setIsSubscribed(false)
        }
      }else{
        toast.error("Something is wrong! Please try again!");
      }
    } catch (error) {
      console.error(error);
    }
  }

  // const activeStyle = "bg-[#D1E6D9] border-2 border-solid border-[#6DA183] ";
  return (
    <>
      {!isSubscribed ? (
        <>
          <div
            className={`flex items-center max-w-[630px] rounded py-[15px] px-[25px]`}
          >
            <div className="flex justify-between items-center w-full">
              <div className="inline-block">
                <h2 className="inline-block text-[30px] font-bold text-[#954a89]">Flash Sale! </h2>
                {/* <div className="inline-block ml-[20px]">
                  <span className="text-[35px]">48 hours <div className="border-[5px] border-solid border-[#7AB794] rotate-[-2.06deg]"></div></span>
                </div> */}
              </div>
            </div>
          </div>
          <hr />
          <div
            className={`flex items-center max-w-[630px] rounded py-[15px] px-[25px] cursor-pointer`}
            onClick={() => handleActive('annual')}
          >
            <div className={`p-[7px] mr-5 rounded-full ${activePlan === "annual" ? 'bg-green-500' : 'bg-gray-400'}`}>
              <div className="p-2 bg-white rounded-full" />
            </div>
            <div className="flex justify-between items-center w-full">
              <div>
                <h2 className="text-[20px] text-[#384259] font-bold">
                  Annual
                  {/* <span className="text-[13px] text-success"> SAVE 30% <br/> <span className="text-pirmaryBlue">Promotion code: <span className="text-success text-[15px]">TRIPA30</span></span></span> */}
                  <br/>
                  <span className="text-pirmaryBlue text-[13px] text-success">Save $259.91 Limited-time offer!</span>
                </h2>
              </div>
              <div className="text-right">
                <p className="text-[18px] font-bold text-[#7AB794] my-[5px]">
                  <span className="line-through text-[15px] font-bold text-[#828282] pr-[8px]">$359.90</span>$99.99
                </p>
                <span className="text-[14px] font-normal text-[#828282]">
                  per year
                </span>
              </div>
            </div>
          </div>
          <hr />
          {/* <div
            className={`flex items-center ${
              activePlan === "quarterly" && activeStyle
            } max-w-[630px] rounded py-[15px] px-[25px] cursor-pointer`}
            onClick={() => handleActive('quarterly')}
          >
            <div className={`p-[7px] mr-5 rounded-full ${activePlan === "quarterly" ? 'bg-green-500' : 'bg-gray-400'}`}>
              <div className="p-2 bg-white rounded-full" />
            </div>
            <div>
              <h2 className="text-[24px] text-[#384259] font-bold  ">
                Quarterly Plan
              </h2>
              <p className="text-[18px] font-bold text-[#384259] my-[10px]">
                $20.00/month
              </p>
              <span className="text-[14px] font-normal text-[#384259]">
                Billed as one payment of $60.00
              </span>
            </div>
          </div>
          <hr /> */}
          <div
            className={`flex items-center max-w-[630px] rounded py-[15px] px-[25px]`}
          >
            <div className={`opacity-0 p-[7px] mr-5 rounded-full ${activePlan === "monthly" ? 'bg-green-500' : 'bg-gray-400'}`}>
              <div className="p-2 bg-white rounded-full" />
            </div>
            <div className="flex justify-between items-center w-full">
              <div>
                <h2 className="text-[20px] text-[#384259] font-bold">Monthly</h2>
              </div>
              <div className="text-right">
                <p className="text-[18px] font-bold text-[#384259] my-[5px]">
                  <span className="text-[14px] font-normal text-[#828282] pr-[8px]"> It's just</span>$8.33
                </p>
                <span className="text-[14px] font-normal text-[#828282]">
                  per month
                </span>
              </div>
            </div>
          </div>
          <hr />

          <div className="my-6 text-secondaryBalck text-[16px]">
            Charity; 2% of your subscription goes to charity that supports education in developing countries.
          </div>
          {/* <div className="my-6 text-secondaryBalck text-[16px]">
            When you change your Freemium subscription to the Annual plan, you will have access to the Premium data.   Are you sure you want to change your current plan?
          </div> */}

          <div className="flex items-center max-w-[630px]">
            <SuccessBtn className='w-full py-4 text=[16px] font-bold' onClick={handleCheckout}>Buy TRIPA Premium</SuccessBtn>
          </div>

          <div className="mt-10">
            <h4 className="text-[14px] text-secondaryBalck mb-3 text-center">Billed Yearly - Cancel Anytime</h4>
            <p className="text-[13px] text-center text-secondaryBalck">
              Please note your subscription will automatically renew every 12 months, with a charge of $99.99 applied to your account on each renewal. If you wish to change this, you can cancel your subscription anytime in your account settings. We value your continued support.
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="my-6 text-secondaryBalck text-[16px]">
            <p>When you change your Premium subscription to the Freemium, you will lose access to the Premium data.</p>
            <p className="pt-4">Are you sure you want to continue?</p>             
          </div>

          <div className="mt-2 col-span-2 flex justify-between">
            <div>
              <DeleteBtn className='w-full py-4 text=[16px] font-bold' onClick={handleFeemimuPlan}>Continue with Freemium Plan</DeleteBtn>
            </div>
            <SuccessBtn type="submit">
              <span className="font-bold" onClick={() => setIsPlanPage(false)}>Never Mind</span>
            </SuccessBtn>
          </div>
        </>
      )}
    </>
  );
}

export default ChangePlan;
