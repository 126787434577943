import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(
  ({ theme, mdWidth, lgWidth }) => ({
    "& .MuiPaper-root": {
      borderRadius: "15px!important",
      maxWidth: "550px",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
      // minWidth: "550px",
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiPaper-root": {
        minWidth: mdWidth && `${mdWidth}px !important`,
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiPaper-root": {
        minWidth: lgWidth && `${lgWidth}px !important`,
      },
    },
    [theme.breakpoints.up("xl")]: {
      "& .MuiPaper-root": {
        minWidth: "550px",
      },
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiPaper-root": {
        minWidth: "100%",
      },
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  })
);

const BootstrapDialogTitle = (props) => {
  const { children, onClose, bgColorTitle, textColorTitle, ...other } = props;

  const bgColor = bgColorTitle ? bgColorTitle : "#7AB794 ";

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: bgColor,
        color: "#ffffff",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: textColorTitle ?? "white" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Modal({
  title,
  children,
  open,
  setOpen,
  style,
  bgColorTitle,
  bgColorContent = "#ffffff",
  textColorTitle = "#ffffff",
  mdUpWidth = 900,
  lgUpWidth,
  xlUpWidth,
  onClose,
}) {
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={style}>
      <BootstrapDialog
        onClose={() => {
          handleClose();
          onClose();
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        mdWidth={mdUpWidth}
        lgWidth={lgUpWidth}
        xlWidth={xlUpWidth}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          bgColorTitle={bgColorTitle}
          textColorTitle={textColorTitle}
        >
          <span className="font-bold text-lg" style={{ color: textColorTitle }}>
            {title}
          </span>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ padding: "20px 30px 40px 30px !important", bgcolor:bgColorContent }}
        >
          {children}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
