import React, { useState } from "react";
import Modal from "../modal/Modal";
import SuccessBtn from "../buttons/successBtn/SuccessBtn";
import DangerLightBtn from "../buttons/dangerLightBtn/DangerLightBtn";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

function SignOutModal({ open, setOpen }) {
  const [isBusiness, setIsBusiness] = useState(false); 

  const  currentPathIsBusiness= () => {
    var loc = window.location.pathname;
    var dir = loc.substring(0, loc.lastIndexOf('/'));
    if (dir === '/business'){
      setIsBusiness(true)
     return true;
    }
    else { return false;}
  }

  const  signOutHandeler= () => {
    localStorage.removeItem('token-session');
    localStorage.removeItem('access');
    localStorage.removeItem('role');
    localStorage.removeItem('keepMe');
    Cookies.remove("token-session");
    }

  React.useEffect(() => {
    currentPathIsBusiness()
  }, []);

  return (
    <Modal
      mdUpWidth={340}
      style={{ maxWidth: "340px !important", minWidth: "0px" }}
      title={
        <p className="">
          Sign out
        </p>
      }
      open={open}
      setOpen={setOpen}
    >
      <p className="pb-6 text-base text-[#384259]">Do you want to Logout</p>
      <div className="flex items-center justify-between">
        <DangerLightBtn onClick={() => setOpen(false)}>No</DangerLightBtn>
        <Link to={isBusiness ? "/business/login" : "/coaching/login" } onClick={signOutHandeler}>
          <SuccessBtn>Yes</SuccessBtn>
        </Link>
      </div>
    </Modal>
  );
}

export default SignOutModal;
