import React from "react";
import { ReactComponent as NotFoundIMG } from "../../../assets/notFound.svg";
import { Link } from "react-router-dom";
import SuccessBtn from "../buttons/successBtn/SuccessBtn";

function UnAuthorize() {
  return (
    <div className="md:min-h-[80vh] min-h-[50vh] flex items-center justify-center">
      <div className="md:w-[600px] w-full  ">
        <h2 className="md:text-[36px] text-[24px] text-[#384259] font-bold text-center">
        You don't have permission to access
        </h2>
        <div className="">
          <NotFoundIMG className="w-full h-full " />
        </div>

        <Link to="/" className="flex justify-center">
          <SuccessBtn>RETURN TO MAIN PAGE</SuccessBtn>
        </Link>
      </div>
    </div>
  );
}

export default UnAuthorize;
