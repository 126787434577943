import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Sap } from "../../../../assets/sap.svg";
import WhiteBox from "../shared/WhiteBox";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoIcon from "@mui/icons-material/Info";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tooltip from "@mui/material/Tooltip";
import { addCertificationJC, addEducationJC, addTrainingJC, postExperience, postJCProfile } from "../../../../../api/jc";
import { Formik, Field, Form, FieldArray } from "formik";
import cx from "classnames";
import AboutMeModalBody from "../ModalBody/aboutme";
import ExperienceModalBody from "../ModalBody/experience";
import EducationModalBody from "../ModalBody/educcation";
import CertificateModalBody from "../ModalBody/certificate";
import TrainingModalBody from "../ModalBody/training";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfoBoxes from "../../../sharedComponents/profile/infoBox";
import { getByUserId } from "../../../../../api/jc";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import LinearProgress from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import Awards from "../../profile/modalCompnents/awards";
import FunctionArea from "../../profile/modalCompnents/functionArea";
import UpdateSalary from "../../profile/modalCompnents/updateSalary";
import AddSkills from "../../profile/modalCompnents/addSkills";
import Languages from "../../profile/modalCompnents/languages";
import Modal from "../../../sharedComponents/modal/Modal";
import EducationEditModal from "../../profile/modalCompnents/educationModal";
import { allLanguages } from "../../../../contants/languages";
import { Avatar } from "@mui/material";
import { NumericFormat } from 'react-number-format';
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import moment from "moment";
import { updateUser } from "../../../../../redux/actions/user";
import prefferedImageBlured from '../../../../assets/preffered-blured.png';


const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
      },
    },
    low: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#828282 !important",
      },
    },
    mid: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#8B5086 !important",
      },
    },
    high: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#7AB794 !important",
      },
    },
  };
});
function stringAvatar(name) {
  return {
    sx: {
      bgcolor: "#C6E7D4",
      color: "#166534",
      fontWeight: "700",
      // width: "56px",
      // height: "56px",
    },
    children: getLetterFromTheName(name),
  };
}

const getLetterFromTheName = (fullName) => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if(index === 0 || index === allNames.length - 1){
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}
export const charMapping = {
  I: {
    name: "Interactive",
    color: "#e5c311",
  },
  D: {
    name: "Decisive",
    color: "#ff0000",
  },
  S: {
    name: "Steady",
    color: "#008000",
  },
  C: {
    name: "Cautious",
    color: "#0000ff",
  },
};
function CheckResume() {
  const dispatch = useDispatch();
  const [isOpenAboutMeModal, setIsOpenAboutMeModal] = useState(false);
  const [isOpenExperienceModal, setIsOpenExperienceModal] = useState(false);
  const [isOpenEducationModal, setIsOpenEducationModal] = useState(false);
  const [isOpenCertificateModal, setIsOpenCertificateModal] = useState(false);
  const [isOpenTrainingModal, setIsOpenTrainingModal] = useState(false);
  const [isNewExperience, setNewExperience] = useState(false);
  const [isNewEducation, setNewEducation] = useState(false);
  const [isNewTraining, setNewTraining] = useState(false);
  const [isNewCertificate, setNewCertificate] = useState(false);
  const [activeIndex, setIndex] = useState(0);
  const [result, setResult] = useState([]);
  const [allData, setAllData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const progressstyles = useStyles();

  const resumeData = useSelector((state) => state.steps.resume);

  const formik = useRef();
  const navigate = useNavigate();

  const [addFunction, setAddFunction] = useState(false);
  const [updateSalary, setUpdateSalary] = useState(false);
  const [addSkilss, setAddSkilss] = useState(false);
  const [languages, setLanguages] = useState(false);
  const [awards, setAwards] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedAward, setSelectedAward] = useState(-1);

  const max = Math.max(...result?.map((x) => Number(x?.value || 0)));
  const maxValue = result?.filter((x) => Number(x.value) === max);
  const extraValues = allData?.driver_of_engagement?.split(",");

  const mapDriverOfEngagmentColors = {
    Aesthetic: "#A9D18E",
    Economic: "#4472C4",
    Individualistic: "#ED7D33",
    Political: "#C2342C",
    Altruistic: "#E5C311",
    Regulatory: "#7F7F7F",
    Theoretical: "#7F6000",
  };

  const getData = () => {
    getByUserId().then((res) => {
      dispatch(updateUser(res?.data));
      setAllData(res?.data);
      setResult(
        res?.data?.assessments.filter(
          (i) => i.assessment.assessment_type === "BehaviorAssessment"
        )?.[0]?.assessment?.result
      );
    });
  };

  const aboutSubmit = (values) => {
    formik.current.setValues({ ...formik.current.values, ...values });
    setIsOpenAboutMeModal(false);
  };

  const experienceSubmit = (values) => {
    if (!values) {
      formik?.current?.setValues({
        ...formik?.current?.values,
        workExperience: formik?.current?.values?.workExperience?.filter((exp, i) => i !== activeIndex ),
      }).then(() => {
        console.log('delete exp!');
        checkValidation()
      });
      setIsOpenExperienceModal(false);
      return
    }
    if (isNewExperience) {
      formik?.current?.setValues({
        ...formik?.current?.values,
        workExperience: [{ ...values, isValid: true}, ...(formik?.current?.values?.workExperience || [])],
      });
    } else {
      formik?.current?.setValues({
        ...formik?.current?.values,
        workExperience: formik?.current?.values?.workExperience?.map(
          (experience, index) => {
            if (index === activeIndex) {
              return { ...experience, ...values, isValid: true };
            } else {
              return experience;
            }
          }
        ),
      }).then(() => {
        console.log('update exp!');
        checkValidation()
      });
    }
    setIsOpenExperienceModal(false);
  };

  const educationSubmit = (values) => {
    if (isNewEducation) {
      formik?.current?.setValues({
        ...formik?.current?.values,
        education: [values, ...formik?.current?.values?.education],
      });
    } else {
      formik?.current?.setValues({
        ...formik?.current?.values,
        education: formik?.current?.values?.education?.map(
          (education, index) => {
            if (index === activeIndex) {
              return { ...education, ...values };
            } else {
              return education;
            }
          }
        ),
      });
    }
    setIsOpenEducationModal(false);
  };

  const certificateSubmit = (values) => {
    if (!values) {
      formik?.current?.setValues({
        ...formik?.current?.values,
        certificate: formik?.current?.values?.certificate?.filter((exp, i) => i !== activeIndex ),
      }).then(() => {
        console.log('delete ceritification');
        checkValidation()
      });
      setIsOpenCertificateModal(false);
      return
    }
    if (isNewCertificate) {
      formik?.current?.setValues({
        ...formik?.current?.values,
        certificate: [{ ...values, isValid: true }, ...(formik?.current?.values?.certificate || [])],
      });
    } else {
      formik?.current?.setValues({
        ...formik?.current?.values,
        certificate: formik?.current?.values?.certificate?.map(
          (certificate, index) => {
            console.log('updating certificates');
            if (index === activeIndex) {
              return { ...certificate, ...values, isValid: true };
            } else {
              return certificate;
            }
          }
        ),
      }).then(() => {
        console.log('update certification!');
        checkValidation()
      });
    }
    setIsOpenCertificateModal(false);
  };
  const trainingSubmit = (values) => {
    if (!values) {
      formik?.current?.setValues({
        ...formik?.current?.values,
        training: formik?.current?.values?.training?.filter((exp, i) => i !== activeIndex ),
      }).then(() => {
        console.log('delete training!');
        checkValidation()
      });
      setIsOpenTrainingModal(false);
      return
    }
    if (isNewTraining) {
      formik?.current?.setValues({
        ...formik?.current?.values,
        training: [{ ...values, isValid: true }, ...(formik?.current?.values?.training || [])],
      });
    } else {
      formik?.current?.setValues({
        ...formik?.current?.values,
        training: formik?.current?.values?.training?.map(
          (training, index) => {
            if (index === activeIndex) {
              return { ...training, ...values, isValid: true };
            } else {
              return training;
            }
          }
        ),
      }).then(() => {
        console.log('update training!');
        checkValidation()
      });
    }
    setIsOpenTrainingModal(false);
  };

  useEffect(() => {
    getData();
    if (resumeData) {
      const {
        name,
        phoneNumbers,
        summary,
        workExperience = [],
        education = [],
        certifications = [],
        training = [],
        publications,
      } = resumeData;

      //getting gender from localstorage if not parsed from resume
      let data = localStorage.getItem('accessData');
      data = data && JSON.parse(data)
      let gender = data?.firstNameAndLastName?.gender || ''
       let email = data?.email_address || ''

      formik.current.setValues({
        firstName: name?.first || "",
        lastName: name?.last || "",
        birthDate: name?.dateOfBirth || "",
        gender: name?.gender || gender || "",
        workAt: workExperience?.[0]?.organization || "",
        wentTo: education?.[0]?.organization || "",
        email,
        phone: phoneNumbers?.length ? phoneNumbers[0] : "",
        summary: summary || "",
        workExperience: workExperience?.map(
          ({ dates, organization = '', jobTitle = '', jobDescription = '' }) => {
            let isValidDate = true
            if (dates?.startDate && dates?.endDate) {
              if (
                moment(dates.startDate).isAfter(dates.endDate) ||
                moment(dates.startDate).isSame(dates.endDate)
              )
                isValidDate = false;
            }
            return {
              startDate: dates?.startDate || '',
              endDate: dates?.endDate || '',
              companyName: organization,
              role: jobTitle,
              experienceSummary: jobDescription,
              achievements: '',
              isValid:
                dates?.startDate &&
                  organization &&
                  jobTitle &&
                  isValidDate
                  ? true
                  : false,
            };
          }
        ),
        education: education?.map(
          ({ organization = '', accreditation, dates }) => {
            let isValidDate = true
            if (dates?.startDate && dates?.completionDate) {
              if (
                moment(dates.startDate).isAfter(dates.completionDate) ||
                moment(dates.startDate).isSame(dates.completionDate)
              )
                isValidDate = false;
            }
            return {
              institution_name: organization,
              field_of_study: accreditation?.education || '',
              from_date: dates?.startDate || '',
              to_date: dates?.completionDate || '',
              isValid:
                organization &&
                  accreditation?.education &&
                  dates?.startDate &&
                  dates?.completionDate &&
                  isValidDate
                  ? true
                  : false,
            };
          }
        ),
        certificate: certifications?.map(
          (certificate) => {
            if(typeof certificate === 'string') {
              return {
                name : certificate,
                issuing_by_organization: '',
                issue_date: '',
                credential_id: '',
                isValid: false,
              };
            } else {
              let { name = '', issue = '', dates = '', credential_id = '' } = certificate
              return {
                name,
                issuing_by_organization: issue,
                issue_date: dates,
                credential_id,
                isValid: name && issue && dates ? true : false,
              };
            }
          }
        ),
        training: training?.map((training) => {
          let isValidDate = true
          if (training?.date?.start_date && training?.date?.end_date) {
            if (
              moment(training.date.start_date).isAfter(training.date.end_date) ||
              moment(training.date.start_date).isSame(training.date.end_date)
            )
              isValidDate = false;
          }
          return {
            institution_ame: training?.institution_ame,
            name_of_certificate: training?.name_of_certificate,
            field_of_study: training?.field_of_study,
            start_date: training?.date?.start_date,
            end_date: training?.date?.end_date,
            description: training?.description,
            isValid:
              training?.date?.start_date &&
                training?.institution_ame &&
                training?.field_of_study &&
                isValidDate
                ? true
                : false,
          };
        }),
        function_area_preferences: publications || "",
        salary: publications || "",
      });
    } else {
      let data = localStorage.getItem('accessData');
      data = data && JSON.parse(data)
      let firstName = data?.firstNameAndLastName?.first_name || ''
      let lastName = data?.firstNameAndLastName?.last_name || ''
      let gender = data?.firstNameAndLastName?.gender || ''
      let email = data?.email_address || ''

      formik?.current?.setValues({
        ...formik?.current?.values,
        firstName,
        lastName,
        gender,
        email
      });
    }
  }, []);

  const checkValidation = () => {
    let experiences = formik?.current?.values?.workExperience
      ?.map(({ isValid = false }) => isValid).includes(false)

    let educations = formik?.current?.values?.education
      ?.map(({ isValid = false }) => isValid).includes(false)

    let certificates = formik?.current?.values?.certificate
      ?.map(({ isValid = false }) => isValid).includes(false)

    let trainings = formik?.current?.values?.training
      ?.map(({ isValid = false }) => isValid).includes(false)

    console.log('====================================');
    console.log(formik?.current?.values?.workExperience
      ?.map(({ isValid = false }) => isValid));
    console.log(formik?.current?.values?.education
      ?.map(({ isValid = false }) => isValid));
    console.log(formik?.current?.values?.certificate
      ?.map(({ isValid = false }) => isValid));
    console.log(formik?.current?.values?.training
      ?.map(({ isValid = false }) => isValid));
    console.log('====================================');
    console.log('before setdisabled');
    setIsDisabled(experiences || educations || certificates || trainings)
    console.log('after setdisabled');
  }

  useEffect(() => {
    checkValidation()
  }, [formik?.current]);

  const handelSubmit = async (values) => {
    const formData = new FormData();
    const { firstName, lastName, birthDate, gender } = values || {};
    if (!firstName || !lastName || !birthDate || !gender) {
      toast.error("Please fill all the required fields.");
      return;
    }
    formData.append(
      "body",
      JSON.stringify({
        birthday: birthDate,
        first_name: firstName,
        last_name: lastName,
        phone_number: values?.phone,
        email_id: values?.email,
        gender: gender,
        about_me: values?.summary,
      })
    );
    const allPromises = [];
    allPromises.push(postJCProfile(formData));
    values?.workExperience.forEach((exp) => {
      const experienceFormData = new FormData();
      experienceFormData.append(
        "body",
        JSON.stringify({
          from_date: exp.startDate,
          to_date: exp.endDate,
          company_name: exp.companyName,
          role: exp.role,
          experience_summary: exp.experienceSummary,
          achievements: exp.achievements,
          work_recommendation_upload_path: "",
          isPresent: false,
        })
      );
      allPromises.push(postExperience(experienceFormData));
    });
    values?.education.forEach(edu => {
      allPromises.push(addEducationJC({...edu, majors_obtained: edu.majors_obtained || 'High School'}));
    })
    values?.certificate.forEach(certificate => {
      let data = {
        ...certificate,
        issue_date: new Date(...certificate?.issue_date.split("-")),
      };
      allPromises.push(
        addCertificationJC(data))
    })
    values?.training?.length && values?.training.forEach(training => {
      let data = {
        ...training,
        start_date: new Date(...training.start_date.split("-")),
        end_date: new Date(...training.end_date.split("-")),
      };
      allPromises.push(addTrainingJC(data))
    })

    Promise.all(allPromises)
      .then((res) => {
        console.log(res);
        toast.success("Profile updated..!!");
        navigate("/coaching/profile");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error while updating the data.");
      });
  };

  const handleSaveForLater = async (values) => {
    const formData = new FormData();
    const { firstName, lastName, birthDate, gender } = values || {};
    formData.append(
      "body",
      JSON.stringify({
        birthday: birthDate,
        first_name: firstName,
        last_name: lastName,
        phone_number: values?.phone,
        email_id: values?.email,
        gender: gender,
        about_me: values?.summary,
      })
    );
    const allPromises = [];
    allPromises.push(postJCProfile(formData));
    values?.workExperience.forEach((exp) => {
      const experienceFormData = new FormData();
      experienceFormData.append(
        "body",
        JSON.stringify({
          from_date: exp.startDate,
          to_date: exp.endDate,
          company_name: exp.companyName,
          role: exp.role,
          experience_summary: exp.experienceSummary,
          achievements: exp.achievements,
          work_recommendation_upload_path: "",
          isPresent: false,
        })
      );
      allPromises.push(postExperience(experienceFormData));
    });
    values?.education.forEach(edu => {
      allPromises.push(addEducationJC({...edu, majors_obtained: edu.majors_obtained || 'High School'}));
    })
    values?.certificate.forEach(certificate => {
      let data = {
        ...certificate,
        issue_date: new Date(...certificate?.issue_date.split("-")),
      };
      allPromises.push(
        addCertificationJC(data))
    })
    values?.training?.length && values?.training.forEach(training => {
      let data = {
        ...training,
        start_date: new Date(...training.start_date.split("-")),
        end_date: new Date(...training.end_date.split("-")),
      };
      allPromises.push(addTrainingJC(data))
    })

    Promise.all(allPromises)
      .then((res) => {
        console.log(res);
        toast.success("Profile saved..!!");
        navigate("/coaching/profile");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error while saving the data.");
      });
  };

  return (
    <>
      <div className="w-full max-w-[950px] mx-auto lg:px-0 md:px-10 sm:px-8 px-4">
        <h2 className="text-[#384259] sm:text-[28px] text-[22px] font-bold mt-16">
          Looks pretty good !<span className="ml-2">&#128077;</span>
        </h2>
        <p className="text-[#384259] sm:text-base text-[14px] mb-8">
          We have parsed the uploaded CV and here are some details that we
          fetched. Please verify and modify if needed.
        </p>
      </div>
   
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          birthDate: "",
          gender: "",
          workAt: "",
          workExperience: [],
          education: [],
          certificate: [],
          training: [],
        }}
        innerRef={formik}
        onSubmit={async (values) => handelSubmit(values)}
        vali
      >
        {({ errors, values, touched }) => (
          <Form>
            <WhiteBox>
              <h4 className="text-[18px] text-[#384259] font-bold">
                Basic Information
              </h4>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8">
                <div className="flex flex-col my-4">
                  <label htmlFor="firstName" className="text-pirmaryBlue">
                    First Name*
                  </label>
                  <Field
                    name="firstName"
                    type="text"
                    placeholder="Enter your First Name"
                    className={cx(
                      "focus:outline-none border-2 p-[12px] border-solid rounded",
                      {
                        "border-red-500":
                          !values?.firstName && touched.firstName,
                      },
                      "border-grayLight"
                    )}
                  />
                  {!values?.firstName && touched.firstName && (
                    <span className="text-red-500">This is required field</span>
                  )}
                </div>
                <div className="flex flex-col my-4">
                  <label htmlFor="lastName" className="text-pirmaryBlue">
                    Last Name*
                  </label>
                  <Field
                    name="lastName"
                    type="text"
                    placeholder="Enter your Last Name"
                    className={cx(
                      "focus:outline-none border-2 p-[12px] border-solid rounded",
                      {
                        "border-red-500": !values?.lastName && touched.lastName,
                      },
                      "border-grayLight"
                    )}
                  />
                  {!values?.lastName && touched.lastName && (
                    <span className="text-red-500">This is required field</span>
                  )}
                </div>
                <div className="flex flex-col my-4">
                  <label htmlFor="birthDate" className="text-pirmaryBlue">
                    Birth date*
                  </label>
                  <Field
                    type="month"
                    name="birthDate"
                    placeholder="MM/YYYY"
                    max={moment().format("YYYY-MM")}
                    className={cx(
                      "focus:outline-none border-2 p-[12px] border-solid rounded",
                      {
                        "border-red-500":
                          !values?.birthDate && touched.birthDate,
                      },
                      "border-grayLight"
                    )}
                  />
                  {!values?.birthDate && touched.birthDate && (
                    <span className="text-red-500">This is required field</span>
                  )}
                </div>
                <div className="flex flex-col my-4">
                  <label htmlFor="gender" className="text-pirmaryBlue">
                    Gender*
                  </label>
                  <Field
                    as="select"
                    name="gender"
                    className={cx(
                      "focus:outline-none border-2 p-[12px] border-solid rounded",
                      { "border-red-500": !values?.gender && touched.gender },
                      "border-grayLight"
                    )}
                  >
                    <option value="">Select option</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="Transgender">Transgender</option>
                    <option value="Non-binary">Non-binary</option>
                    <option value="Prefer not to answer">Prefer not to answer</option>
                  </Field>
                  {!values?.gender && touched.gender && (
                    <span className="text-red-500">This is required field</span>
                  )}
                </div>
              </div>
            </WhiteBox>
            <WhiteBox>
              <div className="flex flex-row items-center justify-between">
                <h2 className="text-2xl text-[#384259] font-bold">
                  About me{" "}
                  <span>
                    <Tooltip
                      placement="top"
                      title={
                        <div className="rounded-md">
                          <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                            About me
                          </div>
                          <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                            Briefly summarize the essential information you want a hiring manager or human resource professional to know from your resume. The information should be brief and to the point, emphasizing the most important and relevant skills and experience for the position being applied for.
                          </div>
                        </div>
                      }
                      arrow
                    >
                      <InfoIcon sx={{ cursor: "pointer", color: "#828282" }} />
                    </Tooltip>
                  </span>
                </h2>
                <EditOutlinedIcon
                  onClick={() => setIsOpenAboutMeModal(true)}
                  sx={{ cursor: "pointer", color: "#828282" }}
                />
              </div>
              <div className="text-[#828282] mt-6">{values?.summary}</div>

              <div className="border-b-2 my-4 "></div>

              <div className="grid lg:grid-cols-3 sm:grid-cols-2 gird-cols-1 gap-8">
                <div className="flex flex-col justify-center">
                  <h5 className="text-[#000000] text-base font-bold">
                    Works at
                  </h5>
                  <p className="text-[#828282] text-[14px]">{values?.workAt}</p>
                </div>
                <div className="flex flex-col justify-center">
                  <h5 className="text-[#000000] text-base font-bold">
                    Went to
                  </h5>
                  <p className="text-[#828282] text-[14px]">{values?.wentTo}</p>
                </div>
                <div className="flex flex-col justify-center">
                  <h5 className="text-[#000000] text-base font-bold">Email</h5>
                  <p className="text-[#828282] text-[14px]">{values?.email}</p>
                </div>
                <div className="flex flex-col justify-center">
                  <h5 className="text-[#000000] text-base font-bold">Phone</h5>
                  <p className="text-[#828282] text-[14px]">{values?.phone}</p>
                </div>
              </div>
            </WhiteBox>
            <WhiteBox>
              <div className="flex flex-row items-center justify-between">
                <h2 className="text-2xl text-[#384259] font-bold">
                  Work Experience{" "}
                  <span>
                    <Tooltip
                      placement="top"
                      title={
                        <div className="rounded-md">
                          <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                            Work experience
                          </div>
                          <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                            Add your work experience by entering the role title, company name, and the time you hold that position (MM-YYYY).
                          </div>
                        </div>
                      }
                      arrow
                    >
                      <InfoIcon sx={{ cursor: "pointer", color: "#828282" }} />
                    </Tooltip>
                  </span>
                </h2>
                <AddCircleIcon
                  sx={{ cursor: "pointer", color: "#828282" }}
                  onClick={() => {
                    setNewExperience(true);
                    setIsOpenExperienceModal(true);
                  }}
                />
              </div>
              <FieldArray
                name="experience"
                render={() => (
                  <div className="flex flex-col divide-y">
                    {values?.workExperience?.map((experience, index) => (
                      <div
                        key={index}
                        className="flex sm:flex-row flex-col justify-between items-start pt-6"
                      >
                        <div className="w-[60px] h-[40px] mt-6 mr-6">
                          <Avatar {...stringAvatar(`${experience?.companyName}`)} />
                        </div>
                        <div className="w-[100%]">
                          <div className="flex flex-col mb-4">
                            <h5 className="text-[18px] text-[#384259] font-bold">
                              {experience.role}
                            </h5>
                            <p className="text-base text-[#384259] font-normal">
                              {experience.companyName}
                            </p>
                            <p className="text-[14px] text-[#828282]">
                              {experience?.startDate && moment(experience.startDate).format('DD/MM/YYYY')} to {experience?.isPresent ? 'Present' : experience?.endDate && moment(experience.endDate).format('DD/MM/YYYY')}
                            </p>
                          </div>
                          <div className="flex flex-col mb-4">
                            <h5 className="text-base text-[#384259] font-bold mb-1">
                              Experience summary
                            </h5>
                            <p className="text-[14px] text-[#828282]">
                              {experience.experienceSummary}
                            </p>
                          </div>
                          <div className="flex flex-col mb-4">
                            <h5 className="text-base text-[#384259] font-bold mb-1">
                              Achievements
                            </h5>
                            <p className="text-[14px] text-[#828282] text-justify">
                              {experience.achievements}
                            </p>
                            {!experience?.isValid && <span className="text-red-500">please check the input data.</span>}
                          </div>
                        </div>
                        <div className={cx('p-1', {"border border-red-500 rounded text-red-500": !experience?.isValid})}>
                          <EditOutlinedIcon
                            sx={{ cursor: "pointer", color: !experience?.isValid ? "#ef4444" : "#828282" }}
                            onClick={() => {
                              setNewExperience(false);
                              setIsOpenExperienceModal(true);
                              setIndex(index);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
            </WhiteBox>
            <WhiteBox>
              <div className="flex flex-row items-center justify-between">
                <h2 className="text-2xl text-[#384259] font-bold">
                  Education{" "}
                  <span>
                    <Tooltip
                      placement="top"
                      title={
                        <div className="rounded-md">
                          <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                            Education
                          </div>
                          <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                            Please list the institutions you attended to receive education or training in a particular subject, including the degree or certificate earned and the month and year of graduation (MM-YYYY).
                          </div>
                        </div>
                      }
                      arrow
                    >
                      <InfoIcon sx={{ cursor: "pointer", color: "#828282" }} />
                    </Tooltip>
                  </span>
                </h2>
                <AddCircleIcon
                  sx={{ cursor: "pointer", color: "#828282" }}
                  onClick={() => {
                    setNewEducation(true);
                    setIsOpenEducationModal(true);
                  }}
                />
              </div>
              <FieldArray
                name="education"
                render={() => (
                  <div className="flex flex-col divide-y">
                    {values?.education?.map((education, index) => (
                      <div
                        key={index}
                        className="flex sm:flex-row flex-col justify-between items-start pt-6"
                      >
                        <div className="w-[60px] h-[40px] mt-6 mr-6">
                          <Avatar {...stringAvatar(`${education?.institution_ame}`)} />
                        </div>
                        <div style={{ flex: 4 }} className="w-[100%]">
                          <div className="flex flex-col mb-4">
                            <h5 className="text-base text-[#384259] font-bold mb-1">
                              {education.institution_name}
                            </h5>
                            <p className="text-base text-[#384259] font-normal">
                              {education.field_of_study}
                            </p>
                            <p className="text-[14px] text-[#828282]">
                              {education?.from_date && moment(education.from_date).format('DD/MM/YYYY')} to {education?.to_date && moment(education.to_date).format('DD/MM/YYYY')}
                            </p>
                            {!education?.isValid && <span className="text-red-500">please check the input data.</span>}
                          </div>
                        </div>
                        <div className={cx("p-1", {"border border-red-500 rounded text-red-500": !education?.isValid})}>
                          <EditOutlinedIcon
                            sx={{ cursor: "pointer", color: !education?.isValid ? "#ef4444" : "#828282" }}
                            onClick={() => {
                              setNewEducation(false);
                              setIsOpenEducationModal(true);
                              setIndex(index);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
            </WhiteBox>
            <WhiteBox>
              <div className="flex flex-row items-center justify-between">
                <h2 className="text-2xl text-[#384259] font-bold">
                  Professional Certificates{" "}
                  <span>
                    <Tooltip
                      placement="top"
                      title={
                        <div className="rounded-md">
                          <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                            Professional certificates
                          </div>
                          <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                            A professional certificate verifies your knowledge, experience, and skills in a specific job or task for which you have received training. It is an essential credential that demonstrates your competency and enhances your professional credibility.
                          </div>
                        </div>
                      }
                      arrow
                    >
                      <InfoIcon sx={{ cursor: "pointer", color: "#828282" }} />
                    </Tooltip>
                  </span>
                </h2>
                <AddCircleIcon
                  sx={{ cursor: "pointer", color: "#828282" }}
                  onClick={() => {
                    setNewCertificate(true);
                    setIsOpenCertificateModal(true);
                  }}
                />
              </div>
              <FieldArray
                name="certificate"
                render={() => (
                  <div className="flex flex-col divide-y">
                    {values?.certificate?.map((certificate, index) => (
                      <div
                        key={index}
                        className="flex sm:flex-row flex-col justify-between items-start pt-6"
                      >
                        <div className="w-[60px] h-[40px] mt-6 mr-6">
                          <Avatar {...stringAvatar(`${certificate?.name || certificate?.issuing_by_organization}`)} />
                        </div>
                        <div style={{ flex: 4 }} className="w-[100%]">
                          <div className="flex flex-col mb-4">
                            <h5 className="text-base text-[#384259] font-bold mb-1">
                              {certificate.name}
                            </h5>
                            <p className="text-base text-[#384259] font-normal">
                              {certificate.issuing_by_organization}
                            </p>
                            <p className="text-[14px] text-[#828282]">
                              {certificate?.issue_date && moment(certificate.issue_date).format('DD/MM/YYYY')}
                            </p>
                            <div className="text-secondaryBalck">Certificate Number {certificate?.credential_id}</div>
                            {!certificate?.isValid && <span className="text-red-500">please check the input data.</span>}
                          </div>
                        </div>
                        <div className={cx('p-1', {"border border-red-500 rounded text-red-500": !certificate?.isValid})}>
                          <EditOutlinedIcon
                            sx={{ cursor: "pointer", color: !certificate?.isValid ? "#ef4444" : "#828282" }}
                            onClick={() => {
                              setNewCertificate(false);
                              setIsOpenCertificateModal(true);
                              setIndex(index);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
            </WhiteBox>
            <WhiteBox>
              <div className="flex flex-row items-center justify-between">
                <h2 className="text-2xl text-[#384259] font-bold">
                  Trainings{" "}
                  <span>
                    <Tooltip
                      placement="top"
                      title={
                        <div className="rounded-md">
                          <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                            Training
                          </div>
                          <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                            Enter the name of the institution where you received knowledge, training, or instruction in a particular subject, along with the title obtained and the month and year (MM-YYYY).
                          </div>
                        </div>
                      }
                      arrow
                    >
                      <InfoIcon sx={{ cursor: "pointer", color: "#828282" }} />
                    </Tooltip>
                  </span>
                </h2>
                <AddCircleIcon
                  sx={{ cursor: "pointer", color: "#828282" }}
                  onClick={() => {
                    setNewTraining(true);
                    setIsOpenTrainingModal(true);
                  }}
                />
              </div>
              <FieldArray
                name="training"
                render={() => (
                  <div className="flex flex-col divide-y">
                    {values?.training?.map((training, index) => (
                      <div
                        key={index}
                        className="flex sm:flex-row flex-col justify-between items-start pt-6"
                      >
                        <div className="w-[60px] h-[40px] mt-6 mr-6">
                          <Avatar {...stringAvatar(`${training?.institution_ame}`)} />
                        </div>
                        <div style={{ flex: 4 }} className="w-[100%]">
                          <div className="flex flex-col mb-4">
                            <h5 className="text-[18px] text-[#384259] font-bold">
                              {training.institution_ame}
                            </h5>
                            <p className="text-base text-[#384259] font-normal">
                              {training.name_of_certificate}
                            </p>
                            <p className="text-base text-[#384259] font-normal">
                              {training.field_of_study}
                            </p>
                            <p className="text-[14px] text-[#828282]">
                              {training?.start_date && moment(training.start_date).format('DD/MM/YYYY')} to {training?.end_date && moment(training?.end_date).format('DD/MM/YYYY')}
                            </p>
                            <p className="text-[14px] text-[#828282] mt-3">
                              {training.description}
                            </p>
                            {!training?.isValid && <span className="text-red-500">please check the input data.</span>}
                          </div>
                        </div>
                        <div className={cx('p-1', {"border border-red-500 rounded text-red-500": !training?.isValid})}>
                          <EditOutlinedIcon
                            sx={{ cursor: "pointer", color: !training?.isValid ? "#ef4444" : "#828282"  }}
                            onClick={() => {
                              setNewTraining(false);
                              setIsOpenTrainingModal(true);
                              setIndex(index);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
            </WhiteBox>
            <div className="flex lg:mx-auto max-w-[950px]">
              <InfoBoxes
                title="Assessment insights summary"
                TooltipText="Your Insights summary provides a quick view of your Unique Selling Proposition (USP). In addition, it gives you a glance at your behavior style (your preference) and priorities (your top three (3)) values that can aid in determining a job opportunity that maximizes your peak performance."
                TooltipTitle="Assessment insights summary"
                containerStyle="mx-3 my-6 w-full"
              >
                <img src={prefferedImageBlured} alt='preferred behavior' />
                {/* <div className="flex flex-col divide-y">
                  <div className="flex flex-wrap justify-between py-3">
                    <span className="text-base text-pirmaryBlue">
                      Preferred behavior:{" "}
                    </span>
                    <div className=''>
                    {maxValue?.map((preBehavior, index) => {
                      return (
                        <>
                          <Tooltip
                            placement='top'
                            title={
                              <div className='rounded-md'>
                                <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
                                  {`${charMapping?.[preBehavior?.type]?.name}`}
                                </div>
                                <div className='tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b'>
                                  Please finish validating your CV to see your report.
                                </div>
                              </div>
                            }
                            arrow
                          >
                            <span
                              className='text-base font-bold cursor-pointer'
                              style={{ color: charMapping?.[preBehavior?.type]?.color }}
                            >
                              {charMapping?.[preBehavior?.type]?.name}
                            </span>
                          </Tooltip>
                          {index < maxValue.length - 1 && <>, &nbsp;</>}
                        </>
                      )
                    })}
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-between py-3">
                    <span className="text-base text-pirmaryBlue">
                      Values:{" "}
                    </span>
                    <div className="">
                      {extraValues?.map((extra, index) => {
                        return (
                          <>
                            <Tooltip
                              key={index}
                              placement="top"
                              title={
                                <div className="rounded-md">
                                  <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                                    {`${extra}`}
                                  </div>
                                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                                    Please finish validating your CV to see your report.
                                  </div>
                                </div>
                              }
                              arrow
                            >
                              <span
                                className="text-base font-bold cursor-pointer"
                                style={{
                                  color: mapDriverOfEngagmentColors?.[extra],
                                }}
                              >
                                {extra}
                              </span>
                            </Tooltip>
                            {index < extraValues.length - 1 && <>, &nbsp;</>}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div> */}
              </InfoBoxes>
              <InfoBoxes
                handleClickAdd={() => {
                  setAddFunction(true);
                }}
                containerStyle=" mx-3 my-6 w-full"
                title="Functional area of preference"
                TooltipText="Selects your preferred functional area, such as sales, marketing, finance, management, operations, accounting, information technology, and human resources, that aligns with your interest and skills. Your choice should be based on your strengths and passion, leading to greater job satisfaction and success."
                TooltipTitle="Functional area of preference"
                icon={<AddCircleOutlineTwoToneIcon />}
              >
                <div className="flex flex-col divide-y">
                  {allData?.function_area_preferences?.map((item, index) => (
                    <div className="flex justify-between py-3" key={item}>
                      <span className="text-base text-pirmaryBlue">{item}</span>
                      <span className="text-base font-bold text-[#8B5086]">
                        <EditOutlinedIcon
                          className="text-secondaryBalck cursor-pointer"
                          onClick={() => {
                            setAddFunction(true);
                            setSelected(item);
                          }}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </InfoBoxes>
            </div>
            <div className="flex lg:mx-auto max-w-[950px]">
              <InfoBoxes
                title="Salary expectation"
                TooltipText="When entering your salary expectation, separate the three spaces that indicate thousands; for instance, 1000 should be 1 000, 20,000 should be 20 000, and 150000 should be 150 000."
                TooltipTitle="Salary expectation"
                containerStyle="mx-3 my-6 w-full"
              >
                <div className="flex flex-col divide-y">
                  {allData && (
                    <div className="flex justify-between py-3">
                      <span className="text-base text-pirmaryBlue font-bold">
                        {allData?.salary_currency || ""}{" "}
                        <NumericFormat
                          value={allData?.salary_expectation_min || ""}
                          thousandSeparator={' '}
                          thousandsGroupStyle='thousand'
                          displayType="text"
                        />{" - "}
                        <NumericFormat
                          value={allData?.salary_expectation_max || ""}
                          thousandSeparator={' '}
                          thousandsGroupStyle='thousand'
                          displayType="text"
                        />
                      </span>
                      <span className="text-base font-bold text-[#8B5086]">
                        <EditOutlinedIcon
                          onClick={() => {
                            setUpdateSalary(true);
                          }}
                          className="text-secondaryBalck cursor-pointer"
                        />
                      </span>
                    </div>
                  )}
                </div>
              </InfoBoxes>
              <InfoBoxes
                title="Awards"
                icon={<AddCircleOutlineTwoToneIcon />}
                containerStyle="mx-3 my-6 w-full"
                handleClickAdd={() => {
                  setAwards(true);
                  setSelectedAward(-1);
                }}
                TooltipText="Please list any relevant awards you have received that you would like a potential employer to know about. This information can help increase your profile and demonstrate your achievements."
                TooltipTitle="Awards"
              >
                <div className="flex flex-col divide-y">
                  {allData &&
                    allData?.awards?.map((item, index) => (
                      <div
                        className="flex justify-between py-3"
                        key={item + index}
                      >
                        <span className="text-base text-pirmaryBlue">
                          {item}
                        </span>
                        <span className="text-base font-bold text-[#8B5086]">
                          <EditOutlinedIcon
                            className="text-secondaryBalck cursor-pointer"
                            onClick={() => {
                              setAwards(true);
                              setSelectedAward(index);
                            }}
                          />
                        </span>
                      </div>
                    ))}
                </div>
              </InfoBoxes>
            </div>

            <div className="flex lg:mx-auto max-w-[950px]">
              <InfoBoxes
                title="Skills"
                containerStyle="mx-3 my-6 w-full"
                handleClickAdd={() => {
                  setAddSkilss(true);
                }}
                icon={<AddCircleOutlineTwoToneIcon />}
                TooltipText="Enter your specific technical knowledge or abilities gained through your life experience, formal or informal training, and required for the job you desire. For example, software development language, web design, project management, legal, accounting, and mathematics are some of the skills you could document."
                TooltipTitle="Skills"
              >
                <div className="flex flex-col ">
                  {allData?.skills?.map((val, key) => (
                    <div className="flex flex-col mt-2">
                      <div className="flex justify-between py-3">
                        <span className="text-base text-pirmaryBlue">
                          {val.name}
                        </span>
                        <span className="text-base font-bold text-pirmaryBlue">
                          {val.level * 20}%
                        </span>
                      </div>
                      <div className="-mt-2">
                        <LinearProgress
                          className={
                            val.level <= 2
                              ? progressstyles.low
                              : val.level <= 3
                                ? progressstyles.mid
                                : val.level <= 5
                                  ? progressstyles.high
                                  : ""
                          }
                          variant="determinate"
                          value={val.level * 20}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </InfoBoxes>

              <InfoBoxes
                title="Languages"
                containerStyle="mx-3 my-6 w-full"
                icon={<AddCircleOutlineTwoToneIcon />}
                handleClickAdd={() => {
                  setLanguages(true);
                }}
                TooltipText="Enter or select the languages you speak and the level of proficiency.
                For native or native-like, you can indicate 100%, for intermediary 60 - 70%, and for beginners, 10 - 50%."
                TooltipTitle="Languages"
              >
                <div className="flex flex-col ">
                  {allData?.language_skill?.map((val, key) => (
                    <div className="flex flex-col mt-2">
                      <div className="flex justify-between py-3">
                        <span className="text-base text-pirmaryBlue">
                          {allLanguages.find(({ name }) => name === val.name)?.name || ''}
                        </span>
                        <span className="text-base font-bold text-pirmaryBlue">
                          {val.level * 20}%
                        </span>
                      </div>
                      <div className="-mt-2">
                        <LinearProgress
                          className={
                            val.level <= 2
                              ? progressstyles.low
                              : val.level <= 3
                                ? progressstyles.mid
                                : val.level <= 5
                                  ? progressstyles.high
                                  : ""
                          }
                          variant="determinate"
                          value={val.level * 20}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </InfoBoxes>
            </div>
            
            <div className="w-full max-w-[950px] mx-auto lg:px-0 md:px-10 sm:px-8 px-4">
              <p className="text-base font-bold text-pirmaryBlue mb-3">
                When you review the parsed data, please pay special attention to the items highlighted in <span className="text-red-500">red.</span> These highlights indicate areas that need your edits to make sure you have accurate information.
              </p>
              <p className="text-base font-bold text-pirmaryBlue mb-3">
                If you wish to save your résumé for later validation, click the 'Save for later' button at the bottom of the page. To access your saved résumés anytime, navigate to the 'My profile' tab on your TRIPA profile landing page.
              </p>
            </div>
            <div className="max-w-[950px] w-full  mx-auto my-5 flex items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
              <Button
                type="button"
                variant="contained"
                color="success"
                onClick={() => navigate("/resumeform/upload")}
                sx={{
                  "&.MuiButton-containedSuccess": {
                    backgroundColor: "#E3E6E9",
                    fontSize: "16px",
                    color: "#828282",
                  },
                }}
              >
                Back
              </Button>
              <span>
                <SuccessBtn type="button" onClick={() => handleSaveForLater(values)}>
                  Save for later
                </SuccessBtn>
              </span>
              <Tooltip
                placement="top"
                title={
                  isDisabled ?<div className="tooltip-font-desc text-white p-3 bg-red-500 bg-opacity-80 rounded-b">
                    please check all CV data and fill required data
                  </div> : null
                }
              >
                <span>
                  <SuccessBtn
                    type='submit'
                    disabled={isDisabled}
                    className={cx({ "pointer-events-none": isDisabled })}
                  >
                    Update my profile
                  </SuccessBtn>
                </span>
              </Tooltip>
            </div>
          </Form>
        )}
      </Formik>
      <AboutMeModalBody
        values={formik?.current?.values}
        isOpen={isOpenAboutMeModal}
        onSubmit={aboutSubmit}
        onClose={setIsOpenAboutMeModal}
      />
      <ExperienceModalBody
        values={formik?.current?.values?.workExperience?.[activeIndex]}
        isNew={isNewExperience}
        isOpen={isOpenExperienceModal}
        onSubmit={experienceSubmit}
        onClose={setIsOpenExperienceModal}
      />
      <Modal
        onClose={() => {
          setSelected();
        }}
        title={`${!isNewEducation ? 'Edit' : 'Add'} Education`}
        open={isOpenEducationModal}
        setOpen={setIsOpenEducationModal}
        mdUpWidth={550}
      >
        <EducationEditModal
          isLocal={true}
          selected={isNewEducation ? null :formik?.current?.values?.education?.[activeIndex]}
          handleReload={(data) => {
            if (data) {
              if (isNewEducation) {
                delete data.id
                formik.current.setValues({
                  ...formik?.current?.values,
                  education: [...(formik?.current?.values?.education || []),{ ...data, isValid: true}]
                })
              } else {
                formik.current.setValues({
                  ...formik?.current?.values,
                  education: formik?.current?.values?.education?.map((edu, i) => {
                    if (activeIndex === i) {
                      return { ...data, isValid: true};
                    } else {
                      return edu;
                    }
                  }),
                }).then(() => {
                  console.log('update edu!');
                  checkValidation()
                });
              }
            } else {
              formik.current.setValues({
                ...formik?.current?.values,
                education: formik?.current?.values?.education?.filter(
                  (x, i) => i !== activeIndex
                ),
              }).then(() => {
                console.log('delete edu!');
                checkValidation()
              });
            }
            setIsOpenEducationModal(false);
          }}
        />
      </Modal>
      <CertificateModalBody
        values={formik?.current?.values?.certificate?.[activeIndex]}
        isNew={isNewCertificate}
        isOpen={isOpenCertificateModal}
        onSubmit={certificateSubmit}
        onClose={setIsOpenCertificateModal}
      />
      <TrainingModalBody
        values={formik?.current?.values?.training?.[activeIndex]}
        isNew={isNewTraining}
        isOpen={isOpenTrainingModal}
        onSubmit={trainingSubmit}
        onClose={setIsOpenTrainingModal}
      />
      {addFunction && (
        <Modal
          title={`${selected ? 'Edit' : 'Add'} functional area of preferences`}
          open={addFunction}
          setOpen={setAddFunction}
          mdUpWidth={550}
          onClose={() => {}}
        >
          <FunctionArea
            selected2={selected}
            handleReload={(e) => {
              setAddFunction(false);
              getData();
              setSelected(null)
            }}
            all={allData?.function_area_preferences}
            setSelected2={setSelected}
          />
        </Modal>
      )}
      {updateSalary && (
        <Modal
          title="Update salary expectation"
          open={updateSalary}
          setOpen={setUpdateSalary}
          mdUpWidth={550}
          onClose={() => {
            setSelected("");
          }}
        >
          <UpdateSalary
            data={allData}
            handleReload={() => {
              getData();
              setSelected("");
              setUpdateSalary(false);
            }}
          />
        </Modal>
      )}
      {addSkilss && (
        <Modal
          title="Add skills"
          open={addSkilss}
          setOpen={setAddSkilss}
          mdUpWidth={550}
        >
          <AddSkills
            data={allData}
            handleReload={(e) => {
              setAddSkilss(false);
              getData();
            }}
            getData={getData}
          />
        </Modal>
      )}
      {languages && (
        <Modal
          title="Add language"
          open={languages}
          setOpen={setLanguages}
          mdUpWidth={550}
        >
          <Languages
            data={allData}
            handleReload={(e) => {
              setLanguages(false);
              getData();
            }}
            getData={allData}
          />
        </Modal>
      )}
      {awards && (
        <Modal
          title={`${selectedAward > -1 ? 'Edit' : 'Add'} Award`}
          open={awards}
          setOpen={setAwards}
          mdUpWidth={550}
        >
          <Awards
            handleReload={(e) => {
              setAwards(false);
              getData();
            }}
            all={allData && allData.awards}
            selected={selectedAward}
            setSelected={setSelected}
          />
        </Modal>
      )}
    </>
  );
}

export default CheckResume;
