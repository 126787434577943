import React from "react";
import { CircularProgress } from "@mui/material";

function SuccessBtn({
  children,
  onClick,
  customStyle,
  className,
  type,
  disabled = false,
  ...rest
}) {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`bg-success hover:bg-[#58896d] transition-all py-[9px] px-[27px] d-flex flex-row 
      items-center rounded text-white text-base cursor-pointer ${
        disabled && "cursor-not-allowed"
      } ${className}`}
      style={customStyle}
      disabled={disabled}
      {...rest}
    >
      {disabled ? (
        <CircularProgress/>
      ) : children}
    </button>
  );
}

export default SuccessBtn;
