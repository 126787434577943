import React, { useState } from "react";
import { useEffect } from "react";

import { BehaviorStepper } from "../reusableComponents";

import WhiteBox from "../shared/WhiteBox";
import { toast } from "react-toastify";
import { uploadQuestions } from "../../../../../api/jc";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AssessmentIframe from "./AssessmentIframe";
import config from "../../../../../config/config";

function DriverOfEngagementStepThree() {
  const { state } = useLocation();

  const [mappedQuestions, setMappedQuestions] = useState([]);
  const [iFrameLink, setIFrameLink] = useState('')
  const questions = useSelector(state => state.behaviour.behaviourAssessment);
  const choice = useSelector(state => state.steps.behaviourChoice);


  useEffect(() => {
    if (questions?.length) {
      setMappedQuestions(questions.map(data => data?.question));
    }
  }, [questions])

  const navigate = useNavigate();

  const handelSubmit = () => {

    const formData = []

    choice.forEach(x => {
      formData.push(questions.find(y => y.question === x));
    })

    let temp = {
      assessment: {
        assessment_type: "BehaviorAssessment",
        assessment_data_ordinality: formData
      }
    }
    uploadQuestions(temp).then((res) => {
      toast.success("Updated.")
      navigate('/assessment/values/start')
    });
  }

  React.useEffect(() => {
    let data = localStorage.getItem('accessData');
    data = data && JSON.parse(data)
    console.log(data);
    let code = data?.isBehaviorAssessmentCompleted || ''
    let fName = data?.firstNameAndLastName?.first_name || 'Abc'
    let lName = data?.firstNameAndLastName?.last_name || 'Abc'
    let gender = data?.firstNameAndLastName?.gender === 'male' ? 'm' : 'f'
    let email = data?.email_address || ''
    let iFrameSrc = `${config.assessmentUrlApi}/remote/DISC/${code}/instrument/?fname=${fName}&lname=${lName}&gender=${gender}&email=${email}&completeURL=${window.location.origin}/assessment/behavior/complete/?code=`
    setIFrameLink(iFrameSrc)
  }, []);

  return (
    <>
      <BehaviorStepper step={0} />
      <WhiteBox title="Welcome to the behavior traits assessment!">

      <p className="text-[#384259] text-base mb-5">
        Ready to uncover your unique strengths and areas for improvement? 
      </p>
      
      <p className="text-[#384259] text-base mb-5">
        Here's how you can get started:
      </p>

      <p className="text-[#384259] text-base mb-5">
        Take a look to the right, and you'll see four items representing specific behaviors. Click and drag each item to the left or right, ranking them from "<span className="font-bold">Most like Me</span>" to "<span className="font-bold">Least like Me.</span>" Your final order should accurately represent how you view these behaviors, with the one that best reflects you on top and the least on the bottom.
      </p>

      <p className="text-[#384259] text-base mb-5">
        Don't worry if you're not sure at first. You can always move the items around until you feel your order is the most accurate before clicking "<span className="font-bold">Submit.</span>"
      </p>

      <p className="text-[#384259] text-base mb-5">
        So let's get started, shall we? It's time to unlock your full potential and discover what makes you uniquely you. 
      </p>

      <p className="text-[#384259] text-base">
        Enjoy the journey!
      </p>

        {/* <p className="text-base mb-5 font-bold">
          Before you start, read the following instructions:
        </p> */}

        {/* <p className="text-base mb-5">
          To the right are four items, each representing a specific behavior -
          by clicking and dragging each item, move them from the "Move term from
          here" to the right "Most Like Me" and order them, so they are ranked
          <span className="font-bold"> MOST</span> to{" "}
          <span className="font-bold">LEAST</span> like you.
        </p>

        <p className="text-base mb-4">
          The final order should represent how you rank this behavior from Most
          like me (on top) to Least like me (on the bottom). You can also move
          items around to adjust their order until you feel it is most accurate.
          When you have completed this set of seven answers, click{" "}
          <span className="font-bold">"Submit"</span> to go to the next stage in
          the series."
        </p>

        <TransferList
          choicesArray={mappedQuestions}
          choicesTitle={"Move term from here"}
          handleData={updateBehaviourChoice}
          chosenTitle={"To here"}
          chosenTopOfList={"Most Like me"}
          chosenBottomOfList={"Least like me"}
          heightStyle={"400px"}
          fileName={"driverOfEngagement1"}
        /> */}
        <AssessmentIframe iFrameLink={iFrameLink || ''} />
      </WhiteBox>

      {/* <div className="max-w-[950px] w-full mx-auto my-5 flex items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate('/assessment/behavior')}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#E3E6E9",
              fontSize: "16px",
              color: "#828282",
            },
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => {
            if (choice.length < mappedQuestions.length) {
              toast.warning("Please Move All Items To Hear!!")
            } else {
              handelSubmit()
            }
          }

          } sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#7AB794",
              fontSize: "16px",
            },
          }}
        >
          Submit
        </Button>
      </div> */}
    </>
  );
}

export default DriverOfEngagementStepThree;
