import React from "react";
import BehavioralTraitsSlide4 from "../../../../assets/BehavioralTraitsSlide4.svg";

function Slide5() {
  return (
    <div className="flex md:flex-row flex-col items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <img src={BehavioralTraitsSlide4} alt="" className="w-full h-full" />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          How to get the most out of the report?
        </h5>
        <p className="text-base text-pirmaryBlue mb-6" >As you examine your behavior report, keep the following points in mind:</p>
        <ul className="list-disc pl-5 text-base text-pirmaryBlue mb-6">
          <li>Analyze the information you read about your behavior style's strengths, opportunities for continual improvement, communication tips, ideal job environment, and how you can be more effective. Put them to the test by asking people you trust in your personal life and professional environment.</li>
          <li>Pay attention to your strengths and areas for development.</li>
          <li>Remember that while you have a preferred behavior style, you are a combination of the four elements of behavior (Decisive, Interactive, Stabilizer, and Cautious).</li>
          <li>Each behavior style has a significant priority and denotes specific personal energy and motivation, and each behavior has a wide range of variance in how they prefer to act.</li>
          <li>If multiple behavior styles have the exact measurement, you use them equally and are your preferred style; however, the TRIPA platform will highlight just one for now.</li>
          <li>Know the general characteristics of the other three behavior styles to improve your communication efficiency and increase your chance of influencing others.</li>
          <li>Remember that the behavior report is not designed to describe the nuances of your behavioral style.</li>
        </ul>
        <p className="text-base text-pirmaryBlue mb-6" >In addition to the report introduction, you will find information icons throughout the report providing details to help you understand your report's content. If you need more help, you can schedule a coaching session by emailing your request to {" "}
          <a
            href="mailto:info@tripa.ai"
            className="text-success hover:underline"
          >
            info@tripa.ai
          </a>
          .</p>
      </div>
    </div>
  );
}

export default Slide5;