import React, { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";

export default function BoxDescriptionInfo({
  list,
  title,
  description,
  caption,
  style,
  ToolTip
}) {

  const [toolTipText, setToolTipText] = useState();
  const [toolTipTitle, setToolTipTitle] = useState();

  useEffect(() => {
    checkTitle();
  }, []);

  const checkTitle = () => {
    if (ToolTip) {
      if (title === 'Maximizers') {
        setToolTipTitle('Superpower');
        setToolTipText('Superpower refers to your unique ability or strength that sets you apart and gives you an advantage in a particular area. It represents something you excel at and are naturally gifted or skilled in. Just like a superhero possesses an extraordinary power that makes them stand out, individuals also have unique strengths that make them exceptional in certain areas. In terms of personal strengths and weaknesses, superpowers and kryptonite can be seen as equivalent to strengths and blind spots. Recognizing and leveraging ones superpowers while being aware of and working on their kryptonite or blind spots can contribute to personal growth and success.');
      } else if (title === 'Minimizers') {
        setToolTipTitle('Kryptonite');
        setToolTipText('Kryptonite is a term derived from the Superman comics and refers to a fictional mineral that weakens and drains Supermans powers. In a metaphorical sense, it represents your vulnerabilities, weaknesses, or blind spots. Just as Superman is susceptible to the negative effects of kryptonite, individuals may have aspects or traits that undermine their performance or hinder their success. In the context of personal strengths and weaknesses, the concept of superpowers and kryptonite can be seen as equivalent to strengths and blind spots. Recognizing and leveraging ones superpowers while being aware of and working on their kryptonite or blind spots can contribute to personal growth and success.');
      } else if (title === 'Motivators') {
        setToolTipTitle('Motivators');
        setToolTipText('These are factors that stimulate enthusiasm and drive in a person to continually be interested and committed to a job or role or to make an effort to attain a goal. Motivators can be intrinsic (within an individual, like personal satisfaction or passion) or extrinsic (external factors, like rewards or recognition).');
      } else if (title.startsWith('Needs')) {
        setToolTipTitle('Needs for Growth');
        setToolTipText('This term generally refers to the intrinsic human need for personal development and self-improvement. In a professional setting, it refers to an individuals desire to learn new skills, take on new challenges, and continuously improve their abilities and effectiveness in their role.');
      } else if (title.startsWith('Targets')) {
        setToolTipTitle('Targets for Reinforcement and Development');
        setToolTipText('In a professional or learning context, targets for reinforcement are areas where an individuals skills or behaviors are already strong and should be encouraged or rewarded to maintain that strength. On the other hand, targets for development are areas where an individual could improve. These could be skills that need to be learned or behaviors that need to be changed.');
      } else if (title.startsWith('Preferred')) {
        setToolTipTitle('Preferred environment');
        setToolTipText('This term refers to the type of surroundings or conditions in which a person is most comfortable and effective. In a work context, this could refer to anything from the physical workspace (like a quiet office or a collaborative open space) to aspects of the company culture (like a highly structured environment or one that encourages creativity and autonomy). Different individuals will have different preferred environments depending on their personality, work style, and the tasks they need to perform.');
      }
    }
  }

  return (

    <div
      style={style}
      className="rounded-2xl bg-white py-6 max-h-[500px] overflow-hidden relative border border-grayLight"
    >
      <p className=" text-pirmaryBlue pl-6 font-bold text-2xl mb-5 sticky top-0 bg-white lowercase first-letter:uppercase">
      {title === "Maximizers" ? "Superpower" : (title === "Minimizers" ? "Kryptonite" : title)}
       {ToolTip && (<Tooltip
          placement="top"
          title={
            <div className="rounded-md">
              <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                {toolTipTitle}
              </div>
              <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                {toolTipText}
              </div>
            </div>
          }
          arrow
        >
          <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
        </Tooltip>
        )}
      </p>
      <div className="max-h-full px-6 pb-12 overflow-auto ">
        {description && (
          <div className="text-pirmaryBlue text-base mb-6">{description}</div>
        )}
        {caption && (
          <div className="text-pirmaryBlue text-base font-bold mb-4">{caption}</div>
        )}
        <div className="">
          {list?.map((item, index) => (
            <div
              className="text-base flex items-start justify-start  mb-3"
              key={index + 1}
            >
              <div className="flex items-center">
                <CheckCircleIcon
                  sx={{
                    "&.MuiSvgIcon-root": { fill: "#7AB794 !important" },
                  }}
                />
              </div>
              <span className="text-base text-pirmaryBlue font-normal ml-2">
                {item}
              </span>
            </div>
          ))}
        </div>
        <div className="bg-gradient-to-b from-[rgba(255,255,255,0)] via-[rgba(255,255,255,0.74)] to-white left-0 h-10 absolute bottom-0 w-full"></div>
      </div>
    </div>
  );
}
