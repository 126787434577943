import React from "react";
import { toast } from "react-toastify";
import { sendVerifyMail } from "../../../../api/jc";
import SuccessBtn from "../../sharedComponents/buttons/successBtn/SuccessBtn";
import Modal from "../../sharedComponents/modal/Modal";

const ResendMailVerify = ({ resendVerifyPopUp, setResendVerifyPopUp, email = "" , setResendVerify}) => {
  const [value, setValue] = React.useState("");
  const getVerifyMail = async () => {
    try {
      await sendVerifyMail(value);
      toast.success("Mail verification sent, please check your inbox!");
      setResendVerifyPopUp(false);
      setResendVerify(false);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    email && setValue(email);
  }, [email]);

  return (
    <>
      <Modal
        title="Resend mail verification"
        open={resendVerifyPopUp}
        setOpen={setResendVerifyPopUp}
        mdUpWidth={550}
      >
        <div className="flex flex-col">
          <label className="text-base text-pirmaryBlue">Email *</label>
          <input
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            type="text"
            placeHolder="Enter your email"
            className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
          />
        </div>
        <SuccessBtn onClick={getVerifyMail} className="mt-5" type="submit">
          <span className="font-bold">Send Verify mail</span>
        </SuccessBtn>
      </Modal>
    </>
  );
};

export default ResendMailVerify;
