import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CircleProgressBar from "../../../sharedComponents/circleProgressBar/CircleProgressBar";
import IconBlue from "../../../../assets/characteristicIconBlue.svg";
import TickText from "../../../sharedComponents/tickText/TickText";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export default function Cautious({ setOpenModal, data }) {
  const content = useSelector(
    (state) =>
      state.user?.assessments
        ?.find(
          ({ assessment }) =>
            assessment?.assessment_type === 'BehaviorAssessment'
        )
        ?.assessment?.assessment_content?.score?.find(({ type }) => type == 'C')
        ?.statements
  );
  const [allData, setAllData] = useState({})
  useEffect(() => {
    setAllData(data.filter(i => i.type == "C")[0])
  }, [data])
  return (
    <div className="pt-4">
      <p className="text-[#0000ff] font-bold text-2xl mb-2">Cautious</p>
      <div className="text-base font-bold text-pirmaryBlue">
        Approach to standards, procedures, and expectations.
      </div>
      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="text-base lg:col-span-8 col-span-1 text-pirmaryBlue mt-4 mb-10 pr-4">
          <span className="font-bold text-[#0000ff]">HIGHER C </span>individuals with a higher C score tend to adhere to rules, standards, procedures, and protocols set by those in authority whom they respect. They prefer tasks to be completed accurately and according to the operating manual. They firmly believe that rules are meant to be followed and wish others would share the same values.
        </div>
        <div className=" lg:col-span-2 col-span-1 px-4 py-4 justify-self-center">
          <CircleProgressBar percentage={allData && Number(allData?.value)} pathColor="#0000ff" />
        </div>
      </div>
      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="text-base lg:col-span-8 col-span-1 text-pirmaryBlue mt-4 mb-10">
          <span className="font-bold text-[#0000ff]">LOWER C </span>individuals with a LOWER C score tend to work more independently and may not adhere strictly to rules or standard operating procedures. Instead, they focus on achieving their goals efficiently and may develop new strategies or approaches. Rules are viewed as guidelines by those with a Lower C score, and they may be willing to bend or break them if necessary to achieve their objectives.
        </div>
        <div className=" lg:col-span-2 col-span-1 px-4 py-4 justify-self-center">
          <CircleProgressBar percentage={allData && Number(allData?.adaptiveValue)} pathColor="grey" />
        </div>
      </div>

      <div className="grid lg:grid-cols-10 grid-col-1">
        <div className="lg:col-span-8 col-span-1 mt-4 mb-10 lg:w-[90%]">
          {content?.length && (
            <>
              <div className="text-base font-bold mb-4">{content[0]}</div>
              {content.slice(1, content.length - 1).map((data, i) => <TickText key={i} text={data} />)}
            </>
          )}

          <div className=" text-secondaryBalck text-sm mt-4">
            * High score doesn’t mean good, and a low score doesn’t mean bad, as
            this is a spectrum or continuum of BEHAVIORAL TRAITS.
          </div>
        </div>
        <div
          className="lg:col-span-2 col-span-1 mt-4 mb-10 flex flex-col
          items-center text-center cursor-pointer"
          onClick={() => setOpenModal(true)}
        >
          <div className="w-[100px] h-[100px]">
            <img src={IconBlue} alt="" className="w-full" />
          </div>
          <p className="text-sm font-bold text-pirmaryBlue mt-4">
            Observable characteristic of High <br />{" "}
            <span className="text-[#0000ff]">Cautious</span> behavior <Tooltip placement="bottom"
              title={
                <div className="rounded-md">
                  <div className="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
                    Cautious Behavior
                  </div>
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    Cautious individuals are detail-oriented, thoughtful, patient, and prudent. They favor planning and preparation, double-check their work, and often show reluctance to embrace change without a compelling reason. Their self-discipline, resilience, and focus on safety lead to a careful, methodical approach, reducing the likelihood of mistakes and fostering a safer environment.
                  </div>
                </div>
              }
              arrow
            >
              <InfoIcon fontSize="small" className="cursor-pointer ml-1 text-secondaryBalck" />
            </Tooltip>
          </p>
        </div>
      </div>
    </div>
  );
}
