import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import InfoBoxes from "../../sharedComponents/profile/infoBox";
import { makeStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LinearProgress from "@mui/material/LinearProgress";
import { ReactComponent as PersonalStyle } from "../../../assets/PersonalStyle.svg";
import { ReactComponent as PracticalStyle } from "../../../assets/PracticalStyle.svg";
import { ReactComponent as AnalyticalStyle } from "../../../assets/AnalyticalStyle.svg";
import BoxDescriptionInfo from "../behavioralTraits/sharedcomponents/boxDescriptionInfo";
import ReactApexChart from "react-apexcharts";

import {
  a11yProps,
  TabPanel,
  tabStyleRolBase,
} from "../login/reusableComponents";
import SlideBox from "../../sharedComponents/slideBox/SlideBox";

// slides of SlideBox
import Slide1 from "./slides/Slide1";
import Slide2 from "./slides/Slide2";
import Slide3 from "./slides/Slide3";
import Slide4 from "./slides/Slide4";
import Slide5 from "./slides/Slide5";
import { getByUserId } from "../../../../api/jc";
import DimensionOfThoughts from "../../sharedComponents/DimensionOfThoughts";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../../redux/actions/user";
import RoleBaseTab from "./tabs";
import { roleBaseColors } from "../../../../utils/getColors";

const colors = {
  "Human resource management":"#737210",
  "Personal motivators":"#CA902C",
  "Performance management":"#AA554C",
  "Planning & organizing":"#4FD1C5",
  "Production management":"#7ABEEF",
  "Self management":"#CA722C",
}
const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
      },
    },
    low: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#7AB794 !important",
      },
    },
    mid: {
      height: "5px!important",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5 !important",
      "& .MuiLinearProgress-bar": {
        borderRadius: "5px",
        backgroundColor: "#8B5086 !important",
      },
    },
  };
});
function RolebaseProfile() {
  const dispatch = useDispatch();
  const [openSlideBox, setOpenSlideBox] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [roleBasedCompetanceAssessment, setRoleBasedCompetanceAssessment] =
    useState([]);
  const [activeItem, setActiveItem] = React.useState("Human resource management");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveItem(event.target.name)
  };
  const externalData = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'RoleBasedCompetanceAssessment'
    )?.assessment?.assessment_content?.aiDescriptor?.find(({ heading }) => heading === 'ExternalPatternsPage')
  );
  const internalData = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'RoleBasedCompetanceAssessment'
    )?.assessment?.assessment_content?.aiDescriptor?.find(({ heading }) => heading === 'InternalPatternsPage')
  );
  const roleBaseData = useSelector((state) =>
    state.user?.assessments?.find(
      ({ assessment }) => assessment?.assessment_type === 'RoleBasedCompetanceAssessment'
    )?.assessment?.assessment_content?.aiRoleCompetencies || []
  );
  const roleBaseTooltip = roleBaseData.map(({ headingTitle }) => headingTitle.replace('?', '<br /><br />'))
  const progressstyles = useStyles();


  // const ExternalList1 = [
  //   "Versatility in dealing with people, performance or systems equally.",
  //   "Quick learning ability in a wide variety of business areas.",
  //   "Stability, dependability.",
  //   "Over-all very good to excellent business and people management potential.",
  //   "Understanding and communicating with others.",
  //   "Planning and organizing.",
  // ];
  // const ExternalList2 = [
  //   "Versatility in dealing with people, performance or systems equally.",
  //   "Quick learning ability in a wide variety of business areas.",
  //   "Stability, dependability.",
  //   "Over-all very good to excellent business and people management potential.",
  //   "Understanding and communicating with others.",
  //   "Planning and organizing.",
  // ];
  // const ExternalList3 = [
  //   "Decision-making freedom.",
  //   "Multi-faceted challenges.",
  //   "Independence",
  //   "Status and recognition",
  //   "Sense of Belonging",
  // ];
  // const ExternalList4 = [
  //   "Personal identification Motivatorswith personal needs, desires and systems. Understanding how balanced high Scores may impact roles, duties and objective success of a given position.",
  // ];
  // const ExternalList5 = [
  //   "Empathetic Outlook (R)",
  //   "Practical Thinking (R)",
  //   "Systems Judgment(R)",
  //   "Remember that it is OK that some don’t appreciate artistry, balance or harmony and you can serve as a bridge between those who do and don’t see value in such things.",
  // ];
  // const ExternalList6 = [
  //   "An atmosphere where there is an open exchange of ideas with readily available feedback; the sharing of responsibilities and decisions. A role which allows the most complete usage of all three external dimensions of thought.",
  // ];
  // const InternalList1 = [
  //   "Good sense of self ability.",
  //   "Generous, easy going, dependable.",
  //   "Good self awareness.",
  //   "Good role appreciation.",
  //   "Good problem management.",
  //   "Good problem management.",
  // ];
  // const InternalList2 = [
  //   "Trouble with getting easily bored with status in life that fails to challenge all three dimensions of thought personally.",
  //   "Overall level of development in all three core areas.",
  //   "Self assessment.",
  //   "Good role appreciation.Personal commitment.",
  //   "Sense of mission.",
  // ];
  // const InternalList3 = [
  //   "Self improvement",
  //   "Material possessions.",
  //   "Sense of mission.",
  //   "Status and recognition",
  //   "Sense of belonging",
  // ];
  // const InternalList4 = [
  //   "To increase your clarity scores for each of the three dimensions of thought.",
  // ];
  // const InternalList5 = [
  //   "Self esteem (D)",
  //   "Role awareness (D)",
  //   "Self direction (D)",
  // ];
  // const InternalList6 = [
  //   "An atmosphere where there is an open exchange of ideas with readily available feedback; the sharing of responsibilities and decisions. A role which allows the most complete usage of all three external dimensions of thought.",
  // ];

  const slideBoxList = [
    <Slide1 />,
    <Slide2 />,
    <Slide3 />,
    <Slide4 />,
    <Slide5 />,
  ];

  const classes = tabStyleRolBase();

  const optionschart = {
    series: { data: [6, 8, 9, 10, 12, 14] },
    options: {
      noData: {
        text: 'Loading...'
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, w }) {
          return `
          <div class="min-w-[300px] w-[300px]">
            <div class="h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t">
              ${w.config.labels[seriesIndex]} : ${Number(series[seriesIndex]).toFixed(1)}%
            </div>
            <div className='rounded-md tooltip-font-desc' style='padding:0.75rem;background-color:#4D5871;'>
              <div>${w.config.chart.tooltip[seriesIndex]}</div>
            </div>
          </div>`;
        },
      },
      colors: [
        "#9B7210",
        "#CA902C",
        "#AA554C",
        "#4FD1C5",
        "#7ABEEF",
        "#F6AD55",
        "#8B5086",
        "#7AB794",
      ],
      chart: {
        type: "pie",
        tooltip: roleBaseTooltip,
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: [
            "#9B7210",
            "#CA902C",
            "#AA554C",
            "#4FD1C5",
            "#7ABEEF",
            "#F6AD55",
            "#8B5086",
            "#7AB794",
          ],
        },
      },

      legend: {
        show: false,
      },
      labels: roleBasedCompetanceAssessment?.length
        ? roleBasedCompetanceAssessment.map(({ type }) => type)
        : [],
    },
  };

  // let tabColor;
  // if (value === 0) {
  //   tabColor = classes.blue;
  // } else if (value === 1) {
  //   tabColor = classes.orange;
  // } else if (value === 2) {
  //   tabColor = classes.gray;
  // } else if (value === 3) {
  //   tabColor = classes.yellow;
  // } else if (value === 4) {
  //   tabColor = classes.lightBlue;
  // } else {
  //   tabColor = classes.green;
  // }

  const [dimensions, setDimensions] = useState([]);
  const [values, setValues] = useState({ internal: 0, external: 0 });

  const [graphData, setGraphData] = useState([]);
  const [percent, setpercent] = useState("");
  const [assessmentType, setassessmentType] = useState("");
  const getData = () => {
    getByUserId().then((res) => {
      dispatch(updateUser(res.data));
      let temp3 = [];
      let temp4 = 0;
      setDimensions(
        res.data.assessments.filter(
          (i) => i.assessment.assessment_type === "RoleBasedAssessment"
        )[0]?.assessment?.result
      );
      let values1 = 0;
      let values2 = 0;
      // console.log(res.data.assessments)
      setassessmentType(res.data.assessment_type)
      res.data.assessments
        .filter(
          (i) => i.assessment.assessment_type === "RoleBasedAssessment"
        )[0]
        ?.assessment?.result.filter((i) => i.level === "Internal")
        .forEach((item) => {
          values1 += Number(item.value);
        });
      res.data.assessments
        .filter(
          (i) => i.assessment.assessment_type === "RoleBasedAssessment"
        )[0]
        ?.assessment?.result.filter((i) => i.level === "External")
        .forEach((item) => {
          values2 += Number(item.value);
        });
      let values =
        res?.data?.assessments?.length &&
        res.data.assessments.find(
          (i) => i.assessment.assessment_type === 'RoleBasedAssessment'
        )?.assessment?.api_result?.reliability;
      values?.length &&
        setValues({
          internal: values[0] || 0,
          external: values[1] || 0,
        });
      // console.log(data)
      setRoleBasedCompetanceAssessment(
        res?.data?.assessments.filter(
          (i) =>
            i.assessment.assessment_type === "RoleBasedCompetanceAssessment"
        )[0]?.assessment?.result
      );
      res?.data?.assessments
        .filter(
          (i) =>
            i.assessment.assessment_type === "RoleBasedCompetanceAssessment"
        )[0]
        ?.assessment?.result?.forEach((item) => {
          temp3.push(Number(item?.value));
        });
      res?.data?.assessments
        .filter(
          (i) =>
            i.assessment.assessment_type === "RoleBasedCompetanceAssessment"
        )[0]
        ?.assessment?.result?.forEach((item) => {
          temp4 += Number(item?.value);
        });
      setGraphData(temp3);
      setpercent(temp4);
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <SlideBox
        title="About this report"
        componentList={slideBoxList}
        open={openSlideBox}
        setOpen={setOpenSlideBox}
      />
      <InfoBoxes hasIcon={true} title="Decision-making style">
        <p className="text-[#384259] text-base mt-6">
          Have you ever considered how your decision-making style impacts your success and fulfillment in life? Every day, we make countless decisions that shape our personal and professional paths, and research suggests that our decision-making style can significantly impact the quality of those choices.
        </p>
        <p className="text-[#384259] text-base mt-6">
          Scientific research has identified three distinct approaches to decision-making: personal, practical, and analytical. Each style is unique and can be analyzed based on clarity/understanding and bias/attention, critical factors in the study of values.
        </p>

        <div className="flex items-end mt-8">
          <PersonalStyle />

          <p className="text-[18px] font-bold text-pirmaryBlue ml-3">
            Personal style
          </p>
        </div>

        <p className="text-[#384259] text-base mt-4">
          <span className="font-bold">Personal decision-making</span> focuses on relationships and empathy, prioritizing understanding and empathizing with others, building relationships, and fostering interpersonal connections. This style can be instrumental in roles that require building rapport with clients or customers, such as sales or counseling. However, individuals who rely too heavily on personal decision-making may be less focused on achieving concrete results or making objective decisions based on facts and data. As a result, this style may be less effective in roles requiring analytical or practical thinking, such as engineering or finance.
        </p>

        <div className="flex items-end mt-8">
          <PracticalStyle />

          <p className="text-[18px] font-bold text-pirmaryBlue ml-3">
            Practical style
          </p>
        </div>

        <p className="text-[#384259] text-base mt-4">
          <span className="font-bold">Practical decision-making</span> emphasizes efficiency and outcomes, weighing the pros and cons of different options to make the best decision. Individuals who use this style tend to be task-oriented and focused on efficiency, making it instrumental in problem-solving roles like management or logistics. However, individuals who rely too heavily on this style may be less adept at dealing with complex or ambiguous situations that require more creativity or flexibility. This style may also be less effective in roles requiring personal or empathetic decision-making, such as healthcare or social work.
        </p>
        <div className="flex items-end mt-8">
          <AnalyticalStyle />

          <p className="text-[18px] font-bold text-pirmaryBlue ml-3">
            Analytical style
          </p>
        </div>

        <p className="text-[#384259] text-base mt-4 mb-6">
          <span className="font-bold">Analytical decision-making</span> prioritizes data and objectivity, using a data-driven approach to evaluate options and minimize the impact of personal biases and emotions. This style can be instrumental in roles requiring critical thinking, such as research or data analysis. However, individuals who rely too heavily on this style may be less focused on personal relationships or achieving quick results and may need help in situations that require interpersonal skills or emotional intelligence.
        </p>

        <p className="text-[#384259] text-base mt-1 mb-6">
          Although everyone can use all three decision-making styles, we tend to have a preferred one. Understanding your decision-making style and associated behavior can help you identify your strengths and weaknesses and make better choices.
        </p>
        <p className="text-[#384259] text-base mt-1 mb-6">
          In your report, you'll receive a list of capacities that result from your unique combination of these decision-making styles and your thinking dimension. This information can help you leverage your strengths and improve your decision-making in areas where you need more confidence.
        </p>
        <p className="text-[#384259] text-base mt-1 mb-6">
          So, are you ready to gain insight into your decision-making style and take control of your choices? Discover your unique decision-making style and start making better decisions today.
        </p>
      </InfoBoxes>

      <InfoBoxes hasIcon={true}>
        <div className='absolute top-8 right-4 h-8 rounded-full px-3 flex justify-center items-center bg-grayLight text-pirmaryBlue font-bold text-base right-0'>
          {(values?.internal).toFixed(2)} / {(values?.external).toFixed(2)}
        </div>
        <p className="text-2xl font-bold text-pirmaryBlue mb-2">
          Critical thinking
          <InfoIcon
            className="cursor-pointer ml-1 text-secondaryBalck"
            onClick={() => setOpenSlideBox(true)}
          />
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="col-span-1">
            <DimensionOfThoughts className='relative w-[90%] mx-auto' />
          </div>
          <div className="col-span-1 divide-x-2">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="px-6 pt-6 md:border-r border-dashed">
                <p className="font-bold text-lg text-[#8B5086]">External</p>
                {dimensions
                  ?.filter((i) => i.level === "External")
                  .map((item, index) => (
                    <div key={index} className="flex flex-col mt-2">
                      <div className="flex justify-between py-3">
                        <span className="text-base text-pirmaryBlue">
                          {item.type}
                        </span>
                        <span className="text-base font-bold text-pirmaryBlue">
                          {Number(item.value).toFixed(1)}
                        </span>
                      </div>
                      <div className="-mt-2">
                        <LinearProgress
                          className={progressstyles.mid}
                          variant="determinate"
                          value={Number(item.value * 10).toFixed(1)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <div className="px-6 pt-6">
                <p className="font-bold text-lg text-[#7AB794]">Internal</p>
                {dimensions
                  ?.filter((i) => i.level === "Internal")
                  .map((item, index) => (
                    <div key={index} className="flex flex-col mt-2">
                      <div className="flex justify-between py-3">
                        <span className="text-base text-pirmaryBlue">
                          {item.type}
                        </span>
                        <span className="text-base font-bold text-pirmaryBlue">
                          {Number(item.value).toFixed(1)}
                        </span>
                      </div>
                      <div className="-mt-2">
                        <LinearProgress
                          className={progressstyles.low}
                          variant="determinate"
                          value={Number(item.value * 10).toFixed(1)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </InfoBoxes>

      {externalData?.sections?.length && (
        <InfoBoxes hasIcon={true} title='External decision-making pattern'>
          <p className='text-base text-pirmaryBlue my-[24px]'>
            {externalData.sections[0]?.statements || ''}
          </p>

          <div className='grid mt-6 grid-cols-1 gap-6 md:grid-cols-2'>
            {externalData.sections
              .slice(1, externalData.sections.length)
              .map(({ title = '', statements = '' }, index) => (
                <BoxDescriptionInfo
                  key={index}
                  title={title}
                  description=''
                  caption=''
                  list={statements.split('\n')
                  }
                  ToolTip = {true}
                />
              ))}
          </div>
        </InfoBoxes>
      )}

      {internalData?.sections?.length && 
        <InfoBoxes
          hasIcon={true}
          title="Internal decision making pattern - summary"
        >
          <p className='text-base text-pirmaryBlue my-[24px]'>
            {internalData.sections[0]?.statements || ''}
          </p>

          <div className="grid mt-6 grid-cols-1 gap-6 md:grid-cols-2">
            {internalData.sections
              .slice(1, internalData.sections.length)
              .map(({ title = '', statements = '' }, index) => (
                <BoxDescriptionInfo
                  key={index}
                  title={title}
                  description=''
                  caption=''
                  list={statements.split('\n')}
                  ToolTip={true}
                />
              ))}
          </div>
        </InfoBoxes>
      }

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="col-span-1">
          {/* chart place */}
          <InfoBoxes hasIcon={true} title="" containerStyle="h-full">
            <h2 className="text-xl text-pirmaryBlue font-bold text-center mb-1">
              {assessmentType} competencies
            </h2>
            <div id="chart" className="relative flex justify-center">
              <span className="absolute self-center font-bold text-lg">
                {percent && (percent / graphData?.length)?.toFixed(1) + " %"}
              </span>
              <ReactApexChart
                height={340}
                options={optionschart.options}
                series={graphData || [20, 30, 40, 50, 50, 60]}
                type="donut"
              />
            </div>
            <div className="min-h-44 h-[350px] overflow-y-auto">
              {roleBasedCompetanceAssessment?.length > 0 &&
                roleBasedCompetanceAssessment?.map((item, index) => (
                <div
                  key={index}
                  className={`flex flex-wrap text-pirmaryBlue justify-between items-center py-2 mr-3 border-b`}
                >
                  <span className="flex-grow flex justify-start items-center">
                    <div className="">
                      <span
                        style={{
                          backgroundColor: `${optionschart.options.dataLabels.style.colors[index]}`,
                        }}
                        className={`h-3 mr-2 inline-block w-3 rounded-full`}
                      ></span>
                    </div>
                    <span className="text-base leading-5 max-w-fit inline-block">
                      {item?.type}
                    </span>
                  </span>
                  <span className=" font-bold text-pirmaryBlue">
                    {Number(item?.value).toFixed(1) + "%"}
                  </span>
                </div>
              ))}
            </div>
          </InfoBoxes>
        </div>
        <div className="col-span-2">
          <InfoBoxes hasIcon={true} title="Management" containerStyle="h-full">
            <Tabs
              TabIndicatorProps={{style: {backgroundColor: colors[activeItem] }}}
              className={`${classes.root}`}
              value={value}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {roleBaseData?.map(({ title = '' }, index) => (
                <Tab
                  key={index}
                  label={title}
                  name={title}
                  {...a11yProps(index)}
                  sx={{
                    "&.Mui-selected": {
                      borderBottom: `1px solid ${roleBaseColors[index]} !important`,
                      color: `${roleBaseColors[index]} !important`,
                    },
                    "&.MuiTabs-indicator": {
                      backgroundColor: `${roleBaseColors[index]} !important`,
                    },
                    "&.Mui-expanded": {
                      "& .MuiIconButton-root": {
                      color: `${roleBaseColors[index]} !important`,
                      },
                    },
                  }}
                />
              ))}
            </Tabs>
            {roleBaseData?.map((data, index) => (
              <TabPanel value={value} index={index} key={index}>
                <RoleBaseTab data={data} color={roleBaseColors[index]} />
              </TabPanel>
            ))}
            {/* <TabPanel value={value} index={0}>
              <HrMangement value={graphData[0]} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PersonalMotivators value={graphData[1]} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PerformanceManagement value={graphData[2]} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <PlanningOrganizing value={graphData[3]} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ProductionManagement value={graphData[4]} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <SelfManagement value={graphData[5]} />
            </TabPanel> */}
          </InfoBoxes>
        </div>
      </div>
    </>
  );
}

export default RolebaseProfile;
