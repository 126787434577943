import React from 'react';
import ReactApexChart from 'react-apexcharts';
import WhiteBox from '../../../../../sharedComponents/whiteBox/WhiteBox';

const LineChart = ({ data = [] }) => {
  const lineOptions = {
    series: [
      {
        name: 'Applicants',
        data: data.length > 0 ? data.map(({ count }) => count) : [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Product Trends by Month',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: data.length > 0 ? data.map(({ month }) => month) : [],
      },
    },
  };
  return (
    <WhiteBox
      title='New applicants by month'
      titleStyle={{
        fontSize: '24px',
        color: '#384259',
        fontWeight: '400',
      }}
      style={{ margin: '0px', height: '100%' }}
    >
      <div className='mt-5'>
        <ReactApexChart
          options={lineOptions.options}
          series={lineOptions.series}
          type='line'
          height={350}
        />
      </div>
    </WhiteBox>
  );
};

export default LineChart;
