import React from 'react';
import WhiteBox from '../../../../../sharedComponents/whiteBox/WhiteBox';
import SelectField from '../../../../../sharedComponents/selectField/SelectField';
import { useStyle } from '../reuseabelComponent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DarkBtn from '../../../../../sharedComponents/buttons/darkBtn/DarkBtn';
import PurpleBtn from '../../../../../sharedComponents/buttons/purpleBtn/PurpleBtn';
import { allCountry } from '../../../../../../contants/countries';
import {
  getCustomerStripeData,
  saveCustomerStripeData,
} from '../../../../../../../api/hr';

let initialData = {
  name: '',
  address: '',
  city: '',
  country: '',
  zipcode: '',
  mailing_name: '',
  mailing_address: '',
  mailing_city: '',
  mailing_country: '',
  mailing_zipcode: '',
};

function BillingDetailsAndMialingAddress({
  // data,
  // handleChangeData,
  handleNext,
}) {
  const classes = useStyle();
  const [data, setData] = React.useState(initialData);
  const [isSameBilling, setIsSameBilling] = React.useState(false);

  const handleChangeData = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckAddress = () => {
    setData((prevData) => ({
      ...prevData,
      mailing_name: prevData.name,
      mailing_address: prevData.address,
      mailing_city: prevData.city,
      mailing_country: prevData.country,
      mailing_zipcode: prevData.zipcode,
    }));

    setIsSameBilling((prev) => !prev);
  };

  const handleSubmit = async () => {
    try {
      const res = await saveCustomerStripeData(data);
      handleNext();
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomerData = async () => {
    try {
      const res = await getCustomerStripeData();
      if (res?.data) {
        let { address = '', shipping = '', name = '' } = res.data;
        setData({
          name,
          address: address?.line1 || '',
          city: address?.city || '',
          country: address?.country || '',
          zipcode: address?.postal_code || '',
          mailing_name: shipping?.name || '',
          mailing_address: shipping?.address?.line1 || '',
          mailing_city: shipping?.address?.city || '',
          mailing_country: shipping?.address?.country || '',
          mailing_zipcode: shipping?.address?.postal_code || '',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    getCustomerData();
  }, []);

  return (
    <>
      <div className='grid lg:grid-cols-2 grid-cols-1 gap-6 my-10'>
        <WhiteBox title='Billing details' style={{ margin: 0 }}>
          <div className='flex flex-col mb-4'>
            <label
              htmlFor='name'
              className='text-pirmaryBlue text-base font-normal'
            >
              Full name
            </label>
            <input
              type='text'
              name='name'
              value={data.name || ''}
              onChange={handleChangeData}
              id='name'
              placeholder='Full Name'
              className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
            />
          </div>
          <div className='flex flex-col mb-4'>
            <label
              htmlFor='address'
              className='text-pirmaryBlue text-base font-normal'
            >
              Address
            </label>
            <input
              type='text'
              name='address'
              value={data.address || ''}
              onChange={handleChangeData}
              id='address'
              placeholder='Address'
              className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
            />
          </div>

          <div className='flex md:flex-row flex-col items-center w-[100%] mb-4'>
            <div className='flex flex-col my-3 w-[100%] md:pr-3'>
              <label htmlFor='city' className='text-pirmaryBlue'>
                City
              </label>
              <input
                type='text'
                name='city'
                value={data.city || ''}
                onChange={handleChangeData}
                id='city'
                placeholder='City'
                className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
              />
            </div>

            <div className='flex flex-col my-3 w-[100%] md:pl-3'>
              <label htmlFor='zipcode' className='text-pirmaryBlue'>
                Zip code
              </label>
              <input
                type='text'
                name='zipcode'
                value={data.zipcode || ''}
                onChange={handleChangeData}
                id='zipcode'
                placeholder='Zip Code'
                className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
              />
            </div>
          </div>

          <div className='mb-4'>
            <SelectField
              selectItems={allCountry}
              label='Country'
              labelId='country'
              id='country'
              value={data.country}
              onChange={handleChangeData}
              name='country'
              placeholder='Country'
              inputStyle={{ padding: '14px' }}
            />
          </div>

          <FormGroup>
            <FormControlLabel
              checked={isSameBilling}
              onChange={handleCheckAddress}
              control={<Checkbox />}
              name='isSameBilling '
              label='Biiling Address Same As Mailing Address'
              inputProps={{ 'aria-label': 'controlled' }}
              className={classes.checkbox}
            />
          </FormGroup>
        </WhiteBox>
        <WhiteBox title='Mailing address' style={{ margin: 0 }}>
          <div className='flex flex-col mb-4'>
            <label
              htmlFor='mailing_name'
              className='text-pirmaryBlue text-base font-normal'
            >
              Full name
            </label>
            <input
              type='text'
              name='mailing_name'
              value={data.mailing_name || ''}
              onChange={handleChangeData}
              id='mailing_name'
              placeholder='Full Name'
              className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
              disabled={isSameBilling}
            />
          </div>
          <div className='flex flex-col mb-4'>
            <label
              htmlFor='mailing_address'
              className='text-pirmaryBlue text-base font-normal'
            >
              Address
            </label>
            <input
              type='text'
              name='mailing_address'
              value={data.mailing_address || ''}
              onChange={handleChangeData}
              id='mailing_address'
              placeholder='Address'
              className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
              disabled={isSameBilling}
            />
          </div>

          <div className='flex md:flex-row flex-col items-center w-[100%] mb-4'>
            <div className='flex flex-col my-3 w-[100%] md:pr-3'>
              <label htmlFor='mailing_city' className='text-pirmaryBlue'>
                City
              </label>
              <input
                type='text'
                name='mailing_city'
                value={data.mailing_city || ''}
                onChange={handleChangeData}
                id='mailing_city'
                placeholder='City'
                className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
                disabled={isSameBilling}
              />
            </div>

            <div className='flex flex-col my-3 w-[100%] md:pl-3'>
              <label htmlFor='mailing_zipcode' className='text-pirmaryBlue'>
                Zip code
              </label>
              <input
                type='email'
                name='mailing_zipcode'
                value={data.mailing_zipcode || ''}
                onChange={handleChangeData}
                id='mailing_zipcode'
                placeholder='Zip Code'
                className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
                disabled={isSameBilling}
              />
            </div>
          </div>

          <div className={`mb-4 ${isSameBilling && 'cursor-not-allowed'}`}>
            <SelectField
              selectItems={allCountry}
              label='Country'
              labelId='mailing_country'
              id='mailing_country'
              value={data.mailing_country}
              onChange={handleChangeData}
              name='mailing_country'
              placeholder='Country'
              inputStyle={{ padding: '14px' }}
              disabled={isSameBilling}
            />
          </div>
        </WhiteBox>
      </div>
      <div className='flex items-center justify-end'>
        <DarkBtn>Cancel</DarkBtn>
        <PurpleBtn customStyle={{ marginLeft: '24px' }} onClick={handleSubmit}>
          Next
        </PurpleBtn>
      </div>
    </>
  );
}

export default BillingDetailsAndMialingAddress;
