import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function CircleProgressBar({percentage, pathColor}) {

  const pthcolor = pathColor ? pathColor : "#7AB794"
  return (
    <div className="">
      <div style={{ width: 100, height: 100 }}>
        <CircularProgressbar
          value={percentage}
          strokeWidth={5}
          text={`${percentage}%`}
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",

            // Text size
            textSize: "16px",

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: pthcolor,
            textColor: "#384259",
            trailColor: "#F5F5F5",
            backgroundColor: "red",
          })}
        />
      </div>
    </div>
  );
}

export default CircleProgressBar;
