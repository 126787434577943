import React from 'react';
import { toast } from 'react-toastify';
import { resetPassword } from '../../../../api/jc';
import SuccessBtn from '../../sharedComponents/buttons/successBtn/SuccessBtn';
import Modal from '../../sharedComponents/modal/Modal';
import { PasswordField } from '../login/reusableComponents';

const ResetPassword = ({ resetPassword: token, setResetPassword }) => {
  const [data, setData] = React.useState({
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const changePassword = async () => {
    const error = formValidate(data);
    if (!error) {
      try {
        const res = await resetPassword({
          password: data.password,
          token,
        });
        setResetPassword(false);
        if (res.status === 200) {
          toast.success('Password changed successfully!');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const formValidate = (values) => {
    const myError = 0;
    const regularExpressionPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!values.password) {
      toast.error('Password is required.');
      return 1;
    } else if (values.password.length < 8) {
      toast.error('Password must be more than 8 characters.');
      return 1;
    } else if (!regularExpressionPassword.test(values.password)) {
      toast.error("The password you create must be a combination of alphanumeric characters, which includes both letters (A-Z, a-z) and numbers (0-9). In addition, at least one special character (such as !, @, #, $, %, &, *, etc.) should be included. Please ensure you adhere to these guidelines when setting your password.");
      return 1;
    }
    if (!values.confirmPassword) {
      toast.error('Confirm password is required.');
      return 1;
    } else if (values.confirmPassword !== values.password) {
      toast.error('Confirm password is not correct.');
      return 1;
    }
    return myError;
  };

  return (
    <>
      <Modal
        title='Choose a new password'
        open={token ? true : false}
        setOpen={setResetPassword}
        mdUpWidth={550}
      >
        <PasswordField
          isPassword={showPassword}
          setIsPassword={setShowPassword}
          name={'password'}
          value={data?.password || ''}
          label={'Password'}
          labelFor={'password'}
          id={'password'}
          onChange={(e) => {
            setData((prevData) => ({
              ...prevData,
              password: e.target.value,
            }));
          }}
          placeholder={'Enter your password'}
          className={"focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"}
        />
        <PasswordField
          isPassword={showConfirmPassword}
          setIsPassword={setShowConfirmPassword}
          name={'confirm-password'}
          value={data?.confirmPassword || ''}
          label={'Confirm password'}
          labelFor={'confirm-password'}
          id={'confirm-password'}
          onChange={(e) => {
            setData((prevData) => ({
              ...prevData,
              confirmPassword: e.target.value,
            }));
          }}
          placeholder={'Confirm password'}
          className={"focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"}
        />
        <SuccessBtn onClick={changePassword} className='mt-5' type='submit'>
          <span className='font-bold'>Submit</span>
        </SuccessBtn>
      </Modal>
    </>
  );
};

export default ResetPassword;
