import React from "react";
import Avatartest2 from "../../../../assets/avatartest.svg";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import SuccessButton from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import OutlineSuccessBtn from "../../../sharedComponents/buttons/outlineSuccessBtn/OutlineSuccessBtn";
import DangerButton from "../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import DeleteBtn from "../../../sharedComponents/buttons/dnagerBtn/DnagerBtn";
import PasswordField from "../../../sharedComponents/PasswordField/PasswordField";
import { toast } from "react-toastify";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useStyle, CompanyCard, searchList } from "../reusableComponent";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Sap } from "../../../../assets/sap.svg";
import { ReactComponent as Starbucks } from "../../../../assets/starbucksLogo.svg";
import { changePassword, postJCProfile , deleteProfilePhoto, deactivateAccount, deleteAccount } from "../../../../../api/jc";
import { Avatar } from "@mui/material";
import Modal from "../../../sharedComponents/modal/Modal";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";
import DangerLightBtn from "../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";

const filter = createFilterOptions();
function AccountSettings({ allData, getData }) {
  // const dispatch = useDispatch()

  let initData = {
    currentPassword: "",
    newPssword: "",
    confirmNewPssword: "",
  }
  const [data, setData] = React.useState(initData);
  const resetForm = () => setData(initData)

  const [open, setOpen] = React.useState(false);
  const [deactivateReason, setDeactivateReason] = React.useState("");
  const [restrictAllCompanies, setRestrictAllCompanies] = React.useState(false);
  const [isSearchShown, setIsSearchShown] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [image, setImage] = React.useState('');
  const navigate = useNavigate();

  const handleChangeData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
  };
  const handleDeactive = (e) => {
    const value = e.target.value;
    setDeactivateReason(value);
  };

  const formValidate = (values) => {
    const myError = 0;
    const regularExpressionPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!values.currentPassword) {
      toast.error("Current password is required.");
      return 1;
    }
    if (!values.newPssword) {
      toast.error("New Pssword is required.");
      return 1;
    } else if (values.newPssword.length < 8) {
      toast.error("Password must be more than 8 characters.");
      return 1;
    } else if (!regularExpressionPassword.test(values.newPssword)) {
      toast.error("The password you create must be a combination of alphanumeric characters, which includes both letters (A-Z, a-z) and numbers (0-9). In addition, at least one special character (such as !, @, #, $, %, &, *, etc.) should be included. Please ensure you adhere to these guidelines when setting your password.");
      return 1;
    } else if (values.newPssword === values.currentPassword) {
      toast.error("New password and current password can not be the same.");
      return 1;
    }
    if (!values.confirmNewPssword) {
      toast.error("Confirm password is required.");
      return 1;
    } else if (values.confirmNewPssword !== values.newPssword) {
      toast.error("Confirm password and New passwordis is not the same.");
      return 1;
    }
    return myError;
  };

  const formDeactiveValidate = (deactivateReason) => {
    const myError = 0;
    if (!deactivateReason) {
      toast.error("Reason is required.");
      return 1;
    }
    return myError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = formValidate(data);
    if (!error) {
      let temp = {
        password: data.currentPassword,
        newPassword: data.newPssword
      }
      changePassword(temp).then((res) => {
        console.log(res.data)
        resetForm()
        toast.success(res.data?.message || 'Password changed successfully')
      })
      console.log(data)
    }
  };

  const handleDeactiveSubmit = (e) => {
    e.preventDefault();
    const error = formDeactiveValidate(deactivateReason);
    if (!error) {
      let temp = {
        reason: deactivateReason
      }
      deactivateAccount(temp).then((res) => {
        console.log(res.data)
        toast.success(res.data?.message || 'User account deactived successfully')
        localStorage.removeItem('access')
        navigate('/coaching/login')
      })
      console.log(data)
    }
  }

  const deleteSubmit = (e) =>{
    e.preventDefault();
    deleteAccount({}).then((res) => {
      console.log(res.data)
      toast.success(res.data?.message || 'User account deleted successfully')
      localStorage.removeItem('access')
      navigate('/coaching/login')
    })
  }

  React.useEffect(() => {
    getData();
  }, []);

  const handleChangeImage = (e) => {
    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImage({
        file: files[0],
        render: e.target.result
      });
    };
  };

  const deleteImage = async () => {
    try {
      await deleteProfilePhoto()
      setImage('');
      getData()
    } catch (error) {
      console.error(error);
    }
  };

  const uploadPhoto = async () => {
    try {
      if(image?.file){
        let fd = new FormData();
        fd.append('body', '{}')
        fd.append("file", image.file);
        await postJCProfile(fd)
        toast.success('Profile photo updated!')
        getData()
      } else toast.info('Please select an image first!')
    } catch (error) {
      console.error(error);
    }
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#C6E7D4",
        color: "#166534",
        fontSize: '35px',
        fontWeight: "700",
        width: "6rem",
        height: "6rem",
      },
      children: getLetterFromTheName(name),
    };
  }

  const getLetterFromTheName = (fullName) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if(index === 0 || index === allNames.length - 1){
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  const classes = useStyle();

  React.useEffect(() => {
    allData?.candidate_picture_upload_path &&
      setImage({
        render: allData.candidate_picture_upload_path,
      });    
  }, [allData]);

  return (
    <>
      <h2 className="text-[24px] text-[#384259] font-bold mt-8 mb-6">
        Profile details
      </h2>
      <div className=" flex md:flex-row flex-col items-center w-[100%] ">
        <div
          className="w-[105px] h-[105px] rounded-full flex 
        justify-center items-center md:mr-[24px] mb-6 mb:my-0"
        >
          <span className="min-w-[95px] min-h-[95px] flex justify-center items-center relative ">
            {image?.render ? (
              <img src={image?.render || Avatartest2} alt="" className="mx-auto" />
            ) : (
              <Avatar {...stringAvatar(`${allData?.first_name || ''} ${allData?.last_name || ''}`)} />
            )}
            <label
              htmlFor="editProfile"
              className="flex justify-center items-center cursor-pointer 
            w-[31px] h-[31px] rounded-full shadow bg-[#FFFFFF] absolute top-[-5px] right-[-5px]"
            >
              <ModeEditOutlineOutlinedIcon sx={{ color: "#828282" }} />
            </label>
            <input
              type='file'
              id='editProfile'
              onChange={handleChangeImage}
              className='hidden'
            />
          </span>
        </div>
        <div className="">
          <div className="flex justify-center flex-wrap md:justify-start items-center my-2 md:my-0">
            <SuccessButton
              customStyle={{ marginRight: "16px", marginTop: "5px" }}
              onClick={uploadPhoto}
            >
              Upload profile photo
            </SuccessButton>
            <DangerButton onClick={deleteImage} customStyle={{ marginTop: "5px" }}>
              Delete{" "}
            </DangerButton>
          </div>
          <p className="text-[#828282] text-[14px] font-normal mt-[16px]">
            * Image size should be at least 512px X 512px and max size of 500kb,
            allowed files .png and .jpg{" "}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap">
        <p className="w-[180px] text-base text-[#828282]">Username</p>
        <p className="text-base text-[#384259]">{allData.first_name + " " + allData.last_name}</p>
      </div>
      <div className="flex flex-wrap mt-4 mb-12">
        <p className="w-[180px] text-base text-[#828282]">Email Address</p>
        <div className="text-base text-[#384259] flex flex-wrap ">
          <p className="mr-5">{allData.email_id}</p>
          <p className="text-sm text-[#7AB794]  ">
            <span>
              <CheckOutlinedIcon
                sx={{ color: "#7AB794", fontSize: "20px", marginRight: "7px" }}
              />
            </span>
            <span>Verified</span>
          </p>
        </div>
      </div>

      <div className="divide-x-2 grid grid-cols-9 ">
        <div className="lg:col-start-1 lg:col-end-6 col-start-1 col-end-10 p-[20px] pt-0 2xl:p-[40px] 2xl:pt-0">
          <h2 className="text-[24px] text-[#384259] font-bold mb-6">
            Change password
          </h2>

          <form action="#" className="" onSubmit={handleSubmit}>
            <div className="flex flex-col lg:flex-row lg:items-center justify-between mb-6">
              <label htmlFor="currentPassword" className="lg:min-w-[200px]">
                Current password
              </label>
              <PasswordField
                name={"currentPassword"}
                value={data.currentPassword || ""}
                label={""}
                labelFor={""}
                id={"currentPassword"}
                onChange={handleChangeData}
                placeholder={"Enter your password"}
                style={{ width: "100%" }}
                className={"focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"}
              />
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center justify-between mb-6">
              <label htmlFor="newPssword" className="lg:min-w-[200px]">
                New password
              </label>
              <PasswordField
                name={"newPssword"}
                value={data.newPssword || ""}
                label={""}
                labelFor={""}
                id={"newPssword"}
                onChange={handleChangeData}
                placeholder={"Enter your password"}
                style={{ width: "100%" }}
                className={"focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"}
              />
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center justify-between mb-6">
              <label htmlFor="confirmNewPssword" className="lg:min-w-[200px]">
                Confirm new password
              </label>
              <PasswordField
                name={"confirmNewPssword"}
                value={data.confirmNewPssword || ""}
                label={""}
                labelFor={""}
                id={"confirmNewPssword"}
                onChange={handleChangeData}
                placeholder={"Enter your password"}
                style={{ width: "100%" }}
                className={"focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"}
              />
            </div>

            <div className="flex justify-end items-center mt-[25px]">
              <SuccessButton type="submit">Update Password</SuccessButton>
            </div>
          </form>

          <div className="mt-12">
            <h2 className="text-[24px] font-bold text-[#384259]">
              Deactivate account
            </h2>
            <p className="text-[14px] text-[#828282] mt-2 mb-9">
              You can permanently delete or temporarily freeze your account.
            </p>
            
            <form action="" onSubmit={handleDeactiveSubmit}>
              <div className="flex flex-col lg:flex-row lg:items-center justify-between mb-6">
                <label htmlFor="deactivateAccount" className="lg:min-w-[200px]">
                  Deactivate account
                </label>
                <input
                  type="text"
                  name="deactivateAccount"
                  value={deactivateReason || ""}
                  onChange={handleDeactive}
                  id="deactivateAccount"
                  placeholder="Select reason for deactivate account"
                  className="focus:outline-none border-2 border-solid border-grayLight p-[12px] w-full rounded"
                />
              </div>
              <div className="flex items-center justify-end">
                <SuccessButton type="submit">Deactivate</SuccessButton>
              </div>
            </form>
          </div>

          <div className="flex items-center justify-between mt-8">
            <div className="flex flex-col">
              <h5 className="text-base text-[#384259] mb-1">Delete account</h5>
              <p className="text-sm text-[#828282] max-w-[400px]">
                This account will no longer be available, and all your saved
                data will be permanently deleted.
              </p>
            </div>
            <DeleteBtn onClick={() => setOpen(true)}>Delete</DeleteBtn>
          </div>
        </div>

        <div className="lg:col-start-6 lg:col-end-10 col-start-1 col-end-10 p-[20px] pt-0 2xl:p-[40px] 2xl:pt-0">
          {/* <h2 className="text-[24px] text-[#384259] font-bold">
            Restricted companies
          </h2>
          <p className="text-[14px] text-[#828282] mb-6">
            List out companies that you don’t want to be searched or matched
            with
          </p>
          <FormGroup>
            <FormControlLabel
              checked={restrictAllCompanies}
              onChange={() => setRestrictAllCompanies((prev) => !prev)}
              control={<Checkbox />}
              name="humanititesAndLanguage"
              label="Restrict all companies from searching your profile"
              inputProps={{ "aria-label": "controlled" }}
              className={classes.checkbox}
            />
          </FormGroup>

          <div className="mt-[50px] pb-0 relative">
            <div className="divide-y-2 overflow-y-auto h-[470px] pb-16">
              <CompanyCard img={<Sap />} name="Northwest Consultancy" />
              <CompanyCard img={<Starbucks />} name="Starbucks" />


              <div className={`py-3 ${isSearchShown ? "" : "hidden"}`}>
                <Autocomplete
                  className={classes.search}
                  value={value}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setValue({
                        title: newValue,
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setValue({
                        title: newValue.inputValue,
                      });
                    } else {
                      setValue(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.title
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={searchList}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.el}</li>
                  )}
                  sx={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label="Search a company" />
                  )}
                />
              </div>
            </div>

            <div className="absolute bottom-0 right-2 bg-white w-full flex justify-end">
              <OutlineSuccessBtn onClick={() => setIsSearchShown(true)}>Add</OutlineSuccessBtn>
            </div>
          </div> */}
        </div>
      </div>
      
      <Modal title='Delete Account' mdUpWidth={380} open={open} setOpen={setOpen}>
        <p className='text-base text-[#384259] my-4'>
          Are you sure to delete this Account?
        </p>
        <div className='flex items-center justify-between'>
          <DangerLightBtn onClick={() => setOpen(false)}>No</DangerLightBtn>
          <SuccessBtn onClick={deleteSubmit}>Yes</SuccessBtn>
        </div>
      </Modal>
    </>
  );
}



export default AccountSettings;
