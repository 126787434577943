import React from "react";
import Modal from "../../../../../sharedComponents/modal/Modal";
import PurpleBtn from "../../../../../sharedComponents/buttons/purpleBtn/PurpleBtn";
import FullReportModal from "./FullReport";
import LowComfortProfile from "../sharedComponent/LowComfortProfile";

function LowComfort({ open, setOpen, compatibility, colors }) {
  const [fullReport, setFullReport] = React.useState(false);
  return (
    <>
      <Modal
        mdUpWidth={950}
        bgColorTitle={colors.bg}
        textColorTitle={"#384259"}
        title={compatibility.preferred_behavior_compatibility}
        open={open}
        setOpen={setOpen}
        bgColorContent={"#F8F9FA"}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="col-span-1">
            <LowComfortProfile profile={compatibility.a} />
          </div>
          <div className="col-span-1">
            <LowComfortProfile profile={compatibility.b} />
          </div>
        </div>

        <div className="flex justify-center items-center mt-6">
          <PurpleBtn
            onClick={() => setFullReport(true)}
            customStyle={{ backgroundColor: colors.border, color: "#ffffff" }}
          >
            Full Report
          </PurpleBtn>
        </div>
      </Modal>
      <FullReportModal
        open={fullReport}
        setOpen={setFullReport}
        compatibility={compatibility}
        colors={colors}
      />
    </>
  );
}

export default LowComfort;
