import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { togglePremiumModal } from '../../../../redux/actions/premium';
import Modal from '../../sharedComponents/modal/Modal';
import ChangePlan from '../settingsProfile/tabs/Subscription/ChangePlan';
import ceoImage from '../../../assets/ceo.png';
import alexisImage from '../../../assets/Alexis.png';
import ellipse from '../../../assets/Ellipse.png';

const PremiumModal = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.premiumModalReducer);
  const setOpen = () => {
    dispatch(togglePremiumModal());
  };
  // const [open, setOpen] = React.useState(true);
  return (
    <Modal
      title='Premium'
      open={open}
      setOpen={setOpen}
      mdUpWidth={1050}
      style={{ maxWidth: '550px', width: '100%' }}
    >
      <div className='flex flex-col-reverse flex-col md:flex-row'>
        <div className='flex-1 flex flex-col justify-between md:mx-5 mt-5'>
          <div>
            <span className='text-success text-[18px] px-2 py-1 font-bold border-success border rounded-2xl'>
              Premium
            </span>
            <div className='text-[38px] text-center text-pirmaryBlue my-5'>
              <span className='font-bold'>Experience the</span>&nbsp;
              <span className='font-thin'>Full Power</span>&nbsp;
              <span className='font-bold'>of TRIPA</span>
            </div>
            <p className='text-[17px] text-center text-pirmaryBlue'>
              "A fantastic tool to explore the true potential of employees!
              Straightforward online test, which I took myself. The assessments
              and diagnostics helped me to determine the perfect role fit for my
              existing and new employees."
            </p>
            <div className='flex gap-x-1 justify-center items-center mt-5'>
              <img src={ellipse} className='w-[50px]' />
              <img src={ceoImage} alt='Ceo Image' className='rounded-full' />
              <div>
                <div className='text-[16px] text-pirmaryBlue font-bold'>
                  Martin Mitošinka
                </div>
                <div className='text-[12px] text-secondaryBalck'>
                  CEO, CTO at FREE company
                </div>
              </div>
            </div>        
          </div>
        </div>
        <div className='flex-1'>
          <ChangePlan setIsPlanPage={setOpen} />
        </div>
      </div>
    </Modal>
  );
};

export default PremiumModal;
