import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { postJCProfile } from "../../../../../api/jc";
import DangerLightBtn from "../../../sharedComponents/buttons/dangerLightBtn/DangerLightBtn";
import SuccessBtn from "../../../sharedComponents/buttons/successBtn/SuccessBtn";

export default function Awards({ selected, setSelected, all, handleReload }) {
  const [value, setValue] = useState("");

  const handleSubmit = () => {
    let help = [...all];
    // let helpIndex = all.findIndex((item) => item == selected);

    if (value !== "") {
      if (selected > -1) {
        help[selected] = value;
        let item = { awards: help };
        let fd = new FormData();
        fd.append("body", JSON.stringify(item));
        postJCProfile(fd).then((res) => {
          handleReload();
          console.log(res.data);
          toast.success("Updated.");
        });
      } else {
        help.push(value);
        console.log(help);
        let fd = new FormData();
        fd.append("body", JSON.stringify({ awards: help }));
        postJCProfile(fd).then((res) => {
          console.log(res.data);
          handleReload();
          toast.success("Updated.");
        });
      }
    } else {
      toast.warn("Please type an award.");
    }
  };

  console.log(selected);
  const handleDelete = () => {
    if (selected > -1) {
      let help = [...all];
      help.splice(selected, 1);
      let fd = new FormData();
      fd.append("body", JSON.stringify({ awards: help }));
      postJCProfile(fd).then((res) => {
        handleReload();
        toast.success("successfully removed.");
        console.log(res.data);
      });
    }
  };

  useEffect(() => {
    if (selected > -1) setValue(all[selected]);
  }, [selected]);

  useEffect(() => {
    return () => {
      setSelected(null);
    };
  }, []);
  return (
    <>
      <div className="flex flex-col">
        <label className="text-base text-pirmaryBlue">Award *</label>
        <input
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          type="text"
          placeHolder="Enter award"
          className="w-full rounded-md border text-sm text-pirmaryBlue border-grayLight h-11 px-2 focus:outline-none"
        />
      </div>
      <div className="mt-4 col-span-2 flex justify-between">
        <div>
          {selected > -1 && (
            <DangerLightBtn onClick={handleDelete}>
              <span className="font-bold">Delete</span>
            </DangerLightBtn>
          )}
        </div>
        <SuccessBtn onClick={handleSubmit} type="submit">
          <span className="font-bold">
            {selected > -1 ? "Update award" : "Add award"}
          </span>
        </SuccessBtn>
      </div>
    </>
  );
}
