import React from "react";
import { ReactComponent as ImgSlide2 } from "../../../../../assets/PurposeStatement2.svg";

function Slide1() {
  return (
    <div className="flex items-strat gap-6">
      <div>
        <div className="w-[65px] h-[65px]">
          <ImgSlide2 />
        </div>
      </div>

      <div className="">
        <h5 className="text-lg text-pirmaryBlue font-bold mb-4">
          Four simple steps to start with your PPS
        </h5>
        <p className="text-base text-pirmaryBlue mb-6">
          <span className="font-bold">1.</span> You can begin with the end in mind to reflect on your core purpose, vision, core values, beliefs, and the person you need to become to achieve your objective.
          
          <ul className="list-disc pl-10 mb-6 text-base text-pirmaryBlue">
            <li>What do you want to achieve in life?</li>
            <li>What is your vision for your future self?</li>
            <li>What are your core values and beliefs?</li>
            <li>Whom do you need to become to achieve your objectives?</li>
          </ul>
        </p>
        <p className="text-base text-pirmaryBlue mb-6">
          <span className="font-bold">2.</span> Have clarity in your purpose statement, as it affects everything else in your life.

          <ul className="list-disc pl-10 mb-6 text-base text-pirmaryBlue">
            <li>What is the main message or theme of your purpose statement?</li>
            <li>How does your purpose statement align with your goals and values?</li>
            <li>Is your purpose statement clear and concise?</li>
            <li>How will your purpose statement guide your decisions and actions?</li>
          </ul>
        </p>
        <p className="text-base text-pirmaryBlue mb-6">
          <span className="font-bold">3.</span> When you write your PPS, you can start with a rough draft that you can refine later.

          <ul className="list-disc pl-10 mb-6 text-base text-pirmaryBlue">
            <li>What specific time frame do you want to give yourself to complete your PPS?</li>
            <li>Can you start with a rough draft and refine it later?</li>
            <li>Are there any specific prompts or questions that resonate with you and can help you get started?</li>
          </ul>
        </p>
        <p className="text-base text-pirmaryBlue mb-6">
          <span className="font-bold">4.</span> Ask yourself the right questions to help you get started, and don't overanalyze your first draft. Instead, write in the present tense and focus on your intentions and personal mark.

          <ul className="list-disc pl-10 mb-6 text-base text-pirmaryBlue">
            <li>What are the most important things in your life?</li>
            <li>What is your definition of success, and how does it relate to your purpose statement?</li>
            <li>Who are the people or role models that inspire you, and why?</li>
            <li>What are the unique talents, skills, and strengths you can bring to the world?</li>
            <li>How can you positively impact the people and community around you?</li>
          </ul>
        </p>

        <p className="text-base text-pirmaryBlue mb-6">
          Remember that your PPS is a work in progress that should inspire you, not impress others.{" "}
        </p>

      </div>
    </div>
  );
}

export default Slide1;
