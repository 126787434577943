import React from "react";

function HorizontalProfileInfo({ src, alt, name, subName }) {
  return (
    <div className="flex flex-wrap items-center mb-4">
      <div
        className="w-[55px] h-[55px] rounded-full border-solid"
        style={{ overflow: "hidden" }}
      >
        <img src={src} alt={alt} className="w-full h-full" />
      </div>
      <div className="flex flex-col ml-3">
        <h5 className="text-[18px] text-[#384259] font-bold">{name}</h5>
        <p className="text-[16px] text-pirmaryBlue">{subName}</p>
      </div>
    </div>
  );
}

export default HorizontalProfileInfo;
