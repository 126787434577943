import React from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../sharedComponents/footer/footer";
import Header from "../../sharedComponents/header/header";
import WhiteBox from "../../sharedComponents/whiteBox/WhiteBox";
import Modal from "../../sharedComponents/modal/Modal";
import SuccessBtn from "../../sharedComponents/buttons/successBtn/SuccessBtn";
import SelectField from "../../sharedComponents/selectField/SelectField";
import { ReactComponent as TripaLogo } from "../../../assets/logo.svg";
import { useStyle, formValidate } from "./reuseableComponent";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { signUpApiHR } from "../../../../api/hr";
import { allCountry } from "../../../contants/countries";
import { industries } from "../../../contants/industries";

function CompleteYourProfile() {
  const { state } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [profileData, setProfileData] = React.useState({
    firstName: "",
    lastName: "",
    company_name: "",
    job_title: "",
    industry: "",
    number_of_employee: "",
    country: "",
    city: "",
    leadership: true,
    management: false,
    salesManagement: false,
    financialServices: false,
    informationTechnology: false,
    sales: false,
    customerServices: false,
  });

  const handleProfileData = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (event.target.type === "checkbox") {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: event.target.checked,
      }));
    } else {
      setProfileData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    const error = formValidate(profileData);

    if (!error) {
      let data = {
        full_name: profileData.firstName + " " + profileData.lastName,
        ...profileData,
        ...state.data,
      };
      signUpApiHR(data, state.refferal).then((res) => {
        setOpen(true);
      });
      // e.target.nextElementSibling.click();
    }
  };

  const classes = useStyle();

  return (
    <>
      <Header />
      <WhiteBox
        title="Complete your profile &#9996;"
        style={{ width: "100%", maxWidth: "700px", margin: "70px auto" }}
      >
        <div className="flex md:flex-row flex-col items-center w-[100%]">
          <div className="flex flex-col my-3 w-[100%] md:pr-5">
            <label htmlFor="firstName" className="text-pirmaryBlue text-base">
              First Name *
            </label>
            <input
              type="text"
              name="firstName"
              value={profileData.firstName || ""}
              onChange={handleProfileData}
              id="firstName"
              placeholder="Enter first name"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>

          <div className="flex flex-col my-3 w-[100%] md:pl-5">
            <label htmlFor="lastName" className="text-pirmaryBlue text-base">
              Last Name *
            </label>
            <input
              type="text"
              name="lastName"
              value={profileData.lastName || ""}
              onChange={handleProfileData}
              id="lastName"
              placeholder="Enter last name"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
        </div>

        <div className="flex md:flex-row flex-col items-center w-[100%]">
          <div className="flex flex-col my-3 w-[100%] md:pr-5">
            <label
              htmlFor="company_name"
              className="text-pirmaryBlue text-base"
            >
              Company Name *
            </label>
            <input
              type="text"
              name="company_name"
              value={profileData.company_name || ""}
              onChange={handleProfileData}
              id="company_name"
              placeholder="Enter company name"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>

          <div className="flex flex-col my-3 w-[100%] md:pl-5">
            <label htmlFor="job_title" className="text-pirmaryBlue text-base">
              Job Title *
            </label>
            <input
              type="text"
              name="job_title"
              value={profileData.job_title || ""}
              onChange={handleProfileData}
              id="job_title"
              placeholder="Enter job title"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
        </div>

        <div className="flex md:flex-row flex-col items-center w-[100%]">
          <div className="flex flex-col my-3 w-[100%] md:pr-5">
            <div className="flex flex-col my-4">
              <SelectField
                onChange={handleProfileData}
                selectItems={industries}
                label="Industry"
                labelId="industry"
                id="industry"
                value={profileData.industry}
                name="industry"
                placeholder="Select Industry"
                inputStyle={{ padding: "14px" }}
                required
              />
            </div>
          </div>

          <div className="flex flex-col my-3 w-[100%] md:pl-5">
            <div className="flex flex-col my-4">
              <SelectField
                onChange={handleProfileData}
                selectItems={["1 - 10","11 - 49", "50 - 199", "200 - 500", "501 - 1000", "1000+"]}
                label="Number Employees"
                labelId="number_of_employee"
                id="number_of_employee"
                value={profileData.number_of_employee}
                name="number_of_employee"
                placeholder="Select no. of employees"
                inputStyle={{ padding: "14px" }}
                required
              />
            </div>
          </div>
        </div>

        <div className="flex md:flex-row flex-col items-center w-[100%]">
          <div className="flex flex-col my-3 w-[100%] md:pr-5">
            <div className="flex flex-col my-4">
              <SelectField
                onChange={handleProfileData}
                selectItems={allCountry}
                label="Country"
                labelId="Country"
                id="country"
                value={profileData.country}
                name="country"
                placeholder="Select country"
                inputStyle={{ padding: "14px" }}
                required
              />
            </div>
          </div>

          <div className="flex flex-col my-3 w-[100%] md:pl-5">
            <label htmlFor="city" className="text-pirmaryBlue text-base">
              City *
            </label>
            <input
              type="text"
              name="city"
              value={profileData.city || ""}
              onChange={handleProfileData}
              id="city"
              placeholder="Enter city"
              className="focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded"
            />
          </div>
        </div>

        <h5 className="text-[#384259] text-base font-normal mt-[16px] mb-[5px]">
          Tell us which role-based competencies you would like to assess your workforce or find your ideal candidate.
        </h5>
        <p className="text-[#828282] text-[14px] ">
          Would you like us to add a role-based competency not listed? No worries; we can help you once you finish completing your profile. Please email us at <a
            href="mailto:info@tripa.ai"
            className="text-success hover:underline"
          >
            info@tripa.ai
          </a>
          .
        </p>

        <div className="w-full h-[200px] overflow-y-auto my-4">
          <FormGroup>
            <FormControlLabel
              checked={profileData.leadership}
              onChange={handleProfileData}
              control={<Checkbox />}
              name="leadership"
              label="Leadership"
              inputProps={{ "aria-label": "controlled" }}
              className={classes.checkbox}
            />
            <FormControlLabel
              checked={profileData.management}
              onChange={handleProfileData}
              control={<Checkbox />}
              name="management"
              label="Management"
              inputProps={{ "aria-label": "controlled" }}
              className={classes.checkbox}
            />
            <FormControlLabel
              checked={profileData.salesManagement}
              onChange={handleProfileData}
              control={<Checkbox />}
              name="salesManagement"
              label="Sales management"
              inputProps={{ "aria-label": "controlled" }}
              className={classes.checkbox}
            />
            <FormControlLabel
              checked={profileData.financialServices}
              onChange={handleProfileData}
              control={<Checkbox />}
              name="financialServices"
              label="Financial services"
              inputProps={{ "aria-label": "controlled" }}
              className={classes.checkbox}
            />
            <FormControlLabel
              checked={profileData.informationTechnology}
              onChange={handleProfileData}
              control={<Checkbox />}
              name="informationTechnology"
              label="Information technology"
              inputProps={{ "aria-label": "controlled" }}
              className={classes.checkbox}
            />
            <FormControlLabel
              checked={profileData.sales}
              onChange={handleProfileData}
              control={<Checkbox />}
              name="sales"
              label="Sales"
              inputProps={{ "aria-label": "controlled" }}
              className={classes.checkbox}
            />
            <FormControlLabel
              checked={profileData.customerServices}
              onChange={handleProfileData}
              control={<Checkbox />}
              name="customerServices"
              label="Customer services"
              inputProps={{ "aria-label": "controlled" }}
              className={classes.checkbox}
            />
          </FormGroup>
        </div>
        <div className="w-full flex items-center justify-end">
          <Button
            type="submit"
            variant="contained"
            color="success"
            className={classes.completeSignupBtn}
            onClick={handleSubmit}
            endIcon={
              <ArrowRightAltIcon
                sx={{
                  "&.MuiSvgIcon-root": {
                    fontSize: "30px",
                    color: "white",
                  },
                }}
              />
            }
          >
            Complete Signup
          </Button>
          <Link to="/business/dashboard"></Link>
        </div>
      </WhiteBox>

      <Modal
        title="Email verification"
        open={open}
        setOpen={setOpen}
        mdUpWidth={550}
        style={{ maxWidth: "550px", width: "100%" }}
      >
        <div className="flex justify-center mb-5">
          <TripaLogo className="h-9 w-28"/>
        </div>
        <p className="text-base text-pirmaryBlue mb-[10px]">
          An email was sent to {state?.data?.email_address || ""} click on the
          link to confirm
        </p>
        <p className="text-[14px] text-[#828282] mb-[35px]">
          Don’t see the email from no-reply@tripa.ai check your spam or junk inbox.
        </p>
        <Link to="/business/login" className="flex justify-center">
          <SuccessBtn> Login</SuccessBtn>
        </Link>
      </Modal>
      <Footer />
    </>
  );
}

export default CompleteYourProfile;
