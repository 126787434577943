import React from 'react';
import Modal from '../../../../../sharedComponents/modal/Modal';
import SelectField from '../../../../../sharedComponents/selectField/SelectField';
import SuccessBtn from '../../../../../sharedComponents/buttons/successBtn/SuccessBtn';
import { postHRPool } from '../../../../../../../api/hr';
import datas from "../../../../../../contants/cities.json"

function UpdateCandidate({ open, setOpen, jobReq, getAllPools, getJobIds }) {
  const [jobIds, setJobIds] = React.useState([]);
  const [data, setData] = React.useState({
    candidate_pool_name: '',
    city: '',
    country: '',
    job_requisition: '',
    job_id: '',
  });

  const getJobIdsByTitle = async (title, data) => {
    const allIds = await getJobIds(title);
    await setJobIds(allIds);
    data && setData(data);
  };

  const handleChangeData = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((prevData) => ({ ...prevData, [name]: value }));
    if (name === 'job_requisition') {
      getJobIdsByTitle(value);
    }
  };

  const handleUpdate = async () => {
    try {
      await postHRPool({ ...data, id: data._id });
      getAllPools();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (open) {
      getJobIdsByTitle(open.job_requisition, open);
    }
  }, [open]);

  const countries = Object.keys(datas);
  const cities = data.country ? datas[data.country] : [];

  return (
    <Modal title='Update candidate pool' open={open} setOpen={setOpen}>
      <div className='flex flex-col my-4'>
        <label
          htmlFor='candidate_pool_name'
          className='text-pirmaryBlue text-base'
        >
          Pool Name
        </label>
        <input
          type='text'
          name='candidate_pool_name'
          value={data.candidate_pool_name || ''}
          onChange={handleChangeData}
          id='candidate_pool_name'
          placeholder='Pool Name'
          className='focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded'
        />
      </div>
      
      <div className='flex flex-col my-4 '>
        <label htmlFor='country' className='text-pirmaryBlue text-base'>
          Country
        </label>
        <SelectField
          selectItems={countries}
          label=''
          labelId='country'
          id='country'
          value={data.country || ''}
          onChange={handleChangeData}
          name='country'
          placeholder='Select country'
          inputStyle={{ padding: '14px' }}
        />
      </div>

      <div className='flex flex-col my-4 '>
        <label htmlFor='city' className='text-pirmaryBlue text-base'>
          City
        </label>
        <SelectField
          selectItems={cities}
          label=''
          labelId='city'
          id='city'
          value={data.city || ''}
          onChange={handleChangeData}
          name='city'
          placeholder='Select city'
          inputStyle={{ padding: '14px' }}
          disabled={cities?.length > 0 ? false : true}
        />
      </div>

      <div className='flex flex-col my-4 '>
        <label htmlFor='job_requisition' className='text-pirmaryBlue text-base'>
          Job Requisition
        </label>
        <SelectField
          selectItems={jobReq || []}
          label=''
          labelId='job_requisition'
          id='job_requisition'
          value={data.job_requisition}
          onChange={handleChangeData}
          name='job_requisition'
          placeholder='Select'
          inputStyle={{ padding: '14px' }}
        />
      </div>
      <div className='flex flex-col my-4 '>
        <label
          htmlFor='job_id'
          className='text-pirmaryBlue text-base'
        >
          Job ID
        </label>
        <SelectField
          selectItems={jobIds || []}
          label=''
          labelId='job_id'
          id='job_id'
          value={data.job_id}
          onChange={handleChangeData}
          name='job_id'
          placeholder='Select'
          inputStyle={{ padding: '14px' }}
        />
      </div>
      <div className='flex items-center justify-end'>
        <SuccessBtn onClick={handleUpdate}>Update candidate pool</SuccessBtn>
      </div>
    </Modal>
  );
}

export default UpdateCandidate;
