import React from "react";

import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

export default function InfoBoxes({
  title,
  icon,
  children,
  TooltipTitle,
  TooltipText,
  handleClickAdd,
  hasIcon,
  containerStyle,
  displayStyle,
  displayWidthStyle
}) {
  return (
    <div
      className={`bg-white rounded-2xl shadow-md relative px-8 py-6 mt-6 ${
        containerStyle ? containerStyle : ""
      }`}
    >
      <div className={`justify-between mb-6 ${displayStyle ? displayStyle:"flex"}`}>
        <p className="font-bold text-2xl lowercase first-letter:uppercase">
          {title}
          {!hasIcon && (
            <Tooltip
              placement="top"
              title={
                <div className={`rounded-md ${displayWidthStyle ? displayWidthStyle:""}`}>
                  {TooltipTitle && (
                    <div className='h-10 bg-pirmaryBlue text-white text-sm font-bold px-3 items-center flex rounded-t'>
                      {TooltipTitle}
                    </div>
                  )}
                  <div className="tooltip-font-desc text-white p-3 bg-[#4D5871] rounded-b">
                    {TooltipText}
                  </div>
                </div>
              }
              arrow
            >
              <InfoIcon
                className="text-secondaryBalck cursor-pointer ml-1"
                fontSize="small"
              />
            </Tooltip>
          )}
        </p>
        <span
          className="text-secondaryBalck cursor-pointer relative top-1"
          onClick={handleClickAdd}
        >
          {/* <EditOutlinedIcon className='text-secondaryBalck cursor-pointer' /> */}
          {icon}
        </span>
      </div>
      {children}
    </div>
  );
}
