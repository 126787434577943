import React from "react";
import { Link } from "react-router-dom";
import LogoLight from "../../../assets/tripalogowhite.svg";

export default function Footer() {
  return (
    <>
      {/* <footer className="bg-[#1C1D1F]  pt-28 pb-6">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-8 ">
          <div className=" flex flex-col items-center justify-center">
            <Link to="/">
              <img src={LogoLight} alt="" />
            </Link>
            <p className="my-3 text-white text-sm">
              Discover your career-driven purpose
            </p>
            <div className="my-1 flex justify-center items-center">
              <Link
                to="/"
                className="w-[35px] h-[35px] bg-[#414244] hover:bg-[#5e6065] transition-all rounded-full flex justify-center items-center mx-1"
              >
                <Facebook />
              </Link>
              <Link
                to="/"
                className="w-[35px] h-[35px] bg-[#414244] hover:bg-[#5e6065] transition-all rounded-full flex justify-center items-center mx-1"
              >
                <Twitter />
              </Link>
              <Link
                to="/"
                className="w-[35px] h-[35px] bg-[#414244] hover:bg-[#5e6065] transition-all rounded-full flex justify-center items-center mx-1"
              >
                <Linkedin />
              </Link>
              <Link
                to="/"
                className="w-[35px] h-[35px] bg-[#414244] hover:bg-[#5e6065] transition-all rounded-full flex justify-center items-center mx-1"
              >
                <Instagram />
              </Link>
            </div>
          </div>
          <div className=" text-white flex flex-col lg:items-start items-center justify-center">
            <Link to="#" className="text-2xl font-bold mb-2">
              Sitemap
            </Link>
            <Link to="/home/contact-us" className="my-1">
              Contact Us
            </Link>
            <Link to="/home/about" className="my-1">
              About Us
            </Link>
            <Link to="/home/contact-sales" className="my-1">
              Contact Sales
            </Link>
          </div>
          <div className=" text-white flex flex-col lg:items-start items-center justify-center">
            <Link to="#" className="text-2xl font-bold mb-2">
              Informations
            </Link>
            <Link to="/home/terms-conditions" className="my-1">
              General Terms & conditions
            </Link>
            <Link to="/home/privacy-policy" className="my-1">
              Privacy Policy
            </Link>
            <Link to="/home/cookie-settings" className="my-1">
              Cookie Settings
            </Link>
          </div>

          <div className="text-white flex flex-col lg:items-start items-center justify-center">
            <Link to="#" className="text-2xl font-bold mb-2">
              Contact Us
            </Link>
            <div>
              <div className="flex my-2">
                <span className="">
                  <Phone />
                </span>
                <span className="ml-4">+421-907-207-601</span>
              </div>
              <div className="flex my-2">
                <span className="">
                  <Mail />
                </span>
                <span className="ml-4">info@tripa.ai</span>
              </div>
              <div className="flex my-2">
                <span className="">
                  <Location />
                </span>
                <span className="ml-4 w-36">
                  Vienna, Austria Bratislava, Slovakia
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center text-white text-xs mt-24">
          Copyright ©2022, TRIPA, Inc. IMX - All rights reserved.
        </div>
      </footer> */}

      <footer class="footer">
        <div class="container">
          <div class="main-footer">
            <div class="footer-widgets" id="footer-widgets-one">
              <img src={LogoLight} alt="tripa-logo" class="footer-logo" />
              <p>Makes the invisible visible</p>
              <ul class="list-item social-icon">
                <li class="social-icon-style">
                  <a
                    href="https://www.facebook.com/tripacareerdriven"
                    target="_blank"
                    rel="noreferrer"
                    class="social-icon-item"
                  >
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                <li class="social-icon-style">
                  <a
                    href="https://twitter.com/TRIPA87755146"
                    class="social-icon-item"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li class="social-icon-style">
                  <a
                    href="https://www.linkedin.com/company/tripa/"
                    class="social-icon-item"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa-brands fa-linkedin-in"></i>
                  </a>
                </li>
                <li class="social-icon-style">
                  <a
                    href="https://www.instagram.com/tripacareerdriven/"
                    class="social-icon-item"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="footer-widgets" id="footer-widgets-two">
              <h3 class="footer-widget-title">Sitemap</h3>
              <ul class="list-item">
                <li class="menu-list">
                  <a href="https://tripa.ai/#features" target="_blank" rel="noreferrer">
                    FEATURES
                  </a>
                </li>
                <li class="menu-list">
                  <a href="https://tripa.ai/#team" target="_blank" rel="noreferrer">
                    TEAM
                  </a>
                </li>
                <li class="menu-list">
                  <a href="https://tripa.ai/#contactus" target="_blank" rel="noreferrer">
                    CONTACT
                  </a>
                </li>
                <li class="menu-list">
                  <Link to="/home/contact-sales">CONTACT SALES</Link>
                </li>
              </ul>
            </div>

            <div class="footer-widgets" id="footer-widgets-three">
              <h3 class="footer-widget-title">Informations</h3>
              <ul class="list-item">
                <li class="menu-list">
                  <a href="https://tripa.ai/general-terms-conditions/" target="_blank" rel="noreferrer">
                    Terms of Use and Conditions
                  </a>
                </li>
                <li class="menu-list">
                  <a href="https://tripa.ai/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
                </li>
                <li class="menu-list">
                  <a href="https://tripa.ai/cookie-policy/" target="_blank" rel="noreferrer">Cookie Settings</a>
                </li>
              </ul>
            </div>

            <div class="footer-widgets" id="footer-widgets-four">
              <h3 class="footer-widget-title">Contact Us</h3>
              <ul class="list-item info-icon">
                <li class="menu-list">
                  <a href="tel:+19548540755" class="info-widget">
                    <i class="fa-solid fa-phone"></i>
                    <span> +1 (954) 854-0755</span>
                  </a>
                </li>
                <li class="menu-list">
                  <a href="tel:+421907207601" class="info-widget">
                    <i class="fa-solid fa-phone"></i>{" "}
                    <span>+421-907-207-601</span>
                  </a>
                </li>
                <li class="menu-list">
                  <a href="mailto:info@tripa.ai" class="info-widget">
                    <i class="fa-solid fa-envelope"></i>{" "}
                    <span>info@tripa.ai</span>
                  </a>
                </li>
                <li class="menu-list">
                  <a
                    href="https://goo.gl/maps/45koooWPcSbnqXer5"
                    class="info-widget"
                  >
                    <i class="fa-solid fa-location-dot"></i>
                    <span>
                      3960 SW 195 Terrace
                      <br />
                      Miramar, Fl 33029, United States Žlta 7, Bratislava 85107,
                      Slovakia
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="bottom-section">
            <p>Copyright ©2022, TRIPA, Inc. – All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
}
