import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

// style for tab panel
export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const tabsStyle = makeStyles({
  root: {
    flexGrow: 1,
    color: "#273164",
    textAlign: "right",
    "& .MuiBox-root": {
      padding: "0 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#7AB794 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },
  tab: {
    width: "50%",
    maxWidth: "unset!important",
    border: "1px solid #E3E6E9 !important",
    "&.Mui-selected": {
      border: "1px solid #7AB794 !important",
      background: "#7AB794",
      color: "white !important",
      outline: "none",
      boxShadow: "none",
    },
  },
  checkbox: {
    "& .Mui-checked": {
      color: "#7AB693 !important",
    },
    "& .MuiFormControlLabel-label": {
      color: "#828282",
    },
  },
});

export const PasswordField = ({
  isPassword,
  setIsPassword,
  name,
  label,
  labelFor,
  id,
  value,
  onChange,
  placeholder,
  style,
  className,
  required = false,
}) => {
  const togglePassword = () => {
    setIsPassword(!isPassword);
  };

  return (
    <div className="flex flex-col my-4 relative">
      <label htmlFor={labelFor} className="text-pirmaryBlue">
        {label ? label : "Password"}
        {required ? " *" : null}
      </label>
      <input
        type={isPassword ? "text" : "password"}
       className= {className}
        // className={cx("focus:outline-none border-2 border-solid border-grayLight p-[12px] rounded" , + className)}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={style}
      />
      <button onClick={togglePassword} className="absolute right-5 top-[50%]">
        {isPassword ? (
          <VisibilityOutlinedIcon sx={{ color: "#828282" }} />
        ) : (
          <VisibilityOffOutlinedIcon sx={{ color: "#828282" }} />
        )}
      </button>
    </div>
  );
};

export const formValidate = (values) => {
  const myError = false;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (!values.email) {
    toast.error("Email is required!");
    return true;
  } else if (!regex.test(values.email)) {
    toast.error("This is not a valid email format!");
    return true;
  }
  if (!values.password) {
    toast.error("Password is required");
    return true;
  }
  return myError;
};
export const tabstyleBbehavior = makeStyles({
  root: {
    flexGrow: 1,
    color: "#273164",
    textAlign: "right",
    "& .MuiBox-root": {
      padding: "0 !important",
    },
  },
  red: {
    borderBottom: "1px solid #e5e5e5 !important",
    "& .MuiTabs-indicator": {
      backgroundColor: "#ff0000 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },
  yellow: {
    borderBottom: "1px solid #e5e5e5 !important",
    "& .MuiTabs-indicator": {
      backgroundColor: "#e5c311 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },
  green: {
    borderBottom: "1px solid #e5e5e5 !important",
    "& .MuiTabs-indicator": {
      backgroundColor: "green !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },
  blue: {
    borderBottom: "1px solid #e5e5e5 !important",

    "& .MuiTabs-indicator": {
      backgroundColor: "#00f !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#f0b90b",
      },
    },
  },

  tab: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    "&.Mui-selected": {
      outline: "none",
      boxShadow: "none",
    },
  },

  redTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #ff0000 !important",
      color: "red !important",
    },
  },
  yellowTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #e5c311 !important",
      color: "#e5c311 !important",
    },
  },
  greenTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid green !important",
      color: "green !important",
    },
  },
  blueTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #00f !important",
      color: "#00f !important",
    },
  },
});
export const tabstyleValue = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid #e5e5e5 !important",
    color: "#273164",

    textAlign: "right",
    "& .MuiBox-root": {
      padding: "0 !important",
    },
  },
  tab: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    "&.Mui-selected": {
      outline: "none",
      boxShadow: "none",
    },
  },

  green: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#A9D18E !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#A9D18E",
      },
    },
  },
  blue: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#4472C4 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#4472C4",
      },
    },
  },
  orange: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#ED7D33 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#ED7D33",
      },
    },
  },
  red: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#C2342C !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#C2342C",
      },
    },
  },
  yellow: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#E5C311 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#E5C311",
      },
    },
  },
  gray: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#7F7F7F !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#7F7F7F",
      },
    },
  },
  brown: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#7F6000 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#7F6000",
      },
    },
  },

  greenTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #A9D18E !important",
      color: "#A9D18E !important",
    },
  },
  blueTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #4472C4 !important",
      color: "#4472C4 !important",
    },
  },
  orangeTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #ED7D33 !important",
      color: "#ED7D33 !important",
    },
  },
  redTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #C2342C !important",
      color: "#C2342C !important",
    },
  },
  yellowTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #E5C311 !important",
      color: "#E5C311 !important",
    },
  },
  grayTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #7F7F7F !important",
      color: "#7F7F7F !important",
    },
  },
  brownTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #7F6000 !important",
      color: "#7F6000 !important",
    },
  },
}));
export const tabStyleRolBase = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "right",
    "& .MuiBox-root": {
      padding: "0 !important",
    },
  },
  tab: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    "&.Mui-selected": {
      outline: "none",
      boxShadow: "none",
    },
  },

  blue: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#096dd9 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#096dd9",
      },
    },
  },
  orange: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#ed7d31 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#ed7d31",
      },
    },
  },
  gray: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#a5a5a5 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#a5a5a5",
      },
    },
  },
  yellow: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#ffc000 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#ffc000",
      },
    },
  },
  lightBlue: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#5b9bd5 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#5b9bd5",
      },
    },
  },
  green: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#70ad47 !important",
    },
    "& .Mui-expanded": {
      "& .MuiIconButton-root": {
        color: "#70ad47",
      },
    },
  },

  blueTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #4472c4 !important",
      color: "#4472c4 !important",
    },
  },
  orangeTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #ed7d31 !important",
      color: "#ed7d31 !important",
    },
  },
  grayTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #a5a5a5 !important",
      color: "#a5a5a5 !important",
    },
  },
  yellowTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #ffc000 !important",
      color: "#ffc000 !important",
    },
  },
  lightBlueTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #5b9bd5 !important",
      color: "#5b9bd5 !important",
    },
  },
  greenTab: {
    "&.Mui-selected": {
      borderBottom: "1px solid #70ad47 !important",
      color: "#70ad47 !important",
    },
  },
}));
