import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import SuccessBtn from "../buttons/successBtn/SuccessBtn";
import SecondaryBtn from "../buttons/secondaryBtn/SecondaryBtn";
import { useSelector } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "15px!important",
  },
  [theme.breakpoints.up("md")]: {
    "& .MuiPaper-root": {
      minWidth: "800px",
    },
    "& .MuiDialogContent-root": {
      minHeight: "40vh",
      display: "flex",
      flexDirection: "column",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& .MuiPaper-root": {
      margin: "20px",
      minWidth: "100%",
    },
    "& .MuiDialogContent-root": {
      minHeight: "80vh",
      display: "flex",
      flexDirection: "column",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "#7AB794",
        color: "#ffffff",
      }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function SlideBox({ componentList, title, open, setOpen, style, doneMethod }) {
  const businessModal = useSelector(state => state.budgetReducer.showInstructions)
  const [step, setStep] = React.useState(0);

  React.useEffect(() => {
    businessModal && setStep(0)
  }, [businessModal]);
  
  const handleBackBtn = () => {
    if (step !== 0) {
      setStep((prev) => prev - 1);
    }
  };
  const handleNextBtn = () => {
    if (step < componentList.length - 1) {
      setStep((prev) => prev + 1);
    } else {
      doneMethod ? doneMethod() : setOpen(false);
    }
  };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={style}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span className="font-bold text-lg">{title}</span>
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ padding: "20px 30px 40px 30px !important" }}
        >
          {/* steps will render here */}
          {componentList.map(
            (Component, index) =>
              step === index && <div key={index}>{Component}</div>
          )}

          {/* control steps */}
          <div
            className={`mt-auto relative flex items-center ${
              step !== 0 ? "justify-between" : "justify-end"
            }`}
          >
            {step !== 0 && (
              <SecondaryBtn
                customStyle={{ marginTop: "20px" }}
                onClick={handleBackBtn}
              >
                Back
              </SecondaryBtn>
            )}
            <SuccessBtn
              customStyle={{ marginTop: "20px" }}
              onClick={handleNextBtn}
            >
              {step === componentList.length - 1 ? "Done" : "Next"}
            </SuccessBtn>

            <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[50%] flex">
              {componentList.map((Component, index) => (
                <div
                  key={index}
                  className={`w-[11px] h-[11px] rounded-full mr-1 ${
                    step === index ? "bg-success" : "bg-[#daebe1]"
                  }`}
                ></div>
              ))}
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default SlideBox;
