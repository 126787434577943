import React, { useEffect } from "react";
import WhiteBox from "../../sharedComponents/whiteBox/WhiteBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { a11yProps, TabPanel, tabsStyle } from "./reusableComponent";

import AccountSettings from "./tabs/AccountSettings";
import Subscription from "./tabs/Subscription/Subscription";
import Recommendation from "./tabs/Recommendation";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getByUserId, updateSubscriptionStatus } from "../../../../api/jc";
import { updateUser } from "../../../../redux/actions/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function SettingsProfile() {
  const dispatch = useDispatch()
  const searchParams = useLocation().search;
  const isPaymentSuccess = new URLSearchParams(searchParams).get('success');
  const isPaymentCancel = new URLSearchParams(searchParams).get('canceled');
  const sessionId = new URLSearchParams(searchParams).get('session_id');
  const [value, setValue] = React.useState(0);
  const [allData, setAllData] = React.useState({});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = tabsStyle();
  const navigate = useNavigate();

  const getData = () => {
    getByUserId().then((res) => {
      setAllData(res.data);
      dispatch(updateUser(res.data));
    });
  }

  const updateStatus = async () => {
    try {
      await updateSubscriptionStatus({ user_pricing_type: "premium", sessionId: sessionId || ''})
    } catch (error) {
      console.error(error);
    }
  }
  
  useEffect(() => {
    if(isPaymentSuccess === 'true') {
      updateStatus();
      toast.success('Subscription activated successfully!')
      navigate('/coaching/profile/settings')
      setValue(1)
    }
    if(isPaymentCancel === 'true') {
      toast.error('Subscription failed, please try again!')
      navigate('/coaching/profile/settings')
      setValue(1)
    }
  }, []);

  return (
    <WhiteBox>
      <Tabs
        className={classes.root}
        value={value}
        onChange={handleChange}
        variant="scrollable"
        allowScrollButtonsMobile
        aria-label="basic tabs example"
        sx={{
          "& .MuiTabs-scroller": {
            borderRadius: "4px",
            color: "white",
          },
          "&.MuiTabs-root": {
            "&.MuiTabs-scroller": {
              "&.MuiTabs-indicator": {
                backgroundColor: "red !important",
              },
            },
          },
        }}
      >
        <Tab
          label="Account settings"
          {...a11yProps(0)}
          className={classes.tab}
        />
        <Tab label="Subscription" {...a11yProps(1)} className={classes.tab} />
        <Tab label="Recommendation" {...a11yProps(2)} className={classes.tab} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <AccountSettings
          allData={allData}
          getData={getData}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Subscription />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Recommendation allData={allData} />
      </TabPanel>
    </WhiteBox>
  );
}

export default SettingsProfile;
