import React from "react";

function ModalCard({ title, optionList, classstyle, linestyle }) {
  return (
    <div className={` h-[200px] ${classstyle}`}>
      <h4
        className={
          `text-base font-bold text-pirmaryBlue 
          rounded bg-grayLight py-2 px-2.5 ` + linestyle
        }
      >
        {title}
      </h4>
      <ul className="mt-2 ml-2.5 overflow-y-auto h-[150px]">
        {optionList.map((option, index) => {
          return (
            <li className="text-base text-secondaryBalck " key={index}>
              {option}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default ModalCard;
